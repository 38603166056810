import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { getStorage } from "../helper/storage";
import Header from "../Component/Layout/Header";
import Sidebar from "../Component/Layout/Sidebar";
import classNames from "classnames";

export const ProtectRoutes = ({ children }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const loggedIn = getStorage("logged-in");
  if (loggedIn === null) {
    return <Navigate to={"/login"} />;
  }
  return (
    <>
      <div className="al_dashboard">
        <div className="d-flex">
          <Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
          <div className={classNames("content", { "is-open": sidebarIsOpen })}>
            <Header toggle={toggleSidebar} />
            <div className="_mainContainer">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
