import config from "./_apiConfig/apiConfig";
import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const userLogin = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.baseURL + apiEndPoints.userLogin,
    data,
    false
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const changeUserPassword = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.baseURL + apiEndPoints.userChangePassword,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const dashboardDataRequest = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.baseURL + apiEndPoints.dashData,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
