import React, { useState } from "react";
import Select from "react-select";
import { FormFeedback, Label } from "reactstrap";

const Dropdown = (props) => {

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!selectedOption) {
      setIsFocused(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      position: "relative"
    }),
    placeholder: () => ({
      display: "none" // Hides react-select's default placeholder
    })
  };


  const {
    placeholder,
    options,
    selectedOption,
    setSelectedOption,
    valueText,
    labelText,
    label,
    error,
    focus,
    boxClass,
    className,
    classNamePrefix,
    isMulti = false,
    style,
    disabled,
    components,
    onMenuScrollToBottom,

  } = props;
  const invalid = !!(focus && error);
  const valid = !!(focus && !error);

  return (
    <div className={boxClass}>
      <div className="custom-select-wrapper" style={{ position: "relative" }}>
      {/* <div
        className={`floating-placeholder ${
          selectedOption || isFocused ? "float" : ""
        }`}
      >
        Select an option
      </div> */}

      {label && (
        <div
        className={`floating-placeholder ${
          selectedOption || isFocused ? "float" : ""
        }`}
      >
        <span className="_likInpt">{label}</span>
      </div>

        // <Label className="FormLableKaran"><span className="_likInpt">{label}</span></Label>
      )}

      <Select
        isMulti={isMulti}
        // hideSelectedOptions={!isMulti}
        className={className}
        styles={customStyles}
        value={selectedOption}
        // value={
        //   typeof selectedOption === "string"
        //     ? options?.find((item) => item[valueText] === selectedOption)
        //     : selectedOption
        // }
        classNamePrefix={classNamePrefix}
        onChange={setSelectedOption}
        onFocus={handleFocus}
      onBlur={handleBlur}
        options={
          valueText === "value" && labelText === "label"
            ? options
            : options?.map((item) => ({
              value: item[valueText],
              label: item[labelText],
              data: item
            }))
        }
        menuPlacement="auto"
        maxMenuHeight={190}
        placeholder={placeholder}
        isDisabled={disabled}
        components={components}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      {invalid && <FormFeedback>{error}</FormFeedback>}
    </div>
    </div>
  );
};

export default Dropdown;
