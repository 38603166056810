import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getProduct = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.product, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addProductApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.product, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateProductApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.product + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteProductApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.product + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveProduct = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.product + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};