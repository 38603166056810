import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getDocumentType = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.documentType, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addDocumentTypeApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.documentType, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateDocumentTypeApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.documentType + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteDocumentTypeApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.documentType + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveDocument = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.documentType + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};