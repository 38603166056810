import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getAllApprove = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.driverTrans, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};