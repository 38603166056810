import React, { useEffect, useState,useCallback } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch, getTags } from "../../services";
import { toast } from "react-toastify";
import {
  addOrderClientValidation,
  addOrderValidation,
  addResearchSchema,
  modalInputSchema,
  productModalSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  dateForXlsx,
  formatDate,
  formatDateList,
  handleKeyDown,
  orderTabList,
  pageCount,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  deleteResearch,
  getAllCustomer,
  singleNewDetails,
  updateActiveResearch,
  updateResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import {
  acceptAndRejectStatus,
  addFleetsForOrder,
  addOrder,
  deleteOrder,
  getAllFleetforOrder,
  getAllOrder,
  getFleetETAList,
  getdryCargo,
  gettankersCsv,
  singleOrderDetails,
  updateActiveOrder,
  updateOrder,
} from "../../services/orderService";
import { addProductApi, getProduct } from "../../services/productService";
import { getAllDriver } from "../../services/driverService";
import mytruck from "../../../image/tanker.png";
import {
  getAllDrop,
  getAllPeckup,
  getAllPickup,
  getOrderCountApi,
} from "../../services/locationService";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import { getAllRoute, singleRouteDetails } from "../../services/routeService";
import { components } from "react-select";
import { getUnit } from "../../services/unitService";
import CommonRoute from "../../Component/Common/CommonRoute";
import {
  addExitBorderApi,
  getExitBorder,
} from "../../services/exitBorderService";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { getStorage, setStorage } from "../../helper/storage";
import CommonSingleRoute from "../../Component/Common/CommonSingleRoute";
import { FiEdit } from "react-icons/fi";
import DropdownWithButton from "../../Component/Common/DropdownWithButton";

const productTypeList = [
  { label: "Liquid", value: 1 },
  { label: "Dry", value: 2 },
];

export const Orders = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    customer_name: "",
  };
  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const fleetObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const productObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const customerObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const driverObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const routeObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const borderObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  let defultFromLocation = {
    fromLocation: "",

  };

  let defultToLocation = {
    toLocation: "",

  };


  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Order"))[0]?.
    permissions : []



  const orderObj = {
    customer_reference_number: "",
    customer: userLogin?.user_type === 2 ? userLogin?.id : "",
    branch: "",
    pickup_date: formatDate(new Date(), "-"),
    drop_date: formatDate(new Date(), "-"),
    route: "",
    product: "",
    exit_border: "",
    qty: "",
    allocated_qty: "",
    pending_qty: "",
    remark: "",
    driver: "",
    status: 1,
    is_active: true,
    capacity_trailer_one: "",
    capacity_trailer_two: "",
    productName: "",
    product_type: "",
    unit: "",
    addBranch: "",
    addBorder: "",
    from_location: "",
    to_location: "",
    count: "",
  };
  const orderObjName = {
    customer_reference_number: "",
    customer: "",
    branch: "",
    pickup_date: formatDate(new Date(), "-"),
    drop_date: formatDate(new Date(), "-"),
    route: "",
    product: "",
    exit_border: "",
    qty: "",
    allocated_qty: "",
    pending_qty: "",
    remark: "",
    driver: "",
    status: 1,
    is_active: true,
    capacity_trailer_one: "",
    capacity_trailer_two: "",
    productName: "",
    productType: "",
    unit: "",
    addBranch: "",
    addBorder: "",
    from_location: "",
    to_location: "",
    count: "",
  };

  const result = userLogin?.user_branches?.join(",");

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenClient, setIsOpenClient] = useState(false)
  const [openFleet, isOpenFleet] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [allotedisOpen, setAllotedisOpen] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [imageType, setimageType] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    branch_uuid: result,
    customer_id: userLogin?.user_type === 2 ? userLogin?.id : "",
    branch_uuid: userLogin?.user_branches?.map((obj) => obj).join(","),
    ...obj,
  });
  const [filterModal, setFilterModal] = useState({
    limit: 100,
    status: status,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [isAllModLoadding, setIsAllModLoadding] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [branchList, setBranchList] = useState();
  const [fleetList, setFleetList] = useState();
  const [driverList, setDriverList] = useState();
  const [routeList, setRouteList] = useState();
  const [productList, seProductList] = useState();
  const [filterFleet, setFilterFleet] = useState([]);
  const [filterFleetPagi, setFilterFleetPagi] = useState();
  const [selectTrailerList, setSelectTrailerList] = useState([]);
  const [downloadcsv, setDownload] = useState("");
  const [routeValue, setRouteValue] = useState(1);
  const [selectTraler, setSelectTraler] = useState();
  const [OrderID, setOrderID] = useState({
    id: 1,
    qty: "",
  });
  const [indexOfFleet, setIndexOfFleet] = useState();
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [orderIdForXlx, setOrderIdForXlx] = useState("");
  const [itemForDownLoad, setItemForDownLoad] = useState("");
  const [loaddingSend, setLoaddingSend] = useState("");
  const [pickupList, setPickupList] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [routeDetails, setRouteDetails] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [borderList, setBorderList] = useState([]);
  const [productStage, setProductStage] = useState(0);
  const [productType, setProductType] = useState(null);
  const [unitType, setUnitType] = useState(null);
  const [openModal, setOpenModal] = useState({
    branch: false,
    product: false,
    exitBorder: false,
  });
  const [searchKey, setSearchKey] = useState("");
  const [fleetProductType, setFleetProductType] = useState({ type: "", orderId: "" ,branch_uuid:"", customer_name: "", order_number:"" });
  const [openRoute, setOpenRoute] = useState(false);
  const [openRoute2, setOpenRoute2] = useState(false);
  const [orderCount, setOrderCount] = useState()
  const [openCustomer, setOpenCustomer] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [all, setAll] = useState(false);
  const [fromList, setFromList] = useState([
    defultFromLocation
  ])
  const [toList, setToList] = useState([
    defultToLocation
  ])
  const [etaDataList,setETADatalist]=useState([])
  const [isDisablePopUp,setDisablePop]= useState(false)

  useEffect(() => {
    if (selectTrailerList?.length > 0) {
      setFilterFleet(filterByReference(filterFleet, selectTrailerList));
    }
  }, [selectTrailerList]);

//   useEffect(() => {

//     if (fleetProductType.orderId) {

//       handlefleetList(fleetProductType);
//       setAllotedisOpen(true);
//     }
// }, [searchKey, fleetProductType]);


  useEffect(() => {
    getBranchList();
    getFleetsList();
    getCustomerList();
    getProductList();
    getDriverList();
    getFromLocatonList();
    getToLocatonList();
    getUnitList();
    getBorderList();
    getOrderCount()
  }, []);

  useEffect(() => {
    if (formOrderValue?.route) {
      getRouteDetails();
    } else {
      setRouteDetails("");
    }
  }, [formOrderValue?.route]);

  // console.log("ok",customerList,userLogin?.id)

  const getRouteDetails = async () => {
    const res = await singleRouteDetails(formOrderValue.route);
    setRouteDetails(res?.results);
    setFormOrderName({
      ...formOrderName,
      from_location: { value: res?.results?.pickup_location_detail[0]?.id, label: res?.results?.pickup_location_detail[0]?.name },
      to_location: { value: res?.results?.drop_location_detail[0]?.id, label: res?.results?.drop_location_detail[0]?.name },
    });
    setFormOrderValue({ ...formOrderValue, from_location: res?.results?.pickup_location_detail[0]?.id, to_location: res?.results?.drop_location_detail[0]?.id });
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add the selected value to the array
      setSelectedValues([...selectedValues, value]);
    } else {
      // Remove the deselected value from the array
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const Requirement = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },
    { heading: "Date", className: "sorting", value: "date", sortName: "" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customer_name",
      sortName: "",
    },
    {
      heading: "Route",
      className: "sorting_asc",
      value: "routeName",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "status",
      sortName: "",
    },
    {
      heading: "Product",
      className: "sorting_asc",
      value: "product",
      sortName: "",
    },
    {
      heading: "Requirement",
      className: "sorting_asc",
      value: "qty",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const Pending = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },
    { heading: "Date", className: "sorting", value: "date", sortName: "asc" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customer_name",
      sortName: "",
    },
    {
      heading: "Route",
      className: "sorting_asc",
      value: "routeName",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "status",
      sortName: "",
    },
    {
      heading: "Product",
      className: "sorting_asc",
      value: "product",
      sortName: "",
    },
    {
      heading: "Requirement",
      className: "sorting_asc",
      value: "qty",
      sortName: "",
    },
    {
      heading: "Placed",
      className: "sorting_asc",
      value: "placed",
      sortName: "",
    },
    // { heading: "Balance", className: "sorting_asc", value: "Balance" },
    { heading: "Action", className: "sorting_asc", value: "action" },
  ];

  const ActiveOrder = [
    {
      heading: "S.No",
      className: "sorting_asc",
      value: "serial",
      sortName: "",
    },
    {
      heading: "Order No.",
      className: "sorting_asc",
      value: "order_numbers",
      sortName: "",
    },
    {
      heading: "Order Reference Number ",
      className: "sorting_asc",
      value: "order_ref_number",
      sortName: "",
    },

    // { heading: "Expiry Date", className: "sorting", value: "drop_date" },
    { heading: "Date", className: "sorting_asc", value: "date", sortName: "" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customer_name",
      sortName: "",
    },
    {
      heading: "Route",
      className: "sorting_asc ",
      value: "routeName",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "status",
      sortName: "",
    },
    {
      heading: "Finance Approval",
      className: "sorting_asc",
      value: "finance_approval",
      sortName: "",
    },
    {
      heading: "Nominated",
      className: "sorting_asc",
      value: "nominate",
      sortName: "",
    },
    {
      heading: "Approval",
      className: "sorting_asc",
      value: "approveDocu",
      sortName: "",
    },
    {
      heading: "Pending",
      className: "sorting_asc",
      value: "pendding",
      sortName: "",
    },
    {
      heading: "Loading",
      className: "sorting_asc",
      value: "atLoadding",
      sortName: "",
    },
    // {
    //   heading: "Fund Allocation",
    //   className: "sorting_asc",
    //   value: "fundAllocation",
    //   sortName: "",
    // },
    {
      heading: "Intransit",
      className: "sorting_asc",
      value: "intransit",
      sortName: "",
    },
    {
      heading: "At Destination",
      className: "sorting_asc",
      value: "atDestinations",
      sortName: "",
    },
    {
      heading: "Delivered",
      className: "sorting_asc",
      value: "deliveredes",
      sortName: "",
    },
    {
      heading: "In Return",
      className: "sorting_asc",
      value: "inReturn",
      sortName: "",
    },
    {
      heading: "Completed",
      className: "sorting_asc",
      value: "Incompleted",
      sortName: "",
    },
    {
      heading: "Cancel",
      className: "sorting_asc",
      value: "cancelIn",
      sortName: "",
    },
    { heading: "Action", className: "sorting_asc", value: "action" },
  ];



  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({

      ...item,
      serial: (
        <>
          {+filter.status !== 5 && (
            <input
              type="checkbox"
              value={item.id}
              checked={selectedValues.includes(item.id)}
              onChange={handleCheckboxChange}
            />
          )}{" "}
          {i + 1}
        </>
      ),
      nominate: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 1 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.nominated}</a></div></>,

      approveDocu: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 2 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.document_ready}</a></div></>,
      pendding: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 3 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.at_port_entry}</a></div></>,
      atLoadding: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 4 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.at_loading}</a></div></>,
      fundAllocation: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 5 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.fund_allocation}</a></div></>,
      intransit: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 6 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.in_transit}</a></div></>,
      atDestinations: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 7 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.at_destination}</a></div></>,

      deliveredes: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 8 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.delivered}</a></div></>,
      inReturn: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 9 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.in_return}</a></div></>,
      Incompleted: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 10 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.completed}</a></div></>,
      cancelIn: <><div onClick={() => setStorage("statusTag", { orderId: item?.order_number, id: 11 })}><a href={`/trips`} target="_blank" rel="noopener noreferrer">{item?.trip_data?.cancel}</a></div></>,
      order_numbers: item?.order_number,
      status: (
        <div className="statusofOrder">

          {item?.status === 1 ? (
            <span>Created</span>
          ) : item?.status === 2 ? (
            <span>Pending</span>
          ) : item?.status === 3 ? (
            <span>Active Orders</span>
          ) : item?.status === 4 ? (
            <span>Near to Expair</span>
          ) : item?.status === 5 ? (
            <span>Cancelled</span>
          ) : item?.status === 6 ? (
            <span>Completed</span>
          ) : (
            ""
          )}
        </div>
      ),
      // route1: routeList?.find((ru) => ru.id === item.route)?.route_name,
      routeName:(
        <div className="cursorPointer" onClick={()=>setDisablePop(item?.route)}>{item?.route_name}</div>
      ),
      product: (
        <div>
          {item?.product_detail?.name}
        </div>
      ),
      date: formatDateList(item?.added_on),
      qty: `${item?.qty} ${item?.product_detail?.product_type === 1 ? "Ltr" : "Ton"
        }`,
      finance_approval:(<span style={{color:"#0D6EFD",textDecoration:"underline", cursor: "pointer"}}>{item?.trip_data?.finance_approval}</span>),
      nominated: item?.trip_data?.nominated,
      atDestination: item?.trip_data?.at_destination,
      at_loading: item?.trip_data?.at_loading,
      at_port_entry: item?.trip_data?.at_port_entry,
      cancel: item?.trip_data?.cancel,
      completed: item?.trip_data?.completed,
      delivered: item?.trip_data?.delivered,
      document_ready: item?.trip_data?.document_ready,
      fund_allocation: item?.trip_data?.fund_allocation,
      in_return: item?.trip_data?.in_return,
      in_transit: item?.trip_data?.in_transit,
      action: (
        <>
          <div className="actionBtn">
            {+filter.status === 2 && (
              <div className="requiermentBtn">
                {" "}
                <button
                  className="anime-btn accept_odr"
                  disabled={isLoadding}
                  onClick={() => {
                    setFleetProductType({ ...fleetProductType, type: item?.product_detail?.product_type, orderId: item?.id, branch_uuid :item?.branch, customer_name: item?.customer_name, order_number:item?.order_number  });
                    // handlefleetList(item?.product_detail?.product_type);
                    setOrderID({
                      id: item?.id,
                      qty: item?.qty,
                      type: item?.product_detail?.product_type,
                    });
                  }}
                >
                  Allocate
                </button>
                <button
                  className="cancel_ord"
                  onClick={() => hendleDeleteTag(item, true)}
                >
                  Cancel
                </button>
              </div>
            )}
            {+filter.status === 1 && (
              <div className="requiermentBtn">
                <button
                  className="anime-btn accept_odr"
                  onClick={() => acceptHandle(item?.id)}
                >
                  Accept Order
                </button>
                <button
                  className="cancel_ord"
                  onClick={() => hendleDeleteTag(item, true)}
                >
                  Cancel
                </button>
              </div>
            )}
            {+filter.status === 3 && (
              <div className="requiermentBtn">
                <button
                  className="anime-btn accept_odr"
                  onClick={() => {
                    setOrderIdForXlx(item?.id);
                    setItemForDownLoad(item);
                    setOpenDownload(true);
                  }}
                >
                  Download
                </button>
                <button
                  className="cancel_ord"
                  onClick={() => hendleDeleteTag(item, true)}
                >
                  Cancel
                </button>
              </div>
            )}

            {/* <SwitchField onSwitchChange={() => updateTagStatus(item)} value={item?.is_active} /> */}
            {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>  : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
            {/* <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} /> */}
            {/* <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> */}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));

    return (userLogin?.user_type !== 1 && userLogin?.user_branches?.length === 0) ? [] : listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllOrder", debouncedFilter],
    () => getAllOrder(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);



  useEffect(() => {
    getRouteList();
    setFormOrderValue({ ...formOrderValue, route: "" });
    setFormOrderName({ ...formOrderName, route: "" });
}, [formOrderValue.customer]);

  useEffect(() => {
    getOrderCount()
  }, [filter])

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);

    if (res.results?.length === 1) {


      setFormOrderName({
        ...formOrderName,
        branch: { data: res.results[0], label: res.results[0]?.name, value: res.results[0]?.id }
      })
      setFormOrderValue({
        ...formOrderValue,
        branch: res?.results[0]?.id
      })
    }



  };

  const getFleetsList = async () => {
    const res = await getFleetType(fleetObj);
    setFleetList(res?.results);
  };

  const getProductList = async () => {
    const res = await getProduct(productObj);
    seProductList(res?.results);
  };

  const getCustomerList = async () => {
    const payload = {
      ...customerObj,
      branch:result,
    }
    const res = await getAllCustomer(payload);
    const list = res?.results?.map((item, i) => {
      return { name: item?.name, id: item?.id };
    });

    setCustomerList(list);
  };
  const getDriverList = async () => {
    const res = await getAllDriver(driverObj);
    setDriverList(res?.results);
  };
  
  const getRouteList = async () => {
    const res = await getAllRoute(
      formOrderValue?.customer
        ? Object.assign(routeObj, { customer: formOrderValue?.customer })
        : routeObj
    );
    setRouteList(res?.results);
  };
  const getUnitList = async () => {
    const res = await getUnit();

    setUnitList(res?.results);
  };

  const getBorderList = async () => {
    const res = await getExitBorder(borderObj);

    setBorderList(res?.results);
  };

  const getFromLocatonList = async () => {
    const payload = {
      status: {
        limit: "all",
        status: 1,
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "id",
      },
      type: 1,
    };
    const res = await getAllPickup(payload);
    setPickupList(res?.results);
  };

  const getToLocatonList = async () => {
    const payload = {
      status: {
        limit: "all",
        status: 1,
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "id",
      },
      type: 2,
    };
    const res = await getAllDrop(payload);
    setDropList(res?.results);
  };


  const getOrderCount = async () => {
    const res = await getOrderCountApi();

    const result = res?.results?.map((item) => {
      return { label: item?.label === 'all' ? "All" : item?.label, value: item?.value, count: item?.count }
    })

    setOrderCount(result);
  };
  const acceptHandle = async (id) => {
    const payload = {
      action_type: "Accept",
      id: id,
    };
    const res = await acceptAndRejectStatus(payload);
    if (res.status === 200) {
      toast.success(res.message);
      setFilter({
        ...filter,
        status: 2,
      });
    }
  };

  const cancelHandle = async (id) => {
    const payload = {
      action_type: "Reject",
      id: id,
    };
    const res = await acceptAndRejectStatus(payload);
    if (res.status === 200) {
      onSuccessResponse(res.message);
      setAll(false);
      setSelectedValues([]);
      setOpenDel(false);
      setFilter({
        ...filter,
        status: 5,
      });
    }

    if (res.status === 400) {
      toast.error(res.error[0]);
    }
  };



  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event)=>{
    setSearchKey(event.target.value);
  }

  const handlefleetList = async (product, searchValue) => {
    
    setIsAllModLoadding(true);
    const payload = {
      // order_id: product?.orderId,
      product_type: product?.type,
      name: searchValue,
      limit: filterModal.limit,
      offset: filterModal?.offset,
      branch_uuid: product?.branch_uuid,
      nomination_status:1,
    };

    const res = await getAllFleetforOrder(payload);
    if (res.status === 200) {
      setFilterFleet(res.results);
      setFilterFleetPagi(res);
      setIsAllModLoadding(false);
      handleETAData(res?.results, product?.orderId)
      // setAllotedisOpen(true);
      // setFleetProductType("")
    } else {
      setIsAllModLoadding(false);
    }
  };

  const debouncedHandleFleetList = useCallback(debounce(handlefleetList , 500), []);

  useEffect(() => {
    if (fleetProductType?.orderId) {
      debouncedHandleFleetList(fleetProductType, searchKey);
      setAllotedisOpen(true);
    }
}, [searchKey, fleetProductType, debouncedHandleFleetList,filterModal ]);

// const handleETAData =async (fleetDatalist, orderId)=>{
//   const fleetIds = fleetDatalist.map((fleet) => fleet.id);

//   const payload = {
//     fleet_ids: fleetIds,
//     order: orderId   
//   };

//   const response = await getFleetETAList(payload)
//   if(response?.status === 200) {
//     const etaData = response?.results;

//     const matchedFleetData = fleetDatalist.map((fleet) => {
//       const fleetId = fleet.id;
//       const eta = etaData[fleetId]; 

//       return {
//         ...fleet,
//         total_duration_hours: eta?.total_duration_hours || 'N/A',
//         total_distance_kms: eta?.total_distance_kms || 'N/A'
//       };
//     });

//     setETADatalist(matchedFleetData);
//   }else{
//     setETADatalist([])
//   }
// }

const handleETAData =async (fleetDatalist, orderId)=>{
  const fleetIds = fleetDatalist.map((fleet) => fleet.id);
  const payload = {
    fleet_ids: fleetIds,
    order: orderId   
  };
  const response = await getFleetETAList(payload)
  if(response?.status === 200) {
    setETADatalist(response?.results)
  }else{
    setETADatalist([])
  }
}

  const callBackData = (data) => {
    if (data.trailer_details.length > 0) {
      let trailerArray = data.trailer_details.map((item, index) => ({
        ...item,
        ["capacity_trailer_" + (index + 1)]: item.tare_weight,
      }));

      let totalCapacity = trailerArray.reduce(
        (accumulator, currentValue, currentIndex) => {
          return (
            accumulator +
            parseInt(currentValue["capacity_trailer_" + (currentIndex + 1)])
          );
        },
        0
      );

      setSelectTraler({
        ...data,
        trailer_details: trailerArray,
        date: formatDate(new Date(), "-"),
        totalCapacity: totalCapacity,
        driver: data.driver,
      });
      setFormOrderValue({ ...formOrderValue, driver: data.driver });
      let driverDetails = driverList?.find((item) => item?.id === data.driver);
      setFormOrderName({
        ...formOrderName,
        driver: driverDetails
          ? { label: driverDetails.name, value: driverDetails.id }
          : {},
      });
    }
  };

  useEffect(() => {
    setSelectTraler({ ...selectTraler, driver: formOrderValue.driver });
  }, [formOrderValue.driver]);

  useEffect(() => {
    if (selectTraler?.trailer_details?.length > 0) {
      let totalCapacity = selectTraler?.trailer_details?.reduce(
        (accumulator, currentValue, currentIndex) => {
          return (
            accumulator +
            parseInt(+currentValue["capacity_trailer_" + (currentIndex + 1)])
          );
        },
        0
      );

      setSelectTraler({ ...selectTraler, totalCapacity: totalCapacity });
    }
  }, [selectTraler?.trailer_details]);

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1?.filter((el) => {
      return !arr2?.find((element) => {
        return element?.id === el?.id;
      });
    });
    return res;
  };

  const onSubmit = () => {
    const trailer = [...selectTrailerList];
    trailer.push(selectTraler);
    setSelectTrailerList(trailer);
    setIsOpenModal(false);
    setSelectTraler("");
  };

  const deleteHandle = (i) => {
    const trailer = [...selectTrailerList];
    const filter = [...selectTrailerList];
    const filterLet = [...filterFleet];
    filterLet.push(filter.filter((item, index) => index === i)[0]);
    setFilterFleet(filterLet);
    trailer.splice(i, 1);
    setSelectTrailerList(trailer);
    isOpenFleet(false);
  };

  const sendNoninationHandle = async () => {
    const list = selectTrailerList.map((item) => {
      const capacity = [];
      item.trailer_details.map((u, i) => {
        capacity["capacity_trailer_" + (i + 1)] =
          u["capacity_trailer_" + (i + 1)];
      });
      return {
        ...capacity,
        fleet_id: item?.id,
        arrival_datetime: item?.date,
        driver: item?.driver,
      };
    });

    setLoaddingSend(true);

    const res = await addFleetsForOrder({ fleet_data: list, id: OrderID.id });
    if (res.status === 200) {
      toast.success(res?.message);
      setFilter({
        ...filter,
        status: 3,
      });
      setAllotedisOpen(false);
      setSelectTrailerList([]);
      setLoaddingSend(false);
    } else {
      setLoaddingSend(false);
      if (res?.error) {
        toast.error(res?.error?.fleet_id);
      } else {
        toast.error(res?.message);
      }

    }
  };

  const deleteFleetHandle = (index) => {
    isOpenFleet(true);
    setIndexOfFleet(index);
  };

  const handleExportAction = () => {
    downloadcsvExcle.mutate(orderIdForXlx);
  };

  const downloadcsvExcle = useMutation(
    downloadcsv === 1 ? getdryCargo : gettankersCsv,
    {
      onSuccess: (res) => {
        if (res) {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/octet-stream" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `JOB-REF#${itemForDownLoad?.reference_number}-${dateForXlsx()}-${itemForDownLoad?.customer_name
            }.xlsx`
          );
          link.click();
          // link.download = 'filename.txt';
          setOpenDownload(false);
        }
      },
    }
  );

  const clearAll = () => {
    setFormOrderValue({
      customer_reference_number: "",
      customer: "",
      branch: "",
      pickup_date: formatDate(new Date(), "-"),
      drop_date: formatDate(new Date(), "-"),
      route: "",
      product: "",
      qty: "",
      allocated_qty: "",
      pending_qty: "",
      remark: "",
      driver: "",
      status: 1,
      is_active: true,
      exit_border: "",
      from_location: "",
      to_location: ""
    });
    setFormOrderName({
      customer_reference_number: "",
      customer: "",
      branch: "",
      pickup_date: formatDate(new Date(), "-"),
      drop_date: formatDate(new Date(), "-"),
      route: "",
      product: "",
      qty: "",
      allocated_qty: "",
      pending_qty: "",
      remark: "",
      driver: "",
      status: 1,
      is_active: true,
      exit_border: "",
      from_location: "",
      to_location: ""
    });
    setPreview("");
    setIsEdit(false);
    setFormValue("");
    setFormError("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    if (name === "tags") stateObj["tags"] = value?.map((i) => i?.value);

    setFormValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addResearchSchema);
      setValidationError(error);
    }
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    // console.log("branchx", formOrderValue);
    // console.log("branchx1", formOrderName);

    if (!!formError) {
      const error = await checkFormError(stateObj, addOrderValidation);
      setValidationError(error);
    }
  };

  const onOrderSubmit = async (e, type) => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      type === "Admin" ? addOrderValidation : addOrderClientValidation
    );

    const payload = {
      order_type: routeValue,
      customer_reference_number: formOrderValue?.customer_reference_number,
      customer: formOrderValue?.customer,
      branch: userLogin?.user_branches?.length <= 1 ? userLogin?.user_branches[0] : formOrderValue?.branch,
      pickup_date: formOrderValue?.pickup_date,
      drop_date: formOrderValue?.drop_date,
      route: formOrderValue?.route,
      product: formOrderValue?.product,
      qty: formOrderValue?.qty,
      exit_border: formOrderValue?.exit_border,
      // remark: formOrderValue?.remark,
      status: formOrderValue?.status,
      is_active: formOrderValue?.is_active,
    };

    // if (type === "Admin") {
    //   payload['branch'] = formOrderValue?.branch;
    // }

    // if (type === "client") {
    //   payload['to_location'] = formOrderValue?.to_location;
    //   payload['from_location'] = formOrderValue?.from_location;
    // }

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(payload);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };
  const handleAddNew = (value) => {
    if (value === "product") {
      const payload = {
        name: formOrderValue.productName,
        product_type: formOrderValue?.product_type,
        unit: formOrderValue.unit,
      };
      addNewProductMutation.mutate(payload);
    }

    if (value === "branch") {
      const payload = {
        name: formOrderValue.addBranch,
      };

      addNewBranchMutation.mutate(payload);
    }

    if (value === "ExitBorder") {
      const payload = {
        name: formOrderValue.addBorder,
      };

      addNewBorderMutation.mutate(payload);
    }
  };
  const handleDelete = () => {
    cancelHandle(deleteId);
  };

  const handleEditAction = (item) => {
    getOrderDetails.mutate(item?.id);
  };
  const getOrderDetails = useMutation(singleOrderDetails, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          customer_reference_number: res?.results?.customer_reference_number,
          customer: res?.results.customer,
          branch: res?.results.branch,
          pickup_date: res?.results.pickup_date,
          drop_date: res?.results.drop_date,
          route: res?.results?.route,
          product: res?.results.product,
          qty: res?.results.qty,
          exit_border: res?.results.exit_border,
          remark: res?.results.remark,
          status: res?.results.status,
          is_active: res?.results.is_active,
        });

        setRouteValue(res?.results?.order_type);
        const common = (name, id) => {
          if (name === "product") {
            let details = productList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "branch") {
            let details = branchList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "from_location") {
            let details = pickupList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "to_location") {
            let details = dropList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "customer") {
            let details = customerList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }

          if (name === "route") {
            let details = routeList?.find((i) => i.id === id);
            return {
              data: details,
              label: details?.route_name,
              value: details?.id,
            };
          }
        };

        setFormOrderName({
          ...formOrderName,
          customer: common("customer", res?.results?.customer),
          pickup_date: res?.results.pickup_date,
          drop_date: res?.results.drop_date,
          from_location: common("from_location", res?.results?.from_location),
          to_location: common("to_location", res?.results?.to_location),
          product: common("product", res?.results?.product),
          branch: common("branch", res?.results?.branch),
          route: common("route", res?.results?.route),
        });

        setPreview(res?.results?.image);
        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );
    const payload = {
      formdata: data,
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setFleetProductType({ ...fleetProductType, type: res?.results?.product_detail?.product_type, orderId: res?.results?.id , branch_uuid:res?.results?.branch, customer_name: res?.results?.customer_name, order_number:res?.results?.order_number});
        setOrderID({
          id: res?.results?.id,
          qty: res?.results?.qty,
          type: res?.results?.product_detail?.product_type,
        });
        setFilter({
          ...filter,
          status: 2,
        });
        setAllotedisOpen(true)
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });
  const addNewBranchMutation = useMutation(addBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          branch: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addBranch: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          branch: res?.results?.id,
          addBranch: "",
        });

        setOpenModal({ ...openModal, branch: false });
        getBranchList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const addNewBorderMutation = useMutation(addExitBorderApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          exit_border: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addBorder: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          exit_border: res?.results?.id,
          addBorder: "",
        });

        setOpenModal({ ...openModal, exitBorder: false });
        getBorderList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const addNewProductMutation = useMutation(addProductApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);
        setFormOrderName({
          ...formOrderName,
          product: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          productName: "",
          product_type: "",
          unit: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          product: res?.results?.id,
          productName: "",
          product_type: "",
          unit: "",
        });

        setOpenModal({ ...openModal, product: false });
        getProductList();
        setProductType("");
        setUnitType("");
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteOrder, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllOrder");

    setIsOpen(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handlePageClickModal = async (data) => {
    const currentPage = data.selected;
    setFilterModal((s) => ({
      ...s,
      offset: currentPage * filterModal.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data.id);
  };

  const hendleAllCancle = (data, flag) => {
    setOpenDel(true);
    setAll(true);
  };

  const handleActive = (data) => {
    if (data.value !== filter?.status) {
      setLoaderForList(true);

      setFilter({
        ...filter,
        status: data.value,
      });
    }

  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;
      case 2:
        return Pending;
      case 3:
        return ActiveOrder;
      default:
        return Requirement;
    }
  };

  /// ----------- add Branch and product ---------

  const addNewSubmit = async (value) => {
    if (value === "branch") {
      const validationResult = await checkFormError(
        { name: formOrderValue.addBranch },
        modalInputSchema
      );

      if (validationResult === true) {
        setIsLoadding(true);
        if (isEdit === true) {
          handleUpdate();
        } else {
          handleAddNew(value);
        }
      } else {
        setFormError(validationResult);
        setIsLoadding(false);
      }
    }

    if (value === "ExitBorder") {
      const validationResult = await checkFormError(
        { name: formOrderValue.addBorder },
        modalInputSchema
      );

      if (validationResult === true) {
        setIsLoadding(true);
        if (isEdit === true) {
          handleUpdate();
        } else {
          handleAddNew(value);
        }
      } else {
        setFormError(validationResult);
        setIsLoadding(false);
      }
    }

    if (value === "product") {
      const validationResult = await checkFormError(
        {
          name: formOrderValue.productName,
          product_type: formOrderValue?.product_type,
          unit: formOrderValue?.unit,
        },
        productModalSchema
      );

      if (validationResult === true) {
        setIsLoadding(true);
        if (isEdit === true) {
          handleUpdate();
        } else {
          handleAddNew(value);
        }
      } else {
        setFormError(validationResult);
        setIsLoadding(false);
      }
    }
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Branch":
        setFormOrderValue({ ...formOrderValue, addBranch: inputvalue });
        setOpenModal({ ...openModal, branch: true });
        break;
      case "Select Customer":
        setOpenCustomer(true);
        break;
      case "Select Product":
        setFormOrderValue({ ...formOrderValue, productName: inputvalue });
        setOpenModal({ ...openModal, product: true });
        break;
      case "Select Route":
        setOpenRoute(true);
        break;
      case "Select Border":
        setFormOrderValue({ ...formOrderValue, addBorder: inputvalue });
        setOpenModal({ ...openModal, exitBorder: true });
        break;
      case "Select Trip Route":
        setOpenRoute2(true);
        break;
      default:
        break;
    }
  };

  const routeCallBack = (data) => {
    if (data?.email) {
      getCustomerList();
      setFormOrderValue({ ...formOrderValue, customer: data?.id });
      setFormOrderName({
        ...formOrderName,
        customer: { value: data?.id, label: data?.name },
      });
    } else {
      getRouteList();
      setFormOrderValue({ ...formOrderValue, route: data?.id });
      setFormOrderName({
        ...formOrderName,
        route: { value: data?.id, label: data?.route_name },
      });
    }
  };

  const routeClientCallBack = (data) => {
    // console.log("data", data, data?.drop_location_detail[0]?.id)
    if (data?.email) {
      getCustomerList();
      setFormOrderValue({ ...formOrderValue, customer: data?.id });
      setFormOrderName({
        ...formOrderName,
        customer: { value: data?.id, label: data?.name },
        from_location: { value: data?.pickup_location_detail[0]?.id, label: data?.pickup_location_detail[0]?.name },
        to_location: { value: data?.drop_location_detail[0]?.id, label: data?.drop_location_detail[0]?.name },
      });


      setFormOrderValue({ ...formOrderValue, from_location: data?.pickup_location_detail[0]?.id, to_location: data?.drop_location_detail[0]?.id });
    } else {
      getRouteList();
      setFormOrderValue({ ...formOrderValue, route: data?.id });
      setFormOrderName({
        ...formOrderName,
        route: { value: data?.id, label: data?.route_name },
        from_location: { value: data?.pickup_location_detail[0]?.id, label: data?.pickup_location_detail[0]?.name },
        to_location: { value: data?.drop_location_detail[0]?.id, label: data?.drop_location_detail[0]?.name },
      });


      setFormOrderValue({ ...formOrderValue, from_location: data?.pickup_location_detail[0]?.id, to_location: data?.drop_location_detail[0]?.id });
    }


    // if (data?.email) {
    //   getCustomerList();
    //   setFormOrderValue({ ...formOrderValue, customer: data?.id });
    //   setFormOrderName({
    //     ...formOrderName,
    //     customer: { value: data?.id, label: data?.name },
    //   });
    // } else {
    //   getRouteList();
    //   setFormOrderValue({ ...formOrderValue, route: data?.id });
    //   setFormOrderName({
    //     ...formOrderName,
    //     route: { value: data?.id, label: data?.route_name },
    //   });
    // }
  };

  const locationDetails = (i) => i?.map((u) => u?.name).join("   +   ");

  const cancelAllCheckBox = () => {
    selectedValues?.map((item) => {
      cancelHandle(item);
    });
  };

  const handleAllocateModalReset = ()=>{
    setSearchKey("")
    setFilterModal({
      limit: 100,
      status: status,
      ...obj,
    })
  }

  const handleViewOption= (option)=>{
    setOpenRoute(option)
  }

  return (
    <>
      <div className="tabMain_head">
        {" "}
        {orderCount && (
          <Tabs
            TabList={orderCount}
            handleActive={handleActive}
            activeTab={filter.status}
          />
        )}
        <div className="checkBox_Cnacle">
          {selectedValues.length > 0 && (
            <button className="anime-btn addPlan" onClick={() => hendleAllCancle()}>
              Cancel Orders
            </button>
          )}
          <button className="anime-btn addPlan" onClick={() => setIsOpen(true)}>
            Add Plan
          </button>
          {/* {  userLogin?.user_type === 1 && <button className="anime-btn addPlan" onClick={() => setIsOpen(true)}>
            Add Plan
          </button>}*/}

          {/* { (userLogin?.user_type !== 1 && permissions[2]) && <button className="anime-btn addPlan" onClick={() => setIsOpenClient(true)}>
            Add Plan Client
          </button>}  */}
          {/* {userLogin?.user_type !== 1 && <button className="anime-btn addPlan" onClick={() => setIsOpenClient(true)}>
            Add Plan Client test
          </button>} */}
        </div>
      </div>

      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Customer Name"
              label="Customer Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  customer_name: e.target.value,
                }))
              }
              value={filter?.customer_name}
            />
          </div>

          <div className="inr_flex_mini">
            <InputField
              placeholder="Order Number"
              label="Order Number"
              className="anvSearch_input"
              value={filter?.order_number}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  order_number: e.target.value,
                }))
              }
            />
          </div>
          {/* <div className="inr_flex_mini">
            <InputField
              placeholder="Search"
              className="anvSearch_input"
              value={filter?.search}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  search: e.target.value,
                }))
              }
            />
          </div> */}
          <div className="inr_flex_mini">
            {" "}
            <Dropdown
              className="dropdownoforder orderModal_Input"
              placeholder="Select Branch"
              label="Select Branch"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              name="driver"
              options={branchList ? branchList : []}
              valueText="id"
              labelText="name"
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.branchFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, branch_uuid: data.value });
                setFormOrderName({ ...formOrderName, branchFilter: data });
              }}
            />{" "}
          </div>
          <div className="inr_flex_mini">
            {(filter?.customer_name ||
              filter?.order_number ||
              filter?.branch_uuid.length == 36) && (
              <button
                className="anime-btn reset_button"
                onClick={() => {
                  setFilter({
                    ...filter,
                    customer_name: "",
                    order_number: "",
                    branch_uuid: result,
                  });
                  setFormOrderName({ ...formOrderName, branchFilter: "" });
                }}
              >
                Reset
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="table table_hieght">
        <div className="table-responsive">
          {/* {loaderForList ? <div className="loaderGif"> <img src={mytruck} alt="" /></div> :  */}
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
            // tableLoading={tableLoading}
          />
          {/* } */}
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                formOrderName?.count
                  ? formOrderName?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));

                setFormOrderName({ ...formOrderName, count: data });
              }}
            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpenModal}
        headerName={isEdit ? "Edit Allocate Fleet" : "Allocate Fleet"}
        toggle={() => {
          setIsOpenModal(false);
          setSelectTraler();
        }}
        cancelbtn_mdllcls="cancelbtn_mdllcls"
        submitLabel={isEdit ? "Update Fleet" : "Allot Fleet"}
        onSubmit={(e) => onSubmit(e)}
        className="addFleetModal"
        disabled={!selectTraler?.date || !formOrderValue?.driver}
        inputProps={
          <div className="alloteFleetModal">
            <InputField
              className="modal_input orderModal_Input"
              label="Fleet Number"
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Fleet Number"
              name="number"
              type="text"
              disabled={true}
              focus={!!formError?.number}
              error={formError.number}
              value={selectTraler?.truck_number}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <div>
              
              <CustomDatePicker
                  value={selectTraler?.date}
                  label="Arrival Date & Time"
                  // value="10/12/2023"
                  type="date"
                  max={formatDate(new Date())}
                  name="date"
                  onSelect={({ target: { name, value } }) =>
                    setSelectTraler({ ...selectTraler, date: value })
                  }
                  className="date-input"
                  placeholder={selectTraler?.date}
                />
            </div>

            <div>
              {/* <span>
                <Label className="FormLableKaran">Driver</Label>
              </span>
              <div> */}
                <Dropdown
                  placeholder="Select Driver"
                  className="dropdownoforder orderModal_Input"
                  label="Select Driver"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  name="driver"
                  options={driverList ? driverList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!formError?.driver}
                  error={formError.driver}
                  selectedOption={formOrderName?.driver}
                  setSelectedOption={(data) => {
                    handleOrderChange("driver", data.value);
                    setFormOrderName({ ...formOrderName, driver: data });
                  }}
                />
              {/* </div> */}
            </div>
            <div>
              {selectTraler?.trailer_details?.map((item, index) => (
                <InputField
                  className="modal_input orderModal_Input"
                  label={`Capacity  (${item?.trailer_number}) (${item?.tare_weight})`}
                  labelClass="modal_label"
                  labelSpanClass="style_for_star"
                  // placeholder="ABB 505 MC"
                  name={"capacity_trailer_" + (index + 1)}
                  type="text"
                  // focus={!!formError?.number}
                  // error={formError.number}
                  value={item["capacity_trailer_" + (index + 1)]}
                  onChange={({ target: { name, value } }) => {
                    const trailerArray = [...selectTraler.trailer_details];
                    trailerArray.splice(index, 1, { ...item, [name]: value });
                    setSelectTraler({
                      ...selectTraler,
                      trailer_details: trailerArray,
                    });
                    // handleOrderChange(name, value)
                  }}
                />
              ))}
            </div>
          </div>
        }
      />

      {/* {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setPreview("");

            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e, "Admin")}
          title={isEdit ? "Edit Order" : "Add Order"}
          submitLabel={isEdit ? "Update order" : "Create Order"}
          inputProps={
            <div className="anvOt_gridBx ">
              <div className="anvOt_outerFull ">
                <FormGroup tag="fieldset" className="orderRadio">
                  <FormGroup check></FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        onClick={() => setRouteValue(1)}
                        checked={routeValue === 1}
                      />{" "}
                      One Way
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        onClick={() => setRouteValue(2)}
                        checked={routeValue === 2}
                      />{" "}
                      Round Way
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={
                    isEdit ? "Customer Reference No" : "Customer Reference No"
                  }
                  labelClass=""
                  placeholder="Reference No"
                  name="customer_reference_number"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.customer_reference_number}
                  error={formError.customer_reference_number}
                  value={formOrderValue?.customer_reference_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">

                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Customer"
                  label="Customer Name"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  name="customer"
                  options={customerList ? customerList : []}
                  valueText="id"
                  labelText="name"
                  components={userLogin?.user_type == 1 ? { Menu: CustomControl } : ""}
                  focus={!!formError?.customer}
                  error={formError?.customer}
                  selectedOption={formOrderName.customer}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, customer: data });
                    handleOrderChange("customer", data.value);
                  }}
                />

              </div>
              <div className="anvOt_inputField ">

                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Branch"
                  label="Branch Name"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  name="branch"
                  options={branchList ? branchList : []}
                  valueText="id"
                  labelText="name"
                  components={userLogin?.user_type == 1 ? { Menu: CustomControl } : ""}
                  focus={!!formError?.branch}
                  error={formError?.branch}
                  selectedOption={formOrderName?.branch}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, branch: data });
                    handleOrderChange("branch", data?.value);
                  }}
                />

              </div>
              <div className="anvOt_inputField ">

                 <CustomDatePicker
                      value={formOrderName?.pickup_date}
                      max={formatDate(new Date())}
                      name="pickup_date"
                      label="Pickup Date"
                      type="date"
                      focus={!!formError?.pickup_date}
                      error={formError.pickup_date}
                      onSelect={({ target: { name, value } }) => {
                        handleOrderChange(name, value);
                        setFormOrderName({
                          ...formOrderName,
                          pickup_date: value,
                        });
                      }}
                      className="date-input"
                    />

              </div>
              <div className="anvOt_inputField ">
                
                    <CustomDatePicker
                      value={formOrderName?.drop_date}
                      max={formatDate(new Date())}
                      name="drop_date"
                      label="Drop Date"
                      type="date"
                      focus={!!formError?.drop_date}
                      error={formError.drop_date}
                      onSelect={({ target: { name, value } }) => {
                        handleOrderChange(name, value);
                        setFormOrderName({
                          ...formOrderName,
                          drop_date: value,
                        });
                      }}
                      className="date-input"
                    />
                  
              </div>
              <div className="anvOt_outerFull ">
                <div className="anvOt_inputField ">

                  <Dropdown
                    placeholder="Select Route"
                    className="dropdownoforder"
                    name="from_location"
                    label="Route"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    options={routeList ? routeList : []}
                    valueText="id"
                    labelText="route_name"
                    components={userLogin?.user_type == 1 ? { Menu: CustomControl } : ""}
                    focus={!!formError?.route}
                    error={formError.route}
                    selectedOption={formOrderName?.route}
                    disabled={!formOrderValue.customer}
                    setSelectedOption={(data) => {
                      handleOrderChange("route", data.value);
                      setFormOrderName({ ...formOrderName, route: data });
                    }}
                  />

                </div>
              </div>

              {routeDetails && (
                <div className="anvOt_outerFull ">
                  <div className="locationDetails">
                    <span>Pickup Location</span>
                    <span>
                      {locationDetails(routeDetails?.pickup_location_detail)}
                    </span>
                  </div>
                </div>
              )}

              {routeDetails && (
                <div className="anvOt_outerFull ">
                  <div className="locationDetails">
                    {" "}
                    <span>Drop Location</span>
                    <span>
                      {locationDetails(routeDetails?.drop_location_detail)}
                    </span>
                  </div>
                </div>
              )}

              <div className="anvOt_inputField ">

                <Dropdown
                  placeholder="Select Product"
                  className="dropdownoforder"
                  name="product"
                  label="Product"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  options={productList ? productList : []}
                  valueText="id"
                  labelText="name"
                  components={{ Menu: CustomControl }}
                  focus={!!formError?.product}
                  error={formError.product}
                  selectedOption={formOrderName?.product}
                  setSelectedOption={(data) => {
                    handleOrderChange("product", data.value);
                    setFormOrderName({ ...formOrderName, product: data });
                    setProductStage(data.data?.product_type);
                  }}
                />

              </div>
              <div className="anvOt_inputField ">
                <div className="dropdaonAndselect">
                  <div className="anvSpecificDiv">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Qty" : "Qty"}
                      labelClass="FormLableKaran"
                      placeholder=""
                      name="qty"
                      type="text"
                      maxLength={10}
                      onKeyDown={handleKeyDown}
                      data={formOrderName?.product}
                      focus={!!formError?.qty}
                      error={formError.qty}
                      value={formOrderValue?.qty}
                      onChange={({ target: { name, value } }) =>
                        handleOrderChange(name, value)
                      }
                    />
                  </div>
                </div>
              </div>

              {productStage === 2 && (
                <div className="anvOt_outerFull ">
                  <div className="anvOt_inputField ">

                    <Dropdown
                      placeholder="Select Border"
                      className="dropdownoforder"
                      name="exit_border"
                      label="Exit Border"
                      classNamePrefix="_anvFloatSelect"
                      boxClass="_anvFloatedSelect"
                      options={borderList ? borderList : []}
                      valueText="id"
                      labelText="name"
                      components={{ Menu: CustomControl }}
                      focus={!!formError?.exit_border}
                      error={formError.exit_border}
                      selectedOption={formOrderName?.exit_border}
                      setSelectedOption={(data) => {
                        handleOrderChange("exit_border", data.value);
                        setFormOrderName({
                          ...formOrderName,
                          exit_border: data,
                        });
                      }}
                    />

                  </div>
                </div>
              )}
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Remark" : "Remark"}
                  labelClass="FormLableKaran"
                  placeholder="Add Remark"
                  name="remark"
                  type="text"
                  focus={!!formError?.remark}
                  error={formError.remark}
                  value={formOrderValue?.remark}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
          }
        />
      )} */}

      <InputModal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
          clearAll();
          setPreview("");
          setIsEdit(false);
        }}
        headerName={isEdit ? "Edit Order" : "Add Order"}
        submitLabel="Fleet Allocation"
        cancelbtn_mdllcls="cancelbtn_mdllcls"
        onSubmit={(e) => onOrderSubmit(e, "Admin")}
        className="_ps_mrModal"
        inputProps={
          <>
            <div className="llt_krn_mdl">
              <Dropdown
                className="dropdownoforder orderModal_Input mb-1"
                placeholder="Select Customer"
                label="Customer Name"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect lltMarginBtm"
                name="customer"
                options={customerList ? customerList : []}
                valueText="id"
                labelText="name"
                components={
                  userLogin?.user_type == 1 ? { Menu: CustomControl } : ""
                }
                focus={!!formError?.customer}
                error={formError?.customer}
                selectedOption={formOrderName.customer}
                setSelectedOption={(data) => {
                  setFormOrderName({ ...formOrderName, customer: data });
                  handleOrderChange("customer", data.value);
                }}
              />

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={
                    isEdit ? "Customer Reference No" : "Customer Reference No"
                  }
                  labelClass=""
                  placeholder="Reference No"
                  name="customer_reference_number"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.customer_reference_number}
                  error={formError.customer_reference_number}
                  value={formOrderValue?.customer_reference_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              {userLogin && userLogin?.user_branches?.length <= 1 ? (
                <></>
              ) : (
                <Dropdown
                  className="dropdownoforder orderModal_Input mb-1"
                  placeholder="Select Branch"
                  label="Branch Name"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect lltMarginBtm"
                  name="branch"
                  options={branchList ? branchList : []}
                  valueText="id"
                  labelText="name"
                  components={
                    userLogin?.user_type == 1 ? { Menu: CustomControl } : ""
                  }
                  focus={!!formError?.branch}
                  error={formError?.branch}
                  selectedOption={formOrderName?.branch}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, branch: data });
                    handleOrderChange("branch", data?.value);
                  }}
                />
              )}
              <Dropdown
                placeholder="Select Product"
                className="dropdownoforder orderModal_Input mb-1"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect lltMarginBtm"
                label="Select Product"
                name="driver"
                options={productList ? productList : []}
                valueText="id"
                labelText="name"
                components={{ Menu: CustomControl }}
                focus={!!formError?.product}
                error={formError.product}
                selectedOption={formOrderName?.product}
                setSelectedOption={(data) => {
                  handleOrderChange("product", data.value);
                  setFormOrderName({ ...formOrderName, product: data });
                  setProductStage(data.data?.product_type);
                }}
              />

              <div className="anvOt_inputField ">
                <div className="dropdaonAndselect">
                  <div className="anvSpecificDiv">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Qty" : "Qty"}
                      labelClass="FormLableKaran"
                      placeholder=""
                      name="qty"
                      type="text"
                      maxLength={10}
                      onKeyDown={handleKeyDown}
                      data={formOrderName?.product}
                      focus={!!formError?.qty}
                      error={formError.qty}
                      value={formOrderValue?.qty}
                      onChange={({ target: { name, value } }) =>
                        handleOrderChange(name, value)
                      }
                    />
                  </div>
                </div>
              </div>

              <DropdownWithButton
                placeholder="Select Route"
                className="dropdownoforder"
                name="from_location"
                label="Route"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect llt_float_select"
                options={routeList ? routeList : []}
                valueText="id"
                labelText="route_name"
                components={
                  userLogin?.user_type == 1 ? { Menu: CustomControl } : ""
                }
                focus={!!formError?.route}
                error={formError.route}
                selectedOption={formOrderName?.route}
                disabled={!formOrderValue.customer}
                setSelectedOption={(data) => {
                  handleOrderChange("route", data.value);
                  setFormOrderName({ ...formOrderName, route: data });
                }}
                isViewVisible={true}
                onViewOption={handleViewOption}
              />
              <div className="llt_odr_mdl_bx">
                <div className="llt_mdl_bx_flex">
                  <p>Loading Point</p>
                  {routeDetails && (
                    <h6>
                      {routeDetails?.route_location_details?.from_locations}
                    </h6>
                  )}
                </div>
                <div className="llt_mdl_bx_flex">
                  <p>Border</p>
                  {routeDetails && (
                    <h6>
                      {routeDetails?.route_location_details?.exit_borders}
                    </h6>
                  )}
                </div>
                <div className="llt_mdl_bx_flex">
                  <p>Offloading Point</p>
                  {routeDetails && (
                    <h6>
                      {routeDetails?.route_location_details?.to_locations}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />

      {isOpenClient && (
        <CustomOffcanvas
          isOpen={isOpenClient}
          toggle={() => {
            setIsOpenClient(!isOpenClient);
            clearAll();
            setPreview("");

            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e, "client")}
          title={isEdit ? "Edit Requirement" : "Add Requirement"}
          submitLabel={isEdit ? "Update Requirement" : "Create Requirement"}
          inputProps={
            <div className="anvOt_gridBx ">
              <div className="anvOt_outerFull ">
                <FormGroup tag="fieldset" className="orderRadio">
                  <FormGroup check></FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        onClick={() => setRouteValue(1)}
                        checked={routeValue === 1}
                      />{" "}
                      One Way
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        onClick={() => setRouteValue(2)}
                        checked={routeValue === 2}
                      />{" "}
                      Round Way
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Customer Name</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Customer"
                    // isMulti={false}
                    name="customer"
                    options={customerList ? customerList : []}
                    valueText="id"
                    labelText="name"
                    components={{ Menu: CustomControl }}
                    focus={!!formError?.customer}
                    error={formError?.customer}
                    selectedOption={formOrderName.customer}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, customer: data });
                      handleOrderChange("customer", data.value);
                    }}
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={
                    isEdit
                      ? "Loading / Customer Reference No."
                      : "Loading / Customer Reference No."
                  }
                  labelClass="FormLableKaran"
                  placeholder="Reference No"
                  name="customer_reference_number"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.customer_reference_number}
                  error={formError.customer_reference_number}
                  value={formOrderValue?.customer_reference_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <div>
                  <span>
                    <Label className="FormLableKaran">Pickup Date</Label>
                  </span>
                  <div>
                    <CustomDatePicker
                      value={formOrderName?.pickup_date}
                      max={formatDate(new Date())}
                      name="pickup_date"
                      type="date"
                      focus={!!formError?.pickup_date}
                      error={formError.pickup_date}
                      onSelect={({ target: { name, value } }) => {
                        handleOrderChange(name, value);
                        setFormOrderName({
                          ...formOrderName,
                          pickup_date: value,
                        });
                      }}
                      className="date-input"
                      // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                      // className="date-input"
                    />
                  </div>
                </div>
              </div>
              <div className="anvOt_inputField ">
                <div>
                  <span>
                    <Label className="FormLableKaran">Drop Date</Label>
                  </span>
                  <div>
                    <CustomDatePicker
                      value={formOrderName?.drop_date}
                      max={formatDate(new Date())}
                      name="drop_date"
                      type="date"
                      focus={!!formError?.drop_date}
                      error={formError.drop_date}
                      onSelect={({ target: { name, value } }) => {
                        handleOrderChange(name, value);
                        setFormOrderName({
                          ...formOrderName,
                          drop_date: value,
                        });
                      }}
                      className="date-input"
                      // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                      // className="date-input"
                    />
                  </div>
                </div>
              </div>
              <div className="anvOt_outerFull ">
                <div className="anvOt_inputField ">
                  <span>
                    <Label className="FormLableKaran">Route</Label>
                  </span>
                  <div>
                    <Dropdown
                      placeholder="Select Trip Route"
                      className="dropdownoforder"
                      name="from_location"
                      options={routeList ? routeList : []}
                      valueText="id"
                      labelText="route_name"
                      components={{ Menu: CustomControl }}
                      focus={!!formError?.route}
                      error={formError.route}
                      selectedOption={formOrderName?.route}
                      disabled={!formOrderValue.customer}
                      setSelectedOption={(data) => {
                        handleOrderChange("route", data.value);
                        setFormOrderName({ ...formOrderName, route: data });
                      }}
                    />
                  </div>
                </div>
              </div>

              {routeDetails && (
                <div className="anvOt_outerFull ">
                  <div className=" ">
                    <span>
                      <Label className="FormLableKaran">From Location</Label>
                    </span>
                    <div>
                      {routeDetails?.pickup_location_detail?.map(
                        (item, index) => (
                          <div className="location_margin">
                            <Dropdown
                              placeholder="Select From Location"
                              className="dropdownoforder"
                              name="from_location"
                              // options={item ? item : []}
                              disabled
                              valueText="id"
                              labelText="name"
                              components={
                                userLogin?.user_type == 1
                                  ? { Menu: CustomControl }
                                  : ""
                              }
                              focus={!!formError?.route}
                              error={formError.route}
                              selectedOption={{
                                label: item?.name,
                                value: item?.id,
                              }}
                              // disabled={!formOrderValue.customer}
                              setSelectedOption={(data) => {
                                handleOrderChange("route", data.value);
                                setFormOrderName({
                                  ...formOrderName,
                                  route: data,
                                });
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              {routeDetails && (
                <div className="anvOt_outerFull ">
                  <div className=" ">
                    <span>
                      <Label className="FormLableKaran">To Location</Label>
                    </span>
                    <div>
                      {routeDetails?.drop_location_detail?.map(
                        (item, index) => (
                          <div className="location_margin">
                            <Dropdown
                              placeholder="Select To Location"
                              className="dropdownoforder"
                              name="to_location"
                              options={routeList ? routeList : []}
                              valueText="id"
                              labelText="route_name"
                              disabled
                              components={
                                userLogin?.user_type == 1
                                  ? { Menu: CustomControl }
                                  : ""
                              }
                              selectedOption={{
                                label: item?.name,
                                value: item?.id,
                              }}
                              // disabled={!formOrderValue.customer}
                              setSelectedOption={(data) => {
                                handleOrderChange("route", data.value);
                                setFormOrderName({
                                  ...formOrderName,
                                  route: data,
                                });
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Product</Label>
                </span>
                <div>
                  <Dropdown
                    placeholder="Select Product"
                    className="dropdownoforder"
                    name="product"
                    options={productList ? productList : []}
                    valueText="id"
                    labelText="name"
                    components={{ Menu: CustomControl }}
                    focus={!!formError?.product}
                    error={formError.product}
                    selectedOption={formOrderName?.product}
                    setSelectedOption={(data) => {
                      handleOrderChange("product", data.value);
                      setFormOrderName({ ...formOrderName, product: data });
                      setProductStage(data.data?.product_type);
                      // setProductType()
                    }}
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <div className="dropdaonAndselect">
                  <div className="anvSpecificDiv">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Qty" : "Qty"}
                      labelClass="FormLableKaran"
                      placeholder=""
                      name="qty"
                      type="text"
                      maxLength={10}
                      onKeyDown={handleKeyDown}
                      data={formOrderName?.product}
                      focus={!!formError?.qty}
                      error={formError.qty}
                      value={formOrderValue?.qty}
                      onChange={({ target: { name, value } }) =>
                        handleOrderChange(name, value)
                      }
                    />
                  </div>
                  {/* <select name="cars" id="cars" className="qntSelect">
                                    <option value="ltr">LTR</option>
                                    <option value="kg">KG</option>
                                    <option value="barrel">Barrel</option>
                                </select> */}
                </div>
              </div>

              {productStage === 2 && (
                <div className="anvOt_outerFull ">
                  <div className="anvOt_inputField ">
                    <span>
                      <Label className="FormLableKaran">Exit Border</Label>
                    </span>
                    <div>
                      <Dropdown
                        placeholder="Select Border"
                        className="dropdownoforder"
                        name="exit_border"
                        options={borderList ? borderList : []}
                        valueText="id"
                        labelText="name"
                        components={{ Menu: CustomControl }}
                        focus={!!formError?.exit_border}
                        error={formError.exit_border}
                        selectedOption={formOrderName?.exit_border}
                        // disabled={!formOrderValue.exit_border}
                        setSelectedOption={(data) => {
                          handleOrderChange("exit_border", data.value);
                          setFormOrderName({
                            ...formOrderName,
                            exit_border: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="anvOt_outerFull ">
                <div className=" ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Remark" : "Remark"}
                    labelClass="FormLableKaran"
                    placeholder="Add Remark"
                    name="remark"
                    type="textarea"
                    rows={3}
                    focus={!!formError?.remark}
                    error={formError.remark}
                    value={formOrderValue?.remark}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
              </div>
            </div>
          }
        />
      )}
      <Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => (all ? cancelAllCheckBox() : handleDelete())}
      />
      <Modal size="md" isOpen={openFleet} backdrop keyboard centered={true}>
        <ModalHeader toggle={() => isOpenFleet(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody> Are you sure you want to delete </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => isOpenFleet(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => deleteHandle(indexOfFleet)}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="md" isOpen={openDownload} backdrop keyboard centered={true}>
        <ModalHeader toggle={() => setOpenDownload(false)}>
          Download Excel
        </ModalHeader>
        <ModalBody className="csvModalBody">
          <FormGroup tag="fieldset" className="RadioButton">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  onClick={() => setDownload(1)}
                />{" "}
                Dry Cargo
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  onClick={() => setDownload(2)}
                />{" "}
                Tankers
              </Label>
            </FormGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpenDownload(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleExportAction}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      {allotedisOpen && (
        <AllocateVehicleModal
          isOpen={allotedisOpen}
          fleetList={fleetList}
          filterFleet={filterFleet}
          setSearchKey={setSearchKey}
          searchKey={searchKey}
          toggle={() => {
            setAllotedisOpen(false);
            setSelectTrailerList([]);
            setFleetProductType({ type: "", orderId: "", branch_uuid:"",customer_name: "", order_number:""  });
            setSearchKey("");
            setFilterFleet([]);
            setFilterFleetPagi([]);
          }}
          handlePageClick={handlePageClickModal}
          filterFleetPagi={filterFleetPagi}
          toggle2={() => {
            setIsOpenModal(!isOpenModal);

            // setFleetProductType('')
          }}
          callBackData={callBackData}
          selectTrailerList={selectTrailerList}
          deleteHandle={deleteHandle}
          sendNoninationHandle={sendNoninationHandle}
          deleteFleetHandle={deleteFleetHandle}
          OrderID={OrderID}
          loaddingSend={loaddingSend}
          isLoading={isAllModLoadding}
          filterModal={filterModal}
          etaDataList={etaDataList}
          headerData={fleetProductType}
          searchInput={
            <>
              <div className="anvTask_prog">
                <div className="anvInputs&fillters">
                  <div className="inr_flex allocateSearch">
                    <InputField
                      placeholder="Search"
                      className="anvSearch_input"
                      value={searchKey}
                      onChange={handleSearchChange}
                      searchableInput={true}
                    />
                  </div>

                  <div className="">
                    <button
                      className="anime-btn reset_button"
                      onClick={() => handleAllocateModalReset()}
                    >
                      Reset
                    </button>
                  </div>
                  {/* <div>

                  <button className={`Fleet_filter_button ${!filterValue ? "fleet_active" : ""}`} onClick={() => setTabFilterValue(false)}>
                    Intransit
                  </button>
                  <button className={`Fleet_filter_button ${filterValue ? "fleet_active" : ""}`} onClick={() => {

                    setTabFilterValue(true)
                  }}>Available</button>
                </div> */}
                </div>
              </div>
            </>
          }
        />
      )}

      {openModal?.branch && (
        <InputModal
          isOpen={openModal?.branch}
          headerName={isEdit ? "Edit Branch" : "Add Branch"}
          toggle={() =>
            setOpenModal({ ...openModal, branch: !openModal?.branch })
          }
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit("branch")}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Branch" : "Branch Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Branch Name"
              name="addBranch"
              focus={!!formError.addBranch}
              error={formError.addBranch}
              value={formOrderValue.addBranch}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}

      {openModal?.exitBorder && (
        <InputModal
          isOpen={openModal?.exitBorder}
          headerName={isEdit ? "Edit Exit Border" : "Add Exit Border"}
          toggle={() =>
            setOpenModal({ ...openModal, exitBorder: !openModal?.exitBorder })
          }
          submitLabel={isEdit ? "Update " : "Continue"}
          onSubmit={(e) => addNewSubmit("ExitBorder")}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Exit Border" : "Exit Border Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Exit Border Name"
              name="addBorder"
              focus={!!formError.addBorder}
              error={formError.addBorder}
              value={formOrderValue.addBorder}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}

      {openModal?.product && (
        <InputModal
          isOpen={openModal?.product}
          headerName={isEdit ? "Edit Product" : "Add Product"}
          toggle={() =>
            setOpenModal({ ...openModal, product: !openModal?.product })
          }
          submitLabel={isEdit ? "Update Country" : "Continue"}
          onSubmit={(e) => addNewSubmit("product")}
          // disabled={isEdit ? (JSON.stringify(inputValue) === JSON.stringify(disableInp)) : ""}
          inputProps={
            <>
              <InputField
                className="modal_input"
                label={isEdit ? "Edit Product" : "Product Name"}
                labelClass="modal_label"
                labelSpan="*"
                labelSpanClass="style_for_star"
                placeholder="Product Name"
                name="productName"
                focus={!!formError.productName}
                error={formError.productName}
                value={formOrderValue.productName}
                onChange={({ target: { name, value } }) =>
                  handleOrderChange(name, value)
                }
              />
              <div className="ProductSpace">
                <Dropdown
                  placeholder="Liquid/Dry"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  label="Product Type"
                  name="product_type"
                  options={productTypeList}
                  focus={!!formError.product_type}
                  error={formError.product_type}
                  selectedOption={productType}
                  setSelectedOption={(data) => {
                    handleOrderChange("product_type", data?.value);
                    setProductType(data);
                  }}
                  valueText="value"
                  labelText="label"
                />
              </div>
              <div>
                <Dropdown
                  className="dropdownoforder"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  label="Unit Type"
                  placeholder="LTR/KG"
                  name="unit"
                  options={unitList ? unitList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!formError.unit}
                  error={formError.unit}
                  selectedOption={unitType}
                  setSelectedOption={(data) => {
                    handleOrderChange("unit", data?.value);
                    setUnitType(data);
                  }}
                />
              </div>
            </>
          }
        />
      )}

      {openRoute && (
        <CommonRoute
          isOpen={openRoute}
          setIsOpen={setOpenRoute}
          routeCallBack={routeCallBack}
          customerValue={formOrderName?.customer}
          isDisableOpen={false}
        />
      )}
      {openRoute2 && (
        <CommonSingleRoute
          isOpen={openRoute2}
          setIsOpen={setOpenRoute2}
          routeCallBack={routeClientCallBack}
          customerValue={formOrderName?.customer}
        />
      )}
      {openCustomer && (
        <CommonCustomer
          isOpen={openCustomer}
          setIsOpen={setOpenCustomer}
          routeCallBack={routeCallBack}
        />
      )}

      {isDisablePopUp && (
        <CommonRoute
          isOpen={isDisablePopUp}
          setIsOpen={setDisablePop}
          isDisableOpen={true}
        />
      )}
    </>
  );
};
