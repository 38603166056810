import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CustomTable from "./CustomTable";

export const Tabs = ({
  TabList,
  handleActive,
  activeTab,
  customClassName,
  count,
}) => {
  return (
    <div className="">
      {/* <div className='tabMain_head'> */}
      <div
        className={
          customClassName ? `tabBtn_bx ${customClassName}` : "tabBtn_bx"
        }
      >
        {TabList?.map((i) => {
          return (
            <div className="count_label_div llt_tab_active">
              <button
                key={i.label}
                className={(activeTab === i.value ? "active" : "") + " btnOpt"}
                onClick={() => {
                  handleActive(i);
                }}
              >
                {i.label}
              </button>
              {i.count !== undefined && (
                <div
                  onClick={() => {
                    handleActive(i);
                  }}
                  className="count_number_div"
                >
                  <span>{i.count}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* </div> */}

      <div className="tabContent">
        <div className="filterBx">
          <div className="filterBtn"></div>
        </div>
      </div>

      {/* <Nav tabs>
                <NavItem>
                    <NavLink

                        active={active}
                        onClick={() => {
                            setActive('tab1')
                            setActiveClass(true)
                            setActiveClass1(false);
                        }}
                    >
                        Tab 1
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active1}
                        onClick={() => {
                            setActive('tab2')
                            setActiveClass(false)
                            setActiveClass1(true);
                        }}
                    >
                        Tab 2
                    </NavLink>
                </NavItem> */}
      {/* Add more tabs as needed */}
      {/* </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="tab1">
                    tab1
                </TabPane>
                <TabPane tabId="tab2">
                    Tab 2 content
                </TabPane> */}
      {/* Add more tab panes as needed */}
      {/* </TabContent> */}
    </div>
  );
};
