import { apiEndPoints, methodType, performRequest } from "./_apiConfig";

export const getAllTrips = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.trip, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllDetailsLog = (data = {}) => {
  
    return performRequest(methodType.GET, apiEndPoints.triplog, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateTripApi = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.trip + `/${data?.id}`,
        data?.formdata,
        true,
        data.FormData ? true : false
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateTripFundApi = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.trip + `/${data?.id}`,
        data?.formdata,
        true,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateTripExFuelApi = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.trip + `/${data?.id}`,
        data?.formdata,
        true,

    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleTripDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.trip + `/${data}` + `/view`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}



export const singleTripDetailsExpense = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.trip + `/${data}` + `/trip_expenses`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}



export const transationExpense = (data)=>{
    return performRequest(methodType.GET, apiEndPoints.trip + `?driver=${data}`, {}, true)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return error;
    });
}


export const getTripCsv = (tripID = {}) => {
   
    let data = {}
    return performRequest(methodType.GET, apiEndPoints.trip + `/${tripID}/trip_report`, data, true, false, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getTripExcel = (date = {}) => {
   
    let data = {}
    return performRequest(methodType.GET, apiEndPoints.tripReport + `?from_date=${date.from_date}&to_date=${date.to_date}`, data, true, false, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const verifyOtpApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.verifyOtp, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
  };



  export const getTripCountApi = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.tripCount, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const changeSubStatus = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.subStatusChange, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
  };

  export const changeMarkBorderStatus = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.markBorderStatus, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
  };
  