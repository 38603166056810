import React, { useEffect, useState } from "react";
import { NavItem, Nav } from "reactstrap";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../image/karanLogo.png";
import "./Sidebar.css";
import { constant, constant1 } from "../../helper";
import SubMenu from "./SubMenu";
import { getStorage } from "../../helper/storage";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

const Sidebar = ({ isOpen, toggle }) => {
  const [settingToggle, setSettingToggle] = useState("");
  const userLogin = getStorage("logged-in");
  const [newPermission, setNewPermission] = useState();
  const [sidebarList, setSidebarList] = useState();
  const navigate = useNavigate();
  const { is_dashboard_access } = userLogin || {};

  useEffect(() => {
    setSettingToggle(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (isOpen === false) {
      setSettingToggle("");
    }
  }, [isOpen]);

  const handleMenuClick = (item) => {
    if (!isOpen && item?.Child?.length > 0) {
      toggle();
    }

    setSettingToggle(
      item?.Child?.some((child) => child?.path === settingToggle) ||
        settingToggle === item.name
        ? ""
        : item.name
    );
  };

  const filterSidebarList = constant.sidebarArr.filter(
    (item) => item.name !== "Approval"
  );

  const permissionsArray = Object.entries(userLogin?.user_permissions).reduce(
    (itemArray, [key, value]) => {
      if (value.permissions[1]) {
        itemArray.push({
          id: parseInt(key) + 1,
          module_name: value.module_name,
          permissions: value.permissions,
        });
      }

      return itemArray;
    },
    []
  );

  const filteredArray = constant?.sidebarArr?.filter((item1) =>
    permissionsArray?.some((item2) => item1?.key === item2?.module_name)
  );
  const filteredArray1 = constant?.sidebarArr
    ?.filter((item1) => item1?.key === "Settings")[0]
    ?.Child?.filter((item2) =>
      permissionsArray?.some((item3) => item2?.name === item3?.module_name)
    );
  useEffect(() => {
    if (filteredArray?.length > 0) {
      navigate(filteredArray[0]?.path);
    }
  }, []);

  // useEffect(()=>{
  //   if(filteredArray1?.length){
  //     if(userLogin?.is_dashboard_access){
  //       const list =[{
  //         name: "Dashboard",
  //         key: "Dashboard",
  //         path: "/dashboard",
  //         logo: (
  //           <>
  //             <img src="/Images/Dashboard.svg" alt="" />
  //           </>
  //         ),
  //       },...filteredArray, {
  //         name: "Settings",
  //         key: "Settings",
  //         logo: (
  //           <>
  //             <img src="/Images/Tags.svg" alt="" />
  //           </>
  //         ),
  //         Child:filteredArray1}]
  // console.log(list)
  //         setSidebarList(list)
  //     }else{
  //       const list = [
  //       ...filteredArray, {
  //         name: "Settings",
  //         key: "Settings",
  //         logo: (
  //           <>
  //             <img src="/Images/Tags.svg" alt="" />
  //           </>
  //         ),
  //         Child:filteredArray1}]
  //         console.log(list)

  //         setSidebarList(list)
  //     }

  //   }else{
  //     if(userLogin?.is_dashboard_access){
  //       setSidebarList([{
  //         name: "Dashboard",
  //         key: "Dashboard",
  //         path: "/dashboard",
  //         logo: (
  //           <>
  //             <img src="/Images/Dashboard.svg" alt="" />
  //           </>
  //         ),
  //       },...filteredArray])
  //     }else{
  //       setSidebarList([...filteredArray])
  //     }

  //   }

  // },[filteredArray?.length>0])

  useEffect(() => {
    if (filteredArray1?.length) {
      const dashboardItem = userLogin?.is_dashboard_access
        ? [
            {
              name: "Dashboard",
              key: "Dashboard",
              path: "/dashboard",
              logo: <img src="/Images/Dashboard.svg" alt="" />,
            },
          ]
        : [];

      const settingsItem = {
        name: "Settings",
        key: "Settings",
        logo: <img src="/Images/Tags.svg" alt="" />,
        Child: filteredArray1,
      };

      const sidebarContent = [
        ...dashboardItem,
        ...filteredArray,
        ...(filteredArray1.length ? [settingsItem] : []),
      ];

      setSidebarList(sidebarContent);
    } else {
      const defaultList = [
        ...(userLogin?.is_dashboard_access
          ? [
              {
                name: "Dashboard",
                key: "Dashboard",
                path: "/dashboard",
                logo: <img src="/Images/Dashboard.svg" alt="" />,
              },
            ]
          : []),
        ...filteredArray,
      ];

      setSidebarList(defaultList);
    }
  }, [filteredArray?.length, filteredArray1?.length, is_dashboard_access]);

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <div className="sideBarLogo_div">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="side-menu">
        {/* { permissionsArray.length >0 ? <Nav vertical className="list-unstyled pb-3 pt-2">
          {(userLogin?.user_type === 2 ? !userLogin?.is_web_accessible ? filterSidebarList : constant1.sidebarArr: !userLogin?.is_web_accessible? filterSidebarList
            : constant.sidebarArr
          )?.map((item, i) => {
          
            return [{id
              :  1,
              module_name
              : 
              "Dashboard",
              permissions
              : 
              {1: true, 2: true, 3: true, 4: true}},...permissionsArray].some(e=>e.module_name === item.key)?
             (
              <>
                {
                  <NavItem key={item.path}>
                    {item?.Child?.length > 0 ? (
                      <button
                        onClick={() =>
                          setSettingToggle(
                            item?.Child?.some(
                              (child) => child?.path === settingToggle
                            ) || settingToggle === item.name
                              ? ""
                              : item.name
                          )
                        }
                        className={
                          (item?.Child?.some(
                            (child) => child?.path === settingToggle
                          ) || settingToggle === item.name
                            ? "active "
                            : "") + " nav-link"
                        }
                        key={i}
                        to={item.path}
                      >
                        <span className="sidebar_logo">{item.logo}</span>
                        <span>{item.name}</span>
                      </button>
                    ) : (
                      <Link
                        className={
                          (settingToggle === item.path ? "active " : "") +
                          " nav-link"
                        }
                        key={i}
                        to={item.path}
                      >
                        <span className="sidebar_logo">{item.logo}</span>
                        <span>{item.name}</span>
                      </Link>
                    )}
                    {(item?.Child?.some(
                      (child) => child?.path === settingToggle
                    ) ||
                      settingToggle === item.name) && (
                      <div className="anvSubmenu">
                        {item?.Child && (
                          <>
                            <Nav vertical className="list-unstyled pb-3 pt-2">
                              {item?.Child?.map((item, i) => (
                                <NavItem key={item.path}>
                                  <Link
                                    className={
                                      (settingToggle === item.path
                                        ? "active "
                                        : "") + " nav-link"
                                    }
                                    key={i}
                                    to={item.path}
                                  >
                                    <span>{item.name}</span>
                                  </Link>
                                </NavItem>
                              ))}
                            </Nav>
                          </>
                        )}
                      </div>
                    )}
                  </NavItem>
                }
              </>
            ):<></>}
          )}
        </Nav>:
        <Nav vertical className="list-unstyled pb-3 pt-2">
          {(userLogin?.user_type === 2 ? !userLogin?.is_web_accessible ? filterSidebarList:constant1.sidebarArr : !userLogin?.is_web_accessible?filterSidebarList:constant.sidebarArr)?.map((item, i) => {
          
            return (
              <>
                <NavItem key={item.path}>
                  {item?.Child?.length > 0 ? (
                    <button
                      onClick={() =>
                        setSettingToggle(
                          item?.Child?.some(
                            (child) => child?.path === settingToggle
                          ) || settingToggle === item.name
                            ? ""
                            : item.name
                        )
                      }
                      className={
                        (item?.Child?.some(
                          (child) => child?.path === settingToggle
                        ) || settingToggle === item.name
                          ? "active "
                          : "") + " nav-link"
                      }
                      key={i}
                      to={item.path}
                    >
                      <span className="sidebar_logo">{item.logo}</span>
                      <span>{item.name}</span>
                    </button>
                  ) : (
                    <Link
                      className={
                        (settingToggle === item.path ? "active " : "") +
                        " nav-link"
                      }
                      key={i}
                      to={item.path}
                    >
                      <span className="sidebar_logo">{item.logo}</span>
                      <span>{item.name}</span>
                    </Link>
                  )}
                  {(item?.Child?.some(
                    (child) => child?.path === settingToggle
                  ) ||
                    settingToggle === item.name) && (
                      <div className="anvSubmenu">
                        {item?.Child && (
                          <>
                            <Nav vertical className="list-unstyled pb-3 pt-2">
                              {item?.Child?.map((item, i) => (
                                <NavItem key={item.path}>
                                  <Link
                                    className={
                                      (settingToggle === item.path
                                        ? "active "
                                        : "") + " nav-link"
                                    }
                                    key={i}
                                    to={item.path}
                                  >
                                    <span>{item.name}</span>
                                  </Link>
                                </NavItem>
                              ))}
                            </Nav>
                          </>
                        )}
                      </div>
                    )}
                </NavItem>
              </>
            );
          })}
        </Nav>} */}

        <Nav vertical className="list-unstyled pb-3 pt-2">
          {/* { (userLogin?.user_type === 1 ? constant?.sidebarArr  : userLogin?.user_type === 2 ? constant1?.sidebarArr: sidebarList?.length>1 ? sidebarList:constant1?.sidebarArr)?.map((item, i) => { */}
          {(userLogin?.user_type === 1
            ? constant?.sidebarArr
            : sidebarList
          )?.map((item, i) => {
            return (
              <>
                <NavItem key={item?.path}>
                  {item?.Child?.length > 0 ? (
                    <button
                      // onClick={() =>
                      //   setSettingToggle(
                      //     (item?.Child)?.some(
                      //       (child) => child?.path === settingToggle
                      //     ) || settingToggle === item.name
                      //       ? ""
                      //       : item.name
                      //   )
                      // }
                      onClick={() => handleMenuClick(item)}
                      className={
                        (item?.Child?.some(
                          (child) => child?.path === settingToggle
                        ) || settingToggle === item.name
                          ? "active "
                          : "") + " nav-link"
                      }
                      key={i}
                      to={item?.path}
                    >
                      <span className="sidebar_logo">{item?.logo}</span>
                      <span className="_xText">{item?.name}</span>
                      <span className="menu-arrow" style={{ fontSize: "24px" }}>
                        {settingToggle === item.name ? (
                          <MdOutlineKeyboardArrowUp />
                        ) : (
                          <MdOutlineKeyboardArrowDown />
                        )}
                      </span>
                    </button>
                  ) : (
                    <Link
                      className={
                        (settingToggle === item?.path ? "active " : "") +
                        " nav-link"
                      }
                      key={i}
                      to={item?.path}
                    >
                      <span className="sidebar_logo">{item?.logo}</span>
                      <span className="_xText">{item?.name}</span>
                    </Link>
                  )}
                  {(item?.Child?.some(
                    (child) => child?.path === settingToggle
                  ) ||
                    settingToggle === item?.name) && (
                    <div className="anvSubmenu">
                      {item?.Child && (
                        <>
                          <Nav vertical className="list-unstyled pb-3 pt-2">
                            {item?.Child?.map((item, i) => (
                              <NavItem key={item?.path}>
                                <Link
                                  className={
                                    (settingToggle === item?.path
                                      ? "active "
                                      : "") + " nav-link"
                                  }
                                  key={i}
                                  to={item?.path}
                                >
                                  <span>{item?.name}</span>
                                </Link>
                              </NavItem>
                            ))}
                          </Nav>
                        </>
                      )}
                    </div>
                  )}
                </NavItem>
              </>
            );
          })}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
