import React, { useEffect, useState } from "react";
import { Label, Modal } from "reactstrap";
import { components } from "react-select";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch } from "../../services";
import { toast } from "react-toastify";
import {
  addCustomerFullValiadtion,
  addCustomerValiadtion,
  addEditCustomerValiadtion,
  addResearchSchema,
  editCustomerFullValiadtion,
  modalFileSchema,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  addCustomerTabList,
  checkFormError,
  customerTabList,
  formatDate,
  handleKeyDown,
  pageCount,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";

import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";

import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  addCustomer,
  deleteCustomer,
  getAllCustomer,
  singleCustomerDetails,
  updateActiveCustomer,
  updateActiveResearch,
  updateCustomer,
  uploadExcelCustomer,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { addCountryApi, getCountry } from "../../services/countryService";
import mytruck from "../../../image/tanker.png";
import { addCurrencyApi, getCurrency } from "../../services/currencyService";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { Menu, MenuItem, Typography } from "@mui/material";
import { getStorage } from "../../helper/storage";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

export const Customer = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    name: "",
    email: "",
    mobile_number: "",
  };

  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };
  const currencyObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    email: "",
    branch: "",
    name: "",
    is_web_access: false,
    mobile_number: "",
    password: "",
    confirm_password: "",
    taxation_number: "",
    credit_limit: "",
    currency: "",
    contract: null,
    address_one: "",
    address_two: "",
    country: "",
    city: "",
    addBranch: "",
    addCurrency: "",
    addCountry: "",
    openName: "",
    is_active: true,
    count: "",
  };
  const orderObjName = {
    email: "",
    branch: "",
    name: "",
    is_web_access: false,
    mobile_number: "",
    password: "",
    confirm_password: "",
    taxation_number: "",
    credit_limit: "",
    currency: "",
    contract: null,
    address_one: "",
    address_two: "",
    country: "",
    city: "",
    addBranch: "",
    addCurrency: "",
    addCountry: "",
    openName: "",
    is_active: true,
    count: "",
  };
  const userLogin = getStorage("logged-in");
  const result = userLogin?.user_branches?.join(",");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [activeModalTab, setActiveModalTab] = useState(1);
  const [offsetbranch, setoffsetBranch] = useState(0);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    branch: result,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [initialformOrderValue, setInitialFormOrderValue] = useState(orderObj);

  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [countryList, setCountryList] = useState();
  const [currencyList, setCurrencyList] = useState();
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [inputValue, setInputValue] = useState({
    excel_file: "",
  });
  const [inputError, setInputError] = useState({
    excel_file: "",
  });
  const [disableInp, setDisableInp] = useState({
    excel_file: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [editAction, setEditAction] = useState("");
  const [response, setResponse] = useState("");
  const [itemId, setItemId] = useState("");
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseSelectMenu = (e) => {
    handleCloseUserMenu();
  };

  useEffect(() => {
    if (response) {
      if (editAction === "details") {
        setIsOpen(true);
      }

      if (editAction === "password") {
        setIsOpenPassword(true);
      }
    }
  }, [response]);

  const Requirement = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },

    {
      heading: "Branch Name",
      className: "sorting",
      value: "branchName",
      sortName: "",
    },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "name",
      sortName: "",
    },
    {
      heading: "Email ID",
      className: "sorting_asc",
      value: "email",
      sortName: "",
    },
    // { heading: "In-Transit", className: "sorting_asc", value: "In_Transit" },
    {
      heading: "Last Login",
      className: "sorting_asc",
      value: "lastlogin",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const Pending = [
    { heading: "Date", className: "sorting", value: "Date" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "Customer_Name",
    },
    { heading: "Route", className: "sorting_asc", value: "Route" },
    { heading: "Status", className: "sorting_asc", value: "status" },
    { heading: "Product", className: "sorting_asc", value: "Product" },
    { heading: "Requirement", className: "sorting_asc", value: "Requirement" },
    { heading: "Placed", className: "sorting_asc", value: "Placed" },
    { heading: "Balance", className: "sorting_asc", value: "Balance" },
    { heading: "Action", className: "sorting_asc", value: "action" },
  ];
  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      status: (
        <div className="statusofOrder">
          <span>Pending</span>
        </div>
      ),
      serial: i + 1,
      branchName: item?.branch_names?.map((branch) =>
        item?.branch_names?.length > 1 ? `${branch.name} , ` : branch.name
      ),

      lastlogin: item?.last_login ? item?.last_login : "User Not Login",

      action: (
        <>
          <div className="actionBtn">
            <div className="customerStatus">
              <SwitchField
                onSwitchChange={() => updateTagStatus(item)}
                value={item?.is_active}
              />
              {item?.is_active ? <span>Disable</span> : <span>Enable</span>}
            </div>
            <Menu
              className="other_menu"
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={(e) => {
                  setEditAction("details");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Customer
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setEditAction("password");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Password
              </MenuItem>
            </Menu>
            <span className="_faEdit" onClick={(e) => { setItemId(item); handleOpenUserMenu(e); }}><FiEdit /></span>
            {/* <img
              src={EditIcon}
              alt=""
              onClick={(e) => {
                setItemId(item);
                handleOpenUserMenu(e);
              }}
            /> */}

            <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>
            {/* <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            /> */}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return (userLogin?.user_type !== 1 && userLogin?.user_branches?.length === 0) ? [] : listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAll", debouncedFilter],
    () => getAllCustomer(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getBranchList();
    getCountryList();
    getCurrencyList();
  }, []);

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };

  const getCountryList = async () => {
    const res = await getCountry(countryObj);
    setCountryList(res?.results);
  };

  const getCurrencyList = async () => {
    const res = await getCurrency(currencyObj);

    setCurrencyList(res?.results);
  };

  const clearAll = () => {
    setFormOrderValue({
      email: "",
      branch: "",
      name: "",
      is_web_access: false,
      mobile_number: "",
      password: "",
      confirm_password: "",
      taxation_number: "",
      credit_limit: "",
      currency: "",
      contract: null,
      address_one: "",
      address_two: "",
      country: "",
      city: "",
      addBranch: "",
      addCurrency: "",
      addCountry: "",
      openName: "",
      is_active: true,
    });
    setFormOrderName({
      email: "",
      branch: "",
      name: "",
      is_web_access: false,
      mobile_number: "",
      password: "",
      confirm_password: "",
      taxation_number: "",
      credit_limit: "",
      currency: "",
      contract: "",
      address_one: "",
      address_two: "",
      country: "",
      city: "",
      addBranch: "",
      addCurrency: "",
      addCountry: "",
      openName: "",
      is_active: true,
    });
    setPreview("");

    setFormError("");
    setInputError("");
    setInputValue({
      excel_file: "",
    });

    setResponse("");
    setEditAction("");
    setIsOpenPassword(false);
  };

  const closeModal = () => {
    // setIsOpen(true);
    setIsEdit(false);
    setFormValue({
      number: "",
      date: new Date(),
    });
    setFormValue("");
    setFormError("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addCustomerFullValiadtion);
      setValidationError(error);
    }
  };

  const validationHnalde = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      isEdit ? addEditCustomerValiadtion : addCustomerValiadtion
    );

    setValidationError(validationResult);
    if (validationResult === true) {
      setActiveModalTab(2);
    }
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      isEdit ? editCustomerFullValiadtion : addCustomerFullValiadtion
    );

    const payload = {};

    Object.keys(formOrderValue).map((item, index) => {
      if (formOrderValue[item] !== initialformOrderValue[item]) {
        // formData.append(item, formOrderValue[item]);

        payload[item] = formOrderValue[item];
        if (item === "branch") {
          payload["branch"] = formOrderValue?.branch?.map((i) => i.value);
        }
      }
      if (item === "is_web_access") {
        payload["is_web_access"] = formOrderValue.is_web_access;
      }
      if (item === "is_active") {
        payload["is_active"] = formOrderValue.is_active;
      }
    });


    const formData = new FormData();

    if (isEdit === true) {
      setIsLoadding(false);
      handleUpdate();
    } else {
      if (validationResult === true) {
        setIsLoadding(false);
        handleAdd(payload);
      } else {
        setIsLoadding(false);
        setFormError(validationResult);
      }
    }
  };

  const updateActiveMutation = useMutation(updateActiveCustomer, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleUploadExcel = (formData) => {
    uploadMutation.mutate(formData);
  };
  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getCustomerDetails.mutate(item?.id);
  };

  const getCustomerDetails = useMutation(singleCustomerDetails, {
    onSuccess: (res) => {
      if (res?.success) {

        setFormOrderValue({
          email: res?.results?.email,
          branch: res?.results?.branch,
          name: res?.results?.name,
          is_web_access: res?.results?.is_web_access,
          mobile_number: res?.results?.mobile_number,
          // password: res?.results?.password,
          // confirm_password: res?.results?.confirm_password,
          taxation_number: res?.results?.taxation_number,
          credit_limit: res?.results?.credit_limit,
          currency: res?.results?.currency,
          // contract: res?.results?.contract,
          address_one: res?.results?.address_one,
          address_two: res?.results?.address_two,
          country: res?.results?.country,
          city: res?.results?.city,
          is_active: res?.results?.is_active,
        });

        setInitialFormOrderValue({
          email: res?.results?.email,
          branch: res?.results?.branch,
          name: res?.results?.name,
          is_web_access: res?.results?.is_web_access,
          mobile_number: res?.results?.mobile_number,
          // password: res?.results?.password,
          // confirm_password: res?.results?.confirm_password,
          taxation_number: res?.results?.taxation_number,
          credit_limit: res?.results?.credit_limit,
          currency: res?.results?.currency,
          // contract: res?.results?.contract,
          address_one: res?.results?.address_one,
          address_two: res?.results?.address_two,
          country: res?.results?.country,
          city: res?.results?.city,
          is_active: res?.results?.is_active,
        });

        // if (name === "branch") {
        //     let details = branchList?.find((i) => i.id === id)
        //     return { data: details, label: details?.name, value: details?.id }
        // }
        const common = (name, id) => {
          if (name === "currency") {
            let details = currencyList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }

          if (name === "country") {
            let details = countryList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
        };

        setFormOrderName({
          ...formOrderName,
          branch: res?.results?.branch_names.map((item) => {
            return { label: item?.name, value: item?.id };
          }),
          currency: common("currency", res?.results?.currency),
          country: common("country", res?.results?.country),
        });

        setPreview(res?.results?.image);
        setIsEdit(true);
        setResponse(res);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    // const formData = new FormData();
    // if (isOpen === true) {
    //   Object.keys(formOrderValue).map((item, index) => {
    //     if (formOrderValue[item] !== initialformOrderValue[item]) {
    //       formData.append(item, formOrderValue[item]);
    //       if (item === "branch") {
    //         formData["branch"] = formOrderValue?.branch.map((i) => i.value);
    //       }

    //     }
    //   });
    // }

    // if (isOpenPassword === true) {
    //   Object.keys(formOrderValue).map((item, index) => {
    //     if (formOrderValue[item] !== initialformOrderValue[item]) {
    //       if (item === "branch") {
    //         formData["branch"] = formOrderValue?.branch.map((i) => i.value);
    //       }
    //       formData.append(item, formOrderValue[item]);
    //     }
    //   });
    // }
    const transformedBranches = formOrderValue.branch?.map((branch) => branch.value);
    const payload = {
      formData:{
        ...formOrderValue,
        branch: transformedBranches
      },
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addCustomer, {
    onSuccess: (res) => {
      if (res?.success) {
        setUpdateId(res?.results?.id);
        setActiveModalTab(3);
        setIsEdit(true);
        onSuccessResponse(res?.message);
        setIsOpen(false)
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const uploadMutation = useMutation(uploadExcelCustomer, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const updateMutation = useMutation(updateCustomer, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
        setIsOpen(false);
        setIsLoadding(false);
        setActiveModalTab(1);
        clearAll();
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const deleteMutation = useMutation(deleteCustomer, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAll");
    // setIsOpen(false);
    setIsOpen1(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleModalActive = (data) => {
    setActiveModalTab(data?.value);
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;
      case 2:
        return Requirement;
      case 3:
        return Requirement;
      default:
        return Requirement;
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalFileSchema);
      setInputError(error);
    }
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { excel_file: inputValue.excel_file },
      modalFileSchema
    );

    const formData = new FormData();
    formData.append("excel_file", inputValue?.excel_file);

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleUploadExcel(formData);
      }
    } else {
      setIsLoadding(false);
      setInputError(validationResult);
    }
  };

  /// ----------- add Branch ,currency and country ---------
  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Branch":
        setFormOrderValue({
          ...formOrderValue,
          addBranch: inputvalue,
          openName: "branch",
        });
        setOpenModal(true);

        break;

      case "Select Currency":
        setFormOrderValue({
          ...formOrderValue,
          addCurrency: inputvalue,
          openName: "currency",
        });
        setOpenModal(true);
        break;

      case "Select Country":
        setFormOrderValue({
          ...formOrderValue,
          addCountry: inputvalue,
          openName: "country",
        });
        setOpenModal(true);
        break;

      default:
        break;
    }
  };

  const addNewSubmit = async (value) => {
    const validationResult = await checkFormError(
      {
        name:
          value === "branch"
            ? formOrderValue.addBranch
            : value === "currency"
              ? formOrderValue?.addCurrency
              : formOrderValue.addCountry,
      },
      modalInputSchema
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAddNew(value);
      }
    } else {
      setFormError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleAddNew = (value) => {
    const payload = {
      name:
        value === "branch"
          ? formOrderValue.addBranch
          : value === "currency"
            ? formOrderValue?.addCurrency
            : formOrderValue.addCountry,
    };

    value === "branch"
      ? addNewBranchMutation.mutate(payload)
      : value === "currency"
        ? addNewCurrencyMutation.mutate(payload)
        : addNewCountryMutation.mutate(payload);
  };

  const addNewBranchMutation = useMutation(addBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          branch: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addBranch: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          branch: res?.results?.id,
          addbranch: "",
        });

        setOpenModal(false);
        getBranchList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const addNewCurrencyMutation = useMutation(addCurrencyApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          currency: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addCurrency: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          currency: res?.results?.id,
          addCurrency: "",
        });
        setOpenModal(false);
        getCurrencyList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const addNewCountryMutation = useMutation(addCountryApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          country: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addCountry: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          country: res?.results?.id,
          addCountry: "",
        });
        setOpenModal(false);
        getCountryList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });
  // const loadMoreOptions = async () => {
  //     setoffsetBranch(offsetbranch + 1)
  //     const branchObj = {
  //         offset: offsetbranch + 10,
  //         // currentPage: 1,
  //         search: "",
  //         ordering: "-id",
  //         limit: 10

  //     };
  //     const res = await getBranch(branchObj)
  //     const br = [...branchList, ...res.results]
  //     // consople.log(res.resiult)
  //     // br.push(res?.results?.map((i) => i))

  //     setBranchList((pre) => [...pre, ...res.results]);

  // }

  const showHandle = () => {
    setShowPassword(!showPassword);
  };
  const showHandleConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={customerTabList}
          handleActive={handleActive}
          activeTab={+filter?.status}
        />
        <div className="button_group">
          <button className="anime-btn addPlan" onClick={() => setIsOpen(true)}>
            Add Customer
          </button>
          <button className="anime-btn addPlan" onClick={() => setIsOpen1(true)}>
            Upload Excel
          </button>
        </div>
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>

          <div className="inr_flex">
            <InputField
              placeholder="Customer"
              label="Customer"
              className="anvSearch_input"
              value={filter?.name}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,

                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex">
            <InputField
              placeholder="Email"
              label="Email"
              className="anvSearch_input"
              value={filter?.email}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,

                  email: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex">
            <InputField
              placeholder="Mobile Number"
              label="Mobile Number"
              className="anvSearch_input"
              value={filter?.mobile_number}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,

                  mobile_number: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex">
            {" "}
            <Dropdown
              placeholder="Select Branch"
              className="dropdownoforder orderModal_Input"
              label="Select Branch"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              name="driver"
              options={branchList ? branchList : []}
              valueText="id"
              labelText="name"
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.branchFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, branch: data.value });
                setFormOrderName({ ...formOrderName, branchFilter: data });
              }}
            />{" "}
          </div>
          {(filter?.email || filter?.name || filter?.branch.length == 36 || filter?.mobile_number) && <div className="inr_flex_mini">
            <button className="anime-btn reset_button" onClick={() => {
              setFilter({
                ...filter,
                email: "",
                name: "",
                mobile_number: "",
                branch: result

              })
              setFormOrderName({ ...formOrderName, branchFilter: '' })
            }
            } >Reset</button>
          </div>}
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={formOrderName?.count ? formOrderName?.count : { label: "20", value: 20 }}

              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))

                setFormOrderName({ ...formOrderName, count: data });
              }}


            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen1}
        headerName={isEdit ? "Upload" : "Upload"}
        toggle={() => setIsOpen1(!isOpen1)}
        submitLabel={isEdit ? "Upload" : "Upload"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.excel_file === disableInp.excel_file : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={isEdit ? "Upload" : "Upload"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Upload"
            name="excel_file"
            type="file"
            focus={!!inputError.excel_file}
            error={inputError.excel_file}
            // value={inputValue.excel_file}
            onChange={({ target: { name, files } }) =>
              handleOnChange(name, files[0])
            }
          />
        }
      />
      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) =>
            +activeModalTab === 1 ? validationHnalde() : onOrderSubmit(e)
          }
          title={isEdit ? "Edit Customer" : "Add Customer"}
          submitLabel={
            +activeModalTab === 2
              ? "Save"
              : +activeModalTab === 3
                ? "Upload"
                : "Continue"
          }
          inputProps={
            <>
              {isOpen && (
                <div div className="TabHeadofmodal">
                  {" "}
                  <Tabs
                    TabList={addCustomerTabList}
                    handleActive={handleModalActive}
                    activeTab={+activeModalTab}
                  />
                </div>
              )}
              {+activeModalTab === 1 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">

                    <div className="anvOt_inputField ">
                      <Dropdown
                        className="dropdownoforder"
                        placeholder="Select Branch"
                        label="Select Branch*"
                        classNamePrefix="_anvFloatSelect"
                        boxClass="_anvFloatedSelect"
                        name="branch"
                        options={branchList ? branchList : []}
                        valueText="id"
                        labelText="name"
                        isMulti={true}
                        components={userLogin?.user_type == 1 ? { Menu: CustomControl } : ""}
                        focus={!!formError?.branch}
                        error={formError?.branch}
                        selectedOption={formOrderName?.branch}
                        // onMenuScrollToBottom={loadMoreOptions}
                        setSelectedOption={(data) => {
                          setFormOrderName({
                            ...formOrderName,
                            branch: data,
                          });
                          handleOrderChange("branch", data);
                        }}
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input orderModal_Input"
                        label={isEdit ? "Customer Name*" : "Customer Name*"}
                        labelClass="FormLableKaran"
                        placeholder="Customer Name"
                        name="name"
                        type="text"
                        focus={!!formError?.name}
                        error={formError.name}
                        value={formOrderValue?.name}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={
                          isEdit
                            ? "Email ID (It will be used for Login)*"
                            : "Email ID (It will be used for Login)*"
                        }
                        labelClass="FormLableKaran"
                        placeholder="Email ID"
                        name="email"
                        type="text"
                        focus={!!formError?.email}
                        error={formError.email}
                        value={formOrderValue?.email}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Contact Number*" : "Contact Number*"}
                        labelClass="FormLableKaran"
                        placeholder="Add Contact Number"
                        name="mobile_number"
                        type="text"
                        maxLength={12}
                        onKeyDown={handleKeyDown}
                        focus={!!formError?.mobile_number}
                        error={formError.mobile_number}
                        value={formOrderValue?.mobile_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className=" checkboxtext anvOt_outerFull">
                      <InputField
                        className="modal_input "
                        labelClass="FormLableKaran"
                        placeholder="Company Registration Number"
                        name="is_web_access"
                        type="checkbox"
                        focus={!!formError?.is_web_access}
                        error={formError.is_web_access}
                        value={formOrderValue?.is_web_access}
                        checked={formOrderValue.is_web_access === true}
                        onChange={({ target: { name, checked } }) => {
                          handleOrderChange(name, checked);
                        }}
                      />
                      <sapn className="checkboxTextbox">Web App Access</sapn>
                    </div>

                    {isEdit === false && formOrderValue?.is_web_access && (
                      <div className="anvOt_inputField ">
                        <div className="dropdaonAndselect">
                          <div className="anvSpecificDiv">
                            <InputField
                              className="modal_input orderModal_Input"
                              label={isEdit ? "Set Password" : "Set Password"}
                              labelClass="FormLableKaran"
                              placeholder="Enter Password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              data="password"
                              showPassword={showPassword}
                              showHandle={showHandle}
                              focus={!!formError?.password}
                              error={formError.password}
                              value={formOrderValue?.password}
                              onChange={({ target: { name, value } }) =>
                                handleOrderChange(name, value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {isEdit === false && formOrderValue?.is_web_access && (
                      <div className="anvOt_inputField ">
                        <div className="dropdaonAndselect">
                          <div className="anvSpecificDiv">
                            <InputField
                              className="modal_input orderModal_Input"
                              label={
                                isEdit ? "Repeat Password" : "Repeat Password"
                              }
                              labelClass="FormLableKaran"
                              placeholder="Repeat Password"
                              name="confirm_password"
                              type={showConfirmPassword ? "text" : "password"}
                              data="password"
                              showPassword={showConfirmPassword}
                              showHandle={showHandleConfirm}
                              focus={!!formError?.confirm_password}
                              error={formError.confirm_password}
                              value={formOrderValue?.confirm_password}
                              onChange={({ target: { name, value } }) =>
                                handleOrderChange(name, value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Taxation Number" : "Taxation Number"}
                        labelClass="FormLableKaran"
                        placeholder="Company Registration Number"
                        name="taxation_number"
                        type="text"
                        maxLength={15}
                        // focus={!!formError?.taxation_number}
                        // error={formError.taxation_number}
                        value={formOrderValue?.taxation_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>

                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Credit Limit" : "Credit Limit"}
                        labelClass="FormLableKaran"
                        placeholder="Enter Credit Limit"
                        name="credit_limit"
                        type="text"
                        maxLength={6}
                        onKeyDown={handleKeyDown}
                        // focus={!!formError?.credit_limit}
                        // error={formError.credit_limit}
                        value={formOrderValue?.credit_limit}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <Dropdown
                        className="dropdownoforder"
                        placeholder="Select Currency"
                        label="Select Currency"
                        classNamePrefix="_anvFloatSelect"
                        boxClass="_anvFloatedSelect"
                        name="currency"
                        options={currencyList ? currencyList : []}
                        valueText="id"
                        labelText="name"
                        components={userLogin?.user_type == 1 ? { Menu: CustomControl } : ""}
                        // focus={!!formError?.currency}
                        // error={formError?.currency}
                        selectedOption={formOrderName?.currency}
                        setSelectedOption={(data) => {
                          setFormOrderName({
                            ...formOrderName,
                            currency: data,
                          });
                          handleOrderChange("currency", data?.value);
                        }}
                      />
                    </div>
                    {/* <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField ">

                                        <InputField
                                            className="modal_input orderModal_Input"
                                            label={isEdit ? "Attach Contract" : "Attach Contract"}
                                            labelClass="FormLableKaran"
                                            placeholder="Add Remark"
                                            name="contract"
                                            type="file"
                                            focus={!!formError?.contract}
                                            error={formError.contract}
                                            // value={formOrderValue?.remark}
                                            onChange={({ target: { name, files } }) =>
                                                handleOrderChange(name, files[0])
                                            }


                                        />

                                    </div>
                                </div> */}
                  </div>
                </div>
              )}
              {+activeModalTab === 2 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_outerFull">
                      <span className="customerAddress">
                        Customer Address Details
                      </span>
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Address Line 1" : "Address Line 1"}
                        labelClass="FormLableKaran"
                        placeholder="Address Line 1"
                        name="address_one"
                        type="text"
                        focus={!!formError?.address_one}
                        error={formError.address_one}
                        value={formOrderValue?.address_one}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Address Line 2" : "Address Line 2"}
                        labelClass="FormLableKaran"
                        placeholder="Address Line 2"
                        name="address_two"
                        type="text"
                        focus={!!formError?.address_two}
                        error={formError.address_two}
                        value={formOrderValue?.address_two}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>

                    <div className="anvOt_inputField ">
                      <Dropdown
                        className="dropdownoforder"
                        placeholder="Select Country"
                        label="Select Country*"
                        classNamePrefix="_anvFloatSelect"
                        boxClass="_anvFloatedSelect"
                        name="country"
                        options={countryList ? countryList : []}
                        valueText="id"
                        labelText="name"
                        components={{ Menu: CustomControl }}
                        focus={!!formError?.country}
                        error={formError?.country}
                        selectedOption={formOrderName?.country}
                        setSelectedOption={(data) => {
                          setFormOrderName({
                            ...formOrderName,
                            country: data,
                          });
                          handleOrderChange("country", data?.value);
                        }}
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "City*" : "City*"}
                        labelClass="FormLableKaran"
                        placeholder="city"
                        name="city"
                        type="text"
                        focus={!!formError?.city}
                        error={formError.city}
                        value={formOrderValue?.city}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {+activeModalTab === 3 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_outerFull">
                      <div className="anvOt_inputField ">
                        <InputField
                          className="modal_input orderModal_Input"
                          label={isEdit ? "Attach Contract" : "Attach Contract"}
                          labelClass="FormLableKaran"
                          placeholder="Add Remark"
                          name="contract"
                          type="file"
                          focus={!!formError?.contract}
                          error={formError.contract}
                          // value={formOrderValue?.remark}
                          onChange={({ target: { name, files } }) =>
                            handleOrderChange(name, files[0])
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          }
        />
      )}
      <Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />

      {openModal && (
        <InputModal
          isOpen={openModal}
          headerName={
            isEdit
              ? "Edit Branch"
              : formOrderValue.openName === "branch"
                ? "Add Branch"
                : formOrderValue.openName === "currency"
                  ? "Add Currency"
                  : "Add Country"
          }
          toggle={() => setOpenModal(!openModal)}
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={
                isEdit
                  ? "Edit Branch"
                  : formOrderValue.openName === "branch"
                    ? "Branch Name"
                    : formOrderValue.openName === "currency"
                      ? "Currency Name"
                      : "Country Name"
              }
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder={
                formOrderValue.openName === "branch"
                  ? "Branch Name"
                  : formOrderValue.openName === "currency"
                    ? "Currency Name"
                    : "Country Name"
              }
              name={
                formOrderValue.openName === "branch"
                  ? "addBranch"
                  : formOrderValue.openName === "currency"
                    ? "addCurrency"
                    : "addCountry"
              }
              focus={
                formOrderValue.openName === "branch"
                  ? !!formError.addBranch
                  : formOrderValue.openName === "currency"
                    ? !!formError.addCurrency
                    : !!formError.addCountry
              }
              error={
                formOrderValue.openName === "branch"
                  ? formError.addBranch
                  : formOrderValue.openName === "currency"
                    ? formError.addCurrency
                    : formError.addCountry
              }
              value={
                formOrderValue.openName === "branch"
                  ? formOrderValue.addBranch
                  : formOrderValue.openName === "currency"
                    ? formOrderValue.addCurrency
                    : formOrderValue.addCountry
              }
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}

      <InputModal
        isOpen={isOpenPassword}
        headerName={isEdit ? "Edit Password" : "Add Branch"}
        toggle={() => {
          setIsOpenPassword(!isOpenPassword);
          setIsEdit(false);
          clearAll();
        }}
        submitLabel={isEdit ? "Update Password" : "Continue"}
        onSubmit={(e) => handleUpdate(e)}
        // disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoadding}
        inputProps={
          <>
            <div className="dropdaonAndselect">
              <div className="anvSpecificDiv">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Set Password" : "Set Password"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  data="password"
                  showPassword={showPassword}
                  showHandle={showHandle}
                  focus={!!formError?.password}
                  error={formError.password}
                  value={formOrderValue?.password}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
            <div className="dropdaonAndselect">
              <div className="anvSpecificDiv">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Repeat Password" : "Repeat Password"}
                  labelClass="FormLableKaran"
                  placeholder="Repeat Password"
                  name="confirm_password"
                  type={showConfirmPassword ? "text" : "password"}
                  data="password"
                  showPassword={showConfirmPassword}
                  showHandle={showHandleConfirm}
                  focus={!!formError?.confirm_password}
                  error={formError.confirm_password}
                  value={formOrderValue?.confirm_password}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
