import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getCompanyList = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.company, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const getFundAllocationStateListBackend = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.fundAllocationState, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addCompanyApi = (data = {}) => {
    
    return performRequest(methodType.POST, apiEndPoints.company, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateCompanyApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.company + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteCompanyApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.company + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveCompany = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.company + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};