import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const InputModal = (props) => {
  const {
    className,
    customeClassName,
    isOpen,
    toggle,
    onSubmit,
    headerName,
    submitLabel,
    inputProps,
    disabled,
    mapView = false,
    size = "md",
    isLoadding,
    mapModal,
    sendFor,
    approveBtn,
    loaddingAproove,
    loaddingReject,
    mapInputProps,
    cancelbtn_mdllcls
  } = props;

  return (
    <div>

      <Modal
        size={size}
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        backdrop
        keyboard
        centered={true}
      >

        {mapView && (
         <>{mapInputProps}</>
        )}

        <div className="_secHand_bx">
          <ModalHeader toggle={toggle}>{headerName}</ModalHeader>
          <ModalBody>
            <div className={customeClassName ? `${customeClassName}` : "ModalFlexDiv"}>
              <div className=" flex labelwithinput">{inputProps}</div>

            </div>
          </ModalBody>
          <ModalFooter className="footerOfModal">
            {isLoadding ? <Button
              className="anime-btn ques_row_btn"
              // onClick={onSubmit}
              disabled={true}
            >
              Creating..
            </Button> : <>{!mapModal && <Button
              className="anime-btn ques_row_btn"
              onClick={onSubmit}
              disabled={disabled}
            >
              {submitLabel}
            </Button>}</>
            }
            {sendFor && <>
              {loaddingReject ? <Button
                className="anime-btn ques_row_btn"
                // onClick={onSubmit}
                disabled={true}
              >
                Creating..
              </Button> : <>{!mapModal && <Button
                className="anime-btn ques_row_btn"
                onClick={approveBtn}
                disabled={disabled}
              >
                {sendFor}
              </Button>}</>
              }
            </>}

            {!mapModal && <Button className={cancelbtn_mdllcls} color="" onClick={toggle}>
              Cancel
            </Button>}
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default InputModal;
