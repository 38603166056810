import React, { useEffect, useState } from "react";
import { Button, Card, CardText, CardTitle, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, } from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import {
    addCategory,
    deleteCategory,
    getBranch,
    getCommunity,
    getTags,
    updateCategory,
} from "../../services";
import { toast } from "react-toastify";
import { addDriverReciptVali, addDriverValidation, addLocationValidation, addOrderValidation, addResearchSchema, addResearchSchemaForImage, addTruckFleet, editDriverValidation, modalFileSchema, modalInputSchema } from "../../helper/errorSchema";
import { checkFormError, driverDetailsTabList, driverTabList, formatDate, handleKeyDown, locationTabList, orderTabList } from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import CheckboxField from "../../Component/Common/CheckboxField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { singleNewDetails, updateActiveResearch } from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Link } from "react-router-dom";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { addCountryApi, getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import { acceptAndRejectStatus, addFleetsForOrder, addOrder, getAllFleetforOrder, getAllOrder, getdryCargo, gettankersCsv } from "../../services/orderService";
import { getProduct } from "../../services/productService";
import { addDriver, addDriverPaid, addDriverRecipt, deleteDriver, deleteDriverPaid, getAllDriver, getAllDriverTrans, singleDriverDetails, singleDriverDetailsPaid, singleDriverWallet, updateActiveDriver, updateDriver, updateDriverPaid, uploadExcelDriver } from "../../services/driverService";
import mytruck from "../../../image/tanker.png"
import { components } from "react-select";
import { getStorage, setStorage } from "../../helper/storage";
import { getExpenseCategory } from "../../services/expenseCategory";
import { getCurrency } from "../../services/currencyService";
import { getAllRoute } from "../../services/routeService";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
const routeList = [{ label: 'Cash', value: 1 }, { label: 'Online', value: 2 }]

export const DriverDetails = () => {

    const formObj = {
        number: "",
        date: formatDate(new Date(), '-'),
    };

    const orderObj = {
        name: "",
        mobile_number: "",
        emergency_mobile_number: "",
        is_mobile_access: false,
        opining_balance_date: formatDate(new Date(), '-'),
        opining_balance: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        password: "",
        confirm_password: "",
        license_number: "",
        license_expiry_date: formatDate(new Date(), '-'),
        license_file: null,
        passport_number: "",
        passport_expiry_date: formatDate(new Date(), '-'),
        passport_file: null,
        contract_expiry_date: formatDate(new Date(), '-'),
        contract_file: null,
        reciptDate: formatDate(new Date(), '-'),
        reciptDriver: "",
        reciCategory: "",
        reciDescription: "",
        reciModeOFpay: "",
        reciAmount: "",
        reciCurrency: "",
        is_active: true,
        addCountry: "",



        // paidDate: formatDate(new Date(), '-'),
        // paidDriver: "",
        // paidCategory: "",
        // paidDescription: "",
        // paidModeOFpay: "",
        // paidAmount: "",
        // paidCurrency: ""
    }

    const orderObjName = {

        name: "",
        mobile_number: "",
        emergency_mobile_number: "",
        is_mobile_access: false,
        opining_balance_date: formatDate(new Date(), '-'),
        opining_balance: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        password: "",
        confirm_password: "",
        license_number: "",
        license_expiry_date: formatDate(new Date(), '-'),
        license_file: "",
        passport_number: "",
        passport_expiry_date: formatDate(new Date(), '-'),
        passport_file: "",
        contract_expiry_date: formatDate(new Date(), '-'),
        contract_file: "",
        is_active: true,
    }


    const reciObj = {
        reciptDate: formatDate(new Date(), '-'),
        reciptDriver: "",
        reciCategory: "",
        reciDescription: "",
        reciModeOFpay: "",
        reciAmount: "",
        reciCurrency: "",
        reci_active: true
    }

    const reciObjName = {
        reciptDate: formatDate(new Date(), '-'),
        reciptDriver: "",
        reciCategory: "",
        reciDescription: "",
        reciModeOFpay: "",
        reciAmount: "",
        reciCurrency: "",
        reci_active: true
    }


    const Requirement = [

        { heading: "S.No.", className: "sorting", value: "SN", sortName: "" },
        { heading: "Date", className: "sorting_asc", value: "date", sortName: "" },
        { heading: "Category", className: "sorting_asc", value: "category_name", sortName: "" },
        { heading: "Description", className: "sorting_asc", value: "description", sortName: "" },
        { heading: "Trip Relation", className: "sorting_asc", value: "tripAv", sortName: "" },
        { heading: "Driver Received", className: "sorting_asc", value: "driverRecep", sortName: "" },
        { heading: "Driver Gave", className: "sorting_asc", value: "driverGave", sortName: "" },
        { heading: "Action", className: "sorting_asc", value: "action", sortName: "" },


    ];
    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id"

    };

    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true
    };

    let defultExpense = {
        dates: formatDate(new Date(), '-'),
        amounts: "",
        currencies: "",

    };

    const routeObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
    };

    const driverID = getStorage("driverID")
    const drWallet = getStorage("driverDetails")
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [status, setStatus] = useState(1)
    const [filter, setFilter] = useState({
        limit: 20,
        status: status,
        driver: driverID,
        ...obj,

    });
    const [openModal, setOpenModal] = useState(false)
    const [formValue, setFormValue] = useState(formObj);
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formReciValue, setFormReciValue] = useState(reciObj)
    const [formReciName, setFormReciName] = useState(reciObjName)

    const [formError, setFormError] = useState("");
    const [updateId, setUpdateId] = useState("")
    const [showCropper, setShowCropper] = useState(false);
    const [isLoadding, setIsLoadding] = useState(false)
    const [countryList, setCountryList] = useState()
    const [currencyList, setCurrencyList] = useState()
    const [currencyList2, setCurrencyList2] = useState()
    const [driverList, setDriverList] = useState()
    const [walletList, setWalletList] = useState()
    const [loaderForList, setLoaderForList] = useState(false)
    const [inputValue, setInputValue] = useState({
        excel_file: "",
    });
    const [inputError, setInputError] = useState({
        excel_file: "",
    });
    const [disableInp, setDisableInp] = useState({
        excel_file: "",
    });
    const debouncedFilter = useDebounce(filter, 500);
    const [actionModal, setAction] = useState('')
    const [exCategoryList, setExCategoryList] = useState([])
    const [expenseList, setExpenseList] = useState([
        defultExpense
    ])

    const [transtaionType, setTranstaionType] = useState('')
    console.log("walletList", walletList)
    const rowDataList = () => {
        const listArray = dataList?.results?.map((item, i) => ({

            ...item,
            status: (
                <div className="statusofOrder">
                    {item?.status === 1 ? <span>Requirement</span> : item?.status === 2 ? <span>Pending</span> : item?.status === 3 ? <span>Active Orders</span> : item?.status === 4 ? <span>Near to Expair</span> : item?.status === 5 ? <span>Cancelled</span> : item?.status === 6 ? <span>Completed</span> : ""}
                </div>
            ),

            route: (
                <div>
                    {`${item?.from_location} < ${item?.to_location}`}
                </div>
            ),

            product: (
                <div>
                    {item?.product_detail?.name}
                </div>
            ),

            date: (

                <div className="dateOfOredr">{formatDate(item?.date)}</div>
            ),
            qty: `${item?.qty} Ltr`,
            SN: item.receipt_number || item.payment_number,
            tripAv: item?.trip && <div className="driverName" onClick={() => {
                setStorage("tripDetals", "Trip")
                setStorage("tripID", item.trip)
                navigate("/trip-details")
            }}>{`${item?.trip_nomination_number ? item?.trip_nomination_number : ""}`}</div>,
            driverRecep: item.receipt_number ? `+ ${item?.amount} ${item?.currency_name}` : "",
            driverGave: item.payment_number ? `- ${item?.amount} ${item?.currency_name}` : "",
            action: (
                <>
                    <div className="actionBtn">

                    <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>
                    <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>

                        {/* <div className="customerStatus"><SwitchField onSwitchChange={() => updateTagStatus(item)} value={item?.is_active} />{item?.is_active ? <span>Disable</span> : <span>Enable</span>}</div> */}

                        {/* <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
                        <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> */}
                    </div>
                </>
            ),
            sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
            icon: (
                <>
                    <img src={item?.icon} alt={item?.name} />
                </>
            ),
        }));
        return listArray;
    };



    const { data: dataList, isSuccess } = useQuery(
        ["getAllDriverTrans", debouncedFilter],
        () => getAllDriverTrans(debouncedFilter, filter),
        {
            enabled: Boolean(debouncedFilter),
            refetchOnWindowFocus: false

        }
    )

    useEffect(() => {
        if (dataList) {
            setLoaderForList(false)
        } else {
            setLoaderForList(true)
        }
    }, [dataList])


    useEffect(() => {

        getCountryList()
        getExpenseList()
        getCurrencyList()
        getDriverList()

    }, [driverID])

    useEffect(() => {
        if (drWallet === "wallet") {
            setFilter({
                ...filter,
                status: 2
            })
        }
    }, [drWallet])

    useEffect(() => {
        if (driverID) {
            getShowDetails(driverID)
            getDriverWallet(driverID)

        }

    }, [countryList])



    const getCountryList = async () => {
        const res = await getCountry(countryObj)
        setCountryList(res?.results)
    }

    const getExpenseList = async () => {
        const res = await getExpenseCategory(countryObj)
        setExCategoryList(res?.results)

    }
    const getCurrencyList = async () => {
        // const res = await getCurrency(countryObj)
        // setCurrencyList(res?.results)
        // setCurrencyList2(res?.results?.map((item) => {
        //     return { ...item, label: item?.name, value: item?.id }
        // }))
    }
    const getDriverList = async () => {
        const res = await getAllDriver(countryObj)
        setDriverList(res?.results)

    }

    const getShowDetails = (driverID) => {
        getDriverDetails.mutate(driverID)
    }

    const getDriverWallet = async (driverID) => {

        const res = await singleDriverWallet(driverID)
        setWalletList(res?.results)

    }


    const clearAll = () => {
        setFormReciValue({
            ...formReciValue,
            reciptDate: formatDate(new Date(), '-'),
            reciptDriver: driverID,
            reciCategory: "",
            reciDescription: "",
            reciModeOFpay: "",
            reciAmount: "",
            reciCurrency: "",
            reci_active: true,
        })
        setFormReciName({
            ...formReciName,
            reciptDate: formatDate(new Date(), '-'),
            reciptDriver: { label: driverList?.find((i) => i?.id === driverID).name, value: driverList?.find((i) => i?.id === driverID).id },
            reciCategory: "",
            reciDescription: "",
            reciModeOFpay: "",
            reciAmount: "",
            reciCurrency: "",
            reci_active: true,
        })

        setIsEdit(false);
        setFormValue("");
        setFormError("");
        setInputError('');
        setInputValue({
            excel_file: ""
        })
    }


    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };

    const updateTagStatus = (item) => {

        const payload = {
            id: item.id,
            is_active: !item?.is_active
        }
        updateActiveMutation.mutate(payload);
    }

    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (name === "reciDescription" || name === "reciAmount") {
            setFormReciValue({ ...formReciValue, [name]: value })
        }

        if (!!formError) {
            const error = await checkFormError(stateObj, addDriverValidation
            );
            setValidationError(error);
        }
    };

    const handleReciChange = async (name, value) => {

        const stateObj = { ...formReciValue, [name]: value };

        setFormReciValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addDriverValidation
            );
            setValidationError(error);
        }
    };




    const onOrderSubmit = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue },
            isEdit ? editDriverValidation : addDriverValidation
        );


        const formData = new FormData();
        formData.append("name", formOrderValue?.name);
        formData.append("mobile_number", formOrderValue?.mobile_number);
        formData.append("emergency_mobile_number", formOrderValue?.emergency_mobile_number);
        formData.append("is_mobile_access", formOrderValue?.is_mobile_access);
        formData.append("opining_balance_date", formOrderValue?.opining_balance_date);
        formData.append("opining_balance", formOrderValue?.opining_balance);
        formData.append("address_one", formOrderValue?.address_one);
        formData.append("address_two", formOrderValue?.address_two);
        formData.append("country", formOrderValue?.country);
        formData.append("state", formOrderValue?.state);
        formData.append("city", formOrderValue?.city);
        formData.append("pincode", formOrderValue?.pincode);
        formData.append("password", formOrderValue?.password);
        formData.append("confirm_password", formOrderValue?.confirm_password);
        formData.append("license_number", formOrderValue?.license_number);
        formData.append("license_expiry_date", formOrderValue?.license_expiry_date);
        if (formOrderValue?.license_file) formData.append("license_file", formOrderValue?.license_file);

        formData.append("passport_number", formOrderValue?.passport_number);
        formData.append("passport_expiry_date", formOrderValue?.passport_expiry_date);
        if (formOrderValue?.passport_file) formData.append("passport_file", formOrderValue?.passport_file);

        formData.append("contract_expiry_date", formOrderValue?.contract_expiry_date);
        if (formOrderValue?.contract_file) formData.append("contract_file", formOrderValue?.contract_file);

        formData.append("is_active", formOrderValue?.is_active);




        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate(formData);
            } else {
                handleAdd(formData);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };

    const onOrderSubmitRecept = async () => {

        const validationResult = await checkFormError(
            { ...formReciValue },
            addDriverReciptVali
        );
        const payload = {
            date: formReciValue.reciptDate,
            driver: formReciValue.reciptDriver,
            category: formReciValue.reciCategory,
            description: formReciValue.reciDescription,
            payment_mode: formReciValue.reciModeOFpay,
            amount: formReciValue.reciAmount,
            currency: formReciValue.reciCurrency,
            transaction_type: transtaionType,
            is_active: formReciValue?.reci_active
        }



        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate(payload);
            } else {
                handleAdd(payload);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    }

    const updateActiveMutation = useMutation(updateActiveDriver, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });


    const handleAdd = (formData) => {
        addMutationPaid.mutate(formData)

    };

    const handleDelete = () => {
        deleteMutation.mutate(deleteId);
    };


    const handleEditAction = (item) => {
        setAction(item?.transaction_type === 1 ? "paid" : "recept")
        getDriverDetailsPaid.mutate(item?.id)


    };

    const getDriverDetailsPaid = useMutation(singleDriverDetailsPaid, {
        onSuccess: (res) => {
            if (res?.success) {

                setFormReciValue({
                    ...formReciValue,
                    reciptDate: res?.results?.date,
                    reciptDriver: res?.results?.driver,
                    reciCategory: res?.results?.category,
                    reciDescription: res?.results?.description,
                    reciModeOFpay: res?.results?.payment_mode,
                    reciAmount: res?.results?.amount,
                    reciCurrency: res?.results?.currency,
                    transtaionType: res?.results?.transaction_type,
                    is_active: res?.results?.is_active
                })

                setTranstaionType(res?.results?.transaction_type)


                const common = (name, id) => {
                    if (name === "driver") {
                        let details = driverList?.find((i) => i?.id === id)
                        return { data: details, label: details?.name, value: details?.id }
                    }
                }
                setFormReciName({
                    ...formReciName,
                    reciptDriver: common("driver", res?.results?.driver),
                    reciptDate: res?.results?.date,
                    reciCategory: { label: res?.results?.category_name, value: res?.results?.category },
                    reciDescription: res?.results?.description,
                    reciModeOFpay: { label: res?.results?.payment_mode === 1 ? "Cash" : "Online", value: res?.results?.payment_mode },
                    reciCurrency: { label: res?.results?.currency_name, value: res?.results?.currency },
                    is_active: res?.results?.is_active
                })


                setIsEdit(true)
                setIsOpen1(true)
                setFormError("");
                setUpdateId(res?.results?.id)

            } else {

            }
        }
    })

    const getDriverDetails = useMutation(singleDriverDetails, {
        onSuccess: (res) => {
            if (res?.success) {

                setFormOrderValue({
                    name: res?.results?.name,
                    mobile_number: res?.results?.mobile_number,
                    emergency_mobile_number: res?.results?.emergency_mobile_number,
                    is_mobile_access: res?.results?.is_mobile_access,
                    opining_balance_date: res?.results?.opining_balance_date,
                    opining_balance: res?.results?.opining_balance,
                    address_one: res?.results?.address_one,
                    address_two: res?.results?.address_two,
                    country: res?.results?.country,
                    state: res?.results?.state,
                    city: res?.results?.city,
                    pincode: res?.results?.pincode,
                    license_number: res?.results?.license_number,
                    license_expiry_date: res?.results?.license_expiry_date,
                    passport_number: res?.results?.passport_number,
                    passport_expiry_date: res?.results?.passport_expiry_date,
                    contract_expiry_date: res?.results?.contract_expiry_date,
                    is_active: res?.results?.is_active,
                })

                const newExpe = res?.results?.driver_wallets?.map((data, index) => {
                    return {

                        dates: data?.date,
                        amounts: data?.amount,
                        currencies: data?.currency,
                    }


                })

                const newCure = res?.results?.driver_wallets?.map((data, index) => {
                    return {

                        value: data?.currency,
                        label: data?.currency_name,
                    }


                })
                setCurrencyList2(newCure)

                setExpenseList(newExpe)


                const common = (name, id) => {


                    if (name === "country") {
                        let details = countryList?.find((i) => i?.id === id)
                        return { data: details, label: details?.name, value: details?.id }
                    }
                    if (name === "driver") {
                        let details = driverList?.find((i) => i?.id === id)
                        return { data: details, label: details?.name, value: details?.id }
                    }
                }

                setFormOrderName({
                    ...formOrderName,
                    country: common("country", res?.results?.country),
                    opining_balance_date: res?.results?.opining_balance_date,
                    passport_expiry_date: res?.results?.passport_expiry_date,
                    contract_expiry_date: res?.results?.contract_expiry_date,
                    license_expiry_date: res?.results?.license_expiry_date,
                })
                setFormReciName({
                    ...formReciName,
                    reciptDriver: { data: res?.results, label: res?.results?.name, value: res?.results?.id },
                })

                setFormReciValue({
                    ...formReciValue,
                    reciptDriver: res?.results?.id
                })
                setFormError("");
                setUpdateId(res?.results?.id)

            } else {

            }
        }
    })

    const handleUpdate = async (data) => {

        const payload = {
            formdata: data,
            id: updateId
        }

        updateMutation.mutate(payload);
    };



    const addMutationPaid = useMutation(addDriverPaid, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);

                getDriverWallet(driverID)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });


    const updateMutation = useMutation(updateDriverPaid, {
        onSuccess: (res) => {
            if (res?.success) {
                setIsEdit(false);
                onSuccessResponse(res?.message);
                getDriverWallet(driverID)
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const deleteMutation = useMutation(deleteDriverPaid, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                setOpenDel(false);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success", { position: "bottom-left" });
        queryClient.refetchQueries("getAllDriverTrans");
        setIsOpen1(false);
        setIsLoadding(false)
        clearAll()
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
        setIsLoadding(false)
    };

    const handlePageClick = async (data) => {
        const currentPage = data.selected;
        setFilter((s) => ({
            ...s,
            offset: currentPage * filter.limit,
            currentPage: currentPage,
        }));
    };

    const hendleDeleteTag = (data, flag) => {
        setOpenDel(flag);
        setDeleteId(data)
    }


    const handleActive = (data) => {
        setLoaderForList(true)
        setFilter({
            ...filter,
            status: data.value
        })
    }

    const getTableList = () => {
        switch (+filter.status) {
            case 1:
                return Requirement

            default:
                return Requirement

        }
    }



    /// ----------- add Branch ---------

    const CustomControl = ({ children, ...props }) => (
        <components.Menu {...props}>
            <button className="addMoreBtn" onClick={() => addNewEntryHandle(props?.selectProps?.placeholder, props?.selectProps?.inputValue)}>+ Add New {props?.selectProps?.inputValue ? ` -> "${props?.selectProps?.inputValue}"` : ""}</button>
            {children}

        </components.Menu>
    );

    const addNewEntryHandle = (PlaceName, inputvalue) => {

        switch (PlaceName) {

            case "Select Country":
                setFormOrderValue({ ...formOrderValue, addCountry: inputvalue, openName: "country" })
                setOpenModal(true)
                break;

            default:
                break;
        }
    }

    const addNewSubmit = async (value) => {


        const validationResult = await checkFormError(
            { name: formOrderValue.addCountry },
            modalInputSchema
        )

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate();
            } else {
                handleAddNew(value);
            }
        } else {
            setFormError(validationResult);
            setIsLoadding(false)
        }

    };

    const handleAddNew = (value) => {

        const payload = {
            name: formOrderValue?.addCountry,
        }

        addNewCountryMutation.mutate(payload)

    }


    const addNewCountryMutation = useMutation(addCountryApi, {
        onSuccess: (res) => {
            if (res?.success) {
                // onSuccessResponse(res?.message);

                setFormOrderName({ ...formOrderName, country: { data: res?.results, label: res?.results?.name, value: res?.results?.id }, addCountry: "" })
                setFormOrderValue({ ...formOrderValue, country: res?.results?.id, addCountry: "" })
                setOpenModal(false)
                getCountryList()
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });


    const updateExpoDetails = (index, data) => {
        const { key, value } = data;
        let newExpenseList = [...expenseList];
        newExpenseList[index][key] = value;
        setExpenseList(newExpenseList);

    }



    return (
        <>
            <div className='tabMain_head'>
                {formOrderValue?.name ? formOrderValue?.name : ""}
                <div className="button_group">
                    {+filter?.status === 2 ? <>
                        <button className='addPlan' onClick={() => {
                            setAction("paid ")
                            setTranstaionType(1)
                            setIsOpen1(true)
                        }}>Driver Paid</button>
                        <button className='addPlan' onClick={() => {
                            setAction("recept")
                            setIsOpen1(true)
                            setTranstaionType(2)
                        }}>Driver Received</button></> : <> <button className='addPlan' onClick={() => setIsOpen(true)}>Edit</button></>}

                </div>
            </div>
            <div className='tabMain_head'> <Tabs TabList={driverDetailsTabList} handleActive={handleActive} activeTab={+filter.status} />

            </div>
            {+filter?.status === 2 && <div className="anvTask_prog driverFlex">

                <div>
                    <span className="spanName">Driver Balance</span>
                    {walletList?.map((item) => <span className={item?.currency_name === "MZN" ? "spanFirst" : item?.currency_name === "ZAR" ? "spanSecond" : item?.currency_name === "USD" ? "spanThird" : ""}>{`${item?.currency_name} ${item?.amount}`}</span>)}
                    {/* <span className="spanSecond">Rs. 5000</span> */}
                </div>

                <div className="anvInputs&fillters">

                    <div className="inr_flex_filter" >
                        <button className="filterBox">Filter</button>
                    </div>
                    <div className="inr_flex">
                        <InputField placeholder="Category" label="Category" className="anvSearch_input" />
                    </div>
                    <div className="inr_flex">
                        <InputField placeholder="Search" label="Search" className="anvSearch_input" value={filter?.search}
                            onChange={(e) =>
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    search: e.target.value,
                                }))
                            } />
                    </div>
                </div>
            </div>}

            <div>
                <div className="table-responsive">
                    {+filter?.status === 2 && <CustomTable
                        column={getTableList()}
                        data={rowDataList()}
                        tableLoading={loaderForList}

                    />
                    }


                    {+filter?.status === 1 && <div className="overflowDriver" >
                        <div className="driverDetailsPage ">
                            <div className="anvOt_gridBx  ">
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Driver Name" : "Driver Name"}
                                        labelClass="FormLableKaran"
                                        placeholder="Enter Name"
                                        name="name"
                                        type="text"
                                        disabled="true"
                                        focus={!!formError?.name}
                                        error={formError.name}
                                        value={formOrderValue?.name}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Contact Number" : "Contact Number"}
                                        labelClass="FormLableKaran"
                                        placeholder="Contact Number"
                                        name="mobile_number"
                                        maxLength={12}
                                        type="text"
                                        disabled="true"
                                        focus={!!formError?.mobile_number}
                                        error={formError.mobile_number}
                                        value={formOrderValue?.mobile_number}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Emergency Contact Number" : "Emergency Contact Number"}
                                        labelClass="FormLableKaran"
                                        placeholder="Emergency Contact Number"
                                        name="emergency_mobile_number"
                                        type="text"
                                        maxLength={12}
                                        disabled="true"
                                        focus={!!formError?.emergency_mobile_number}
                                        error={formError.emergency_mobile_number}
                                        value={formOrderValue?.emergency_mobile_number}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="anvOt_gridBx ">
                                <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField checkboxtext">
                                        <InputField
                                            className="modal_input "
                                            labelClass="FormLableKaran"
                                            placeholder="Company Registration Number"
                                            name="is_mobile_access"
                                            type="checkbox"
                                            disabled="true"
                                            focus={!!formError?.is_mobile_access}
                                            error={formError.is_mobile_access}
                                            value={formOrderValue?.is_mobile_access}
                                            checked={formOrderValue.is_mobile_access === true}

                                            onChange={({ target: { name, checked } }) => {

                                                handleOrderChange(name, checked)
                                            }
                                            }
                                        />
                                        <sapn className='checkboxTextbox mb-0'>Mobile App Access</sapn>

                                    </div>
                                </div>
                            </div>


                            {formOrderValue?.is_mobile_access && <div className="anvOt_gridBx ">
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Set Password" : "Set Password"}
                                        labelClass="FormLableKaran"
                                        placeholder="Enter Password"
                                        name="password"
                                        type="password"
                                        disabled="true"
                                        focus={!!formError?.password}
                                        error={formError.password}
                                        value={formOrderValue?.password}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>
                                {/* } */}
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Repeat Password" : "Repeat Password"}
                                        labelClass="FormLableKaran"
                                        placeholder="Repeat Password"
                                        name="confirm_password"
                                        type="password"
                                        disabled="true"
                                        focus={!!formError?.confirm_password}
                                        error={formError.confirm_password}
                                        value={formOrderValue?.confirm_password}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>

                            </div>}


                            <div className="anvOt_outerFull partMarg">
                                {expenseList.length > 0 && <span>Account Details</span>}
                            </div>
                            {expenseList?.map((onExpo, index) => <div className="anvOt_gridBx mb-0">


                                <div className="anvOt_inputField ">
                                    <CustomDatePicker
                                        type="date"
                                        label="Opening Balance Date"
                                        value={onExpo?.dates}
                                        max={formatDate(new Date())}
                                        focus={`${formError[`expense[${index}].dates`]}` !== "undefined" ? !!`${formError[`expense[${index}].dates`]}` : ""}
                                        error={`${formError[`expense[${index}].dates`]}` !== "undefined" ? `${formError[`expense[${index}].dates`]}` : ""}
                                        name="dates"
                                        disabled="true"
                                        placeholder={onExpo?.dates}
                                        onSelect={({ target: { name, value } }) => {
                                            handleOrderChange(name, value)
                                            setFormOrderName({ ...formOrderName, opining_balance_date: value })
                                            updateExpoDetails(index, { key: "dates", value: value })

                                        }
                                        }
                                        className="date-input"
                                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                    // className="date-input"
                                    />
                                </div>
                                <div className="anvOt_inputField diplayDriver">
                                    <div className="_crrOpening">
                                        <InputField
                                            className="modal_input orderModal_Input widthDriver"
                                            label={isEdit ? "Opening Balance (Dr. / Cr.)" : " Opening Balance (Dr. / Cr.)"}
                                            labelClass="FormLableKaran"
                                            placeholder=""
                                            name="amounts"
                                            type="text"
                                            disabled="true"
                                            focus={`${formError[`expense[${index}].amounts`]}` !== "undefined" ? !!`${formError[`expense[${index}].amounts`]}` : ""}
                                            error={`${formError[`expense[${index}].amounts`]}` !== "undefined" ? `${formError[`expense[${index}].amounts`]}` : ""}
                                            value={onExpo?.amounts}
                                            onChange={({ target: { name, value } }) => {
                                                updateExpoDetails(index, { key: "amounts", value: value })
                                                handleOrderChange(name, value)
                                            }


                                            }
                                        />
                                    </div>

                                    <div className="_crrOpening">
                                        <Dropdown
                                            className='dropdownoforder'
                                            placeholder="currency"
                                            label="currency"
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect"
                                            name="currencies"
                                            options={currencyList2 ? currencyList2 : []}
                                            valueText="id"
                                            labelText="name"
                                            disabled="true"
                                            focus={`${formError[`expense[${index}].currencies`]}` !== "undefined" ? !!`${formError[`expense[${index}].currencies`]}` : ""}
                                            error={`${formError[`expense[${index}].currencies`]}` !== "undefined" ? `${formError[`expense[${index}].currencies`]}` : ""}
                                            selectedOption={currencyList2?.find((item) => item?.value === onExpo?.currencies)}
                                            components={{ Menu: CustomControl }}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, currency: data })
                                                handleOrderChange("currencies", data?.value);
                                                updateExpoDetails(index, { key: "currencies", value: data?.value })

                                            }}
                                        />

                                    </div>
                                </div>


                            </div>)}

                            <div className="anvOt_outerFull partMarg">
                                <span>Driver Address Details</span>
                            </div>
                            <div className="anvOt_gridBx ">

                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Address Line 1" : "Address Line 1"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="address_one"
                                        type="text"
                                        disabled="true"
                                        focus={!!formError?.address_one}
                                        error={formError.address_one}
                                        value={formOrderValue?.address_one}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Address Line 2" : "Address Line 2"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="address_two"
                                        type="text"
                                        disabled="true"
                                        focus={!!formError?.address_two}
                                        error={formError.address_two}
                                        value={formOrderValue?.address_two}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <Dropdown
                                        className='dropdownoforder'
                                        placeholder="Select Country"
                                        label="Select Country"
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect"
                                        name="country"
                                        options={countryList ? countryList : []}
                                        valueText="id"
                                        labelText="name"
                                        disabled="true"
                                        focus={!!formError?.country}
                                        error={formError?.country}
                                        selectedOption={formOrderName?.country}
                                        components={{ Menu: CustomControl }}
                                        setSelectedOption={(data) => {
                                            setFormOrderName({ ...formOrderName, country: data })
                                            handleOrderChange("country", data?.value);

                                        }}
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "State" : "State"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="state"
                                        type="text"
                                        disabled="true"
                                        focus={!!formError?.state}
                                        error={formError.state}
                                        value={formOrderValue?.state}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "City" : "City"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="city"
                                        type="text"
                                        disabled="true"
                                        focus={!!formError?.city}
                                        error={formError.city}
                                        value={formOrderValue?.city}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Pin Code" : "Pin Code"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="pincode"
                                        type="text"
                                        disabled="true"
                                        maxLength={7}
                                        focus={!!formError?.pincode}
                                        error={formError.pincode}
                                        value={formOrderValue?.pincode}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                            </div>


                            {/* <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Country"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="country"
                                    type="text"
                                    focus={!!formError?.country}
                                    error={formError.country}
                                    value={formOrderValue?.country}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div> */}


                            <div className="anvOt_outerFull partMarg">
                                <span>Document Details</span>
                            </div>
                            <div className="anvOt_gridBx ">

                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "License Number" : "License Number"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="license_number"
                                        type="text"
                                        maxLength={16}
                                        disabled="true"
                                        focus={!!formError?.license_number}
                                        error={formError.license_number}
                                        value={formOrderValue?.license_number}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <CustomDatePicker
                                        label="License Expiry Date"
                                        type="date"
                                        value={formOrderName?.license_expiry_date}
                                        max={formatDate(new Date())}
                                        focus={!!formError?.license_expiry_date}
                                        error={formError?.license_expiry_date}
                                        name="license_expiry_date"
                                        disabled="true"
                                        placeholder={formOrderName?.license_expiry_date}
                                        onSelect={({ target: { name, value } }) => {
                                            handleOrderChange(name, value)
                                            setFormOrderName({ ...formOrderName, license_expiry_date: value })
                                        }
                                        }
                                        className="date-input"
                                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                    // className="date-input"
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Attach License" : "Attach License"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="license_file"
                                        type="file"
                                        disabled="true"
                                        focus={!!formError?.license_file}
                                        error={formError.license_file}
                                        // value={formOrderValue?.license_file}
                                        onChange={({ target: { name, files } }) =>
                                            handleOrderChange(name, files[0])
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Passport Number" : "Passport Number"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="passport_number"
                                        type="text"
                                        maxLength={12}
                                        disabled="true"
                                        focus={!!formError?.passport_number}
                                        error={formError.passport_number}
                                        value={formOrderValue?.passport_number}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <CustomDatePicker
                                        label="Passport Expirty Date"
                                        type="date"
                                        value={formOrderName?.passport_expiry_date}
                                        max={formatDate(new Date())}
                                        focus={!!formError?.passport_expiry_date}
                                        error={formError?.passport_expiry_date}
                                        name="passport_expiry_date"
                                        disabled="true"
                                        placeholder={formOrderName?.passport_expiry_date}
                                        onSelect={({ target: { name, value } }) => {
                                            handleOrderChange(name, value)
                                            setFormOrderName({ ...formOrderName, passport_expiry_date: value })
                                        }
                                        }
                                        className="date-input"
                                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                    // className="date-input"
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Attach Passport" : "Attach Passport"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="passport_file"
                                        type="file"
                                        disabled="true"
                                        focus={!!formError?.passport_file}
                                        error={formError.passport_file}
                                        // value={formOrderValue?.passport_file}
                                        onChange={({ target: { name, files } }) =>
                                            handleOrderChange(name, files[0])
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <CustomDatePicker
                                        label="Contract Expiry Date"
                                        type="date"
                                        value={formOrderName?.contract_expiry_date}
                                        max={formatDate(new Date())}
                                        focus={!!formError?.contract_expiry_date}
                                        error={formError?.contract_expiry_date}
                                        name="contract_expiry_date"
                                        disabled="true"
                                        placeholder={formOrderName?.contract_expiry_date}
                                        onSelect={({ target: { name, value } }) => {
                                            handleOrderChange(name, value)
                                            setFormOrderName({ ...formOrderName, contract_expiry_date: value })
                                        }
                                        }
                                        className="date-input"
                                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                    // className="date-input"
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Attach Contract" : "Attach Contract"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="contract_file"
                                        type="file"
                                        disabled="true"
                                        focus={!!formError?.contract_file}
                                        error={formError?.contract_file}
                                        // value={formOrderValue?.contract_file}
                                        onChange={({ target: { name, files } }) =>
                                            handleOrderChange(name, files[0])
                                        }
                                    />
                                </div>
                            </div>

                        </div>
                    </div>}
                </div>
            </div>



            <InputModal
                isOpen={isOpen1}
                headerName={isEdit ? actionModal === "recept" ? "Edit Driver Receipt" : " Edit Driver Paid" : actionModal === "recept" ? "Driver Receipt" : "Driver Paid"}
                toggle={() => {
                    clearAll()
                    setIsOpen1(!isOpen1)

                }}
                submitLabel={"Submit"}
                onSubmit={(e) => onOrderSubmitRecept(e)}
                isLoadding={isLoadding}
                customeClassName="ModalRouteFlex"
                className="routemodalNew"
                inputProps={

                    <>
                        <div className="anvMaintab_container_route drFlex">
                            <div>
                                <CustomDatePicker
                                    type="date"
                                    label="Date"
                                    value={formReciName?.reciptDate}
                                    max={formatDate(new Date())}
                                    focus={!!formError?.reciptDate}
                                    error={formError?.reciptDate}
                                    name="reciptDate"

                                    placeholder={formReciName?.reciptDate}
                                    onSelect={({ target: { name, value } }) => {
                                        handleReciChange(name, value)
                                        setFormReciName({ ...formReciName, reciptDate: value })
                                    }
                                    }
                                    className="date-input"
                                // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                // className="date-input"
                                />

                            </div>
                            <div>
                                <Dropdown
                                    className='dropdownoforder'
                                    placeholder="Select Driver "
                                    label="Select Category"
                                    classNamePrefix="_anvFloatSelect"
                                    boxClass="_anvFloatedSelect"
                                    name="reciptDriver"
                                    options={driverList ? driverList : []}
                                    valueText="id"
                                    labelText="name"
                                    disabled="true"
                                    focus={!!formError?.reciptDriver}
                                    error={formError?.reciptDriver}
                                    selectedOption={formReciName?.reciptDriver}
                                    components={{ Menu: CustomControl }}
                                    setSelectedOption={(data) => {
                                        setFormReciName({ ...formReciName, reciptDriver: data })
                                        handleReciChange("reciptDriver", data?.value);

                                    }}
                                />
                            </div>
                            <div>

                                <Dropdown
                                    className='dropdownoforder'
                                    placeholder="Select Category"
                                    label="Select Category"
                                    classNamePrefix="_anvFloatSelect"
                                    boxClass="_anvFloatedSelect"
                                    name="reciCategory"
                                    options={exCategoryList ? exCategoryList : []}
                                    valueText="id"
                                    labelText="name"
                                    focus={!!formError?.reciCategory}
                                    error={formError?.reciCategory}
                                    selectedOption={formReciName?.reciCategory}
                                    components={{ Menu: CustomControl }}
                                    setSelectedOption={(data) => {
                                        setFormReciName({ ...formReciName, reciCategory: data })
                                        handleReciChange("reciCategory", data?.value);

                                    }}
                                />
                            </div>
                            <div>
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Description" : "Description"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="reciDescription"
                                    type="text"
                                    maxLength={12}
                                    focus={!!formError?.reciDescription}
                                    error={formError.reciDescription}
                                    value={formReciValue?.reciDescription}
                                    onChange={({ target: { name, value } }) =>
                                        handleReciChange(name, value)
                                    }
                                />
                            </div>
                            <div>
                                <Dropdown
                                    className='dropdownoforder'
                                    placeholder="Cash / Online"
                                    label="Mode of Payment"
                                    classNamePrefix="_anvFloatSelect"
                                    boxClass="_anvFloatedSelect"
                                    name="country"
                                    options={routeList ? routeList : []}
                                    valueText="value"
                                    labelText="label"

                                    focus={!!formError?.reciModeOFpay}
                                    error={formError?.reciModeOFpay}
                                    selectedOption={formReciName?.reciModeOFpay}
                                    components={{ Menu: CustomControl }}
                                    setSelectedOption={(data) => {
                                        setFormReciName({ ...formReciName, reciModeOFpay: data })
                                        handleReciChange("reciModeOFpay", data?.value);

                                    }}
                                />
                            </div>
                            <div className="anvOt_gridBxDr ">
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Amount" : "Amount"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="reciAmount"
                                        type="text"
                                        onKeyDown={handleKeyDown}

                                        focus={!!formError?.reciAmount}
                                        error={formError.reciAmount}
                                        value={formReciValue?.reciAmount}
                                        onChange={({ target: { name, value } }) =>
                                            handleReciChange(name, value)
                                        }
                                    />
                                </div>
                                <div className="anvOt_inputField ">
                                    <Dropdown
                                        className='dropdownoforder'
                                        // classNamePrefix="anvDrop_down"
                                        placeholder="Currency"
                                        label="Currency"
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect"
                                        name="reciCurrency"
                                        options={currencyList2 ? currencyList2 : []}
                                        valueText="value"
                                        labelText="label"
                                        focus={!!formError?.reciCurrency}
                                        error={formError?.reciCurrency}
                                        selectedOption={formReciName?.reciCurrency}
                                        components={{ Menu: CustomControl }}
                                        setSelectedOption={(data) => {
                                            setFormReciName({ ...formOrderName, reciCurrency: data })
                                            handleReciChange("reciCurrency", data?.value);

                                        }}
                                    />
                                </div>
                            </div>
                        </div>


                    </>
                }
            />


            <ConfirmModal
                isOpen={openDel}
                toggle={() => setOpenDel(!openDel)}
                onSubmit={() => handleDelete()}
            />


        </>
    );
};
