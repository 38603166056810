import React, { useEffect, useState } from "react";
import { Label, Modal, } from "reactstrap";
import { components } from "react-select";
import { InputField } from "../../Component/Common/InputField";
import {
    addBranchApi,
    getBranch,

} from "../../services";
import { toast } from "react-toastify";
import { addCustomerFullValiadtion, addCustomerValiadtion, addEditCustomerValiadtion, addResearchSchema, editCustomerFullValiadtion, modalFileSchema, modalInputSchema } from "../../helper/errorSchema";
import { addCustomerTabList, checkFormError, customerTabList, formatDate, handleKeyDown } from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";

import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";

import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { addCustomer, deleteCustomer, getAllCustomer, singleCustomerDetails, updateActiveCustomer, updateActiveResearch, updateCustomer, uploadExcelCustomer } from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { addCountryApi, getCountry } from "../../services/countryService";
import mytruck from "../../../image/tanker.png"
import { addCurrencyApi, getCurrency } from "../../services/currencyService";
import InputModal from "../../Component/Common/ConstomModal/InputModal";

export const CommonCustomer = (props) => {

    const { isOpen, setIsOpen, routeCallBack } = props


    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        name: "",
        email: "",
        mobile_number: ""
    };

    const branchObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"
    };

    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true
    };
    const currencyObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };




    const orderObj = {
        email: "",
        branch: '',
        name: "",
        is_web_access: false,
        mobile_number: "",
        password: "",
        confirm_password: '',
        taxation_number: '',
        credit_limit: "",
        currency: "",
        contract: "",
        address_one: "",
        address_two: "",
        country: "",
        city: '',
        addBranch: "",
        addCurrency: "",
        addCountry: "",
        openName: "",
        isActive: true


    }
    const orderObjName = {
        email: "",
        branch: '',
        name: "",
        is_web_access: false,
        mobile_number: "",
        password: "",
        confirm_password: '',
        taxation_number: '',
        credit_limit: "",
        currency: "",
        contract: "",
        address_one: "",
        address_two: "",
        country: "",
        city: '',
        addBranch: "",
        addCurrency: "",
        addCountry: "",
        openName: "",
        isActive: true
    }
    const queryClient = useQueryClient();

    const [isEdit, setIsEdit] = useState(false);
    const [activeModalTab, setActiveModalTab] = useState(1)
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [initialformOrderValue, setInitialFormOrderValue] = useState(orderObj)
    const [formError, setFormError] = useState("");
    const [isLoadding, setIsLoadding] = useState(false)
    const [branchList, setBranchList] = useState()
    const [countryList, setCountryList] = useState()
    const [currencyList, setCurrencyList] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [updateId, setUpdateId] = useState("")


    useEffect(() => {
        getBranchList()
        getCountryList()
        getCurrencyList()

    }, [])

    const getBranchList = async () => {
        const res = await getBranch(branchObj)
        setBranchList(res?.results)

    }

    const getCountryList = async () => {
        const res = await getCountry(countryObj)
        setCountryList(res?.results)

    }

    const getCurrencyList = async () => {
        const res = await getCurrency(currencyObj)

        setCurrencyList(res?.results)

    }


    const clearAll = () => {
        setFormOrderValue({
            email: "",
            branch: '',
            name: "",
            is_web_access: false,
            mobile_number: "",
            password: "",
            confirm_password: '',
            taxation_number: '',
            credit_limit: "",
            currency: "",
            contract: "",
            address_one: "",
            address_two: "",
            country: "",
            city: '',
            addBranch: "",
            addCurrency: "",
            addCountry: "",
            openName: "",

        })
        setFormOrderName({
            email: "",
            branch: '',
            name: "",
            is_web_access: false,
            mobile_number: "",
            password: "",
            confirm_password: '',
            taxation_number: '',
            credit_limit: "",
            currency: "",
            contract: "",
            address_one: "",
            address_two: "",
            country: "",
            city: '',
            addBranch: "",
            addCurrency: "",
            addCountry: "",
            openName: "",

        })

        // setIsEdit(false);
        setFormError("")

    }


    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };


    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addCustomerFullValiadtion);
            setValidationError(error);
        }
    };

    const validationHnalde = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue },
            isEdit ? addEditCustomerValiadtion : addCustomerValiadtion
        );

      
        setValidationError(validationResult)
        if (validationResult === true) {
            setActiveModalTab(2)
        }
    }

    const onOrderSubmit = async () => {
      
        const validationResult = await checkFormError(
            { ...formOrderValue },
            isEdit ? editCustomerFullValiadtion : addCustomerFullValiadtion
        );


        const payload = {
          
        };

      Object.keys(formOrderValue).map((item, index) => {
          if (formOrderValue[item] !== initialformOrderValue[item]) {
              // formData.append(item, formOrderValue[item]);
             
              payload[item] = formOrderValue[item]
              if(item==="branch"){
                  payload["branch"] =  formOrderValue?.branch.map((i) =>  i.value)
              }
             
          }
          if(item==="is_web_access"){
              payload["is_web_access"] = formOrderValue.is_web_access
          }
          if(item==="is_active"){
              payload["is_active"] = formOrderValue.is_active
          }
      })


      if (isEdit === true) {
        setIsLoadding(true)
        handleUpdate();
    } else {
        if (validationResult === true) {
            setIsLoadding(true)
        handleAdd(payload);
        }else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    }
    };

    const handleUpdate = () => {
        const formData = new FormData();
        if(isOpen===true){
          Object.keys(formOrderValue).map((item, index) => {
            if (formOrderValue[item] !== initialformOrderValue[item]) {
                formData.append(item, formOrderValue[item]);
            }
        })
        }
        
        // if(isOpenPassword===true){
        //   Object.keys(formOrderValue).map((item, index) => {
        //     if (formOrderValue[item] !== initialformOrderValue[item]) {
        //         formData.append(item, formOrderValue[item]);
        //     }
        // })
        // }
        const payload = {
            formdata: formData,
            id: updateId
        }

        updateMutation.mutate(payload);
     }
    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };


    const addMutation = useMutation(addCustomer, {
        onSuccess: (res) => {
            if (res?.success) {
                setUpdateId(res?.results?.id)
                setActiveModalTab(3)
                setIsEdit(true)
                onSuccessResponse(res?.message);
                routeCallBack(res?.results)
            } else {
                setIsLoadding(false)
                onErrorResponse(res?.error);
            }
        },
    });

    const updateMutation = useMutation(updateCustomer, {
        onSuccess: (res) => {
            if (res?.success) {
                setIsEdit(false);
                onSuccessResponse(res?.message);
                setIsOpen(false)
                setIsLoadding(false)
                setActiveModalTab(1)
                clearAll()
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success", { position: "bottom-left" });
        queryClient.refetchQueries("getAll");
        // setIsOpen(false);
        setIsLoadding(false)
        clearAll()
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
    };

    const handleModalActive = (data) => {
        setActiveModalTab(data?.value)
    }


    /// ----------- add Branch ,currency and country ---------
    const CustomControl = ({ children, ...props }) => (
        <components.Menu {...props}>
            <button className="addMoreBtn" onClick={() => addNewEntryHandle(props?.selectProps?.placeholder, props?.selectProps?.inputValue)}>+ Add New {props?.selectProps?.inputValue ? ` -> "${props?.selectProps?.inputValue}"` : ""}</button>
            {children}

        </components.Menu>
    );

    const addNewEntryHandle = (PlaceName, inputvalue) => {

        switch (PlaceName) {
            case "Select Branch":
                setFormOrderValue({ ...formOrderValue, addBranch: inputvalue, openName: "branch" })
                setOpenModal(true)

                break;

            case "Select Currency":
                setFormOrderValue({ ...formOrderValue, addCurrency: inputvalue, openName: "currency" })
                setOpenModal(true)
                break;

            case "Select Country":
                setFormOrderValue({ ...formOrderValue, addCountry: inputvalue, openName: "country" })
                setOpenModal(true)
                break;

            default:
                break;
        }
    }

    const addNewSubmit = async (value) => {


        const validationResult = await checkFormError(
            { name: value === "branch" ? formOrderValue.addBranch : value === "currency" ? formOrderValue?.addCurrency : formOrderValue.addCountry },
            modalInputSchema
        )

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate();
            } else {
                handleAddNew(value);
            }
        } else {
            setFormError(validationResult);
            setIsLoadding(false)
        }

    };

    const handleAddNew = (value) => {

        const payload = {
            name: value === "branch" ? formOrderValue.addBranch : value === "currency" ? formOrderValue?.addCurrency : formOrderValue.addCountry,
        }

        value === "branch" ? addNewBranchMutation.mutate(payload) : value === "currency" ? addNewCurrencyMutation.mutate(payload) : addNewCountryMutation.mutate(payload);

    }



    const addNewBranchMutation = useMutation(addBranchApi, {
        onSuccess: (res) => {
            if (res?.success) {
                // onSuccessResponse(res?.message);

                setFormOrderName({ ...formOrderName, branch: { data: res?.results, label: res?.results?.name, value: res?.results?.id }, addBranch: "" })
                setFormOrderValue({ ...formOrderValue, branch: res?.results?.id, addbranch: "" })

                setOpenModal(false)
                getBranchList()
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    const addNewCurrencyMutation = useMutation(addCurrencyApi, {
        onSuccess: (res) => {
            if (res?.success) {
                // onSuccessResponse(res?.message);

                setFormOrderName({ ...formOrderName, currency: { data: res?.results, label: res?.results?.name, value: res?.results?.id }, addCurrency: "" })
                setFormOrderValue({ ...formOrderValue, currency: res?.results?.id, addCurrency: "" })
                setOpenModal(false)
                getCurrencyList()
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    const addNewCountryMutation = useMutation(addCountryApi, {
        onSuccess: (res) => {
            if (res?.success) {
                // onSuccessResponse(res?.message);

                setFormOrderName({ ...formOrderName, country: { data: res?.results, label: res?.results?.name, value: res?.results?.id }, addCountry: "" })
                setFormOrderValue({ ...formOrderValue, country: res?.results?.id, addCountry: "" })
                setOpenModal(false)
                getCountryList()
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });



    return (
        <>
            {isOpen && <CustomOffcanvas
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(!isOpen)
                    setFormOrderValue({
                        email: "",
                        branch: '',
                        name: "",
                        is_web_access: false,
                        mobile_number: "",
                        password: "",
                        confirm_password: '',
                        taxation_number: '',
                        credit_limit: "",
                        currency: "",
                        image: "",
                        address_one: "",
                        address_two: "",
                        country: "",
                        city: ''
                    })

                    setIsEdit(false);
                }
                }
                isLoadding={isLoadding}
                onSubmit={(e) => +activeModalTab === 1 ?  validationHnalde():onOrderSubmit(e)}
                title={isEdit ? "Edit Customer" : "Add Customer"}
                submitLabel={+activeModalTab === 2 ? "Save" : +activeModalTab === 3 ? "Upload" :"Continue"}
                inputProps={

                    <>
                        {isOpen && <div div className='TabHeadofmodal'> <Tabs TabList={addCustomerTabList} handleActive={handleModalActive} activeTab={+activeModalTab} /></div>}
                        {+activeModalTab === 1 && <div className="anvMaintab_container">
                            <div className="anvOt_gridBx_modal">

                                <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField ">
                                            <Dropdown
                                                className='dropdownoforder'
                                                classNamePrefix="_anvFloatSelect"
                                                boxClass="_anvFloatedSelect"
                                                label="Branch"
                                                placeholder="Select Branch"
                                                name="branch"
                                                options={branchList ? branchList : []}
                                                valueText="id"
                                                labelText="name"
                                                isMulti={true}
                                                // components={{ Menu: CustomControl }}
                                                focus={!!formError?.branch}
                                                error={formError?.branch}
                                                selectedOption={formOrderName?.branch}
                                                // onMenuScrollToBottom={loadMoreOptions}
                                                setSelectedOption={(data) => {

                                                    setFormOrderName({ ...formOrderName, branch: data })
                                                    handleOrderChange("branch", data);

                                                }}
                                            />
                                    </div>
                                </div>
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input orderModal_Input"
                                        label={isEdit ? "Customer Name" : "Customer Name"}
                                        labelClass="FormLableKaran"
                                        placeholder="Customer Name"
                                        name="name"
                                        type="text"
                                        focus={!!formError?.name}
                                        error={formError.name}
                                        value={formOrderValue?.name}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Email ID (It will be used for Login)" : "Email ID (It will be used for Login)"}
                                        labelClass="FormLableKaran"
                                        placeholder="Email ID"
                                        name="email"
                                        type="text"
                                        focus={!!formError?.email}
                                        error={formError.email}
                                        value={formOrderValue?.email}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>
                                <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField ">

                                        <InputField
                                            className="modal_input orderModal_Input"
                                            label={isEdit ? "Contact Number" : "Contact Number"}
                                            labelClass="FormLableKaran"
                                            placeholder="Add Contact Number"
                                            name="mobile_number"
                                            type="text"
                                            maxLength={12}
                                            onKeyDown={handleKeyDown}
                                            focus={!!formError?.mobile_number}
                                            error={formError.mobile_number}
                                            value={formOrderValue?.mobile_number}
                                            onChange={({ target: { name, value } }) =>
                                                handleOrderChange(name, value)
                                            }
                                        />

                                    </div>
                                </div>
                                <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField checkboxtext">
                                        <InputField
                                            className="modal_input "

                                            labelClass="FormLableKaran"
                                            placeholder="Company Registration Number"
                                            name="is_web_access"
                                            type="checkbox"
                                            focus={!!formError?.is_web_access}
                                            error={formError.is_web_access}
                                            value={formOrderValue?.is_web_access}
                                            checked={formOrderValue.is_web_access === true}
                                            onChange={({ target: { name, checked } }) => {
                                                handleOrderChange(name, checked)
                                            }
                                            }
                                        />
                                        <sapn className='checkboxTextbox'>Web App Access</sapn>

                                    </div>
                                </div>
                                {formOrderValue?.is_web_access &&
                                    <div className="anvOt_inputField ">

                                        <InputField
                                            className="modal_input orderModal_Input"
                                            label={isEdit ? "Set Password" : "Set Password"}
                                            labelClass="FormLableKaran"
                                            placeholder="Enter Password"
                                            name="password"
                                            type="password"
                                            focus={!!formError?.password}
                                            error={formError.password}
                                            value={formOrderValue?.password}
                                            onChange={({ target: { name, value } }) =>
                                                handleOrderChange(name, value)
                                            }
                                        />

                                    </div>
                                }
                                {formOrderValue?.is_web_access &&
                                    <div className="anvOt_inputField ">

                                        <InputField
                                            className="modal_input orderModal_Input"
                                            label={isEdit ? "Repeat Password" : "Repeat Password"}
                                            labelClass="FormLableKaran"
                                            placeholder="Repeat Password"
                                            name="confirm_password"
                                            type="password"
                                            focus={!!formError?.confirm_password}
                                            error={formError.confirm_password}
                                            value={formOrderValue?.confirm_password}
                                            onChange={({ target: { name, value } }) =>
                                                handleOrderChange(name, value)
                                            }
                                        />

                                    </div>
                                }
                                <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField ">

                                        <InputField
                                            className="modal_input orderModal_Input"
                                            label={isEdit ? "Taxation Number" : "Taxation Number"}
                                            labelClass="FormLableKaran"
                                            placeholder="Company Registration Number"
                                            name="taxation_number"
                                            type="text"
                                            maxLength={15}
                                            focus={!!formError?.taxation_number}
                                            error={formError.taxation_number}
                                            value={formOrderValue?.taxation_number}
                                            onChange={({ target: { name, value } }) =>
                                                handleOrderChange(name, value)
                                            }
                                        />

                                    </div>
                                </div>
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Credit Limit" : "Credit Limit"}
                                        labelClass="FormLableKaran"
                                        placeholder="Enter Credit Limit"
                                        name="credit_limit"
                                        type="text"
                                        maxLength={6}
                                        onKeyDown={handleKeyDown}
                                        focus={!!formError?.credit_limit}
                                        error={formError.credit_limit}
                                        value={formOrderValue?.credit_limit}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>
                                <div className="anvOt_inputField ">
                                        <Dropdown
                                            className='dropdownoforder'
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect"
                                            label="Currency"
                                            placeholder="Select Currency"
                                            name="currency"
                                            options={currencyList ? currencyList : []}
                                            valueText="id"
                                            labelText="name"
                                            components={{ Menu: CustomControl }}
                                            focus={!!formError?.currency}
                                            error={formError?.currency}
                                            selectedOption={formOrderName?.currency}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, currency: data })
                                                handleOrderChange("currency", data?.value);

                                            }}
                                        />


                                </div>
                                

                            </div>
                        </div>}
                        {+activeModalTab === 2 && <div className="anvMaintab_container">
                            <div className="anvOt_gridBx_modal">

                                <div className="anvOt_outerFull">
                                    <span className="customerAddress">Customer Address Details</span>
                                </div>
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Address Line 1" : "Address Line 1"}
                                        labelClass="FormLableKaran"
                                        placeholder="Address Line 1"
                                        name="address_one"
                                        type="text"
                                        focus={!!formError?.address_one}
                                        error={formError.address_one}
                                        value={formOrderValue?.address_one}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Address Line 2" : "Address Line 2"}
                                        labelClass="FormLableKaran"
                                        placeholder="Address Line 2"
                                        name="address_two"
                                        type="text"
                                        focus={!!formError?.address_two}
                                        error={formError.address_two}
                                        value={formOrderValue?.address_two}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>


                                <div className="anvOt_inputField ">
                                        <Dropdown
                                            className='dropdownoforder'
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect"
                                            label="Country"
                                            placeholder="Select Country"
                                            name="country"
                                            options={countryList ? countryList : []}
                                            valueText="id"
                                            labelText="name"
                                            components={{ Menu: CustomControl }}
                                            focus={!!formError?.country}
                                            error={formError?.country}
                                            selectedOption={formOrderName?.country}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, country: data })
                                                handleOrderChange("country", data?.value);

                                            }}
                                        />
                                </div>
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "City" : "City"}
                                        labelClass="FormLableKaran"
                                        placeholder="city"
                                        name="city"
                                        type="text"
                                        focus={!!formError?.city}
                                        error={formError.city}
                                        value={formOrderValue?.city}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />

                                </div>


                            </div>
                        </div>}
                        {+activeModalTab === 3 && <div className="anvMaintab_container">
                            <div className="anvOt_gridBx_modal">

                               
                                
                                

                            <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField ">

                                        <InputField
                                            className="modal_input orderModal_Input"
                                            label={isEdit ? "Attach Contract" : "Attach Contract"}
                                            labelClass="FormLableKaran"
                                            placeholder="Add Remark"
                                            name="contract"
                                            type="file"
                                            focus={!!formError?.contract}
                                            error={formError.contract}
                                            // value={formOrderValue?.remark}
                                            onChange={({ target: { name, files } }) =>
                                                handleOrderChange(name, files[0])
                                            }


                                        />

                                    </div>
                                </div>


                            </div>
                        </div>}
                    </>

                }
            />}



            {openModal && < InputModal
                isOpen={openModal}
                headerName={isEdit ? "Edit Branch" : formOrderValue.openName === "branch" ? "Add Branch" : formOrderValue.openName === "currency" ? "Add Currency" : "Add Country"}
                toggle={() => setOpenModal(!openModal)}
                submitLabel={isEdit ? "Update Branch" : "Continue"}
                onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
                // disabled={isEdit ? inputValue.name === disableInp.name : ""}
                isLoadding={isLoadding}
                inputProps={

                    <InputField
                        className="modal_input"
                        label={isEdit ? "Edit Branch" : formOrderValue.openName === "branch" ? "Branch Name" : formOrderValue.openName === "currency" ? "Currency Name" : "Country Name"}
                        labelClass="modal_label"
                        labelSpan="*"
                        labelSpanClass="style_for_star"
                        placeholder={formOrderValue.openName === "branch" ? "Branch Name" : formOrderValue.openName === "currency" ? "Currency Name" : "Country Name"}
                        name={formOrderValue.openName === "branch" ? "addBranch" : formOrderValue.openName === "currency" ? "addCurrency" : "addCountry"}
                        focus={formOrderValue.openName === "branch" ? !!formError.addBranch : formOrderValue.openName === "currency" ? !!formError.addCurrency : !!formError.addCountry}
                        error={formOrderValue.openName === "branch" ? formError.addBranch : formOrderValue.openName === "currency" ? formError.addCurrency : formError.addCountry}
                        value={formOrderValue.openName === "branch" ? formOrderValue.addBranch : formOrderValue.openName === "currency" ? formOrderValue.addCurrency : formOrderValue.addCountry}
                        onChange={({ target: { name, value } }) =>
                            handleOrderChange(name, value)
                        }
                    />
                }
            />
            }

        </>
    );
};
