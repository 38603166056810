import React, { useEffect, useState } from "react";
import { Loader } from "./Loader";
import mytruck from "../../../image/tanker.png"
// import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { LiaSortSolid, LiaSortDownSolid, LiaSortUpSolid } from "react-icons/lia";

import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
const CustomTable = (props) => {
  const { data, column, boxClass, tableLoading, tableClass } = props;

  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState([]);
  const [sortColumn, setSortColumn] = useState([]);


  useEffect(() => {
    setSortedData(data);
    setSortColumn(column)
  }, [data])



  const sortData = (column) => {
    let newData = [...sortedData];

    newData.sort((a, b) => {
  
      if (sortOrder === 'asc') {
        setSortOrder("desc")
        return a[column] > b[column] ? 1 : -1;
      } else {
        setSortOrder("asc")
        return a[column] < b[column] ? 1 : -1;
      }
    });

    setSortedData(newData);
  };



  return (
    <div className={boxClass + " tabFix"}>
      <table className={tableClass + " table tabNew"}>
        <thead className="table_head_style">


          <tr style={{ backgroundColor: "#44447c" }}>
            {sortColumn?.map((item, i) => (
              <>
                <TableHeadItems key={i} item={item} setSortOrder={setSortOrder}
                  sortData={sortData}
                  sortOrder={sortOrder}
                  index={i}
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn} />

              </>
            ))}
          </tr>
        </thead>
        <tbody className="table_body_style">
          {tableLoading || sortedData?.length <= 0 ? (
            <tr >
              <td colSpan={10} rowSpan={1}>
                {tableLoading ? (
                  <div className="loaderGif"> <img src={mytruck} alt="" /></div>
                ) : (
                  <span className="d-flex justify-content-center">
                    No data!
                  </span>
                )}
              </td>
            </tr>
          ) : (
            sortedData?.map((item, i) => (
              <>
                <TableRow key={i} item={item} column={column} />
              </>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableHeadItems = ({ item, setSortOrder, sortData, sortOrder, index, sortColumn, setSortColumn }) => (

  <th className={item.className}

  >{item?.heading}{item?.sortName === "desc" ? <span className="sortFortable"><TiArrowSortedUp className="upsort fontsizeUPArrow" /><LiaSortDownSolid className="downsort pointerShort" onClick={() => {
    setSortOrder('asc');
    sortData(item?.value);
    let test = [...sortColumn]
    test[index].sortName = "asc"
    setSortColumn(test)
  }} /></span> : item?.sortName === "asc" ? <span className="sortFortable" ><LiaSortUpSolid className="upsort pointerShort" onClick={() => {
    setSortOrder('desc');
    sortData(item?.value);
    let test = [...sortColumn]
    test[index].sortName = "desc"
    setSortColumn(test)
  }} /><TiArrowSortedDown className="downsort fontsizeOFArrow" /></span> : <span className="sortFortable"><LiaSortUpSolid className="upsort pointerShort" onClick={() => {
    setSortOrder('desc');
    sortData(item?.value);
    let test = [...sortColumn]
    test[index].sortName = "desc"
    setSortColumn(test)
  }} /><LiaSortDownSolid className="downsort pointerShort" onClick={() => {
    setSortOrder('asc');
    sortData(item?.value);
    let test = [...sortColumn]
    test[index].sortName = "asc"
    setSortColumn(test)
  }} /></span>} </th>
);

const TableRow = ({ item, column }) => (
  <tr >
    {column?.map((colItem, i) => {
      return (
        <>
          <td key={i}>{item[`${colItem.value}`]}</td>
        </>
      );
    })}
  </tr>
);

export default CustomTable;
