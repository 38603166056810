import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";

import { modalBarnchSchema, modalInputSchema } from "../../helper/errorSchema";
import {
  branchTabList,
  breadcrumbsMenu,
  checkFormError,
  pageCount,
} from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import {
  addBranchApi,
  deleteBranchApi,
  getBranch,
  updateActiveBranch,
  updateBranchApi,
} from "../../services";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import CheckboxField from "../../Component/Common/CheckboxField";
import mytruck from "../../../image/tanker.png";
import { getStorage } from "../../helper/storage";
import {
  getCompanyList,
  getFundAllocationStateListBackend,
} from "../../services/companyService";
import NewGoogleMapComp from "../../Component/Common/MapComponent/NewGoogleMapComp";
import { GoogleMapsLoader } from "../../Component/Common/MapComponent/GoogleMapsLoader";
import AutocompleteInput from "../../Component/Common/MapComponent/AutocompleteInput";
import { Geocoder } from "@react-google-maps/api";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import { useNavigate } from "react-router-dom";

const dummyDrop = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
  { label: "three", value: 3 },
];

const fundDropdownListStatic = [
  { label: "Financial Approval", value: -1 },
  { label: "Client Approval", value: 1 },
  { label: "Client Approved", value: 2 },
  { label: "Loading Point", value: 3 },
  { label: "FM Approval", value: 4 },
  { label: "Border Clearence", value: 6 },
  { label: "Offloading Point", value: 7 },
];
const formObj = {
  name: "",
  company: "",
  fund_allocation_state: "",
  syntax: "",
  formatted_address: "",
  // radius: "",
  geo_fencing: {},
  coordinates: { longitude: "", latitude: "" },
};

export const Branch = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [fundAllocationStateList, setFundAllocationstateList] = useState([]);
  const [loaderForList, setLoaderForList] = useState(false);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    limit: 20,
    status: 1,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length
    ? Object.values(userLogin?.user_permissions)?.filter((i) =>
        i?.module_name?.includes("Branch")
      )[0]?.permissions
    : [];
  const [polygonValidataion, setPolygonValidation] = useState(false);

  const { data, isSuccess } = useQuery(
    ["getBranch", debouncedFilter],
    () => getBranch(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );
  const navigate = useNavigate();

  useEffect(() => {
    getCompnayList();
  }, []);

  useEffect(() => {
    getFundAllocationStateList();
  }, []);

  useEffect(() => {
    if (data) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [data]);

  const getCompnayList = async () => {
    const res = await getCompanyList();
    setCompanyList(res?.results);
  };

  const getFundAllocationStateList = async () => {
    const res = await getFundAllocationStateListBackend();
    setFundAllocationstateList(res?.results);
  };

  const [inputValue, setInputValue] = useState(formObj);
  const [inputError, setInputError] = useState({
    name: "",
    count: "",
    company: "",
    fund_allocation_state: "",
    syntax: "",
    geo_fencing: {},
    // radius: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    count: "",
    company: "",
    fund_allocation_state: "",
    syntax: "",
    // radius: "",
    formatted_address: "",
  });

  const closeModal = () => {
    setIsOpen(true);
    setIsEdit(false);
    setInputValue(formObj);
    setInputError("");
    setPolygonValidation(false);
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      {
        name: inputValue.name,
        company: inputValue?.company,
        syntax: inputValue?.syntax,
        // radius: inputValue?.radius,
      },

      modalBarnchSchema
    );
    if (validationResult === true) {
      if (
        inputValue?.geo_fencing === null ||
        (typeof inputValue?.geo_fencing === "object" &&
          inputValue?.geo_fencing !== null &&
          Object.keys(inputValue.geo_fencing).length === 0)
      ) {
        setPolygonValidation("Please select the polygon first");
      } else {
        setPolygonValidation(false);
        // setIsLoadding(true);
        if (isEdit === true) {
          handleUpdate();
        } else {
          handleAdd();
        }
      }
    } else {
      setInputError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };

  const handleAdd = () => {
    const payload = {
      ...inputValue,
    };
    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };
  const handleEditAction = (item) => {
    setDisableInp({
      name: item.name,
      company: item?.company,
      syntax: item?.syntax,
      fund_allocation_state: item?.fund_allocation_state,
      formatted_address: item?.formatted_address,
      // radius: Math.floor(item?.radius),
      coordinates: item?.coordinates,
    });
    setInputValue({
      id: item.id,
      name: item.name,
      company: item?.company,
      syntax: item?.syntax,
      fund_allocation_state: item?.fund_allocation_state,
      formatted_address: item?.formatted_address,
      // radius: Math.floor(item?.radius),
      geo_fencing: item?.geo_fencing,
      coordinates: item?.coordinates,
    });
    setIsOpen(true);
    setIsEdit(true);
    setInputError("");
  };

  const handleUpdate = () => {
    const payload = {
      ...inputValue,
    };
    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const deleteMutation = useMutation(deleteBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const onSuccessResponse = (message) => {
    toast.success(message?.message ? message?.message : "success", {
      position: "bottom-left",
    });
    queryClient.refetchQueries("getBranch");
    setIsOpen(false);
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const updateActiveMutation = useMutation(updateActiveBranch, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleBranchDetails = (data) => {
    navigate("/branch-details", { state: data });
  };

  const column = [
    { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },
    {
      heading: "Branch Name",
      className: "sorting_asc",
      value: "branch_name",
      sortName: "",
    },
    {
      heading: "Company Name",
      className: "sorting_asc",
      value: "company_name",
      sortName: "",
    },
    {
      heading: "Syntax",
      className: "sorting_asc",
      value: "syntax",
      sortName: "",
    },
    {
      heading: "Fund Allocation State",
      className: "sorting_asc",
      value: "fund_allocation_state_name",
      sortName: "",
    },
    { heading: "Action", className: "sorting", value: "action", sortName: "" },
  ];

  const rowDataList = () => {
    const listArray = data?.results?.map((item, i) => ({
      ...item,
      check: (
        <>
          <CheckboxField type="checkbox" name={item?.title} />
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      branch_name: (
        <>
          <span
            className="branchName_tbl_fx"
            onClick={() => handleBranchDetails(item)}
          >
            {item?.name}
          </span>
        </>
      ),
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
      action: (
        <>
          <div className="actionBtn">
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)}
              value={item?.is_active}
            />

            {userLogin?.user_type === 1 ? (
              <span className="_faEdit" onClick={() => handleEditAction(item)}>
                <FiEdit />
              </span>
            ) : (
              permissions[3] && (
                <span
                  className="_faEdit"
                  onClick={() => handleEditAction(item)}
                >
                  <FiEdit />
                </span>
              )
            )}
            {userLogin?.user_type === 1 ? (
              <span
                className="_faDelete"
                onClick={() => hendleDeleteTag(item, true)}
              >
                <MdOutlineDelete />
              </span>
            ) : (
              permissions[4] && (
                <span
                  className="_faDelete"
                  onClick={() => hendleDeleteTag(item, true)}
                >
                  <MdOutlineDelete />
                </span>
              )
            )}
          </div>
        </>
      ),
    }));
    return listArray;
  };

  const getSeletTechnology = (id, data) => {
    const details = data?.find((item) => item?.id === id);
    return {
      label: details?.name,
      value: details?.id,
    };
  };

  const getSeletFund = (id, data) => {
    const details = data?.find((item) => item?.value === id);

    return {
      label: details?.label,
      value: details?.value,
    };
  };

  const handlePlaceSelected = ({ lat, lng, addressComponents }) => {
    const latStr = lat.toString();
    const lngStr = lng.toString();
    setInputValue((prev) => ({
      ...prev,
      coordinates: {
        ...prev.coordinates,
        latitude: latStr,
        longitude: lngStr,
      },
      formatted_address: addressComponents || "",
    }));
  };

  // const handleRadiusChange = (newRadius) => {
  //   setInputValue({ ...inputValue, radius: Math.floor(newRadius) });
  // };

  // const getFormattedAddress = async (lat, lng) => {
  //   const geocoder = new window.google.maps.Geocoder();
  //   const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };

  //   return new Promise((resolve, reject) => {
  //     geocoder.geocode({ location: latLng }, (results, status) => {
  //       if (status === "OK" && results[0]) {
  //         resolve(results[0].formatted_address);
  //       } else {
  //         reject("Failed to get address");
  //       }
  //     });
  //   });
  // };

  // const handleLocationChange = async (newLat, newLng) => {
  //   try {
  //     const formatted_address = await getFormattedAddress(newLat, newLng);
  //     setInputValue((prev) => ({
  //       ...prev,
  //       coordinates: {
  //         ...prev.coordinates,
  //         latitude: newLat,
  //         longitude: newLng,
  //       },
  //       formatted_address: formatted_address,
  //     }));
  //   } catch (error) {
  //     console.error("Error fetching the formatted address:", error);
  //   }
  // };

  const getFormattedAddress = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject("Failed to get address");
        }
      });
    });
  };

  const calculatePolygonCenter = (path) => {
    let latSum = 0;
    let lngSum = 0;
    const len = path.length;

    path.forEach((point) => {
      latSum += point[1];
      lngSum += point[0];
    });

    return {
      lat: latSum / len,
      lng: lngSum / len,
    };
  };

  const handlePolygonChange = async (polygonPath) => {
    if (!polygonPath?.coordinates) {
      return;
    }

    const center = calculatePolygonCenter(polygonPath.coordinates[0]);

    try {
      const formatted_address = await getFormattedAddress(
        center.lat,
        center.lng
      );

      setInputValue((prev) => ({
        ...prev,
        geo_fencing: {
          type: "Polygon",
          coordinates: polygonPath.coordinates,
        },
        formatted_address: formatted_address,
        coordinates: {
          latitude: center?.lat,
          longitude: center?.lng,
        },
      }));
    } catch (error) {
      console.error("Error fetching the formatted address:", error);
    }
  };

  return (
    <>
      <div className="ques_container">
        <Breadcrumbs
          active="Branch"
          action={
            userLogin?.user_type === 1 ? (
              <Button
                type="button"
                className="btn anime-btn ques_row_btn"
                onClick={() => closeModal()}
              >
                Add Branch
              </Button>
            ) : (
              permissions[2] && (
                <Button
                  type="button"
                  className="btn anime-btn ques_row_btn"
                  onClick={() => closeModal()}
                >
                  Add Country
                </Button>
              )
            )
          }
        />

        <div className="ques_container">
          <div className="anvTask_prog">
            <div className="anvInputs&fillters">
              <div className="inr_flex_mini ">
                <Dropdown
                  placeholder="Enable"
                  option={dummyDrop}
                  valueText="value"
                  labelText="label"
                  label="Enable"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                />
              </div>

              <div className="inr_flex">
                <InputField
                  placeholder="Search"
                  label="Search"
                  className="anvSearch_input"
                  value={filter?.name}
                  onChange={(e) =>
                    setFilter((s) => ({
                      ...s,
                      ...obj,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div className="__tbdTble_bxs">
            <div className="table-responsive">
              <CustomTable
                column={column}
                data={rowDataList()}
                tableLoading={loaderForList}
              />
            </div>
          </div>
        </div>
      </div>

      {data?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                inputValue?.count
                  ? inputValue?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));
                setInputValue({ ...inputValue, count: data });
              }}
            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(data?.count / filter?.limit)}
          />
        </div>
      )}

      <GoogleMapsLoader>
        <InputModal
          className="mapView_modal modal_width locationMap_modal"
          mapView={true}
          isOpen={isOpen}
          headerName={isEdit ? "Edit Branch" : "Branch"}
          toggle={() => setIsOpen(!isOpen)}
          submitLabel={isEdit ? "Update Branch" : "Add Branch"}
          onSubmit={(e) => onSubmit(e)}
          disabled={
            isEdit
              ? inputValue.name === disableInp.name &&
                inputValue.fund_allocation_state ===
                  disableInp.fund_allocation_state &&
                inputValue?.syntax === disableInp.syntax &&
                inputValue?.company === disableInp.company &&
                // inputValue?.radius === disableInp.radius &&
                inputValue?.formatted_address === disableInp.formatted_address
              : !inputValue?.name ||
                !inputValue?.syntax ||
                !inputValue?.company ||
                !inputValue?.fund_allocation_state ||
                // !inputValue?.radius ||
                !inputValue?.formatted_address
          }
          isLoadding={isLoadding}
          inputProps={
            <>
              <InputField
                className="modal_input"
                label={isEdit ? "Edit Branch" : "Branch Name"}
                labelClass="modal_label"
                labelSpan="*"
                labelSpanClass="style_for_star"
                placeholder="Enter Name"
                name="name"
                focus={!!inputError.name}
                error={inputError.name}
                value={inputValue.name}
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
              />
              <InputField
                className="modal_input"
                label={isEdit ? "Edit Branch syntax" : "Branch syntax"}
                labelClass="modal_label"
                labelSpan="*"
                labelSpanClass="style_for_star"
                placeholder="Enter syntax"
                name="syntax"
                focus={!!inputError.syntax}
                error={inputError.syntax}
                value={inputValue.syntax}
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
              />

              <div className="">
                <Dropdown
                  className="dropdownoforder"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  label="Company Name"
                  placeholder="Select Company Name"
                  name="company"
                  options={companyList}
                  focus={!!inputError.company}
                  error={inputError.company}
                  selectedOption={getSeletTechnology(
                    inputValue?.company,
                    companyList
                  )}
                  setSelectedOption={(data) => {
                    handleOnChange("company", data?.value);
                  }}
                  valueText="id"
                  labelText="name"
                />
              </div>

              <div className="anvBasis_flt">
                <div className="">
                  <Dropdown
                    placeholder="Select Fund Allocation State"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    label="Fund Allocation Step"
                    name="fund_allocation_state"
                    options={fundDropdownListStatic}
                    valueText="value"
                    labelText="label"
                    focus={!!inputError.fund_allocation_state}
                    error={inputError.fund_allocation_state}
                    selectedOption={getSeletFund(
                      inputValue?.fund_allocation_state,
                      fundDropdownListStatic
                    )}
                    setSelectedOption={(data) => {
                      handleOnChange("fund_allocation_state", data?.value);
                    }}
                  />
                </div>
              </div>

              <div className="_inpField_head">
                <h4>Mark Location</h4>
              </div>

              {/* <AutocompleteInput
                onPlaceSelected={handlePlaceSelected}
                value={inputValue?.formatted_address}
              /> */}

              <div className="__gMap_float">
                <AutocompleteInput
                  onPlaceSelected={handlePlaceSelected}
                  // value={formOrderValue?.formatted_address}
                />
              </div>

              <div className="_halfDiv">
                <InputField
                  className="modal_input"
                  label="Address Location"
                  labelClass="modal_label"
                  placeholder="Enter Location"
                  name="formatted_address"
                  value={inputValue?.formatted_address}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
              </div>

              {/* <InputField
                className="modal_input"
                label="Radius"
                labelClass="modal_label"
                labelSpan="*"
                labelSpanClass="style_for_star"
                placeholder="Enter Radius"
                name="radius"
                type="number"
                focus={!!inputError.radius}
                error={inputError.radius}
                value={inputValue?.radius}
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
              /> */}

              <div className="_longTitudeBx">
                <InputField
                  className="modal_input"
                  label="Latitude"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Latitude"
                  name="latitude"
                  value={inputValue?.coordinates?.latitude}
                  readOnly
                />

                <InputField
                  className="modal_input"
                  label="Longitude"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Longitude"
                  name="longitude"
                  value={inputValue?.coordinates?.longitude}
                  readOnly
                />
              </div>
              {polygonValidataion && polygonValidataion !== false && (
                <div style={{ color: "red", fontSize: "13px" }}>
                  {polygonValidataion}
                </div>
              )}
            </>
          }
          mapInputProps={
            <div className="_mapView_bxs">
              {/* <NewGoogleMapComp
                zoom={12}
                lat={inputValue?.coordinates?.latitude || "20.5937"}
                lng={inputValue?.coordinates?.longitude || "78.9629"}
                radius={Math.floor(inputValue?.radius) || 100}
                onLocationChange={handleLocationChange}
                onRadiusChange={handleRadiusChange}
              /> */}
              <NewGoogleMapComp
                zoom={12}
                lat={inputValue?.coordinates?.latitude || "20.5937"}
                lng={inputValue?.coordinates?.longitude || "78.9629"}
                locations={[]}
                onPolygonChange={handlePolygonChange}
                // onNewPolygonChange={handlePolygonChange}
              />
            </div>
          }
        />
      </GoogleMapsLoader>

      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
    </>
  );
};
