import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getCountry = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.country, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addCountryApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.country, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateCountryApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.country + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteCountryApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.country + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


export const updateActiveCountry = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.country + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};