import { apiEndPoints, methodType, performRequest } from "./_apiConfig";

export const getAllOrder = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.order, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addOrder = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.order, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleNewDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.research + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}
export const singleOrderDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.order + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const updateOrder = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.order + `/${data?.id}`,
        data.formdata,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteOrder = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.order + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


export const acceptAndRejectStatus = (data = {}) => {
    const payload = {

    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.order + `/${data?.id}` + `?action_type=${data?.action_type}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const getAllFleetforOrder = (data = {}) => {

    const payload = {}
    // return performRequest(methodType.GET, apiEndPoints.fleet + `?order_id=${data?.order_id}` +  `&limit=${data?.limit}` + `&search=${data?.name}` + `&offset=${data?.offset}` + `&nomination_status=${data?.nomination_status}` + `&branch_uuid=${data?.branch_uuid}`, payload, true)
    return performRequest(methodType.GET, apiEndPoints.fleet +  `?limit=${data?.limit}` + `&search=${data?.name}` + `&offset=${data?.offset}` + `&nomination_status=${data?.nomination_status}` + `&branch_uuid=${data?.branch_uuid}`, payload, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addFleetsForOrder = (data = {}) => {

    const payload = {
        fleet_data: data?.fleet_data
    }
    return performRequest(methodType.POST, apiEndPoints.order + `/${data?.id}/manage_order_fleet`, payload, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getdryCargo = (OrederID = {}) => {
    let data = {}
    return performRequest(methodType.GET, apiEndPoints.order + "/export_dry_cargo_csv" + `?order_id=${OrederID}`, data, true, false, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const gettankersCsv = (OrederID = {}) => {
    let data = {}
    return performRequest(methodType.GET, apiEndPoints.order + `/export_tankers_csv` + `?order_id=${OrederID}`, data, true, false, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

// rder/order/435cf9b9-2b47-424c-b79d-517c26958b83/manage_order_fleet

export const updateActiveOrder = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.order + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const financeApprovalOrderList = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.financeApproval , data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateFinancialApproval = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.order + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getFleetETAList = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.etaFleetOrder, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
