import * as Yup from "yup";
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter email"),
  password: Yup.string()
    .min(3, "minimum 3 characters required")
    .required("Required"),
});

export const chnagePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(6, "minimum 6 characters required!")
    .required("Required"),
  new_password: Yup.string()
    .notOneOf(
      [Yup.ref("old_password")],
      "New password cannot be the same as old meme"
    )
    .min(3, "minimum 6 characters required!")
    .required("Required"),
  confirm_password: Yup.string()
    .required("Required")
    .min(3, "minimum 6 characters required")
    .oneOf([Yup.ref("new_password")], "Passwords do not match"),
});

export const modalInputSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
});
export const fuelStationApiValidation = Yup.object().shape({
  title: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
    // url: Yup.string()
    // .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     'Enter correct url!'
    // )
    // .required('Please enter website'),
    url:Yup.string().url()
});

export const fleetGroupValidation = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
    codeList: Yup.array().of(
      Yup.object().shape({
        station_api: Yup.string().required("Required"),
        code: Yup.string().required("Required"),
      })
    ),
});

export const modalFileSchema = Yup.object().shape({
  excel_file: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
});


export const fleetModalSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
  measurement: Yup.string()
    .required("Required"),
  no_of_trailer: Yup.string()
    .required("Required"),
  product_type: Yup.string()
    .required("Required"),
    syntex_code: Yup.string()
    .required("Required"),
    code: Yup.string()
    .required("Required"),
});

export const productModalSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
  product_type: Yup.string()
    .required("Required"),
  unit: Yup.string()
    .required("Required")
});

export const createAiToolsSchema = Yup.object().shape({
  title: Yup.string(),
  category: Yup.string(),
  short_description: Yup.string().required("Required"),
  description: Yup.string(),
  webpage_link: Yup.string().url().required("Required"),
  pricing: Yup.string(),
  video_link: Yup.string().required("Required"),
  tags: Yup.array(
    Yup.object({
      label: Yup.string().required(),
      value: Yup.number().required()
    })
  ),
  // image: Yup.string().required("Required"),
  facebook_url: Yup.string().url(),
  twitter_url: Yup.string().url(),
  linkedin_url: Yup.string().url(),
  internal_remark: Yup.string(),
});
export const createAiToolsSchemaForImage = Yup.object().shape({
  title: Yup.string(),
  category: Yup.string(),
  short_description: Yup.string().required("Required"),
  description: Yup.string(),
  webpage_link: Yup.string().url().required("Required"),
  pricing: Yup.string(),
  video_link: Yup.string().required("Required"),

  tags: Yup.array(
    Yup.object({
      label: Yup.string().required(),
      value: Yup.number().required()
    })
  ),
  image: Yup.string().required("Required"),
  facebook_url: Yup.string().url(),
  twitter_url: Yup.string().url(),
  linkedin_url: Yup.string().url(),
  internal_remark: Yup.string(),
});

export const createCommunitySchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  slug: Yup.string().required("Required"),
  short_description: Yup.string().required("Required"),
  description: Yup.string(),
  // image: Yup.string().required("Required"),
  // cover_image: Yup.string().required("Required"),
  tags: Yup.array()
    .of(Yup.number()) // For example, validate each element as a string
    .min(1)
    .required("Required"),
  persona: Yup.number().required("Required"),
});

export const createCommunitySchemaForImage = Yup.object().shape({
  title: Yup.string().required("Required"),
  slug: Yup.string().required("Required"),
  short_description: Yup.string().required("Required"),
  description: Yup.string(),
  image: Yup.string().required("Required"),
  cover_image: Yup.string().required("Required"),
  tags: Yup.array()
    .of(Yup.number()) // For example, validate each element as a string
    .min(1)
    .required("Required"),
  persona: Yup.number().required("Required"),
});

export const questionBankSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
  question_type: Yup.string().required("Required"),
  question_category: Yup.string().required("Required"),
  question_options: Yup.array().when("question_type", {
    is: (option) =>
      option === "Single Choice" ||
      option === "Multi Choice" ||
      option === "Drop Down",
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("Required"),
          can_add_comment: Yup.boolean("must be boolean").required("Required"),
          points: Yup.string("must be number type")
            .matches(/^\d+$/, {
              message: "must be valid value",
              excludeEmptyString: true,
            })
            .required("Required"),
          comment_title: Yup.string().when("can_add_comment", {
            is: (test) => test === true,
            then: () =>
              Yup.string("Type of Investigation").required("Required"),
          }),
        })
      ),
  }),
});
// value.match(/^\d+$/) !== null);

export const questionnaireFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  industry: Yup.string().required("Required"),
  questionnaire_sections: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Required"),
    })
  ),
});

export const createUserSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  designation: Yup.string().required("Required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter email"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
    )
    .required("Required"),
  password2: Yup.string()
    .required("Required")
    .min(8, "minimum 8 characters required")
    .oneOf([Yup.ref("password")], "Password do not match"),
  industry: Yup.array()
    .min(1, "Select at least one branch *")
    .of(Yup.string().required("Required")),
  role: Yup.string().required("Required"),
  // industry: Yup.string().required("Required"),
});


export const addCategorySchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  icon: Yup.string().required("Required"),

});

export const addResearchSchema = Yup.object().shape({
  title: Yup.string().required("Required").min(3, "minimum 3 characters required!"),
  // subject: Yup.string().required("Required"),
  short_description: Yup.string(),
  description: Yup.string(),
  webpage_link: Yup.string().url().required("Required"),
  tags: Yup.array()
    .of(Yup.number()) // For example, validate each element as a string
    .min(1, 'required')
    .required('Array is required'),
  // image: Yup.string().required("Required"),
});

export const addResearchSchemaForImage = Yup.object().shape({
  title: Yup.string().required("Required").min(3, "minimum 3 characters required!"),
  // subject: Yup.string().required("Required"),
  short_description: Yup.string(),
  description: Yup.string(),
  webpage_link: Yup.string().url().required("Required"),
  tags: Yup.array()
    .of(Yup.number()) // For example, validate each element as a string
    .min(1, 'required')
    .required('Array is required'),
  image: Yup.string().required("Required"),
});

//////////////////////////////////////////////


export const addTruckFleet = Yup.object().shape({
  number: Yup.string().required("Required"),
  date: Yup.date().required(),

});



export const addCustomerValiadtion = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  // branch: Yup.string().required("Required"),
  branch: Yup.array(
    Yup.object({
      label: Yup.string().required(),
      value: Yup.string().required()
    })
  ),
  // branch: Yup.array()
  // .of(Yup.string()) // For example, validate each element as a string
  // .min(1, 'required')
  // .required('Array is required'),
  name: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  mobile_number: Yup.string().required("Required"),
  // password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  //       "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
  //     )
  // }),

  // confirm_password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(8, "minimum 8 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match")
  // }),
  // taxation_number: Yup.string().required("Required"),
  // credit_limit: Yup.string().required("Required"),
  // currency: Yup.string().required("Required"),
  // contract: Yup.string().required("Required"),

});

export const addEditCustomerValiadtion = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
//  branch: Yup.array()
//   .of(Yup.string()) // For example, validate each element as a string
//   .min(1, 'required')
//   .required('Array is required'),
  name: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  mobile_number: Yup.string().required("Required"),
  // password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  //       "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
  //     )
  // }),

  // confirm_password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(8, "minimum 8 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match")
  // }),
  // taxation_number: Yup.string().required("Required"),
  // credit_limit: Yup.string().required("Required"),
  // currency: Yup.string().required("Required"),
  // contract: Yup.string().required("Required"),

});

export const addCustomerFullValiadtion = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  branch: Yup.array(
    Yup.object({
      label: Yup.string().required(),
      value: Yup.string().required()
    })
  ),
  name: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  mobile_number: Yup.string().required("Required"),
  // password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  //       "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
  //     )
  // }),

  // confirm_password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(8, "minimum 8 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match")
  // }),
  // taxation_number: Yup.string().required("Required"),
  // credit_limit: Yup.string().required("Required"),
  // currency: Yup.string().required("Required"),
  // contract: Yup.string().required("Required"),
  // address_one: Yup.string().required("Required"),
  // address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  city: Yup.string().required("Required"),

});
export const editCustomerFullValiadtion = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  branch: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  mobile_number: Yup.string().required("Required"),
  // password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  //       "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
  //     )
  // }),

  // confirm_password: Yup.string().when('is_web_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(8, "minimum 8 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match")
  // }),
  // taxation_number: Yup.string().required("Required"),
  // credit_limit: Yup.string().required("Required"),
  // currency: Yup.string().required("Required"),
  // contract: Yup.string().required("Required"),
  // address_one: Yup.string().required("Required"),
  // address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  city: Yup.string().required("Required"),

});

export const addFleetValidationFirst = Yup.object().shape({
  truck_number: Yup.string().required("Required"),
  fleet_type: Yup.string().required("Required"),
  branch: Yup.string().required("Required"),
  // tare_weight: Yup.string().required("Required"),
  tracker_number: Yup.string().required("Required"),
  fleet_group: Yup.string().required("Required"),
  driver: Yup.string().required("Required"),

});

export const addFleetValidationSecond = Yup.object().shape({
  verbete_expire_date: Yup.string().required("Required"),
  insurance_expire_date: Yup.string().required("Required"),
  cross_border_payment_expire_date: Yup.string().required("Required"),
  inspection_expire_date: Yup.string().required("Required"),
  caderneta_expire_date: Yup.string().required("Required"),
  manifesto_expire_date: Yup.string().required("Required"),
  taxa_de_radio_expire_date: Yup.string().required("Required"),
  fire_extinguisher_expiry_date: Yup.string().required("Required"),
  registration_status: Yup.string().required("Required"),
  import_document_number: Yup.string().required("Required"),
})

export const addFleetValidation = Yup.object().shape({
  truck_number: Yup.string().required("Required"),
  fleet_type: Yup.string().required("Required"),
  branch: Yup.string().required("Required"),
  // tare_weight: Yup.string().required("Required"),
  // vin_number: Yup.string().required("Required"),
  // trailers: Yup.array(
  //   Yup.object({
  //     label: Yup.string().required(),
  //     value: Yup.number().required()
  //   })
  // ),
  fleet_group: Yup.string().required("Required"),
  driver: Yup.string().required("Required"),
  tracker_number:Yup.string().required("Required"),
  // livret: Yup.string().required("Required"),
  verbete_expire_date: Yup.string().required("Required"),
  insurance_expire_date: Yup.string().required("Required"),
  cross_border_payment_expire_date: Yup.string().required("Required"),
  inspection_expire_date: Yup.string().required("Required"),
  // caderneta_expire_date: Yup.string().required("Required"),
  // manifesto_expire_date: Yup.string().required("Required"),
  // taxa_de_radio_expire_date: Yup.string().required("Required"),
  // fire_extinguisher_expiry_date: Yup.string().required("Required"),
  // registration_status: Yup.string().required("Required"),
  // import_document_number: Yup.string().required("Required")
});


export const crossBorderValidtion = Yup.object().shape({
  country: Yup.string().required("Required"),
  document_type: Yup.string().required("Required"),
  cross_border_permit_date: Yup.string().required("Required"),
  fleet: Yup.string().required("Required"),
})


export const addTrailerValidationFirst = Yup.object().shape({
  trailer_number: Yup.string().required("Required"),
  trailer_type: Yup.string().required("Required"),
  branch: Yup.string().required("Required"),
  tare_weight: Yup.string().required("Required"),
  // vin_number: Yup.string().required("Required"),


});

export const addTrailerValidationSecond = Yup.object().shape({
  verbete_expire_date: Yup.string().required("Required"),
  insurance_expire_date: Yup.string().required("Required"),
  cross_border_payment_expire_date: Yup.string().required("Required"),
  inspection_expire_date: Yup.string().required("Required"),
  caderneta_expire_date: Yup.string().required("Required"),
  manifesto_expire_date: Yup.string().required("Required"),
  taxa_de_radio_expire_date: Yup.string().required("Required"),
  registration_status: Yup.string().required("Required"),
  import_document_number: Yup.string().required("Required"),
})

export const crossBorderValidtionForTrailer = Yup.object().shape({
  country: Yup.string().required("Required"),
  document_type: Yup.string().required("Required"),
  cross_border_permit_date: Yup.string().required("Required"),
  trailer: Yup.string().required("Required"),
})


export const addTrailerValidations = Yup.object().shape({
  trailer_number: Yup.string().required("Required"),
  trailer_type: Yup.string().required("Required"),
  branch: Yup.string().required("Required"),
  tare_weight: Yup.string().required("Required"),
  // vin_number: Yup.string().required("Required"),
  // livret: Yup.string().required("Required"),
  verbete_expire_date: Yup.string().required("Required"),
  // insurance_expire_date: Yup.string().required("Required"),
  // cross_border_payment_expire_date: Yup.string().required("Required"),
  // inspection_expire_date: Yup.string().required("Required"),
  caderneta_expire_date: Yup.string().required("Required"),
  manifesto_expire_date: Yup.string().required("Required"),
  taxa_de_radio_expire_date: Yup.string().required("Required"),
  // registration_status: Yup.string().required("Required"),
});



export const addTrailerModal = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
})

export const addOrderValidation = Yup.object().shape({
  // reference_number: Yup.string().required("Required"),
  customer_reference_number: Yup.string().required("Required"),
  customer: Yup.string().required("Required"),
  // branch: Yup.string().required("Required"),
  // pickup_date: Yup.string().required("Required"),
  // drop_date: Yup.string().required("Required"),
  route: Yup.string().required("Required"),
  product: Yup.string().required("Required"),
  qty: Yup.string().required("Required"),
  // remark: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  is_active: Yup.string().required("Required"),
})

export const addOrderClientValidation = Yup.object().shape({
  customer: Yup.string().required("Required"),
  pickup_date: Yup.string().required("Required"),
  drop_date: Yup.string().required("Required"),
  route: Yup.string().required("Required"),
  product: Yup.string().required("Required"),
  qty: Yup.string().required("Required"),
  // remark: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  is_active: Yup.string().required("Required"),
})

export const addLocationValidation = Yup.object().shape({
  // location_type: Yup.number,
  customer: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  contact_number: Yup.string().required("Required"),
  address_one: Yup.string().required("Required"),
  address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),

  city: Yup.string().required("Required"),


})

export const verifyTimeBranchSchema =Yup.object().shape({
  alert_start_time: Yup.string().required("Required"),
  alert_end_time: Yup.string().required("Required"),
})

export const addFuelStationValidation = Yup.object().shape({

  station_name: Yup.string().required("Required"),
  contact_number: Yup.string().required("Required"),
  address_one: Yup.string().required("Required"),
  address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pincode: Yup.string().required("Required"),

})



export const addDriverValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  mobile_number: Yup.string().required("Required"),
  branch: Yup.string().required("Required"),
  // emergency_mobile_number: Yup.string().required("Required"),
  is_mobile_access: Yup.boolean(),
  // opining_balance_date: Yup.string().required("Required"),
  // opining_balance: Yup.string().required("Required"),
  expense: Yup.array().of(
    Yup.object().shape({
      dates: Yup.string().required("Required"),
      amounts: Yup.string().required("Required"),
      currencies: Yup.string().required("Required")
        .nullable(),
    })
  ),
  // address_one: Yup.string().required("Required"),
  // address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  // pincode: Yup.string().required("Required"),

  // password: Yup.string().when('is_mobile_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().matches(
  //       /^(?=.*\d)(?=.*[!@#\$%\^&\*]).{6,}$/,
  //       "Password must contain one special case character and numbers, and have at least 6 characters"
  //     )
  // }),

  // confirm_password: Yup.string().when('is_mobile_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(6, "minimum 6 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match"),
  // }),

  // password: Yup.string().when('is_mobile_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().required("Required")
  //     .min(6, "minimum 6 characters required")
  // }),

  // confirm_password: Yup.string().when('is_mobile_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(6, "minimum 6 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match"),
  // }),
  license_number: Yup.string().required("Required"),
  license_expiry_date: Yup.string().required("Required"),
  // license_file: Yup.string().required("Required"),
  passport_number: Yup.string().required("Required"),
  passport_expiry_date: Yup.string().required("Required"),
  // passport_file: Yup.string().required("Required"),
  // contract_expiry_date: Yup.string().required("Required"),
  // contract_file: Yup.string().required("Required"),
  is_active: Yup.boolean(),
})
export const editDriverValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  mobile_number: Yup.string().required("Required"),
  emergency_mobile_number: Yup.string().required("Required"),
  is_mobile_access: Yup.boolean(),
  expense: Yup.array().of(
    Yup.object().shape({
      dates: Yup.string().required("Required"),
      amounts: Yup.string().required("Required"),
      currencies: Yup.string().required("Required")
        .nullable(),
    })
  ),
  // opining_balance_date: Yup.string().required("Required"),
  // opining_balance: Yup.string().required("Required"),
  // address_one: Yup.string().required("Required"),
  // address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  // pincode: Yup.string().required("Required"),
  // password: Yup.string().when('is_mobile_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () =>
  //     Yup.string().matches(
  //       /^(?=.*\d)(?=.*[!@#\$%\^&\*]).{6,}$/,
  //       "Password must contain one special case character and numbers, and have at least 6 characters"
  //     )
  // }),

  // confirm_password: Yup.string().when('is_mobile_access', {
  //   is: (test) => test === true, // Validation will apply when the checkbox is true
  //   then: () => Yup.string()
  //     .required("Required")
  //     .min(6, "minimum 6 characters required")
  //     .oneOf([Yup.ref("password")], "Password do not match"),
  // }),
  license_number: Yup.string().required("Required"),
  license_expiry_date: Yup.string().required("Required"),
  // license_file: Yup.string().required("Required"),
  passport_number: Yup.string().required("Required"),
  passport_expiry_date: Yup.string().required("Required"),
  // passport_file: Yup.string().required("Required"),
  // contract_expiry_date: Yup.string().required("Required"),
  // contract_file: Yup.string().required("Required"),
  is_active: Yup.boolean(),
})

export const MarkedDocumentVal = Yup.object().shape({
  date: Yup.string().required("Required"),
  comment: Yup.string().required("Required"),
})

export const addRouteDetails = Yup.object().shape({
  customer: Yup.string().required("Required"),
  from_location: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Location required"),
      value: Yup.string()
        .required("email required")
        .nullable(),

    })
  ),

  to_location: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Location required"),
      value: Yup.string()
        .required("email required")
        .nullable(),

    })
  ),
  route_name: Yup.string().required("Required"),
  kilometer: Yup.string().required("Required"),

})

export const addExpenseValidation = Yup.object().shape({

  expense: Yup.array().of(
    Yup.object().shape({
      category: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
      currency: Yup.string().required("Required"),
      // description: Yup.string().required("Required")
      //   .nullable(),
    })
  ),

})

export const addFuelValidation = Yup.object().shape({

  customer: Yup.string().required("Required"),
  from_location: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Location required"),
      value: Yup.string()
        .required("email required")
        .nullable(),

    })
  ),

  to_location: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Location required"),
      value: Yup.string()
        .required("email required")
        .nullable(),

    })
  ),
  route_name: Yup.string().required("Required"),
  kilometer: Yup.string().required("Required"),

  expense: Yup.array().of(
    Yup.object().shape({
      category: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
      // description: Yup.string().required("Required")
      //   .nullable(),
    })
  ),

  fuel_expenses: Yup.array().of(
    Yup.object().shape({
      fuel_station: Yup.string().required("Required"),
      ltr: Yup.string().required("Required"),
      // description: Yup.string().required("Required")
      //   .nullable(),
    })
  ),

})

export const addFuelSingleValidation = Yup.object().shape({

  customer: Yup.string().required("Required"),
  from_location: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Location required"),
      value: Yup.string()
        .required("email required")
        .nullable(),

    })
  ),

  to_location: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Location required"),
      value: Yup.string()
        .required("email required")
        .nullable(),

    })
  ),
  route_name: Yup.string().required("Required"),
  kilometer: Yup.string().required("Required"),

  

})
export const addExpenseFundValidation = Yup.object().shape({

  expense: Yup.array().of(
    Yup.object().shape({
      category: Yup.string().required("Required"),
      amount: Yup.string().required("Required")
        .min(1)
        .required("Required"),
      modeOfPayment: Yup.string().required("Required"),
      currency:Yup.string().required("Required"),
    })
  ),
  fuel_expenses: Yup.array().of(
    Yup.object().shape({
      fuel_station: Yup.string().required("Required"),
      qty: Yup.string().required("Required")
        .nullable(),
    })
  ),

})


export const addDriverReciptVali = Yup.object().shape({
  reciptDate: Yup.string().required("Required"),
  reciptDriver: Yup.string().required("Required"),
  reciCategory: Yup.string().required("Required"),
  reciDescription: Yup.string().required("Required"),
  reciModeOFpay: Yup.string().required("Required"),
  reciAmount: Yup.string().required("Required"),
  reciCurrency: Yup.string().required("Required"),
})

export const addTripReciptExpence = Yup.object().shape({
  reciptDate: Yup.string().required("Required"),
  reciCategory: Yup.string().required("Required"),
  reciDescription: Yup.string().required("Required"),
  reciAmount: Yup.string().required("Required"),
  reciCurrency: Yup.string().required("Required"),

})

export const addTripFuelExpence = Yup.object().shape({
  reciptDate: Yup.string().required("Required"),
  reciFleet: Yup.string().required("Required"),
  reciDescription: Yup.string().required("Required"),
  reciFuelStation: Yup.string().required("Required"),
  reciFuelType: Yup.string().required("Required"),
  // reciAmount: Yup.string().required("Required"),
  reciLtr: Yup.string().required("Required"),
  // reciRate: Yup.string().required("Required"),
  reciRoute: Yup.string().required("Required"),
})

export const addUserValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  mobile_number: Yup.string().required("Required"),
  alternative_mobile_number: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  password: Yup.string().when('is_web_access', {
    is: (test) => test === true, // Validation will apply when the checkbox is true
    then: () =>
      Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
      )
  }),

  confirm_password: Yup.string().when('is_web_access', {
    is: (test) => test === true, // Validation will apply when the checkbox is true
    then: () => Yup.string()
      .required("Required")
      .min(8, "minimum 8 characters required")
      .oneOf([Yup.ref("password")], "Password do not match")
  }),
  role: Yup.string().required("Required"),
  branch: Yup.array()
    .min(1)
    .required()
    .nullable(),

})

export const editUserValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  mobile_number: Yup.string().required("Required"),
  alternative_mobile_number: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  role: Yup.string().required("Required"),
})

export const addUserValidationFirst = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  mobile_number: Yup.string().required("Required"),
  alternative_mobile_number: Yup.string().required("Required"),
  is_web_access: Yup.boolean(),
  password: Yup.string().when('is_web_access', {
    is: (test) => test === true, // Validation will apply when the checkbox is true
    then: () =>
      Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        "Password must contain lowercase, uppercase, and numbers, and have at least 8 characters"
      )
  }),

  confirm_password: Yup.string().when('is_web_access', {
    is: (test) => test === true, // Validation will apply when the checkbox is true
    then: () => Yup.string()
      .required("Required")
      .min(8, "minimum 8 characters required")
      .oneOf([Yup.ref("password")], "Password do not match")
  }),
  user_type: Yup.string().required("Required"),

})

export const modalConpnayInput = Yup.object().shape({
  name:Yup.string().required("Required"),
  address_one: Yup.string().required("Required"),
  address_two: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  contact_number: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
})


export const modalBarnchSchema  = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
    company: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
    syntax: Yup.string()
    .min(3, "minimum 3 characters required!")
    .required("Required"),
    // syntax: Yup.string()
    // .required("Required"),
});

export const filterTripDate ={}