import { apiEndPoints, methodType, performRequest, performRequestKaran } from "./_apiConfig";

export const getAllDriver = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.driver, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const getAllDriverTrans = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.driverTrans, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllDriverTransforTrip = (data = {}) => {
   
    return performRequest(methodType.GET, apiEndPoints.driverTrans +`?trip=${data}`, {}, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};



export const addDriver = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.driver, data, true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};



export const deleteDriver = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.driver + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}



export const updateDriver = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.driver + `/${data?.id}`,
        data?.formdata,
        true, true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const singleDriverDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.driver + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const updateActiveDriver = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.driver + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const uploadExcelDriver = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.driver + `/upload_drivers`, data, true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


///Paid----------------

export const addDriverPaid = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.driverTrans, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteDriverPaid = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.driverTrans + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateDriverPaid = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.driverTrans + `/${data?.id}`,
        data?.formdata,
        true, true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleDriverDetailsPaid = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.driverTrans + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const singleDriverWallet = (data = {}) => {
    const payload = {}
    return performRequest(methodType.GET, apiEndPoints.driverWallet + `?driver=${data}`, payload, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const updateWalletDriver = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.driverWallet + `/${data?.id}`,
        data?.formate   ,
        true, true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


///.................fuel expense


export const addFuelPaid = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.fuelExpenseTrip, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllFuelExpense = (data = {}) => {
   
    return performRequest(methodType.GET, apiEndPoints.fuelExpenseTrip , data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updatefuelTripExpense = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.fuelExpenseTrip + `/${data?.id}`,
        data?.formdata   ,
        true, 
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//...........karan api....



export const approvefuelExpense = (data = {}) => {
   
   
    return performRequest(methodType.POST, apiEndPoints.ApproveFuel, data, true)
        .then((response) => {
         
            return response;
        })
        .catch((error) => {
            console.log(error)
            return error;
        });
};

export const getKranExpense = (payload = {}) => {

    const data = {}
   
    return performRequest(methodType.GET, apiEndPoints.completeOrder + `?order_date=${payload?.order_date}`, data, true)
        .then((response) => {
          
            return response;
        })
        .catch((error) => {
            console.log(error)
            return error;
        });
};

