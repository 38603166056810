import React, { useEffect, useState } from "react";
import { Label, Modal, } from "reactstrap";
import { components } from "react-select";

import { InputField } from "../../Component/Common/InputField";
import {
    getBranch,

} from "../../services";
import { toast } from "react-toastify";
import { addCustomerFullValiadtion, addCustomerValiadtion, addExpenseValidation, addFleetValidation, addFuelValidation, addResearchSchema, addRouteDetails, editCustomerFullValiadtion, modalInputSchema } from "../../helper/errorSchema";
import { addCustomerTabList, addRouteTabList, checkFormError, customerTabList, formatDate, handleKeyDown, pageCount, routeTabList } from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";

import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";

import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { addCustomer, deleteCustomer, getAllCustomer, singleCustomerDetails, updateActiveCustomer, updateActiveResearch, updateCustomer } from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { getCountry } from "../../services/countryService";
import mytruck from "../../../image/tanker.png"
import { addCurrencyApi, getCurrency } from "../../services/currencyService";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { getExpenseCategory } from "../../services/expenseCategory";
import { addFuelStation, getAllFuelStation } from "../../services/fuelStationService";
import { addRoute, deleteRouteApi, getAllRoute, singleRouteDetails, updateActiveRoute, updateRouteApi } from "../../services/routeService";
import { getAllDrop, getAllPickup } from "../../services/locationService";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { getExitBorder, getForRouteExitBorder } from "../../services/exitBorderService";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

export const Route = () => {
    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id"
    };


    const fuelstationObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const customerObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const expenseObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true

    };
    const currencyObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };


    const formObj = {
        number: "",
        date: formatDate(new Date()),
    };

    const orderObj = {
        customer: "",
        from_location: [],
        to_location: [],
        route_name: '',
        kilometer: '',
        remark: "",
        expenses: [],
        fuel_expenses: [],
        is_active: true,
        addCurrency: "",
        count: ""
    }
    const orderObjName = {
        customer: "",
        from_location: [],
        to_location: [],
        route_name: '',
        kilometer: '',
        remark: "",
        expenses: [],
        fuel_expenses: [],
        is_active: true,
        addCurrency: "",
        count: ""
    }

    let defultExpense = {
        category: "",
        amount: "",
        currency: "",
        description: "",
    };

    let defultFuel = {
        fuel_station: "",
        ltr: "",
        description: "",
    };


    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [status, setStatus] = useState(1)
    const [activeModalTab, setActiveModalTab] = useState(1)
    const [openCustomer, setOpenCustomer] = useState(false)

    const [filter, setFilter] = useState({
        limit: 20,
        status: status,
        ...obj,
    });

    const [formValue, setFormValue] = useState(formObj);
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formError, setFormError] = useState("");
    const [customerList, setCustomerList] = useState()
    const [updateId, setUpdateId] = useState("")
    const [preview, setPreview] = useState(null)
    const [showCropper, setShowCropper] = useState(false);
    const [isLoadding, setIsLoadding] = useState(false)
    const [fuelStationList, setFuelStationList] = useState()
    const [expeCateList, setExpeCateList] = useState()
    const [countryList, setCountryList] = useState()
    const [pickupList, setPickupList] = useState([])
    const [dropList, setDropList] = useState([])
    const [borderList, setBorderList] = useState([])
    const [currencyList, setCurrencyList] = useState()
    const debouncedFilter = useDebounce(filter, 500);
    const [loaderForList, setLoaderForList] = useState(false)
    const [fromList, setFromList] = useState([{ label: "", value: "" }])
    const [toList, setToList] = useState([{ label: "", value: "" }])
    const [borderCheckList, setBorderCheckList] = useState([{ label: "", value: "" }])
    const [expenseList, setExpenseList] = useState([
        defultExpense
    ])
    const [fuelList, setFuelList] = useState([
        defultFuel
    ])

    const [openModal, setOpenModal] = useState(false)

    const Requirement = [
        { heading: "S.No", className: "sorting_asc", value: "serial", sortName: "" },
        { heading: "Customer Name", className: "sorting_asc", value: "customer_name", sortName: "" },
        { heading: "Route Name", className: "sorting", value: "route_name", sortName: "" },
        { heading: "Pickup Location", className: "sorting_asc", value: "pickUp_location", sortName: "" },
        { heading: "Drop Location", className: "sorting_asc", value: "drop_location", sortName: "" },
        { heading: "Contact Number", className: "sorting_asc", value: "mobile", sortName: "" },
        { heading: "KMs", className: "sorting_asc", value: "km", sortName: "" },
        { heading: "Action", className: "sorting_asc", value: "action", sortName: "" },
    ];


    const rowDataList = () => {
        const listArray = dataList?.results?.map((item, i) => ({
            ...item,
            status: (
                <div className="statusofOrder">
                    <span>Pending</span>
                </div>
            ),
            serial: i + 1,
            customer_name: item?.customer_detail ? item?.customer_detail?.name : "User Not Login",
            pickUp_location: item?.pickup_location_detail?.map((i) => item?.pickup_location_detail?.length > 1 ? `${i.name} , ` : i.name),
            drop_location: item?.drop_location_detail?.map((i) => item?.drop_location_detail?.length > 1 ? `${i.name} , ` : i.name),
            mobile: item?.customer_detail?.mobile_number,
            km: item?.kilometer,
            action: (
                <>
                    <div className="actionBtn">

                        <div className="customerStatus"><SwitchField onSwitchChange={() => updateTagStatus(item)} value={item?.is_active} />{item?.is_active ? <span>Disable</span> : <span>Enable</span>}</div>

                        <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>
                        <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>

                        {/* <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
                        <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> */}
                    </div>
                </>
            ),
            sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,

            icon: (
                <>
                    <img src={item?.icon} alt={item?.name} />
                </>
            ),
        }));
        return listArray;
    };

    const { data: dataList, isSuccess } = useQuery(
        ["getAllRoute", debouncedFilter],
        () => getAllRoute(debouncedFilter, filter),
        { enabled: Boolean(debouncedFilter) }
    );


    useEffect(() => {
        if (dataList) {
            setLoaderForList(false)
        } else {
            setLoaderForList(true)
        }
    }, [dataList])

    useEffect(() => {
        getExpeCategoryList()
        getFuelStationList()
        getCountryList()
        getCurrencyList()
        getCustomerList()
        getToLocatonList()
        getFromLocatonList()
        // getBranchList()
        getExitBorderList()
    }, [])

    const getExpeCategoryList = async () => {
        const res = await getExpenseCategory(expenseObj)
        setExpeCateList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getFuelStationList = async () => {
        const res = await getAllFuelStation(fuelstationObj)
        setFuelStationList(res?.results?.map((item) => {
            return { ...item, label: item?.station_name, value: item?.id }
        }))

    }

    const getCountryList = async () => {
        const res = await getCountry(countryObj)
        setCountryList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getCurrencyList = async () => {
        const res = await getCurrency(currencyObj)
        setCurrencyList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))
        // setCurrencyList(res?.results)

    }

    // const getBranchList = async () => {
    //     const res = await getBranch(customerObj)
    //     const list = res.results?.map((item, i) => {
    //         return { name: item.name, id: item?.id }
    //     })

    //     setBranchList(list)

    // }

    const getCustomerList = async () => {
        const res = await getAllCustomer(customerObj)
        const list = res?.results?.map((item, i) => {
            return { name: item.name, id: item?.id }
        })

        setCustomerList(list)

    }

    const getFromLocatonList = async () => {
        const payload = {
            status: {
                limit: 40,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
            type: 1
        }
        const res = await getAllPickup(payload)
        setPickupList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getToLocatonList = async () => {
        const payload = {
            status: {
                limit: 40,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
            type: 2
        }
        const res = await getAllDrop(payload)
        setDropList(res?.results?.map((item) => {

            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getExitBorderList = async () => {
        const payload = {
            status: {
                limit: 40,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
        }
        const res = await getForRouteExitBorder(payload)
        setBorderList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const clearAll = () => {
        setFormOrderValue({
            customer: "",
            from_location: [],
            to_location: [],
            route_name: '',
            kilometer: '',
            remark: "",
            expenses: [],
            fuel_expenses: [],
            is_active: true

        })
        setFormOrderName({
            customer: "",
            from_location: [],
            to_location: [],
            route_name: '',
            kilometer: '',
            remark: "",
            expenses: [],
            fuel_expenses: [],
            is_active: true

        })
        setPreview('')
        setIsEdit(false);
        setFuelList([{
            fuel_station: "",
            ltr: "",
            description: "",
        }])
        setExpenseList([{
            category: "",
            amount: "",
            currency: "",
            description: "",
        }])
        setFromList([{ label: "", value: "" }])
        setToList([{ label: "", value: "" }])
        setBorderCheckList([{ label: "", value: "" }])
        setFormError('')
        setActiveModalTab(1)
    }



    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };


    const updateTagStatus = (item) => {

        const payload = {
            id: item.id,
            is_active: !item?.is_active
        }
        updateActiveMutation.mutate(payload);
    }

    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addRouteDetails);
            setValidationError(error);
        }
    };

    const validationHnalde = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue, from_location: fromList, to_location: toList },
            addRouteDetails
        );

        setValidationError(validationResult)
        if (validationResult === true) {
            setActiveModalTab(2)
        }
    }

    const validationHnalde2 = async () => {
        const validationResult = await checkFormError(
            { expense: expenseList },
            addExpenseValidation
        );


        setValidationError(validationResult)
        if (validationResult === true) {
            setActiveModalTab(3)
        }
    }

    const onOrderSubmit = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue, from_location: fromList, to_location: toList, expense: expenseList, fuel_expenses: fuelList },
            isEdit ? addFuelValidation : addFuelValidation
        );

        const payload = {
            customer: formOrderValue.customer,
            from_location: fromList?.map((i) => i?.id ? i?.id : i?.value),
            to_location: toList?.map((i) => i?.id ? i?.id : i?.value),
            exit_border: borderCheckList?.map((i) => i?.id ? i?.id : i?.value),
            route_name: formOrderValue.route_name,
            kilometer: formOrderValue.kilometer,
            remark: formOrderValue.remark,
            expenses: expenseList,
            fuel_expenses: fuelList,
            is_active: formOrderValue.is_active
        }

        if (validationResult === true) {

            setIsLoadding(true)
            if (isEdit === true) {


                handleUpdate(payload);
            } else {
                handleAdd(payload);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };

    const updateActiveMutation = useMutation(updateActiveRoute, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };

    const handleDelete = () => {
        deleteMutation.mutate(deleteId);
    };

    const handleEditAction = (item) => {
        getRouteDetails.mutate(item?.id);
    };

    const getRouteDetails = useMutation(singleRouteDetails, {
        onSuccess: (res) => {
            if (res?.success) {
                setFormOrderValue({
                    customer: res?.results?.customer_detail?.id,
                    route_name: res?.results?.route_name,
                    kilometer: res?.results?.kilometer,
                    remark: res?.results?.remark,
                    is_active: res?.results?.is_active,
                });

                const common = (name, id) => {
                    if (name === "customer") {
                        let details = customerList?.find((i) => i.id === id);
                        return { data: details, label: details?.name, value: details?.id };
                    }
                    if (name === "currency") {
                        let details = currencyList?.find((i) => i.id === id);
                        return { data: details, label: details?.name, value: details?.id };
                    }
                    if (name === "country") {
                        let details = countryList?.find((i) => i.id === id);
                        return { data: details, label: details?.name, value: details?.id };
                    }
                };

                setFormOrderName({
                    ...formOrderName,
                    customer: common("customer", res?.results?.customer_detail?.id),
                    currency: common("currency", res?.results?.currency),
                    country: common("country", res?.results?.country),
                });

                const newFromList = res?.results?.pickup_location_detail?.map((i) => ({
                    label: i?.name, value: i?.id
                }));
                const newToList = res?.results?.drop_location_detail?.map((i) => ({
                    label: i?.name, value: i?.id
                }));
                const newBorderCheckList = res?.results?.exit_border_detail?.map((i) => ({
                    label: i?.name, value: i?.id
                }));

                setFromList(newFromList);
                setToList(newToList);
                setBorderCheckList(newBorderCheckList);

                generateRouteName(newFromList, newBorderCheckList, newToList);

                const expoNew = res.results?.expense_detail?.map((data) => ({
                    category: data?.category,
                    amount: data?.amount,
                    currency: data?.currency,
                    description: data?.description,
                }));
                setExpenseList(expoNew);

                const fuelNew = res.results?.fuel_expense_detail?.map((data) => ({
                    fuel_station: data?.fuel_station,
                    description: data?.description,
                    ltr: data?.ltr,
                }));
                setFuelList(fuelNew);

                setPreview(res?.results?.image);
                setIsEdit(true);
                setIsOpen(true);
                setFormError("");
                setUpdateId(res?.results?.id);
            } else {

            }
        },
    });

    const generateRouteName = (fromList, borderCheckList, toList) => {
        const extractInitials = (list) => {
            return list
                .map((item) => {
                    if (item && item.name) {
                        return item.name.slice(0, 3).toUpperCase();
                    } else if (item && item.label) {
                        return item.label.slice(0, 3).toUpperCase();
                    }
                    return '';
                })
                .filter((initials) => initials !== '')
                .join("-");
        };

        const fromListInitials = fromList.length > 0 ? extractInitials(fromList) : '';
        const borderCheckListInitials = borderCheckList.length > 0 ? extractInitials(borderCheckList) : '';
        const toListInitials = toList.length > 0 ? extractInitials(toList) : '';

        const routeName = [fromListInitials, borderCheckListInitials, toListInitials]
            .filter((initials) => initials !== '')
            .join("-");

        setFormOrderValue((prevState) => ({
            ...prevState,
            route_name: routeName,
        }));

    };

    useEffect(() => {
        generateRouteName(fromList, borderCheckList, toList);
    }, [fromList, toList, borderCheckList]);

    const handleUpdate = async (data) => {

        // const validationResult = await checkFormError(
        //     { ...formValue },
        //     addResearchSchema
        // );


        const payload = {
            formdata: data,
            id: updateId
        }



        updateMutation.mutate(payload);
    };

    const addMutation = useMutation(addRoute, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                setIsLoadding(false)
                onErrorResponse(res?.error);
            }
        },
    });

    const updateMutation = useMutation(updateRouteApi, {
        onSuccess: (res) => {
            if (res?.success) {
                setIsEdit(false);
                onSuccessResponse(res?.message);
                // queryClient.refetchQueries("getAllRoute");      
            } else {
                setIsLoadding(false)
                onErrorResponse(res?.error);
            }
        },
    });

    const deleteMutation = useMutation(deleteRouteApi, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                setOpenDel(false);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success", { position: "bottom-left" });
        queryClient.refetchQueries("getAllRoute");
        setIsOpen(false);
        setIsLoadding(false)
        clearAll()

    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
    };

    const handlePageClick = async (data) => {
        const currentPage = data.selected;
        setFilter((s) => ({
            ...s,
            offset: currentPage * filter.limit,
            currentPage: currentPage,
        }));
    };

    const hendleDeleteTag = (data, flag) => {
        setOpenDel(flag);
        setDeleteId(data)
    }

    const handleActive = (data) => {
        setLoaderForList(true)
        setFilter({
            ...filter,
            status: data.value
        })
    }

    const handleModalActive = (data) => {
        setActiveModalTab(data?.value)
    }


    const getTableList = () => {
        switch (+filter.status) {
            case 1:
                return Requirement
            case 2:
                return Requirement
            default:
                return Requirement

        }
    }

    const addNewExpo = (e) => {
        e.preventDefault();
        setExpenseList((preState) => {
            return [...preState, defultExpense]
        });
    }


    const addNewFuel = (e) => {
        e.preventDefault();
        setFuelList((preState) => {
            return [...preState, defultFuel]
        });
    }

    const addPickLoction = (e) => {
        e.preventDefault();
        setFromList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const addDropLoction = (e) => {
        e.preventDefault();
        setToList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const addBorderLoction = (e) => {
        e.preventDefault();
        setBorderCheckList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const deleteNewExpo = (e) => {
        e.preventDefault();
        setExpenseList((prevState) => prevState.slice(0, -1));
    };

    const deleteNewFuel = (e) => {
        e.preventDefault();
        setFuelList((prevState) => prevState.slice(0, -1));
    };

    const deletePickLocation = (e) => {
        e.preventDefault();
        setFromList((prevState) => prevState.slice(0, -1));
    };

    const deleteDropLocation = (e) => {
        e.preventDefault();
        setToList((prevState) => prevState.slice(0, -1));
    };

    const deleteBorderLocation = (e) => {
        e.preventDefault();
        setBorderCheckList((prevState) => prevState.slice(0, -1));
    };
    const updateExpoDetails = (index, data) => {
        const { key, value } = data;

        let newExpenseList = [...expenseList];
        newExpenseList[index][key] = value;
        setExpenseList(newExpenseList);

    }

    const updateFuelDetails = (index, data) => {
        const { key, value } = data;

        let newFuelList = [...fuelList];
        newFuelList[index][key] = value;
        setFuelList(newFuelList);

    }

    const CustomControl = ({ children, ...props }) => (
        <components.Menu {...props}>
            <button className="addMoreBtn" onClick={() => addNewEntryHandle(props?.selectProps?.placeholder, props?.selectProps?.inputValue)}>+ Add New {props?.selectProps?.inputValue ? ` -> "${props?.selectProps?.inputValue}"` : ""}</button>
            {children}

        </components.Menu>
    );

    const addNewEntryHandle = (PlaceName, inputvalue) => {

        switch (PlaceName) {
            case "Select Branch":
                setFormOrderValue({ ...formOrderValue, addBranch: inputvalue, openName: "branch" })
                setOpenModal(true)

                break;

            case "Select Currency":
                setFormOrderValue({ ...formOrderValue, addCurrency: inputvalue, openName: "currency" })
                setOpenModal(true)
                break;

            case "Select Country":
                setFormOrderValue({ ...formOrderValue, addCountry: inputvalue, openName: "country" })
                setOpenModal(true)
                break;

            case "Select Customer Name":
                setOpenCustomer(true)
                break;

            default:
                break;
        }
    }

    const addNewSubmit = async (value) => {


        const validationResult = await checkFormError(
            { name: value === "branch" ? formOrderValue.addBranch : value === "currency" ? formOrderValue?.addCurrency : formOrderValue.addCountry },
            modalInputSchema
        )

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate();
            } else {
                handleAddNew(value);
            }
        } else {
            setFormError(validationResult);
            setIsLoadding(false)
        }

    };

    const handleAddNew = (value) => {

        const payload = {
            name: formOrderValue?.addCurrency
        }

        addNewCurrencyMutation.mutate(payload)

    }

    const addNewCurrencyMutation = useMutation(addCurrencyApi, {
        onSuccess: (res) => {
            if (res?.success) {
                let expe = [...expenseList];
                expe.map((item, index) => {
                    updateExpoDetails(index, { key: "currency", value: res?.results?.id })

                })
                setExpenseList(expe)
                getCurrencyList()

                setOpenModal(false)

                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });



    const routeCallBack = (data) => {

        if (data?.email) {
            getCustomerList()
            setFormOrderValue({ ...formOrderValue, customer: data?.id })
            setFormOrderName({ ...formOrderName, customer: { value: data?.id, label: data?.name } })
        }
    }

    useEffect(() => {
        if (isEdit === false) {
            if (fromList.length > 0 || toList.length > 0 || borderCheckList.length > 0) {
                getRouteName();
            }
        }
    }, [fromList, toList, borderCheckList, isEdit]);

    const getRouteName = () => {
        const extractInitials = (list) => {
            return list
                .map((item) => {
                    if (item && item.name) {
                        return item.name.slice(0, 3).toUpperCase();
                    }
                    return '';
                })
                .filter(initials => initials !== '')
                .join("-");
        };


        const fromListInitials = fromList.length > 0 ? extractInitials(fromList) : '';
        const borderCheckListInitials = borderCheckList.length > 0 ? extractInitials(borderCheckList) : '';
        const toListInitials = toList.length > 0 ? extractInitials(toList) : '';

        const routeName = [fromListInitials, borderCheckListInitials, toListInitials]
            .filter((initials) => initials !== '')
            .join("-");

        setFormOrderValue((prevState) => ({
            ...prevState,
            route_name: routeName
        }));
    };

    return (
        <>
            <div className='tabMain_head'> <Tabs TabList={routeTabList} handleActive={handleActive} activeTab={+filter?.status} />
                <div>
                    <button className='addPlan' onClick={() => setIsOpen(true)}>Add Route</button>

                </div>

            </div>
            <div className="anvTask_prog">
                <div className="anvInputs&fillters">

                    <div className="inr_flex_filter" >
                        <button className="filterBox">Filter</button>
                    </div>

                    <div className="inr_flex">
                        <InputField placeholder="Search" className="anvSearch_input" label="Search"
                            value={filter?.search}
                            onChange={(e) =>
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    search: e.target.value,
                                }))
                            }
                        />
                    </div>
                    {filter?.search && <div className="inr_flex_mini">
                        <button className="anime-btn reset_button" onClick={() => {
                            setFilter({
                                ...filter,
                                search: ""

                            })

                        }
                        } >Reset</button>
                    </div>}
                </div>
            </div>

            <div>
                <div className="table-responsive">
                    <CustomTable
                        column={getTableList()}
                        data={rowDataList()}
                        tableLoading={loaderForList}
                    />

                </div>


            </div>
            {dataList?.results?.length === 0 ? (
                ""
            ) : (
                <div className="pagination_count">
                    <div className="">
                        <Dropdown
                            placeholder="Select Count"
                            className="dropdownoforder orderModal_Input"
                            name="driver"
                            options={pageCount ? pageCount : []}
                            valueText="value"
                            labelText="label"
                            selectedOption={formOrderName?.count ? formOrderName?.count : { label: "20", value: 20 }}

                            setSelectedOption={(data) => {
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    limit: data.value,
                                }))

                                setFormOrderName({ ...formOrderName, count: data });
                            }}

                        />
                    </div>

                    <CustomPagination
                        onPageChange={handlePageClick}
                        currentPage={filter?.currentPage}
                        pageCount={Math.ceil(dataList?.count / filter?.limit)}
                    />
                </div>
            )}

            <InputModal
                isOpen={isOpen}
                headerName={isEdit ? "Edit Route" : "Create Route"}
                toggle={() => {
                    clearAll()
                    setIsOpen(!isOpen)

                }}
                submitLabel={+activeModalTab === 3 ? "Save" : "Continue"}
                onSubmit={(e) => +activeModalTab === 3 ? onOrderSubmit(e) : +activeModalTab === 1 ? validationHnalde() : validationHnalde2()}
                // disabled={isEdit ? inputValue.name === disableInp.name : ""}
                isLoadding={isLoadding}
                customeClassName="ModalRouteFlex"
                className="routemodalNew"
                inputProps={

                    <>
                        {isOpen && <div div className='TabHeadofmodal routemargin'> <Tabs TabList={addRouteTabList} handleActive={handleModalActive} activeTab={+activeModalTab} customClassName="route" /></div>}
                        {+activeModalTab === 1 && <div className="anvMaintab_container_route">
                            <div className="dropdownMargin">
                                <Dropdown
                                    className='dropdownoforder'
                                    classNamePrefix="_anvFloatSelect"
                                    boxClass="_anvFloatedSelect"
                                    label="Customer Name"
                                    placeholder="Select Customer Name"
                                    name="customer"
                                    options={customerList ? customerList : []}
                                    valueText="id"
                                    labelText="name"
                                    focus={!!formError?.customer}
                                    error={formError?.customer}
                                    components={{ Menu: CustomControl }}
                                    selectedOption={formOrderName?.customer}
                                    setSelectedOption={(data) => {

                                        setFormOrderName({ ...formOrderName, customer: data })
                                        handleOrderChange("customer", data?.value);

                                    }}
                                />
                            </div>
                            <div className="dropdownMargin mt-2">
                                {fromList?.map((onFrom, index) => <>
                                    <Dropdown
                                        className='dropdownoforder mb-1'
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect lltMarginBtm"
                                        label="Loading Point"
                                        placeholder="Select Location"
                                        name="branch"
                                        options={pickupList ? pickupList : []}
                                        valueText="value"
                                        labelText="label"
                                        focus={`${formError[`from_location[${index}].label`]}` !== "undefined" ? !!`${formError[`from_location[${index}].label`]}` : ""}
                                        error={`${formError[`from_location[${index}].label`]}` !== "undefined" ? `${formError[`from_location[${index}].label`]}` : ""}
                                        selectedOption={onFrom}
                                        setSelectedOption={(data) => {
                                            setFormOrderName({ ...formOrderName, branch: data })
                                            const newFromList = [...fromList];
                                            newFromList[index] = data;
                                            setFromList(newFromList)

                                        }}
                                    />
                                </>)}
                                <div className="d-flex gap-2">
                                    <div className="addLoctionBtn ">
                                        <span onClick={addPickLoction}>+ Add Loading Point</span>
                                    </div>
                                    {fromList && fromList?.length > 1 &&
                                        <div className="addLoctionBtn delColorLocation">
                                            <span onClick={deletePickLocation}>+ Delete Loading Point</span>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="dropdownMargin mt-3">
                                {borderCheckList?.map((onFrom, index) => <>
                                    <Dropdown
                                        className='dropdownoforder mb-1'
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect lltMarginBtm"
                                        label="Border"
                                        placeholder="Select Location"
                                        name="branch"
                                        options={borderList ? borderList : []}
                                        valueText="value"
                                        labelText="label"
                                        // focus={`${formError[`from_location[${index}].label`]}` !== "undefined" ? !!`${formError[`from_location[${index}].label`]}` : ""}
                                        // error={`${formError[`from_location[${index}].label`]}` !== "undefined" ? `${formError[`from_location[${index}].label`]}` : ""}
                                        selectedOption={onFrom}
                                        setSelectedOption={(data) => {
                                            setFormOrderName({ ...formOrderName, branch: data })
                                            const newFromList = [...borderCheckList];
                                            newFromList[index] = data;
                                            setBorderCheckList(newFromList)
                                        }}
                                    />
                                </>)}
                                <div className="d-flex gap-2">
                                    <div className="addLoctionBtn">
                                        <span onClick={addBorderLoction}>+ Add Border Location</span>
                                    </div>
                                    {borderCheckList && borderCheckList?.length > 1 &&
                                        <div className="addLoctionBtn delColorLocation">
                                            <span onClick={deleteBorderLocation}>+ Delete Border Location</span>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="dropdownMargin mt-3">
                                {toList?.map((onFrom, index) => <>
                                    <Dropdown
                                        className='dropdownoforder mb-1'
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect lltMarginBtm"
                                        label="Offloading Point"
                                        placeholder="Select Location"
                                        name="branch"
                                        options={dropList ? dropList : []}
                                        valueText="value"
                                        labelText="label"
                                        focus={`${formError[`to_location[${index}].label`]}` !== "undefined" ? !!`${formError[`to_location[${index}].label`]}` : ""}
                                        error={`${formError[`to_location[${index}].label`]}` !== "undefined" ? `${formError[`to_location[${index}].label`]}` : ""}
                                        selectedOption={onFrom}
                                        setSelectedOption={(data) => {

                                            setFormOrderName({ ...formOrderName, branch: data })
                                            handleOrderChange("branch", data?.value);
                                            const newToList = [...toList];
                                            newToList[index] = data;
                                            setToList(newToList)
                                        }}
                                    />
                                </>)}
                                <div className="d-flex gap-2">
                                    <div className="addLoctionBtn">
                                        <span onClick={addDropLoction}>+ Add Offloading Point</span>
                                    </div>
                                    {toList && toList?.length > 1 &&
                                        <div className="addLoctionBtn delColorLocation">
                                            <span onClick={deleteDropLocation}>+ Delete Offloading Point</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="inputMargin mt-2">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "Route Name" : "Route Name"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Route Name"
                                    name="route_name"
                                    type="text"
                                    focus={!!formError?.route_name}
                                    error={formError.route_name}
                                    value={formOrderValue?.route_name || ""}
                                    readOnly
                                />

                            </div>
                            <div className="inputMargin">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "KMS" : "KMS"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter KMs"
                                    name="kilometer"
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.kilometer}
                                    error={formError.kilometer}
                                    value={formOrderValue?.kilometer}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            <div className="inputMargin" >

                                <InputField
                                    className="modal_input routeModal_Input "
                                    label={isEdit ? "Remarks" : "Remarks"}
                                    labelClass="FormLableKaran"
                                    placeholder="Remarks"
                                    name="remarks"
                                    type="textarea"
                                    focus={!!formError?.remarks}
                                    error={formError.remarks}
                                    value={formOrderValue?.remarks}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                        </div>}
                        {+activeModalTab === 2 &&
                            <div className="anvMaintab_container_route">
                                {expenseList?.map((onExpo, index) => <div className="marginExpoBottom">
                                    <div className="expenseTabdiv">
                                        <div className="dropdownMargin">
                                            {/* <span>
                                                <Label className="FormLableKaran">Expense Category</Label>
                                            </span> */}
                                            <Dropdown
                                                className='dropdownoforder'
                                                // classNamePrefix="mySelect"
                                                placeholder="Parking Fees"
                                                classNamePrefix="_anvFloatSelect"
                                                boxClass="_anvFloatedSelect"
                                                label="Expense Category"
                                                name="branch"
                                                options={expeCateList ? expeCateList : []}
                                                valueText="id"
                                                labelText="name"
                                                focus={`${formError[`expense[${index}].category`]}` !== "undefined" ? !!`${formError[`expense[${index}].category`]}` : ""}
                                                error={`${formError[`expense[${index}].category`]}` !== "undefined" ? `${formError[`expense[${index}].category`]}` : ""}
                                                selectedOption={expeCateList.find((item) => item?.id === onExpo.category)}
                                                setSelectedOption={(data) => {

                                                    setFormOrderName({ ...formOrderName, customer: data })
                                                    handleOrderChange("category", data?.value);
                                                    updateExpoDetails(index, { key: "category", value: data?.value })

                                                }}
                                            />
                                        </div>
                                        <div className="inputMargin">

                                            <InputField
                                                className="modal_input routeModal_Input"
                                                label={isEdit ? "Amount" : "Amount"}
                                                labelClass="FormLableKaran"
                                                placeholder="Amount"
                                                name="amount"
                                                type="text"
                                                onKeyDown={handleKeyDown}
                                                focus={`${formError[`expense[${index}].amount`]}` !== "undefined" ? !!`${formError[`expense[${index}].amount`]}` : ""}
                                                error={`${formError[`expense[${index}].amount`]}` !== "undefined" ? `${formError[`expense[${index}].amount`]}` : ""}
                                                value={onExpo?.amount}
                                                onChange={({ target: { name, value } }) =>
                                                    updateExpoDetails(index, { key: "amount", value: value })
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="dropdownMargin">
                                        <Dropdown
                                            className='dropdownoforder'
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect"
                                            label="Currency"
                                            placeholder="Select Currency"
                                            name="currency"
                                            options={currencyList ? currencyList : []}
                                            components={{ Menu: CustomControl }}

                                            valueText="id"
                                            labelText="name"
                                            focus={`${formError[`expense[${index}].currency`]}` !== "undefined" ? !!`${formError[`expense[${index}].currency`]}` : ""}
                                            error={`${formError[`expense[${index}].currency`]}` !== "undefined" ? `${formError[`expense[${index}].currency`]}` : ""}
                                            selectedOption={currencyList.find((item) => item?.id === onExpo.currency)}
                                            setSelectedOption={(data) => {

                                                setFormOrderName({ ...formOrderName, currency: data })
                                                handleOrderChange("currency", data?.value);
                                                updateExpoDetails(index, { key: "currency", value: data?.value })

                                            }}
                                        />
                                    </div>
                                    <div className="inputMargin">

                                        <InputField
                                            className="modal_input routeModal_Input"
                                            label={isEdit ? "Description" : "Description"}
                                            labelClass="FormLableKaran"
                                            placeholder="Description"
                                            name="description"
                                            type="text"
                                            focus={`${formError[`expense[${index}].description`]}` !== "undefined" ? !!`${formError[`expense[${index}].description`]}` : ""}
                                            error={`${formError[`expense[${index}].description`]}` !== "undefined" ? `${formError[`expense[${index}].description`]}` : ""}
                                            value={onExpo?.description}
                                            onChange={({ target: { name, value } }) =>
                                                updateExpoDetails(index, { key: "description", value: value })
                                            }
                                        />

                                    </div>
                                </div>)}
                                <div className="d-flex gap-2">
                                    <div className="addLoctionBtn">
                                        <span onClick={addNewExpo}>+ Add Expenses</span>
                                    </div>
                                    {
                                        expenseList && expenseList?.length > 1 &&
                                        <div className="addLoctionBtn delColorLocation">
                                            <span onClick={deleteNewExpo}>+ Delete Expenses</span>
                                        </div>
                                    }
                                </div>
                            </div>}
                        {+activeModalTab === 3 &&
                            <div className="anvMaintab_container_route" >
                                {fuelList?.map((onfuel, index) => <div className="marginExpoBottom">
                                    <div className="expenseTabdiv">
                                        <div className="dropdownMargin">
                                            <Dropdown
                                                className='dropdownoforder'
                                                classNamePrefix="_anvFloatSelect"
                                                boxClass="_anvFloatedSelect"
                                                label="Fuel Station"
                                                placeholder="Parking Fees"
                                                name="fuel_station"
                                                options={fuelStationList ? fuelStationList : []}
                                                valueText="id"
                                                labelText="station_name"
                                                focus={`${formError[`fuel_expenses[${index}].fuel_station`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].fuel_station`]}` : ""}
                                                error={`${formError[`fuel_expenses[${index}].fuel_station`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].fuel_station`]}` : ""}
                                                selectedOption={fuelStationList.find((item) => item?.id === onfuel.fuel_station)}
                                                setSelectedOption={(data) => {

                                                    setFormOrderName({ ...formOrderName, fuel_station: data })
                                                    updateFuelDetails(index, { key: "fuel_station", value: data?.value })


                                                }}
                                            />
                                        </div>
                                        <div className="inputMargin">

                                            <InputField
                                                className="modal_input routeModal_Input"
                                                label={isEdit ? "Ltr" : "Ltr"}
                                                labelClass="FormLableKaran"
                                                placeholder="Qty Ltr"
                                                name="ltr"
                                                type="text"
                                                onKeyDown={handleKeyDown}
                                                focus={`${formError[`fuel_expenses[${index}].ltr`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].ltr`]}` : ""}
                                                error={`${formError[`fuel_expenses[${index}].ltr`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].ltr`]}` : ""}
                                                value={onfuel?.ltr}
                                                onChange={({ target: { name, value } }) =>
                                                    updateFuelDetails(index, { key: "ltr", value: value })
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="inputMargin">

                                        <InputField
                                            className="modal_input routeModal_Input"
                                            label={isEdit ? "Description" : "Description"}
                                            labelClass="FormLableKaran"
                                            placeholder="Description"
                                            name="description"
                                            type="text"
                                            focus={`${formError[`fuel_expenses[${index}].description`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].description`]}` : ""}
                                            error={`${formError[`fuel_expenses[${index}].description`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].description`]}` : ""}
                                            value={onfuel?.description}
                                            onChange={({ target: { name, value } }) =>
                                                updateFuelDetails(index, { key: "description", value: value })
                                            }
                                        />

                                    </div>
                                </div>)}
                                <div className="d-flex gap-2">

                                    <div className="addLoctionBtn">
                                        <span onClick={addNewFuel} >+ Add Fuel Expenses</span>
                                    </div>
                                    {
                                        fuelList && fuelList?.length > 1 &&
                                        <div className="addLoctionBtn delColorLocation">
                                            <span onClick={deleteNewFuel}>+ Delete Expenses</span>
                                        </div>
                                    }
                                </div>

                            </div>}
                    </>
                }
            />

            <Modal isOpen={showCropper} onClose={() => setShowCropper(true)} className="imageCrooperModal" >
                {
                    typeof showCropper !== "boolean" && (
                        <ImageCropper
                            setFormValue={setFormValue}
                            setShowCropper={setShowCropper}
                            image={showCropper}
                            cropShape="rect"
                            aspect={6 / 3}
                            formValue={formValue}
                        />
                    )
                }

            </Modal >
            <ConfirmModal
                isOpen={openDel}
                toggle={() => setOpenDel(!openDel)}
                onSubmit={() => handleDelete()}
            />

            {openModal && < InputModal
                isOpen={openModal}
                headerName={isEdit ? "Edit Branch" : formOrderValue.openName === "branch" ? "Add Branch" : formOrderValue.openName === "currency" ? "Add Currency" : "Add Country"}
                toggle={() => setOpenModal(!openModal)}
                submitLabel={isEdit ? "Update Branch" : "Continue"}
                onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
                // disabled={isEdit ? inputValue.name === disableInp.name : ""}
                isLoadding={isLoadding}
                inputProps={

                    <InputField
                        className="modal_input"
                        label={isEdit ? "Edit Branch" : formOrderValue.openName === "branch" ? "Branch Name" : formOrderValue.openName === "currency" ? "Currency Name" : "Country Name"}
                        labelClass="modal_label"
                        labelSpan="*"
                        labelSpanClass="style_for_star"
                        placeholder={formOrderValue.openName === "branch" ? "Branch Name" : formOrderValue.openName === "currency" ? "Currency Name" : "Country Name"}
                        name={formOrderValue.openName === "branch" ? "addBranch" : formOrderValue.openName === "currency" ? "addCurrency" : "addCountry"}
                        focus={formOrderValue.openName === "branch" ? !!formError.addBranch : formOrderValue.openName === "currency" ? !!formError.addCurrency : !!formError.addCountry}
                        error={formOrderValue.openName === "branch" ? formError.addBranch : formOrderValue.openName === "currency" ? formError.addCurrency : formError.addCountry}
                        value={formOrderValue.openName === "branch" ? formOrderValue.addBranch : formOrderValue.openName === "currency" ? formOrderValue.addCurrency : formOrderValue.addCountry}
                        onChange={({ target: { name, value } }) =>
                            handleOrderChange(name, value)
                        }
                    />
                }
            />
            }
            {openCustomer && < CommonCustomer isOpen={openCustomer} setIsOpen={setOpenCustomer} routeCallBack={routeCallBack} />}

        </>
    );
};
