
import React, { useEffect, useState } from "react";
import { Button, Card, CardText, CardTitle, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, } from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import {
    addCategory,
    deleteCategory,
    getBranch,
    getCommunity,
    getTags,
    updateCategory,
} from "../../services";
import { toast } from "react-toastify";
import { addDriverValidation, addLocationValidation, addOrderValidation, addResearchSchema, addResearchSchemaForImage, addTruckFleet, modalInputSchema } from "../../helper/errorSchema";
import { checkFormError, driverTabList, formatDate, locationTabList, nominationTabList, orderTabList } from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import CheckboxField from "../../Component/Common/CheckboxField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { addResearch, deleteResearch, getAllCustomer, getAllResearch, singleNewDetails, updateActiveResearch } from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Link } from "react-router-dom";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import { acceptAndRejectStatus, addFleetsForOrder, addOrder, getAllFleetforOrder, getAllOrder, getdryCargo, gettankersCsv } from "../../services/orderService";
import { getProduct } from "../../services/productService";
import { addDriver, deleteDriver, getAllDriver, updateDriver } from "../../services/driverService";


export const Nomination = () => {

    const formObj = {
        number: "",
        date: formatDate(new Date()),
    };

    const orderObj = {
        name: "",
        mobile_number: "",
        emergency_mobile_number: "",
        is_mobile_access: true,
        opining_balance_date: "",
        opining_balance: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        password: "",
        confirm_password: "",
        license_number: "",
        license_expiry_date: "",
        license_file: null,
        passport_number: "",
        passport_expiry_date: "",
        passport_file: null,
        contract_expiry_date: "",
        contract_file: null,
        is_active: true,
    }

    const orderObjName = {

        name: "",
        mobile_number: "",
        emergency_mobile_number: "",
        is_mobile_access: true,
        opining_balance_date: "",
        opining_balance: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        password: "",
        confirm_password: "",
        license_number: "",
        license_expiry_date: "",
        license_file: "",
        passport_number: "",
        passport_expiry_date: "",
        passport_file: "",
        contract_expiry_date: "",
        contract_file: "",
        is_active: true,
    }



    const Requirement = [

        { heading: "Date", className: "sorting", value: "", sortName: "asc" },
        { heading: "Branch", className: "sorting_asc", value: "", sortName: "asc" },
        { heading: "Customer Name", className: "sorting_asc", value: "", sortName: "asc" },
        { heading: "Route", className: "sorting_asc", value: "", sortName: "asc" },
        { heading: "Order No", className: "sorting_asc", value: "", sortName: "asc" },
        { heading: "Truck Number", className: "sorting_asc", value: "", sortName: "asc" },
        { heading: "Availabilty", className: "sorting_asc", value: "", sortName: "asc" },
        { heading: "Action", className: "sorting_asc", value: "action", sortName: "asc" },

    ];


    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "id"

    };
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [status, setStatus] = useState(1)
    const [filter, setFilter] = useState({
        limit: 20,
        status: status,
        ...obj,

    });

    const [formValue, setFormValue] = useState(formObj);
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formError, setFormError] = useState("");
    const [updateId, setUpdateId] = useState("")
    const [showCropper, setShowCropper] = useState(false);
    const [isLoadding, setIsLoadding] = useState(false)
    const [countryList, setCountryList] = useState()


    const debouncedFilter = useDebounce(filter, 500);






    const rowDataList = () => {
        const listArray = dataList?.results?.map((item, i) => ({
            ...item,
            status: (
                <div className="statusofOrder">
                    {item?.status === 1 ? <span>Requirement</span> : item?.status === 2 ? <span>Pending</span> : item?.status === 3 ? <span>Active Orders</span> : item?.status === 4 ? <span>Near to Expair</span> : item?.status === 5 ? <span>Cancelled</span> : item?.status === 6 ? <span>Completed</span> : ""}
                </div>
            ),

            route: (
                <div>
                    {`${item?.from_location} < ${item?.to_location}`}
                </div>
            ),

            product: (
                <div>
                    {item?.product_detail?.name}
                </div>
            ),

            date: (

                <div className="dateOfOredr">{formatDate(item?.added_on)}</div>
            ),
            qty: `${item?.qty} Ltr`,

            action: (
                <>
                    <div className="actionBtn">
                        <div className="requiermentBtn">
                            {filter?.status === 1 ? <button className="anime-btn accept_odr" >
                                Send Reminder
                            </button> : <button className="anime-btn accept_odr" >
                                Start Trip
                            </button>}
                            <button className="anime-btn accept_odr">
                                Edit
                            </button>
                        </div>
                    </div>
                </>
            ),
            sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
            icon: (
                <>
                    <img src={item?.icon} alt={item?.name} />
                </>
            ),
        }));
        return listArray;
    };



    const { data: dataList, isSuccess } = useQuery(
        ["getAllDriver", debouncedFilter],
        () => getAllDriver(debouncedFilter, filter),
        {
            enabled: Boolean(debouncedFilter),
            refetchOnWindowFocus: false

        }
    );

    useEffect(() => {

        getCountryList()

    }, [])


    const getCountryList = async () => {
        const res = await getCountry()
        setCountryList(res?.results)

    }


    const clearAll = () => {
        setFormOrderValue({
            name: "",
            mobile_number: "",
            emergency_mobile_number: "",
            is_mobile_access: true,
            opining_balance_date: "",
            opining_balance: "",
            address_one: '',
            address_two: '',
            country: "",
            state: "",
            city: "",
            pincode: '',
            password: "",
            confirm_password: "",
            license_number: "",
            license_expiry_date: "",
            license_file: "",
            passport_number: "",
            passport_expiry_date: "",
            passport_file: "",
            contract_expiry_date: "",
            contract_file: "",
            is_active: true,

        })
        setFormOrderName({
            name: "",
            mobile_number: "",
            emergency_mobile_number: "",
            is_mobile_access: true,
            opining_balance_date: "",
            opining_balance: "",
            address_one: '',
            address_two: '',
            country: "",
            state: "",
            city: "",
            pincode: '',
            password: "",
            confirm_password: "",
            license_number: "",
            license_expiry_date: "",
            license_file: "",
            passport_number: "",
            passport_expiry_date: "",
            passport_file: "",
            contract_expiry_date: "",
            contract_file: "",
            is_active: true,

        })
        setIsEdit(false);
        setFormValue("");
        setFormError("");
    }


    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };

    const updateTagStatus = (item) => {

        const payload = {
            id: item.id,
            is_active: !item?.is_active
        }
        updateActiveMutation.mutate(payload);
    }

    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addDriverValidation
            );
            setValidationError(error);
        }
    };


    const onOrderSubmit = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue },
            addDriverValidation
        );


        const formData = new FormData();
        formData.append("name", formOrderValue?.name);
        formData.append("mobile_number", formOrderValue?.mobile_number);
        formData.append("emergency_mobile_number", formOrderValue?.emergency_mobile_number);
        formData.append("is_mobile_access", formOrderValue?.is_mobile_access);
        formData.append("opining_balance_date", formOrderValue?.opining_balance_date);
        formData.append("opining_balance", formOrderValue?.opining_balance);
        formData.append("address_one", formOrderValue?.address_one);
        formData.append("address_two", formOrderValue?.address_two);
        formData.append("country", formOrderValue?.country);
        formData.append("state", formOrderValue?.state);
        formData.append("city", formOrderValue?.city);
        formData.append("pincode", formOrderValue?.pincode);
        formData.append("password", formOrderValue?.password);
        formData.append("confirm_password", formOrderValue?.confirm_password);
        formData.append("license_number", formOrderValue?.license_number);
        formData.append("license_expiry_date", formOrderValue?.license_expiry_date);
        if (formOrderValue?.license_file) formData.append("license_file", formOrderValue?.license_file);

        formData.append("passport_number", formOrderValue?.passport_number);
        formData.append("passport_expiry_date", formOrderValue?.passport_expiry_date);
        if (formOrderValue?.passport_file) formData.append("passport_file", formOrderValue?.passport_file);

        formData.append("contract_expiry_date", formOrderValue?.contract_expiry_date);
        if (formOrderValue?.contract_file) formData.append("contract_file", formOrderValue?.contract_file);

        formData.append("is_active", formOrderValue?.is_active);




        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate();
            } else {
                handleAdd(formData);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };

    const updateActiveMutation = useMutation(updateActiveResearch, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };

    const handleDelete = () => {
        deleteMutation.mutate(deleteId);
    };


    const handleEditAction = (item) => {
        getResearchDetails.mutate(item?.id)

    };

    const getResearchDetails = useMutation(singleNewDetails, {
        onSuccess: (res) => {
            if (res?.success) {

                setFormValue({
                    title: res?.results?.title,
                    subject: res?.results?.subject,
                    short_description: res?.results?.short_description,
                    description: res?.results?.description,
                    webpage_link: res?.results?.webpage_link,
                    tags: res?.results?.tags.length ? res?.results?.tags?.map((i) => i) : [],
                    remark: '',
                    // image: res?.results?.image

                })

                let tagDetails = res?.results?.tags_detail?.map(
                    (item) => {
                        return { label: item?.name, value: item?.id };
                    }
                );

                setIsEdit(true)
                setIsOpen(true)
                setFormError("");
                setUpdateId(res?.results?.id)

            } else {

            }
        }
    })

    const handleUpdate = async (data) => {

        const validationResult = await checkFormError(
            { ...formValue },
            addResearchSchema
        );


        const payload = {
            formdata: data,
            id: updateId
        }

        updateMutation.mutate(payload);
    };

    const addMutation = useMutation(addDriver, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);

            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const updateMutation = useMutation(updateDriver, {
        onSuccess: (res) => {
            if (res?.success) {
                setIsEdit(false);
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const deleteMutation = useMutation(deleteDriver, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                setOpenDel(false);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success");
        queryClient.refetchQueries("getAllDriver");
        setIsOpen(false);
        setIsLoadding(false)
        clearAll()
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error"));
        setIsLoadding(false)
    };

    const handlePageClick = async (data) => {
        const currentPage = data.selected;
        setFilter((s) => ({
            ...s,
            offset: currentPage * filter.limit,
            currentPage: currentPage,
        }));
    };

    const hendleDeleteTag = (data, flag) => {
        setOpenDel(flag);
        setDeleteId(data)
    }


    const handleActive = (data) => {
        setFilter({
            ...filter,
            status: data.value
        })
    }

    const getTableList = () => {
        switch (+filter.status) {
            case 1:
                return Requirement

            default:
                return Requirement

        }
    }



    return (
        <>
            <div className='tabMain_head'> <Tabs TabList={nominationTabList} handleActive={handleActive} activeTab={filter.status} />
                <div>
                    {/* <button className='addPlan' onClick={() => setIsOpen(true)}>Add Driver</button> */}
                </div>

            </div>
            <div className="anvTask_prog">
                <div className="anvInputs&fillters">

                    <div className="inr_flex_filter" >
                        <button className="filterBox">Filter</button>
                    </div>
                    <div className="inr_flex_mini ">
                        <InputField placeholder="Customer Name" className="anvSearch_input" />
                    </div>
                    <div className="inr_flex_mini">
                        <InputField placeholder="Search" className="anvSearch_input" />
                    </div>
                </div>
            </div>

            <div>
                <div className="table-responsive">
                    <CustomTable
                        column={getTableList()}
                        data={rowDataList()}
                    // tableLoading={tableLoading}
                    />
                </div>
            </div>
            {dataList?.results?.length === 0 ? (
                ""
            ) : (
                <CustomPagination
                    onPageChange={handlePageClick}
                    currentPage={filter?.currentPage}
                    pageCount={Math.ceil(dataList?.count / filter?.limit)}
                />
            )}



            {isOpen && <CustomOffcanvas
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(!isOpen)
                    clearAll()
                    setIsEdit(false);
                }
                }
                isLoadding={isLoadding}
                onSubmit={(e) => onOrderSubmit(e)}
                title={isEdit ? "Edit Research" : "Add Driver"}
                submitLabel={isEdit ? "Update Research" : "Save"}
                inputProps={
                    <div className="anvAdd_drive">
                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Driver Name"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="name"
                                    type="text"
                                    focus={!!formError?.name}
                                    error={formError.name}
                                    value={formOrderValue?.name}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : " Contact Number"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="mobile_number"
                                    maxLength={10}
                                    type="text"
                                    focus={!!formError?.mobile_number}
                                    error={formError.mobile_number}
                                    value={formOrderValue?.mobile_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="anvOt_gridBx ">
                            <div className="anvOt_outerFull">
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Truck number" : "Emergency Contact Numberr"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="emergency_mobile_number"
                                        type="text"
                                        maxLength={10}
                                        focus={!!formError?.emergency_mobile_number}
                                        error={formError.emergency_mobile_number}
                                        value={formOrderValue?.emergency_mobile_number}
                                        onChange={({ target: { name, value } }) =>
                                            handleOrderChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="anvOt_outerFull">
                                <div className="anvOt_inputField checkboxtext">
                                    <InputField
                                        className="modal_input "
                                        labelClass="FormLableKaran"
                                        placeholder="Company Registration Number"
                                        name="is_mobile_access"
                                        type="checkbox"
                                        focus={!!formError?.is_mobile_access}
                                        error={formError.is_mobile_access}
                                        value={formOrderValue?.is_mobile_access}
                                        onChange={({ target: { name, checked } }) => {

                                            handleOrderChange(name, checked)
                                        }
                                        }
                                    />
                                    <sapn className='checkboxTextbox'>Mobile App Access</sapn>

                                </div>
                            </div>
                        </div>


                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">

                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Set Password"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Password"
                                    name="password"
                                    type="password"
                                    focus={!!formError?.password}
                                    error={formError.password}
                                    value={formOrderValue?.password}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            {/* } */}
                            <div className="anvOt_inputField ">

                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Repeat Password"}
                                    labelClass="FormLableKaran"
                                    placeholder="Repeat Password"
                                    name="confirm_password"
                                    type="password"
                                    focus={!!formError?.confirm_password}
                                    error={formError.confirm_password}
                                    value={formOrderValue?.confirm_password}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>

                        </div>


                        <div className="anvOt_outerFull partMarg">
                            <span>Account Details</span>
                        </div>
                        <div className="anvOt_gridBx ">


                            <div className="anvOt_inputField ">
                                <div >
                                    <span>
                                        <Label className="FormLableKaran">Opening Balance Date</Label>
                                    </span>
                                    <div>
                                        <CustomDatePicker
                                            value={formOrderName?.opining_balance_date}
                                            max={formatDate(new Date())}
                                            focus={!!formError?.opining_balance_date}
                                            error={formError?.opining_balance_date}
                                            name="opining_balance_date"
                                            onSelect={({ target: { name, value } }) => {
                                                handleOrderChange(name, value)
                                                setFormOrderName({ ...formOrderName, opining_balance_date: value })
                                            }
                                            }
                                            className="date-input"
                                        // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                        // className="date-input"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : " Opening Balance (Dr. / Cr.)"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="opining_balance"
                                    type="text"
                                    focus={!!formError?.opining_balance}
                                    error={formError.opining_balance}
                                    value={formOrderValue?.opining_balance}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="anvOt_outerFull partMarg">
                            <span>Driver Address Details</span>
                        </div>
                        <div className="anvOt_gridBx ">

                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Address Line 1"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="address_one"
                                    type="text"
                                    focus={!!formError?.address_one}
                                    error={formError.address_one}
                                    value={formOrderValue?.address_one}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Address Line 2"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="address_two"
                                    type="text"
                                    focus={!!formError?.address_two}
                                    error={formError.address_two}
                                    value={formOrderValue?.address_two}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="anvOt_gridBx ">
                            {/* <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Country"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="country"
                                    type="text"
                                    focus={!!formError?.country}
                                    error={formError.country}
                                    value={formOrderValue?.country}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div> */}
                            <div className="anvOt_inputField ">
                                <span>
                                    <Label className="FormLableKaran">Country</Label>
                                </span>
                                <div>
                                    <Dropdown
                                        className='dropdownoforder'
                                        placeholder="Select Country"
                                        name="fleet_type"
                                        options={countryList ? countryList : []}
                                        valueText="id"
                                        labelText="name"
                                        focus={!!formError?.country}
                                        error={formError?.country}
                                        selectedOption={formOrderName?.country}
                                        setSelectedOption={(data) => {

                                            setFormOrderName({ ...formOrderName, country: data })
                                            handleOrderChange("country", data?.value);

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "State"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="state"
                                    type="text"
                                    focus={!!formError?.state}
                                    error={formError.state}
                                    value={formOrderValue?.state}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>


                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "City"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="city"
                                    type="text"
                                    focus={!!formError?.city}
                                    error={formError.city}
                                    value={formOrderValue?.city}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Pin Code"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="pincode"
                                    type="text"
                                    maxLength={7}
                                    focus={!!formError?.pincode}
                                    error={formError.pincode}
                                    value={formOrderValue?.pincode}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="anvOt_outerFull partMarg">
                            <span>Document Details</span>
                        </div>
                        <div className="anvOt_gridBx ">

                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "License Number"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="license_number"
                                    type="text"
                                    maxLength={16}
                                    focus={!!formError?.license_number}
                                    error={formError.license_number}
                                    value={formOrderValue?.license_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <div >
                                    <span>
                                        <Label className="FormLableKaran">License Expiry Date</Label>
                                    </span>
                                    <div>
                                        <CustomDatePicker
                                            value={formOrderName?.license_expiry_date}
                                            max={formatDate(new Date())}
                                            focus={!!formError?.license_expiry_date}
                                            error={formError?.license_expiry_date}
                                            name="license_expiry_date"
                                            onSelect={({ target: { name, value } }) => {
                                                handleOrderChange(name, value)
                                                setFormOrderName({ ...formOrderName, license_expiry_date: value })
                                            }
                                            }
                                            className="date-input"
                                        // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                        // className="date-input"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="anvOt_gridBx ">
                            <div className="anvOt_outerFull">
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Truck number" : "Attach License"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="license_file"
                                        type="file"
                                        focus={!!formError?.license_file}
                                        error={formError.license_file}
                                        // value={formOrderValue?.license_file}
                                        onChange={({ target: { name, files } }) =>
                                            handleOrderChange(name, files[0])
                                        }
                                    />
                                </div>
                            </div>

                        </div>


                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Passport Number"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="passport_number"
                                    type="text"
                                    maxLength={12}
                                    focus={!!formError?.passport_number}
                                    error={formError.passport_number}
                                    value={formOrderValue?.passport_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <div >
                                    <span>
                                        <Label className="FormLableKaran">Passport Expirty Date</Label>
                                    </span>
                                    <div>
                                        <CustomDatePicker
                                            value={formOrderName?.passport_expiry_date}
                                            max={formatDate(new Date())}
                                            focus={!!formError?.passport_expiry_date}
                                            error={formError?.passport_expiry_date}
                                            name="passport_expiry_date"
                                            onSelect={({ target: { name, value } }) => {
                                                handleOrderChange(name, value)
                                                setFormOrderName({ ...formOrderName, passport_expiry_date: value })
                                            }
                                            }
                                            className="date-input"
                                        // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                        // className="date-input"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="anvOt_outerFull">
                                <div className="anvOt_inputField ">
                                    <InputField
                                        className="modal_input orderModal_Input"
                                        label={isEdit ? "Truck number" : "Attach Passport"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="passport_file"
                                        type="file"
                                        focus={!!formError?.passport_file}
                                        error={formError.passport_file}
                                        // value={formOrderValue?.passport_file}
                                        onChange={({ target: { name, files } }) =>
                                            handleOrderChange(name, files[0])
                                        }
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <div >
                                    <span>
                                        <Label className="FormLableKaran">Contract Expiry Date</Label>
                                    </span>
                                    <div>
                                        <CustomDatePicker
                                            value={formOrderName?.contract_expiry_date}
                                            max={formatDate(new Date())}
                                            focus={!!formError?.contract_expiry_date}
                                            error={formError?.contract_expiry_date}
                                            name="contract_expiry_date"
                                            onSelect={({ target: { name, value } }) => {
                                                handleOrderChange(name, value)
                                                setFormOrderName({ ...formOrderName, contract_expiry_date: value })
                                            }
                                            }
                                            className="date-input"
                                        // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                        // className="date-input"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Attach Contract"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="contract_file"
                                    type="file"
                                    focus={!!formError?.contract_file}
                                    error={formError?.contract_file}
                                    // value={formOrderValue?.contract_file}
                                    onChange={({ target: { name, files } }) =>
                                        handleOrderChange(name, files[0])
                                    }
                                />
                            </div>
                        </div>

                    </div>

                }
            />}
            <Modal isOpen={showCropper} onClose={() => setShowCropper(true)} className="imageCrooperModal">
                {typeof showCropper !== "boolean" && (
                    <ImageCropper
                        setFormValue={setFormValue}
                        setShowCropper={setShowCropper}
                        image={showCropper}
                        cropShape="rect"
                        aspect={6 / 3}
                        formValue={formValue}
                    />
                )}

            </Modal>
            <ConfirmModal
                isOpen={openDel}
                toggle={() => setOpenDel(!openDel)}
                onSubmit={() => handleDelete()}
            />
        </>
    );
};
