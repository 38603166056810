import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import {
  checkFormError,
  formatDate,
  getTimeAmPm,
  tripDetailsTabList,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../../Component/Common/Tabs";
import { getStorage } from "../../helper/storage";
import {
  getAllDetailsLog,
  getAllTripsDetailsLog,
  singleTripDetails,
  singleTripDetailsExpense,
} from "../../services/tripService";
import TableForModal from "../../Component/Common/TableForModal";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { getCurrency } from "../../services/currencyService";
import { getExpenseCategory } from "../../services/expenseCategory";
import {
  addTripFuelExpence,
  addTripReciptExpence,
} from "../../helper/errorSchema";
import {
  addDriverPaid,
  addFuelPaid,
  approvefuelExpense,
  getAllDriverTrans,
  getAllDriverTransforTrip,
  getAllFuelExpense,
  getKranExpense,
  singleDriverDetailsPaid,
  updateDriverPaid,
  updatefuelTripExpense,
} from "../../services/driverService";
import { CiWallet } from "react-icons/ci";
import { getAllFleet } from "../../services/fleetTypeService";
import { getAllRoute } from "../../services/routeService";
import { getAllFuelStation } from "../../services/fuelStationService";
import useDebounce from "../../helper/customhook";
import axios from "axios";
const modeList = [
  { label: "Cash", value: 1 },
  { label: "Online", value: 2 },
];
const fuelTypeList = [
  { label: "PETROL", value: 1 },
  { label: "DIESEL", value: 2 },
];


export const TripDetails = () => {
  const formObj = {
    number: "",
    date: formatDate(new Date(), "-"),
  };

  const orderObj = {
    customer: "",
    pickup_date: formatDate(new Date(), "-"),
    drop_date: formatDate(new Date(), "-"),
    from_location: "",
    to_location: "",
    route: "",
    product: "",
    qty: "",
    remark: "",
    driver: "",
    truck_number: "",
    nomination: "",
  };
  const orderObjName = {
    customer: "",
    pickup_date: formatDate(new Date(), "-"),
    drop_date: formatDate(new Date(), "-"),
    from_location: "",
    to_location: "",
    route: "",
    product: "",
    qty: "",
    remark: "",
    driver: "",
    truck_number: "",
    nomination: "",
  };

  const reciObj = {
    reciptDate: formatDate(new Date(), "-"),
    orderDate: getTimeAmPm(new Date(), "-"),
    reciptDriver: "",
    reciCategory: "",
    reciDescription: "",
    reciModeOFpay: "",
    reciAmount: "",
    reciCurrency: "",
    reciFleet: "",
    reciFuelStation: "",
    reciFuelType: 2,
    reciLtr: "",
    reciRate: "",
    reciRoute: "",
    reci_active: true,
  };

  const reciObjName = {
    reciptDate: formatDate(new Date(), "-"),
    orderDate: getTimeAmPm(new Date()),
    reciptDriver: "",
    reciCategory: "",
    reciDescription: "",
    reciModeOFpay: "",
    reciAmount: "",
    reciCurrency: "",
    reciFleet: "",
    reciFuelStation: "",
    reciFuelType: fuelTypeList.find((i) => i.value === 2),
    reciLtr: "",
    reciRate: "",
    reciRoute: "",
    reci_active: true,
  };

  const Requirement = [
    { heading: "Date.", className: "sorting", value: "date", sortName: "" },
    {
      heading: "Trn Number",
      className: "sorting_asc",
      value: "tipno",
      sortName: "",
    },
    {
      heading: "Account",
      className: "sorting_asc",
      value: "account",
      sortName: "",
    },
    {
      heading: "Category",
      className: "sorting_asc",
      value: "category_name",
      sortName: "",
    },
    {
      heading: "Description",
      className: "sorting_asc",
      value: "description",
      sortName: "",
    },
    {
      heading: "Amount",
      className: "sorting_asc",
      value: "amounts",
      sortName: "",
    },
    { heading: "", className: "sorting_asc", value: "action", sortName: "" },
  ];
  const RouteExpencePay = [
    { heading: "Date.", className: "sorting", value: "date", sortName: "" },

    {
      heading: "Category",
      className: "sorting_asc",
      value: "category_name",
      sortName: "",
    },
    {
      heading: "Description",
      className: "sorting_asc",
      value: "description",
      sortName: "",
    },
    {
      heading: "Amount",
      className: "sorting_asc",
      value: "amounts",
      sortName: "",
    },
  ];
  const claim = [
    { heading: "Date.", className: "sorting", value: "date", sortName: "" },
    {
      heading: "Trn Number",
      className: "sorting_asc",
      value: "tipno",
      sortName: "",
    },
    {
      heading: "Account",
      className: "sorting_asc",
      value: "account",
      sortName: "",
    },
    {
      heading: "Category",
      className: "sorting_asc",
      value: "category_name",
      sortName: "",
    },
    {
      heading: "Description",
      className: "sorting_asc",
      value: "description",
      sortName: "",
    },
    {
      heading: "Amount",
      className: "sorting_asc",
      value: "amountsClaim",
      sortName: "",
    },
    { heading: "", className: "sorting_asc", value: "action", sortName: "" },
  ];
  const SummaryCol = [
    { heading: "Category", className: "sorting", value: "label", sortName: "" },
    {
      heading: "Amount",
      className: "sorting_asc",
      value: "value",
      sortName: "",
    },
  ];
  const fuelCulome = [
    { heading: "Date.", className: "sorting", value: "fuleDate", sortName: "" },
    // {
    //   heading: "Trn Number",
    //   className: "sorting_asc",
    //   value: "order_number",
    //   sortName: "",
    // },
    {
      heading: "Station Name",
      className: "sorting_asc",
      value: "fuel_station_name",
      sortName: "",
    },
    {
      heading: "Truck Number",
      className: "sorting_asc",
      value: "truck_number",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "statusFuel",
      sortName: "",
    },
    { heading: "LTR", className: "sorting_asc", value: "qty", sortName: "" },
    {
      heading: "Given",
      className: "sorting_asc",
      value: "actule",
      sortName: "",
    },
    { heading: "Rate", className: "sorting_asc", value: "rate", sortName: "" },
    {
      heading: "Amount",
      className: "sorting_asc",
      value: "total_amount",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "actionFuel",
      sortName: "",
    },
  ];
  const fuelCulExpense = [
    { heading: "Date.", className: "sorting", value: "fuleDate", sortName: "" },
    // {
    //   heading: "Trn Number",
    //   className: "sorting_asc",
    //   value: "order_number",
    //   sortName: "",
    // },
    {
      heading: "Station Name",
      className: "sorting_asc",
      value: "fuel_station_name",
      sortName: "",
    },
    {
      heading: "Truck Number",
      className: "sorting_asc",
      value: "vehicle_number",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "statusFuel",
      sortName: "",
    },
    { heading: "LTR", className: "sorting_asc", value: "qty", sortName: "" },

    { heading: "Rate", className: "sorting_asc", value: "rate", sortName: "" },
    {
      heading: "Amount",
      className: "sorting_asc",
      value: "total_amount",
      sortName: "",
    },
  ];
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  let defultExpense = {
    dates: formatDate(new Date(), "-"),
    amounts: "",
    currencies: "",
  };

  const routeObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const tripID = getStorage("tripID");
  const tripDetals = getStorage("tripDetals");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    trip: tripID,
    ...obj,
  });
  const [exCategoryList, setExCategoryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [fleetList, setFleetList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [fuelStationList, setFuelStationList] = useState([]);
  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formReciValue, setFormReciValue] = useState(reciObj);
  const [formReciName, setFormReciName] = useState(reciObjName);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);
  const [loaderForExpense, setloaderForExpenseList] = useState(false);
  const [transtaionType, setTranstaionType] = useState("");
  const [inputValue, setInputValue] = useState({
    excel_file: "",
  });
  const [inputError, setInputError] = useState({
    excel_file: "",
  });
  const [actionModal, setAction] = useState("");
  const [tripExpense, setTripExpense] = useState([]);
  const [fuelExpense, setFuelExpense] = useState([]);
  const [driverTransaction, setDriverTransaction] = useState([]);
  const [trailerArry, setTrailerArry] = useState([]);
  const [driverId, setDriverId] = useState();
  const [sendForNomi, setSendForNomi] = useState(0);
  const [loaddingAproove, setLoaddingAproove] = useState(false);
  const [loaddingReject, setLoaddingReject] = useState(false);
  const debouncedFilter = useDebounce(filter, 500);
  const [isClaimed, setIsClaimed] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [tripSum, setTripSum] = useState(0);
  const [driverSum, setDriverSum] = useState(0);
  const [driverSumReceve, setDriverSumReceve] = useState(0);
  const [driverSumPaid, setDriverSumPaid] = useState(0);
  const [fuelSum, setFuelSum] = useState(0);
  const [responseKaran, setResponseKaran] = useState();
  const [tripLogList, setTripLogList] = useState();
  const [labelStatus, setLabel] = useState("");
  const [routeDetails, setRouteDetails] = useState();
  
  const { data: dataList, isSuccess } = useQuery(
    ["getAllFuelExpen", debouncedFilter],
    () => getAllFuelExpense(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  const fuelArray = [
    {
      label: "Payments",
      value: parseInt(tripSum),
    },
    {
      label: "Fuel",
      value: parseInt(fuelSum),
    },
    {
      label: "Claim Paid",
      value: parseInt(`-${driverSumPaid}`),
    },
    {
      label: "Claim Received",
      value: parseInt(driverSumReceve),
    },
  ];

  useEffect(() => {
    const total = tripSum + fuelSum - driverSumPaid + driverSumReceve;
    setTotalSum(total);
  }, [tripSum, fuelSum, driverSumPaid, driverSumReceve]);

  useEffect(() => {
    const trip = tripExpense?.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseInt(
          currentValue.payment_number
            ? +currentValue.amount
            : -currentValue.amount
        )
      );
    }, 0);
    setTripSum(trip);
  }, [tripExpense]);

  useEffect(() => {
    const driver = driverTransaction?.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseInt(
          currentValue.payment_number
            ? +currentValue.amount
            : -currentValue.amount
        )
      );
    }, 0);

    const Receve = driverTransaction
      ?.filter((item) => item.receipt_number)
      .reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.amount);
      }, 0);

    const Paid = driverTransaction
      ?.filter((item) => item.payment_number)
      .reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.amount);
      }, 0);

    setDriverSumReceve(Receve);
    setDriverSumPaid(Paid);
    setDriverSum(driver);
  }, [driverTransaction]);

  useEffect(() => {
    const fuel = dataList?.results?.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseInt(currentValue.total_amount ? currentValue.total_amount : 0)
      );
    }, 0);

    setFuelSum(fuel);
  }, [dataList?.results]);

  useEffect(() => {
    getCurrencyList();
    getExpenseList();
    getFleetList();
    getRouteList();
    getAllFuelStat();
  }, []);

  useEffect(() => {
    if (tripExpense) {
      setloaderForExpenseList(false);
      setIsLoadding(false);
    } else {
      setloaderForExpenseList(true);
    }
  }, [tripExpense]);

  useEffect(() => {
    if (tripID) {
      if ((fleetList, routeList)) getShowDetails(tripID);

      getShowExpense(tripID);
      getShowdriverTransaction(tripID);
    }
  }, [tripID, fleetList, routeList]);
  useEffect(() => {
    if (filter.status == 4) {
      getLogTrip();
    }
  }, [filter]);

  useEffect(() => {
    if (tripDetals === "Trip") {
      setFilter({
        ...filter,
        status: 2,
      });
    }
  }, [tripDetals]);

  useEffect(() => {
    const total =
      formReciValue.reciRate * formReciValue.reciLtr
        ? formReciValue.reciLtr
        : 0;
    setFormReciValue({ ...formReciValue, reciAmount: total });
    setFormReciName({ ...formReciName, reciAmount: total });
  }, [formReciValue.reciRate, formReciValue.reciLtr]);

  useEffect(() => {
    if (labelStatus === "approve") {
      if (responseKaran?.status === 400) {
        const payload = { fuel_order_status: 2, id: updateId };
        handleUpdateFuel(payload);
      } else {
        const payload = {
          fuel_order_status: 3,
          order_otp: responseKaran?.results?.otp,
          order_number: responseKaran?.results?.order_no,
          id: updateId,
        };
        handleUpdateFuel(payload);
      }
    }

    if (labelStatus === "resend") {
      if (responseKaran?.status === 200) {
        const payload = {
          fuel_order_status: 3,
          order_otp: responseKaran?.results?.otp,
          order_number: responseKaran?.results?.order_no,
          id: updateId,
        };

        handleUpdateFuel(payload);
      } else {
        setUpdateId("");
      }
    }
    if (labelStatus === "check") {
      if (responseKaran?.message === "Data Available") {
        const payload = { fuel_order_status: 4, id: updateId };
        handleUpdateFuel(payload);
      } else {
        setUpdateId("");
      }
    }
  }, [responseKaran]);

  const rowDataList = (tripExpense) => {
    const listArray = tripExpense?.map((item, i) => ({
      ...item,
      tipno: item?.receipt_number ? item?.receipt_number : item?.payment_number,
      status: (
        <div className="statusofOrder">
          {item?.status === 1 ? (
            <span>Requirement</span>
          ) : item?.status === 2 ? (
            <span>Pending</span>
          ) : item?.status === 3 ? (
            <span>Active Orders</span>
          ) : item?.status === 4 ? (
            <span>Near to Expair</span>
          ) : item?.status === 5 ? (
            <span>Cancelled</span>
          ) : item?.status === 6 ? (
            <span>Completed</span>
          ) : (
            ""
          )}
        </div>
      ),

      statusFuel: (
        <div className="">
          {item?.fuel_order_status === 1 ? (
            <span>Requested</span>
          ) : item?.fuel_order_status === 2 ? (
            <span>Low Balance</span>
          ) : item?.fuel_order_status === 3 ? (
            <div className="otp_div">
              <span>Success</span>
              <small>
                {item?.order_number ? `O.N.-${item?.order_number}` : ""}
              </small>
              <small>{item?.order_otp ? `OTP-${item?.order_otp}` : ""}</small>
            </div>
          ) : item?.fuel_order_status === 4 ? (
            <span>Completed</span>
          ) : (
            ""
          )}
        </div>
      ),
      route: <div>{`${item?.from_location} < ${item?.to_location}`}</div>,

      product: <div>{item?.product_detail?.name}</div>,

      date: <div className="dateOfOredr">{formatDate(item?.date)}</div>,
      qty: item?.qty ? `${item?.qty} Ltr` : "",
      actule: item?.actual_qty_given ? `${item?.actual_qty_given} Ltr` : "",

      SN: i + 1,
      account: `Driver > ${item?.driver_name}`,
      amounts: (
        <div className="ammount_status">
          <div>
            {item?.receipt_number
              ? `+ ${item?.amount} ${item?.currency_name}`
              : `- ${item?.amount} ${item?.currency_name}`}
          </div>
        </div>
      ),
      amountsClaim: (
        <div className="ammount_status">
          <div>
            {item?.receipt_number
              ? `+ ${item?.amount} ${item?.currency_name}`
              : `- ${item?.amount} ${item?.currency_name}`}
          </div>
          {item.approval_status === 1 && <div>?</div>}
        </div>
      ),
      // action: (
      //     <>
      //         <div className="actionBtn">

      //             <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />
      //         </div>
      //     </>
      // ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
      fuleDate: item?.order_date,
      actionFuel: (
        <>
          <div className="button_div">
            {item?.fuel_order_status === 1 && (
              <button
                className="fuelActionbtn"
                disabled={item?.id === updateId}
                onClick={() => {
                  approveStatus(item, "approve");
                  setLabel("approve");
                  setUpdateId(item?.id);
                }}
              >
                Approve
              </button>
            )}
            {item?.fuel_order_status === 2 && (
              <button
                className="fuelActionbtn"
                disabled={item?.id === updateId}
                onClick={() => {
                  approveStatus(item, "resend");
                  setLabel("resend");
                  setUpdateId(item?.id);
                }}
              >
                Resend
              </button>
            )}
            {item?.fuel_order_status === 3 && (
              <button
                className="fuelActionbtn"
                disabled={item?.id === updateId}
                onClick={() => {
                  approveStatus(item, "check");
                  setLabel("check");
                  setUpdateId(item?.id);
                }}
              >
                Check
              </button>
            )}
          </div>
        </>
      ),
    }));
    return listArray;
  };

  const getCurrencyList = async () => {
    const res = await getCurrency(countryObj);
    setCurrencyList(
      res?.results?.map((item) => {
        return { ...item, label: item?.name, value: item?.id };
      })
    );
  };
  const getLogTrip = async () => {
    const res = await getAllDetailsLog({ trip: tripID ,ordering:"status"});
   
    setTripLogList(res?.results);
  };

  const getFleetList = async () => {
    const res = await getAllFleet(countryObj);
    setFleetList(
      res?.results?.map((item) => {
        return { ...item, label: item?.name, value: item?.id };
      })
    );
  };

  const getRouteList = async () => {
    const res = await getAllRoute(countryObj);
    setRouteList(
      res?.results?.map((item) => {
        return { ...item, label: item?.name, value: item?.id };
      })
    );
  };

  const getAllFuelStat = async () => {
    const res = await getAllFuelStation(countryObj);
    setFuelStationList(
      res?.results?.map((item) => {
        return {
          ...item,
          label: `${item?.station_name} ${item?.code ? `- ${item.code}` : ""}`,
          value: item?.id,
        };
      })
    );
  };

  const getExpenseList = async () => {
    const res = await getExpenseCategory(countryObj);
    setExCategoryList(res?.results);
  };

  const getShowDetails = (tripId) => {
    getTripDetails.mutate(tripId);
  };

  const getShowExpense = (tripId) => {
    getTripExpenseDetails.mutate(tripId);
  };

  const getShowdriverTransaction = (tripId) => {
    getDriverTansDetails.mutate(tripId);
  };

  const getDriverTansDetails = useMutation(getAllDriverTransforTrip, {
    onSuccess: (res) => {
      setDriverTransaction(res?.results);

      // setFuelExpense(res?.results)
    },
  });

  const getTripExpenseDetails = useMutation(singleTripDetailsExpense, {
    onSuccess: (res) => {
      setTripExpense(res?.results);

      // setFuelExpense(res?.results)
    },
  });

  const getDriverDetailsPaid = useMutation(singleDriverDetailsPaid, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormReciValue({
          ...formReciValue,
          reciptDate: res?.results?.date,
          reciCategory: res?.results?.category,
          reciDescription: res?.results?.description,
          reciModeOFpay: res?.results?.payment_mode,
          reciAmount: res?.results?.amount,
          reciCurrency: res?.results?.currency,
          transtaionType: res?.results?.transaction_type,
          is_active: res?.results?.is_active,
        });

        setTranstaionType(res?.results?.transaction_type);
        setFormReciName({
          ...formReciName,
          reciptDate: res?.results?.date,
          reciCategory: {
            label: res?.results?.category_name,
            value: res?.results?.category,
          },
          reciDescription: res?.results?.description,
          reciModeOFpay: {
            label: res?.results?.payment_mode === 1 ? "Cash" : "Online",
            value: res?.results?.payment_mode,
          },
          reciCurrency: {
            label: res?.results?.currency_name,
            value: res?.results?.currency,
          },
          is_active: res?.results?.is_active,
        });

        setIsEdit(true);
        setIsOpen1(true);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const getTripDetails = useMutation(singleTripDetails, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          customer: res?.results?.trip_details?.customer_name,
          pickup_date: res?.results?.trip_details?.pickup_date,
          drop_date: res?.results?.trip_details?.pickup_date,
          from_location: res?.results?.trip_details?.from_location,
          to_location: res?.results?.trip_details?.to_location,
          route: res?.results?.trip_details?.route,
          product: res?.results?.trip_details?.product,
          qty: res?.results?.trip_details?.qty,
          remark: res?.results?.trip_details?.remark,
          driver: res?.results?.fleet_details?.driver_name,
          truck_number: res?.results?.fleet_details?.truck_number,
          nomination: res?.results?.trip_details?.nomination_number,
        });
        setRouteDetails({
          route_name: res?.results?.trip_details?.route,
          id: res?.results?.trip_details?.route_id,
        });
        setFormReciValue({
          ...formReciValue,
          reciFleet: res?.results?.fleet_details?.fleet_id,
          reciRoute: res?.results?.trip_details?.route_id,
        });

        const common = (name, id) => {
          if (name === "customer") {
            let details = fleetList?.find((i) => i.id === id);

            return {
              data: details,
              label: details?.truck_number,
              value: details?.id,
            };
          }
          if (name === "currency") {
            let details = routeList?.find((i) => i.id === id);

            return {
              data: details,
              label: details?.route_name,
              value: details?.id,
            };
          }
        };

        setFormReciName({
          ...formReciName,
          reciFleet: common("customer", res?.results?.fleet_details?.fleet_id),
          reciRoute: common("currency", res?.results?.trip_details?.route_id),
        });

        setTrailerArry(res?.results?.trailer_details);
        setDriverId(res?.results?.fleet_details);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const clearAll = () => {
    setFormReciValue({
      ...formReciValue,
      reciptDate: formatDate(new Date(), "-"),
      reciCategory: "",
      reciDescription: "",
      reciDedut: "",
      reciAmount: "",
      reciModeOFpay: "",
      reciCurrency: "",
      reciFleet: "",
      reciFuelStation: "",
      reciFuelType: "",
      reciLtr: "",
      reciRate: "",
      reciRoute: "",
      reci_active: true,
    });
    setFormReciName({
      ...formReciName,
      reciptDate: formatDate(new Date(), "-"),
      reciCategory: "",
      reciDescription: "",
      reciDedut: "",
      reciAmount: "",
      reciCurrency: "",
      reciModeOFpay: "",
      reciFleet: "",
      reciFuelStation: "",
      reciFuelType: "",
      reciLtr: "",
      reciRate: "",
      reciRoute: "",
      reci_active: true,
    });

    setIsEdit(false);
    setFormValue("");
    setFormError("");
    setInputError("");
    setInputValue({
      excel_file: "",
    });
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const handleReciChange = async (name, value) => {
    const stateObj = { ...formReciValue, [name]: value };

    setFormReciValue(stateObj);

    // if (!!formError) {
    //     const error = await checkFormError(stateObj, addDriverValidation
    //     );
    //     setValidationError(error);
    // }
  };

  const onOrderSubmitRecept = async (e) => {
    const validationResult = await checkFormError(
      { ...formReciValue },
      addTripReciptExpence
    );

    const payload = {
      date: formReciValue.reciptDate,
      driver: driverId?.driver_id,
      category: formReciValue.reciCategory,
      description: formReciValue.reciDescription,
      payment_mode: formReciValue.reciModeOFpay,
      amount: formReciValue.reciAmount,
      currency: formReciValue.reciCurrency,
      transaction_type: transtaionType,
      is_active: formReciValue?.reci_active,
      trip: tripID,
    };

    if (isClaimed) payload["is_claimed"] = isClaimed;

    if (validationResult === true) {
      setIsLoadding(true);

      if (isEdit === true) {
        handleUpdate(payload);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const sendForApproveHandle = async () => {
    const validationResult = await checkFormError(
      { ...formReciValue },
      addTripReciptExpence
    );

    const payload = {
      date: formReciValue.reciptDate,
      driver: driverId?.driver_id,
      category: formReciValue.reciCategory,
      description: formReciValue.reciDescription,
      payment_mode: formReciValue.reciModeOFpay,
      amount: formReciValue.reciAmount,
      currency: formReciValue.reciCurrency,
      transaction_type: transtaionType,
      is_active: formReciValue?.reci_active,
      trip: tripID,
      approval_status: 1,
      is_claimed: isClaimed,
    };

    if (validationResult === true) {
      setLoaddingReject(true);

      if (isEdit === true) {
        handleUpdate(payload);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const onSubmitFuel = async () => {
    const validationResult = await checkFormError(
      { ...formReciValue },
      addTripFuelExpence
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdateFuel();
      } else {
        handleAddFuel();
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const handleAdd = (formData) => {
    addMutationPaid.mutate(formData);
  };

  const handleAddFuel = () => {
    const payload = {
      order_date: formReciValue.reciptDate,
      trip: tripID,
      fleet: formReciValue?.reciFleet,
      fuel_station: formReciValue.reciFuelStation,
      description: formReciValue.reciDescription,
      fuel_type: formReciValue.reciFuelType,
      amount: formReciValue.reciAmount,
      qty: formReciValue.reciLtr,
      rate: formReciValue.reciRate,
      route: formReciValue.reciRoute,
      fuel_order_status: 1,
    };

    addFuelMutationPaid.mutate(payload);
  };

  const handleUpdate = async (data) => {
    const payload = {
      formdata: data,
      id: updateId,
    };

    updateMutation.mutate(payload);
  };
  const handleUpdateFuel = async (data) => {
    const otp = {
      fuel_order_status: data?.fuel_order_status,
      order_number: data?.order_number,
      order_otp: data?.order_otp,
    };

    const payload = {
      formdata: { fuel_order_status: data?.fuel_order_status },
      id: data?.id,
    };

    if (data?.order_number) payload["formdata"] = otp;

    updateFuelMutation.mutate(payload);
  };

  const addMutationPaid = useMutation(addDriverPaid, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        getShowExpense(tripID);
        getShowdriverTransaction(tripID);
        setIsLoadding(false);
        setLoaddingReject(false);
        setUpdateId("");
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
        setLoaddingReject(false);
        setIsClaimed(false);
        setUpdateId("");
      }
    },
  });
  const addFuelMutationPaid = useMutation(addFuelPaid, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setIsLoadding(false);
        queryClient.refetchQueries("getAllFuelExpen");
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });
  const updateMutation = useMutation(updateDriverPaid, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
        setIsClaimed(false);
      }
    },
  });

  const updateFuelMutation = useMutation(updatefuelTripExpense, {
    onSuccess: (res) => {
      if (res?.success) {
        setLabel("");
        setResponseKaran("");
        setIsEdit(false);
        queryClient.refetchQueries("getAllFuelExpen");
        onSuccessResponse(res?.message);
        setUpdateId("");
      } else {
        onErrorResponse(res?.error);
        setUpdateId("");
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllDriverTrans");
    setIsOpen1(false);
    setIsOpen2(false);
    setIsClaimed(false);

    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const handleActive = (data) => {
    // setLoaderForList(true)
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const approveStatus = async (item, label) => {
    // const payload = {
    //   customer: "333333",
    //   vehicle_no: item?.truck_number,
    //   plant: item?.fuel_station_code,
    //   product: item?.fuel_type == 1 ? "Petrol" : "diesel",
    //   qty: parseInt(item?.qty),
    //   driver: item?.driver_name,
    //   trip: item?.trip,
    //   orderDate: item?.order_date,
    // };

    const payload = {
      fuel_expense: item?.id
    };

    if (label === "approve") {
      approveFuelMutation.mutate(payload);
    }

    if (label === "resend") {
      approveFuelMutation.mutate(payload);
    }

    if (label === "check") {
      const getorder = { order_date: item?.order_date,fuel_expense_id:item?.id };
      getFuelExpenseKaran.mutate(getorder);
    }
  };

  const getFuelExpenseKaran = useMutation(getKranExpense, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);

        setResponseKaran(res);

        onSuccessResponse(res?.message);
      } else {
        setUpdateId("");
        onErrorResponse(res?.error);
      }
    },
  });
  const approveFuelMutation = useMutation(approvefuelExpense, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);

        setResponseKaran(res);

        onSuccessResponse(res?.message);
      } else {
        setUpdateId("");
        onErrorResponse(res?.error);
      }
    },
  });

  return (
    <>
      <div className="tabMain_head">
        Trip - {formOrderValue?.nomination ? formOrderValue?.nomination : ""}
      </div>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={tripDetailsTabList}
          handleActive={handleActive}
          activeTab={+filter.status}
        />
      </div>

      <div className="anvAverage_height">
        <div className="table-responsive">
          {+filter?.status === 2 && (
            <div className="tripMainDiv">
              <div className="fistDivOFTrip">
                {/* <div className="TripFirstDiv">
                                <div className="tripButton"><span>Payments</span><button>Pay</button></div>
                                <div><TableForModal
                                    column={Requirement}
                                    data={rowDataList()}
                                    // tableLoading={loaderForList}
                                    boxClass="tableForTripdetails"

                                /></div>
                            </div> */}
                <div className="TripFirstDiv">
                  <div className="tripButton">
                    <span>Payments</span>
                    <button
                      onClick={() => {
                        setAction("paid");
                        setTranstaionType(1);
                        // setIsOpen1(true);
                        setIsClaimed(false);
                      }}
                    >
                      Pay
                    </button>
                  </div>
                  <div>
                    <TableForModal
                      column={Requirement}
                      data={rowDataList(tripExpense)}
                      boxClass="tableForTripdetails"
                      tableLoading={loaderForExpense}
                      total={tripSum}
                    />
                  </div>
                </div>
                <div className="TripFirstDiv">
                  <div className="tripButton">
                    <span>Claim</span>
                    <button
                      onClick={() => {
                        setAction("paid");
                        setTranstaionType(1);
                        setIsOpen1(true);
                        setIsClaimed(true);
                      }}
                    >
                      Pay
                    </button>
                    <button
                      onClick={() => {
                        setAction("recept");
                        setTranstaionType(2);
                        setIsOpen1(true);
                        setIsClaimed(true);
                      }}
                    >
                      Received
                    </button>
                  </div>
                  <div>
                    <TableForModal
                      column={claim}
                      data={rowDataList(driverTransaction)}
                      boxClass="tableForTripdetails"
                      tableLoading={loaderForExpense}
                      total={driverSum}
                    />
                  </div>
                </div>
                <div className="TripFirstDiv">
                  <div className="tripButton">
                    <span>Fuel Details</span>
                    <button
                      onClick={() => {
                        setIsClaimed(false);
                        setIsOpen2(true);
                      }}
                    >
                      Give Fuel
                    </button>
                  </div>
                  <div>
                    <TableForModal
                      column={fuelCulome}
                      data={rowDataList(dataList?.results)}
                      tableLoading={loaderForExpense}
                      boxClass="tableForTripdetails"
                      total={fuelSum}
                    />
                  </div>
                </div>
              </div>
              <div className="secondDivofTrip">
                <div className="TripFirstDiv">
                  <div className="tripButton">
                    <span>Summary</span>
                  </div>
                  <div>
                    <TableForModal
                      column={SummaryCol}
                      data={rowDataList(fuelArray)}
                      tableLoading={loaderForExpense}
                      boxClass="tableForTripdetails"
                      total={totalSum}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {+filter?.status === 1 && (
            <div className="overflowDriver">
              <div className="driverDetailsPage ">
                <div className="anvOt_gridBx ">
                  <div className="anvOt_outerFull ">
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Customer Name" : "Customer Name"}
                        labelClass="FormLableKaran"
                        placeholder="Customer Name"
                        name="reference_number"
                        type="text"
                        disabled="true"
                        value={formOrderValue.customer}
                      />
                    </div>
                  </div>
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Pickup Date" : "Pickup Date"}
                      labelClass="FormLableKaran"
                      placeholder="Pickup Date"
                      name="reference_number"
                      type="text"
                      disabled="true"
                      value={formOrderValue.pickup_date}
                    />
                  </div>
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Drop Date" : "Drop Date"}
                      labelClass="FormLableKaran"
                      placeholder="Drop Date"
                      name="reference_number"
                      type="text"
                      disabled="true"
                      value={formOrderValue.drop_date}
                    />
                  </div>
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "From Location" : "From Location"}
                      labelClass="FormLableKaran"
                      placeholder="From Location"
                      name="remark"
                      type="text"
                      disabled="true"
                      value={formOrderValue?.from_location}
                    />
                  </div>
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "To Location" : "To Location"}
                      labelClass="FormLableKaran"
                      placeholder="To Location"
                      name="remark"
                      type="text"
                      disabled="true"
                      value={formOrderValue?.to_location}
                    />
                  </div>

                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Truck Number" : "Truck Number"}
                      labelClass="FormLableKaran"
                      placeholder="Truck Number"
                      name="remark"
                      type="text"
                      disabled="true"
                      value={formOrderValue?.truck_number}
                    />
                  </div>

                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Driver Name" : "Driver Name"}
                      labelClass="FormLableKaran"
                      placeholder="Driver Name"
                      name="remark"
                      type="text"
                      disabled="true"
                      value={formOrderValue?.driver}
                    />
                  </div>
                  <div className="anvOt_outerFull ">
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Route Name" : "Route Name"}
                        labelClass="FormLableKaran"
                        placeholder="Route Name"
                        name="remark"
                        type="text"
                        disabled="true"
                        value={formOrderValue?.route}
                      />
                    </div>
                  </div>
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Product Name" : "Product Name"}
                      labelClass="FormLableKaran"
                      placeholder="Product Name"
                      name="remark"
                      type="text"
                      disabled="true"
                      value={formOrderValue?.product}
                    />
                  </div>
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Qty" : "Qty"}
                      labelClass="FormLableKaran"
                      placeholder="Qty"
                      name="remark"
                      type="text"
                      disabled="true"
                      value={formOrderValue?.qty}
                    />
                  </div>
                  <div className="anvOt_outerFull trailerdetails_box">
                    <span className="first">Trailer Details</span>
                    {trailerArry.map((item) => (
                      <div className="trips_Details">
                        <span>{item?.trailer_number}</span>
                        <span>
                          {item?.trailer_type === 2 ? "Solid" : "Liquid"}
                        </span>
                        <span>{item?.weight}</span>
                      </div>
                    ))}
                  </div>

                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input"
                      label={isEdit ? "Remark" : "Remark"}
                      labelClass="FormLableKaran"
                      placeholder="Add Remark"
                      name="remark"
                      type="text"
                      disabled="true"
                      focus={!!formError?.remark}
                      error={formError.remark}
                      value={formOrderValue?.remark}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {+filter?.status === 5 && (
            <div className="tripMainDiv">
              <div className="fistDivOFTrip">
                <div className="TripFirstDiv">
                  <div className="tripButton margin-top">
                    <span>Route Name- Route name will come here</span>
                  </div>
                  <div className="tripButton">
                    <span>Payments</span>
                  </div>
                  <div>
                    <TableForModal
                      column={RouteExpencePay}
                      // data={rowDataList(tripExpense)}
                      boxClass="tableForTripdetails"
                      tableLoading={loaderForExpense}
                      // total={tripSum}
                    />
                  </div>
                </div>

                <div className="TripFirstDiv">
                  <div className="tripButton">
                    <span>Fuel Details</span>
                  </div>
                  <div>
                    <TableForModal
                      column={fuelCulExpense}
                      // data={rowDataList(dataList?.results)}
                      tableLoading={loaderForExpense}
                      boxClass="tableForTripdetails"
                      // total={fuelSum}
                    />
                  </div>
                </div>
              </div>
              <div className="secondDivofTrip">
                <div className="TripFirstDiv">
                  <div className="tripButton">
                    <span>Summary</span>
                  </div>
                  <div>
                    <TableForModal
                      column={SummaryCol}
                      data={rowDataList(fuelArray)}
                      tableLoading={loaderForExpense}
                      boxClass="tableForTripdetails"
                      total={totalSum}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        
          {+filter?.status === 4 && (
            <div className="overflowDriver">
              <div className="driverDetailsPage ">
                <div className="vertical-timeline">
                  <div class="container">
                    <div class="wrapper">
                      <ul class="sessions">
                        {tripLogList?.map((item) => (
                          <li className="timeLine">
                            <div class="time">
                              {
                              item?.status == 1
                              ? "Nomination"
                              :
                              item?.status == 2
                                ? "Doc. Ready"
                                : item?.status == 3
                                ? "At Port Entry"
                                : item?.status == 4
                                ? "At Loading"
                                : item?.status == 5
                                ? "Fund Allocation"
                                : item?.status == 6
                                ? "In Transit"
                                : item?.status == 7
                                ? "At Destination"
                                : item?.status == 8
                                ? "Delivered"
                                : item?.status == 9
                                ? "In Return"
                                : item?.status == 10
                                ? "Completed"
                                : item?.status == 11
                                ? "Cancelled"
                                : ""}
                            </div>
                            <p>
                              {item?.date} {item?.action_by_name?`Approved By ${item?.action_by_name}`:""} {`comments - ${item?.comment}`}
                            </p>
                            {item?.document?.length > 0 && (
                              <button className="addMore_button">
                                +Add More
                              </button>
                            )}
                            <div className="doc_main">
                            {(item?.at_loading_document?.length && item?.status == 4) > 0 && (
                                <>
                                  {item?.at_loading_document?.map((item) => (
                                    <div className="timeline_document" onClick={()=>{
                                      window.open(item, '_blank');
                                    }}>
                                      <div className="doc_img">
                                      <img src={item} alt="" />
                                      </div>
                                      <div className="doc_info">
                                        <span className="doc_name">
                                          Document Name
                                        </span>
                                        <span className="doc_time">
                                          Upload time
                                        </span>
                                        <span className="upload_by">
                                          Aman Sharma
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                              {(item?.fund_allocation_document?.length && item?.status == 5) > 0 && (
                                <>
                                  {item?.fund_allocation_document?.map((item) => (
                                    <div className="timeline_document" onClick={()=>{
                                      window.open(item, '_blank');
                                    }}>
                                      <div className="doc_img">
                                      <img src={item} alt="" />
                                      </div>
                                      <div className="doc_info">
                                        <span className="doc_name">
                                          Document Name
                                        </span>
                                        <span className="doc_time">
                                          Upload time
                                        </span>
                                        <span className="upload_by">
                                          Aman Sharma
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                               {(item?.delivered_document?.length && (item?.status == 4 || item?.status == 8)) > 0 && (
                                <>
                                  {item?.delivered_document?.map((item) => (
                                    <div className="timeline_document" onClick={()=>{
                                      window.open(item, '_blank');
                                    }}>
                                      <div className="doc_img">
                                      <img src={item} alt="" />
                                      </div>
                                      <div className="doc_info">
                                        <span className="doc_name">
                                          Document Name
                                        </span>
                                        <span className="doc_time">
                                          Upload time
                                        </span>
                                        <span className="upload_by">
                                          Aman Sharma
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                            {item?.event === "Delivered" && (
                              <p>Receiver - Rohit Jain has signed</p>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isOpen1 && (
        <InputModal
          isOpen={isOpen1}
          headerName={
            isEdit
              ? actionModal === "recept"
                ? "Edit Claim Receipt"
                : " Edit Claim Paid to Driver"
              : actionModal === "recept"
              ? "Add Claim Receipt"
              : "Add Claim Paid to Driver"
          }
          toggle={() => {
            clearAll();
            setIsOpen1(!isOpen1);
          }}
          approveBtn={() => {
            sendForApproveHandle();
          }}
          loaddingReject={loaddingReject}
          loaddingAproove={loaddingAproove}
          submitLabel={"Add Payment"}
          sendFor={"Send for Approval"}
          onSubmit={(e) => onOrderSubmitRecept()}
          isLoadding={isLoadding}
          customeClassName="ModalRouteFlex"
          className="routemodalNew"
          inputProps={
            <>
              <div className="anvMaintab_container_route drFlex">
                <div>
                  <span>
                    <Label className="FormLableKaran">Date</Label>
                  </span>
                  <div>
                    <CustomDatePicker
                      type="date"
                      value={formReciName?.reciptDate}
                      max={formatDate(new Date())}
                      focus={!!formError?.reciptDate}
                      error={formError?.reciptDate}
                      name="reciptDate"
                      placeholder={formReciName?.reciptDate}
                      onSelect={({ target: { name, value } }) => {
                        handleReciChange(name, value);
                        setFormReciName({ ...formReciName, reciptDate: value });
                      }}
                      className="date-input"
                      // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                      // className="date-input"
                    />
                  </div>
                </div>

                <div>
                  <span>
                    <Label className="FormLableKaran">Category</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      placeholder="Select Category"
                      name="reciCategory"
                      options={exCategoryList ? exCategoryList : []}
                      valueText="id"
                      labelText="name"
                      focus={!!formError?.reciCategory}
                      error={formError?.reciCategory}
                      selectedOption={formReciName?.reciCategory}
                      // components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormReciName({
                          ...formReciName,
                          reciCategory: data,
                        });
                        handleReciChange("reciCategory", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span>
                    <Label className="FormLableKaran">Currency</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      classNamePrefix="anvDrop_down"
                      placeholder="Currency"
                      name="reciCurrency"
                      options={currencyList ? currencyList : []}
                      valueText="value"
                      labelText="label"
                      focus={!!formError?.reciCurrency}
                      error={formError?.reciCurrency}
                      selectedOption={formReciName?.reciCurrency}
                      // components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormReciName({
                          ...formReciName,
                          reciCurrency: data,
                        });
                        handleReciChange("reciCurrency", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <InputField
                    className="modal_input orderModal_Input input_radius"
                    label={isEdit ? "Description" : "Description"}
                    labelClass="FormLableKaran"
                    placeholder="Description"
                    name="reciDescription"
                    type="text"
                    maxLength={12}
                    focus={!!formError?.reciDescription}
                    error={formError.reciDescription}
                    value={formReciValue?.reciDescription}
                    onChange={({ target: { name, value } }) =>
                      handleReciChange(name, value)
                    }
                  />
                </div>

                <div className="anvOt_gridBxDr ">
                  <div className="anvOt_inputField ">
                    <InputField
                      className="modal_input orderModal_Input input_radius"
                      label={isEdit ? "Amount" : "Amount"}
                      labelClass="FormLableKaran"
                      placeholder="Amount"
                      name="reciAmount"
                      type="text"
                      maxLength={12}
                      focus={!!formError?.reciAmount}
                      error={formError.reciAmount}
                      value={formReciValue?.reciAmount}
                      onChange={({ target: { name, value } }) =>
                        handleReciChange(name, value)
                      }
                    />
                  </div>
                  <div className="anvOt_inputField ">
                    <span>
                      <Label className="FormLableKaran">Mode of Payment</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        classNamePrefix="anvDrop_down"
                        placeholder="Select"
                        name="reciCurrency"
                        options={modeList ? modeList : []}
                        valueText="value"
                        labelText="label"
                        focus={!!formError?.reciModeOFpay}
                        error={formError?.reciModeOFpay}
                        selectedOption={formReciName?.reciModeOFpay}
                        // components={{ Menu: CustomControl }}
                        setSelectedOption={(data) => {
                          setFormReciName({
                            ...formReciName,
                            reciModeOFpay: data,
                          });
                          handleReciChange("reciModeOFpay", data?.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Deduct From" : "Deduct From"}
                    labelClass="FormLableKaran"
                    placeholder="Driver Name"
                    name="reciDescription"
                    type="text"
                    maxLength={12}
                    disabled="true"
                    // focus={!!formError?.reciDescription}
                    // error={formError.reciDescription}
                    value={driverId?.driver_name}
                    // onChange={({ target: { name, value } }) =>
                    //     handleReciChange(name, value)
                    // }
                  />
                </div>
              </div>
            </>
          }
        />
      )}
      {/* {isOpen2 && (
        <InputModal
          isOpen={isOpen2}
          headerName={isEdit ? "Edit Fuel" : "Add Fuel"}
          toggle={() => {
            clearAll();
            setIsOpen2(!isOpen2);
          }}
          submitLabel={"Add Payment"}
          onSubmit={(e) => onSubmitFuel()}
          // isLoadding={isLoadding}
          customeClassName="ModalRouteFlex"
          className="routemodalNew"
          inputProps={
            <div className="anvAdd_Fuel">
            <div className="">
              <div className="anvMaintab_container_route drFlex">
                <div>
                  <span>
                    <Label className="FormLableKaran">Order date</Label>
                  </span>
                  <div>
                    <CustomDatePicker
                      type="date"
                      value={formReciName?.reciptDate}
                      max={formatDate(new Date())}
                      focus={!!formError?.reciptDate}
                      error={formError?.reciptDate}
                      name="reciptDate"
                      placeholder={formReciName?.reciptDate}
                      onSelect={({ target: { name, value } }) => {
                        handleReciChange(name, value);
                        setFormReciName({ ...formReciName, reciptDate: value });
                      }}
                      className="date-input"
                      // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                      // className="date-input"
                    />
                  </div>
                </div>

                <div>
                  <span>
                    <Label className="FormLableKaran">fleet</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      placeholder="Select Fleet"
                      name="reciFleet"
                      options={fleetList ? fleetList : []}
                      valueText="id"
                      labelText="truck_number"
                      focus={!!formError?.reciFleet}
                      error={formError?.reciFleet}
                      selectedOption={formReciName?.reciFleet}
                      // components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormReciName({ ...formReciName, reciFleet: data });
                        handleReciChange("reciFleet", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span>
                    <Label className="FormLableKaran">Fuel Station</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      classNamePrefix="anvDrop_down"
                      placeholder="Select Fuel Station"
                      name="reciFuelStation"
                      options={fuelStationList ? fuelStationList : []}
                      valueText="id"
                      labelText="station_name"
                      focus={!!formError?.reciFuelStation}
                      error={formError?.reciFuelStation}
                      selectedOption={formReciName?.reciFuelStation}
                      // components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormReciName({
                          ...formReciName,
                          reciFuelStation: data,
                        });
                        handleReciChange("reciFuelStation", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span>
                    <Label className="FormLableKaran">Fuel Type</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      classNamePrefix="anvDrop_down"
                      placeholder="Select Fuel Type"
                      name="reciFuelType"
                      options={fuelTypeList ? fuelTypeList : []}
                      valueText="value"
                      labelText="label"
                      focus={!!formError?.reciFuelType}
                      error={formError?.reciFuelType}
                      selectedOption={formReciName?.reciFuelType}
                      // components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormReciName({
                          ...formReciName,
                          reciFuelType: data,
                        });
                        handleReciChange("reciFuelType", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span>
                    <Label className="FormLableKaran">Route</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      classNamePrefix="anvDrop_down"
                      placeholder="select Fuel Tyep"
                      name="reciRoute"
                      options={routeList ? routeList : []}
                      valueText="id"
                      labelText="route_name"
                      focus={!!formError?.reciRoute}
                      error={formError?.reciRoute}
                      selectedOption={formReciName?.reciRoute}
                      // components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormReciName({ ...formReciName, reciRoute: data });
                        handleReciChange("reciRoute", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <InputField
                    className="modal_input orderModal_Input input_radius"
                    label={isEdit ? "Description" : "Description"}
                    labelClass="FormLableKaran"
                    placeholder="Description"
                    name="reciDescription"
                    type="text"
                    maxLength={12}
                    focus={!!formError?.reciDescription}
                    error={formError.reciDescription}
                    value={formReciValue?.reciDescription}
                    onChange={({ target: { name, value } }) =>
                      handleReciChange(name, value)
                    }
                  />
                </div>
                <div className=" ">
                  <InputField
                    className="modal_input orderModal_Input input_radius"
                    boxClass=""
                    label={isEdit ? "LTR" : "LTR"}
                    labelClass="FormLableKaran"
                    placeholder="LTR"
                    name="reciLtr"
                    type="text"
                    maxLength={12}
                    focus={!!formError?.reciLtr}
                    error={formError.reciLtr}
                    value={formReciValue?.reciLtr}
                    onChange={({ target: { name, value } }) =>
                      handleReciChange(name, value)
                    }
                  />
                </div>
                <div className=" ">
                  <InputField
                    className="modal_input orderModal_Input input_radius"
                    label={isEdit ? "Rate" : "Rate"}
                    labelClass="FormLableKaran"
                    placeholder="Rate"
                    name="reciRate"
                    type="text"
                    maxLength={12}
                    focus={!!formError?.reciRate}
                    error={formError.reciRate}
                    value={formReciValue?.reciRate}
                    onChange={({ target: { name, value } }) =>{
                  
                      handleReciChange(name, value)}
                    }
                  />
                </div>

                <div className=" ">
                  <InputField
                    className="modal_input orderModal_Input input_radius"
                    label={isEdit ? "Amount" : "Amount"}
                    labelClass="FormLableKaran"
                    placeholder="Amount"
                    name="reciAmount"
                    type="text"
                    maxLength={12}
                    disabled="true"
                    focus={!!formError?.reciAmount}
                    error={formError.reciAmount}
                    value={formReciValue?.reciAmount}
                    onChange={({ target: { name, value } }) =>
                      handleReciChange(name, value)
                    }
                  />
                </div>
              </div>
            </div>
           </div>
           
          }
        />
      )} */}

      {isOpen2 && (
        <CustomOffcanvas
          customeClass="customeClassForfuel"
          isOpen={isOpen2}
          bodyClass="fullWidFx"
          isLoadding={isLoadding}
          title={isEdit ? "Edit Fuel" : "Add Fuel"}
          toggle={() => {
            clearAll();
            setIsOpen2(!isOpen2);
          }}
          submitLabel={"Add Fuel"}
          onSubmit={(e) => onSubmitFuel()}
          inputProps={
            <div className="anvAdd_Fuel">
              <div className="">
                <div className="anvMaintab_container_route drFlex">
                  <div>
                    <span>
                      <Label className="FormLableKaran">Order date</Label>
                    </span>
                    <div>
                      <CustomDatePicker
                        // type="datetime-local"
                        value={formReciName?.orderDate}
                        max={formatDate(new Date())}
                        focus={!!formError?.reciptDate}
                        error={formError?.reciptDate}
                        name="reciptDate"
                        disabled="true"
                        placeholder={formReciName?.orderDate}
                        onSelect={({ target: { name, value } }) => {
                          handleReciChange(name, value);
                          setFormReciName({
                            ...formReciName,
                            reciptDate: value,
                          });
                        }}
                        className="date-input"
                        // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                        // className="date-input"
                      />
                    </div>
                  </div>

                  <div>
                    <span>
                      <Label className="FormLableKaran">Fleet</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        placeholder="Select Fleet"
                        name="reciFleet"
                        options={fleetList ? fleetList : []}
                        valueText="id"
                        labelText="truck_number"
                        disabled="true"
                        focus={!!formError?.reciFleet}
                        error={formError?.reciFleet}
                        selectedOption={formReciName?.reciFleet}
                        // components={{ Menu: CustomControl }}
                        setSelectedOption={(data) => {
                          setFormReciName({ ...formReciName, reciFleet: data });
                          handleReciChange("reciFleet", data?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <span>
                      <Label className="FormLableKaran">Fuel Station</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        classNamePrefix="anvDrop_down"
                        placeholder="Select Fuel Station"
                        name="reciFuelStation"
                        options={fuelStationList ? fuelStationList : []}
                        valueText="id"
                        labelText="label"
                        focus={!!formError?.reciFuelStation}
                        error={formError?.reciFuelStation}
                        selectedOption={formReciName?.reciFuelStation}
                        // components={{ Menu: CustomControl }}
                        setSelectedOption={(data) => {
                          setFormReciName({
                            ...formReciName,
                            reciFuelStation: data,
                          });
                          handleReciChange("reciFuelStation", data?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <span>
                      <Label className="FormLableKaran">Fuel Type</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        classNamePrefix="anvDrop_down"
                        placeholder="Select Fuel Type"
                        name="reciFuelType"
                        options={fuelTypeList ? fuelTypeList : []}
                        valueText="value"
                        labelText="label"
                        disabled="true"
                        focus={!!formError?.reciFuelType}
                        error={formError?.reciFuelType}
                        selectedOption={formReciName?.reciFuelType}
                        // components={{ Menu: CustomControl }}
                        setSelectedOption={(data) => {
                          setFormReciName({
                            ...formReciName,
                            reciFuelType: data,
                          });
                          handleReciChange("reciFuelType", data?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <span>
                      <Label className="FormLableKaran">Route</Label>
                    </span>
                    <div>
                      <Dropdown
                        className="dropdownoforder"
                        classNamePrefix="anvDrop_down"
                        placeholder="Select Route"
                        name="reciRoute"
                        options={routeList ? routeList : []}
                        valueText="id"
                        labelText="route_name"
                        disabled="true"
                        focus={!!formError?.reciRoute}
                        error={formError?.reciRoute}
                        selectedOption={formReciName?.reciRoute}
                        // components={{ Menu: CustomControl }}
                        setSelectedOption={(data) => {
                          setFormReciName({ ...formReciName, reciRoute: data });
                          handleReciChange("reciRoute", data?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <InputField
                      className="modal_input orderModal_Input input_radius"
                      label={isEdit ? "Description" : "Description"}
                      labelClass="FormLableKaran"
                      placeholder="Description"
                      name="reciDescription"
                      type="text"
                      labelSpan="*"
                      labelSpanClass="style_for_star"
                      maxLength={12}
                      focus={!!formError?.reciDescription}
                      error={formError.reciDescription}
                      value={formReciValue?.reciDescription}
                      onChange={({ target: { name, value } }) =>
                        handleReciChange(name, value)
                      }
                    />
                  </div>
                  <div className=" ">
                    <InputField
                      className="modal_input orderModal_Input input_radius"
                      boxClass=""
                      label={isEdit ? "LTR" : "LTR"}
                      labelClass="FormLableKaran"
                      placeholder="LTR"
                      name="reciLtr"
                      type="text"
                      labelSpan="*"
                      labelSpanClass="style_for_star"
                      maxLength={12}
                      focus={!!formError?.reciLtr}
                      error={formError.reciLtr}
                      value={formReciValue?.reciLtr}
                      onChange={({ target: { name, value } }) =>
                        handleReciChange(name, value)
                      }
                    />
                  </div>
                  <div className=" ">
                    <InputField
                      className="modal_input orderModal_Input input_radius"
                      label={isEdit ? "Rate" : "Rate"}
                      labelClass="FormLableKaran"
                      placeholder="Rate"
                      name="reciRate"
                      type="text"
                      maxLength={12}
                      focus={!!formError?.reciRate}
                      error={formError.reciRate}
                      value={formReciValue?.reciRate}
                      onChange={({ target: { name, value } }) => {
                        handleReciChange(name, value);
                      }}
                    />
                  </div>

                  <div className=" ">
                    <InputField
                      className="modal_input orderModal_Input input_radius"
                      label={isEdit ? "Amount" : "Amount"}
                      labelClass="FormLableKaran"
                      placeholder="Amount"
                      name="reciAmount"
                      type="text"
                      maxLength={12}
                      disabled="true"
                      focus={!!formError?.reciAmount}
                      error={formError.reciAmount}
                      value={formReciValue?.reciAmount}
                      onChange={({ target: { name, value } }) =>
                        handleReciChange(name, value)
                      }
                    />
                  </div>
                  
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
