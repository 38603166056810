import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getExitBorder = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.exitBorder, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getForRouteExitBorder = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.exitBorder , data.status, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addExitBorderApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.exitBorder, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateExitBorderApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.exitBorder + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteExitBorderApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.exitBorder + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveExitBorder = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.exitBorder + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};