import React from 'react';
import { useMutation } from 'react-query';
import {
  getDriverDetails,
  getDriverTransaction,
  getDriverTripReport,
  getExpenseDetails,
  getFleetDetails,
  getFuelTransaction,
  getOrdersDetails,
  getTrailersDetails,
  getVehicleReport,
} from "../../services/reportService";

export const ReportDownload = () => {

  const downloadExcelMutation = useMutation(
    (reportType) => {
      switch (reportType) {
        case 'driverTransaction':
          return getDriverTransaction();
        case 'driverTripReport':
          return getDriverTripReport();
        case 'driverDetails':
          return getDriverDetails();
        case 'fuelTransaction':
          return getFuelTransaction();
        case 'ordersDetails':
          return getOrdersDetails();
        case 'expenseDetails':
          return getExpenseDetails();
        case 'trailersDetails':
          return getTrailersDetails();
        case 'fleetDetails':
          return getFleetDetails();
        default:
          throw new Error('Unsupported report type: ' + reportType);
      }
    },
    {
      onSuccess: (data, variables) => {
        if (data) {
          const url = window.URL.createObjectURL(
            new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${variables}.xlsx`); 
          link.click();
        }
      },
      onError: (error) => {
        console.error('Error downloading report:', error);
      },
    }
  );

  const handleDownload = async (reportType) => {
    try {
      downloadExcelMutation.mutate(reportType);
    } catch (error) {
      console.error('Error triggering download:', error);
    }
  };

  const reports = [
    { type: 'driverTransaction', name: 'Driver Transaction Report' },
    { type: 'driverTripReport', name: 'Driver Trip Report' },
    { type: 'driverDetails', name: 'Driver Details Report' },
    { type: 'fuelTransaction', name: 'Fuel Transaction Report' },
    { type: 'ordersDetails', name: 'Orders Details Report' },
    { type: 'expenseDetails', name: 'Expense Details Report' },
    { type: 'trailersDetails', name: 'Trailers Details Report' },
    { type: 'fleetDetails', name: 'Fleet Details Report' },
  ];


  return (
    <div className='rprtDownload_bx'>
      <table className="report-table">
        <thead className='table_head_style'>
          <tr className="report-table-header-row">
            <th className="report-table-header">Report Name</th>
            <th className="report-table-header">Download</th>
          </tr>
        </thead>
        <tbody>
          {reports.map(report => (
            <tr key={report.type} className="report-table-row">
              <td className="report-table-cell">{report.name}</td>
              <td className="report-table-cell">
                <button
                  className="addPlanReport"
                  onClick={() => handleDownload(report.type)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

