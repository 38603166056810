import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";

import { modalConpnayInput, modalInputSchema } from "../../helper/errorSchema";
import { breadcrumbsMenu, checkFormError, handleKeyDown, pageCount } from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import { addBranchApi, deleteBranchApi, getBranch, updateActiveBranch, updateBranchApi } from "../../services";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import CheckboxField from "../../Component/Common/CheckboxField";
import mytruck from "../../../image/tanker.png"
import { getStorage } from "../../helper/storage";
import { getAllFuelStationApi } from "../../services/fuelStationService";
import { deleteCountryApi, getCountry } from "../../services/countryService";
import { addCompanyApi, deleteCompanyApi, getCompanyList, updateActiveCompany, updateCompanyApi } from "../../services/companyService";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

const dummyDrop = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
  { label: "three", value: 3 },
];

export const Company = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id"
  };
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("")
  const [isLoadding, setIsLoadding] = useState(false)
  const [loaderForList, setLoaderForList] = useState(false)
  const queryClient = useQueryClient();
  const [countryList, setCountryList] = useState();

  const [filter, setFilter] = useState({
    limit: 20,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Branch"))[0]?.
    permissions : []


  const { data, isSuccess } = useQuery(
    ["getCompanyList", debouncedFilter],
    () => getCompanyList(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  useEffect(() => {
    getCountryList()
  }, [])


  useEffect(() => {
    if (data) {
      setLoaderForList(false)
    } else {
      setLoaderForList(true)
    }
  }, [data])
  const [inputValue, setInputValue] = useState({
    count: "",
    name: "",
    syntax_code: "",
    contact_number: "",
    address_one: "",
    address_two: "",
    city: "",
    email: "",
    is_active: true,
    country: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    syntax_code: "",
    contact_number: "",
    address_one: "",
    address_two: "",
    city: "",
    email: "",
    is_active: true,
    country: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    syntax_code: "",
    contact_number: "",
    address_one: "",
    address_two: "",
    city: "",
    email: "",
    is_active: true,
    country: "",
  });

  const closeModal = () => {
    setIsOpen(true);
    setIsEdit(false);
    setInputValue("");
    setInputError("");
  };

  const getCountryList = async () => {
    const res = await getCountry();
    setCountryList(res?.results);
  };
  const onSubmit = async () => {
    const validationResult = await checkFormError(
      {
        name: inputValue?.name,
        contact_number: inputValue.contact_number,
        address_one: inputValue.address_one,
        address_two: inputValue.address_two,
        city: inputValue.city,
        email: inputValue.email,
        country: inputValue.country,
      },
      modalConpnayInput
    );
    if (validationResult === true) {
      setIsLoadding(true)
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd();
      }
    } else {
      setInputError(validationResult);
      setIsLoadding(false)
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value }
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalConpnayInput);
      setInputError(error);
    }
  };

  const handleAdd = () => {
    const payload = {
      name: inputValue?.name,
      syntax_code: inputValue?.syntax_code,
      contact_number: inputValue.contact_number,
      address_one: inputValue.address_one,
      address_two: inputValue.address_two,
      city: inputValue.city,
      email: inputValue.email,
      is_active: true,
      country: inputValue.country,
    };


    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };
  const handleEditAction = (item) => {
    setDisableInp({ name: item.name });
    setInputValue({
      id: item.id,
      name: item.name,
      syntax_code: item?.syntax_code,
      address_one: item.address_one,
      address_two: item.address_two,
      city: item.city,
      contact_number: item.contact_number,
      email: item.email,
      country: item.country,
      is_active: inputValue?.is_active,

    });
    setIsOpen(true);
    setIsEdit(true);
    setInputError("");
  };

  const handleUpdate = () => {
    const payload = {
      id: inputValue.id,
      name: inputValue.name,
      syntax_code: inputValue?.syntax_code,
      contact_number: inputValue.contact_number,
      address_one: inputValue.address_one,
      address_two: inputValue.address_two,
      city: inputValue.city,
      email: inputValue.email,
      is_active: inputValue?.is_active,
      country: inputValue.country,
    };
    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addCompanyApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false)
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false)
      }
    },
  });

  const updateMutation = useMutation(updateCompanyApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false)
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false)
      }
    },
  });

  const deleteMutation = useMutation(deleteCompanyApi, {
    onSuccess: (res) => {

      if (res?.success) {
        onSuccessResponse(res);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data)
  }

  const onSuccessResponse = (message) => {
    toast.success(message?.message ? message?.message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getCompanyList");
    setIsOpen(false);
  };

  const onErrorResponse = (error) => {
    toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const updateTagStatus = (item) => {

    const payload = {
      id: item.id,
      is_active: !item?.is_active
    }
    updateActiveMutation.mutate(payload);
  }

  const updateActiveMutation = useMutation(updateActiveCompany, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const column = [
    { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },
    { heading: "Company  Name", className: "sorting_asc", value: "name", sortName: "" },
    { heading: "Syntex", className: "sorting_asc", value: "syntax_code", sortName: "" },
    { heading: "Action", className: "sorting", value: "action", sortName: "" },
  ];

  const rowDataList = () => {


    const listArray = data?.results?.map((item, i) => ({
      ...item,
      check: (
        <>
          <CheckboxField type="checkbox" name={item?.title} />
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
      action: (
        <>
          <div className="actionBtn">
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)} value={item?.is_active}
            />

            {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span> : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
            {userLogin?.user_type === 1 ? <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span> : permissions[4] && <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>}



            {/* {userLogin?.user_type === 1 ? <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} /> : permissions[3] && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
            {userLogin?.user_type === 1 ? <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> : permissions[4] && <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />} */}

          </div>
        </>
      ),
    }));
    return listArray;
  };

  const getSeletTechnology = (id, data) => {

    const details = data?.find((item) => item?.id === id);
    return {
      label: details?.name,
      value: details?.id,
    };
  };

  return (
    <>
      <div className="ques_container">
        <Breadcrumbs
          active="Company"
          action={
            userLogin?.user_type === 1 ? <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Company
            </Button> : permissions[2] && <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Country
            </Button>
          }
        />
        <div className="anvTask_prog">
          <div className="anvInputs&fillters">


            <div className="inr_flex_mini ">
              <Dropdown
                placeholder="Enable"
                option={dummyDrop}
                valueText="value"
                labelText="label"
                label="Enable"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
              />
            </div>

            <div className="inr_flex">
              <InputField placeholder="Search"
                label="Search"
                className="anvSearch_input"
                value={filter?.name}
                onChange={(e) =>
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    name: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div className="table-responsive">
            {/* <CustomTable
            
              data={rowDataList()}
            // tableLoading={tableLoading}
            /> */}
            <CustomTable
              column={column}
              data={rowDataList()}
              tableLoading={loaderForList}
            />
          </div>
        </div>
      </div>

      {data?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))
                setInputValue({ ...inputValue, count: data });
              }}

            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(data?.count / filter?.limit)}
          />
        </div>
      )}



      <InputModal
        isOpen={isOpen}
        size=""
        className="anvFleetType_modal"
        headerName={isEdit ? "Edit Company" : "Add Company"}
        toggle={() => setIsOpen(!isOpen)}
        submitLabel={isEdit ? "Update Company" : "Add Company"}
        onSubmit={(e) => onSubmit(e)}
        disabled={
          isEdit
            ? JSON.stringify(inputValue) === JSON.stringify(disableInp)
            : ""
        }
        isLoadding={isLoadding}
        inputProps={
          <>
            <div className="fleetGroup_input">
              <InputField
                className="modal_input"
                label={isEdit ? "Edit Company" : "Company"}
                labelClass="modal_label"
                //   labelSpan="*"
                //   labelSpanClass="style_for_star"
                placeholder="Enter Company"
                name="name"
                focus={!!inputError.name}
                error={inputError.name}
                value={inputValue.name}
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
              />
              <InputField
                className="modal_input"
                label={isEdit ? "Edit Syntex" : "Syntex"}
                labelClass="modal_label"
                //   labelSpan="*"
                //   labelSpanClass="style_for_star"
                maxLength={10}

                placeholder="Syntex Code"
                name="syntax_code"
                focus={!!inputError.syntax_code}
                error={inputError.syntax_code}
                value={inputValue.syntax_code}


                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);

                }}
              />



            </div>

            <InputField
              className="modal_input"
              label={isEdit ? "Edit Address Line 1" : "Address Line 1"}
              labelClass="modal_label"
              //   labelSpan="*"
              //   labelSpanClass="style_for_star"
              placeholder="Address "
              name="address_one"
              focus={!!inputError.address_one}
              error={inputError.address_one}
              value={inputValue.address_one}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Address Line 2" : "Address Line 2"}
              labelClass="modal_label"
              //   labelSpan="*"
              //   labelSpanClass="style_for_star"
              placeholder="Address "
              name="address_two"
              focus={!!inputError.address_two}
              error={inputError.address_two}
              value={inputValue.address_two}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />


            <div className="fleetGroup_input">

              <div className="anvOt_inputField country_with ">
                {/* <span>
                  <Label className="FormLableKaran">Country</Label>
                </span> */}
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Country"
                    label="Select Country"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="country"
                    options={countryList ? countryList : []}
                    valueText="id"
                    labelText="name"
                    // classNamePrefix="heightOfDropdown"
                    focus={!!inputError.country}
                    error={inputError.country}
                    // selectedOption={countryList.find((crt)=> crt.id === inputValue?.country)}
                    selectedOption={getSeletTechnology(
                      inputValue?.country,
                      countryList
                    )}
                    setSelectedOption={(data) => {
                      handleOnChange("country", data?.value);
                    }}
                  />
                </div>
              </div>

              <InputField
                className="modal_input"
                label={isEdit ? "Edit City" : " City"}
                labelClass="modal_label"
                //   labelSpan="*"
                //   labelSpanClass="style_for_star"
                placeholder="City"
                name="city"
                focus={!!inputError.city}
                error={inputError.city}
                value={inputValue.city}

                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);

                }}
              />

            </div>
            <div className="fleetGroup_input">

              <InputField
                className="modal_input"
                label={isEdit ? "Edit Contact Number" : "Contact Number"}
                labelClass="modal_label"
                //   labelSpan="*"
                //   labelSpanClass="style_for_star"
                maxLength={10}
                onKeyDown={handleKeyDown}
                placeholder="Contact Number"
                name="contact_number"
                focus={!!inputError.contact_number}
                error={inputError.contact_number}
                value={inputValue.contact_number}


                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);

                }}
              />

              <InputField
                className="modal_input"
                label={isEdit ? "Edit Email" : "Email"}
                labelClass="modal_label"
                //   labelSpan="*"
                //   labelSpanClass="style_for_star"
                placeholder="Email"
                name="email"
                focus={!!inputError.email}
                error={inputError.email}
                value={inputValue.email}

                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);

                }}
              />

            </div>


          </>
        }
      />
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
    </>
  );
};
