import { useCallback, useState } from "react";
import getCroppedImg from "./cropperfunction";
import Cropper from "react-easy-crop";

function ImageCropper(props) {
  const { setFormValue, image, setShowCropper, cropShape, aspect, formValue, imageType } = props;
  const [range, setRange] = useState(1);
  const imageLink = URL.createObjectURL(image);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setloading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {

    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleClose = () => {
    setShowCropper(false);
  };

  const showCroppedImage = async () => {
    setloading(true);
    try {
      const croppedImage = await getCroppedImg(imageLink, croppedAreaPixels, 0);
      setFormValue({ ...formValue, image: croppedImage })
      setShowCropper(false);
      if (imageType === "image") setFormValue({ ...formValue, image: croppedImage }); setShowCropper(false);
      if (imageType === "cover") setFormValue({ ...formValue, cover_image: croppedImage }); setShowCropper(false);
      setloading(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <div className="containe_image">
        <div className="containe_croper">
          <Cropper
            image={imageLink}
            crop={crop}
            cropShape={cropShape}
            zoom={range}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setRange}
            onInitialized={(instance) => {

              setFormValue({ instance });
            }}
          />
        </div>

        <div>
          <div>
            <label>Zoom</label>
            <input
              type="range"
              value={range}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setRange(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
          <div className="button_show">
            <button onClick={() => handleClose()}>
              CLOSE !
            </button>

            <button
              type="button"

              onClick={() => showCroppedImage()}
              disabled={loading}
            >
              <span className="px-3">Submit</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageCropper;
