import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";

import {
  fleetGroupValidation,
  modalInputSchema,
} from "../../helper/errorSchema";
import { breadcrumbsMenu, checkFormError, pageCount } from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import {
  addBranchApi,
  deleteBranchApi,
  getBranch,
  updateActiveBranch,
  updateBranchApi,
} from "../../services";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import CheckboxField from "../../Component/Common/CheckboxField";
import mytruck from "../../../image/tanker.png";
import { addFleetCode, deleteFleetGroupApi, getFleetGroup, updateFleetGroupApi } from "../../services/fleetTypeService";
import { getCountry } from "../../services/countryService";
import { getStorage } from "../../helper/storage";
import { getAllFuelStationApi } from "../../services/fuelStationService";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

const dummyDrop = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
  { label: "three", value: 3 },
];

export const FleetGroup = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  const objectCode = {
    station_api: "",
    code: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);
  const [loaderForList, setLoaderForList] = useState(false);
  const queryClient = useQueryClient();
  const [codeList, setCodeList] = useState([objectCode]);
  const [filter, setFilter] = useState({
    limit: 20,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Fleet Group"))[0]?.
    permissions : []

  const [countryList, setCountryList] = useState();

  const { data, isSuccess } = useQuery(
    ["getFleetGroup", debouncedFilter],
    () => getFleetGroup(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  useEffect(() => {
    getCountryList();
  }, []);
  useEffect(() => {
    if (data) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [data]);
  const [inputValue, setInputValue] = useState({
    name: "",
    mozambique_code: "",
    zambia_code: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    mozambique_code: "",
    zambia_code: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    mozambique_code: "",
    zambia_code: "",
  });

  const getCountryList = async () => {
    const res = await getAllFuelStationApi(countryObj);
    setCountryList(res?.results);
  };
  const closeModal = () => {
    setIsOpen(true);
    setIsEdit(false);
    setInputValue("");
    setInputError("");
    setCodeList([objectCode]);
  };

  const onSubmit = async () => {

    const validationResult = await checkFormError(
      { name: inputValue.name, codeList: codeList },
      fleetGroupValidation
    );


    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd();
      }
    } else {
      setInputError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, fleetGroupValidation);
      setInputError(error);
    }
  };

  const handleAdd = () => {
    const payload = {
      name: inputValue.name,
      fleet_code: codeList,
    };
    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };
  const handleEditAction = async (item) => {
    setDisableInp({ name: item.name });
    setInputValue({ id: item.id, name: item.name });
    const fleetgr = await item?.fleet_code.map((item) => {
      return {
        station_api: item?.station_api,
        code: item?.code
      }
    })
    setCodeList(fleetgr);
    setIsOpen(true);
    setIsEdit(true);
    setInputError("");
  };

  const handleUpdate = () => {
    const payload = {
      id: inputValue.id,
      name: inputValue.name,
      fleet_code: codeList,
    };
    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addFleetCode, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateFleetGroupApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const deleteMutation = useMutation(deleteFleetGroupApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const onSuccessResponse = (message) => {
    toast.success(message?.message ? message?.message : "success", {
      position: "bottom-left",
    });
    queryClient.refetchQueries("getFleetGroup");
    setIsOpen(false);
    setIsEdit(false);
    setInputValue("");
    setInputError("");
    setCodeList([objectCode]);
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const updateActiveMutation = useMutation(updateActiveBranch, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const addNew = (e) => {
    e.preventDefault();
    setCodeList((preState) => {
      return [...preState, objectCode];
    });
  };

  const updateCodeDetails = (index, data) => {
    const { key, value } = data;

    let newExpenseList = [...codeList];
    newExpenseList[index][key] = value;

    setCodeList(newExpenseList);
  };
  const handleDeleteCode = (index) => {
    let List = [...codeList];
    List.splice(index, 1);
    setCodeList(List);
  };

  const column = [
    { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },
    {
      heading: "Fleet Group",
      className: "sorting_asc",
      value: "name",
      sortName: "",
    },
    { heading: "Action", className: "sorting", value: "action", sortName: "" },
  ];

  const rowDataList = () => {
    const listArray = data?.results?.map((item, i) => ({
      ...item,
      check: (
        <>
          <CheckboxField type="checkbox" name={item?.title} />
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
      action: (
        <>
          <div className="actionBtn">
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)}
              value={item?.is_active}
            />

            {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span> : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
            {userLogin?.user_type === 1 ? <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span> : permissions[4] && <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>}

            {/* {userLogin?.user_type === 1 ? <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} /> : permissions[3] && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
            {userLogin?.user_type === 1 ? <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> : permissions[4] && <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />} */}
          </div>
        </>
      ),
    }));
    return listArray;
  };

  const getSeletTechnology = (id, data) => {
    console.log("data", data, id)
    const details = data?.find((item) => item?.id === id);
    return {
      label: details?.title,
      value: details?.id,
    };
  };

  return (
    <>
      <div className="ques_container">
        <Breadcrumbs
          active="Fleet Group"
          action={
            userLogin?.user_type === 1 ? <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Fleet Group
            </Button> : permissions[2] && <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Fleet Group
            </Button>
          }
        />
        <div className="anvTask_prog">
          <div className="anvInputs&fillters">
            <div className="inr_flex_mini ">
              <Dropdown
                placeholder="Enable"
                label="Enable"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
                option={dummyDrop}
                valueText="value"
                labelText="label"
              />
            </div>

            <div className="inr_flex">
              <InputField
                placeholder="Search"
                label="Search"
                className="anvSearch_input"
                value={filter?.name}
                onChange={(e) =>
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    name: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div className="table-responsive">
            {/* <CustomTable
            
              data={rowDataList()}
            // tableLoading={tableLoading}
            /> */}
            <CustomTable
              column={column}
              data={rowDataList()}
              tableLoading={loaderForList}
            />
          </div>
        </div>
      </div>

      {data?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))
                setInputValue({ ...inputValue, count: data });
              }}

            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(data?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen}
        size=""
        className="anvFleetType_modal"
        headerName={isEdit ? "Edit Fleet Group" : "Add Fleet Group"}
        toggle={() => setIsOpen(!isOpen)}
        submitLabel={isEdit ? "Update Fleet Group" : "Add Fleet Group"}
        onSubmit={(e) => onSubmit(e)}
        disabled={
          isEdit
            ? JSON.stringify(inputValue) === JSON.stringify(disableInp)
            : ""
        }
        isLoadding={isLoadding}
        inputProps={
          <>
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Fleet Group Name" : "Fleet Group Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Fleet Group Name"
              name="name"
              focus={!!inputError.name}
              error={inputError.name}
              value={inputValue.name}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />

            {codeList?.map((item, index) => (
              <div className="fleetGroup_input">

                <div className="anvOt_inputField ">
                  <span>
                    <Label className="FormLableKaran">API For</Label>
                  </span>
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      placeholder="API For"
                      name="station_api"
                      options={countryList ? countryList : []}
                      valueText="id"
                      labelText="title"
                      focus={
                        `${inputError[`codeList[${index}].country`]}` !==
                          "undefined"
                          ? !!`${inputError[`codeList[${index}].country`]}`
                          : ""
                      }
                      error={
                        `${inputError[`codeList[${index}].country`]}` !==
                          "undefined"
                          ? `${inputError[`codeList[${index}].country`]}`
                          : ""
                      }
                      // selectedOption={countryList.find((crt)=> crt.id === item?.country)}
                      selectedOption={getSeletTechnology(
                        item?.station_api,
                        countryList
                      )}
                      setSelectedOption={(data) => {

                        updateCodeDetails(index, {
                          key: "station_api",
                          value: data?.value,
                        });
                        handleOnChange("station_api", data?.value);
                      }}
                    />
                  </div>
                </div>

                <InputField
                  className="modal_input"
                  label={isEdit ? "Edit Code" : "Select Code"}
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Select Code"
                  name="code"
                  focus={
                    `${inputError[`codeList[${index}].code`]}` !== "undefined"
                      ? !!`${inputError[`codeList[${index}].code`]}`
                      : ""
                  }
                  error={
                    `${inputError[`codeList[${index}].code`]}` !== "undefined"
                      ? `${inputError[`codeList[${index}].code`]}`
                      : ""
                  }
                  value={item.code}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                    updateCodeDetails(index, { key: "code", value: value });
                  }}
                />
                <div className="deleteBoxDriver">
                  {codeList?.length !== 1 && (

                    <span className="_faDelete" onClick={(e) => handleDeleteCode(index)}><MdOutlineDelete /></span>
                    // <img
                    //   src={DeleteIcon}
                    //   alt=""
                    //   onClick={(e) => handleDeleteCode(index)}
                    // />
                  )}
                </div>
              </div>
            ))}
            <div className="fleetGroup_input">
              <button className="addMoreBtn" onClick={addNew}>
                + Add More
              </button>
            </div>
          </>
        }
      />
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
    </>
  );
};
