import React, { useEffect, useState } from "react";
import { InputField } from "../../Component/Common/InputField";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useDebounce from "../../helper/customhook";
import {
  financeApprovalOrderList,
  updateFinancialApproval,
} from "../../services/orderService";
import { formatDateList, pageCount } from "../../helper";
import { getAllRoute } from "../../services/routeService";
import { toast } from "react-toastify";
import { getResposeError } from "../../helper";
import CustomPagination from "../../Component/Common/CustomPagination";
import { getBranch } from "../../services";
import { Loader } from "../../Component/Common/Loader";
import CommonRoute from "../../Component/Common/CommonRoute";

const custmor = [
  { name: "branch 1", id: 1 },
  { name: "branch 2", id: 2 },
  { name: "branch 3", id: 3 },
  { name: "branch 4", id: 4 },
];

const column = [
  {
    heading: "Date of Creation",
    className: "sorting_asc",
    value: "date",
    sortName: "",
  },
  {
    heading: "Order Number",
    className: "sorting_asc",
    value: "order_number",
    sortName: "",
  },
  {
    heading: "Reference Number",
    className: "sorting_asc",
    value: "customer_reference_number",
    sortName: "",
  },
  {
    heading: "Customer Name",
    className: "sorting_asc",
    value: "customer_name",
    sortName: "",
  },
  {
    heading: "Route",
    className: "sorting_asc",
    value: "routeName",
    sortName: "",
  },
  {
    heading: "Product",
    className: "sorting_asc",
    value: "product",
    sortName: "",
  },
  {
    heading: "Order Qty",
    className: "sorting_asc",
    value: "qty",
    sortName: "",
  },
  {
    heading: "Add Rate Per Qty",
    className: "sorting_asc",
    value: "ratesPerQty",
    sortName: "",
  },
  {
    heading: "Total Order Value",
    className: "sorting_asc",
    value: "total_value",
    sortName: "",
  },
  {
    heading: "Action",
    className: "sorting_asc",
    value: "action",
    sortName: "",
  },
];

const obj = {
  offset: 0,
  currentPage: 0,
  name: "",
  ordering: "-id",
};

const formObj = {
  reference_number: "",
  customer_reference_number: "",
  order_number: "",
  order_type: "",
  customer: "",
  branch: "",
  pickup_date: "",
  drop_date: "",
  route: "",
  product: "",
  qty: "",
  allocated_qty: "",
  pending_qty: "",
  remark: "",
  status: "",
  is_active: true,
  exit_border: "",
  order_ref_number: "",
  ui_flow: "",
  finance_approval: "",
  rate: "",
  total_value: "",
};

const branchObj = {
  offset: 0,
  currentPage: 0,
  search: "",
  ordering: "-id",
  limit: "all",
};

export const FinancialApproval = () => {
  const [loaderForList, setLoaderForList] = useState(false);
  const [inputValue, setInputValue] = useState(formObj);
  const [branchList, setBranchList] = useState([]);
  const [branchFilter, setBranchFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const [isDisablePopUp, setDisablePop] = useState(false);
  const [filter, setFilter] = useState({
    ...obj,
    limit: 20,
    finance_approval: 0,
    search: "",
    order_number: "",
    branch_uuid: "",
  });
  const debouncedFilter = useDebounce(filter, 500);

  const { data: dataList } = useQuery(
    ["financialOrderList", debouncedFilter],
    () => financeApprovalOrderList(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    getBranchList();
  }, []);

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };

  const handleInputChange = (e, id, field, qty) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");

    if (field === "rate") {
      const totalValue = numericValue * qty;

      setInputValue((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          rate: numericValue,
          total_value: totalValue,
        },
      }));
    }
  };

  const handleApprove = (item) => {
    setIsLoading(item?.id);
    const rowData = inputValue[item.id];
    const payload = {
      status: 3,
      rate: rowData?.rate,
      id: item?.id,
    };
    if (rowData !== undefined && rowData?.rate !== "") {
      updateMutation.mutate(payload);
    }
  };

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
      setIsLoading(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      serial: i + 1,
      product: <div>{item?.product_detail?.name}</div>,
      routeName: (
        <div
          className="cursorPointer"
          onClick={() => setDisablePop(item?.route)}
        >
          {item?.route_name}
        </div>
      ),
      date: formatDateList(item?.added_on),
      ratesPerQty: (
        <div className="d-flex align-items-center">
          <span className="llt_rates_span">$</span>
          <InputField
            className="llt_ratesInp"
            name={`rate-${item.id}`}
            value={inputValue[item.id]?.rate || ""}
            onChange={(e) =>
              handleInputChange(e, item.id, "rate", Math.floor(item.qty))
            }
            type="text"
            pattern="[0-9]*"
          />
        </div>
      ),
      total_value: (
        <InputField
          className="llt_totalValInp"
          name={`total_value-${item.id}`}
          value={
            inputValue[item.id]?.total_value === undefined
              ? "$0"
              : `$${inputValue[item.id]?.total_value}`
          }
          disabled
        />
      ),
      action: (
        <button
          className="llt_apprOrdBtn"
          onClick={() => handleApprove(item)}
          disabled={!inputValue[item.id]?.rate}
        >
          {isLoading === item?.id ? (
            <Loader loaderClass="llt_approLoad" />
          ) : (
            "Approve Order"
          )}
        </button>
      ),
    }));
    return listArray;
  };

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", {
      position: "bottom-left",
    });
    queryClient.refetchQueries("financialOrderList");
    setIsLoading(false);
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoading(false);
  };

  const updateMutation = useMutation(updateFinancialApproval, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handleResetFunc = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: "",
      order_number: "",
      branch_uuid: "",
    }));
    setBranchFilter(null);
  };

  return (
    <>
      <div className="llt_financial_heading">
        <h6>Finance Approval</h6>
      </div>

      <div className="anvTask_prog pb-3">
        <div className="anvInputs&fillters">
          <div className="inr_flex_mini">
            {/* <Dropdown
              placeholder="Customer"
              className="dropdownoforder orderModal_Input"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              label="Customer"
              name="driver"
              options={custmor ? custmor : []}
              valueText="id"
              labelText="name"
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              //   selectedOption={formOrderName?.branchFilter}
              //   setSelectedOption={(data) => {
              //     // handleOrderChange("driver", data.value);
              //     setFilter({ ...filter, branch_uuid: data.value });
              //     setFormOrderName({ ...formOrderName, branchFilter: data });
              //   }}
            /> */}
            <InputField
              placeholder="Customer Name"
              label="Customer Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  search: e.target.value,
                }))
              }
              value={filter?.search}
            />
          </div>

          <div className="inr_flex_mini">
            <Dropdown
              placeholder="Select Branch"
              label="Select Branch"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={branchList ? branchList : []}
              valueText="id"
              labelText="name"
              // focus={!!formError?.driver}
              // error={formError.driver}
              selectedOption={branchFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, branch_uuid: data.value });
                setBranchFilter(data);
              }}
            />{" "}
          </div>

          <div className="inr_flex_mini">
            <InputField
              placeholder="Search Order"
              className="anvSearch_input"
              label="Search Order"
              value={filter?.order_number}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  order_number: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini">
            {(filter?.search ||
              filter?.order_number ||
              filter?.branch_uuid) && (
              <button
                className="anime-btn reset_button"
                onClick={() => handleResetFunc()}
              >
                Reset
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="table table_hieght">
        <div className="table-responsive">
          <CustomTable
            column={column}
            data={rowDataList()}
            tableLoading={loaderForList}
            tableClass="lltTable_financial"
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                inputValue?.count
                  ? inputValue?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));
                setInputValue({ ...inputValue, count: data });
              }}
            />
          </div>
          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      {isDisablePopUp && (
        <CommonRoute
          isOpen={isDisablePopUp}
          setIsOpen={setDisablePop}
          isDisableOpen={true}
        />
      )}
    </>
  );
};
