export const methodType = {
  PUT: "put",
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PATCH: "patch",
};

export const apiEndPoints = {
  userLogin: "auth/login",
  dashData: "dashboard/dashboard-statistics",
  coreCategory: "core/category",
  authUserProfile: "auth/user-profile",
  aiTools: "tool/ai-tool",
  community: "social/community",
  research: "core/research",
  news: "core/news",
  post: "social/post",
  category: "core/category",
  country: "core/country",
  persona: "core/persona",
  industry: "core/industry",
  branch: "core/branch",
  documentType: "core/document-type",
  product: "fleet/product",
  fleetType: "core/fleet-type",
  customer: "auth/customer",
  fleet: "/fleet/fleet",
  tariler: "/fleet/trailer",
  crossBorder: "/fleet/cross-border-permit",
  order: "/order/order",
  driver: "/auth/driver",
  location: "/core/location",
  currency: "/core/currency",
  unit: "/core/unit",
  exitBorder: "/core/exit-border",
  fuelStation: "/core/fuel-station",
  expenseCateory: "/core/expense-category",
  unasign: "/fleet/fleet/unassigned_trailers",
  routes: "/fleet/route",
  trip: "/order/trip",
  driverTrans: "wallet/driver-transaction",
  driverWallet: "wallet/driver-wallet",
  user: "/auth/user",
  permission: "auth/permission",
  roles: "auth/role",
  tripReport:"order/trip_report",
  fleetGroup:"/core/fleet-group",
  fuelExpenseTrip:"/order/trip-fuel-expense",
  ApproveFuel:"/order/create-fuel-order",
  completeOrder:"/order/completed-fuel-order",
  gpsTrack:"fleet/gps-locations",
  triplog:"/order/trip-log",
  fuelStationApi:"/core/fuel-station-api",
  verifyOtp:"/order/order/verify_order_otp",
  orderCount:"/order/order/status_counts",
  tripCount:"/order/trip/status_counts",
  company:"core/company",
  fundAllocationState:"order/trip/status_fund_allocation",
  fuelTransactionReport:"fleet/fuel-transactions",
  vehicleReport:"fleet/vehicle-report",
  trailersDetailsReport: "fleet/trailers-details",
  ordersDetailsReport: "order/orders-details",
  expenseDetailsReport: "auth/expense-details",
  driverTripReport: "auth/driver-trip-report",
  driverTransactionReport: "auth/driver-transactions",
  driverDetailsReport: "auth/driver-details",
  fleetDetailsReport: "fleet/fleet-details",
  documentTypeRelatedToBranch: "core/document-type?branch__uuid=",
  financeApproval:"/order/finance-approval",
  etaFleetOrder:"order/fleet-eta",
  branchAlertGroup:"core/branch-alert-group",
  branchAlertLevel:"core/branch-alert-level",
  subStatusChange:"/order/trip-sub-status",
  markBorderStatus:"/order/trip-location-details",
};
