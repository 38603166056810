import { apiEndPoints, methodType, performRequest } from "./_apiConfig";




export const getAllTrailer = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.tariler, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllTrailerWithType = (data) => {
    return performRequest(methodType.GET, apiEndPoints.unasign + `?trailer_type=${data.type}`, data?.data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addTrailerApi = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.tariler, data)
        .then((response) => {

            return response;
        })
        .catch((error) => {

            return error;
        });
};

export const updateTrailer = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.tariler + `/${data?.id}`,
        data.formdata,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};



export const singleTrailerDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.tariler + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const deleteTarilerApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.tariler + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveTrailer = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.tariler + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const uploadExcelTrailer = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.tariler + `/upload_trailers`, data, true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};