import { apiEndPoints, methodType, performRequest } from "./_apiConfig";

export const getAllLocation = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.location, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
export const getAllDrop = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.location + `?location_type=${data?.type}`, data.status, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
export const getAllPickup = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.location + `?location_type=${data?.type}`, data.status, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
export const addLocation = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.location, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteLocation = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.location + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateLocation = (data = {}) => {

    return performRequest(
        methodType.PUT,
        apiEndPoints.location + `/${data?.id}`,
        data?.formdata,
        true,
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateActiveLocation = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.location + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleLocationDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.location + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const getOrderCountApi = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.orderCount, data, true)
        .then((response) => {
            
            return response;
        })
        .catch((error) => {
            return error;
        });
};
