import React, { useEffect, useState } from "react";
import CheckboxField from "../../../Component/Common/CheckboxField";
import Dropdown from "../../../Component/Common/Dropdown";
import { CustomDatePicker } from "../../../Component/Common/ConstomModal/CustomDatePicker";
import { branchTabList, checkFormError, hourTimeList } from "../../../helper";
import { useLocation } from "react-router-dom";
import {
  addHierarchyDetails,
  deleteHierarchyLevel,
  getHierarchyDetailsList,
  updateBranchApi,
  updateHierarchyDetails,
} from "../../../services";
import { Tabs } from "../../../Component/Common/Tabs";
import { verifyTimeBranchSchema } from "../../../helper/errorSchema";
import { getAllUser } from "../../../services/userService";
import { toast } from "react-toastify";

const timeObj = {
  alert_start_time: "",
  alert_end_time: "",
};

const tripDataList = [
  { branch: "", trip_status: -1 },
  { branch: "", trip_status: 1 },
  { branch: "", trip_status: 2 },
  { branch: "", trip_status: 3 },
  { branch: "", trip_status: 4 },
  { branch: "", trip_status: 5 },
  { branch: "", trip_status: 6 },
  { branch: "", trip_status: 7 },
  { branch: "", trip_status: 8 },
  { branch: "", trip_status: 9 },
  { branch: "", trip_status: 10 },
  { branch: "", trip_status: 11 },
];

export const BranchDetails = () => {
  const location = useLocation();
  const dataFromBranch = location?.state;
  const [timeInputVal, setTimeInputVal] = useState(timeObj);
  const [timeInputErr, setTimeInputErr] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filter, setFilter] = useState({
    status: 2,
  });
  const [isTimeFilled, setTimeFilled] = useState(false);

  // useEffect(() => {
  //   callUserList();
  //   callHierarchyDetails();
  // }, []);

  useEffect(() => {
    if (dataFromBranch?.alert_end_time && dataFromBranch?.alert_start_time) {
      setTimeInputVal({
        alert_end_time: dataFromBranch?.alert_end_time,
        alert_start_time: dataFromBranch?.alert_start_time,
      });
      setTimeFilled(true);
      callUserList();
      callHierarchyDetails();
    }
  }, [dataFromBranch]);

  const handleActive = (data) => {
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const callUserList = async () => {
    const payload = {
      status: 1,
      limit: 200,
      user_types:4,
    };
    const response = await getAllUser(payload);
    if (response?.success === true && response?.status === 200) {
      setUserList(response?.results);
    }
  };

  const callHierarchyDetails = async () => {
    const payload = {
      branch_uuid: dataFromBranch?.id,
    };
    const response = await getHierarchyDetailsList(payload);

    if (response?.success === true && response?.status === 200) {
      const apiData = response.results;

      // Map through the tripDataList and update the formState based on API response
      const updatedFormState = tripDataList.map((tripItem) => {
        const matchingApiItem = apiData.find(
          (apiItem) => apiItem.trip_status === tripItem.trip_status
        );

        // If there's a match, use API data, otherwise use default values
        return matchingApiItem
          ? {
              id: matchingApiItem?.id,
              branch: matchingApiItem.branch,
              trip_status: matchingApiItem.trip_status,
              alert_levels: matchingApiItem.alert_levels.map((level) => ({
                uuid: level?.uuid,
                id: level?.id,
                user: level.user,
                first_alert_duration: parseInt(level.first_alert_duration),
                whatsapp_enabled: level.whatsapp_enabled,
                email_enabled: level.email_enabled,
                level: level.level,
              })),
            }
          : {
              branch: dataFromBranch?.id,
              trip_status: tripItem.trip_status,
              alert_levels: [
                {
                  user: "",
                  first_alert_duration: "",
                  whatsapp_enabled: false,
                  email_enabled: false,
                  level: 1,
                },
              ],
            };
      });

      setFormState(updatedFormState);
      setOriginalFormState(JSON.parse(JSON.stringify(updatedFormState)));
    }
  };

  const handleTimeInput = async (event, fieldName) => {
    const timeValue = event.target.value;
    setTimeInputVal((prev) => ({
      ...prev,
      [fieldName]: timeValue,
    }));
    if (!!timeInputErr) {
      const error = await checkFormError(timeInputVal, verifyTimeBranchSchema);
      setTimeInputErr(error);
    }
  };

  const submitTime = async () => {
    const validationResult = await checkFormError(
      timeInputVal,
      verifyTimeBranchSchema
    );
    if (validationResult === true) {
      const payload = {
        ...timeInputVal,
        id: dataFromBranch?.id,
      };
      const response = await updateBranchApi(payload);
      if (response?.status === 200 && response?.success === true) {
        toast?.success(response?.message);
        setTimeFilled(true);
        callUserList();
        callHierarchyDetails();
      }
    } else {
      setTimeInputErr(validationResult);
    }
  };

  const statusLabels = {
    "-1": "Financial Approval",
    1: "Client Approval",
    2: "Client Approved",
    3: "Loading Point",
    4: "FM Approval",
    5: "Fund Allocation",
    6: "In Transit",
    7: "Off Loading Point",
    8: "Delivered",
    9: "In Return",
    10: "Completed",
    11: "Cancelled",
  };

  const getStatusLabel = (status) => statusLabels[status] || "Unknown Status";

  const [formState, setFormState] = useState(
    tripDataList.map((item) => ({
      branch: dataFromBranch?.id,
      trip_status: item.trip_status,
      alert_levels: [
        {
          user: "",
          first_alert_duration: "",
          whatsapp_enabled: false,
          email_enabled: false,
          level: 1,
        },
      ],
    }))
  );
  const [originalFormState, setOriginalFormState] = useState([]);

  const updateAlertLevel = (index, levelIndex, field, value) => {
    setFormState((prevFormState) => {
      const updatedFormState = [...prevFormState];
      updatedFormState[index].alert_levels[levelIndex][field] = value;
      return updatedFormState;
    });
  };

  const handleUserChange = (index, levelIndex, selectedUser) => {
    updateAlertLevel(index, levelIndex, "user", selectedUser?.value || "");
  };

  const handleAlertDurationChange = (index, levelIndex, selectedDuration) => {
    updateAlertLevel(
      index,
      levelIndex,
      "first_alert_duration",
      selectedDuration?.value || ""
    );
  };

  const handlePlatformChange = (index, levelIndex, platform, value) => {
    updateAlertLevel(index, levelIndex, platform, value);
  };

  const handleAddLevel = (index) => {
    const updatedFormState = [...formState];
    updatedFormState[index].alert_levels.push({
      user: "",
      first_alert_duration: "",
      whatsapp_enabled: false,
      email_enabled: false,
      level: updatedFormState[index].alert_levels.length + 1,
    });
    setFormState(updatedFormState);
  };

  const handleDeleteLevel = async (formIndex, levelIndex, delId) => {
    if (delId) {
      const payload = {
        uuid: delId,
      };

      try {
        const response = await deleteHierarchyLevel(payload);
        if (response?.status === 200 && response?.success === true) {
          toast?.success(response?.message);
          callHierarchyDetails();
        }
      } catch (error) {
        console.error("Error deleting level:", error);
        return;
      }
    }
    const updatedFormState = [...formState];

    updatedFormState[formIndex].alert_levels = updatedFormState[
      formIndex
    ].alert_levels.filter((_, i) => i !== levelIndex);

    setFormState(updatedFormState);
  };

  const handleSave = async (index) => {
    const itemToSave = formState[index];
    if (itemToSave?.id) {
      handleUpdateTrip(itemToSave);
    } else {
      handleAddTrip(itemToSave);
    }
  };

  const handleAddTrip = async (payload) => {
    const response = await addHierarchyDetails(payload);
    if (response?.success === true) {
      if (response?.status === 201 && response?.success === true) {
        toast?.success(response?.message);
        callHierarchyDetails();
      }
    }
  };

  const handleUpdateTrip = async (payload) => {
    const response = await updateHierarchyDetails(payload);
    if (response?.success === true) {
      if (response?.status === 200 && response?.success === true) {
        toast?.success(response?.message);
        callHierarchyDetails();
      }
    }
  };

  const getUserOption = (userId) => {
    const user = userList.find((u) => u.id === userId);
    return user ? { label: user.name, value: user.id } : null;
  };

  const getFirstAlertOption = (durationValue) => {
    const duration = hourTimeList.find((d) => d.value === durationValue);
    return duration ? { label: duration.label, value: duration.value } : null;
  };

  const isSaveDisabledForAdd = (item) => {
    const isAnyLevelIncomplete = item.alert_levels.some((level) => {
      return !level.user || !level.first_alert_duration;
    });

    return isAnyLevelIncomplete;
  };

  const isSaveDisabledForUpdate = (item, index) => {
    const originalItem = originalFormState[index];
  
    if (!originalItem) {
      return true;
    }
  
    const isAnyLevelIncomplete = item.alert_levels.some((level) => {
      return level.user == null || level.first_alert_duration == null;
    });
    
    const isNoChange =
      item.alert_levels.length === originalItem.alert_levels.length &&
      item.alert_levels.every((level, idx) => {
        const originalLevel = originalItem.alert_levels[idx];
        if (!originalLevel) return false;
    
        const isUserSame = level.user === originalLevel.user;
        const isDurationSame =
          Number(level.first_alert_duration) ===
          Number(originalLevel.first_alert_duration);
    
        return isUserSame && isDurationSame;
      });
    
    return isAnyLevelIncomplete || (item?.id && isNoChange);
  };
  
  return (
    <>
      <div className="__headTp_txt">
        <div className="d-flex gap-2 align-items-center">
          <h4>Branch</h4>
          <span>{`( ${dataFromBranch?.name} )`}</span>
        </div>
      </div>

      <div className="__nwTab_otrBx">
        <Tabs
          TabList={branchTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
      </div>
      <>
        <div className="__hrcyBxs">
          <div className="_alert_txtBxs">
            <p>Alert/ Working Time</p>

            <div className="d-flex gap-3 align-items-start">
              <div className="_cst-flex-[185px]">
                <CustomDatePicker
                  type="time"
                  label="Start Time"
                  value={timeInputVal?.alert_start_time}
                  onSelect={(e) => handleTimeInput(e, "alert_start_time")}
                  className="date-input"
                  focus={!!timeInputErr?.alert_start_time}
                  error={timeInputErr?.alert_start_time}
                />
              </div>
              <div className="_cst-flex-[185px]">
                <CustomDatePicker
                  type="time"
                  label="End Time"
                  value={timeInputVal?.alert_end_time}
                  onSelect={(e) => handleTimeInput(e, "alert_end_time")}
                  className="date-input"
                  focus={!!timeInputErr?.alert_end_time}
                  error={timeInputErr?.alert_end_time}
                />
              </div>

              {timeInputVal?.alert_end_time &&
                timeInputVal?.alert_start_time && (
                  <button
                    style={{ flex: "0 0 150px", minHeight: "42px" }}
                    className="btn anime-btn submitSv_btn"
                    onClick={() => submitTime()}
                  >
                    Submit
                  </button>
                )}
            </div>
          </div>

          {isTimeFilled && isTimeFilled === true ? (
            <div className="__tbDiv_fx">
              <div className="tbDiv__fx__box">
                {formState?.map((item, index) => (
                  <div className="__trx_appEnd_bx" key={index}>
                    <p>{getStatusLabel(item.trip_status)}</p>
                    <table>
                      <thead>
                        <tr>
                          <th>Levels</th>
                          <th>Select User</th>
                          <th>First Alert</th>
                          <th>Platform</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.alert_levels.map((level, levelIndex) => (
                          <tr key={level.id}>
                            <td>
                              <div>
                                {level?.level === 1
                                  ? `${level.level}st Level`
                                  : level?.level === 2
                                  ? `${level.level}nd Level`
                                  : level?.level === 3
                                  ? `${level.level}rd Level`
                                  : `${level.level}th Level`}
                              </div>
                            </td>
                            <td>
                              <Dropdown
                                className="dropdownoforder"
                                classNamePrefix="_anvFloatSelect"
                                boxClass="_anvFloatedSelect"
                                placeholder="Select User"
                                label="Select User"
                                options={userList}
                                valueText="id"
                                labelText="name"
                                selectedOption={getUserOption(level.user)}
                                setSelectedOption={(selectedUser) => {
                                  handleUserChange(
                                    index,
                                    levelIndex,
                                    selectedUser
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <Dropdown
                                className="dropdownoforder"
                                classNamePrefix="_anvFloatSelect"
                                boxClass="_anvFloatedSelect"
                                placeholder="Select Alert Duration"
                                label="First Alert"
                                options={hourTimeList}
                                valueText="value"
                                labelText="label"
                                selectedOption={getFirstAlertOption(
                                  level.first_alert_duration
                                )}
                                setSelectedOption={(selectedDuration) => {
                                  handleAlertDurationChange(
                                    index,
                                    levelIndex,
                                    selectedDuration
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <div className="d-flex gap-3">
                                <CheckboxField
                                  type="checkbox"
                                  label="Whatsapp"
                                  checked={level.whatsapp_enabled}
                                  onChange={(e) =>
                                    handlePlatformChange(
                                      index,
                                      levelIndex,
                                      "whatsapp_enabled",
                                      e.target.checked
                                    )
                                  }
                                />
                                <CheckboxField
                                  type="checkbox"
                                  label="Email"
                                  checked={level.email_enabled}
                                  onChange={(e) =>
                                    handlePlatformChange(
                                      index,
                                      levelIndex,
                                      "email_enabled",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {item.alert_levels &&
                                item.alert_levels?.length > 1 && (
                                  <span
                                    className="hier_delet_txt"
                                    onClick={() =>
                                      handleDeleteLevel(
                                        index,
                                        levelIndex,
                                        level?.uuid
                                      )
                                    }
                                  >
                                    Delete
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="llt_btnhir_flex_bx">
                      <button
                        className="__lvlAdd"
                        onClick={() => handleAddLevel(index)}
                      >
                        + Add Level
                      </button>

                      {/* <button
                        type="button"
                        className=" llt_anime-btn "
                        onClick={() => handleSave(index)}
                      >
                        {item?.id ? "Update" : "Save"}
                      </button> */}

                      {item?.id ? (
                        <button
                          type="button"
                          className="llt_anime-btn"
                          onClick={() => handleSave(index)}
                          disabled={isSaveDisabledForUpdate(item, index)} // Passing index only
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="llt_anime-btn"
                          onClick={() => handleSave(index)}
                          disabled={isSaveDisabledForAdd(item)} // Using the add function
                        >
                          Save
                        </button>
                      )}

                      {/* <button
                        type="button"
                        className="llt_anime-btn"
                        onClick={() => handleSave(index)}
                        disabled={isSaveDisabled(
                          item,
                          originalFormState[index]
                        )}
                      >
                        {item?.id ? "Update" : "Save"}
                      </button> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="hier_displayAlertMessage">
              <span>Please select the start time and end time first</span>
            </div>
          )}
        </div>

        {/* <div className="_frmFooter_bx">
          <button
            type="button"
            className="btn anime-btn submitSv_btn"
            // onClick={handleSubmit}
          >
            Save
          </button>

          <button type="button" className="btn anime-btn cancelSv_btn">
            Cancel
          </button>
        </div> */}
      </>
    </>
  );
};
