import { apiEndPoints, methodType, performRequest } from "./_apiConfig";

export const getAllUser = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.user, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addUser = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.user, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteUser = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.user + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateUser = (data = {}) => {

    return performRequest(
        methodType.PATCH,
        apiEndPoints.user + `/${data?.id}`,
        data?.formdata,
        true,
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateActiveUser = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.user + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleUserDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.user + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}


export const getPermission = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.permission, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getRoles = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.roles, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addRolesApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.roles, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateRolesApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.roles + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteRolesApi = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.roles + `/${data?.uuid}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}