import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { modalInputSchema } from "../../helper/errorSchema";
import { checkFormError, pageCount, roleTabList, userTabList } from "../../helper";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import CustomTable from "../../Component/Common/CustomTable";
import CheckboxField from "../../Component/Common/CheckboxField";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { addRolesApi, deleteRolesApi, getPermission, getRoles, updateRolesApi } from "../../services/userService";
import { Tabs } from "../../Component/Common/Tabs";
import { findAllByTestId } from "@testing-library/react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

const dummyDrop = [
    { label: "one", value: 1 },
    { label: "two", value: 2 },
    { label: "three", value: 3 },
];

const permissionAction = {
    1: { name: "View", label: 1 },
    2: { name: "Create", label: 2 },
    3: { name: "Edit", label: 3 },
    4: { name: "Delete", label: 4 },
};

export const Role = () => {
    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "id",
    };
    const [isOpen, setIsOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const queryClient = useQueryClient();
    const [loaderForList, setLoaderForList] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [isLoadding, setIsLoadding] = useState(false)

    const [filter, setFilter] = useState({
        limit: 20,
        ...obj,
    });
    const debouncedFilter = useDebounce(filter, 500);

    const { data: dataList, isSuccess } = useQuery(
        ["rolesList", debouncedFilter],
        () => getRoles(debouncedFilter, filter),
        { enabled: Boolean(debouncedFilter) }
    );

    const handleActive = (data) => {
        setLoaderForList(true)
        setFilter({
            ...filter,
            status: data.value
        })
    }

    const { data: permissionList } = useQuery(
        ["getPermission", debouncedFilter],
        () => getPermission(debouncedFilter, filter),
        { enabled: Boolean(debouncedFilter) }
    );

    const [inputValue, setInputValue] = useState({
        name: "",
        module_name: "",
        permissionList: [],
        is_dashboard_access: false,
        count: ""
    });


    const [inputError, setInputError] = useState({
        name: "",
    });

    const [disableInp, setDisableInp] = useState({
        name: "",
    });

    useEffect(() => {
        if (!Boolean(inputValue?.uuid)) {
            setInputValue((s) => ({
                ...s,
                permissionList: permissionList?.results
                    ? permissionList?.results.map((item) => {

                        const permissions = Object.entries(item?.permissions).map(
                            (i) => ({
                                ...permissionAction[i[0]],
                                value: i[1],
                            })
                        );
                        return {
                            ...item, permissions
                        };
                    })
                    : [],
            }));
        }
    }, [isOpen]);

    const column = [
        { heading: "Role Name", className: "sorting_asc", value: "name", sortName: "" },
        { heading: "Users", className: "sorting_asc", value: "user_count", sortName: "" },
        { heading: "Action", className: "sorting_asc", value: "action", sortName: "" },
    ];
    const rowDataList = () => {
        const listArray = dataList?.results?.map((item, i) => ({
            ...item,
            check: (
                <>
                    <CheckboxField type="checkbox" name={item?.title} />
                </>
            ),
            action: (
                <>
                    <div className="actionBtn">

                        {/* <div className="customerStatus"><SwitchField
                            // onSwitchChange={() => updateTagStatus(item)} 
                            value={item?.is_active} />{item?.is_active ? <span>Disable</span> : <span>Enable</span>}</div> */}

                        <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>
                        <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>

                        {/* <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
                        <img src={DeleteIcon} alt=""
                            onClick={() => hendleDeleteTag(item, true)}
                        /> */}
                    </div>

                    {/* <div className="actionBtn">

                        <SwitchField
                            // onClick={() => updateTagStatus(item)}
                            value={item?.is_active}
                        />
                        <span className="pointer" onClick={() => handleEditAction(item)}>
                            <img src={EditIcon} alt="" />
                        </span>
                        <span className="pointer" onClick={() => setOpenDel(item)}>
                            <img src={DeleteIcon} alt="" />
                        </span>
                    </div> */}
                </>
            ),
        }));
        return listArray;
    };

    const closeModal = () => {
        setIsOpen(true);
        setInputValue({
            ...inputValue,
            name: "",
            module_name: "",
            uuid: "",
            is_dashboard_access: false,
            permissionList: [],
        });
        setInputError("");
    };

    useEffect(() => {
        if (dataList) {
            setLoaderForList(false)
        } else {
            setLoaderForList(true)
        }
    }, [dataList])

    const hendleDeleteTag = (data, flag) => {
        setOpenDel(flag);
        setDeleteId(data)
    }

    const onSubmit = async () => {
        const demoItemObj = {};
        inputValue?.permissionList.map((item, ind) => {
            const permissions = {};
            item?.permissions.map((per) => {
                permissions[per?.label] = per.value;
            });
            demoItemObj[ind + 1] = {
                module_name: item?.module_name,
                permissions,
            };
        });

        const validationResult = await checkFormError(
            { name: inputValue.name },
            modalInputSchema
        );
        if (inputValue?.uuid) {
            setIsLoadding(true)
            handleUpdate(demoItemObj);
        } else {

            if (validationResult === true) {
                setIsLoadding(true)
                handleAdd(demoItemObj);
            } else {
                setInputError(validationResult);
            }
        }
    };

    const handleOnChange = async (name, value) => {
        const stateObj = { ...inputValue, [name]: value };
        setInputValue(stateObj);
        if (!!inputError) {
            const error = await checkFormError(stateObj, modalInputSchema);
            setInputError(error);
        }
    };

    const handleAdd = (objPayload) => {
        const payload = {
            name: inputValue.name,
            is_dashboard_access: inputValue?.is_dashboard_access,
            updated_permissions: objPayload,
        };
        addMutation.mutate(payload);
    };

    const handleDelete = () => {
        deleteMutation.mutate(deleteId);
    };

    const handleEditAction = (item) => {
        const editPermission = Object.values(item?.permissions).map((per) => {
            const permissions = Object.entries(per?.permissions).map((i) => ({
                ...permissionAction[i[0]],
                value: i[1],
            }));
            return { ...per, permissions };
        });
        setDisableInp({
            ...inputValue,
            uuid: item.uuid,
            name: item.name,
            is_dashboard_access: item?.is_dashboard_access,
            permissionList: editPermission,
        });
        setInputValue({
            ...inputValue,
            uuid: item.uuid,
            name: item.name,
            is_dashboard_access: item?.is_dashboard_access,
            permissionList: editPermission,
        });
        setIsOpen(true);
        setInputError("");
    };

    const handleUpdate = (objPayload) => {
        const payload = {
            id: inputValue.uuid,
            name: inputValue.name,
            is_dashboard_access: inputValue?.is_dashboard_access,
            updated_permissions: objPayload,
        };
        updateMutation.mutate(payload);
    };

    const addMutation = useMutation(addRolesApi, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const updateMutation = useMutation(updateRolesApi, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const deleteMutation = useMutation(deleteRolesApi, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                setOpenDel(false);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success");
        queryClient.refetchQueries("rolesList");
        setIsOpen(false);
        setIsLoadding(false)
        setInputValue({ ...inputValue, uuid: "", is_dashboard_access: false, name: "" })
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error"));
    };

    const handlePageClick = async (data) => {
        const currentPage = data.selected;
        setFilter((s) => ({
            ...s,
            offset: currentPage * filter.limit,
            currentPage: currentPage,
        }));
    };

    // const updateTagStatus = (item) => {
    //     const payload = {
    //         id: item.id,
    //         name: item.name,
    //         is_active: !item?.is_active,
    //     };
    //     updateActiveMutation.mutate(payload);
    // };

    // const updateActiveMutation = useMutation(updateIndustry, {
    //     onSuccess: (res) => {
    //         if (res?.success) {
    //             onSuccessResponse(res?.message);
    //         } else {
    //             onErrorResponse(res?.error);
    //         }
    //     },
    // });

    return (
        <>
            <div className='tabMain_head'> <Tabs TabList={roleTabList} handleActive={handleActive} activeTab={+filter?.status} />
                <div>
                    <button className='addPlan' onClick={() => setIsOpen(true)}>Add Role</button>

                </div>

            </div>
            <div className="anvTask_prog">
                <div className="anvInputs&fillters">

                    <div className="inr_flex_filter" >
                        <button className="filterBox">Filter</button>
                    </div>

                    <div className="inr_flex">
                        <InputField placeholder="Search" className="anvSearch_input"
                            label="Search"
                            value={filter?.search}
                            onChange={(e) =>
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    search: e.target.value,
                                }))
                            }
                        />
                    </div>
                    {filter?.search && <div className="inr_flex_mini">
                        <button className="anime-btn reset_button" onClick={() => {
                            setFilter({
                                ...filter,
                                search: ""

                            })

                        }
                        } >Reset</button>
                    </div>}
                </div>
            </div>

            <div>
                <div className="table-responsive">
                    <CustomTable column={column} data={rowDataList()} tableLoading={loaderForList} />
                </div>


            </div>
            {dataList?.results?.length === 0 ? (
                ""
            ) : (
                <div className="pagination_count">
                    <div className="">
                        <Dropdown
                            placeholder="Select Count"
                            className="dropdownoforder orderModal_Input"
                            name="driver"
                            options={pageCount ? pageCount : []}
                            valueText="value"
                            labelText="label"
                            selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
                            setSelectedOption={(data) => {
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    limit: data.value,
                                }))
                                setInputValue({ ...inputValue, count: data });
                            }}

                        />
                    </div>

                    <CustomPagination
                        onPageChange={handlePageClick}
                        currentPage={filter?.currentPage}
                        pageCount={Math.ceil(dataList?.count / filter?.limit)}
                    />
                </div>
            )}

            {isOpen && <CustomOffcanvas
                customeClass="customeClassForRole"
                isOpen={isOpen}
                bodyClass="fullWidFx"
                isLoadding={isLoadding}
                title={inputValue?.uuid ? "Edit User Roles" : "Add User Role"}
                toggle={() => {
                    setIsOpen(!isOpen)
                    setInputValue({ ...inputValue, uuid: "", is_dashboard_access: false, name: "" })
                }}
                submitLabel={inputValue?.uuid ? "Update Roles" : "Add Roles"}
                onSubmit={(e) => onSubmit(e)}
                inputProps={

                    <>
                        <div >
                            <div className="anvOt_outerFull input_bottom_margin">
                                <div className="anvOt_inputField ">

                                    <InputField
                                        className="modal_input"
                                        label="Role Name"
                                        labelClass="modal_label"
                                        labelSpan="*"
                                        labelSpanClass="style_for_star"
                                        placeholder="Enter Role name"
                                        name="name"
                                        focus={!!inputError?.name}
                                        error={inputError?.name}
                                        value={inputValue?.name}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />

                                </div>
                            </div>
                            <div className="tableForRoledetails">
                                <div className=" mt-2">


                                    {/* <CheckboxField
                                                    type="checkbox"
                                                    label="All Access"
                                                    labelClass="checkbox_head"
                                                    name="all_access"
                                                    checked={inputValue?.is_dashboard_access === true}
                                                    onChange={(e) => {
                                                        setInputValue((s) => {
                                                            const permissionsArray = s?.permissionList.map((a) => ({
                                                                ...a,
                                                                permissions: a?.permissions?.map((p) => ({
                                                                    ...p,
                                                                    value: !s?.is_dashboard_access,
                                                                })),
                                                            }));
                                                            return {
                                                                ...s,
                                                                is_dashboard_access: !s?.is_dashboard_access,
                                                                permissionList: permissionsArray,
                                                            };
                                                        });
                                                    }}
                                                /> */}
                                    <div className=" mt-2">

                                        <div className={"border py-2"} >
                                            <div className="row mx-0" >
                                                <div className="col-3">
                                                    <CheckboxField
                                                        type="checkbox"
                                                        label="Dashboard"
                                                        name="is_dashboard_access"
                                                        checked={inputValue.is_dashboard_access === true
                                                        }
                                                        onChange={() => {
                                                            setInputValue({ ...inputValue, is_dashboard_access: !inputValue?.is_dashboard_access })
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-2">
                                                    <CheckboxField
                                                        type="checkbox"
                                                        label="View"
                                                        name="is_dashboard_access"
                                                        checked={inputValue.is_dashboard_access === true
                                                        }
                                                        onChange={() => {
                                                            setInputValue({ ...inputValue, is_dashboard_access: !inputValue?.is_dashboard_access })
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        {inputValue?.permissionList?.map((item, ind) => (
                                            <div className={"border py-2"} key={item?.name}>
                                                <div className="row mx-0" key={ind}>
                                                    <div className="col-3">
                                                        <CheckboxField
                                                            type="checkbox"
                                                            label={item?.module_name}
                                                            name={item?.module_name}
                                                            checked={item?.permissions?.some(
                                                                (e) => e.value === true
                                                            )}
                                                            onChange={() => {
                                                                const permissionsArray = [
                                                                    ...inputValue?.permissionList,
                                                                ];
                                                                permissionsArray.splice(ind, 1, {
                                                                    ...item,
                                                                    permissions: item?.permissions?.map((p) => ({
                                                                        ...p,
                                                                        value: !item?.permissions.some(
                                                                            (e) => e.value === true
                                                                        ),
                                                                    })),
                                                                });
                                                                handleOnChange(
                                                                    "permissionList",
                                                                    permissionsArray
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {item?.permissions?.map((act, key) => (
                                                        <div className="col-2" key={act?.name}>
                                                            <CheckboxField
                                                                type="checkbox"
                                                                label={act?.name}
                                                                name={act?.name}
                                                                disabled={
                                                                    act?.name === "View"
                                                                        ? item?.permissions
                                                                            .filter((per) => per?.name !== "View")
                                                                            .some((e) => e.value === true)
                                                                        : false
                                                                }
                                                                checked={act?.value}
                                                                onChange={() => {
                                                                    const permissions = [...item?.permissions];
                                                                    permissions[key].value = !act?.value;
                                                                    if (act?.name !== "View") {
                                                                        permissions[0].value = true;
                                                                    }
                                                                    const permissionArray = [
                                                                        ...inputValue?.permissionList,
                                                                    ];
                                                                    permissionArray.splice(ind, 1, {
                                                                        ...item,
                                                                        permissions,
                                                                    });
                                                                    handleOnChange(
                                                                        "permissionList",
                                                                        permissionArray
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </>

                }
            />}


            <ConfirmModal
                isOpen={openDel}
                toggle={() => setOpenDel(!openDel)}
                onSubmit={() => handleDelete()}
            />




        </>
    );
};
