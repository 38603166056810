import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getAllResearch = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.research, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const getAllCustomer = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.customer, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addCustomer = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.customer, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const uploadExcelCustomer = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.customer + `/upload_customers`, data, true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addResearch = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.research, data, true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteCustomer = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.customer + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const deleteResearch = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.research + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateResearch = (data = {}) => {

    return performRequest(
        methodType.PUT,
        apiEndPoints.customer + `/${data?.id}`,
        data?.formdata,
        true, true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateCustomer = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.customer + `/${data?.id}`,
        data?.formData,
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateActiveResearch = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.research + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleNewDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.customer + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const singleCustomerDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.customer + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const updateActiveCustomer = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.customer + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};