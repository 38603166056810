import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CustomPagination from "./CustomPagination";
import { InputField } from "./InputField";
import { TfiClose } from "react-icons/tfi";
import mytruck from "../../../image/tanker.png";
import allocateFleetLoading from "../../../image/fleetNoData.png";
import { convertHoursToDaysHoursMinutes, getUpcomingDateFromHours, timeAgoCalculation } from "../../helper";

const AllocateVehicleModal = (props) => {
  const {
    className = "anvLarge_modal",
    isOpen,
    toggle,
    onSubmit,
    headerName,
    submitLabel,
    inputProps,
    disabled,
    size = "xl",
    toggle2,
    filterFleet,
    fleetList,
    callBackData,
    selectTrailerList,
    deleteHandle,
    sendNoninationHandle,
    deleteFleetHandle,
    OrderID,
    loaddingSend,
    setSearchKey,
    handlePageClick,
    filterFleetPagi,
    filterModal,
    searchKey,
    searchInput,
    isLoading,
    etaDataList,
    headerData
  } = props;

  const [totalWeight, setTotalWeight] = useState(0);
  const [substect, setSubstract] = useState(0);
  const initialTotal = parseInt(OrderID.qty);
  // const [filterList, setFilterList] = useState()
  // const [filterValue, setTabFilterValue] = useState(false)


  useEffect(() => {
    totalValue();
  }, [selectTrailerList]);

  const totalValue = () => {
    const sum = selectTrailerList?.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.totalCapacity);
    }, 0);

    setSubstract(initialTotal - sum);
    setTotalWeight(sum);
  };

  return (
    <Modal
      size={size}
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      backdrop
      keyboard
      centered={true}
    >
      <ModalBody>
        <div className="mainTable_flex">
          <div className="topHead_bx">
          <h3>{`Fleet Allocation For ${headerData?.customer_name} | Order #${headerData?.order_number}`}</h3>
            <button onClick={toggle} className="closeIconeFont">
              <TfiClose />
            </button>
          </div>
          <div className="rightTable_bx">

                           <>{searchInput}</>

            <div style={{ paddingLeft: "20px" }}>
              <div className="anvMod_tableContainer">
                <table className="anvMod_table">
                  <thead>
                    <tr>
                      <th>Fleet Number</th>
                      <th>Fleet Type</th>
                      <th>Capacity</th>
                      <th>Driver</th>
                      {/* <th>Border Pass </th> */}
                      <th>ETA </th>
                      <th align="center">GPS Location</th>
                      <th align="center">Action</th>
                    </tr>
                  </thead>

                  {/* {filterFleet &&
                    filterFleet?.filter((fx) => fx?.is_available == filterValue)?.map((item, index) => (
                      <tbody className="alloteFleetTable">
                        <tr>
                          <td>{item?.truck_number}</td>
                             <td>{fleetList.filter((i) => i.id === item?.fleet_type)[0]?.name}</td> 
                          
                          <td>{item?.fleet_type_name}</td>
                          <td>
                            {item?.trailer_details?.reduce(
                              (accumulator, currentValue) => {
                                return (
                                  accumulator + parseInt(currentValue.tare_weight)
                                );
                              },
                              0
                            )}
                          </td>
                          <td><div className="_drvrName"><p title={item?.driver_name} >{item?.driver_name}</p></div></td>
                          <td>
                            <div className="_dtStatus_bx">
                              <p>1 Days | 11:33 Hour</p>
                              <span className="_smallTxt">1 Sep 24 | 14:32 </span>
                            </div>

                          </td>
                          <td>
                            <div className="_dtStatus_bx">
                              <p>Beira Port, Mozambique</p>
                              <span className="_smallTxt" style={{color: '#21888B'}}>10min Ago </span>
                            </div>
                          </td>
                          <td>
                            <button
                              className="anime-btn modactionBtn"
                              onClick={() => {
                                toggle2();
                                callBackData(item);
                              }}
                            >
                              Allocate
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))} */}
                  {isLoading ?
                    <tbody>
                      <tr>
                        <td colSpan={7} rowSpan={1}>
                          <div className=" llt_loading_gif"> <img src={mytruck} alt="" /></div>
                        </td>
                      </tr>
                    </tbody>
                  : filterFleet && filterFleet?.length > 0 ? (
                    filterFleet?.map((item, i) => {
                      const eta = etaDataList[item?.id];
                      return (
                        <tbody className="alloteFleetTable" key={i}>
                          <tr>
                            <td>{item?.truck_number}</td>
                            <td>{item?.fleet_type_name}</td>
                            <td>
                              {item?.trailer_details?.reduce(
                                (accumulator, currentValue) => {
                                  return (
                                    accumulator +
                                    parseInt(currentValue.tare_weight)
                                  );
                                },
                                0
                              )}
                            </td>
                            <td>
                              <div className="_drvrName">
                                <p title={item?.driver_name}>
                                  {item?.driver_name}
                                </p>
                              </div>
                            </td>
                            <td>
                              {eta ? (
                                <div className="_dtStatus_bx">
                                  <p>
                                    {eta?.total_duration_hours === null
                                      ? "---"
                                      : convertHoursToDaysHoursMinutes(
                                          eta.total_duration_hours
                                        )}
                                  </p>
                                  <span className="_smallTxt">
                                  {eta?.total_duration_hours === null
                                      ? "---"
                                      : getUpcomingDateFromHours(
                                          eta.total_duration_hours
                                        )}
                                  </span>
                                </div>
                              ) : (
                                <p>No ETA Available</p>
                              )}
                            </td>
                            <td>
                              <div className="_dtStatus_bx">
                                <p>
                                  {item?.last_location_name === null
                                    ? "---"
                                    : item?.last_location_name}
                                </p>
                                <span
                                  className="_smallTxt"
                                  style={{ color: "#21888B" }}
                                >
                                  {item?.location_data_updated_on === null
                                    ? "---"
                                    : timeAgoCalculation(
                                        item?.location_data_updated_on
                                      )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <button
                                className="anime-btn modactionBtn"
                                onClick={() => {
                                  toggle2();
                                  callBackData(item);
                                }}
                              >
                                Allocate
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={7} rowSpan={1}>
                            <div className="d-flex align-items-center justify-content-center">No data found!</div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              {filterFleetPagi?.length === 0 ? (
                ""
              ) : (
                <CustomPagination
                  onPageChange={handlePageClick}
                  currentPage={filterModal?.currentPage}
                  pageCount={Math.ceil(
                    filterFleetPagi?.count / filterModal?.limit
                  )}
                  boxClass="fleetPagination"
                />
              )}
            </div>
          </div>

          <div className="leftTable_bx">
            <div className="allocateHead llt_right_border_allocate">
              Allocate Fleet
            </div>
            {selectTrailerList && selectTrailerList?.length > 0 ? (
              <>
                <div className="anvMod_tableContainer ">
                  <table className="anvMod_table ">
                    <thead>
                      <tr>
                        <th>Fleet Number</th>
                        <th>Capacity</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {selectTrailerList &&
                        selectTrailerList?.map((item, index) => (
                          <tr>
                            <td>{item.truck_number}</td>
                            <td>{item.totalCapacity}</td>
                            <td
                              onClick={() => deleteFleetHandle(index)}
                              style={{ cursor: "pointer", width: "15%" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                              >
                                <rect
                                  width="22"
                                  height="22"
                                  rx="2"
                                  fill="#FFE9E9"
                                />
                                <path
                                  d="M16.4163 6.4502L15.9688 13.4678C15.8544 15.2607 15.7972 16.1572 15.3336 16.8017C15.1043 17.1204 14.8091 17.3893 14.4668 17.5914C13.7745 18.0002 12.8478 18.0002 10.9944 18.0002C9.13859 18.0002 8.21067 18.0002 7.51788 17.5906C7.17536 17.3882 6.88012 17.1188 6.65095 16.7996C6.18742 16.154 6.1315 15.2563 6.01967 13.4608L5.58301 6.4502"
                                  stroke="#FF0000"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M8.83301 10.8145H13.1663"
                                  stroke="#FF0000"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M9.91699 13.5581H12.0837"
                                  stroke="#FF0000"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M4.5 6.45H17.5M13.929 6.45L13.436 5.46421C13.1084 4.80938 12.9447 4.48197 12.6622 4.27776C12.5996 4.23247 12.5332 4.19218 12.4638 4.15729C12.151 4 11.7756 4 11.0248 4C10.2551 4 9.87029 4 9.5523 4.16388C9.48182 4.20021 9.41457 4.24213 9.35124 4.28922C9.06549 4.50169 8.90587 4.84109 8.58663 5.51988L8.1492 6.45"
                                  stroke="#FF0000"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="llt_allocatefleet_pic">
                  <img src={allocateFleetLoading} alt="FleetLoadingImg" />
                </div>
              </>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="anv_fleetModalFooter">
        <div className="anvFirst_sublistBx">
          <div className="anvFirst_sublist">
            <div>Order :</div>
            <div className="fw-semibold">{`${initialTotal} ${
              OrderID?.type === 1 ? "Ltr" : "Ton"
            }`}</div>
          </div>

          <div className="anvFirst_sublist">
            <div>Alloted :</div>
            <div className="fw-semibold">{`${totalWeight} ${
              OrderID?.type === 1 ? "Ltr" : "Ton"
            }`}</div>
          </div>

          <div className="anvFirst_sublist">
            <div>Pending :</div>

            <div className="fw-semibold">{`${substect} ${
              OrderID?.type === 1 ? "Ltr" : "Ton"
            }`}</div>
          </div>
        </div>

        {/* <div className="otrTickBx">
          <div className="avnTickFull_wid">
            <div>
              <div className="anvTick_tabFlex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_481_602)">
                    <mask
                      id="mask0_481_602"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38569 21.2411 8.17221C20.7385 6.95874 20.001 5.85655 19.071 4.929C18.1435 3.99901 17.0413 3.2615 15.8278 2.75884C14.6143 2.25619 13.3135 1.9983 12 2C10.6865 1.99833 9.3857 2.25623 8.17222 2.75888C6.95875 3.26154 5.85656 3.99904 4.92901 4.929C3.99904 5.85655 3.26154 6.95874 2.75889 8.17221C2.25623 9.38569 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z"
                        fill="white"
                        stroke="white"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L11 15L17 9"
                        stroke="black"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_481_602)">
                      <path d="M0 0H24V24H0V0Z" fill="#0011AA" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_481_602">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Fleet Border Pass- OK</p>
              </div>

              <div className="anvTick_tabFlex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_481_644)">
                    <mask
                      id="mask0_481_644"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2411C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38568 21.2411 8.17221C20.7385 6.95873 20.001 5.85655 19.071 4.92899C18.1435 3.99901 17.0413 3.26149 15.8278 2.75884C14.6143 2.25618 13.3135 1.99829 12 1.99999C10.6865 1.99832 9.3857 2.25622 8.17222 2.75887C6.95875 3.26153 5.85656 3.99903 4.92901 4.92899C3.99904 5.85655 3.26154 6.95873 2.75889 8.17221C2.25623 9.38568 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z"
                        fill="white"
                        stroke="white"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L11 15L17 9"
                        stroke="black"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_481_644)">
                      <path d="M0 0H24V24H0V0Z" fill="#3DBE00" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_481_644">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Trailer Border Pass- OK</p>
              </div>
            </div>

            <div>
              <div className="anvTick_tabFlex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_481_609)">
                    <mask
                      id="mask0_481_609"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38569 21.2411 8.17221C20.7385 6.95874 20.001 5.85655 19.071 4.929C18.1435 3.99901 17.0413 3.2615 15.8278 2.75884C14.6143 2.25619 13.3135 1.9983 12 2C10.6865 1.99833 9.3857 2.25623 8.17222 2.75888C6.95875 3.26154 5.85656 3.99904 4.92901 4.929C3.99904 5.85655 3.26154 6.95874 2.75889 8.17221C2.25623 9.38569 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z"
                        fill="white"
                        stroke="white"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L11 15L17 9"
                        stroke="black"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_481_609)">
                      <path d="M0 0H24V24H0V0Z" fill="#68696C" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_481_609">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Border Pass Not Available</p>
              </div>
              <div className="anvTick_tabFlex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_481_651)">
                    <mask
                      id="mask0_481_651"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2411C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38568 21.2411 8.17221C20.7385 6.95873 20.001 5.85655 19.071 4.92899C18.1435 3.99901 17.0413 3.26149 15.8278 2.75884C14.6143 2.25618 13.3135 1.99829 12 1.99999C10.6865 1.99832 9.3857 2.25622 8.17222 2.75887C6.95875 3.26153 5.85656 3.99903 4.92901 4.92899C3.99904 5.85655 3.26154 6.95873 2.75889 8.17221C2.25623 9.38568 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z"
                        fill="white"
                        stroke="white"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L11 15L17 9"
                        stroke="black"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_481_651)">
                      <path d="M0 0H24V24H0V0Z" fill="#BE0000" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_481_651">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Border Pass Expired</p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="otrSend_bx">
          {loaddingSend ? (
            <button className="sendNominate" disabled={true}>
              Sending...
            </button>
          ) : (
            <button
              className="sendNominate"
              disabled={!(initialTotal <= totalWeight)}
              onClick={sendNoninationHandle}
            >
              Send for Nomination
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AllocateVehicleModal;
