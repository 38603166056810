import React from 'react';
import { useJsApiLoader } from "@react-google-maps/api";

const libraries = ['places'];

export const GoogleMapsLoader = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return isLoaded ? children : <div>Loading...</div>;
};