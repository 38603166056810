import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  MarkerClusterer,
  InfoBox,
} from "@react-google-maps/api";
import customeMarker from "../../../image/karanYellow.png";
import { TimeDecoder } from "../../helper";
import streeing from "../../../image/steering.png";
import locationIng from "../../../image/location.png";
import stoping from "../../../image/stop.png";
import orderClip from "../../../image/orderClip.png";
const containerStyle = {
  width: "100%",
  height: "100%",
  border: "1px solid #ddd",
  borderRadius: "12px",
};



const center = {
  lat: -19.7872,
  lng: 34.877,
};



function GoogleMapComponent({ mapList, zoom, latLOng, activeWindow }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
  });

  const [map, setMap] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapMaker,setMapMAker] = useState(null)
  
  const loadHandler = (map) => {
    setMap(map);
  };
  
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const options = useMemo(
    () => ({
      // mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
      zoomControl: true,
    }),
    []
  );

  useEffect(() => {
    if (activeWindow) setActiveMarker(activeWindow);
  }, [activeWindow]);

  const onLoad = (mapMarker) => {
  
    setMapMAker(
      mapMarker
);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={latLOng}
      zoom={zoom}
      options={options}
      onLoad={loadHandler}
      onUnmount={onUnmount}
      onClick={() => setActiveMarker(null)}
      clustererclustererclusterer
    >
      <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
        {(clusterer) =>
          mapList &&
          mapList?.map((item, index) => {
          
            return (
              <Marker
                key={item?.trackerid}
                position={{
                  lat: parseFloat(item.latend),
                  lng: parseFloat(item.lngend),
                }}
                onClick={() => handleActiveMarker(item?.tracker_number)}
                icon={{
                  url: customeMarker,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                clusterer={clusterer}
                onLoad={onLoad}
              >
                {item?.address && (
                  <InfoBox
                    position={{
                      lat: parseFloat(item.latend),
                      lng: parseFloat(item.lngend),
                    }}
                    options={{ closeBoxURL: ``, enableEventPropagation: false }}
                    anchor={mapMaker}
                  >
                    <div
                      className="mapinfoBox"
                      onMouseOver={() =>
                        setSelectedMarker(item?.tracker_number)
                      }
                      onMouseOut={() => setSelectedMarker(null)}
                    >
                      {item?.address}
                    </div>
                  </InfoBox>
                )}
                {(selectedMarker||activeMarker) === item?.tracker_number && (
                  <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                    <div className="hover_info_window">
                      <div className="driver_trip">
                        <div className="stop_div_trip">
                          <img src={orderClip} />
                          <div className="text_stop">
                            Trip-{" "}
                            {item?.trip_number
                              ? item?.trip_number
                              : "Not Assigned"}{" "}
                            {`${item?.truck_number} - ${item?.tracker_number}`}
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="stop_trip">
                        <div className="stop_div_trip">
                          <img src={stoping} />
                          <span>Stopped since: </span>
                        </div>
                        <span>{TimeDecoder(item?.parktime)} </span>
                      </div>

                      
                      <div className="driver_trip">
                            <div className="stop_div_trip">
                            <img src={streeing} />
                            <span> Driver- {item?.driver_name}</span>
                            </div>
                            <span>{`${item.speed}km/h`}</span>
                          </div>

                      <div className="driver_trip">
                        <div className="stop_div_trip">
                          <img src={locationIng} />
                          <div className="text_stop"> {item?.address}</div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
                {/* {activeMarker === item?.tracker_number ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div className="circle_div">
                      <div>{`Track Id - ${item.tracker_number}`}</div>
                      <div>{`Speed - ${item?.speed}`}</div>
                      <div className="online-truck">
                        Online -{" "}
                        {`${item?.online}` ? (
                          <span className="circle"></span>
                        ) : (
                          <span className="circle_red"></span>
                        )}{" "}
                      </div>
                    </div>
                  </InfoWindow>
                ) : null} */}
              </Marker>
            );
          })
        }
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapComponent);
