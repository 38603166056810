import React, { useEffect, useState } from "react";
import { Label, Modal, Table } from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import {
  addBranchApi,
  getBranch,
  getDocumentTypeRelatedToBranch,
} from "../../services";
import { toast } from "react-toastify";
import {
  addFleetValidation,
  addResearchSchema,
  addTrailerValidationFirst,
  addTrailerValidations,
  crossBorderValidtionForTrailer,
  modalFileSchema,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  addTrailerTabList,
  checkFormError,
  formatDate,
  pageCount,
  trailerTabList,
  TrailerTypelist,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  singleNewDetails,
  updateActiveResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import { getDocumentType } from "../../services/documentTypeService";
import {
  addTrailerApi,
  deleteTarilerApi,
  getAllTrailer,
  singleTrailerDetails,
  updateActiveTrailer,
  updateTrailer,
  uploadExcelTrailer,
} from "../../services/trailerService";
import { addCrossForTrailer } from "../../services/crossBorderService";
import mytruck from "../../../image/tanker.png";
import { components } from "react-select";
import { getStorage } from "../../helper/storage";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

export const Trailer = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    trailer_number: "",
  };

  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const fleetObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  const documentObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date(), "-"),
  };

  const orderObj = {
    branch: "",
    trailer_type: "",
    trailer_number: "",
    tare_weight: "",
    vin_number: "",
    livret: "",
    verbete_expire_date: formatDate(new Date(), "-"),
    insurance_expire_date: formatDate(new Date(), "-"),
    cross_border_payment_expire_date: formatDate(new Date(), "-"),
    inspection_expire_date: formatDate(new Date(), "-"),
    caderneta_expire_date: formatDate(new Date(), "-"),
    manifesto_expire_date: formatDate(new Date(), "-"),
    taxa_de_radio_expire_date: formatDate(new Date(), "-"),
    registration_status: "",
    import_document_number: "",
    country: "",
    document_type: "",
    cross_border_permit_date: formatDate(new Date(), "-"),
    addbranch: "",
    count: "",
    document_details_data: [],
  };
  const orderObjName = {
    branch: "",
    trailer_type: "",
    trailer_number: "",
    tare_weight: "",
    vin_number: "",
    livret: "",
    verbete_expire_date: formatDate(new Date(), "-"),
    insurance_expire_date: formatDate(new Date(), "-"),
    cross_border_payment_expire_date: formatDate(new Date(), "-"),
    inspection_expire_date: formatDate(new Date(), "-"),
    caderneta_expire_date: formatDate(new Date(), "-"),
    manifesto_expire_date: formatDate(new Date(), "-"),
    taxa_de_radio_expire_date: formatDate(new Date(), "-"),
    registration_status: "",
    import_document_number: "",
    country: "",
    document_type: "",
    cross_border_permit_date: formatDate(new Date(), "-"),
    addbranch: "",
    count: "",
  };
  const queryClient = useQueryClient();
  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length
    ? Object.values(userLogin?.user_permissions)?.filter((i) =>
      i?.module_name?.includes("Trailer")
    )[0]?.permissions
    : [];
  const result = userLogin?.user_branches?.join(",");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    branch_uuid: result,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [branchList, setBranchList] = useState();
  const [fleetList, setFleetList] = useState();
  const [countryList, setCountryList] = useState();
  const [documentList, setDocumentList] = useState();
  const [tarilerId, setTrailerId] = useState("");
  const [crossBorderList, setCrossBorderList] = useState([]);
  const [activeModalTab, setActiveModalTab] = useState(1);
  const [crossBordrCountry, setCrossBordrCountry] = useState([]);
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [disbleButton, setDisbleButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState({
    excel_file: "",
  });
  const [inputError, setInputError] = useState({
    excel_file: "",
  });
  const [disableInp, setDisableInp] = useState({
    excel_file: "",
  });
  const [selectedBranch, setSelectedBranch] = useState();
  const [documentListRelatedToBrach, setDocumentListRelatedToBranch] =
    useState();

  const AllFleet = [
    { heading: "S NO.", className: "sorting", value: "sno", sortName: "" },
    {
      heading: "Trailer Number",
      className: "sorting_asc",
      value: "trailer_number",
      sortName: "",
    },
    {
      heading: "Trailer Type",
      className: "sorting_asc",
      value: "trailerType",
      sortName: "",
    },
    {
      heading: "Assoicated Fleet",
      className: "sorting_asc",
      value: "fleet",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const addFleet = [
    {
      heading: "Trailer Number",
      className: "sorting",
      value: "Trailer Number",
    },
    { heading: "Capacity", className: "sorting_asc", value: "Capacity" },
    { heading: "", className: "sorting_asc", value: "action1" },
  ];

  const Pending = [
    { heading: "Date", className: "sorting", value: "Date" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "Customer_Name",
    },
    { heading: "Route", className: "sorting_asc", value: "Route" },
    { heading: "Status", className: "sorting_asc", value: "status" },
    { heading: "Product", className: "sorting_asc", value: "Product" },
    { heading: "Requirement", className: "sorting_asc", value: "Requirement" },
    { heading: "Placed", className: "sorting_asc", value: "Placed" },
    { heading: "Balance", className: "sorting_asc", value: "Balance" },
    { heading: "Action", className: "sorting_asc", value: "action" },
  ];

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      status: (
        <div className="statusofOrder">
          <span>Pending</span>
        </div>
      ),
      branch: (
        <>
          <span>
            {
              branchList?.filter((filter) => filter?.id === item?.branch)[0]
                ?.name
            }
          </span>
        </>
      ),
      truckType: (
        <>
          <span>
            {
              fleetList?.filter((filter) => filter?.id === item?.fleet_type)[0]
                ?.name
            }
          </span>
        </>
      ),
      trailerType: item?.trailer_type === 2 ? "SOLID" : "LIQUID",
      // fleet: (
      //     <>
      //         <span>{item?.fleet_type_details?.no_of_trailer}</span>
      //     </>
      // ),

      action: (
        <>
          <div className="actionBtn">
            <div className="customerStatus">
              <SwitchField
                onSwitchChange={() => updateTagStatus(item)}
                value={item?.is_active}
              />
              {item?.is_active ? <span>Disable</span> : <span>Enable</span>}
            </div>

            {/* <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
                        <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> */}

            {userLogin?.user_type === 1 ? (
              <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>
              // <img
              //   src={EditIcon}
              //   alt=""
              //   onClick={() => handleEditAction(item)}
              // />
            ) : (
              permissions[3] && (
                <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>
                // <img
                //   src={EditIcon}
                //   alt=""
                //   onClick={() => handleEditAction(item)}
                // />
              )
            )}
            {userLogin?.user_type === 1 ? (
              <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>
              // <img
              //   src={DeleteIcon}
              //   alt=""
              //   onClick={() => hendleDeleteTag(item, true)}
              // />
            ) : (
              permissions[4] && (
                <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>
                // <img
                //   src={DeleteIcon}
                //   alt=""
                //   onClick={() => hendleDeleteTag(item, true)}
                // />
              )
            )}
            <button
              className="anime-btn accept_odr"
              onClick={() => {
                setIsOpen(true);
                setActiveModalTab(3);
                setDisbleButton(true);
                setTrailerId(item.id);
              }}
            >
              CBP
            </button>
          </div>
        </>
      ),
      action1: (
        <>
          <div className="actionBtn">
            <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            />
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return userLogin?.user_type !== 1 && userLogin?.user_branches?.length === 0
      ? []
      : listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllTrailer", debouncedFilter],
    () => getAllTrailer(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getBranchList();
    getFleetsList();
    getCountryList();
    getDocumentList();
  }, []);

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };

  const getFleetsList = async () => {
    const res = await getFleetType(fleetObj);
    setFleetList(res?.results);
  };

  const getCountryList = async () => {
    const res = await getCountry(countryObj);
    setCountryList(res?.results);
  };

  const getDocumentList = async () => {
    const res = await getDocumentType(documentObj);
    setDocumentList(res?.results);
  };

  const clearAll = () => {
    setFormOrderValue({
      branch: "",
      trailer_type: "",
      trailer_number: "",
      tare_weight: "",
      vin_number: "",
      livret: "",
      verbete_expire_date: formatDate(new Date(), "-"),
      insurance_expire_date: formatDate(new Date(), "-"),
      cross_border_payment_expire_date: formatDate(new Date(), "-"),
      inspection_expire_date: formatDate(new Date(), "-"),
      caderneta_expire_date: formatDate(new Date(), "-"),
      manifesto_expire_date: formatDate(new Date(), "-"),
      taxa_de_radio_expire_date: formatDate(new Date(), "-"),
      registration_status: "",
      import_document_number: "",
    });
    setFormOrderName({
      branch: "",
      trailer_type: "",
      trailer_number: "",
      tare_weight: "",
      vin_number: "",
      livret: "",
      verbete_expire_date: formatDate(new Date(), "-"),
      insurance_expire_date: formatDate(new Date(), "-"),
      cross_border_payment_expire_date: formatDate(new Date(), "-"),
      inspection_expire_date: formatDate(new Date(), "-"),
      caderneta_expire_date: formatDate(new Date(), "-"),
      manifesto_expire_date: formatDate(new Date(), "-"),
      taxa_de_radio_expire_date: formatDate(new Date(), "-"),
      registration_status: "",
      import_document_number: "",
    });
    setPreview("");
    setIsEdit(false);
    setFormValue("");
    setFormError("");
    setInputError("");
    setInputValue({
      excel_file: "",
    });
    setActiveModalTab(1);
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addTrailerValidations);
      setValidationError(error);
    }
  };

  const handleOrderChangeDocument = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);
    if (!!formError) {
      const error = await checkFormError(stateObj, addFleetValidation);
      setValidationError(error);
    }
  };

  const validationHnalde = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      addTrailerValidationFirst
    );

    if (selectedBranch?.branch) {
      const res = await getDocumentTypeRelatedToBranch(
        documentObj,
        selectedBranch?.branch
      );
      setDocumentListRelatedToBranch(res?.results);
    }

    setValidationError(validationResult);

    if (validationResult === true) {
      setActiveModalTab(2);
    }
  };

  const validationHnaldeDate = async () => {
    setIsOpen(false);
    clearAll();
    setActiveModalTab(1);
    setCrossBorderList([]);
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      addTrailerValidations
    );

    const dataDocumentDetail = documentListRelatedToBrach
      .filter((item) => formOrderValue[item.name] !== undefined)
      .map((item) => {
        return {
          document_type: item.id,
          fleet: null,
          trailer: "",
          value: formOrderValue[item.name],
        };
      });

    formOrderValue.document_details_data = dataDocumentDetail;

    const payload = {
      branch: formOrderValue?.branch,
      trailer_type: formOrderValue.trailer_type,
      trailer_number: formOrderValue?.trailer_number,
      tare_weight: formOrderValue?.tare_weight,
      vin_number: formOrderValue?.vin_number,
      livret: formOrderValue?.livret,
      verbete_expire_date: formOrderValue?.verbete_expire_date,
      insurance_expire_date: formOrderValue?.insurance_expire_date,
      cross_border_payment_expire_date:
        formOrderValue?.cross_border_payment_expire_date,
      inspection_expire_date: formOrderValue?.inspection_expire_date,
      caderneta_expire_date: formOrderValue?.caderneta_expire_date,
      manifesto_expire_date: formOrderValue?.manifesto_expire_date,
      taxa_de_radio_expire_date: formOrderValue?.taxa_de_radio_expire_date,
      registration_status: formOrderValue?.registration_status,
      import_document_number: formOrderValue?.import_document_number,
      document_details_data: formOrderValue?.document_details_data,
    };

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(payload);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const addCrossBorderDocument = async () => {
    const payload = {
      country: formOrderValue?.country,
      document_type: formOrderValue?.document_type,
      cross_border_permit_date: formOrderValue.cross_border_permit_date,
      trailer: tarilerId,
    };

    const validationResult = await checkFormError(
      {
        ...payload,
      },
      crossBorderValidtionForTrailer
    );

    if (validationResult === true) {
      if (isEdit === true) {
        // handleUpdate();
      } else {
        const res = await addCrossForTrailer(payload);

        if (res.status === 201) {
          setIsOpenModal2(false);
          let list = [...crossBorderList];
          let cuntyList = [...crossBordrCountry];
          list.push(res?.results);
          cuntyList.push(res?.results.country_name);
          setCrossBorderList(list);
          setCrossBordrCountry(cuntyList);

          setFormOrderValue({
            ...formOrderValue,
            country: "",
            cross_border_permit_date: formatDate(new Date()),
            document_type: "",
          });
          setFormOrderName({
            ...formOrderName,
            country: "",
            cross_border_permit_date: formatDate(new Date()),
            document_type: "",
          });
        }
      }
    } else {
      setInputError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveTrailer, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleUploadExcel = (formData) => {
    uploadMutation.mutate(formData);
  };

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getTrailerDetails.mutate(item?.id);
  };

  const getTrailerDetails = useMutation(singleTrailerDetails, {
    onSuccess: async (res) => {
      const response = await getDocumentTypeRelatedToBranch(
        documentObj,
        res?.results?.branch
      );
      setDocumentListRelatedToBranch(response?.results);
      if (res?.success) {
        setFormOrderValue({
          trailer_number: res?.results?.trailer_number,
          trailer_type: res?.results?.trailer_type,
          branch: res?.results?.branch,
          tare_weight: res?.results?.tare_weight,
          vin_number: res?.results?.vin_number,
          trailers: res?.results?.trailers,
          livret: res?.results?.livret,
          verbete_expire_date: formatDate(
            new Date(res?.results?.verbete_expire_date),
            "-"
          ),
          insurance_expire_date: res?.results?.insurance_expire_date,
          cross_border_payment_expire_date:
            res?.results?.cross_border_payment_expire_date,
          inspection_expire_date: res?.results?.inspection_expire_date,
          caderneta_expire_date: res?.results?.caderneta_expire_date,
          manifesto_expire_date: res?.results?.manifesto_expire_date,
          taxa_de_radio_expire_date: res?.results?.taxa_de_radio_expire_date,
          fire_extinguisher_expiry_date:
            res?.results?.fire_extinguisher_expiry_date,
          registration_status: res?.results?.registration_status,
          import_document_number: res?.results?.import_document_number,
          // driver: res?.results?.driver,
          document_details_data: [],
        });

        const common = (name, id) => {
          if (name === "fleet") {
            let details = TrailerTypelist?.find((i) => i.value === id);
            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
          if (name === "branch") {
            let details = branchList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "document") {
            let nameObj = { ...formOrderName };
            response?.results.forEach((doc) => {
              const matchingDocument = res?.results?.document_details?.find(
                (data) => data.document_type === doc.id
              );
              if (matchingDocument) {
                nameObj[doc.name] = matchingDocument.value;
              }
            });
            return nameObj;
          }
        };

        setFormOrderName({
          ...common("document"),
          ...formOrderName,
          trailer_type: common("fleet", res?.results?.trailer_type),
          branch: common("branch", res?.results?.branch),

          verbete_expire_date: formatDate(
            new Date(res?.results?.verbete_expire_date),
            "-"
          ),
          insurance_expire_date: res?.results?.insurance_expire_date,
          cross_border_payment_expire_date:
            res?.results?.cross_border_payment_expire_date,
          inspection_expire_date: res?.results?.inspection_expire_date,
          caderneta_expire_date: res?.results?.caderneta_expire_date,
          manifesto_expire_date: res?.results?.manifesto_expire_date,
          taxa_de_radio_expire_date: res?.results?.taxa_de_radio_expire_date,
          fire_extinguisher_expiry_date:
            res?.results?.fire_extinguisher_expiry_date,
        });

        setPreview(res?.results?.image);
        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );

    const dataDocumentDetail = documentListRelatedToBrach
      .filter((item) => formOrderValue[item.name] !== undefined)
      .map((item) => {
        return {
          document_type: item.id,
          fleet: null,
          trailer: updateId,
          value: formOrderValue[item.name],
        };
      });
    formOrderValue.document_details_data = dataDocumentDetail;

    const payload = {
      formdata: {
        branch: formOrderValue?.branch,
        trailer_type: formOrderValue?.trailer_type,
        trailer_number: formOrderValue?.trailer_number,
        tare_weight: formOrderValue?.tare_weight,
        vin_number: formOrderValue?.vin_number,
        verbete_expire_date: formOrderValue?.verbete_expire_date,
        insurance_expire_date: formOrderValue?.insurance_expire_date,
        cross_border_payment_expire_date:
          formOrderValue?.cross_border_payment_expire_date,
        inspection_expire_date: formOrderValue?.inspection_expire_date,
        caderneta_expire_date: formOrderValue?.caderneta_expire_date,
        manifesto_expire_date: formOrderValue?.manifesto_expire_date,
        taxa_de_radio_expire_date: formOrderValue?.taxa_de_radio_expire_date,
        registration_status: formOrderValue?.registration_status,
        import_document_number: formOrderValue?.import_document_number,
        document_details_data: formOrderValue?.document_details_data,
      },
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const uploadMutation = useMutation(uploadExcelTrailer, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const addMutation = useMutation(addTrailerApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setTrailerId(res?.results?.id);
        setIsOpen(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateTrailer, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteTarilerApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllTrailer");
    setIsLoadding(false);
    setIsOpen1(false);
    setIsOpen(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };
  useEffect(() => {
    if (formValue?.image) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(formValue?.image);
    } else {
      if (preview) {
        setPreview(preview);
      } else {
        setPreview(null);
      }
    }
  }, [formValue?.image]);

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleModalActive = (data) => {
    setActiveModalTab(data?.value);
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return AllFleet;
      case 2:
        return AllFleet;
      case 3:
        return AllFleet;
      default:
        return AllFleet;
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalFileSchema);
      setInputError(error);
    }
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { excel_file: inputValue.excel_file },
      modalFileSchema
    );

    const formData = new FormData();
    formData.append("excel_file", inputValue?.excel_file);

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleUploadExcel(formData);
      }
    } else {
      setIsLoadding(false);
      setInputError(validationResult);
    }
  };

  /// ----------- add Branch ---------

  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Branch":
        setFormOrderValue({
          ...formOrderValue,
          addCountry: inputvalue,
          openName: "branch",
        });
        setOpenModal(true);
        break;

      default:
        break;
    }
  };

  const addNewSubmit = async (value) => {
    const validationResult = await checkFormError(
      { name: formOrderValue.addBranch },
      modalInputSchema
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAddNew(value);
      }
    } else {
      setFormError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleAddNew = (value) => {
    const payload = {
      name: formOrderValue?.addBranch,
    };

    addNewBranchMutation.mutate(payload);
  };

  const addNewBranchMutation = useMutation(addBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          branch: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addBranch: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          branch: res?.results?.id,
          addbranch: "",
        });

        setOpenModal(false);
        getBranchList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={trailerTabList}
          handleActive={handleActive}
          activeTab={+filter.status}
        />
        <div></div>
        {userLogin?.user_type === 1 ? (
          <div className="button_group">
            <button className="anime-btn addPlan" onClick={() => setIsOpen(true)}>
              Add Trailer
            </button>
            <button className="anime-btn addPlan" onClick={() => setIsOpen1(true)}>
              Upload Excel
            </button>
          </div>
        ) : (
          permissions[2] && (
            <div className="button_group">
              <button className="anime-btn addPlan" onClick={() => setIsOpen(true)}>
                Add Trailer
              </button>
              <button className="anime-btn addPlan" onClick={() => setIsOpen1(true)}>
                Upload Excel
              </button>
            </div>
          )
        )}
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>

          <div className="inr_flex">
            <InputField
              placeholder="Trailer Number"
              className="anvSearch_input"
              label="Trailer Number"
              value={filter?.trailer_number}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  trailer_number: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Branch"
              label="Select Branch"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={branchList ? branchList : []}
              valueText="id"
              labelText="name"
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.branchFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, branch_uuid: data.value });
                setFormOrderName({ ...formOrderName, branchFilter: data });
              }}
            />{" "}
          </div>
          {(filter?.branch_uuid.length == 36 || filter?.trailer_number) && <div className="inr_flex_mini">
            <button className="anime-btn reset_button" onClick={() => {
              setFilter({
                ...filter,
                trailer_number: "",
                branch_uuid: result

              })
              setFormOrderName({ ...formOrderName, branchFilter: '' })
            }
            } >Reset</button>
          </div>}
        </div>


      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                formOrderName?.count
                  ? formOrderName?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));

                setFormOrderName({ ...formOrderName, count: data });
              }}
            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen1}
        headerName={isEdit ? "Upload" : "Upload"}
        toggle={() => setIsOpen1(!isOpen1)}
        submitLabel={isEdit ? "Upload" : "Upload"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={isEdit ? "Upload" : "Upload"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Upload"
            name="excel_file"
            type="file"
            focus={!!inputError.excel_file}
            error={inputError.excel_file}
            // value={inputValue.excel_file}
            onChange={({ target: { name, files } }) =>
              handleOnChange(name, files[0])
            }
          />
        }
      />

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setDocumentListRelatedToBranch([]);
            validationHnaldeDate();
            setFormOrderValue({
              branch: "",
              trailer_type: "",
              trailer_number: "",
              tare_weight: "",
              vin_number: "",
              livret: "",
              verbete_expire_date: formatDate(new Date()),
              insurance_expire_date: formatDate(new Date()),
              cross_border_payment_expire_date: formatDate(new Date()),
              inspection_expire_date: formatDate(new Date()),
              caderneta_expire_date: formatDate(new Date()),
              manifesto_expire_date: formatDate(new Date()),
              taxa_de_radio_expire_date: formatDate(new Date()),
              registration_status: "",
              import_document_number: "",
            });
            setPreview("");
            setIsEdit(false);
            setDisbleButton(false);
            setActiveModalTab(1);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) =>
            +activeModalTab === 1
              ? validationHnalde()
              : +activeModalTab === 2
                ? onOrderSubmit(e)
                : validationHnaldeDate()
          }
          title={isEdit ? "Edit Trailer" : "Add Trailer"}
          submitLabel={+activeModalTab === 3 ? "Save" : "Continue"}
          inputProps={
            <>
              {isOpen && (
                <div div className="TabHeadofmodal">
                  {" "}
                  <Tabs
                    TabList={addTrailerTabList}
                    handleActive={handleModalActive}
                    activeTab={+activeModalTab}
                  />
                </div>
              )}
              {+activeModalTab === 1 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_inputField ">
                      {/* <span>
                        <Label className="FormLableKaran">Trailer Branch</Label>
                      </span> */}
                      <div>
                        <Dropdown
                          className="dropdownoforder"
                          placeholder="Select Branch"
                          label="Select Branch*"
                          classNamePrefix="_anvFloatSelect"
                          boxClass="_anvFloatedSelect"
                          name="branch"
                          options={branchList ? branchList : []}
                          valueText="id"
                          labelText="name"
                          focus={!!formError?.branch}
                          error={formError?.branch}
                          selectedOption={formOrderName?.branch}
                          components={
                            userLogin?.user_type == 1
                              ? { Menu: CustomControl }
                              : ""
                          }
                          setSelectedOption={(data) => {
                            setFormOrderName({
                              ...formOrderName,
                              branch: data,
                            });
                            setSelectedBranch({
                              branch: data?.value,
                            });
                            handleOrderChange("branch", data?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      {/* <span>
                        <Label className="FormLableKaran">Trailer Type</Label>
                      </span> */}
                      <div>
                        <Dropdown
                          className="dropdownoforder"
                          placeholder="Trailer Type"
                          label="Trailer Type*"
                          classNamePrefix="_anvFloatSelect"
                          boxClass="_anvFloatedSelect"
                          name="trailer_type"
                          options={TrailerTypelist ? TrailerTypelist : []}
                          valueText="value"
                          labelText="label"
                          focus={!!formError?.trailer_type}
                          error={formError?.trailer_type}
                          selectedOption={formOrderName?.trailer_type}
                          setSelectedOption={(data) => {
                            setFormOrderName({
                              ...formOrderName,
                              trailer_type: data,
                            });
                            handleOrderChange("trailer_type", data?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Trailer Number*" : "Trailer Number*"}
                        labelClass="FormLableKaran"
                        placeholder="Trailer Number"
                        name="trailer_number"
                        type="text"
                        focus={!!formError?.trailer_number}
                        error={formError.trailer_number}
                        value={formOrderValue?.trailer_number}
                        onChange={({ target: { name, value } }) => {
                          handleOrderChange(name, value.toUpperCase());
                        }}
                      />
                    </div>

                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "TARE Weight*" : "TARE Weight*"}
                        labelClass="FormLableKaran"
                        placeholder="Ton"
                        name="tare_weight"
                        type="text"
                        maxLength={10}
                        focus={!!formError?.tare_weight}
                        error={formError.tare_weight}
                        value={formOrderValue?.tare_weight}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Truck number" : "Chassis No."}
                        labelClass="FormLableKaran"
                        placeholder="Enter Chassis No."
                        name="vin_number"
                        type="text"
                        // focus={!!formError?.vin_number}
                        // error={formError.vin_number}
                        value={formOrderValue?.vin_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {+activeModalTab === 2 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input1"
                        label={isEdit ? "RC" : "RC"}
                        labelClass="FormLableKaran"
                        placeholder="Enter RC"
                        name="livret"
                        type="text"
                        // focus={!!formError?.livret}
                        // error={formError.livret}
                        value={formOrderValue?.livret}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    {/* <div className="anvOt_inputField ">
                                    <div >
                                        <span>
                                            <Label className="FormLableKaran">Verbete (Expire date)</Label>
                                        </span>
                                        <div>
                                            <CustomDatePicker
                                                value={formOrderName?.verbete_expire_date}
                                                max={formatDate(new Date())}
                                                focus={!!formError?.verbete_expire_date}
                                                error={formError.verbete_expire_date}
                                                name="verbete_expire_date"
                                                onSelect={({ target: { name, value } }) => {
                                                    handleOrderChange(name, value)
                                                    setFormOrderName({ ...formOrderName, verbete_expire_date: value })
                                                }
                                                }
                                                className="date-input"
                                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                            // className="date-input"
                                            />

                                        </div>
                                    </div>
                                </div> */}
                    <div className="anvOt_inputField ">
                      <div>
                        {/* <span>
                          <Label className="FormLableKaran">
                            Insurance (Expire date)
                          </Label>
                        </span> */}
                        <div>
                          <CustomDatePicker
                            type="date"
                            label="Insurance (Expire date)"
                            value={formOrderName?.insurance_expire_date}
                            max={formatDate(new Date())}
                            // focus={!!formError?.insurance_expire_date}
                            // error={formError.insurance_expire_date}
                            name="insurance_expire_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                insurance_expire_date: value,
                              });
                            }}
                            className="date-input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="anvOt_inputField ">
                      <div>
                        {/* <span>
                          <Label className="FormLableKaran">
                            Cross Border Payment (Expire date)
                          </Label>
                        </span> */}
                        <div>
                          <CustomDatePicker
                            type="date"
                            label="Cross Border Payment (Expire date)"
                            value={
                              formOrderName?.cross_border_payment_expire_date
                            }
                            max={formatDate(new Date())}
                            // focus={
                            //   !!formError?.cross_border_payment_expire_date
                            // }
                            // error={formError.cross_border_payment_expire_date}
                            name="cross_border_payment_expire_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                cross_border_payment_expire_date: value,
                              });
                            }}
                            className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <div>
                        {/* <span>
                          <Label className="FormLableKaran">
                            Inspection (Expire date)
                          </Label>
                        </span> */}
                        <div>
                          <CustomDatePicker
                            type="date"
                            label="Inspection (Expire date)"
                            value={formOrderName?.inspection_expire_date}
                            max={formatDate(new Date())}
                            // focus={!!formError?.inspection_expire_date}
                            // error={formError.inspection_expire_date}
                            name="inspection_expire_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                inspection_expire_date: value,
                              });
                            }}
                            className="date-input"
                          />
                        </div>
                      </div>
                    </div>

                    {documentListRelatedToBrach?.map((doc, index) => (
                    <div className="anvOt_inputField "  key={doc.id}>
                      <div>
                        <div>
                          <CustomDatePicker
                            type="date"
                            label={`${doc.name}`}
                            value={formOrderName[doc.name]}
                            max={formatDate(new Date())}
                            name={`${doc.name}`}
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChangeDocument(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                [doc.name]: value,
                              });
                            }}
                            className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    ))}
                    
                    {/* {documentListRelatedToBrach?.map((doc, index) => (
                      <div className="anvOt_inputField">
                        <div key={doc.id}>
                          <span>
                            <Label className={`${doc.name}`}>
                              {`${doc.name}`}
                            </Label>
                          </span>
                          <CustomDatePicker
                            value={formOrderName[doc.name]}
                            max={formatDate(new Date())}
                            // focus={!!formError?.inspection_expire_date}
                            // error={formError.inspection_expire_date}
                            name={`${doc.name}`}
                            type="date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChangeDocument(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                [doc.name]: value,
                              });
                            }}
                            className="date-input"
                          // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                          // className="date-input"
                          />
                        </div>
                      </div>
                    ))} */}
                    {/* <div className="anvOt_inputField ">
                                    <div >
                                        <span>
                                            <Label className="FormLableKaran">Caderneta (Expire date)</Label>
                                        </span>
                                        <div>
                                            <CustomDatePicker
                                                type="date"
                                                value={formOrderName?.caderneta_expire_date}
                                                max={formatDate(new Date())}
                                                focus={!!formError?.caderneta_expire_date}
                                                error={formError.caderneta_expire_date}
                                                name="caderneta_expire_date"
                                                onSelect={({ target: { name, value } }) => {
                                                    handleOrderChange(name, value)
                                                    setFormOrderName({ ...formOrderName, caderneta_expire_date: value })
                                                }
                                                }
                                                className="date-input"

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="anvOt_inputField ">
                                    <div >
                                        <span>
                                            <Label className="FormLableKaran">Manifesto (Expire date)</Label>
                                        </span>
                                        <div>
                                            <CustomDatePicker
                                                type="date"
                                                value={formOrderName?.manifesto_expire_date}
                                                max={formatDate(new Date())}
                                                focus={!!formError?.manifesto_expire_date}
                                                error={formError.manifesto_expire_date}
                                                name="manifesto_expire_date"
                                                onSelect={({ target: { name, value } }) => {
                                                    handleOrderChange(name, value)
                                                    setFormOrderName({ ...formOrderName, manifesto_expire_date: value })
                                                }
                                                }
                                                className="date-input"

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="anvOt_outerFull">
                                    <div className="anvOt_inputField ">
                                        <div >
                                            <span>
                                                <Label className="FormLableKaran">Taxa de Radio (Expire date)</Label>
                                            </span>
                                            <div>
                                                <CustomDatePicker
                                                    type="date"
                                                    value={formOrderName?.taxa_de_radio_expire_date}
                                                    max={formatDate(new Date())}
                                                    focus={!!formError?.taxa_de_radio_expire_date}
                                                    error={formError.taxa_de_radio_expire_date}
                                                    name="taxa_de_radio_expire_date"
                                                    onSelect={({ target: { name, value } }) => {
                                                        handleOrderChange(name, value)
                                                        setFormOrderName({ ...formOrderName, taxa_de_radio_expire_date: value })
                                                    }
                                                    }
                                                    className="date-input"

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Registration No" : "Registration No"}
                        labelClass="FormLableKaran"
                        placeholder="Registration No"
                        name="registration_status"
                        type="text"
                        maxLength={10}
                        // focus={!!formError?.registration_status}
                        // error={formError.registration_status}
                        value={formOrderValue?.registration_status}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={
                          isEdit
                            ? "Import Documents (DU number)"
                            : "Import Documents (DU number)"
                        }
                        labelClass="FormLableKaran"
                        placeholder="Import Documents"
                        name="import_document_number"
                        type="text"
                        maxLength={10}
                        focus={!!formError?.import_document_number}
                        error={formError.import_document_number}
                        value={formOrderValue?.import_document_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {+activeModalTab === 3 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal crossBorderTrailer">
                    <div className="addpermitDetails">
                      <span>Add Permit Details</span>
                      <button
                        className="anime-btn addPlan"
                        disabled={!disbleButton}
                        onClick={() => setIsOpenModal2(true)}
                      >
                        Add Details
                      </button>
                    </div>

                    {[...new Set(crossBordrCountry)]?.map((cty) => {
                      return (
                        <>
                          <div className="crosstrailerButtonBox">
                            <span>{cty}</span>
                            <button className="addCrossAction">Edit</button>
                            <button className="addCrossAction">Delete</button>
                          </div>

                          <Table bordered hover responsive>
                            <thead>
                              <tr>
                                <th>Document</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            {crossBorderList
                              ?.filter((filtr) => filtr.country_name === cty)
                              ?.map((item, i) => (
                                <tbody>
                                  <tr
                                    className={
                                      i % 2 === 0
                                        ? "table-primary"
                                        : "table-success"
                                    }
                                  >
                                    <td scope="row">
                                      {
                                        documentList?.filter(
                                          (doc) =>
                                            doc?.id === item?.document_type
                                        )[0]?.name
                                      }
                                    </td>
                                    <td>{item?.cross_border_permit_date}</td>
                                    <td>Active</td>
                                  </tr>
                                </tbody>
                              ))}
                          </Table>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          }
        />
      )}
      <Modal
        Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />

      <InputModal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(!isOpenModal)}
        headerName={isEdit ? "Edit Category" : "Add Trailer"}
        submitLabel={isEdit ? "Update Category" : "Continue"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        inputProps={
          <InputField
            className="modal_input orderModal_Input"
            label={isEdit ? "Edit Category" : "Trailer Number"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Enter Category name"
            name="name"
            focus={!!inputError.name}
            error={inputError.name}
            value={inputValue.name}
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
          />
        }
      />

      <InputModal
        isOpen={isOpenModal2}
        toggle={() => setIsOpenModal2(!isOpenModal2)}
        headerName={isEdit ? "Edit Category" : "Add Cross Border Permit"}
        submitLabel={isEdit ? "Update Category" : "Continue"}
        onSubmit={(e) => addCrossBorderDocument(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        inputProps={
          <>
            {" "}
            <div>
              <span>
                <Label className="FormLableKaran">Country</Label>
              </span>
              <div>
                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Country"
                  name="fleet_type"
                  options={countryList ? countryList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!formError?.country}
                  error={formError?.country}
                  selectedOption={formOrderName?.country}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, country: data });
                    handleOrderChange("country", data?.value);
                  }}
                />
              </div>
            </div>
            <div>
              <span>
                <Label className="FormLableKaran">Document Name</Label>
              </span>
              <div>
                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Document"
                  name="fleet_type"
                  options={documentList ? documentList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!formError?.document_type}
                  error={formError?.document_type}
                  selectedOption={formOrderName?.document_type}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, document_type: data });
                    handleOrderChange("document_type", data?.value);
                  }}
                />
              </div>
            </div>
            <div>
              <span>
                <Label className="FormLableKaran">Expire date</Label>
              </span>
              <div>
                <CustomDatePicker
                  type="date"
                  value={formOrderName?.cross_border_permit_date}
                  max={formatDate(new Date())}
                  focus={!!formError?.cross_border_permit_date}
                  error={formError.cross_border_permit_date}
                  name="cross_border_permit_date"
                  onSelect={({ target: { name, value } }) => {
                    handleOrderChange(name, value);
                    setFormOrderName({
                      ...formOrderName,
                      cross_border_permit_date: value,
                    });
                  }}
                  className="date-input"
                // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                // className="date-input"
                />
              </div>
            </div>
          </>
        }
      />

      {openModal && (
        <InputModal
          isOpen={openModal}
          headerName={isEdit ? "Edit Branch" : "Add Branch"}
          toggle={() => setOpenModal(!openModal)}
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Branch" : "Branch Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Branch Name"
              name="addBranch"
              focus={!!formError.addBranch}
              error={formError.addBranch}
              value={formOrderValue.addBranch}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}
    </>
  );
};
