import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import Select from 'react-select';

import { modalInputSchema } from "../../helper/errorSchema";
import { breadcrumbsMenu, checkFormError, pageCount } from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import CheckboxField from "../../Component/Common/CheckboxField";
import { addDocumentTypeApi, deleteDocumentTypeApi, getDocumentType, updateActiveDocument, updateDocumentTypeApi } from "../../services/documentTypeService";
import mytruck from "../../../image/tanker.png"
import { getStorage } from "../../helper/storage";
import { getBranch } from "../../services";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";


const dummyDrop = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
  { label: "three", value: 3 },
];

const branchObj = {
  offset: 0,
  currentPage: 0,
  search: "",
  ordering: "-id",
  limit: "all",
};

export const DocumentType = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id"
  };
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("")
  const [isLoadding, setIsLoadding] = useState(false)
  const [branchList, setBranchList] = useState();
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [disableSelectedBranches, setDisableSelectedBranches] = useState([]);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    limit: 20,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Document Type"))[0]?.
    permissions : []
  const [loaderForList, setLoaderForList] = useState(false)
  const { data, isSuccess } = useQuery(
    ["getDocumentType", debouncedFilter],
    () => getDocumentType(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  useEffect(() => {
    if (data) {
      setLoaderForList(false)
    } else {
      setLoaderForList(true)
    }
  }, [data])
  const [inputValue, setInputValue] = useState({
    name: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
  });

  useEffect(() => {
    getBranchList();
  }, [])

  const closeModal = () => {
    setIsOpen(true);
    setIsEdit(false);
    setInputValue("");
    setInputError("");
    setSelectedBranches([]);
  };

  const clearAll = () => {
    setSelectedBranches("");
  }

  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#21888b',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#21888b',
        color: 'red',
      },
    }),
  };

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    const branches = res?.results.map(branch => ({ label: branch.name, value: branch.id }));
    setBranchList(branches);
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { name: inputValue.name },
      modalInputSchema
    );
    if (validationResult === true) {
      setIsLoadding(true)
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd();
      }
    } else {
      setIsLoadding(false)
      setInputError(validationResult);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value }
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };

  const handleAdd = () => {
    const payload = {
      name: inputValue.name,
      branch: selectedBranches.map(branch => branch.value)

    };
    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };
  const handleEditAction = (item) => {
    setDisableInp({ name: item.name });
    setInputValue({ id: item.id, name: item.name });
    setIsOpen(true);
    setIsEdit(true);
    setInputError("");
    const selected = item.branch.map(branchId => branchList?.find(branch => branch.value === branchId));
    setSelectedBranches(selected);
    setDisableSelectedBranches(selected)
  };

  const handleUpdate = () => {
    const payload = {
      id: inputValue.id,
      name: inputValue.name,
      branch: selectedBranches.map(branch => branch.value)

    };
    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addDocumentTypeApi, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsLoadding(false)
        onSuccessResponse(res);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false)
      }
    },
  });

  const updateMutation = useMutation(updateDocumentTypeApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false)
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false)
      }
    },
  });

  const deleteMutation = useMutation(deleteDocumentTypeApi, {
    onSuccess: (res) => {

      if (res?.success) {
        onSuccessResponse(res);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data)
  }

  const onSuccessResponse = (message) => {
    toast.success(message?.message ? message?.message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getDocumentType");
    setIsOpen(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const updateTagStatus = (item) => {

    const payload = {
      id: item.id,
      is_active: !item?.is_active
    }
    updateActiveMutation.mutate(payload);
  }
  const updateActiveMutation = useMutation(updateActiveDocument, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const column = [
    { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },
    { heading: "Document Name", className: "sorting_asc", value: "name", sortName: "" },
    { heading: "Branch", className: "sorting_asc", value: "branchName", sortName: "" },
    { heading: "Action", className: "sorting", value: "action", sortName: "" },
  ];

  const rowDataList = () => {
    console.log("nakul", data?.results);

    const listArray = data?.results?.map((item, i) => {
      const branchNames = item.branch?.map(branchId => {
        const branch = branchList?.find(b => b.value === branchId);
        return branch ? branch.label : "";
      }) || [];

      return {
        ...item,
        branchName: branchNames.join(', '),
        check: (
          <>
            <CheckboxField type="checkbox" name={item?.title} />
          </>
        ),
        sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
        icon: (
          <>
            <img src={item?.icon} alt={item?.name} />
          </>
        ),
        action: (
          <>
            <div className="actionBtn">
              <SwitchField
                onSwitchChange={() => updateTagStatus(item)}
                value={item.is_active}
              />

              {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span> : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
              {userLogin?.user_type === 1 ? <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span> : permissions[4] && <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>}

              {/* {userLogin?.user_type === 1 ? (
                <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
              ) : (
                permissions[3] && (
                  <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
                )
              )}
              {userLogin?.user_type === 1 ? (
                <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />
              ) : (
                permissions[4] && (
                  <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />
                )
              )} */}
            </div>
          </>
        ),
      };
    });

    return listArray;
  };

  return (
    <>
      <div className="ques_container">
        <Breadcrumbs
          active="Document Type"
          action={
            userLogin?.user_type === 1 ? <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Document Type
            </Button> : permissions[2] && <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Document Type
            </Button>
          }
        />
        <div className="anvTask_prog">
          <div className="anvInputs&fillters">


            <div className="inr_flex_mini ">
              <Dropdown
                placeholder="Enable"
                option={dummyDrop}
                valueText="value"
                labelText="label"
                label="Enable"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
              />
            </div>

            <div className="inr_flex">
              <InputField placeholder="Search"
                label="Search"
                className="anvSearch_input"
                value={filter?.name}
                onChange={(e) =>
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    name: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div className="table-responsive">
            <CustomTable
              column={column}
              data={rowDataList()}
              tableLoading={loaderForList}
            />
          </div>
        </div>
      </div>

      {data?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))
                setInputValue({ ...inputValue, count: data });
              }}

            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(data?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen}
        headerName={isEdit ? "Edit Document Type" : "Add Document Type"}
        toggle={() => setIsOpen(!isOpen)}
        submitLabel={isEdit ? "Update Document" : "Save"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.name === disableInp.name && selectedBranches === disableSelectedBranches : ""}
        isLoadding={isLoadding}
        inputProps={
          <>
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Document" : "Document Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Document Name"
              name="name"
              focus={!!inputError.name}
              error={inputError.name}
              value={inputValue.name}
              onChange={({ target: { name, value } }) => handleOnChange(name, value)} />
            <div className="select-wrapper">
              {/* <label className="modal_label1">
                {isEdit ? "Edit Branch" : "Branch Name"} <span className="style_for_star">*</span>
              </label> */}
              <Select
                isMulti
                name="branches"
                // label={isEdit ? "Edit Branch" : "Branch Name"}
                label="Branch Name"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
                options={branchList}
                value={selectedBranches}
                onChange={setSelectedBranches}
                className="basic-multi-select"
                // classNamePrefix="select"
                placeholder="Select Branches"
                styles={customStyles}
              />
            </div>
          </>

        }

      />
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
    </>
  );
};
