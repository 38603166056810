import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const InputModalNew = (props) => {
    const {
        className,
        customeClassName,
        isOpen,
        toggle,
        onSubmit,
        headerName,
        submitLabel,
        inputProps,
        disabled,
        size = "md",
        isLoadding,
        btnwidth,
        submitLabel2,
        btnwidthNew,
        onSubmitReject
    } = props;

    return (
        <div>
            <Modal
                size={size}
                isOpen={isOpen}
                toggle={toggle}
                className={className}
                backdrop
                keyboard
                centered={true}
            >
                <ModalHeader toggle={toggle}>{headerName}</ModalHeader>
                <ModalBody>
                    <div className={customeClassName ? `${customeClassName}` : "ModalFlexDiv"}>
                        <div className=" flex labelwithinput">{inputProps}</div>

                    </div>
                </ModalBody>
                <ModalFooter className="footerOfModalNew">
                { submitLabel2 && <>
                    {isLoadding ? <Button
                        className={`anime-btn ques_row_btn ${submitLabel2 ? btnwidthNew:btnwidth}`}
                        // onClick={onSubmit}
                        disabled={true}
                    >
                       Rejecting...
                    </Button> : <Button
                        className={`anime-btn ques_row_btn ${submitLabel2 ? btnwidthNew:btnwidth}`}
                        onClick={onSubmitReject}
                        // disabled={disabled}
                    >
                        {submitLabel2}
                    </Button>}
                    </>}

                    {isLoadding ? <Button
                        className={`anime-btn ques_row_btn ${ submitLabel2 ? btnwidthNew:btnwidth}`}
                        // onClick={onSubmit}
                        disabled={true}
                    >
                        Creating..
                    </Button> : <Button
                        className={`anime-btn ques_row_btn ${submitLabel2 ? btnwidthNew:btnwidth}`}
                        onClick={onSubmit}
                        disabled={disabled}
                    >
                        {submitLabel}
                    </Button>}

                   


                </ModalFooter>
            </Modal>
        </div>
    );
};

export default InputModalNew;
