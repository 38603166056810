import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Button, Modal, ModalBody, ModalFooter, Label } from "reactstrap";
import Dropdown from "../../Component/Common/Dropdown";
import CommonSignature from "../../Component/Common/CommonSignature";
import useDebounce from "../../helper/customhook";
import { dashboardDataRequest, getBranch } from "../../services";
import streeing from "../../../image/steering.png";
import locationIng from "../../../image/location.png";
import stoping from "../../../image/stop.png";
import orderClip from "../../../image/orderClip.png";

import {
  TimeDecoder,
  dashboradSecondTabList,
  dashboradTabList,
  orderTabList,
} from "../../helper";
import { Tabs } from "../../Component/Common/Tabs";
import { InputField } from "../../Component/Common/InputField";
import SwitchField from "../../Component/Common/SwitchField";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";

import GoogleMapComponent, {
  MyMapComponent,
} from "../../Component/Common/GoogleMapComponent";
import { getAllFleet, getMapTrackList } from "../../services/fleetTypeService";

export function Dashboard() {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };

  const defaultCenter = { lat: 18.6696553, lng: 35.5273354 };
  const [updatebranchList, setUpadteBranchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [switchValue, setSwitchValue] = useState(false);
  const [activeTabFleet, setActiveTabFleet] = useState(1);
  const toggle = () => setOpen(!open);
  const [latLOng, setLatLOng] = useState(defaultCenter);
  const [zoomMap, setZoomMap] = useState(3.5);

  const [activeWindow, setActiveWindow] = useState("");
  const [mapAdress, setMapAdress] = useState([]);
  const [mapListOFList, setMapListOFList] = useState([]);
  const [fleetList, setFleetList] = useState([]);
  const [updateMapArray, setUpdateMapArray] = useState();


  const handleSelectChange = ({ target: { value } }) => {
    setFocusAfterClose(JSON.parse(value));
  };
  const [status, setStatus] = useState(1);

  const [filter, setFilter] = useState({
    dashboard_type: "Admin",
    limit: 20,
    status: status,
    ...obj,
  });

  const [subFilter, setSubFilter] = useState({
    status: status,
  });

  const debouncedFilter = useDebounce(filter, 500);
  const debouncedSubFilter = useDebounce(subFilter, 500);

  // const { data, isSuccess } = useQuery(
  //   ["dashData", debouncedFilter],
  //   () => dashboardDataRequest(debouncedFilter, filter),
  //   { enabled: Boolean(debouncedFilter) }
  // );

  const { data: branchList } = useQuery(
    ["getBranch", debouncedFilter],
    () => getBranch(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  // const { data: mapTrackList } = useQuery(
  //   ["getMapTrackList", debouncedSubFilter],
  //   () => getMapTrackList(debouncedSubFilter, subFilter),
  //   { enabled: Boolean(debouncedSubFilter) }
  // );

  const getFleetsList = async (data) => {
    const res = await getAllFleet(data);
    setFleetList(res?.results);
  };

  // useEffect(() => {
  //   const updatedArray1 = mapTrackList?.results?.fleet_point_data.map(
  //     (obj1, index) => {
  //       const matchingObj2 = mapTrackList?.results?.fleet_statistics_data.find(
  //         (obj2, index2) => index2 === index
  //       );

  //       if (matchingObj2) {
  //         // Add the "age" property to obj1
  //         return { ...obj1, ...matchingObj2 };
  //       }

  //       return obj1;
  //     }
  //   );



  //   setUpdateMapArray(updatedArray1);

  //   updatedArray1?.map((item, index) => {
  //     fetchAddress(
  //       item?.latend,
  //       item?.lngend,
  //       "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
  //       index,
  //       updatedArray1
  //     );
  //   });
  // }, [mapTrackList]);

  const fetchAddress = async (
    latitude,
    longitude,
    apiKey,
    index,
    updatedArray1
  ) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        let newExpenseList = [...updatedArray1];
        newExpenseList[index][
          "address"
        ] = `${data.results[0].address_components[0].long_name}, ${data.results[0].address_components[1].long_name}, ${data.results[0].address_components[2].long_name} , ${data.results[0].address_components[3].long_name} , ( ${data.results[0].address_components[4].long_name} )`;

        await setMapListOFList(newExpenseList);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    if (branchList?.results) allBranch(branchList);
  }, [branchList]);

  const column = [
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
    {
      Header: "Fleet Movement",
      accessor: "spead_mov",
    },
    {
      Header: "Current Location",
      accessor: "address",
    },
    {
      Header: "Ignition",
      accessor: "ignition_en",
    },
    {
      Header: "Driver",
      accessor: "driver_name",
    },
    {
      Header: "Trip Assigned",
      accessor: "trip_number",
    },
  ];
  const columnFleet = [
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
  ];
  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      name: item?.name,
      designation: item?.designation,
      contact: item?.contact_number,
      spead_mov: item?.speed > 0 ? `${item?.speed}km/hr` : "Stop",
      ignition_en: item?.speed > 0 ? "ON" : "OFF",
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));

    return listArray;
  };
  const allBranch = async () => {
    const branchData = await branchList?.results?.map((item) => {
      return {
        id: item.id,
        is_active: item?.is_active,
        name: item?.name,
        response_message: item?.response_message,
      };
    });

    setUpadteBranchList([
      {
        id: "all",
        is_active: true,
        name: "All Branch",
        response_message: "All Branch",
      },
      ...branchData,
    ]);
  };

  const handleActive = (data) => {
    // setLoaderForList(true)

    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleActiveFleet = (data) => {
    setActiveTabFleet(data?.value);
    setZoomMap(3.5)
    if (data?.value == 2) {
      setSubFilter({
        status: data.value,
        running: true,
      });
    }
    if (data?.value == 3) {
      setSubFilter({
        status: data.value,
        stopped: true,
      });
    }
    if (data?.value == 1) {
      setSubFilter({
        status: data.value,
      });
    }

    if (data?.value == 4) {
      setSubFilter({
        status: data.value,
      });
      getFleetsList({ no_device: true, limit: 20 });
    }
  };

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={dashboradTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
      </div>
      <div className="ques_container">
        <div className="dashboard_container">
          {filter.status === 1 && (
            <div className="dashbordCountBoxFirst">
              <div className="anvOt_inputField ">
                {/* <span>
                  <Label className="FormLableKaran">Branch</Label>
                </span> */}
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Branch"
                    label="Select Branch"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="Branch"
                    options={updatebranchList ? updatebranchList : []}
                    valueText="id"
                    labelText="name"
                    // focus={!!formError?.country}
                    // error={formError?.country}
                    // selectedOption={formOrderName?.country}
                    // components={{ Menu: CustomControl }}
                    setSelectedOption={(data) => {
                      // setFormOrderName({ ...formOrderName, country: data })
                      // handleOrderChange("country", data?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {filter.status === 2 && (
            <div className="dashboard_iner_container">
              <div className="fleet_moniter_container">
                <div className="fleet_moiter">
                  <InputField
                    placeholder="Search"
                    className="anvSearch_input"
                    label="Search"
                    value={filter?.search}
                    onChange={(e) =>
                      setFilter((s) => ({
                        ...s,
                        ...obj,
                        search: e.target.value,
                      }))
                    }
                  />
                  <div className="customerStatus">
                    <SwitchField
                      onSwitchChange={(e) => setSwitchValue(e.target.checked)}
                      value={switchValue}
                    />
                    Show Map
                  </div>
                </div>

                <Tabs
                  customClassName="fleet_moniter_dash"
                  TabList={dashboradSecondTabList}
                  handleActive={handleActiveFleet}
                  activeTab={activeTabFleet}
                />
              </div>
              {switchValue && (
                <div className="switch_div">
                  {subFilter.status !== 4 && (
                    <div className="details_div">
                      {mapListOFList?.map((item) => (
                        <div
                          className="details_card"
                          onClick={() => {
                            setZoomMap(20)
                            setActiveWindow(item?.tracker_number);
                            setLatLOng({
                              lat: parseFloat(item?.latend),
                              lng: parseFloat(item?.lngend),
                            });

                          }}
                        >
                          <h3>{`${item?.truck_number} - ${item?.tracker_number}`}</h3>
                          <div className="driver_trip">
                            <div className="stop_div_trip">
                              <img src={orderClip} />
                              <span>
                                Trip-{" "}
                                {item?.trip_number
                                  ? item?.trip_number
                                  : "Not Assigned"}{" "}
                                {`${item?.truck_number} - ${item?.tracker_number}`}
                              </span>
                            </div>
                            <div></div>

                          </div>

                          <div className="stop_trip">
                            <div className="stop_div_trip">
                              <img src={stoping} />
                              <span>Stopped since: </span>
                            </div>
                            <span>{TimeDecoder(item?.parktime)} </span>

                          </div>

                          <div className="driver_trip">
                            <div className="stop_div_trip">
                              <img src={streeing} />
                              <span> Driver- {item?.driver_name}</span>
                            </div>
                            <span>{`${item.speed}km/h`}</span>
                          </div>
                          <div className="driver_trip">
                            <div className="stop_div_trip">
                              <img src={locationIng} />
                              <span>{item?.address}</span>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {subFilter.status == 4 && (
                    <div className="details_div">
                      {fleetList?.map((item) => (
                        <div className="details_card">
                          <h6>{`${item?.truck_number} `}</h6>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="map_div">
                    {zoomMap && <GoogleMapComponent
                      mapList={mapListOFList}
                      zoom={zoomMap}
                      latLOng={latLOng}
                      activeWindow={activeWindow}
                    />}
                  </div>
                </div>
              )}
              {!switchValue && (
                <>
                  {subFilter.status !== 4 && (
                    <CustomReactTable
                      columns={column}
                      data={rowDataList(updateMapArray ? updateMapArray : [])}
                    // tableLoading={loaderForList}
                    />
                  )}
                  {subFilter.status === 4 && (
                    <div className="main_container_table">
                      <CustomReactTable
                        columns={columnFleet}
                        data={rowDataList(fleetList ? fleetList : [])}
                      // tableLoading={loaderForList}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {filter.status === 3 && <div className="dashbordCountBoxFirst"></div>}
          {/* <div className="dashbordCountBox">

            <div className="dashboard_div1">
              <div className="dash_subdiv">
                <span className="dash_txt">Total Startups</span>
                <span className="dash_num">500</span>
              </div>

              <div className="dash_subdiv">
                <span className="dash_txt">Verified</span>
                <span className="dash_num">300</span>
              </div>

              <div className="dash_subdiv">
                <span className="dash_txt">Not Verified</span>
                <span className="dash_num">200</span>
              </div>
            </div>
            <div class="dashboard_div1">
              <div className="dash_subdiv">
                <span className="dash_txt">Investors</span>
                <span className="dash_num">500</span>
              </div>
            </div>
          </div>


          <div className="dashbordCountBox">

            <div className="dashboard_div1">
              <div className="dash_subdiv">
                <span className="dash_txt">No. of Task</span>
                <span className="dash_num">500</span>
              </div>

              <div className="dash_subdiv">
                <span className="dash_txt">In Progress</span>
                <span className="dash_num">300</span>
              </div>

              <div className="dash_subdiv">
                <span className="dash_txt">Completed</span>
                <span className="dash_num">200</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
