import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import {
  addCategory,
  deleteCategory,
  getBranch,
  getCommunity,
  getTags,
  updateCategory,
} from "../../services";
import { toast } from "react-toastify";
import {
  addDriverValidation,
  addExpenseFundValidation,
  addExpenseValidation,
  addLocationValidation,
  addOrderValidation,
  addResearchSchema,
  addResearchSchemaForImage,
  addTruckFleet,
  editDriverValidation,
  MarkedDocumentVal,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  driverTabList,
  formatDate,
  formatDateList,
  getDateTime,
  getTimeAmPm,
  handleKeyDown,
  locationTabList,
  orderTabList,
  pageCount,
  tripsTabList,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import CheckboxField from "../../Component/Common/CheckboxField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  singleNewDetails,
  updateActiveResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Link } from "react-router-dom";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import {
  acceptAndRejectStatus,
  addFleetsForOrder,
  addOrder,
  getAllFleetforOrder,
  getAllOrder,
  getdryCargo,
  gettankersCsv,
} from "../../services/orderService";
import { getProduct } from "../../services/productService";
import {
  addDriver,
  deleteDriver,
  getAllDriver,
  singleDriverDetails,
  updateActiveDriver,
  updateDriver,
} from "../../services/driverService";
import mytruck from "../../../image/tanker.png";
import InputModalNew from "../../Component/Common/ConstomModal/InputModalNew";
import { MoreExpanse, MoreExpanseFuel } from "./MoreExpanse";
import { MoreFuel } from "./MoreFuel";
import { getAllRoute, singleRouteDetails } from "../../services/routeService";
import {
  getAllTrips,
  getTripCountApi,
  getTripCsv,
  updateTrip,
  updateTripApi,
  updateTripExFuelApi,
  updateTripFundApi,
  verifyOtpApi,
} from "../../services/tripService";
import { addCurrencyApi, getCurrency } from "../../services/currencyService";
import { VscFilePdf } from "react-icons/vsc";
import CommonRoute from "../../Component/Common/CommonRoute";
import { components } from "react-select";
import { getStorage, setStorage } from "../../helper/storage";
import { FaPencilAlt } from "react-icons/fa";
import CommonSignature from "../../Component/Common/CommonSignature";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
// import GoogleMapComponent from "../../Component/Common/GoogleMapComponent";
import { MdOutlineDelete } from "react-icons/md";
import NewGoogleMapComp from "../../Component/Common/MapComponent/NewGoogleMapComp";
import { GoogleMapsLoader } from "../../Component/Common/MapComponent/GoogleMapsLoader";

export const Trips = () => {
  const formObj = {
    number: "",
    date: formatDate(new Date(), "-"),
  };

  const orderObj = {
    customer: "",
    date: getTimeAmPm(new Date()),
    comment: "",
    document: [],
    driverSignature: "",
    customerSignature: "",
    route: "",
    addCurrency: "",
    diverCheckBox: "",
    approveCheckbox: "",
    count: "",
    loaded_qty_fe: "",
    offloaded_qty_fe: "",
  };

  const orderObjName = {
    customer: "",
    date: new Date(),
    comment: "",
    document: [],
    driverSignature: "",
    customerSignature: "",
    route: "",
    addCurrency: "",
    diverCheckBox: "",
    approveCheckbox: "",
    count: "",
    loaded_qty_fe: "",
    offloaded_qty_fe: "",
  };

  const Requirement = [
    {
      heading: "Trip/ Nomination",
      className: "sorting",
      value: "nomination",
      sortName: "",
    },
    {
      heading: "Order No.",
      className: "sorting_asc",
      value: "orderNo",
      sortName: "",
    },

    {
      heading: "Order Trip Number.",
      className: "sorting_asc",
      value: "order_trip_number",
      sortName: "",
    },

    { heading: "Date", className: "sorting_asc", value: "date", sortName: "" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customerName",
      sortName: "",
    },
    { heading: "Route", className: "sorting_asc", value: "routeName", sortName: "" },
    {
      heading: "Fleet Number",
      className: "sorting_asc",
      value: "fleetNo",
      sortName: "",
    },
    {
      heading: "Driver",
      className: "sorting_asc",
      value: "driverName",
      sortName: "",
    },
    {
      heading: "Fund Status",
      className: "sorting_asc",
      value: "fundAllocationStatus",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "status",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const financialApprovalColumn = [
    {
      heading: "Trip/ Nomination",
      className: "sorting",
      value: "nomination",
      sortName: "",
    },
    {
      heading: "Order No.",
      className: "sorting_asc",
      value: "orderNo",
      sortName: "",
    },

    {
      heading: "Order Trip Number.",
      className: "sorting_asc",
      value: "order_trip_number",
      sortName: "",
    },

    { heading: "Date", className: "sorting_asc", value: "date", sortName: "" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customerName",
      sortName: "",
    },
    { heading: "Route", className: "sorting", value: "routeName", sortName: "" },
    {
      heading: "Fleet Number",
      className: "sorting_asc",
      value: "fleetNo",
      sortName: "",
    },
    {
      heading: "Driver",
      className: "sorting_asc",
      value: "driverName",
      sortName: "",
    },
    {
      heading: "Fund Status",
      className: "sorting_asc",
      value: "fundAllocationStatus",
      sortName: "",
    },
  ];
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    customer_name: "",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  const routeObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const driverObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const queryClient = useQueryClient();
  const userLogin = getStorage("logged-in");
  const permissions = userLogin?.user_permissions.length
    ? Object.values(userLogin?.user_permissions)?.filter((i) =>
      i?.module_name?.includes("Trip")
    )[0]?.permissions
    : [];
  const statusTag = getStorage("statusTag");

  const result = userLogin?.user_branches?.join(",");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: statusTag?.id ? statusTag?.id : status,
    order_number: statusTag?.orderId ? statusTag?.orderId : "",
    branch_uuid: result,
    customer_id: userLogin?.user_type === 2 ? userLogin?.id : "",
    branch_uuid: userLogin?.user_branches?.map((obj) => obj).join(","),
    ...obj,
  });
  const [branchList, setBranchList] = useState();

  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [countryList, setCountryList] = useState();
  const [currencyList, setCurrencyList] = useState([]);
  const [loaderForList, setLoaderForList] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenReciSign, setIsOpenReciSign] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [isOpenModal3, setIsOpenModal3] = useState(false);
  const [isOpenModal4, setIsOpenModal4] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openRoute, setOpenRoute] = useState(false);
  const [isFundToggle, setIsFundToggle] = useState(false);
  const [customerValues, setCustomerValues] = useState({
    value: "",
    label: "",
  });
  const [documentFile, setDocumentFile] = useState([]);
  const [nameFile, setNameFile] = useState([]);

  const [routeDetails, setRouteDetails] = useState("");
  const debouncedFilter = useDebounce(filter, 500);
  const myRefname = useRef(null);
  const driverRef = useRef(null);
  const customerRef = useRef(null);
  const [preview, setPreview] = useState([]);
  const [previewDriver, setPreviewDriver] = useState("");
  const [fundDriver, setFundDriver] = useState("");
  const [approveDriver, setApproveDriver] = useState("");
  const [reciverDriver, setReciverDriver] = useState("");
  const [previewCustomer, setPreviewCustomer] = useState("");
  const [routeList, setRouteList] = useState();
  const [routeList2, setRouteList2] = useState();
  const [nominationNumber, setNominationNumber] = useState();
  const [driverList, setDriverList] = useState();
  const [openDrower, setOpenDrower] = useState({
    openPad: false,
    name: "",
  });
  const [tripCount, setTripCount] = useState();

  const [textState, setTextState] = useState(0);
  const [orderWayType, setOrderWayType] = useState(0);
  const [orderCustomerID, setOrderCustomerID] = useState(0);
  const [signature, setSignature] = useState([]);
  const [statusOForder, setStatusOForder] = useState(0);
  const [openMap, setOpenMap] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [fundPendingCount, setFundPendingCount] = useState(0);
  const [pendingFilterToggle, setPendingFilterToggle] = useState(false);
  const [tripDocument, setTripDocument] = useState([])
  let defultExpense = {
    category: "",
    currency: "",
    description: "",
    modeOfPayment: 2,
    amount: "",
    route: formOrderValue.route,
  };

  let defultFuel = {
    fuel_station: "",
    description: "",
    qty: "",
    route: formOrderValue.route,
  };

  const [expenseList, setExpenseList] = useState([defultExpense]);
  const [fuelList, setFuelList] = useState([defultFuel]);
  const [reciveSignatureFile, setReciveSignatureFile] = useState();
  const [driverSignatureFile, setDriverSignatureFile] = useState();
  const [driveFunSignatureFile, setDriveFunSignatureFile] = useState();
  const [aprroveSignatureFile, setAprroveSignatureFile] = useState();
  const [fundAllocationStatus, setFundAllocationStatus] = useState();
  const [isDisablePopUp,setDisablePop]= useState(false)

  useEffect(() => {
    if (statusTag?.id) {
      setFilter({ ...filter, order_number: statusTag?.orderId });
    }
  }, [statusTag?.id]);

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      orderNo: item?.order_detail?.order_number,
      customerName: item?.order_detail?.customer,
      driverName: item?.order_detail?.driver,
      fundAllocationStatus: (
        <div className="statusofOrder">
          {item?.fund_allocation_status === 0 ? (
            <span>Pending</span>
          ) : item?.fund_allocation_status === 1 ? (
            <span>Allocated</span>
          ) : (
            ""
          )}
        </div>
      ),
      nomination: (
        <div
          className="driverName"
          onClick={() => {
            setStorage("tripID", item.id);
            navigate("/trip-details");
          }}
        >
          {item?.order_detail?.nomination_number}
        </div>
      ),
      serial: i + 1,
      fleetNo: (
        <div className="fleetNameDiv">
          <div className="fleetNameList" onClick={() => setOpenMap(true)}>{item?.fleet_number}</div>
          {/* <span >Mozambique</span> */}
        </div>
      ),

      status: (
        <div className="statusofOrder">
            {item?.status === 1 ? (
              <span>Client Approval</span>
            ) : item?.status === 2 ? (
              <span>Client Approved</span>
            ) : item?.status === 3 ? (
              <span>Loading Point</span>
            ) : item?.status === 4 ? (
            <span>At Loading</span>
            ) : item?.status === 6 ? (
              <span>Border Clearance</span>
            ) : item?.status === 5 ? (
              <span>Fund Allocation</span>
            ) : item?.status === 7 ? (
              <span>Off Loading Point</span>
            ) : item?.status === 8 ? (
              <span>Delivered</span>
            ) : item?.status === 9 ? (
              <span>In Return</span>
            ) : item?.status === 10 ? (
              <span>Completed</span>
            ) : item?.status === 11 ? (
              <span>Cancel</span>
            ) : (
              ""
            )}
        </div>
      ),

      // route: routeList?.find((i) => i.id === item?.route)?.route_name,
      routeName:(
        <div className="cursorPointer" onClick={()=>setDisablePop(item?.route)}>{item?.route_name}</div>
      ),
      product: item?.product_detail?.name,

      date: formatDateList(item?.added_on),
      qty: `${item?.qty} ${item?.product_detail?.product_type === 1 ? "Ltr" : "Ton"
        }`,

      action: (
        <>
          <div className="actionBtn">
            <div className="requiermentBtn">
              {!(item.status === 10 || item.status === 11 || item.status === -1) && (
                <button
                  className="accept_odr btn-color"
                  onClick={() => {
                    setOrderId(item?.order_detail?.order_id);
                    if (item?.status == 5) {
                      const docPrev = item.trip_documents.map((doc) => {
                        return {
                          imageView: doc?.contract,
                          name: doc?.document_name,
                        };
                      });
                      setPreview(docPrev);
                      const arraynew = [...nameFile];
                      item.trip_documents.map((doc) => {
                        arraynew.push(doc.document_name);
                      });
                      setNameFile(arraynew);
                    }
                    handleButtonClick(
                      item.status === item.fund_allocation_state &&
                        item.fund_allocation_status === 0
                        ? 4
                        : item?.status === 4
                          ? item?.status + 1
                          : item?.status,
                      item?.order_detail?.order_type,
                      item?.trip_documents
                    );
                    setStatusOForder(item.status);
                    setUpdateId(item?.id);
                    setOrderCustomerID(item?.order_detail?.customer_id);
                    setCustomerValues({
                      value: item?.order_detail?.customer_id,
                      label: item?.order_detail?.customer,
                    });

                    setOrderWayType(item?.order_detail?.order_type);
                    if (item.status === 5 || item.status === 4) {
                      setFormOrderValue({
                        ...formOrderValue,
                        route: item?.route,
                        driverName: item?.order_detail?.driver,
                        truckNumer: item?.order_detail?.truck_number,
                      });
                      let details = routeList?.find(
                        (i) => i.id === item?.route
                      );
                      setFormOrderName({
                        ...formOrderName,
                        route: {
                          value: details?.id,
                          label: details?.route_name,
                        },
                      });
                    }
                    setTripDocument(item?.trip_documents)
                  }}
                >
                  {handleButtonText(
                    item.status === item.fund_allocation_state &&
                      item.fund_allocation_status === 0
                      ? 4
                      : item?.status == 4
                        ? item?.status + 1
                        : item?.status,
                    item?.order_detail?.order_type,
                    item?.trip_documents
                  )}
                </button>
              )}
              {(item.status === 1 || item.status === 2) && (
                <button
                  className="cancel_ord "
                  onClick={(e) => {
                    setUpdateId(item?.id);
                    setIsOpenDelete(true);
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </>
      ),
      sno: i + 1 + filter.limit * filter.currentPage,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return userLogin?.user_type !== 1 && userLogin?.user_branches?.length === 0
      ? []
      : listArray;
  };

  const pendingRowDataList = () => {
    const pendingListArray = dataList?.results
      ?.filter((item) => item?.fund_allocation_status === 0)
      ?.map((item, i) => ({
        ...item,
        orderNo: item?.order_detail?.order_number,
        customerName: item?.order_detail?.customer,
        driverName: item?.order_detail?.driver,
        fundAllocationStatus: (
          <div className="statusofOrder">
            <span>Pending</span>
          </div>
        ),
        nomination: (
          <div
            className="driverName"
            onClick={() => {
              setStorage("tripID", item.id);
              navigate("/trip-details");
            }}
          >
            {item?.order_detail?.nomination_number}
          </div>
        ),
        serial: i + 1,
        fleetNo: (
          <div className="fleetNameDiv">
            <div className="fleetNameList" onClick={() => setOpenMap(true)}>{item?.fleet_number}</div>
            {/* <span >Mozambique</span> */}
          </div>
        ),
        status: (
          <div className="statusofOrder">
            {item?.status === 1 ? (
              <span>Client Approval</span>
            ) : item?.status === 2 ? (
              <span>Client Approved</span>
            ) : item?.status === 3 ? (
              <span>Loading Point</span>
            ) : item?.status === 4 ? (
              <span>FM Approval</span>
            ) : item?.status === 6 ? (
              <span>Border Clearance</span>
            ) : item?.status === 5 ? (
              <span>Fund Allocation</span>
            ) : item?.status === 7 ? (
              <span>Off Loading Point</span>
            ) : item?.status === 8 ? (
              <span>Delivered</span>
            ) : item?.status === 9 ? (
              <span>In Return</span>
            ) : item?.status === 10 ? (
              <span>Completed</span>
            ) : item?.status === 11 ? (
              <span>Cancel</span>
            ) : (
              ""
            )}
          </div>
        ),
        // route: routeList?.find((i) => i.id === item?.route)?.route_name,
        routeName:(
          <div className="cursorPointer" onClick={()=>setDisablePop(item?.route)}>{item?.route_name}</div>
        ),
        product: item?.product_detail?.name,
        date: formatDateList(item?.added_on),
        qty: `${item?.qty} ${
          item?.product_detail?.product_type === 1 ? "Ltr" : "Ton"
        }`,
        action: (
          <>
            <div className="actionBtn">
              <div className="requiermentBtn">
                {!(item.status === 10 || item.status === 11) && (
                  <button
                    className="accept_odr btn-color"
                    onClick={() => {
                      setOrderId(item?.order_detail?.order_id);
                      if (item?.status == 5) {
                        const docPrev = item.trip_documents.map((doc) => {
                          return {
                            imageView: doc?.contract,
                            name: doc?.document_name,
                          };
                        });
                        setPreview(docPrev);
                        const arraynew = [...nameFile];
                        item.trip_documents.map((doc) => {
                          arraynew.push(doc.document_name);
                        });
                        setNameFile(arraynew);
                      }
                      handleButtonClick(
                        item.status === item.fund_allocation_state &&
                          item.fund_allocation_status === 0
                          ? 4
                          : item?.status === 4
                          ? item?.status + 1
                          : item?.status,
                        item?.order_detail?.order_type
                      );
                      setStatusOForder(item.status);
                      setUpdateId(item?.id);
                      setOrderCustomerID(item?.order_detail?.customer_id);
                      setCustomerValues({
                        value: item?.order_detail?.customer_id,
                        label: item?.order_detail?.customer,
                      });

                      setOrderWayType(item?.order_detail?.order_type);
                      if (item.status === 5 || item.status === 4) {
                        setFormOrderValue({
                          ...formOrderValue,
                          route: item?.route,
                          driverName: item?.order_detail?.driver,
                          truckNumer: item?.order_detail?.truck_number,
                        });
                        let details = routeList?.find(
                          (i) => i.id === item?.route
                        );
                        setFormOrderName({
                          ...formOrderName,
                          route: {
                            value: details?.id,
                            label: details?.route_name,
                          },
                        });
                      }
                    }}
                  >
                    {handleButtonText(
                      item.status === item.fund_allocation_state &&
                        item.fund_allocation_status === 0
                        ? 4
                        : item?.status == 4
                        ? item?.status + 1
                        : item?.status,
                      item?.order_detail?.order_type
                    )}
                  </button>
                )}
                {(item.status === 1 || item.status === 2) && (
                  <button
                    className="cancel_ord "
                    onClick={(e) => {
                      setUpdateId(item?.id);
                      setIsOpenDelete(true);
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </>
        ),
        sno: i + 1 + filter.limit * filter.currentPage,
        icon: (
          <>
            <img src={item?.icon} alt={item?.name} />
          </>
        ),
      }));
    return userLogin?.user_type !== 1 && userLogin?.user_branches?.length === 0
      ? []
      : pendingListArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllTrips", debouncedFilter],
    () => getAllTrips(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    let count = 0;
    dataList?.results?.forEach((item) => {
      if (item?.fund_allocation_status === 0) {
        count++;
      }
    });
    setFundPendingCount(count);
  }, [dataList]);

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
      setIsLoadding(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getCurrencyList();
    getCountryList();
    getRouteList();
    getDriverList();
    getBranchList();
    getTripCount();
  }, []);

  useEffect(() => {
    if (orderCustomerID) {
      const filterRoute = routeList?.filter(
        (route) => route.customer === orderCustomerID
      );

      setRouteList2(filterRoute);
    }
  }, [orderCustomerID, routeList]);
  useEffect(() => {
    if (formOrderValue?.otp?.length === 4) {
      setSuccessMsg("");
    }
  }, [formOrderValue.otp]);
  useEffect(() => {
    if (formOrderValue.route) {
      getRouteDetails(formOrderValue.route);
    }
  }, [formOrderValue.route]);

  useEffect(() => {
    getTripCount();
  }, [filter.status]);

  const getCountryList = async () => {
    const res = await getCountry(countryObj);
    setCountryList(res?.results);
  };

  const getRouteList = async () => {
    const res = await getAllRoute(routeObj);
    setRouteList(res?.results);
  };
  const getCurrencyList = async () => {
    const res = await getCurrency(countryObj);
    setCurrencyList(res?.results);
  };

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };
  const getRouteDetails = async (routeID) => {
    const res = await singleRouteDetails(routeID);

    const newExpe = res?.results?.expense_detail?.map((item, index) => {
      return {
        category: item?.category,
        currency: item?.currency,
        description: item?.description,
        amount: item?.amount,
        modeOfPayment: 2,
        route: formOrderValue?.route,
      };
    });
    setExpenseList(newExpe);
    const newFuel = res?.results?.fuel_expense_detail?.map((item, index) => {
      return {
        fuel_station: item?.fuel_station,
        qty: item?.ltr,
        description: item?.description,
        route: formOrderValue?.route,
      };
    });
    setFuelList(newFuel);
    setRouteDetails(res?.results);
  };

  const getDriverList = async () => {
    const res = await getAllDriver(driverObj);
    setDriverList(res?.results);
  };

  const getTripCount = async () => {
    const res = await getTripCountApi();

    const result = res?.results?.map((item) => {
      return {
        label:
          item?.label === "all"
            ? "All"
            : item?.label === "Document_Ready"
              ? "Client Approved"
              : item?.label === "Nomination"
              ? "Client Approval"
              : item?.label === "At_Port_Ready"
                ? "Loading Point"
                : item?.label === "At_Loading"
                  ? "FM Approval"
                  : item?.label === "Fund_Allocation"
                    ? "Fund Allocation"
                    : item?.label === "In_Transit"
                      ? "Border Clearance"
                      : item?.label === "At_Destination"
                        ? "Off Loading Point"
                        : item?.label === "In_Return"
                          ? "In Return"
                          : item?.label,
        value: item?.value,
        count: item?.count,
      };
    });
    setTripCount(result);
  };

  const clearAll = () => {
    setFormOrderValue({
      date: new Date(),
      comment: "",
      document: [],
      driverSignature: "",
      customerSignature: "",
      route: "",
      ex_category: "",
      fuel_station: "",
    });
    setFormOrderName({
      date: new Date(),
      comment: "",
      document: [],
      driverSignature: "",
      customerSignature: "",
      route: "",
      ex_category: "",
      fuel_station: "",
    });
    setIsEdit(false);
    setFormValue("");
    setFormError("");
    setNameFile([]);
    setPreview("");
    setOrderWayType(0);
    setPreviewDriver("");
    setPreviewCustomer("");
    setFundDriver("");
    setApproveDriver("");
    setReciverDriver("");
    setDocumentFile([]);
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const handlePendingFilterClick = () => {
    setPendingFilterToggle((prev) => !prev);
  };

  function updateKeysInArray(arr) {
    return arr.map((item) => {
      const newItem = { ...item };

      if (newItem.contract) {
        newItem.imageView = newItem.contract;
        delete newItem.contract;
      }

      if (newItem.document_name) {
        newItem.name = newItem.document_name;
        delete newItem.document_name;
      }

      return newItem;
    });
  }
  const extractDocumentNames = (arr) => {
    return arr.map((item, index) => {
      return item.document_name;
    });
  };
  const handleButtonClick = (status, type, docArr) => {
    switch (status) {
      case 1:
        setIsOpenModal(true);
        setTextState(status);
        break;
      case 2:
        setIsOpenModal(true);
        setTextState(status);
        break;
      case 3:
        setIsOpenModal(true);
        setTextState(status);
        break;
      case 5:
        setIsOpenModal1(true);
        setTextState(status);
        if (docArr?.length > 0) {
          const newDocArr = updateKeysInArray(docArr);
          const newNameArr = extractDocumentNames(docArr)
          setPreview(newDocArr);
          setNameFile(newNameArr)
        }
        break;
      case 4:
        setIsOpenModal2(true);
        setTextState(status);
        break;
      case 6:
        setIsOpenModal(true);
        setTextState(status);
        break;
      case 7:
        setIsOpenModal3(true);
        setTextState(status);
        break;
      case 8:
        if (type == 2) {
          setIsOpenModal(true);
          setTextState(status);
        } else {
          setIsOpenModal(true);
          setTextState(status);
        }
      case 9:
        setIsOpenModal(true);
        setTextState(status);

        break;

      default:
        break;
    }
  };

  const handleButtonText = (status, type, docCheck) => {
    switch (status) {
      case 1:
        return "Mark Approved";
      case 2:
        return "At Loading";
      case 3:
        return "Send For Approval";
      case 4:
        return "Fund Allocation";
      case 5:
        return docCheck?.length === 0 ? "Start Trip " : "Approve-Start";
      case 6:
        return "At Off Loading";
      case 7:
        return "Mark Delivered";
      case 8:
        return type == 2 ? "In Return" : "Completed";
      case 9:
        return "Completed";

      default:
        break;
    }
  };
  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const onOrderSubmitFunModal4 = () => {
    setIsOpenModal4(true);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addDriverValidation);
      setValidationError(error);
    }
  };

  const onOrderSubmitFun = () => {
    const formData = new FormData();

    formData.append("status", statusOForder);
    formData.append("date", getDateTime(formOrderName?.date, "-"));
    // formData.append("docs", driveFunSignatureFile);
    // formData.append("document_names", driveFunSignatureFile?.name);
    // formData.append("loaded_qty_fe", formOrderValue?.loaded_qty_fe);
    formData.append("is_fund_allocation", 1);
    formData.append("fund_allocation_status", 1);

    // documentFile?.map((e, i) => {
    //   formData.append("docs", e);
    //   formData.append("document_names", nameFile[i]);
    // });

    setIsLoadding(true);

    handleUpdateFund(formData);
  };

  const onOrderSubmitFund = async () => {
    const validationResult = await checkFormError(
      { expense: expenseList, fuel_expenses: fuelList },
      addExpenseFundValidation
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(formOrderValue);
      } else {
        handleUpdate(formOrderValue);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const onOrderSubmitMarkAlot = () => {
    const formData = new FormData();
    formData.append("status", orderWayType === 1 ? 10 : statusOForder + 1);
    formData.append("comment", formOrderValue.comment);
    formData.append("receiver_name", formOrderValue.receiver_Name);
    formData.append("receiver_remark", formOrderValue.remark);
    formData.append("receiver_signature", reciveSignatureFile.file);
    formData.append("docs", driverSignatureFile?.file);
    formData.append("document_names", driverSignatureFile?.name);
    documentFile?.map((e, i) => {
      formData.append("docs", e);
      formData.append("document_names", nameFile[i]);
    });
    setIsLoadding(true);

    handleUpdateFund(formData);
  };

  const updateAprroveSignature = (action) => {
    // console.log("documentFile", documentFile)
    const formData = new FormData();
    if (statusOForder === 4) {
      formData.append("status", "6");
    }
    formData.append("loaded_qty_fe", formOrderValue?.loaded_qty_fe);


    if (action === "reject") {
      // console.log("first")
      formData.append("trip_documents", []);
    } else {
      // console.log("second")
      formData.append("docs", aprroveSignatureFile?.file);
      formData.append("document_names", aprroveSignatureFile?.name);
      documentFile?.map((e, i) => {
        formData.append("docs", e);
        formData.append("document_names", nameFile[i]);
      });
    }
    setIsLoadding(true);

    if (action === "reject") {
      handleUpdateFundReject(formData);
    } else {
      handleUpdateFund(formData);
    }


  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      MarkedDocumentVal
    );
    setIsLoadding(true);
    handleUpdate(formOrderValue);

    // if (validationResult === true) {
    //     setIsLoadding(true)
    //     if (isEdit === true) {
    //         handleUpdate(formOrderValue);
    //     } else {
    //         handleUpdate(formOrderValue);
    //     }
    // } else {
    //     setIsLoadding(false)
    //     setFormError(validationResult);
    // }
  };

  const updateActiveMutation = useMutation(updateActiveDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    const payload = {
      comment: formOrderValue?.comment,
      status: +filter?.status + 1,
    };
    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleUpdate = async (data) => {
    const payload = {
      formdata: {
        comment: formOrderValue?.comment,
        date: getDateTime(formOrderName?.date, "-"),
        status:
          orderWayType == 1 && statusOForder == 8
            ? statusOForder + 2
            : statusOForder + 1,
      },
      id: updateId,
    };
    const payloadWithLoad = {
      formdata: {
        comment: formOrderValue?.comment,
        date: getDateTime(formOrderName?.date, "-"),
        offloaded_qty_fe: formOrderValue?.offloaded_qty_fe,
        status:
          orderWayType == 1 && statusOForder == 8
            ? statusOForder + 2
            : statusOForder + 1,
      },
      id: updateId,
    };

    updateMutation.mutate(
      textState === 8 && orderWayType == 1 ? payloadWithLoad : payload
    );
  };

  const handleUpdateFund = (formData) => {
    const payload = {
      formdata: formData,
      id: updateId,
    };

    updateMutationFund.mutate(payload);
  };

  const handleUpdateFundReject = (formData) => {
    const payload = {
      formdata: formData,
      id: updateId,
    };

    updateMutationFundReject.mutate(payload);
  };

  const handleUpdateSelect = async (data) => {
    setIsLoadding(true);
    const validationResult = await checkFormError(
      { expense: expenseList, fuel_expenses: fuelList },
      addExpenseFundValidation
    );

    const payload = {
      formdata: {
        expenses: expenseList,
        fuel_expenses: fuelList,
        status: statusOForder,
      },
      id: updateId,
    };

    if (validationResult === true) {
      if (isEdit === true) {
        updateExFuelMutation.mutate(payload);
      } else {
        // setIsLoadding(false);
        updateExFuelMutation.mutate(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const addMutation = useMutation(addDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateTripApi, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        setIsOpenModal(false);
        setStorage("statusTag", "");
        setFilter({
          ...filter,
          status:
            statusOForder === 7 || statusOForder === 8
              ? orderWayType === 1
                ? 10
                : statusOForder + 1
              : statusOForder + 1,
        });
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });
  const updateExFuelMutation = useMutation(updateTripExFuelApi, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        setIsLoadding(false);
        setNameFile([]);
        setDocumentFile([]);
        setIsOpenModal4(false);
        setFilter({
          ...filter,
          status: statusOForder + 1,
        });
        onSuccessResponse(res?.message);
        onOrderSubmitFun();
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });
  const updateMutationFund = useMutation(updateTripFundApi, {
    onSuccess: (res) => {
      // console.log("formOrderName", formOrderName);
      if (res?.success) {
        setIsEdit(false);
        setIsOpenModal2(false);
        // isFundToggle ? setIsOpenModal4(true) : setIsOpenModal4(false);
        setIsOpenModal3(false);
        setIsLoadding(false);
        setNameFile([]);
        setDocumentFile([]);
        if (isOpenModal1) {
          // setIsOpenModal4(true);
          setIsOpenModal1(false);

          setPreview([]);
          setNameFile([]);
        } else {
          // clearAll();
          setFilter({
            ...filter,
            status: (() => {
              if (statusOForder === 7) {
                return orderWayType === 1 ? 10 : statusOForder + 1;
              } else {
                return statusOForder + 1;
              }
            })(),
          });
        }

        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const updateMutationFundReject = useMutation(updateTripFundApi, {
    onSuccess: (res) => {
      // console.log("formOrderName", formOrderName);
      if (res?.success) {
        setIsEdit(false);
        setIsOpenModal2(false);
        // isFundToggle ? setIsOpenModal4(true) : setIsOpenModal4(false);
        setIsOpenModal3(false);
        setIsLoadding(false);
        setNameFile([]);
        setDocumentFile([]);
        if (isOpenModal1) {
          // setIsOpenModal4(true);
          setIsOpenModal1(false);

          setPreview([]);
          setNameFile([]);
        } else {
          // clearAll();
          setFilter({
            ...filter,
            status: statusOForder,
          });
        }

        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const updateMutationDelete = useMutation(updateTripApi, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        setIsOpenDelete(false);

        setFilter({
          ...filter,
          status: 11,
        });
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllDriver");
    setIsOpen(false);
    setIsLoadding(false);
    setOrderWayType();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const handleActive = (data) => {
    if (filter.status !== data.value) {
      setIsLoadding(true);
    }
    setStorage("statusTag", { orderId: "", id: "" });
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;
      case -1:
        return financialApprovalColumn;

      default:
        return Requirement;
    }
  };

  const handleDeleteImage = (i) => {
    let list = [...documentFile];
    let name = [...nameFile];
    let preList = [...preview];
    list.splice(i, 1);
    preList.splice(i, 1);
    name.splice(i, 1);
    setDocumentFile(list);
    setPreview(preList);
    setNameFile(name);
  };

  ///---- Mark Document ready ----

  //---- start Trip ---

  const addNewExpo = (e) => {
    e.preventDefault();
    setExpenseList((preState) => {
      return [...preState, defultExpense];
    });
  };

  const addNewFuel = (e) => {
    e.preventDefault();
    setFuelList((preState) => {
      return [...preState, defultFuel];
    });
  };

  const updateExpoDetails = (index, data) => {
    const { key, value } = data;
    let newExpenseList = [...expenseList];
    newExpenseList[index][key] = value;
    setExpenseList(newExpenseList);
  };

  const updateFuelDetails = (index, data) => {
    const { key, value } = data;

    let newFuelList = [...fuelList];
    newFuelList[index][key] = value;
    setFuelList(newFuelList);
  };

  const handleDeleteExpense = (index) => {
    let List = [...expenseList];
    List.splice(index, 1);
    setExpenseList(List);
  };

  const handleDeleteFuel = (index) => {
    let List = [...fuelList];
    List.splice(index, 1);
    setFuelList(List);
  };

  const handleDeleteTrip = () => {
    const payload = {
      formdata: {
        status: 11,
        cancel_reason: formOrderValue.remark,
      },
      id: updateId,
    };
    updateMutationDelete.mutate(payload);
  };

  const addNewSubmit = async (value) => {
    const validationResult = await checkFormError(
      { name: formOrderValue.addCurrency },
      modalInputSchema
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAddNew(value);
      }
    } else {
      setFormError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleAddNew = (value) => {
    const payload = {
      name: formOrderValue?.addCurrency,
    };

    addNewCountryMutation.mutate(payload);
  };

  const addNewCountryMutation = useMutation(addCurrencyApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);
        let expe = [...expenseList];
        expe.map((item, index) => {
          updateExpoDetails(index, {
            key: "currency",
            value: res?.results?.id,
          });
        });
        setExpenseList(expe);
        getCurrencyList();
        setOpenModal(false);
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });
  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Branch":
        setFormOrderValue({ ...formOrderValue, addBranch: inputvalue });
        setOpenModal({ ...openModal, branch: true });
        break;
      case "Select Customer":
        setOpenModal({ ...openModal, customer: true });
        break;
      case "Select Product":
        setFormOrderValue({ ...formOrderValue, productName: inputvalue });
        setOpenModal({ ...openModal, product: true });
        break;
      case "Select Route":
        setOpenRoute(true);
        break;
      default:
        break;
    }
  };
  const routeCallBack = (data) => {
    getRouteList();
    setFormOrderValue({ ...formOrderValue, route: data?.id });
    setFormOrderName({
      ...formOrderName,
      route: { value: data?.id, label: data?.route_name },
    });
  };

  const signatureCallBack = (item) => {
    if (openDrower.name === "driver") {
      const base64Data = item; // Get base64 string
      const byteString = atob(base64Data.split(",")[1]);
      const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const file = new File([ab], "signature.png", { type: mimeString });

      handleOrderChange(
        "driverSignature",
        new File([ab], "driverimage.png", {
          type: mimeString,
        })
      );
      setPreviewDriver(item);
      let signature_ref = [...signature];
      signature_ref.push({
        name: "driver_signature",
        file: new File([ab], "driver_signature.png", {
          type: mimeString,
        }),
      });
      setSignature(signature_ref);
    } else if (openDrower.name === "fundAllocation") {
      handleOrderChange(
        "fundAlloctionSignature",
        new File([item], "driverimage.jpg", {
          type: "image/jpg",
        })
      );
      setFundDriver(item);
      setDriveFunSignatureFile(
        new File([item], "driver_signature.jpg", {
          type: "image/jpg",
        })
      );
    } else if (openDrower.name === "route") {
      handleOrderChange(
        "approverSignature",
        new File([item], "approveImage.png", {
          type: "image/png",
        })
      );
      setApproveDriver(item);
      setAprroveSignatureFile({
        name: "approver_signature",
        file: new File([item], "approver_signature.png", {
          type: "image/png",
        }),
      });
    } else if (openDrower.name === "recive") {
      handleOrderChange(
        "reciverSignature",
        new File([item], "reciverSignature.png", {
          type: "image/png",
        })
      );
      setReciverDriver(item);
      setReciveSignatureFile({
        name: "receve_signature",
        file: new File([item], "receve_signature.png", {
          type: "image/png",
        }),
      });
      // let signature_ref = [...signature];
      // signature_ref.push({ name: "reciver_signature", file: new File([item], "reciver_signature.jpg", {
      //   type: "application/octet-stream",
      // }) });
      // setSignature(signature_ref);
    }
    if (openDrower.name === "driverMark") {
      handleOrderChange(
        "driverSignature",
        new File([item], "driverimage.png", {
          type: "image/png",
        })
      );
      setPreviewDriver(item);
      setDriverSignatureFile({
        name: "driver_signature",
        file: new File([item], "driver_signature.png", {
          type: "image/png",
        }),
      });
      // let signature_ref = [...signature];
      // signature_ref.push({ name: "reciver_signature", file: new File([item], "reciver_signature.jpg", {
      //   type: "application/octet-stream",
      // }) });
      // setSignature(signature_ref);
    } else {
      handleOrderChange(
        "approverSignature",
        new File([item], "approverImage.png", {
          type: "image/png",
        })
      );
      setPreviewCustomer(item);
      let signature_ref = [...signature];
      signature_ref.push({ name: "customer_signature", file: item });
      setSignature(signature_ref);
    }
  };

  const handleExportAction = (id) => {
    downloadcsvExcle.mutate(id);
  };
  const verifyOtpSubmit = () => {
    const payload = {
      order: orderId,
      otp: parseInt(formOrderValue.otp),
    };
    verifyMutaion.mutate(payload);
  };

  const verifyMutaion = useMutation(verifyOtpApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setSuccessMsg(res?.message);
      } else {
        onErrorResponse(res?.error);
        setSuccessMsg(res?.message);
        setIsLoadding(false);
      }
    },
  });
  const downloadcsvExcle = useMutation(getTripCsv, {
    onSuccess: (res) => {
      if (res) {
        const url = window.URL.createObjectURL(
          new Blob([res], { type: "application/octet-stream" })
        );
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `JOB-REF#${nominationNumber}.xlsx`);
        link.click();
        // link.download = 'filename.txt';
      }
    },
  });

  return (
    <>
    <GoogleMapsLoader>
      <div className="tabMain_head llt_tabMain_head">
        {" "}
        {tripCount && (
          <Tabs
            // TabList={tripsTabList}
            TabList={tripCount}
            handleActive={handleActive}
            activeTab={filter.status}
          />
        )}
        {/* <div>
                    <button className='addPlan' onClick={() => setIsOpen(true)}>Add Driver</button>
                </div> */}
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Customer Name"
              label="Customer Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,

                  customer_name: e.target.value,
                }))
              }
              value={filter?.customer_name}
            />
          </div>
          <div className="inr_flex_mini">
            <InputField
              placeholder="Order Number"
              label="Order Number"
              className="anvSearch_input"
              value={
                // filter?.order_number ? filter?.order_number : statusTag?.orderId
                filter?.order_number
              }
              onChange={(e) => {
                setStorage("statusTag", "");
                setFilter((s) => ({
                  ...s,

                  order_number: e.target.value,
                }));
              }}
            />
          </div>

          <div className="inr_flex_mini">
            <InputField
              placeholder="Search"
              label="Search"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  search: e.target.value,
                }))
              }
              value={filter?.search}
            />
          </div>
          <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Branch"
              label="Select Branch"
              classNamePrefix="_anvFloatSelect"
              boxClass="_anvFloatedSelect"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={branchList ? branchList : []}
              valueText="id"
              labelText="name"
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.branchFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, branch_uuid: data.value });
                setFormOrderName({ ...formOrderName, branchFilter: data });
              }}
            />{" "}
          </div>
          <div className="inr_flex">
            <button
              className={`filterBoxPending ${pendingFilterToggle ? "clicked" : ""
                }`}
              onClick={handlePendingFilterClick}
            >
              Funds Pending ({fundPendingCount})
            </button>
          </div>
          {(filter?.customer_name || filter?.order_number || filter?.branch_uuid.length == 36 || filter?.search || pendingFilterToggle === true) && <div className="inr_flex_mini">
            <button
              className="anime-btn reset_button"
              onClick={() => {
                setFilter({
                  ...filter,
                  customer_name: "",
                  order_number: "",
                  branch_uuid: result,
                  search: "",
                });
                setFormOrderName({ ...formOrderName, branchFilter: "" });
                setPendingFilterToggle(!pendingFilterToggle)
              }}
            >
              Reset
            </button>
          </div>}
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={pendingFilterToggle ? pendingRowDataList() : rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                formOrderName?.count
                  ? formOrderName?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));

                setFormOrderName({ ...formOrderName, count: data });
              }}
            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}
      <InputModalNew
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(!isOpenModal)}
        headerName={
          isEdit
            ? "Edit Category"
            : textState === 1
              ? "Mark Approved"
              : textState === 2
                ? "Mark At Loading"
                : textState === 3
                  ? "Mark Send For Approval"
                  : textState === 6
                    ? "Mark At OffLoading"
                    : textState === 8 && orderWayType == 2
                      ? "In Return"
                      : textState === 8 && orderWayType == 1
                        ? "Completed"
                        : textState === 9
                          ? "Completed"
                          : ""
        }
        submitLabel={
          isEdit
            ? "Update Category"
            : textState === 1
              ? "Mark Approved"
              : textState === 2
                ? "Mark At Loading"
                : textState === 3
                  ? "Mark Send For Approval"
                  : textState === 6
                    ? "Mark At OffLoading"
                    : textState === 8 && orderWayType == 2
                      ? "In Return"
                      : textState === 8 && orderWayType == 1
                        ? "Completed"
                        : textState === 9
                          ? "Completed"
                          : ""
        }
        onSubmit={(e) => {
          onOrderSubmit();
          setIsOpenModal(false);
        }}
        disabled={
          !formOrderValue.date ||
            isLoadding ||
            (textState === 8 && orderWayType == 1)
            ? !formOrderValue?.offloaded_qty_fe
            : ""
        }
        className="NewInput-modal"
        customeClassName="ModalFlexDiv2"
        btnwidth="button-width"
        inputProps={
          <div className="newModalInputFlex">
            <div className="mb-2 position-relative">
              <label className="_reactDateTime_picker"><span className="_likInpt">Select Date and Time</span></label>
              <DateTimePicker
                onChange={(date) => {
                  // handleOrderChange(name, value)
                  setFormOrderName({ ...formOrderName, date: date });
                }}
                value={formOrderName.date}
                format="dd/MM/yyyy | hh:mm a"
                disableClock={true}
              />
            </div>
            <div>
              <InputField
                className="modal_input "
                // label={isEdit ? "Truck number" : "Capacity Trailer 2"}
                labelClass="modal_label"
                labelSpanClass="style_for_star"
                label="Comments"
                placeholder="Comments"
                name="comment"
                type="text"
                // focus={!!formError?.comment}
                // error={formError.comment}
                value={formOrderValue?.comment}
                onChange={({ target: { name, value } }) =>
                  handleOrderChange(name, value)
                }
              />
              {textState === 8 && orderWayType == 1 && (
                <InputField
                  className="modal_input "
                  // style={{ display: "none" }}
                  labelClass="modal_label"
                  labelSpanClass="style_for_star"
                  placeholder="Offloaded Qty "
                  label="Offloaded Qty"
                  name="offloaded_qty_fe"
                  type="text"
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.offloaded_qty_fe}
                  error={formError?.offloaded_qty_fe}
                  value={formOrderValue?.offloaded_qty_fe}
                  onChange={({ target: { name, value } }) => {
                    handleOrderChange(name, value);
                  }}
                />
              )}
            </div>
          </div>
        }
      />

      <InputModalNew
        isOpen={isOpenModal2}
        toggle={() => {
          clearAll();
          setIsOpenModal2(!isOpenModal2);
        }}
        headerName={
          isEdit
            ? "Edit Category"
            : textState === 4
              ? "Send for Fund Allocation"
              : ""
        }
        submitLabel={
          isEdit
            ? "Update Category"
            : textState === 4
              ? "Send for fund Allocation"
              : ""
        }
        onSubmit={(e) => {
          // setIsFundToggle(true);
          onOrderSubmitFunModal4();
          // onOrderSubmitFun();
          // setIsOpenModal2(false)
        }}
        disabled={!formOrderValue?.diverCheckBox || isLoadding}
        className="NewInput-modal"
        customeClassName="ModalFlexDiv2"
        btnwidth="button-width"
        isLoadding={isLoadding}
        inputProps={
          <div className="newModalInputFlex">
            <div className="mb-2 position-relative">
              <label className="_reactDateTime_picker"><span className="_likInpt">Select Date and Time</span></label>
              <DateTimePicker
                onChange={(date) => {
                  // handleOrderChange(name, value)
                  setFormOrderName({ ...formOrderName, date: date });
                }}
                value={formOrderName.date}
                format="dd/MM/yyyy | hh:mm a"
                disableClock={true}
              />
            </div>

            <div className="imagePreviewBox">
              {/* <div className="divWrap">
                {preview?.length > 0 && (
                  <div className="imagePre-div">
                    {preview?.map((item, i) => {
                      return !item.name.includes("pdf") ? (
                        <div className="anvInr_imgBx">
                          <img src={`${item?.imageView}`} alt="" />
                        </div>
                      ) : (
                        <div className="anvInr_imgBx closeIconeFont">
                          <VscFilePdf />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="imageFilename">
                  {nameFile?.map((item, i) => (
                    <div className="displayDelete">
                      <InputField
                        className="modal_input"
                        labelClass="modal_label"
                        labelSpanClass="style_for_star"
                        placeholder=""
                        name="name"
                        type="text"
                        value={item}
                        onChange={({ target: { name, value } }) => {
                          // handleOrderChange(name, files[0])
                          const nameList = [...nameFile];
                          nameList[i] = value;
                          setNameFile(nameList);
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt=""
                        onClick={() => handleDeleteImage(i)}
                      />
                    </div>
                  ))}
                </div>
              </div> */}
              <div className="sigature_fund_div">
                {/* {!fundDriver && (
                  <div
                    className="Siganture_div"
                    onClick={() =>
                      setOpenDrower({
                        ...openDrower,
                        openPad: true,
                        name: "fundAllocation",
                      })
                    }
                  >
                    Driver Signature
                  </div>
                )}
                {fundDriver && (
                  <div
                    className="customerPreview"
                    // onClick={() => driverRef.current.click()}
                  >
                    <span>Driver Signature</span>
                    <div className="Siganture_div">
                      <img src={`${fundDriver}`} alt="" className="" />
                    </div>
                  </div>
                )} */}
                <div className="selectRoute">
                  <Dropdown
                    placeholder="Select Route"
                    label="Select Route"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    className="dropdownoforder"
                    name="from_location"
                    options={routeList2 ? routeList2 : []}
                    valueText="id"
                    labelText="route_name"
                    components={{ Menu: CustomControl }}
                    focus={!!formError?.route}
                    error={formError.route}
                    selectedOption={formOrderName?.route}
                    setSelectedOption={(data) => {
                      handleOrderChange("route", data.value);
                      setFormOrderName(() => {
                        // console.log(data.data.route_name);
                        return { ...formOrderName, route: data };
                      });
                    }}
                  />
                </div>
                <div className="signatureCheck">
                  <InputField
                    labelClass="modal_label"
                    labelSpanClass="style_for_star"
                    placeholder="Comments"
                    // label="Comments"
                    name="diverCheckBox"
                    type="checkbox"
                    // focus={!!formError?.comment}
                    // error={formError.comment}
                    value={formOrderValue?.diverCheckBox}
                    onChange={({ target: { name, checked } }) =>
                      handleOrderChange(name, checked)
                    }
                  />
                  <span>
                    I confirm approval of the document, accepting responsibility
                    for accuracy.
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <InputModalNew
        isOpen={isOpenModal3}
        toggle={() => {
          clearAll();
          setSignature("");
          setIsOpenModal3(!isOpenModal3);
        }}
        headerName={
          isEdit ? "Edit Category" : textState === 7 ? "Mark Delivered" : ""
        }
        submitLabel={
          isEdit ? "Update Category" : textState === 7 ? "Mark Delivered" : ""
        }
        onSubmit={(e) => {
          onOrderSubmitMarkAlot();
        }}
        disabled={!documentFile.length || !reciverDriver}
        className="NewInput-modal"
        customeClassName="ModalFlexDiv2"
        btnwidth="button-width"
        inputProps={
          <div className="routeSelectDiv">
            <div className="mb-2 position-relative">
              <label className="_reactDateTime_picker"><span className="_likInpt">Select Date and Time</span></label>
              <DateTimePicker
                onChange={(date) => {
                  // handleOrderChange(name, value)
                  setFormOrderName({ ...formOrderName, date: date });
                }}
                value={formOrderName.date}
                format="dd/MM/yyyy | hh:mm a"
                disableClock={true}
              />
            </div>

            <InputField
              className="modal_input "
              // style={{ display: "none" }}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder="Comment"
              label="Comment"
              name="comment"
              type="text"
              focus={!!formError?.comment}
              error={formError?.comment}
              value={formOrderValue?.comment}
              onChange={({ target: { name, value } }) => {
                handleOrderChange(name, value);
              }}
            />

            <div className="UploadBox">
              <span>
                Upload Document<span className="star">*</span>
              </span>
              {/* <div
                className="UploadDocumentText"
                onClick={() => myRefname.current.click()}
              >
                Attach Documents
              </div> */}

              <InputField
                className="modal_input "
                // style={{ display: "none" }}
                labelClass="modal_label"
                labelSpanClass="style_for_star"
                placeholder=""
                label="Attach Documents"
                name="file"
                type="file"
                innerRef={myRefname}
                focus={!!formError?.document}
                error={formError?.document}
                value={formOrderValue?.document}
                onChange={({ target: { name, files } }) => {
                  // handleOrderChange(name, files[0])
                  const preList = [...preview];
                  const fileList = [...documentFile];
                  fileList.push(files[0]);
                  preList.push({
                    imageView: URL.createObjectURL(files[0]),
                    name: files[0].name,
                  });
                  const nameList = [...nameFile];
                  nameList.push(files[0].name);
                  setNameFile(nameList);
                  setDocumentFile(fileList);
                  setPreview(preList);
                }}
              />
            </div>
            { }
            <div className="imagePreviewBox">
              <div className="divWrap">
                {preview?.length > 0 && (
                  <div className="imagePre-div">
                    {preview?.map((item, i) => {
                      return !item.name.includes("pdf") ? (
                        <div className="anvInr_imgBx">
                          <img src={`${item?.imageView}`} alt="" />
                        </div>
                      ) : (
                        <div className="anvInr_imgBx closeIconeFont">
                          <VscFilePdf />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="imageFilename">
                  {nameFile.map((item, i) => (
                    <div className="displayDelete">
                      <InputField
                        className="modal_input"
                        labelClass="modal_label"
                        labelSpanClass="style_for_star"
                        placeholder=""
                        name="name"
                        type="text"
                        value={item}
                        onChange={({ target: { name, value } }) => {
                          // handleOrderChange(name, files[0])
                          const nameList = [...nameFile];
                          nameList[i] = value;
                          setNameFile(nameList);
                        }}
                      />
                      <span className="_faDelete" onClick={() => handleDeleteImage(i)} ><MdOutlineDelete /></span>

                      {/* <img
                        src={DeleteIcon}
                        alt=""
                        onClick={() => handleDeleteImage(i)}
                      /> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="driverSignature">
                {!previewDriver && (
                  <div
                    className="UploadDriverText"
                    onClick={() =>
                      setOpenDrower({
                        ...openDrower,
                        openPad: true,
                        name: "driverMark",
                      })
                    }
                  >
                    Driver Signature<span>*</span>
                  </div>
                )}
                <FaPencilAlt
                  className="pencil_for"
                  onClick={() =>
                    setOpenDrower({
                      ...openDrower,
                      openPad: true,
                      name: "driverMark",
                    })
                  }
                />
              </div>

              {previewDriver && (
                <div
                  className="customerPreview"
                  onClick={() =>
                    setOpenDrower({
                      ...openDrower,
                      openPad: true,
                      name: "driverMark",
                    })
                  }
                >
                  <span>Driver Signature</span>
                  <div className="image-container ">
                    <img
                      src={`${previewDriver}`}
                      alt=""
                      onClick={() =>
                        setOpenDrower({
                          ...openDrower,
                          openPad: true,
                          name: "driver",
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <InputField
              className="modal_input "
              style={{ display: "none" }}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder=""
              name="file"
              type="file"
              innerRef={driverRef}
              focus={!!formError?.driverSignature}
              error={formError?.driverSignature}
              // value={formOrderValue?.driverSignature}
              onChange={({ target: { name, files } }) => {
                handleOrderChange("driverSignature", files[0]);
                setPreviewDriver(URL.createObjectURL(files[0]));
                let signature_ref = [...signature];
                signature_ref.push({
                  name: "driver_signature",
                  file: files[0],
                });
                setSignature(signature_ref);
              }}
            />
            <div className="driverSignature">
              {" "}
              {!reciverDriver && (
                <div
                  className="UploadDriverText"
                  // onClick={() => customerRef.current.click()}
                  onClick={() => {
                    setIsOpenModal3(false);
                    setIsOpenReciSign(true);
                  }}
                >
                  Receiver Signature<span>*</span>
                </div>
              )}
              <FaPencilAlt
                className="pencil_for"
                onClick={() =>
                  setOpenDrower({
                    ...openDrower,
                    openPad: true,
                    name: "customer",
                  })
                }
              />
            </div>
            {reciverDriver && (
              <div className="customerPreview">
                <span>Receiver Signature</span>
                <div
                  className="image-container "
                  // onClick={() => customerRef.current.click()}
                  onClick={() => {
                    setIsOpenModal3(false);
                    setIsOpenReciSign(true);
                  }}
                >
                  <img src={`${reciverDriver}`} alt="" />
                </div>
              </div>
            )}
            <InputField
              className="modal_input "
              style={{ display: "none" }}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder=""
              name="file"
              type="file"
              innerRef={customerRef}
              focus={!!formError?.customerSignature}
              error={formError?.customerSignature}
              // value={formOrderValue?.customerSignature}
              onChange={({ target: { name, files } }) => {
                handleOrderChange("customerSignature", files[0]);
                setPreviewCustomer(URL.createObjectURL(files[0]));
                let signature_ref = [...signature];
                signature_ref.push({
                  name: "customer_signature",
                  file: files[0],
                });
                setSignature(signature_ref);
              }}
            />
          </div>
        }
      />

      <InputModalNew
        isOpen={isOpenModal1}
        toggle={() => {
          clearAll();
          setIsOpenModal1(!isOpenModal1);
        }}
        headerName="Start Trip"
        submitLabel={isEdit ? "Update Category" : "Continue"}
        submitLabel2={tripDocument?.length === 0 ? "" : "Reject"}
        onSubmit={(e) => {
          // setIsOpenModal4(true);
          // setIsOpenModal1(false);
          updateAprroveSignature("aprrove");
          // handleUpdateSelect()
        }}
        onSubmitReject={() => {
          updateAprroveSignature("reject");
        }}
        disabled={
          !formOrderValue.route ||
          !formOrderValue?.approveCheckbox ||
          // !documentFile?.length > 0 ||
          !approveDriver ||
          !formOrderValue?.loaded_qty_fe ||
          preview?.length === 0
        }
        className="NewInput-modal"
        customeClassName="ModalFlexDiv2"
        btnwidth="button-width"
        btnwidthNew="button-width_new"
        inputProps={
          <div className="routeSelectDiv">
            <div className="UploadBox">
              <span>
                Upload Document<span className="star">*</span>
              </span>
              {/* <div
                className="UploadDocumentText"
                onClick={() => myRefname.current.click()}
              >
                Attach Documents
              </div> */}
              <InputField
                className="modal_input "
                label="Attach Documents"
                labelClass="modal_label"
                labelSpanClass="style_for_star"
                placeholder=""
                name="file"
                type="file"
                innerRef={myRefname}
                focus={!!formError?.document}
                error={formError?.document}
                value={formOrderValue?.document}
                onChange={({ target: { name, files } }) => {
                  // handleOrderChange(name, files[0])

                  const preList = [...preview];
                  const fileList = [...documentFile];
                  fileList.push(files[0]);
                  preList.push({
                    imageView: URL.createObjectURL(files[0]),
                    name: files[0].name,
                  });
                  const nameList = [...nameFile];
                  nameList.push(files[0].name);
                  setNameFile(nameList);
                  setDocumentFile(fileList);
                  setPreview(preList);
                }}
              />
            </div>
            <div className="imagePreviewBox mb-0">
              <div className="divWrap">
                {preview?.length > 0 && (
                  <div className="imagePre-div">
                    {preview?.map((item, i) => {
                      return !item.name.includes("pdf") ? (
                        <div className="anvInr_imgBx">
                          <img src={`${item?.imageView}`} alt="" onClick={() => {
                            window.open(item?.imageView, '_blank');
                          }} />
                        </div>
                      ) : (
                        <div className="anvInr_imgBx closeIconeFont">
                          <VscFilePdf />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="imageFilename">
                  {nameFile.map((item, i) => (
                    <div className="displayDelete">
                      <InputField
                        className="modal_input"
                        labelClass="modal_label"
                        labelSpanClass="style_for_star"
                        placeholder=""
                        name="name"
                        type="text"
                        disabled
                        value={item}
                        onChange={({ target: { name, value } }) => {
                          // handleOrderChange(name, files[0])
                          const nameList = [...nameFile];
                          nameList[i] = value;
                          setNameFile(nameList);
                        }}
                      />

                      <span className="_faDelete" onClick={() => handleDeleteImage(i)}><MdOutlineDelete /></span>


                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="newModalInputFlex" style={{ marginTop: '-10px' }}>
              <InputField
                labelClass="modal_label"
                labelSpanClass="style_for_star"
                placeholder="Loaded Qty"
                name="loaded_qty_fe"
                type="text"
                // focus={!!formError?.comment}
                // error={formError.comment}
                onKeyDown={handleKeyDown}
                value={formOrderValue?.loaded_qty_fe}
                onChange={({ target: { name, value } }) =>
                  handleOrderChange(name, value)
                }
              />
            </div>
            <div className="selectRoute">
              <Dropdown
                placeholder="Select Route"
                label="Select Route"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
                className="dropdownoforder"
                name="from_location"
                options={routeList2 ? routeList2 : []}
                valueText="id"
                labelText="route_name"
                components={{ Menu: CustomControl }}
                focus={!!formError?.route}
                error={formError.route}
                selectedOption={formOrderName?.route}
                setSelectedOption={(data) => {
                  handleOrderChange("route", data.value);
                  setFormOrderName({ ...formOrderName, route: data });
                }}
              />
            </div>

            {!approveDriver && (
              <div
                className="Siganture_div"
                onClick={() =>
                  setOpenDrower({
                    ...openDrower,
                    openPad: true,
                    name: "route",
                  })
                }
              >
                Approver Signature
              </div>
            )}
            {approveDriver && (
              <div
                className="customerPreview"
              // onClick={() => driverRef.current.click()}
              >
                <span>Approver Signature</span>
                <div className="Siganture_div">
                  <img src={`${approveDriver}`} alt="" className="" />
                </div>
              </div>
            )}
            <div className="signatureCheck">
              <InputField
                labelClass="modal_label"
                labelSpanClass="style_for_star"
                placeholder="Comments"
                name="approveCheckbox"
                type="checkbox"
                // focus={!!formError?.comment}
                // error={formError.comment}
                value={formOrderValue?.approveCheckbox}
                onChange={({ target: { name, checked } }) =>
                  handleOrderChange(name, checked)
                }
              />
              <span>
                I confirm approval of the document, accepting responsibility for
                accuracy.
              </span>
            </div>
          </div>
        }
      />

      <InputModalNew
        isOpen={isOpenDelete}
        toggle={() => {
          setIsOpenDelete(!isOpenDelete);
        }}
        headerName={isEdit ? "Edit Category" : "Delete Trip"}
        submitLabel={isEdit ? "Update Category" : "Delete"}
        onSubmit={(e) => {
          handleDeleteTrip();
        }}
        disabled={!formOrderValue.remark}
        className="NewInput-modal"
        customeClassName="ModalFlexDiv2"
        btnwidth="button-width"
        inputProps={
          <div className="newModalInputFlex ">
            {/* <Dropdown
                            placeholder="Select Route"
                            className='dropdownoforder'
                            name="customer"
                            options={routeList ? routeList : []}
                            valueText="id"
                            labelText="name"
                            // components={{ Menu: CustomControl }}
                            focus={!!formError?.customer}
                            error={formError.customer}
                            selectedOption={formOrderName?.customer}
                            setSelectedOption={(data) => {
                                handleOrderChange("customer", data.value);
                                setFormOrderName({ ...formOrderName, customer: data })
                            }}
                        /> */}

            <InputField
              className="modal_input "
              // label={isEdit ? "Truck number" : "Capacity Trailer 2"}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder="Remark"
              label="Remark"
              name="remark"
              type="text"
              focus={!!formError?.remark}
              error={formError.remark}
              value={formOrderValue?.remark}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          </div>
        }
      />

      <InputModalNew
        isOpen={isOpenReciSign}
        toggle={() => {
          setIsOpenReciSign(!isOpenReciSign);
          setIsOpenModal3(!isOpenModal3);
          setFormOrderValue({
            ...formOrderValue,
            receiver_Name: "",
            otp: "",
            remark: "",
          });
          setReciverDriver("");
          setSuccessMsg("");
        }}
        headerName={isEdit ? "Edit Category" : "Receiver Signature"}
        submitLabel={isEdit ? "Update Category" : "Add Receiver Signature"}
        onSubmit={(e) => {
          // handleDeleteTrip();
          setIsOpenReciSign(!isOpenReciSign);
          setIsOpenModal3(!isOpenModal3);
          setSuccessMsg("");
        }}
        disabled={
          !formOrderValue.remark ||
          successMsg !== "OTP verified successfully" ||
          !formOrderValue?.receiver_Name ||
          !reciverDriver
        }
        className="NewInput-modal"
        customeClassName="ModalFlexDiv2"
        btnwidth="button-width"
        inputProps={
          <div className="">
            {!reciverDriver && (
              <div
                className="Siganture_div"
                onClick={() =>
                  setOpenDrower({
                    ...openDrower,
                    openPad: true,
                    name: "recive",
                  })
                }
              >
                Receiver Signature
              </div>
            )}
            {reciverDriver && (
              <div
                className="customerPreview"
              // onClick={() => driverRef.current.click()}
              >
                <span>Receiver Signature</span>
                <div className="Siganture_div">
                  <img
                    src={`${reciverDriver}`}
                    alt=""
                    className=""
                    onClick={() =>
                      setOpenDrower({
                        ...openDrower,
                        openPad: true,
                        name: "recive",
                      })
                    }
                  />
                </div>
              </div>
            )}

            <InputField
              className="modal_input "
              // label={isEdit ? "Truck number" : "Capacity Trailer 2"}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder="Receiver Name"
              label="Receiver Name"
              name="receiver_Name"
              type="text"
              focus={!!formError?.receiver_Name}
              error={formError.receiver_Name}
              value={formOrderValue?.receiver_Name}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
            <InputField
              className="modal_input "
              // label={isEdit ? "Truck number" : "Capacity Trailer 2"}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder="Remark"
              label="Remark"
              name="remark"
              type="text"
              focus={!!formError?.remark}
              error={formError.remark}
              value={formOrderValue?.remark}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
            {/* <div className="otp_div_recev"> */}
            <InputField
              className="modal_input "
              // label={isEdit ? "Truck number" : "Capacity Trailer 2"}
              labelClass="modal_label"
              labelSpanClass="style_for_star"
              placeholder="OTP"
              label="OTP"
              name="otp"
              maxLength={4}
              onKeyDown={handleKeyDown}
              type="text"
              focus={!!formError?.otp}
              error={formError.otp}
              value={formOrderValue?.otp}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
            <div className="verify_button">
              <butoon className="otp_div_recev">Resent OTP</butoon>
              {!successMsg && formOrderValue?.otp?.length == 4 && (
                <butoon
                  className="otp_div_recev"
                  onClick={() => verifyOtpSubmit()}
                >
                  Verify OTP
                </butoon>
              )}
              {successMsg && (
                <span
                  className={
                    successMsg === "OTP verification failed"
                      ? "verify_red"
                      : "verify_green"
                  }
                >
                  {successMsg}
                </span>
              )}
            </div>

            {/* </div> */}
          </div>
        }
      />

      {isOpenModal4 && (
        <CustomOffcanvas
          isOpen={isOpenModal4}
          toggle={() => {
            setIsOpenModal4(!isOpenModal4);
            setPreview("");
            setIsEdit(false);
            clearAll();
          }}
          isLoadding={isLoadding}
          customeClass="customeClassForTrip"
          onSubmit={(e) => handleUpdateSelect()}
          title={isEdit ? "Edit Customer" : "Fund Allocation"}
          submitLabel={isEdit ? "Save" : "Continue"}
          inputProps={
            <div className="fundMainDiv">
              <div className="fundtext tablePadding">
                <div className="fundtext2">
                  <spna>{`Truck Number- ${formOrderValue.truckNumer}`}</spna>
                  <spna>{`Driver Name- ${formOrderValue.driverName}`}</spna>
                </div>
                <spna>{`Customer - ${customerValues.label}`}</spna>
              </div>

              <div className="anvOt_outerFull tablePadding">
                <div className="anvOt_inputField ">
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Route"
                    label="Select Route"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="branch"
                    options={routeList ? routeList : []}
                    valueText="id"
                    labelText="name"
                    focus={!!formError?.route}
                    error={formError?.route}
                    disabled={true}
                    selectedOption={formOrderName?.route}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, route: data });
                      handleOrderChange("route", data?.value);
                    }}
                  />
                </div>
              </div>

              <div className="expTable_main">
                <div className="tableMainHeadding hedding_bold">Expenses</div>
                <div>
                  <table className="table fundTable">
                    <thead>
                      <tr>
                        <th>
                          Category{" "}
                          <span style={{ color: "red", marginRight: "2px" }}>
                            *
                          </span>
                        </th>
                        <th>Description </th>
                        <th>Mode of Payment</th>
                        <th>Amount</th>
                        <th>
                          Currency{" "}
                          <span style={{ color: "red", marginRight: "2px" }}>
                            *
                          </span>
                        </th>
                        <th className="delHead"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {expenseList?.length &&
                        expenseList?.map((item, index) => {
                          return (
                            <MoreExpanse
                              updateExpoDetails={updateExpoDetails}
                              handleOrderChange={handleOrderChange}
                              index={index}
                              item={item}
                              expenseList={expenseList}
                              formError={formError}
                              setFormError={setFormError}
                              setFormOrderName={setFormOrderName}
                              formOrderName={formOrderName}
                              handleDeleteExpense={handleDeleteExpense}
                              setOpenModal={setOpenModal}
                              currencyList={currencyList}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                  <button className="addfundMoreBtn" onClick={addNewExpo}>
                    + Add Expenses
                  </button>
                </div>
              </div>

              <div className="expTable_main">
                <div className="tableMainHeadding hedding_bold">Fuel</div>
                <div>
                  <table className="table fundTable">
                    <thead>
                      <tr>
                        <th>
                          Fuel Station Name{" "}
                          <span style={{ color: "red", marginRight: "2px" }}>
                            *
                          </span>
                        </th>
                        <th>Description </th>
                        <th>Qty</th>
                        <th className="delHead"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fuelList?.map((item, index) => {
                        return (
                          <MoreFuel
                            updateFuelDetails={updateFuelDetails}
                            handleOrderChange={handleOrderChange}
                            index={index}
                            item={item}
                            fuelList={fuelList}
                            formError={formError}
                            handleDeleteFuel={handleDeleteFuel}
                            setFormOrderValue={setFormOrderValue}
                            setFormOrderName={setFormOrderName}
                            formOrderValue={formOrderValue}
                            formOrderName={formOrderName}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                  <button
                    className="addfundMoreBtn marginB"
                    onClick={addNewFuel}
                  >
                    + Add Fuel
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      <Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
      {openModal && (
        <InputModal
          isOpen={openModal}
          headerName={isEdit ? "Edit Branch" : "Add Currency"}
          toggle={() => setOpenModal(!openModal)}
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Branch" : "Currency Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder={"Currency Name"}
              name="addCurrency"
              focus={!!formError.addCurrency}
              error={formError.addCurrency}
              value={formOrderValue.addCurrency}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}
      {openMap && (
        <InputModal
          isOpen={openMap}
          headerName={isEdit ? "Edit Branch" : "Fleet Location"}
          toggle={() => setOpenMap(!openMap)}
          customeClassName="mapView"
          // submitLabel={isEdit ? "Update Branch" : "Continue"}
          // onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          size="lg"
          mapModal={true}
          inputProps={
            <div className="details_map">
              <div className="details_main_map">
                <div className="details_card_map">
                  <h3>AS06BC2138</h3>
                  <p>
                    Itanagar, Arunachal Pradesh. 12 M From Sri Sri Radha Krishna
                    Mandir Pin-791111 (India)
                  </p>
                  <span className="tripDriver_colr">
                    Stopped since: 2 hours 40 minutes
                  </span>
                  <div className="">
                    <span>Driver- Driver A</span>
                    <span>Trip- Not Assigned</span>
                  </div>
                </div>
              </div>
              <div className="trip_map">
                {/* <NewGoogleMapComp /> */}
                <div className="dummyDiv"></div>
              </div>
            </div>
          }
        />
      )}
      {openRoute && (
        <CommonRoute
          isOpen={openRoute}
          setIsOpen={setOpenRoute}
          routeCallBack={routeCallBack}
          customerValue={customerValues}
          isDisableOpen={false}
        />
      )}

      {
        <CommonSignature
          open={openDrower.openPad}
          setOpen={setOpenDrower}
          signatureCallBack={signatureCallBack}
        />
      }
      {isDisablePopUp && (
        <CommonRoute
          isOpen={isDisablePopUp}
          setIsOpen={setDisablePop}
          isDisableOpen={true}
        />
      )}
    </GoogleMapsLoader>
    </>
  );
};
