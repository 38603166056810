import React, { useEffect, useState } from 'react'
import Dropdown from '../../Component/Common/Dropdown';
import { InputField } from '../../Component/Common/InputField';
import { getExpenseCategory } from '../../services/expenseCategory';
import DeleteIcon from "../../../image/deleteIcon1.png";
import { getCurrency } from '../../services/currencyService';
import { components } from "react-select";
import { handleKeyDown } from '../../helper';
import { MdOutlineDelete } from 'react-icons/md';

const routeList = [{ label: 'Account', value: 1 }, { label: 'Cash', value: 2 }]

export const MoreExpanse = ({ updateExpoDetails, setFormOrderName, formOrderName, index, item, formError, handleOrderChange, handleDeleteExpense, expenseList, setOpenModal, currencyList }) => {

    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const [exCategoryList, setExCategoryList] = useState([])
    const [currencyList2, setCurrency] = useState(currencyList)

    useEffect(() => {
        getExpenseList()

    }, [])
    useEffect(() => {
        setCurrency(currencyList)
    }, [currencyList])

    const CustomControl = ({ children, ...props }) => (
        <components.Menu {...props}>
            <button className="addMoreBtn" onClick={() => addNewEntryHandle(props?.selectProps?.placeholder, props?.selectProps?.inputValue)}>+ Add New {props?.selectProps?.inputValue ? ` -> "${props?.selectProps?.inputValue}"` : ""}</button>
            {children}

        </components.Menu>
    );

    const addNewEntryHandle = (PlaceName, inputvalue) => {

        switch (PlaceName) {

            case "Currency":
                // setFormOrderValue({ ...formOrderValue, addCountry: inputvalue, openName: "country" })
                setOpenModal(true)
                break;

            default:
                break;
        }
    }

    const getExpenseList = async () => {
        const res = await getExpenseCategory(countryObj)
        setExCategoryList(res?.results)

    }





    return (
        <tr key={index}>
            <td>
                <Dropdown
                    className='dropdownoforder'
                    placeholder={!item[index]?.category && "Select Category"}
                    // label="Select Route"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="ex_category"
                    options={exCategoryList ? exCategoryList : []}
                    valueText="id"
                    labelText="name"
                    focus={`${formError[`expense[${index}].category`]}` !== "undefined" ? !!`${formError[`expense[${index}].category`]}` : ""}
                    error={`${formError[`expense[${index}].category`]}` !== "undefined" ? `${formError[`expense[${index}].category`]}` : ""}
                    selectedOption={{ label: exCategoryList?.find((ex) => ex?.id === item?.category)?.name, value: exCategoryList?.find((ex) => ex?.id === item?.category)?.id }}
                    // selectedOption={formOrderName?.ex_category}
                    setSelectedOption={(data) => {

                        updateExpoDetails(index, { key: "category", value: data?.value })
                    }}
                />
            </td>

            <td className="fundinput">
                <div>
                    <InputField

                        labelClass="modal_label"
                        labelSpanClass="style_for_star"
                        placeholder="Description"
                        name="description"
                        type="text"
                        focus={`${formError[`expense[${index}].description`]}` !== "undefined" ? !!`${formError[`expense[${index}].description`]}` : ""}
                        error={`${formError[`expense[${index}].description`]}` !== "undefined" ? `${formError[`expense[${index}].description`]}` : ""}
                        value={item?.description}
                        onChange={({ target: { name, value } }) =>
                            updateExpoDetails(index, { key: "description", value: value })
                        }

                    />

                </div>
            </td>

            <td>
                <Dropdown
                    className='dropdownoforder'
                    placeholder="Cash / In Account"
                    // label="Select Route"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="modeOfPayment"
                    options={routeList ? routeList : []}
                    valueText="value"
                    labelText="label"
                    labelSpanClass="style_for_star"
                    focus={`${formError[`expense[${index}].modeOfPayment`]}` !== "undefined" ? !!`${formError[`expense[${index}].modeOfPayment`]}` : ""}
                    error={`${formError[`expense[${index}].modeOfPayment`]}` !== "undefined" ? `${formError[`expense[${index}].modeOfPayment`]}` : ""}
                    selectedOption={{ label: routeList.find((ex) => ex.value === item?.modeOfPayment)?.label, value: routeList.find((ex) => ex.value === item?.modeOfPayment)?.value }}
                    setSelectedOption={(data) => {
                        setFormOrderName({ ...formOrderName, modeOfPayment: data })
                        updateExpoDetails(index, { key: "modeOfPayment", value: data?.value })
                    }}
                />

            </td>
            <td className="fundinput2">
                <div>
                    < InputField
                        className="widthDriver"
                        labelClass="modal_label "
                        labelSpanClass="style_for_star"
                        placeholder="Amount"
                        name="amount"
                        type="text"
                        value={item?.amount}
                        onKeyDown={handleKeyDown}
                        focus={`${formError[`expense[${index}].amount`]}` !== "undefined" ? !!`${formError[`expense[${index}].amount`]}` : ""}
                        error={`${formError[`expense[${index}].amount`]}` !== "undefined" ? `${formError[`expense[${index}].amount`]}` : ""}

                        onChange={({ target: { name, value } }) =>
                            updateExpoDetails(index, { key: "amount", value: value })
                        }
                    />

                </div>
            </td>
            <td>
                <Dropdown
                    className='dropdownoforder'
                    placeholder="Currency"
                    // label="Select Route"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="currency"
                    options={currencyList2 ? currencyList2 : []}
                    valueText="id"
                    labelText="name"
                    // components={{ Menu: CustomControl }}
                    focus={`${formError[`expense[${index}].currency`]}` !== "undefined" ? !!`${formError[`expense[${index}].currency`]}` : ""}
                    error={`${formError[`expense[${index}].currency`]}` !== "undefined" ? `${formError[`expense[${index}].currency`]}` : ""}
                    selectedOption={{ label: currencyList?.find((ex) => ex?.id === item?.currency)?.name, value: currencyList?.find((ex) => ex?.id === item?.currency)?.id }}
                    // selectedOption={formOrderName?.ex_category}
                    setSelectedOption={(data) => {

                        updateExpoDetails(index, { key: "currency", value: data?.value })
                    }}
                />

            </td>
            <td className='deleteIconeWidth'>

                {expenseList.length !== 1 && (
                    <div><span className="_faDelete" onClick={(e) => handleDeleteExpense(index)} ><MdOutlineDelete /></span></div>)}
                    {/* // <img src={DeleteIcon} alt="" onClick={(e) => handleDeleteExpense(index)} />)} */}

            </td>
        </tr>
    )
}
