import React from "react";
import { FormFeedback, Label } from "reactstrap";
import "./CustomDatePicker.css";

export function CustomDatePicker(props) {
    const { onSelect, value, focus, error, placeholder, classNameHeight, label, type,pattern } = props;
    const invalid = !!(focus && error);
    const valid = !!(focus && !error);
    return (
        <div className="mainDate_picBx _anvFloatedDatePicker">
            <Label className="FormLableKaran"><span className="_likInpt">{label}</span></Label>
            <input
                {...props}
                // type={`${NewModal}` ? "datetime-local" : "date"}
                type={type}
                value={value}
                onChange={onSelect}
                invalid={invalid}
                valid={valid}
                // pattern={pattern}
                placeholder={placeholder}
                className={`cstFrm_inputs ${classNameHeight}`}
            />
            {type === "date" && <div className="dateSvg">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar"
                    viewBox="0 0 16 16"
                >
                    {" "}
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />{" "}
                </svg>
            </div>}
            {invalid && <FormFeedback>{error}</FormFeedback>}

        </div>
    );
}