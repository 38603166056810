import React, { useEffect, useState } from "react";
import { Label, Modal } from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { addDriverValidation, editDriverValidation, modalInputSchema } from "../../helper/errorSchema";
import { checkFormError, formatDate, handleKeyDown, } from "../../helper";
import { useMutation, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import ImageCropper from "../../Component/Common/ImageCropper";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import { addCountryApi, getCountry } from "../../services/countryService";
import { addDriver, } from "../../services/driverService";
import { components } from "react-select";
import { getCurrency } from "../../services/currencyService";
import { MdOutlineDelete } from "react-icons/md";


export const CommonDriver = (props) => {

    const { isOpen, setIsOpen, routeCallBack } = props
    const formObj = {
        number: "",
        date: formatDate(new Date(), '-'),
    };

    const orderObj = {
        name: "",
        mobile_number: "",
        emergency_mobile_number: "",
        is_mobile_access: false,
        opining_balance_date: formatDate(new Date(), '-'),
        opining_balance: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        password: "",
        confirm_password: "",
        license_number: "",
        license_expiry_date: formatDate(new Date(), '-'),
        license_file: null,
        passport_number: "",
        passport_expiry_date: formatDate(new Date(), '-'),
        passport_file: null,
        contract_expiry_date: formatDate(new Date(), '-'),
        contract_file: null,
        is_active: true,
        addCountry: ""
    }

    const orderObjName = {

        name: "",
        mobile_number: "",
        emergency_mobile_number: "",
        is_mobile_access: false,
        opining_balance_date: formatDate(new Date(), '-'),
        opining_balance: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        password: "",
        confirm_password: "",
        license_number: "",
        license_expiry_date: formatDate(new Date(), '-'),
        license_file: "",
        passport_number: "",
        passport_expiry_date: formatDate(new Date(), '-'),
        passport_file: "",
        contract_expiry_date: formatDate(new Date(), '-'),
        contract_file: "",
        is_active: true,
    }

    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true
    };
    let defultExpense = {
        dates: formatDate(new Date(), '-'),
        amounts: "",
        currencies: "",

    };

    const queryClient = useQueryClient();
    const [isEdit, setIsEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [formValue, setFormValue] = useState(formObj);
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formError, setFormError] = useState("");
    const [showCropper, setShowCropper] = useState(false);
    const [isLoadding, setIsLoadding] = useState(false)
    const [countryList, setCountryList] = useState()
    const [currencyList, setCurrencyList] = useState()
    const [currencyList2, setCurrencyList2] = useState()
    const [currencyArray, setCurrencyArray] = useState([])
    const [inputValue, setInputValue] = useState({
        excel_file: "",
    });
    const [inputError, setInputError] = useState({
        excel_file: "",
    });

    const [expenseList, setExpenseList] = useState([
        defultExpense
    ])



    useEffect(() => {

        getCountryList()
        getCurrencyList()

    }, [])

    useEffect(() => {

        if (currencyArray?.length > 0) {
            const nothing = currencyList?.filter((item) => !currencyArray.includes(item.id))
            setCurrencyList2(nothing)
        } else {
            setCurrencyList2(currencyList)
        }

    }, [currencyArray])


    const getCountryList = async () => {
        const res = await getCountry(countryObj)

        setCountryList(res?.results)

    }

    const getCurrencyList = async () => {
        const res = await getCurrency(countryObj)
        setCurrencyList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))
        setCurrencyList2(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }


    const clearAll = () => {
        setFormOrderValue({
            name: "",
            mobile_number: "",
            emergency_mobile_number: "",
            is_mobile_access: false,
            opining_balance_date: formatDate(new Date(), '-'),
            opining_balance: "",
            address_one: '',
            address_two: '',
            country: "",
            state: "",
            city: "",
            pincode: '',
            password: "",
            confirm_password: "",
            license_number: "",
            license_expiry_date: formatDate(new Date(), '-'),
            license_file: "",
            passport_number: "",
            passport_expiry_date: formatDate(new Date(), '-'),
            passport_file: "",
            contract_expiry_date: formatDate(new Date(), '-'),
            contract_file: "",
            is_active: true,

        })
        setFormOrderName({
            name: "",
            mobile_number: "",
            emergency_mobile_number: "",
            is_mobile_access: false,
            opining_balance_date: formatDate(new Date(), '-'),
            opining_balance: "",
            address_one: '',
            address_two: '',
            country: "",
            state: "",
            city: "",
            pincode: '',
            password: "",
            confirm_password: "",
            license_number: "",
            license_expiry_date: formatDate(new Date(), '-'),
            license_file: "",
            passport_number: "",
            passport_expiry_date: formatDate(new Date(), '-'),
            passport_file: "",
            contract_expiry_date: formatDate(new Date(), '-'),
            contract_file: "",
            is_active: true,

        })

        setExpenseList([{
            dates: formatDate(new Date(), '-'),
            amounts: "",
            currencies: ""
        }])
        setCurrencyArray([])
        getCurrencyList()
        setIsEdit(false);
        setFormValue("");
        setFormError("");
        setInputError('');
        setInputValue({
            excel_file: ""
        })
    }


    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };


    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addDriverValidation
            );
            setValidationError(error);
        }
    };


    const onOrderSubmit = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue, expense: expenseList },
            isEdit ? editDriverValidation : addDriverValidation
        );


        const formData = new FormData();
        formData.append("name", formOrderValue?.name);
        formData.append("mobile_number", formOrderValue?.mobile_number);
        formData.append("emergency_mobile_number", formOrderValue?.emergency_mobile_number);
        formData.append("is_mobile_access", formOrderValue?.is_mobile_access);
        expenseList.map((item, index) => {
            formData.append(`dates[${index + 1}]`, item?.dates);
            formData.append(`amounts[${index + 1}]`, item?.amounts);
            formData.append(`currencies[${index + 1}]`, item?.currencies);

        })

        formData.append("address_one", formOrderValue?.address_one);
        formData.append("address_two", formOrderValue?.address_two);
        formData.append("country", formOrderValue?.country);
        formData.append("state", formOrderValue?.state);
        formData.append("city", formOrderValue?.city);
        formData.append("pincode", formOrderValue?.pincode);
        formData.append("password", formOrderValue?.password);
        formData.append("confirm_password", formOrderValue?.confirm_password);
        formData.append("license_number", formOrderValue?.license_number);
        formData.append("license_expiry_date", formOrderValue?.license_expiry_date);
        if (formOrderValue?.license_file) formData.append("license_file", formOrderValue?.license_file);

        formData.append("passport_number", formOrderValue?.passport_number);
        formData.append("passport_expiry_date", formOrderValue?.passport_expiry_date);
        if (formOrderValue?.passport_file) formData.append("passport_file", formOrderValue?.passport_file);

        formData.append("contract_expiry_date", formOrderValue?.contract_expiry_date);
        if (formOrderValue?.contract_file) formData.append("contract_file", formOrderValue?.contract_file);

        formData.append("is_active", formOrderValue?.is_active);

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate(formData);
            } else {
                handleAdd(formData);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };


    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };


    const handleUpdate = async (data) => {

    };

    const addMutation = useMutation(addDriver, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                routeCallBack(res?.results)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });


    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success", { position: "bottom-left" },);
        queryClient.refetchQueries("getAllDriver");
        setIsOpen(false);
        setIsLoadding(false)
        clearAll()
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
        setIsLoadding(false)
    };



    /// ----------- add Branch ---------

    const CustomControl = ({ children, ...props }) => (
        <components.Menu {...props}>
            <button className="addMoreBtn" onClick={() => addNewEntryHandle(props?.selectProps?.placeholder, props?.selectProps?.inputValue)}>+ Add New {props?.selectProps?.inputValue ? ` -> "${props?.selectProps?.inputValue}"` : ""}</button>
            {children}

        </components.Menu>
    );

    const addNewEntryHandle = (PlaceName, inputvalue) => {

        switch (PlaceName) {

            case "Select Country":
                setFormOrderValue({ ...formOrderValue, addCountry: inputvalue, openName: "country" })
                setOpenModal(true)
                break;

            default:
                break;
        }
    }

    const addNewSubmit = async (value) => {


        const validationResult = await checkFormError(
            { name: formOrderValue.addCountry },
            modalInputSchema
        )

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate();
            } else {
                handleAddNew(value);
            }
        } else {
            setFormError(validationResult);
            setIsLoadding(false)
        }

    };

    const handleAddNew = (value) => {

        const payload = {
            name: formOrderValue?.addCountry,
        }

        addNewCountryMutation.mutate(payload)

    }


    const addNewCountryMutation = useMutation(addCountryApi, {
        onSuccess: (res) => {
            if (res?.success) {
                // onSuccessResponse(res?.message);

                setFormOrderName({ ...formOrderName, country: { data: res?.results, label: res?.results?.name, value: res?.results?.id }, addCountry: "" })
                setFormOrderValue({ ...formOrderValue, country: res?.results?.id, addCountry: "" })
                setOpenModal(false)
                getCountryList()
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });




    const addNewExpo = (e) => {
        e.preventDefault();
        setExpenseList((preState) => {
            return [...preState, defultExpense]
        });
    }

    const updateExpoDetails = (index, data) => {
        const { key, value } = data;

        let newExpenseList = [...expenseList];
        newExpenseList[index][key] = value;
        setExpenseList(newExpenseList);

    }

    const handleDeleteExpense = (index) => {

        let List = [...expenseList]
        List.splice(index, 1,)
        setExpenseList(List);
        let newCurre = [...currencyArray];
        newCurre.splice(index, 1,)
        setCurrencyArray(newCurre)
    }


    return (
        <>

            {isOpen && <CustomOffcanvas
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(!isOpen)
                    clearAll()
                    setIsEdit(false);
                }
                }
                isLoadding={isLoadding}
                onSubmit={(e) => onOrderSubmit(e)}
                title={isEdit ? "Edit Driver" : "Add Driver"}
                submitLabel={isEdit ? "Update Driver" : "Save"}
                inputProps={
                    <div className="anvAdd_drive">
                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Driver Name" : "Driver Name"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Name"
                                    name="name"
                                    type="text"
                                    focus={!!formError?.name}
                                    error={formError.name}
                                    value={formOrderValue?.name}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Contact Number" : "Contact Number"}
                                    labelClass="FormLableKaran"
                                    placeholder="Contact Number"
                                    name="mobile_number"
                                    maxLength={12}
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.mobile_number}
                                    error={formError.mobile_number}
                                    value={formOrderValue?.mobile_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Emergency Contact Number" : "Emergency Contact Number"}
                                    labelClass="FormLableKaran"
                                    placeholder="Emergency Contact Number"
                                    name="emergency_mobile_number"
                                    type="text"
                                    maxLength={12}
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.emergency_mobile_number}
                                    error={formError.emergency_mobile_number}
                                    value={formOrderValue?.emergency_mobile_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField "></div>
                            <div className="anvOt_inputField checkboxtext mb-0">
                                <InputField
                                    className="modal_input "
                                    labelClass="FormLableKaran"
                                    placeholder="Company Registration Number"
                                    name="is_mobile_access"
                                    type="checkbox"
                                    focus={!!formError?.is_mobile_access}
                                    error={formError.is_mobile_access}
                                    value={formOrderValue?.is_mobile_access}
                                    checked={formOrderValue.is_mobile_access === true}

                                    onChange={({ target: { name, checked } }) => {

                                        handleOrderChange(name, checked)
                                    }
                                    }
                                />
                                <sapn className='checkboxTextbox'>Mobile App Access</sapn>

                            </div>
                        </div>

                        {/* <div className="anvOt_gridBx ">
                            <div className="anvOt_outerFull">
                                
                            </div>
                            <div className="anvOt_outerFull">
                                
                            </div>
                        </div> */}


                        {formOrderValue?.is_mobile_access && <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">

                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Set Password" : "Set Password"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Password"
                                    name="password"
                                    type="password"
                                    focus={!!formError?.password}
                                    error={formError.password}
                                    value={formOrderValue?.password}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            {/* } */}
                            <div className="anvOt_inputField ">

                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Repeat Password" : "Repeat Password"}
                                    labelClass="FormLableKaran"
                                    placeholder="Repeat Password"
                                    name="confirm_password"
                                    type="password"
                                    focus={!!formError?.confirm_password}
                                    error={formError.confirm_password}
                                    value={formOrderValue?.confirm_password}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>

                        </div>}


                        <div className="anvOt_outerFull partMarg">
                            <span>Account Details</span>
                        </div>
                        {expenseList?.map((onExpo, index) => <div className="anvOt_gridBx ">


                            <div className="anvOt_inputField ">

                                <CustomDatePicker
                                    type="date"
                                    label="Opening Balance Date"
                                    value={onExpo?.dates}
                                    max={formatDate(new Date())}
                                    focus={`${formError[`expense[${index}].dates`]}` !== "undefined" ? !!`${formError[`expense[${index}].dates`]}` : ""}
                                    error={`${formError[`expense[${index}].dates`]}` !== "undefined" ? `${formError[`expense[${index}].dates`]}` : ""}
                                    name="dates"
                                    onSelect={({ target: { name, value } }) => {
                                        handleOrderChange(name, value)
                                        setFormOrderName({ ...formOrderName, opining_balance_date: value })
                                        updateExpoDetails(index, { key: "dates", value: value })

                                    }
                                    }
                                    className="date-input"
                                // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                // className="date-input"
                                />

                            </div>

                            <div className="anvOt_inputField diplayDriver">

                                <div className="_crrOpening">
                                    <InputField
                                        className="modal_input orderModal_Input widthDriver"
                                        label={isEdit ? "Opening Balance (Dr. / Cr.)" : " Opening Balance (Dr. / Cr.)"}
                                        labelClass="FormLableKaran"
                                        placeholder=""
                                        name="amounts"
                                        type="text"
                                        focus={`${formError[`expense[${index}].amounts`]}` !== "undefined" ? !!`${formError[`expense[${index}].amounts`]}` : ""}
                                        error={`${formError[`expense[${index}].amounts`]}` !== "undefined" ? `${formError[`expense[${index}].amounts`]}` : ""}
                                        value={onExpo?.amounts}
                                        onChange={({ target: { name, value } }) => {
                                            updateExpoDetails(index, { key: "amounts", value: value })
                                            handleOrderChange(name, value)
                                        }


                                        }
                                    />
                                </div>

                                <div className="_crrOpening">

                                    <Dropdown
                                        className='dropdownoforder widthDriverDrop w-100'
                                        placeholder="currency"
                                        label="currency"
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect"
                                        name="currencies"
                                        options={currencyList2 ? currencyList2 : []}
                                        valueText="id"
                                        labelText="name"
                                        focus={`${formError[`expense[${index}].currencies`]}` !== "undefined" ? !!`${formError[`expense[${index}].currencies`]}` : ""}
                                        error={`${formError[`expense[${index}].currencies`]}` !== "undefined" ? `${formError[`expense[${index}].currencies`]}` : ""}
                                        selectedOption={currencyList?.find((item) => item?.id === onExpo.currencies)}
                                        components={{ Menu: CustomControl }}
                                        setSelectedOption={(data) => {
                                            setFormOrderName({ ...formOrderName, currency: data })
                                            handleOrderChange("currencies", data?.value);
                                            updateExpoDetails(index, { key: "currencies", value: data?.value })
                                            let newCurre = [...currencyArray];
                                            newCurre.push(data?.value)
                                            setCurrencyArray(newCurre)

                                        }}
                                    />


                                </div>
                                <div className="deleteBoxDriver">
                                    {expenseList.length !== 1 && (
                                        <span className="_faDelete" onClick={(e) => handleDeleteExpense(index)}><MdOutlineDelete /></span>

                                        // <img src={DeleteIcon} alt="" onClick={(e) => handleDeleteExpense(index)} />
                                    )}
                                </div>
                            </div>


                        </div>)}
                        <button onClick={addNewExpo} className="addMoreButtonDriver">+ Add More </button>
                        <div className="anvOt_outerFull partMarg">
                            <span>Driver Address Details</span>
                        </div>
                        <div className="anvOt_gridBx ">

                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Address Line 1" : "Address Line 1"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="address_one"
                                    type="text"
                                    focus={!!formError?.address_one}
                                    error={formError.address_one}
                                    value={formOrderValue?.address_one}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Address Line 2" : "Address Line 2"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="address_two"
                                    type="text"
                                    focus={!!formError?.address_two}
                                    error={formError.address_two}
                                    value={formOrderValue?.address_two}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">

                                <Dropdown
                                    className='dropdownoforder'
                                    placeholder="Select Country"
                                    label="Select Country"
                                    classNamePrefix="_anvFloatSelect"
                                    boxClass="_anvFloatedSelect"
                                    name="country"
                                    options={countryList ? countryList : []}
                                    valueText="id"
                                    labelText="name"
                                    focus={!!formError?.country}
                                    error={formError?.country}
                                    selectedOption={formOrderName?.country}
                                    components={{ Menu: CustomControl }}
                                    setSelectedOption={(data) => {
                                        setFormOrderName({ ...formOrderName, country: data })
                                        handleOrderChange("country", data?.value);

                                    }}
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "State" : "State"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="state"
                                    type="text"
                                    focus={!!formError?.state}
                                    error={formError.state}
                                    value={formOrderValue?.state}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "City" : "City"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="city"
                                    type="text"
                                    focus={!!formError?.city}
                                    error={formError.city}
                                    value={formOrderValue?.city}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Pin Code" : "Pin Code"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="pincode"
                                    type="text"
                                    maxLength={7}
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.pincode}
                                    error={formError.pincode}
                                    value={formOrderValue?.pincode}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>



                        <div className="anvOt_outerFull partMarg">
                            <span>Document Details</span>
                        </div>
                        <div className="anvOt_gridBx ">

                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "License Number" : "License Number"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="license_number"
                                    type="text"
                                    maxLength={16}
                                    focus={!!formError?.license_number}
                                    error={formError.license_number}
                                    value={formOrderValue?.license_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <CustomDatePicker
                                    type="date"
                                    label="License Expiry Date"
                                    value={formOrderName?.license_expiry_date}
                                    max={formatDate(new Date())}
                                    focus={!!formError?.license_expiry_date}
                                    error={formError?.license_expiry_date}
                                    name="license_expiry_date"
                                    onSelect={({ target: { name, value } }) => {
                                        handleOrderChange(name, value)
                                        setFormOrderName({ ...formOrderName, license_expiry_date: value })
                                    }
                                    }
                                    className="date-input"
                                // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                // className="date-input"
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Attach License" : "Attach License"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="license_file"
                                    type="file"
                                    focus={!!formError?.license_file}
                                    error={formError.license_file}
                                    // value={formOrderValue?.license_file}
                                    onChange={({ target: { name, files } }) =>
                                        handleOrderChange(name, files[0])
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Passport Number" : "Passport Number"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="passport_number"
                                    type="text"
                                    maxLength={12}
                                    focus={!!formError?.passport_number}
                                    error={formError.passport_number}
                                    value={formOrderValue?.passport_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <CustomDatePicker
                                    type="date"
                                    label="Passport Expirty Date"
                                    value={formOrderName?.passport_expiry_date}
                                    max={formatDate(new Date())}
                                    focus={!!formError?.passport_expiry_date}
                                    error={formError?.passport_expiry_date}
                                    name="passport_expiry_date"
                                    onSelect={({ target: { name, value } }) => {
                                        handleOrderChange(name, value)
                                        setFormOrderName({ ...formOrderName, passport_expiry_date: value })
                                    }
                                    }
                                    className="date-input"
                                // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                // className="date-input"
                                />

                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Attach Passport" : "Attach Passport"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="passport_file"
                                    type="file"
                                    focus={!!formError?.passport_file}
                                    error={formError.passport_file}
                                    // value={formOrderValue?.passport_file}
                                    onChange={({ target: { name, files } }) =>
                                        handleOrderChange(name, files[0])
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                 <CustomDatePicker
                                            type="date"
                                            label="Contract Expiry Date"
                                            value={formOrderName?.contract_expiry_date}
                                            max={formatDate(new Date())}
                                            focus={!!formError?.contract_expiry_date}
                                            error={formError?.contract_expiry_date}
                                            name="contract_expiry_date"
                                            onSelect={({ target: { name, value } }) => {
                                                handleOrderChange(name, value)
                                                setFormOrderName({ ...formOrderName, contract_expiry_date: value })
                                            }
                                            }
                                            className="date-input"
                                        // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                        // className="date-input"
                                        />
                                    
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Attach Contract" : "Attach Contract"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="contract_file"
                                    type="file"
                                    focus={!!formError?.contract_file}
                                    error={formError?.contract_file}
                                    // value={formOrderValue?.contract_file}
                                    onChange={({ target: { name, files } }) =>
                                        handleOrderChange(name, files[0])
                                    }
                                />
                            </div>
                        </div>  

                    </div>

                }
            />}
            <Modal isOpen={showCropper} onClose={() => setShowCropper(true)} className="imageCrooperModal">
                {typeof showCropper !== "boolean" && (
                    <ImageCropper
                        setFormValue={setFormValue}
                        setShowCropper={setShowCropper}
                        image={showCropper}
                        cropShape="rect"
                        aspect={6 / 3}
                        formValue={formValue}
                    />
                )}

            </Modal>


            {openModal && < InputModal
                isOpen={openModal}
                headerName={isEdit ? "Edit Branch" : "Add Country"}
                toggle={() => setOpenModal(!openModal)}
                submitLabel={isEdit ? "Update Branch" : "Continue"}
                onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
                // disabled={isEdit ? inputValue.name === disableInp.name : ""}
                isLoadding={isLoadding}
                inputProps={

                    <InputField
                        className="modal_input"
                        label={isEdit ? "Edit Branch" : "Country Name"}
                        labelClass="modal_label"
                        labelSpan="*"
                        labelSpanClass="style_for_star"
                        placeholder={"Country Name"}
                        name="addCountry"
                        focus={!!formError.addCountry}
                        error={formError.addCountry}
                        value={formOrderValue.addCountry}
                        onChange={({ target: { name, value } }) =>
                            handleOrderChange(name, value)
                        }
                    />
                }
            />
            }
        </>
    );
};
