import { apiEndPoints, methodType, performRequest } from "./_apiConfig";

export const getAllFuelStation = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.fuelStation, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllFuelStationApi = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.fuelStationApi, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addFuelStation = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.fuelStation, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addFuelStationApi = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.fuelStationApi, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteFuelStation = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.fuelStation + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const deleteFuelStationApi = (data = {}) => {

    return performRequest(
        methodType.DELETE,
        apiEndPoints.fuelStationApi + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateFuelStation = (data = {}) => {

    return performRequest(
        methodType.PUT,
        apiEndPoints.fuelStation + `/${data?.id}`,
        data?.formdata,
        true,
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateFuelStationApi = (data = {}) => {

    return performRequest(
        methodType.PUT,
        apiEndPoints.fuelStationApi + `/${data?.id}`,
        data?.formData,
        
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateActiveFuelStation = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fuelStation + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateActiveFuelStationApi = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fuelStationApi + `/${data?.id}`,
        payload,
        
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleFuelStationDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.fuelStation + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

