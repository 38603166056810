import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { modalInputSchema } from "../../helper/errorSchema";
import { breadcrumbsMenu, checkFormError, pageCount } from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import { addBranchApi, deleteBranchApi, getBranch, updateBranchApi } from "../../services";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import CheckboxField from "../../Component/Common/CheckboxField";
import mytruck from "../../../image/tanker.png"
import { addUnitApi, deleteUnitApi, getUnit, updateUnitApi } from "../../services/unitService";
import { addExpenseCategoryApi, deleteExpenseCategoryApi, getExpenseCategory, updateActiveExpenseCategory, updateExpenseCategoryApi } from "../../services/expenseCategory";
import { getStorage } from "../../helper/storage";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

const dummyDrop = [
    { label: "one", value: 1 },
    { label: "two", value: 2 },
    { label: "three", value: 3 },
];

export const ExpenseCategory = () => {
    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id"
    };
    const [isOpen, setIsOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [isLoadding, setIsLoadding] = useState(false)
    const [loaderForList, setLoaderForList] = useState(false)
    const queryClient = useQueryClient();
    const [filter, setFilter] = useState({
        limit: 20,
        ...obj,
    });
    const debouncedFilter = useDebounce(filter, 500);
    const userLogin = getStorage("logged-in");
    const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Expense Category"))[0]?.
        permissions : []
    const { data, isSuccess } = useQuery(
        ["getExpenseCategory", debouncedFilter],
        () => getExpenseCategory(debouncedFilter, filter),
        { enabled: Boolean(debouncedFilter) }
    );


    useEffect(() => {
        if (data) {
            setLoaderForList(false)
        } else {
            setLoaderForList(true)
        }
    }, [data])
    const [inputValue, setInputValue] = useState({
        name: "",
    });
    const [inputError, setInputError] = useState({
        name: "",
    });
    const [disableInp, setDisableInp] = useState({
        name: "",
    });

    const closeModal = () => {
        setIsOpen(true);
        setIsEdit(false);
        setInputValue("");
        setInputError("");
    };

    const onSubmit = async () => {
        const validationResult = await checkFormError(
            { name: inputValue.name },
            modalInputSchema
        );
        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate();
            } else {
                handleAdd();
            }
        } else {
            setInputError(validationResult);
            setIsLoadding(false)
        }
    };

    const handleOnChange = async (name, value) => {
        const stateObj = { ...inputValue, [name]: value }
        setInputValue(stateObj);
        if (!!inputError) {
            const error = await checkFormError(stateObj, modalInputSchema);
            setInputError(error);
        }
    };

    const handleAdd = () => {
        const payload = {
            name: inputValue.name,
        };
        addMutation.mutate(payload);
    };

    const handleDelete = () => {
        deleteMutation.mutate(deleteId);
    };
    const handleEditAction = (item) => {
        setDisableInp({ name: item.name });
        setInputValue({ id: item.id, name: item.name });
        setIsOpen(true);
        setIsEdit(true);
        setInputError("");
    };

    const handleUpdate = () => {
        const payload = {
            id: inputValue.id,
            name: inputValue.name,
        };
        updateMutation.mutate(payload);
    };

    const addMutation = useMutation(addExpenseCategoryApi, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res);
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    const updateMutation = useMutation(updateExpenseCategoryApi, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res);
                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    const deleteMutation = useMutation(deleteExpenseCategoryApi, {
        onSuccess: (res) => {

            if (res?.success) {
                onSuccessResponse(res);
                setOpenDel(false);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const hendleDeleteTag = (data, flag) => {
        setOpenDel(flag);
        setDeleteId(data)
    }

    const onSuccessResponse = (message) => {
        toast.success(message?.message ? message?.message : "success", { position: "bottom-left" });
        queryClient.refetchQueries("getExpenseCategory");
        setIsOpen(false);
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
    };

    const handlePageClick = async (data) => {
        const currentPage = data.selected;
        setFilter((s) => ({
            ...s,
            offset: currentPage * filter.limit,
            currentPage: currentPage,
        }));
    };

    const updateTagStatus = (item) => {

        const payload = {
            id: item.id,
            is_active: !item?.is_active
        }
        updateActiveMutation.mutate(payload);
    }
    const updateActiveMutation = useMutation(updateActiveExpenseCategory, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const column = [
        { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },

        { heading: "Expense", className: "sorting_asc", value: "name", sortName: "" },
        { heading: "Action", className: "sorting", value: "action", sortName: "" },
    ];

    const rowDataList = () => {


        const listArray = data?.results?.map((item, i) => ({
            ...item,
            check: (
                <>
                    <CheckboxField type="checkbox" name={item?.title} />
                </>
            ),
            sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
            icon: (
                <>
                    <img src={item?.icon} alt={item?.name} />
                </>
            ),
            action: (
                <>
                    <div className="actionBtn">
                        <SwitchField
                            onSwitchChange={() => updateTagStatus(item)}
                            value={item.is_active} />

                        {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span> : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
                        {userLogin?.user_type === 1 ? <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span> : permissions[4] && <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>}


                        {/* { userLogin?.user_type === 1 ? <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />: permissions[3] && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
            { userLogin?.user_type === 1 ? <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />: permissions[4] && <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />} */}
                    </div>
                </>
            ),
        }));
        return listArray;
    };

    return (
        <>
            <div className="ques_container">
                <Breadcrumbs
                    active="Expense"
                    action={
                        userLogin?.user_type === 1 ? <Button
                            type="button"
                            className="btn anime-btn ques_row_btn"
                            onClick={() => closeModal()}
                        >
                            Add Expense Category
                        </Button> : permissions[2] && <Button
                            type="button"
                            className="btn anime-btn ques_row_btn"
                            onClick={() => closeModal()}
                        >
                            Add Expense Category
                        </Button>
                    }
                />
                <div className="anvTask_prog">
                    <div className="anvInputs&fillters">


                        <div className="inr_flex_mini ">
                            <Dropdown
                                placeholder="Enable"
                                option={dummyDrop}
                                valueText="value"
                                labelText="label"
                                label="Enable"
                                classNamePrefix="_anvFloatSelect"
                                boxClass="_anvFloatedSelect"
                            />
                        </div>

                        <div className="inr_flex">
                            <InputField placeholder="Search"
                                label="Search"
                                className="anvSearch_input"
                                value={filter?.search}
                                onChange={(e) =>
                                    setFilter((s) => ({
                                        ...s,
                                        ...obj,
                                        search: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="table-responsive">
                        {/* <CustomTable
            
              data={rowDataList()}
            // tableLoading={tableLoading}
            /> */}
                        <CustomTable
                            column={column}
                            data={rowDataList()}
                            tableLoading={loaderForList}
                        />
                    </div>
                </div>
            </div>

            {data?.results?.length === 0 ? (
                ""
            ) : (
                <div className="pagination_count">
                    <div className="">
                        <Dropdown
                            placeholder="Select Count"
                            className="dropdownoforder orderModal_Input"
                            name="driver"
                            options={pageCount ? pageCount : []}
                            valueText="value"
                            labelText="label"
                            selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
                            setSelectedOption={(data) => {
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    limit: data.value,
                                }))
                                setInputValue({ ...inputValue, count: data });
                            }}

                        />
                    </div>

                    <CustomPagination
                        onPageChange={handlePageClick}
                        currentPage={filter?.currentPage}
                        pageCount={Math.ceil(data?.count / filter?.limit)}
                    />
                </div>
            )}

            <InputModal
                isOpen={isOpen}
                headerName={isEdit ? "Edit Expense" : "Add Expense"}
                toggle={() => setIsOpen(!isOpen)}
                submitLabel={isEdit ? "Update Expense" : "Continue"}
                onSubmit={(e) => onSubmit(e)}
                disabled={isEdit ? inputValue.name === disableInp.name : ""}
                isLoadding={isLoadding}
                inputProps={

                    <InputField
                        className="modal_input"
                        label={isEdit ? "Edit Expense Category" : "Expense Category"}
                        labelClass="modal_label"
                        labelSpan="*"
                        labelSpanClass="style_for_star"
                        placeholder="Expense Category"
                        name="name"
                        focus={!!inputError.name}
                        error={inputError.name}
                        value={inputValue.name}
                        onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                        }
                    />
                }
            />
            <ConfirmModal
                isOpen={openDel}
                toggle={() => setOpenDel(!openDel)}
                onSubmit={() => handleDelete()}
            />
        </>
    );
};
