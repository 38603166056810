import { performRequest, apiEndPoints, methodType } from "./_apiConfig";



export const getCurrency = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.currency, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addCurrencyApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.currency, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateCurrencyApi = (data = {}) => {
    const updateData = {
        name: data.name,
        exchange_rate:data?.exchange_rate
    }
    return performRequest(
        methodType.PUT,
        apiEndPoints.currency + `/${data?.id}`,
        updateData,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteCurrencyApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.currency + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}