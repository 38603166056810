import React, { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { InputField } from "../InputField";

const AutocompleteInput = ({ onPlaceSelected, value }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value || ""); 
  }, [value]);

  const onAutocompleteLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place && place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const addressComponents = place.formatted_address;

        setInputValue(addressComponents);
        onPlaceSelected({ lat, lng, addressComponents });
      } else {
        console.warn("Selected place does not have geometry data.");
      }
    } else {
      console.log("Autocomplete not loaded yet.");
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
      <InputField
        label="Google Location*"
        placeholder="Search Location on Google"
        id="google-search-input"
        className="autocomplete-dropdown"
        value={inputValue}
        onChange={handleInputChange}
      />
    </Autocomplete>
  );
};

export default AutocompleteInput;