import axios from "axios";
import { getStorage, clearStorage } from "../../helper/storage";
import { methodType } from "./apiEndPoints";

axios.interceptors.response.use(
  (response) => {
    const { data } = response;
    return data;
  },
  (error) => {
    const {
      response: { data },
      response,
    } = error;
    const exclude = "auth/login";
    if (response.status === 401 && !response.config?.url.includes(exclude)) {
      clearStorage();
      return Promise.reject(error);
    }
    if (data) {
      return data;
    } else {
      return Promise.reject(error);
    }
  }
);

export const performRequest = async (
  method,
  url,
  data = {},
  isLoggedin = true,
  formData = false,
  isBlob = false,
  karanUrl
) => {
  
  url = url.replaceAll("#", "%23");
  const config = {
    method,
    url,
    baseURL: process.env[process.env.REACT_APP_BASE_API] ,
  };
  if (isBlob) {
    config["responseType"] = "blob"
  }

  if (
    method === methodType.PUT ||
    method === methodType.PATCH ||
    method === methodType.POST ||
    method === methodType.DELETE
  ) {
    config.data = data;
  }

  if (method === methodType.GET) {
    config.params = data;
  }

  if (formData) {
    config.headers = {
      "Content-Type": "multipart/form-data",
    };
  } else {
    config.headers = {
      "Content-Type": "application/json; charset=utf-8",
    };
  }

  if (isLoggedin) {
    let { access } = getStorage("logged-in") || null;
    config.headers.Authorization = `Bearer ${access}`;
  }
  return axios.request(config);
};

export const performRequestKaran = async (
  method,
  url,
  data = {},
  isLoggedin = true,
  formData = false,
  isBlob = false,
 
) => {

  url = url.replaceAll("#", "%23");
  const config = {
    method,
    url,
    baseURL:"https://www.karan-oms.com/" ,
  };
  if (isBlob) {
    config["responseType"] = "blob"
  }

  if (
    method === methodType.PUT ||
    method === methodType.PATCH ||
    method === methodType.POST ||
    method === methodType.DELETE
  ) {
    config.data = data;
  }

  if (method === methodType.GET) {
    config.params = data;
  }

  if (formData) {
    config.headers = {
      "Content-Type": "multipart/form-data",
    };
    config.headers['Access-Control-Allow-Origin'] = '*';
  } else {
    config.headers = {
      "Content-Type": "*",

      // "Content-Type": "application/json; charset=utf-8",
    };
    config.headers['Access-Control-Allow-Origin'] = '*';
  }

  // if (isLoggedin) {
  //   let { access } = getStorage("logged-in") || null;
  //   config.headers.Authorization = `Bearer ${access}`;
  // }
  return axios.request(config);
};