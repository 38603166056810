import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { Label } from 'reactstrap'
import { addRouteTabList, addSingleRouteTabList, checkFormError, formatDate, getResposeError, handleKeyDown } from '../../helper'
import { addExpenseValidation, addFuelSingleValidation, addFuelValidation, addRouteDetails, modalInputSchema } from '../../helper/errorSchema'
import { getCountry } from '../../services/countryService'
import { addCurrencyApi, getCurrency } from '../../services/currencyService'
import { getExpenseCategory } from '../../services/expenseCategory'
import { getAllFuelStation } from '../../services/fuelStationService'
import { getAllDrop, getAllPickup } from '../../services/locationService'
import { getAllCustomer } from '../../services/researchService'
import { addRoute } from '../../services/routeService'
import InputModal from './ConstomModal/InputModal'
import Dropdown from './Dropdown'
import { InputField } from './InputField'
import { Tabs } from './Tabs'
import { components } from "react-select";


const CommonSingleRoute = (props) => {
    const { isOpen, setIsOpen, routeCallBack, customerValue } = props
   

    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id"
    };


    const fuelstationObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const customerObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const expenseObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true

    };
    const currencyObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };


    const formObj = {
        number: "",
        date: formatDate(new Date()),
    };

    const orderObj = {
        customer: customerValue?.value,
        from_location: [],
        to_location: [],
        route_name: '',
        kilometer: '',
        remark: "",
        is_active: true,
        addCurrency: "",
    }
    const orderObjName = {
        customer: customerValue,
        from_location: [],
        to_location: [],
        route_name: '',
        kilometer: '',
        remark: "",
        is_active: true,
        addCurrency: "",
    }


    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formError, setFormError] = useState("");
    const [activeModalTab, setActiveModalTab] = useState(1)
    const [customerList, setCustomerList] = useState()
  
    const [pickupList, setPickupList] = useState([])
    const [dropList, setDropList] = useState([])
    const [fromList, setFromList] = useState([{ label: "", value: "" }])
    const [toList, setToList] = useState([{ label: "", value: "" }])
    const [isLoadding, setIsLoadding] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    
    useEffect(() => {
     
        getCustomerList()
        getToLocatonList()
        getFromLocatonList()


    }, [])


    const getCustomerList = async () => {
        const res = await getAllCustomer(customerObj)
        const list = res.results?.map((item, i) => {
            return { name: item.name, id: item?.id }
        })

        setCustomerList(list)

    }

    const getFromLocatonList = async () => {
        const payload = {
            status: {
                limit: 20,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
            type: 1
        }
        const res = await getAllPickup(payload)
        setPickupList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getToLocatonList = async () => {
        const payload = {
            status: {
                limit: 20,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
            type: 2
        }
        const res = await getAllDrop(payload)
        setDropList(res?.results?.map((item) => {

            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const handleModalActive = (data) => {
        setActiveModalTab(data?.value)
    }

    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };
   

    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addRouteDetails);
            setValidationError(error);
        }
    };

    const onOrderSubmit = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue, from_location: fromList, to_location: toList },
            isEdit ? addFuelSingleValidation : addFuelSingleValidation
        );

        const payload = {
            customer: formOrderValue.customer,
            from_location: fromList?.map((i) => i?.id ? i?.id : i?.value),
            to_location: toList?.map((i) => i?.id ? i?.id : i?.value),
            route_name: formOrderValue.route_name,
            kilometer: formOrderValue.kilometer,
            remark: formOrderValue.remark,
            is_active: formOrderValue.is_active
        }



        if (validationResult === true) {

            setIsLoadding(true)
            if (isEdit === true) {

            } else {
                handleAdd(payload);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };

    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };

    const addMutation = useMutation(addRoute, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                routeCallBack(res?.results)
            } else {
                setIsLoadding(false)
                onErrorResponse(res?.error);
            }
        },
    });

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error"));
    };
    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success");

        // queryClient.refetchQueries("getAllRoute");
        setIsOpen(false);
        setIsLoadding(false)
        clearAll()

    };

    const clearAll = () => {
        setFormOrderValue({
            customer: "",
            from_location: [],
            to_location: [],
            route_name: '',
            kilometer: '',
            remark: "",
            expenses: [],
            fuel_expenses: [],
            is_active: true

        })
        setFormOrderName({
            customer: "",
            from_location: [],
            to_location: [],
            route_name: '',
            kilometer: '',
            remark: "",
            expenses: [],
            fuel_expenses: [],
            is_active: true

        })

        setIsEdit(false);
        
        setFromList([{ label: "", value: "" }])
        setToList([{ label: "", value: "" }])
        setFormError('')
        setActiveModalTab(1)
    }

    

    const addPickLoction = (e) => {
        e.preventDefault();
        setFromList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const addDropLoction = (e) => {
        e.preventDefault();
        setToList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }
  


    
    return (
        <div>
            <InputModal
                isOpen={isOpen}
                headerName={isEdit ? "Edit Route" : "Create Route"}
                toggle={() => {
                    // clearAll()
                    setIsOpen(!isOpen)

                }}
                submitLabel={"Save" }
                onSubmit={(e) =>  onOrderSubmit(e) }

                isLoadding={isLoadding}
                customeClassName="ModalRouteFlex"
                className="routemodalNew"
                inputProps={

                    <>
                        {isOpen && <div div className='TabHeadofmodal routemargin'> <Tabs TabList={addSingleRouteTabList} handleActive={handleModalActive} activeTab={+activeModalTab} customClassName="route" /></div>}
                        {+activeModalTab === 1 && <div className="anvMaintab_container_route">
                            <div className="dropdownMargin">
                                <span>
                                    <Label className="FormLableKaran">Customer Name</Label>
                                </span>
                                <div>
                                    <Dropdown
                                        className='dropdownoforder'
                                        classNamePrefix="mySelect"
                                        placeholder="Select Customer Name"
                                        name="customer"
                                        options={customerList ? customerList : []}
                                        valueText="id"
                                        labelText="name"
                                        focus={!!formError?.customer}
                                        error={formError?.customer}
                                        // component={{ Menu }}
                                        disabled="true"
                                        selectedOption={customerValue}
                                        setSelectedOption={(data) => {

                                            setFormOrderName({ ...formOrderName, customer: data })
                                            handleOrderChange("customer", data?.value);

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="dropdownMargin">
                                {fromList?.map((onFrom, index) => <>
                                    <span>
                                        <Label className="FormLableKaran">Pickup Location</Label>
                                    </span>

                                    <div>
                                        <Dropdown
                                            className='dropdownoforder '
                                            classNamePrefix="mySelect"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={pickupList ? pickupList : []}
                                            valueText="value"
                                            labelText="label"
                                            focus={`${formError[`from_location[${index}].label`]}` !== "undefined" ? !!`${formError[`from_location[${index}].label`]}` : ""}
                                            error={`${formError[`from_location[${index}].label`]}` !== "undefined" ? `${formError[`from_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, branch: data })
                                                const newFromList = [...fromList];
                                                newFromList[index] = data;
                                                setFromList(newFromList)

                                            }}
                                        />
                                    </div>
                                </>)}
                                <div className="addLoctionBtn">
                                    <span onClick={addPickLoction}>+ Add Pickup  Location</span>
                                </div>
                            </div>
                            <div className="dropdownMargin">
                                {toList?.map((onFrom, index) => <><span>
                                    <Label className="FormLableKaran">Drop Location</Label>
                                </span>
                                    <div>
                                        <Dropdown
                                            className='dropdownoforder'
                                            classNamePrefix="mySelect"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={dropList ? dropList : []}
                                            valueText="value"
                                            labelText="label"
                                            focus={`${formError[`to_location[${index}].label`]}` !== "undefined" ? !!`${formError[`to_location[${index}].label`]}` : ""}
                                            error={`${formError[`to_location[${index}].label`]}` !== "undefined" ? `${formError[`to_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {

                                                setFormOrderName({ ...formOrderName, branch: data })
                                                handleOrderChange("branch", data?.value);
                                                const newToList = [...toList];
                                                newToList[index] = data;
                                                setToList(newToList)
                                            }}
                                        />
                                    </div>
                                </>)}
                                <div className="addLoctionBtn">
                                    <span onClick={addDropLoction}>+ Add Drop Location</span>
                                </div>
                            </div>
                            <div className="inputMargin">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "Route Name" : "Route Name"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Route Name"
                                    name="route_name"
                                    type="text"
                                    focus={!!formError?.route_name}
                                    error={formError?.route_name}
                                    value={formOrderValue?.route_name}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            <div className="inputMargin">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "KMS" : "KMS"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter KMs"
                                    name="kilometer"
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.kilometer}
                                    error={formError?.kilometer}
                                    value={formOrderValue?.kilometer}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            <div className="inputMargin" >

                                <InputField
                                    className="modal_input routeModal_Input "
                                    label={isEdit ? "Remarks" : "Remarks"}
                                    labelClass="FormLableKaran"
                                    placeholder="Remarks"
                                    name="remarks"
                                    type="textarea"
                                    focus={!!formError?.remarks}
                                    error={formError?.remarks}
                                    value={formOrderValue?.remarks}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                        </div>}
                    </>
                }
            />

        </div>
    )
}

export default CommonSingleRoute
