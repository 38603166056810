import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import {
  addCategory,
  deleteCategory,
  getBranch,
  getCommunity,
  getTags,
  updateCategory,
} from "../../services";
import { toast } from "react-toastify";
import {
  addDriverValidation,
  editDriverValidation,
  modalFileSchema,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  driverTabList,
  formatDate,
  formatFileDisplay,
  handleKeyDown,
  locationTabList,
  orderTabList,
  pageCount,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import CheckboxField from "../../Component/Common/CheckboxField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  singleNewDetails,
  updateActiveResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Link } from "react-router-dom";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { addCountryApi, getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import {
  acceptAndRejectStatus,
  addFleetsForOrder,
  addOrder,
  getAllFleetforOrder,
  getAllOrder,
  getdryCargo,
  gettankersCsv,
} from "../../services/orderService";
import { getProduct } from "../../services/productService";
import {
  addDriver,
  deleteDriver,
  getAllDriver,
  singleDriverDetails,
  updateActiveDriver,
  updateDriver,
  updateWalletDriver,
  uploadExcelDriver,
} from "../../services/driverService";
import mytruck from "../../../image/tanker.png";
import { components } from "react-select";
import { getStorage, setStorage } from "../../helper/storage";
import { getCurrency } from "../../services/currencyService";
import { CiWallet } from "react-icons/ci";
import { Menu, MenuItem, Typography } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";

export const Driver = () => {
  const formObj = {
    number: "",
    date: formatDate(new Date(), "-"),
  };

  const orderObj = {
    name: "",
    mobile_number: "",
    emergency_mobile_number: "",
    is_mobile_access: false,
    opining_balance_date: formatDate(new Date(), "-"),
    opining_balance: "",
    address_one: "",
    address_two: "",
    branch: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
    confirm_password: "",
    license_number: "",
    license_expiry_date: formatDate(new Date(), "-"),
    license_file: null,
    passport_number: "",
    passport_expiry_date: formatDate(new Date(), "-"),
    passport_file: null,
    contract_expiry_date: formatDate(new Date(), "-"),
    contract_file: null,
    is_active: true,
    addCountry: "",
    count: "",
  };

  const orderObjName = {
    name: "",
    mobile_number: "",
    emergency_mobile_number: "",
    is_mobile_access: false,
    opining_balance_date: formatDate(new Date(), "-"),
    opining_balance: "",
    address_one: "",
    address_two: "",
    branch: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
    confirm_password: "",
    license_number: "",
    license_expiry_date: formatDate(new Date(), "-"),
    license_file: "",
    passport_number: "",
    passport_expiry_date: formatDate(new Date(), "-"),
    passport_file: "",
    contract_expiry_date: formatDate(new Date(), "-"),
    contract_file: "",
    is_active: true,
    count: "",
  };

  const Requirement = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },
    {
      heading: "Driver Name",
      className: "sorting",
      value: "driverName",
      sortName: "",
    },
    {
      heading: "Branch",
      className: "sorting",
      value: "branchName",
      sortName: "",
    },
    {
      heading: "Contact Number",
      className: "sorting_asc",
      value: "mobile_number",
      sortName: "",
    },
    {
      heading: "Available Balance",
      className: "sorting_asc",
      value: "opining_balance",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "driver_status",
      sortName: "",
    },
    {
      heading: "Expiry Date",
      className: "sorting_asc",
      value: "contract_expiry_date",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    mobile_number: "",
    name: "",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };
  let defultExpense = {
    dates: formatDate(new Date(), "-"),
    amounts: "",
    currencies: "",
    id: "",
    driver: "",
  };

  const objAttachment = {
    passport_file: null,
    contract_file: null,
    license_file: null,
  };

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userLogin = getStorage("logged-in");
  const result = userLogin?.user_branches?.join(",");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    branch: result,
    ...obj,
  });
  const [branchList, setBranchList] = useState();

  const [openModal, setOpenModal] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [initialformOrderValue, setInitialFormOrderValue] = useState(orderObj);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [isLoaddingAdd, setIsLoaddingAdd] = useState(false);
  const [countryList, setCountryList] = useState();
  const [currencyList, setCurrencyList] = useState();
  const [currencyList2, setCurrencyList2] = useState();
  const [currencyArray, setCurrencyArray] = useState([]);
  const [loaderForList, setLoaderForList] = useState(false);
  const [inputValue, setInputValue] = useState({
    excel_file: "",
  });
  const [inputError, setInputError] = useState({
    excel_file: "",
  });
  const [disableInp, setDisableInp] = useState({
    excel_file: "",
  });
  const debouncedFilter = useDebounce(filter, 500);

  const [expenseList, setExpenseList] = useState([defultExpense]);

  const [expenseListEdit, setExpenseListEdit] = useState([
    {
      dates: formatDate(new Date(), "-"),
      amounts: "",
      currencies: "",
    },
  ]);
  const [editAction, setEditAction] = useState("");
  const [response, setResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [itemId, setItemId] = useState("");
  const [preFilledFiles, setPreFilledFiles] = useState(objAttachment);
  const [fileNames, setFileNames] = useState(objAttachment);
  const [filePreviews, setFilePreviews] = useState(objAttachment);
  useEffect(() => {
    if (response) {
      if (editAction === "details") {
        setIsOpen(true);
      }

      if (editAction === "password") {
        setIsOpenPassword(true);
      }

      if (editAction === "wallet") {
        setIsOpenWallet(true);
      }
    }
  }, [response]);
  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseSelectMenu = (e) => {
    handleCloseUserMenu();
  };

  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };

  useEffect(() => {
    getBranchList();
  }, []);

  const findBranchName = (dataId)=>{
    const BranchName = branchList?.find((item)=>item?.id=== dataId)

    return BranchName?.name
  }

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      driverName: (
        <div
          className="driverName"
          onClick={() => {
            setStorage("driverID", item.id);
            setStorage("driverDetails", "Trip");
            navigate("/drivers-details");
          }}
        >
          {item.name}
        </div>
      ),
      branchName: (
        <span>{item?.branch ?findBranchName(item?.branch):"-------"}</span>
      ),
      serial: i + 1,
      driver_status: (
        <div className="statusofOrder">
          <span>Available</span>
        </div>
      ),
      status: (
        <div className="statusofOrder">
          {item?.status === 1 ? (
            <span>Requirement</span>
          ) : item?.status === 2 ? (
            <span>Pending</span>
          ) : item?.status === 3 ? (
            <span>Active Orders</span>
          ) : item?.status === 4 ? (
            <span>Near to Expair</span>
          ) : item?.status === 5 ? (
            <span>Cancelled</span>
          ) : item?.status === 6 ? (
            <span>Completed</span>
          ) : (
            ""
          )}
        </div>
      ),

      route: <div>{`${item?.from_location} < ${item?.to_location}`}</div>,

      product: <div>{item?.product_detail?.name}</div>,

      date: <div className="dateOfOredr">{formatDate(item?.added_on)}</div>,
      qty: `${item?.qty} Ltr`,

      action: (
        <>
          <div className="actionBtn">
            <div className="customerStatus">
              <SwitchField
                onSwitchChange={() => updateTagStatus(item)}
                value={item?.is_active}
              />
              {item?.is_active ? <span>Disable</span> : <span>Enable</span>}
            </div>
            <Menu
              className="other_menu"
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={(e) => {
                  handleEmptyFiles();
                  setEditAction("details");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Driver
              </MenuItem>
              {/* <MenuItem
                onClick={(e) => {
                  setEditAction("password");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Password
              </MenuItem> */}
              <MenuItem
                onClick={(e) => {
                  setEditAction("wallet");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Wallet
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setEditAction("password");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Password
              </MenuItem>
            </Menu>

            <span
              className="_faEdit"
              onClick={(e) => {
                setItemId(item);
                handleOpenUserMenu(e);
              }}
            >
              <FiEdit />
            </span>

            <span
              className="_faDelete"
              onClick={() => hendleDeleteTag(item, true)}
            >
              <MdOutlineDelete />
            </span>

            {/* <img
              src={EditIcon}
              alt=""
              onClick={(e) => {
                setItemId(item);
                handleOpenUserMenu(e);
              }}
            />
            <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            /> */}
            <CiWallet
              className="walletIng"
              onClick={() => {
                setStorage("driverID", item.id);
                setStorage("driverDetails", "wallet");
                navigate("/drivers-details");
              }}
            />
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllDriver", debouncedFilter],
    () => getAllDriver(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getCountryList();
    getCurrencyList();
  }, []);

  useEffect(() => {
    if (currencyArray?.length > 0) {
      const nothing = currencyList?.filter(
        (item) => !currencyArray.includes(item.id)
      );
      setCurrencyList2(nothing);
    } else {
      setCurrencyList2(currencyList);
    }
  }, [currencyArray]);

  const getCountryList = async () => {
    const res = await getCountry(countryObj);

    setCountryList(res?.results);
  };

  const getCurrencyList = async () => {
    const res = await getCurrency(countryObj);
    setCurrencyList(
      res?.results?.map((item) => {
        return { ...item, label: item?.name, value: item?.id };
      })
    );
    setCurrencyList2(
      res?.results?.map((item) => {
        return { ...item, label: item?.name, value: item?.id };
      })
    );
  };

  const clearAll = () => {
    setFormOrderValue({
      name: "",
      mobile_number: "",
      emergency_mobile_number: "",
      is_mobile_access: false,
      opining_balance_date: formatDate(new Date(), "-"),
      opining_balance: "",
      address_one: "",
      address_two: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      password: "",
      confirm_password: "",
      license_number: "",
      license_expiry_date: formatDate(new Date(), "-"),
      license_file: "",
      passport_number: "",
      passport_expiry_date: formatDate(new Date(), "-"),
      passport_file: "",
      contract_expiry_date: formatDate(new Date(), "-"),
      contract_file: "",
      is_active: true,
      count: "",
    });
    setFormOrderName({
      name: "",
      mobile_number: "",
      emergency_mobile_number: "",
      is_mobile_access: false,
      opining_balance_date: formatDate(new Date(), "-"),
      opining_balance: "",
      address_one: "",
      address_two: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      password: "",
      confirm_password: "",
      license_number: "",
      license_expiry_date: formatDate(new Date(), "-"),
      license_file: "",
      passport_number: "",
      passport_expiry_date: formatDate(new Date(), "-"),
      passport_file: "",
      contract_expiry_date: formatDate(new Date(), "-"),
      contract_file: "",
      is_active: true,
      count: "",
    });

    setExpenseList([
      {
        dates: formatDate(new Date(), "-"),
        amounts: "",
        currencies: "",
      },
    ]);
    setCurrencyArray([]);
    getCurrencyList();
    setIsEdit(false);
    setFormValue("");
    setFormError("");
    setInputError("");
    setInputValue({
      excel_file: "",
    });
    setEditAction("");
    setResponse("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addDriverValidation);
      setValidationError(error);
    }
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue, expense: expenseList },
      isEdit ? editDriverValidation : addDriverValidation
    );

    const formData = new FormData();
    formData.append("name", formOrderValue?.name);
    formData.append("mobile_number", formOrderValue?.mobile_number);
    formData.append(
      "emergency_mobile_number",
      formOrderValue?.emergency_mobile_number
    );
    formData.append("is_mobile_access", formOrderValue?.is_mobile_access);
    expenseList.map((item, index) => {
      formData.append(`dates[${index + 1}]`, item?.dates);
      formData.append(`amounts[${index + 1}]`, item?.amounts);
      formData.append(`currencies[${index + 1}]`, item?.currencies);
    });
    formData.append("branch", formOrderName?.branch?.data?.id);
    // formData.append("opining_balance_date", formOrderValue?.opining_balance_date);
    // formData.append("opining_balance", formOrderValue?.opining_balance);
    formData.append("address_one", formOrderValue?.address_one);
    formData.append("address_two", formOrderValue?.address_two);
    formData.append("country", formOrderValue?.country);
    formData.append("state", formOrderValue?.state);
    formData.append("city", formOrderValue?.city);
    formData.append("pincode", formOrderValue?.pincode);
    if (formOrderValue?.is_mobile_access) {
      formData.append("password", formOrderValue?.mobile_number);
      formData.append("confirm_password", formOrderValue?.mobile_number);
    }

    formData.append("license_number", formOrderValue?.license_number);
    formData.append("license_expiry_date", formOrderValue?.license_expiry_date);
    if (formOrderValue?.license_file)
      formData.append("license_file", formOrderValue?.license_file);

    formData.append("passport_number", formOrderValue?.passport_number);
    formData.append(
      "passport_expiry_date",
      formOrderValue?.passport_expiry_date
    );
    if (formOrderValue?.passport_file)
      formData.append("passport_file", formOrderValue?.passport_file);

    formData.append(
      "contract_expiry_date",
      formOrderValue?.contract_expiry_date
    );
    if (formOrderValue?.contract_file)
      formData.append("contract_file", formOrderValue?.contract_file);

    formData.append("is_active", formOrderValue?.is_active);

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd(formData);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleUploadExcel = (formData) => {
    uploadMutation.mutate(formData);
  };

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getDriverDetails.mutate(item?.id);
  };

  const getDriverDetails = useMutation(singleDriverDetails, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          name: res?.results?.name,
          mobile_number: res?.results?.mobile_number,
          emergency_mobile_number: res?.results?.emergency_mobile_number,
          is_mobile_access: res?.results?.is_mobile_access,
          opining_balance_date: res?.results?.opining_balance_date,
          opining_balance: res?.results?.opining_balance,
          address_one: res?.results?.address_one,
          address_two: res?.results?.address_two,
          country: res?.results?.country,
          state: res?.results?.state,
          city: res?.results?.city,
          pincode: res?.results?.pincode,
          license_number: res?.results?.license_number,
          license_expiry_date: res?.results?.license_expiry_date,
          passport_number: res?.results?.passport_number,
          passport_expiry_date: res?.results?.passport_expiry_date,
          contract_expiry_date: res?.results?.contract_expiry_date,
          is_active: res?.results?.is_active,
          branch: res?.results?.branch,
        });

        setInitialFormOrderValue({
          name: res?.results?.name,
          mobile_number: res?.results?.mobile_number,
          emergency_mobile_number: res?.results?.emergency_mobile_number,
          is_mobile_access: res?.results?.is_mobile_access,
          opining_balance_date: res?.results?.opining_balance_date,
          opining_balance: res?.results?.opining_balance,
          address_one: res?.results?.address_one,
          address_two: res?.results?.address_two,
          country: res?.results?.country,
          state: res?.results?.state,
          city: res?.results?.city,
          pincode: res?.results?.pincode,
          license_number: res?.results?.license_number,
          license_expiry_date: res?.results?.license_expiry_date,
          passport_number: res?.results?.passport_number,
          passport_expiry_date: res?.results?.passport_expiry_date,
          contract_expiry_date: res?.results?.contract_expiry_date,
          is_active: res?.results?.is_active,
        });

        setPreFilledFiles({
          passport_file: res?.results?.passport_file,
          contract_file: res?.results?.contract_file,
          license_file: res?.results?.license_file,
        });

        const newExpe = res.results?.driver_wallets?.map((data, index) => {
          return {
            dates: data?.date,
            amounts: data?.amount,
            currencies: data?.currency,
            id: data?.id,
            driver: data?.driver,
          };
        });

        const newCurren = res.results?.driver_wallets?.map(
          (data, index) => data?.currency
        );

        setCurrencyArray(newCurren);

        setExpenseList(newExpe);

        setExpenseListEdit(newExpe);

        const common = (name, id) => {
          if (name === "country") {
            let details = countryList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
        };

        const matchedBranch = branchList?.find(
          (item) => item?.id === res?.results?.branch
        );

        const displayBranch = {
          value: matchedBranch?.id,
          label: matchedBranch?.name,
        };

        setFormOrderName({
          ...formOrderName,
          country: common("country", res?.results?.country),
          opining_balance_date: res?.results?.opining_balance_date,
          passport_expiry_date: res?.results?.passport_expiry_date,
          contract_expiry_date: res?.results?.contract_expiry_date,
          license_expiry_date: res?.results?.license_expiry_date,
          branch: displayBranch,
        });

        setIsEdit(true);

        setResponse(res);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleFileChange = (name, file) => {
    if (!file) return;

    const previewUrl = URL.createObjectURL(file);

    setFilePreviews((prev) => ({
      ...prev,
      [name]: previewUrl,
    }));

    setFileNames((prev) => ({
      ...prev,
      [name]: file.name,
    }));
  };

  const handleUpdate = async (data) => {
    const formData = new FormData();
    if (isOpen === true) {
      Object.keys(formOrderValue).map((item, index) => {
        if (formOrderValue[item] !== initialformOrderValue[item]) {
          formData.append(item, formOrderValue[item]);
        }
      });
    }

    if (isOpenPassword === true) {
      Object.keys(formOrderValue).map((item, index) => {
        if (formOrderValue[item] !== initialformOrderValue[item]) {
          formData.append(item, formOrderValue[item]);
        }
      });
    }

    if (isOpenWallet) {
      // Object.keys(expenseList).map((item, index) => {
      //  if( expenseListEdit[index][item] ==! expenseList[index][item]){
      //   formData.append(item, expenseList[index][item]);
      //  }
      // })
      // if(expenseListEdit ==! expenseList){
      // expenseList?.map((item, index) => {
      //   formData.append(`dates[${index + 1}]`, item?.dates);
      //   formData.append(`amounts[${index + 1}]`, item?.amounts);
      //   formData.append(`currencies[${index + 1}]`, item?.currencies);
      // });
      // }
    }

    const payload = {
      formdata: formData,
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const handleWalletUpdate = async (data) => {
    const payload = {
      formdata: {
        date: "",
        currency: "",
        amount: "",
      },
      id: "",
    };
    const expenseValue = expenseList.map((i) => {
      return {
        formate: {
          date: i.dates,
          currency: i.currencies,
          amount: i.amounts,
        },
        id: i.id,
      };
    });

    expenseValue.map((item) => {
      updateWalletMutation.mutate(item);
    });
  };

  const updateWalletMutation = useMutation(updateWalletDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsLoaddingAdd(false);
        onSuccessResponse(res?.message);
      } else {
        setIsLoadding(false);
        setIsLoaddingAdd(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const uploadMutation = useMutation(uploadExcelDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsLoaddingAdd(false);
        onSuccessResponse(res?.message);
      } else {
        setIsLoadding(false);
        setIsLoaddingAdd(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const addMutation = useMutation(addDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteDriver, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllDriver");
    setIsOpen(false);
    setIsOpenPassword(false);
    setIsOpenWallet(false);
    setIsOpen1(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;

      default:
        return Requirement;
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalFileSchema);
      setInputError(error);
    }
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { excel_file: inputValue.excel_file },
      modalFileSchema
    );

    const formData = new FormData();
    formData.append("excel_file", inputValue?.excel_file);

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleUploadExcel(formData);
      }
    } else {
      setIsLoaddingAdd(false);
      setInputError(validationResult);
    }
  };

  /// ----------- add Branch ---------

  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Country":
        setFormOrderValue({
          ...formOrderValue,
          addCountry: inputvalue,
          openName: "country",
        });
        setOpenModal(true);
        break;

      default:
        break;
    }
  };

  const addNewSubmit = async (value) => {
    const validationResult = await checkFormError(
      { name: formOrderValue.addCountry },
      modalInputSchema
    );

    if (validationResult === true) {
      setIsLoaddingAdd(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAddNew(value);
      }
    } else {
      setFormError(validationResult);
      setIsLoaddingAdd(false);
    }
  };

  const handleAddNew = (value) => {
    const payload = {
      name: formOrderValue?.addCountry,
    };

    addNewCountryMutation.mutate(payload);
  };

  const addNewCountryMutation = useMutation(addCountryApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          country: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addCountry: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          country: res?.results?.id,
          addCountry: "",
        });
        setOpenModal(false);
        getCountryList();
        setIsLoaddingAdd(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoaddingAdd(false);
      }
    },
  });

  const addNewExpo = (e) => {
    e.preventDefault();
    setExpenseList((preState) => {
      return [...preState, defultExpense];
    });
  };

  const updateExpoDetails = (index, data) => {
    const { key, value } = data;

    let newExpenseList = [...expenseList];
    newExpenseList[index][key] = value;
    setExpenseList(newExpenseList);
  };

  const handleDeleteExpense = (index) => {
    let List = [...expenseList];
    List.splice(index, 1);
    setExpenseList(List);
    let newCurre = [...currencyArray];
    newCurre.splice(index, 1);
    setCurrencyArray(newCurre);
  };

  const showHandle = () => {
    setShowPassword(!showPassword);
  };
  const showHandleConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const getFileDisplayUrl = (name) =>
    filePreviews[name] || preFilledFiles[name];
  // const getFileName = (name) =>
  //   fileNames[name] || (preFilledFiles[name] && "Existing File");

  const getFileName = (name) => {
    if (fileNames[name]) {
      return formatFileDisplay(fileNames[name]);
    }
    if (preFilledFiles[name]) {
      return formatFileDisplay(preFilledFiles[name]);
    }

    return "No file chosen";
  };

  const handleEmptyFiles = () => {
    setPreFilledFiles(objAttachment);
    setFileNames(objAttachment);
    setFilePreviews(objAttachment);
  };

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={driverTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
        <div className="button_group">
          <button
            className="anime-btn addPlan"
            onClick={() => {
              setIsOpen(true);
              handleEmptyFiles();
            }}
          >
            Add Driver
          </button>
          <button
            className="anime-btn addPlan"
            onClick={() => setIsOpen1(true)}
          >
            Upload Excel
          </button>
        </div>
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>

          <div className="inr_flex">
            <InputField
              placeholder="Name"
              label="Name"
              className="anvSearch_input"
              value={filter?.name}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,

                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex">
            <InputField
              placeholder="Mobile number"
              label="Mobile number"
              className="anvSearch_input"
              value={filter?.mobile_number}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,

                  mobile_number: e.target.value,
                }))
              }
            />
          </div>
          {(filter?.name || filter?.mobile_number) && (
            <div className="inr_flex_mini">
              <button
                className="anime-btn reset_button"
                onClick={() => {
                  setFilter({
                    ...filter,

                    name: "",
                    mobile_number: "",
                  });
                  setFormOrderName({ ...formOrderName, branchFilter: "" });
                }}
              >
                Reset
              </button>
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                formOrderName?.count
                  ? formOrderName?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));

                setFormOrderName({ ...formOrderName, count: data });
              }}
            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen1}
        headerName={isEdit ? "Upload" : "Upload"}
        toggle={() => setIsOpen1(!isOpen1)}
        submitLabel={isEdit ? "Upload" : "Upload"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoaddingAdd}
        inputProps={
          <InputField
            className="modal_input"
            label={isEdit ? "Upload" : "Upload"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Upload"
            name="excel_file"
            type="file"
            focus={!!inputError.excel_file}
            error={inputError.excel_file}
            // value={inputValue.excel_file}
            onChange={({ target: { name, files } }) =>
              handleOnChange(name, files[0])
            }
          />
        }
      />

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e)}
          title={isEdit ? "Edit Driver" : "Add Driver"}
          submitLabel={isEdit ? "Update Driver" : "Save"}
          inputProps={
            <div className="anvAdd_drive">
              <div className="anvOt_gridBx ">
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Driver Name*" : "Driver Name*"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Name"
                    name="name"
                    type="text"
                    focus={!!formError?.name}
                    error={formError.name}
                    value={formOrderValue?.name}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Contact Number*" : "Contact Number*"}
                    labelClass="FormLableKaran"
                    placeholder="Contact Number"
                    name="mobile_number"
                    maxLength={12}
                    type="text"
                    onKeyDown={handleKeyDown}
                    focus={!!formError?.mobile_number}
                    error={formError.mobile_number}
                    value={formOrderValue?.mobile_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={
                      isEdit
                        ? "Emergency Contact Number"
                        : "Emergency Contact Number"
                    }
                    labelClass="FormLableKaran"
                    placeholder="Emergency Contact Number"
                    name="emergency_mobile_number"
                    type="text"
                    maxLength={12}
                    onKeyDown={handleKeyDown}
                    // focus={!!formError?.emergency_mobile_number}
                    // error={formError.emergency_mobile_number}
                    value={formOrderValue?.emergency_mobile_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <div>
                    <Dropdown
                      className="dropdownoforder"
                      placeholder="Select Branch"
                      label="Select Branch*"
                      classNamePrefix="_anvFloatSelect"
                      boxClass="_anvFloatedSelect"
                      name="branch"
                      options={branchList ? branchList : []}
                      valueText="id"
                      labelText="name"
                      focus={!!formError?.branch}
                      error={formError?.branch}
                      selectedOption={formOrderName?.branch}
                      components={
                        userLogin?.user_type == 1 ? { Menu: CustomControl } : ""
                      }
                      setSelectedOption={(data) => {
                        setFormOrderName({ ...formOrderName, branch: data });
                        handleOrderChange("branch", data?.value);
                      }}
                    />
                  </div>
                </div>
                <div className="anvOt_inputField checkboxtext mb-0">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_mobile_access"
                    type="checkbox"
                    focus={!!formError?.is_mobile_access}
                    error={formError.is_mobile_access}
                    value={formOrderValue?.is_mobile_access}
                    checked={formOrderValue.is_mobile_access === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox">Mobile App Access</sapn>
                </div>
              </div>

              {/* {isEdit === false && formOrderValue?.is_mobile_access && (
                <div className="anvOt_gridBx ">
                  <div className="anvOt_inputField ">
                    <div className="dropdaonAndselect">
                      <div className="anvSpecificDiv">
                        <InputField
                          className="modal_input orderModal_Input"
                          label={isEdit ? "Set Password" : "Set Password"}
                          labelClass="FormLableKaran"
                          placeholder="Enter Password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          data="password"
                          showPassword={showPassword}
                          showHandle={showHandle}
                          focus={!!formError?.password}
                          error={formError.password}
                          value={formOrderValue?.password}
                          onChange={({ target: { name, value } }) =>
                            handleOrderChange(name, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                 
                  <div className="anvOt_inputField ">
                    <div className="dropdaonAndselect">
                      <div className="anvSpecificDiv">
                        <InputField
                          className="modal_input orderModal_Input"
                          label={isEdit ? "Repeat Password" : "Repeat Password"}
                          labelClass="FormLableKaran"
                          placeholder="Repeat Password"
                          name="confirm_password"
                          type={showConfirmPassword ? "text" : "password"}
                          data="password"
                          showPassword={showConfirmPassword}
                          showHandle={showHandleConfirm}
                          focus={!!formError?.confirm_password}
                          error={formError.confirm_password}
                          value={formOrderValue?.confirm_password}
                          onChange={({ target: { name, value } }) =>
                            handleOrderChange(name, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {isEdit === false && (
                <>
                  <div className="anvOt_outerFull partMarg">
                    <span>Account Details</span>
                  </div>
                  {expenseList?.map((onExpo, index) => (
                    <div className="anvOt_gridBx mb-3">
                      <div className="anvOt_inputField ">
                        <div>
                          {/* <span>
                            <Label className="FormLableKaran">
                              Opening Balance Date
                            </Label>
                          </span> */}
                          <div>
                            <CustomDatePicker
                              type="date"
                              label="Opening Balance Date"
                              value={onExpo?.dates}
                              max={formatDate(new Date())}
                              focus={
                                `${formError[`expense[${index}].dates`]}` !==
                                "undefined"
                                  ? !!`${formError[`expense[${index}].dates`]}`
                                  : ""
                              }
                              error={
                                `${formError[`expense[${index}].dates`]}` !==
                                "undefined"
                                  ? `${formError[`expense[${index}].dates`]}`
                                  : ""
                              }
                              name="dates"
                              onSelect={({ target: { name, value } }) => {
                                handleOrderChange(name, value);
                                setFormOrderName({
                                  ...formOrderName,
                                  opining_balance_date: value,
                                });
                                updateExpoDetails(index, {
                                  key: "dates",
                                  value: value,
                                });
                              }}
                              className="date-input"
                              // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                              // className="date-input"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="anvOt_inputField diplayDriver">
                        <div className="_crrOpening">
                          <InputField
                            className="modal_input orderModal_Input widthDriver"
                            label={
                              isEdit
                                ? "Opening Balance (Dr. / Cr.)"
                                : " Opening Balance (Dr. / Cr.)"
                            }
                            labelClass="FormLableKaran"
                            placeholder=""
                            name="amounts"
                            type="text"
                            focus={
                              `${formError[`expense[${index}].amounts`]}` !==
                              "undefined"
                                ? !!`${formError[`expense[${index}].amounts`]}`
                                : ""
                            }
                            error={
                              `${formError[`expense[${index}].amounts`]}` !==
                              "undefined"
                                ? `${formError[`expense[${index}].amounts`]}`
                                : ""
                            }
                            value={onExpo?.amounts}
                            onChange={({ target: { name, value } }) => {
                              updateExpoDetails(index, {
                                key: "amounts",
                                value: value,
                              });
                              handleOrderChange(name, value);
                            }}
                          />
                        </div>
                        <div className="_crrOpening">
                          {/* <span>
                            <Label className="FormLableKaran">Currency</Label>
                          </span> */}
                          <Dropdown
                            className="dropdownoforder widthDriverDrop w-100"
                            placeholder="currency"
                            label="currency"
                            classNamePrefix="_anvFloatSelect"
                            boxClass="_anvFloatedSelect"
                            name="currencies"
                            options={currencyList2 ? currencyList2 : []}
                            valueText="id"
                            labelText="name"
                            focus={
                              `${formError[`expense[${index}].currencies`]}` !==
                              "undefined"
                                ? !!`${
                                    formError[`expense[${index}].currencies`]
                                  }`
                                : ""
                            }
                            error={
                              `${formError[`expense[${index}].currencies`]}` !==
                              "undefined"
                                ? `${formError[`expense[${index}].currencies`]}`
                                : ""
                            }
                            selectedOption={currencyList?.find(
                              (item) => item?.id === onExpo.currencies
                            )}
                            components={
                              userLogin?.user_type == 1
                                ? { Menu: CustomControl }
                                : ""
                            }
                            setSelectedOption={(data) => {
                              setFormOrderName({
                                ...formOrderName,
                                currency: data,
                              });
                              handleOrderChange("currencies", data?.value);
                              updateExpoDetails(index, {
                                key: "currencies",
                                value: data?.value,
                              });
                              let newCurre = [...currencyArray];
                              newCurre.push(data?.value);
                              setCurrencyArray(newCurre);
                            }}
                          />
                        </div>
                        <div className="deleteBoxDriver">
                          {expenseList.length !== 1 && (
                            <span
                              className="_faDelete"
                              onClick={(e) => handleDeleteExpense(index)}
                            >
                              <MdOutlineDelete />
                            </span>
                            // <img
                            //   src={DeleteIcon}
                            //   alt=""
                            //   onClick={(e) => handleDeleteExpense(index)}
                            // />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <button onClick={addNewExpo} className="addMoreButtonDriver">
                    + Add More{" "}
                  </button>
                </>
              )}
              <div className="anvOt_outerFull partMarg">
                <span>Driver Address Details</span>
              </div>
              <div className="anvOt_gridBx ">
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Address Line 1" : "Address Line 1"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="address_one"
                    type="text"
                    // focus={!!formError?.address_one}
                    // error={formError.address_one}
                    value={formOrderValue?.address_one}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Address Line 2" : "Address Line 2"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="address_two"
                    type="text"
                    // focus={!!formError?.address_two}
                    // error={formError.address_two}
                    value={formOrderValue?.address_two}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Country"
                    label="Select Country"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="country"
                    options={countryList ? countryList : []}
                    valueText="id"
                    labelText="name"
                    focus={!!formError?.country}
                    error={formError?.country}
                    selectedOption={formOrderName?.country}
                    components={{ Menu: CustomControl }}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, country: data });
                      handleOrderChange("country", data?.value);
                    }}
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "State" : "State"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="state"
                    type="text"
                    focus={!!formError?.state}
                    error={formError.state}
                    value={formOrderValue?.state}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "City*" : "City*"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="city"
                    type="text"
                    focus={!!formError?.city}
                    error={formError.city}
                    value={formOrderValue?.city}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Pin Code" : "Pin Code"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="pincode"
                    type="text"
                    maxLength={7}
                    onKeyDown={handleKeyDown}
                    // focus={!!formError?.pincode}
                    // error={formError.pincode}
                    value={formOrderValue?.pincode}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
              </div>

              {/* <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Country"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="country"
                                    type="text"
                                    focus={!!formError?.country}
                                    error={formError.country}
                                    value={formOrderValue?.country}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div> */}

              <div className="anvOt_outerFull partMarg">
                <span>Document Details</span>
              </div>
              <div className="anvOt_gridBx ">
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "License Number*" : "License Number*"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="license_number"
                    type="text"
                    maxLength={16}
                    focus={!!formError?.license_number}
                    error={formError.license_number}
                    value={formOrderValue?.license_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <CustomDatePicker
                    type="date"
                    label="License Expiry Date"
                    value={formOrderName?.license_expiry_date}
                    max={formatDate(new Date())}
                    focus={!!formError?.license_expiry_date}
                    error={formError?.license_expiry_date}
                    name="license_expiry_date"
                    onSelect={({ target: { name, value } }) => {
                      handleOrderChange(name, value);
                      setFormOrderName({
                        ...formOrderName,
                        license_expiry_date: value,
                      });
                    }}
                    className="date-input"
                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                    // className="date-input"
                  />
                </div>
                <div className="anvOt_inputField">
                  <InputField
                    id="license_file"
                    className="modal_input orderModal_Input llt_input_hide"
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="license_file"
                    type="file"
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    // focus={!!formError?.license_file}
                    // error={formError.license_file}
                    onChange={({ target: { name, files } }) => {
                      handleOrderChange(name, files[0]);
                      handleFileChange(name, files[0]);
                    }}
                  />
                  <div>
                    <label htmlFor="license_file">
                      <div className="llt_label_raletive">
                        {preFilledFiles.license_file ||
                        filePreviews.license_file ? (
                          <div className="ut_mc_upload_img_contener">
                            <div className="llt_input_file_img">
                              <img
                                src={getFileDisplayUrl("license_file")}
                                alt="license"
                              />
                            </div>
                            <p>{getFileName("license_file")}</p>
                            {typeof preFilledFiles.license_file === "string" &&
                              !filePreviews.license_file && (
                                <a
                                  href={preFilledFiles.license_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="llt_file_download_icon">
                                    <IoMdDownload />
                                  </div>
                                </a>
                              )}
                          </div>
                        ) : (
                          <div className="ut_mc_filecontener">
                            <span className="ut_mc_span_file">Choose file</span>
                            No file chosen
                          </div>
                        )}
                        <p className="llt_label_abs">Attach License</p>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Passport Number*" : "Passport Number*"}
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="passport_number"
                    type="text"
                    maxLength={12}
                    focus={!!formError?.passport_number}
                    error={formError.passport_number}
                    value={formOrderValue?.passport_number}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
                <div className="anvOt_inputField ">
                  <CustomDatePicker
                    type="date"
                    label="Passport Expirty Date"
                    value={formOrderName?.passport_expiry_date}
                    max={formatDate(new Date())}
                    focus={!!formError?.passport_expiry_date}
                    error={formError?.passport_expiry_date}
                    name="passport_expiry_date"
                    onSelect={({ target: { name, value } }) => {
                      handleOrderChange(name, value);
                      setFormOrderName({
                        ...formOrderName,
                        passport_expiry_date: value,
                      });
                    }}
                    className="date-input"
                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                    // className="date-input"
                  />
                </div>
                <div className="anvOt_inputField">
                  <InputField
                    id="passport_file"
                    className="modal_input orderModal_Input llt_input_hide"
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="passport_file"
                    type="file"
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    // focus={!!formError?.passport_file}
                    // error={formError.passport_file}
                    onChange={({ target: { name, files } }) => {
                      handleOrderChange(name, files[0]);
                      handleFileChange(name, files[0]);
                    }}
                  />
                  <div>
                    <label htmlFor="passport_file">
                      <div className="llt_label_raletive">
                        {preFilledFiles.passport_file ||
                        filePreviews.passport_file ? (
                          <div className="ut_mc_upload_img_contener">
                            <div className="llt_input_file_img">
                              <img
                                src={getFileDisplayUrl("passport_file")}
                                alt="passport"
                              />
                            </div>
                            <p>{getFileName("passport_file")}</p>
                            {typeof preFilledFiles.passport_file === "string" &&
                              !filePreviews.passport_file && (
                                <a
                                  href={preFilledFiles.passport_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="llt_file_download_icon">
                                    <IoMdDownload />
                                  </div>
                                </a>
                              )}
                          </div>
                        ) : (
                          <div className="ut_mc_filecontener">
                            <span className="ut_mc_span_file">Choose file</span>
                            No file chosen
                          </div>
                        )}
                        <p className="llt_label_abs">Attach Passport</p>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="anvOt_inputField ">
                  <CustomDatePicker
                    type="date"
                    label="Contract Expiry Date"
                    value={formOrderName?.contract_expiry_date}
                    max={formatDate(new Date())}
                    // focus={!!formError?.contract_expiry_date}
                    // error={formError?.contract_expiry_date}
                    name="contract_expiry_date"
                    onSelect={({ target: { name, value } }) => {
                      handleOrderChange(name, value);
                      setFormOrderName({
                        ...formOrderName,
                        contract_expiry_date: value,
                      });
                    }}
                    className="date-input"
                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                    // className="date-input"
                  />
                </div>

                <div className="anvOt_inputField">
                  <InputField
                    id="contract_file"
                    className="modal_input orderModal_Input llt_input_hide"
                    labelClass="FormLableKaran"
                    placeholder=""
                    name="contract_file"
                    type="file"
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    // focus={!!formError?.contract_file}
                    // error={formError?.contract_file}
                    onChange={({ target: { name, files } }) => {
                      handleOrderChange(name, files[0]);
                      handleFileChange(name, files[0]);
                    }}
                  />
                  <div>
                    <label htmlFor="contract_file">
                      <div className="llt_label_raletive">
                        {preFilledFiles.contract_file ||
                        filePreviews.contract_file ? (
                          <div className="ut_mc_upload_img_contener">
                            <div className="llt_input_file_img">
                              <img
                                src={getFileDisplayUrl("contract_file")}
                                alt="contract"
                              />
                            </div>
                            <p>{getFileName("contract_file")}</p>
                            {typeof preFilledFiles.contract_file === "string" &&
                              !filePreviews.contract_file && (
                                <a
                                  href={preFilledFiles.contract_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="llt_file_download_icon">
                                    <IoMdDownload />
                                  </div>
                                </a>
                              )}
                          </div>
                        ) : (
                          <div className="ut_mc_filecontener">
                            <span className="ut_mc_span_file">Choose file</span>
                            No file chosen
                          </div>
                        )}
                        <p className="llt_label_abs">Attach Contract</p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
      <Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />

      {openModal && (
        <InputModal
          isOpen={openModal}
          headerName={isEdit ? "Edit Branch" : "Add Country"}
          toggle={() => setOpenModal(!openModal)}
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoaddingAdd}
          inputProps={
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Branch" : "Country Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder={"Country Name"}
              name="addCountry"
              focus={!!formError.addCountry}
              error={formError.addCountry}
              value={formOrderValue.addCountry}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}

      <InputModal
        isOpen={isOpenPassword}
        headerName={isEdit ? "Edit Password" : "Add Branch"}
        toggle={() => {
          setIsEdit(false);
          clearAll();
          setIsOpenPassword(!isOpenPassword);
        }}
        submitLabel={isEdit ? "Update Password" : "Continue"}
        onSubmit={(e) => handleUpdate(e)}
        // disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoadding}
        inputProps={
          <>
            <div className="dropdaonAndselect">
              <div className="anvSpecificDiv">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Set Password" : "Set Password"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  data="password"
                  showPassword={showPassword}
                  showHandle={showHandle}
                  focus={!!formError?.password}
                  error={formError.password}
                  value={formOrderValue?.password}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
            <div className="dropdaonAndselect">
              <div className="anvSpecificDiv">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Repeat Password" : "Repeat Password"}
                  labelClass="FormLableKaran"
                  placeholder="Repeat Password"
                  name="confirm_password"
                  type={showConfirmPassword ? "text" : "password"}
                  data="password"
                  showPassword={showConfirmPassword}
                  showHandle={showHandleConfirm}
                  focus={!!formError?.confirm_password}
                  error={formError.confirm_password}
                  value={formOrderValue?.confirm_password}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
          </>
        }
      />
      <CustomOffcanvas
        isOpen={isOpenWallet}
        toggle={() => {
          clearAll();
          setIsEdit(false);
          setIsOpenWallet(!isOpenWallet);
        }}
        isLoadding={isLoadding}
        onSubmit={(e) => handleWalletUpdate(e)}
        title={isEdit ? "Edit Wallet" : "Add Branch"}
        submitLabel={isEdit ? "Update Driver" : "Save"}
        // disabled={isEdit ? inputValue.name === disableInp.name : ""}
        inputProps={
          <div className="anvAdd_drive">
            <div className="anvOt_outerFull partMarg mt-0">
              <span>Account Details</span>
            </div>
            {expenseList?.map((onExpo, index) => (
              <div className="anvOt_gridBx mb-3">
                <div className="anvOt_inputField ">
                  <CustomDatePicker
                    type="date"
                    label="Opening Balance Date"
                    value={onExpo?.dates}
                    max={formatDate(new Date())}
                    focus={
                      `${formError[`expense[${index}].dates`]}` !== "undefined"
                        ? !!`${formError[`expense[${index}].dates`]}`
                        : ""
                    }
                    error={
                      `${formError[`expense[${index}].dates`]}` !== "undefined"
                        ? `${formError[`expense[${index}].dates`]}`
                        : ""
                    }
                    name="dates"
                    onSelect={({ target: { name, value } }) => {
                      handleOrderChange(name, value);
                      setFormOrderName({
                        ...formOrderName,
                        opining_balance_date: value,
                      });
                      updateExpoDetails(index, {
                        key: "dates",
                        value: value,
                      });
                    }}
                    className="date-input"
                    // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                    // className="date-input"
                  />
                </div>
                <div className="anvOt_inputField diplayDriver">
                  <div className="_crrOpening">
                    <InputField
                      className="modal_input orderModal_Input widthDriver"
                      label={
                        isEdit
                          ? "Opening Balance (Dr. / Cr.)"
                          : " Opening Balance (Dr. / Cr.)"
                      }
                      labelClass="FormLableKaran"
                      placeholder=""
                      name="amounts"
                      type="text"
                      focus={
                        `${formError[`expense[${index}].amounts`]}` !==
                        "undefined"
                          ? !!`${formError[`expense[${index}].amounts`]}`
                          : ""
                      }
                      error={
                        `${formError[`expense[${index}].amounts`]}` !==
                        "undefined"
                          ? `${formError[`expense[${index}].amounts`]}`
                          : ""
                      }
                      value={onExpo?.amounts}
                      onChange={({ target: { name, value } }) => {
                        updateExpoDetails(index, {
                          key: "amounts",
                          value: value,
                        });
                        handleOrderChange(name, value);
                      }}
                    />
                  </div>

                  <div className="_crrOpening">
                    <Dropdown
                      className="dropdownoforder widthDriverDrop w-100"
                      placeholder="currency"
                      label="currency"
                      classNamePrefix="_anvFloatSelect"
                      boxClass="_anvFloatedSelect"
                      name="currencies"
                      options={currencyList2 ? currencyList2 : []}
                      valueText="id"
                      labelText="name"
                      focus={
                        `${formError[`expense[${index}].currencies`]}` !==
                        "undefined"
                          ? !!`${formError[`expense[${index}].currencies`]}`
                          : ""
                      }
                      error={
                        `${formError[`expense[${index}].currencies`]}` !==
                        "undefined"
                          ? `${formError[`expense[${index}].currencies`]}`
                          : ""
                      }
                      selectedOption={currencyList?.find(
                        (item) => item?.id === onExpo.currencies
                      )}
                      components={{ Menu: CustomControl }}
                      setSelectedOption={(data) => {
                        setFormOrderName({
                          ...formOrderName,
                          currency: data,
                        });
                        handleOrderChange("currencies", data?.value);
                        updateExpoDetails(index, {
                          key: "currencies",
                          value: data?.value,
                        });
                        let newCurre = [...currencyArray];
                        newCurre.push(data?.value);
                        setCurrencyArray(newCurre);
                      }}
                    />
                  </div>
                  <div className="deleteBoxDriver">
                    {expenseList.length !== 1 && (
                      <span
                        className="_faDelete"
                        onClick={(e) => handleDeleteExpense(index)}
                      >
                        <MdOutlineDelete />
                      </span>
                      // <img
                      //   src={DeleteIcon}
                      //   alt=""
                      //   onClick={(e) => handleDeleteExpense(index)}
                      // />
                    )}
                  </div>
                </div>
              </div>
            ))}
            <button onClick={addNewExpo} className="addMoreButtonDriver">
              + Add More{" "}
            </button>
          </div>
        }
      />
    </>
  );
};
