import {
  FormFeedback,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export const InputField = (props) => {
  const {
    error,
    label,
    focus,
    preAddon = false,
    postAddon = false,
    searchableInput = false,
    labelClass,
    boxClass,
    labelSpan,
    labelSpanClass,
    preAddonClass,
    maxLength,
    type,
    rows,
    disabled,
    innerRef,
    style,
    onKeyDown,
    data,
    showPassword,
    showHandle,
    value

  } = props;
  const invalid = !!(focus && error);
  const valid = !!(focus && !error);

  return (
    <FormGroup className={boxClass}>
      <InputGroup className="anvTxt_fielW anvCustom_floatInput form-floating ">

        {searchableInput && (
          <div className="_srhIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path opacity="0.3" d="M14.9998 14.9998L11.622 11.622M11.622 11.622C12.1998 11.0442 12.6581 10.3583 12.9708 9.60336C13.2835 8.84845 13.4444 8.03933 13.4444 7.22222C13.4444 6.40511 13.2835 5.59599 12.9708 4.84108C12.6581 4.08616 12.1998 3.40023 11.622 2.82245C11.0442 2.24466 10.3583 1.78633 9.60336 1.47364C8.84845 1.16094 8.03933 1 7.22222 1C6.40511 1 5.59599 1.16094 4.84108 1.47364C4.08616 1.78633 3.40023 2.24466 2.82245 2.82245C1.65555 3.98934 1 5.57198 1 7.22222C1 8.87245 1.65555 10.4551 2.82245 11.622C3.98934 12.7889 5.57198 13.4444 7.22222 13.4444C8.87245 13.4444 10.4551 12.7889 11.622 11.622Z" stroke="black" stroke-width="1.55551" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        )}

        {preAddon && <InputGroupText>{preAddon}</InputGroupText>}
        <Input invalid={invalid} valid={valid} {...props} value={value} autoComplete="off" style={style} type={type} rows={rows} disabled={disabled} innerRef={innerRef} onKeyDown={onKeyDown} />
        {label && (
          <Label className={labelClass}>
            <span className="_inLab">{label}</span>
            <span className={labelSpanClass}>{labelSpan}</span>
          </Label>
        )}

        {data !== "password" && <button className="fltBtn">{data?.data?.unit_details?.name}</button>}
        {data === "password" && (showPassword ? <BsEye className="fltBtn2" onClick={showHandle} /> : <BsEyeSlash className="fltBtn2" onClick={showHandle} />)}
        {postAddon && <InputGroupText>{postAddon}</InputGroupText>}
      </InputGroup>
      {invalid && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};
