import { apiEndPoints, methodType, performRequest } from "./_apiConfig";



export const getAllRoute = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.routes, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addRoute = (data = {}) => {

    return performRequest(methodType.POST, apiEndPoints.routes, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateRouteApi = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.routes + `/${data?.id}`,
        data.formdata,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteRouteApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.routes + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveRoute = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.routes + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleRouteDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.routes + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

