import { performRequest, apiEndPoints, methodType } from "./_apiConfig";


export const getFuelTransaction = async (data = {}) => {
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.fuelTransactionReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};


export const getVehicleReport = async (data = {}) => {
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.vehicleReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getOrdersDetails = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.ordersDetailsReport, data,true,false,true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getExpenseDetails = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.expenseDetailsReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getDriverTripReport = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.driverTripReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getTrailersDetails = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.trailersDetailsReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getDriverTransaction = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.driverTransactionReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getDriverDetails = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.driverDetailsReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};

export const getFleetDetails = async (data = {}) => {
    
    try {
        const response = await performRequest(methodType.GET, apiEndPoints.fleetDetailsReport, data, true, false, true);
        return response;
    } catch (error) {
        return error;
    }
};


  