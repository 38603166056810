import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label, Modal } from "reactstrap";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import {
  addLocationValidation,
  addResearchSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  formatDate,
  handleKeyDown,
  locationTabList,
  pageCount,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  getAllCustomer,
  singleNewDetails,
  updateActiveResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import mytruck from "../../../image/tanker.png";
import { components } from "react-select";

import {
  addLocation,
  deleteLocation,
  getAllLocation,
  singleLocationDetails,
  updateActiveLocation,
  updateLocation,
} from "../../services/locationService";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { getStorage } from "../../helper/storage";
import NewGoogleMapComp from "../../Component/Common/MapComponent/NewGoogleMapComp";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { GoogleMapsLoader } from "../../Component/Common/MapComponent/GoogleMapsLoader";
import AutocompleteInput from "../../Component/Common/MapComponent/AutocompleteInput";
import CheckboxField from "../../Component/Common/CheckboxField";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

export const Location = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id",
  };

  const customerObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    location_type: 1,
    customer: "",
    name: "",
    contact_number: "",
    address_one: "",
    address_two: "",
    country: "",
    city: "",
    is_active: true,
    count: "",
    formatted_address: "",
    coordinates: "",
    // radius: 100,
    geo_fencing: {},
  };
  const orderObjName = {
    location_type: "",
    customer: "",
    name: "",
    contact_number: "",
    address_one: "",
    address_two: "",
    country: "",
    city: "",
    is_active: true,
    count: "",
    formatted_address: "",
    coordinates: "",
    // radius: 100,
    geo_fencing: {},
  };
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const userLogin = getStorage("logged-in");
  const permissions = Object.values(userLogin?.user_permissions)?.length
    ? Object.values(userLogin?.user_permissions)?.filter((i) =>
        i?.module_name?.includes("Location")
      )[0]?.permissions
    : [];
  const [disableInp, setDisableInp] = useState(orderObj);
  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [countryList, setCountryList] = useState();
  const [downloadcsv, setDownload] = useState("");
  const [loaderForList, setLoaderForList] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [polygonValidataion, setPolygonValidation] = useState(false);

  const Requirement = [
    { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },
    {
      heading: "Location Type",
      className: "sorting",
      value: "locationType",
      sortName: "",
    },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customer_name",
      sortName: "",
    },
    {
      heading: "Location Name",
      className: "sorting_asc",
      value: "name",
      sortName: "",
    },
    { heading: "City", className: "sorting_asc", value: "city", sortName: "" },
    {
      heading: "Contact Number",
      className: "sorting_asc",
      value: "contact_number",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,

      locationType: item.location_type === 1 ? "Pickup" : "Drop",
      status: (
        <div className="statusofOrder">
          {item?.status === 1 ? (
            <span>Requirement</span>
          ) : item?.status === 2 ? (
            <span>Pending</span>
          ) : item?.status === 3 ? (
            <span>Active Orders</span>
          ) : item?.status === 4 ? (
            <span>Near to Expair</span>
          ) : item?.status === 5 ? (
            <span>Cancelled</span>
          ) : item?.status === 6 ? (
            <span>Completed</span>
          ) : (
            ""
          )}
        </div>
      ),

      route: <div>{`${item?.from_location} < ${item?.to_location}`}</div>,

      product: <div>{item?.product_detail?.name}</div>,

      date: <div className="dateOfOredr">{formatDate(item?.added_on)}</div>,
      qty: `${item?.qty} Ltr`,

      action: (
        <>
          <div className="actionBtn">
            <div className="customerStatus">
              <SwitchField
                onSwitchChange={() => updateTagStatus(item)}
                value={item?.is_active}
              />
              {item?.is_active ? <span>Disable</span> : <span>Enable</span>}
            </div>

            {userLogin?.user_type === 1 ? (
              <span className="_faEdit" onClick={() => handleEditAction(item)}>
                <FiEdit />
              </span>
            ) : (
              permissions[3] && (
                <span
                  className="_faEdit"
                  onClick={() => handleEditAction(item)}
                >
                  <FiEdit />
                </span>
              )
            )}
            {userLogin?.user_type === 1 ? (
              <span
                className="_faDelete"
                onClick={() => hendleDeleteTag(item, true)}
              >
                <MdOutlineDelete />
              </span>
            ) : (
              permissions[4] && (
                <span
                  className="_faDelete"
                  onClick={() => hendleDeleteTag(item, true)}
                >
                  <MdOutlineDelete />
                </span>
              )
            )}

            {/* { userLogin?.user_type === 1 ? <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />: permissions[3] && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
            { userLogin?.user_type === 1 ? <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />: permissions[4] && <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />} */}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllLocation", debouncedFilter],
    () => getAllLocation(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getCountryList();
    getCustomerList();
  }, []);

  const getCountryList = async () => {
    const res = await getCountry(countryObj);

    setCountryList(res?.results);
  };

  const getCustomerList = async () => {
    const res = await getAllCustomer(customerObj);
    const list = res.results?.map((item, i) => {
      return { name: item.name, id: item?.id };
    });

    setCustomerList(list);
  };

  const clearAll = () => {
    setFormOrderValue({
      location_type: 1,
      customer: "",
      name: "",
      contact_number: "",
      address_one: "",
      address_two: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      is_active: true,
      // radius:100,
      geo_fencing: {},
    });
    setFormOrderName({
      location_type: 1,
      customer: "",
      name: "",
      contact_number: "",
      address_one: "",
      address_two: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      is_active: true,
      // radius:100,
      geo_fencing: {},
    });
    setPreview("");
    setIsEdit(false);
    setFormValue("");
    setFormError("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };
  const updateActiveMutation = useMutation(updateActiveLocation, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addLocationValidation);
      setValidationError(error);
    }
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      addLocationValidation
    );

    const payload = {
      ...formOrderValue,
    };
    if (validationResult === true) {
      if (
        payload?.geo_fencing === null ||
        (typeof payload?.geo_fencing === "object" &&
          payload?.geo_fencing !== null &&
          Object.keys(payload.geo_fencing).length === 0)
      ) {
        setPolygonValidation("Please select the polygon first");
      } else {
        setPolygonValidation(false);
        setIsLoadding(true);
        if (isEdit === true) {
          handleUpdate(payload);
        } else {
          handleAdd(payload);
        }
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getLocationDetails.mutate(item?.id);
  };

  const getLocationDetails = useMutation(singleLocationDetails, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          location_type: res?.results?.location_type,
          customer: res?.results?.customer,
          name: res?.results?.name,
          contact_number: res?.results?.contact_number,
          address_one: res?.results?.address_one,
          address_two: res?.results?.address_two,
          country: res?.results?.country,
          city: res?.results?.city,
          is_active: res?.results?.is_active,
          coordinates: res?.results?.coordinates,
          // radius: Math.floor(res?.results?.radius),
          geo_fencing: res?.results?.geo_fencing,
          formatted_address: res?.results?.formatted_address,
        });

        setDisableInp({
          location_type: res?.results?.location_type,
          customer: res?.results?.customer,
          name: res?.results?.name,
          contact_number: res?.results?.contact_number,
          address_one: res?.results?.address_one,
          address_two: res?.results?.address_two,
          country: res?.results?.country,
          city: res?.results?.city,
          is_active: res?.results?.is_active,
          coordinates: res?.results?.coordinates,
          // radius: Math.floor(res?.results?.radius),
          geo_fencing: res?.results?.geo_fencing,
          formatted_address: res?.results?.formatted_address,
        });

        setDownload(res?.results?.location_type);

        const common = (name, id) => {
          if (name === "country") {
            let details = countryList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "customer") {
            let details = customerList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
        };
        setFormOrderName({
          ...formOrderName,
          country: common("country", res?.results?.country),
          customer: common("customer", res?.results?.customer),
        });

        setPreview(res?.results?.image);
        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const payload = {
      formdata: data,
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addLocation, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateLocation, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteLocation, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllLocation");
    setIsOpen(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  useEffect(() => {
    if (formValue?.image) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(formValue?.image);
    } else {
      if (preview) {
        setPreview(preview);
      } else {
        setPreview(null);
      }
    }
  }, [formValue?.image]);

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;

      default:
        return Requirement;
    }
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Branch":
        setFormOrderValue({ ...formOrderValue, addBranch: inputvalue });

        break;
      case "Select Customer":
        setOpenCustomer(true);
        break;

      default:
        break;
    }
  };

  const routeCallBack = (data) => {
    getCustomerList(customerObj);
    setFormOrderValue({ ...formOrderValue, customer: data?.id });
    setFormOrderName({
      ...formOrderName,
      customer: { value: data?.id, label: data?.name },
    });
  };

  const handlePlaceSelected = ({ lat, lng, addressComponents }) => {
    const latStr = lat.toString();
    const lngStr = lng.toString();
    setFormOrderValue((prev) => ({
      ...prev,
      coordinates: {
        ...prev.coordinates,
        latitude: latStr,
        longitude: lngStr,
      },
      formatted_address: addressComponents || "",
    }));
  };

  // const handleLocationChange = async (newLat, newLng) => {
  //   try {
  //     const formatted_address = await getFormattedAddress(newLat, newLng);
  //     setFormOrderValue((prev) => ({
  //       ...prev,
  //       coordinates: {
  //         ...prev.coordinates,
  //         latitude: newLat,
  //         longitude: newLng,
  //       },
  //       formatted_address: formatted_address,
  //     }));
  //   } catch (error) {
  //     console.error("Error fetching the formatted address:", error);
  //   }
  // };

  // const handlePolygonChange = (polygonPath) => {
  //   if (!polygonPath?.coordinates) {
  //     return;
  //   }
  //   const geoJson = {
  //     type: "Polygon",
  //     coordinates: polygonPath.coordinates,
  //   };

  //   setFormOrderValue((prev) => ({
  //     ...prev,
  //     geo_fencing: geoJson,
  //   }));
  // };

  const getFormattedAddress = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject("Failed to get address");
        }
      });
    });
  };

  const calculatePolygonCenter = (path) => {
    let latSum = 0;
    let lngSum = 0;
    const len = path.length;

    path.forEach((point) => {
      latSum += point[1];
      lngSum += point[0];
    });

    return {
      lat: latSum / len,
      lng: lngSum / len,
    };
  };

  const handlePolygonChange = async (polygonPath) => {
    if (!polygonPath?.coordinates) {
      return;
    }

    const center = calculatePolygonCenter(polygonPath.coordinates[0]);

    try {
      const formatted_address = await getFormattedAddress(
        center.lat,
        center.lng
      );

      setFormOrderValue((prev) => ({
        ...prev,
        geo_fencing: {
          type: "Polygon",
          coordinates: polygonPath.coordinates,
        },
        formatted_address: formatted_address,
        coordinates: {
          latitude: center?.lat,
          longitude: center?.lng,
        },
      }));
    } catch (error) {
      console.error("Error fetching the formatted address:", error);
    }
  };

  return (
    <>
      <div className="tabMain_head">
        <Tabs
          TabList={locationTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
        <div>
          {userLogin?.user_type === 1 ? (
            <button
              className="anime-btn addPlan"
              onClick={() => setIsOpen(true)}
            >
              Add Location
            </button>
          ) : (
            permissions[2] && (
              <button
                className="anime-btn addPlan"
                onClick={() => setIsOpen(true)}
              >
                Add Location
              </button>
            )
          )}
        </div>
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>

          <div className="inr_flex_mini">
            <InputField
              placeholder="Search"
              label="Search"
              className="anvSearch_input"
              value={filter?.name}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  name: e.target.value,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={
                formOrderName?.count
                  ? formOrderName?.count
                  : { label: "20", value: 20 }
              }
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }));

                setFormOrderName({ ...formOrderName, count: data });
              }}
            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      {isOpen && (
        <GoogleMapsLoader>
          <InputModal
            isOpen={isOpen}
            headerName={isEdit ? "Edit Exit Border" : "Location"}
            toggle={() => {
              setIsOpen(!isOpen);
              clearAll();
            }}
            submitLabel={isEdit ? "Update" : "Add Location"}
            onSubmit={(e) => onOrderSubmit(e)}
            disabled={
              isEdit
                ? formOrderValue.name === disableInp.name &&
                  formOrderValue.contact_number === disableInp.contact_number &&
                  formOrderValue.address_one === disableInp.address_one &&
                  formOrderValue.address_two === disableInp.address_two &&
                  formOrderValue.customer === disableInp.customer &&
                  formOrderValue.country === disableInp.country &&
                  formOrderValue.location_type === disableInp.location_type &&
                  formOrderValue?.city === disableInp.city &&
                  formOrderValue?.formatted_address ===
                    disableInp.formatted_address
                : !formOrderValue?.name ||
                  !formOrderValue?.contact_number ||
                  !formOrderValue?.address_one ||
                  !formOrderValue?.address_two ||
                  !formOrderValue?.customer ||
                  !formOrderValue?.country ||
                  !formOrderValue?.city ||
                  !formOrderValue?.formatted_address
            }
            isLoadding={isLoadding}
            className="mapView_modal modal_width locationMap_modal"
            mapView={true}
            inputProps={
              <>
                <div className="_inpField_head ">
                  <h4>Location Type</h4>
                </div>
                <div className="_longTitudeBx mb-3">
                  <div className="_halfDiv">
                    <CheckboxField
                      type="radio"
                      label="Loading Point"
                      name="location_type"
                      checked={formOrderValue?.location_type === 1}
                      value={1}
                      onChange={({ target: { name, value } }) =>
                        handleOrderChange(name, Math.floor(value))
                      }
                    />
                  </div>
                  <div className="_halfDiv">
                    <CheckboxField
                      type="radio"
                      label="Offloading Point"
                      name="location_type"
                      checked={formOrderValue?.location_type === 2}
                      value={2}
                      onChange={({ target: { name, value } }) =>
                        handleOrderChange(name, Math.floor(value))
                      }
                    />
                  </div>
                </div>
                <Dropdown
                  placeholder="Select Customer Name"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  label="Customer Name"
                  name="fundAllocationState"
                  options={customerList ? customerList : []}
                  valueText="id"
                  labelText="name"
                  selectedOption={formOrderName.customer}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, customer: data });
                    handleOrderChange("customer", data.value);
                  }}
                />
                <InputField
                  className="modal_input"
                  label="Location Name"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Location Name"
                  name="name"
                  value={formOrderValue.name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />

                <InputField
                  className="modal_input"
                  label="Location Contact"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Location Contact"
                  name="contact_number"
                  value={formOrderValue.contact_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
                <div className="_inpField_head">
                  <h4>Mark Location</h4>
                </div>

                {/* <AutocompleteInput
                  onPlaceSelected={handlePlaceSelected}
                  value={formOrderValue?.formatted_address}
                /> */}

                {/* temprory AutoSearch */}

                <div className="__gMap_float">
                  <AutocompleteInput
                    onPlaceSelected={handlePlaceSelected}
                    // value={formOrderValue?.formatted_address}
                  />
                </div>

                <div className="_halfDiv">
                  <InputField
                    className="modal_input"
                    label="Address Location"
                    labelClass="modal_label"
                    placeholder="Enter Location"
                    name="formatted_address"
                    value={formOrderValue?.formatted_address}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>

                {/* temprory AutoSearch */}

                {/* <InputField
                  className="modal_input"
                  label="Radius"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Radius"
                  name="radius"
                  type="number"
                  // focus={!!inputError.radius}
                  // error={inputError.radius}
                  value={formOrderValue?.radius}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                /> */}

                <div className="_longTitudeBx">
                  <InputField
                    className="modal_input"
                    label="Latitude"
                    labelClass="modal_label"
                    labelSpan="*"
                    labelSpanClass="style_for_star"
                    placeholder="Enter Latitude"
                    name="latitude"
                    value={formOrderValue?.coordinates?.latitude}
                    readOnly
                  />

                  <InputField
                    className="modal_input"
                    label="Longitude"
                    labelClass="modal_label"
                    labelSpan="*"
                    labelSpanClass="style_for_star"
                    placeholder="Enter Longitude"
                    name="longitude"
                    value={formOrderValue?.coordinates?.longitude}
                    readOnly
                  />
                </div>

                <div className="_inpField_head">
                  <h4>Address Details</h4>
                </div>

                <InputField
                  className="modal_input"
                  label="Address line 1"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Address line 1"
                  name="address_one"
                  // focus={!!inputError.radius}
                  // error={inputError.radius}
                  value={formOrderValue?.address_one}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />

                <InputField
                  className="modal_input"
                  label="Address line 2"
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Address line 2"
                  name="address_two"
                  // focus={!!inputError.radius}
                  // error={inputError.radius}
                  value={formOrderValue?.address_two}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />

                <div className="_longTitudeBx">
                  <div className="_halfDiv">
                    <Dropdown
                      placeholder="Country"
                      classNamePrefix="_anvFloatSelect"
                      boxClass="_anvFloatedSelect"
                      label="Country"
                      name="fundAllocationState"
                      options={countryList ? countryList : []}
                      valueText="id"
                      labelText="name"
                      // focus={!!inputError.fundAllocationState}
                      // error={inputError.fundAllocationState}
                      selectedOption={formOrderName?.country}
                      setSelectedOption={(data) => {
                        setFormOrderName({ ...formOrderName, country: data });
                        handleOrderChange("country", data?.value);
                      }}
                    />
                  </div>

                  <div className="_halfDiv">
                    <InputField
                      className="modal_input"
                      label="City"
                      labelClass="modal_label"
                      labelSpan="*"
                      labelSpanClass="style_for_star"
                      placeholder="Enter City"
                      name="city"
                      value={formOrderValue?.city}
                      onChange={({ target: { name, value } }) =>
                        handleOrderChange(name, value)
                      }
                    />
                  </div>
                </div>

                {polygonValidataion && polygonValidataion !== false && (
                  <div style={{ color: "red", fontSize: "13px" }}>
                    {polygonValidataion}
                  </div>
                )}
              </>
            }
            mapInputProps={
              <div className="_mapView_bxs">
                <NewGoogleMapComp
                  zoom={12}
                  lat={formOrderValue?.coordinates?.latitude || "20.5937"}
                  lng={formOrderValue?.coordinates?.longitude || "78.9629"}
                  locations={[]}
                  onPolygonChange={handlePolygonChange}
                />
                ;
              </div>
            }
          />
        </GoogleMapsLoader>
      )}
      <Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />

      {openCustomer && (
        <CommonCustomer
          isOpen={openCustomer}
          setIsOpen={setOpenCustomer}
          routeCallBack={routeCallBack}
        />
      )}
    </>
  );
};
