import React, { useEffect, useState } from "react";
import { Label, Modal } from "reactstrap";
import { components } from "react-select";

import { InputField } from "../../Component/Common/InputField";
import { getBranch } from "../../services";
import { toast } from "react-toastify";
import {
  addRouteDetails,
  addUserValidation,
  addUserValidationFirst,
  editUserValidation,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  addRouteTabList,
  addUserTabList,
  checkFormError,
  customerTabList,
  formatDate,
  handleKeyDown,
  pageCount,
  routeTabList,
  userTabList,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { getAllCustomer } from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { addCurrencyApi, getCurrency } from "../../services/currencyService";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { Menu, MenuItem, Typography } from "@mui/material";
import {
  addUser,
  deleteUser,
  getAllUser,
  getRoles,
  singleUserDetails,
  updateActiveUser,
  updateUser,
} from "../../services/userService";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { getStorage } from "../../helper/storage";

const userTypeRole = [
  { label: "Staff", value: 1 },
  { label: "Driver", value: 2 },
  { label: "Customer", value: 3 },
  { label: "Owner", value: 4 },
];

export const User = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };

  const fuelstationObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const customerObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const expenseObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };
  const currencyObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    name: "",
    email: "",
    mobile_number: "",
    other_number: "",
    is_web_access: false,
    password: "none",
    confirm_password: "none",
    user_type: 4,
    is_active: true,
    branches: [],
    role: "",
  };
  const orderObjName = {
    name: "",
    email: "",
    mobile_number: "",
    other_number: "",
    is_web_access: false,
    password: "none",
    confirm_password: "none",
    user_type: 4,
    is_active: true,
    branches: [],
    role: "",
  };

  let defultExpense = {
    category: "",
    amount: "",
    currency: "",
    description: "",
  };

  let defultFuel = {
    fuel_station: "",
    ltr: "",
    description: "",
  };

  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const queryClient = useQueryClient();
  const userLogin = getStorage("logged-in");
  const result = userLogin?.user_branches?.join(",");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [activeModalTab, setActiveModalTab] = useState(1);
  const [openCustomer, setOpenCustomer] = useState(false);

  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    branch:result,
    ...obj,
  });
  const [branchList, setBranchList] = useState([]);
  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [initialformOrderValue, setInitialFormOrderValue] = useState(orderObj);
  const [formError, setFormError] = useState("");
  const [customerList, setCustomerList] = useState();
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [fuelStationList, setFuelStationList] = useState();
  const [expeCateList, setExpeCateList] = useState();
  const [countryList, setCountryList] = useState();
  const [pickupList, setPickupList] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [currencyList, setCurrencyList] = useState();
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [fromList, setFromList] = useState([{ label: "", value: "" }]);
  const [toList, setToList] = useState([{ label: "", value: "" }]);
  const [expenseList, setExpenseList] = useState([defultExpense]);
  const [fuelList, setFuelList] = useState([defultFuel]);
  const [roleList, setRoleList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [editAction, setEditAction] = useState("");
  const [response, setResponse] = useState("");
  const [itemId, setItemId] = useState("");
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  useEffect(() => {
    setOptions(branchList);
  }, [branchList]);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseSelectMenu = (e) => {
    handleCloseUserMenu();
  };

  useEffect(() => {
    if (response) {
      if (editAction === "details") {
        setIsOpen(true);
      }

      if (editAction === "password") {
        setIsOpenPassword(true);
      }
    }
  }, [response]);

  const Requirement = [
    {
      heading: "Staff Name",
      className: "sorting_asc",
      value: "name",
      sortName: "",
    },
    {
      heading: "Roles",
      className: "sorting_asc",
      value: "roleName",
      sortName: "",
    },
    {
      heading: "Branch",
      className: "sorting_asc",
      value: "branchName",
      sortName: "",
    },
    {
      heading: "Email ID",
      className: "sorting_asc",
      value: "email",
      sortName: "",
    },
    {
      heading: "Contact Number",
      className: "sorting",
      value: "mobile_number",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const findBranchNames = (branchIds) => {
    if (!branchIds || branchIds.length === 0) return "-------";
    
    const branchNames = branchIds
      .map((id) => {
        const branch = branchList?.find((item) => item?.id === id);
        return branch?.name || null;
      })
      .filter((name) => name);
  
    return branchNames.length > 0 ? branchNames.join(", ") : "-------";
  };

  const findRoleName = (dataId)=>{
    const RoleName = roleList?.find((item)=>item?.uuid=== dataId)

    return RoleName?.name
  }

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      status: (
        <div className="statusofOrder">
          <span>Pending</span>
        </div>
      ),
      roleName:(
        <span>{item?.role ? findRoleName(item?.role):"-------"}</span>
      ),
      branchName:(
        <span>{item?.branches?.length > 0 ?findBranchNames(item?.branches):"-------"}</span>
      ),
      serial: i + 1,
      customer_name: item?.customer_detail
        ? item?.customer_detail?.name
        : "User Not Login",
      pickUp_location: item?.pickup_location_detail?.map((i) =>
        item?.pickup_location_detail?.length > 1 ? `${i.name} , ` : i.name
      ),
      drop_location: item?.drop_location_detail?.map((i) =>
        item?.drop_location_detail?.length > 1 ? `${i.name} , ` : i.name
      ),
      mobile: item?.customer_detail?.mobile_number,
      km: item?.kilometer,
      action: (
        <>
          <div className="actionBtn">
            {/* <FaSort /> <FaSortUp /><FaSortDown /> */}
            <div className="customerStatus">
              <SwitchField
                onSwitchChange={() => updateTagStatus(item)}
                value={item?.is_active}
              />
              {item?.is_active ? <span>Disable</span> : <span>Enable</span>}
            </div>

            <Menu
              className="other_menu"
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={(e) => {
                  setEditAction("details");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit User
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setEditAction("password");
                  handleEditAction(itemId);
                  handleCloseSelectMenu(e);
                }}
                sx={{ borderBottom: "1px solid #D1D1D1" }}
              >
                Edit Password
              </MenuItem>
            </Menu>
            <span className="_faEdit" onClick={(e) => { setItemId(item); handleOpenUserMenu(e); }}><FiEdit /></span>
            {/* <img
              src={EditIcon}
              alt=""
              onClick={(e) => {
                setItemId(item);
                handleOpenUserMenu(e);
              }}
            /> */}

            <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>

            {/* <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            /> */}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllUser", debouncedFilter],
    () => getAllUser(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getBranchList();
    getRoleList();
  }, []);

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };

  const getRoleList = async () => {
    const res = await getRoles(branchObj);
    setRoleList(res?.results);
  };

  const getCurrencyList = async () => {
    const res = await getCurrency(currencyObj);
    setCurrencyList(
      res?.results?.map((item) => {
        return { ...item, label: item?.name, value: item?.id };
      })
    );
    // setCurrencyList(res?.results)
  };

  const getCustomerList = async () => {
    const res = await getAllCustomer(customerObj);
    const list = res.results?.map((item, i) => {
      return { name: item.name, id: item?.id };
    });

    setCustomerList(list);
  };

  const clearAll = () => {
    setFormOrderValue({
      name: "",
      email: "",
      mobile_number: "",
      alternative_mobile_number: "",
      is_web_access: false,
      password: "none",
      confirm_password: "none",
      user_type: 4,
      is_active: true,
      branch: [],
      role: "",
    });
    setFormOrderName({
      name: "",
      email: "",
      mobile_number: "",
      alternative_mobile_number: "",
      is_web_access: false,
      password: "none",
      confirm_password: "none",
      user_type: 4,
      is_active: true,
      branch: [],
      role: "",
    });
    setPreview("");
    setIsEdit(false);
    setFuelList([
      {
        fuel_station: "",
        ltr: "",
        description: "",
      },
    ]);
    setExpenseList([
      {
        category: "",
        amount: "",
        currency: "",
        description: "",
      },
    ]);
    setFromList([{ label: "", value: "" }]);
    setToList([{ label: "", value: "" }]);
    setFormError("");
    setActiveModalTab(1);
    setSelectAll(false);
    setSelectedCheckboxes([]);
    setEditAction('');
    setResponse("")
    setIsOpenPassword(false)

  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addRouteDetails);
      setValidationError(error);
    }
  };

  const validationHnalde = async () => {
    const validationResult = await checkFormError(
      {
        ...formOrderValue,
        name: formOrderValue.name,
        email: formOrderValue.email,
        mobile_number: formOrderValue.mobile_number,
        alternative_mobile_number: formOrderValue.alternative_mobile_number,
        is_web_access: formOrderValue.is_web_access,
        password: formOrderValue.password,
        confirm_password: formOrderValue.confirm_password,
        user_type: formOrderValue.user_type,
      },
      isEdit ? editUserValidation : addUserValidationFirst
    );


    setValidationError(validationResult);
    if (validationResult === true) {
      setActiveModalTab(2);
    }
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue, branch: selectedCheckboxes },
      isEdit ? editUserValidation : addUserValidation
    );


    const payload = {
      // branch: selectedCheckboxes
    };

    Object.keys(formOrderValue).map((item, index) => {
      payload["branches"] = selectedCheckboxes;
      payload["user_type"] = 4;

      if (formOrderValue[item] !== initialformOrderValue[item]) {
        payload[item] = formOrderValue[item];
        payload["branches"] = selectedCheckboxes;
        payload["user_type"] = 4;
      }
    });

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveUser, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getRouteDetails.mutate(item?.id);
  };

  const getRouteDetails = useMutation(singleUserDetails, {
    onSuccess: (res) => {


      if (res?.success) {
        setFormOrderValue({
          name: res?.results?.name,
          email: res?.results?.email,
          mobile_number: res?.results?.mobile_number,
          alternative_mobile_number: res?.results?.alternative_mobile_number,
          is_web_access: res?.results?.is_web_access,
          user_type: 4,
          is_active: res?.results?.is_active,
          role: res?.results?.role,
        });
        setInitialFormOrderValue({
          name: res?.results?.name,
          email: res?.results?.email,
          mobile_number: res?.results?.mobile_number,
          alternative_mobile_number: res?.results?.alternative_mobile_number,
          is_web_access: res?.results?.is_web_access,
          user_type: 4,
          is_active: res?.results?.is_active,
          role: res?.results?.role,
        });

        setSelectedCheckboxes([...res?.results?.branches]);

        if (branchList.length === res?.results?.branches.length) {
          setSelectAll(true);
        }
        const common = (name, id) => {

          if (name === "role") {
            let details = roleList?.find((i) => i.uuid === id);
            return {
              data: details,
              label: details?.name,
              value: details?.uuid,
            };
          }
        };

        setFormOrderName({
          ...formOrderName,
          role: common("role", res?.results?.role),
        });

        setIsEdit(true);
        setFormError("");
        setResponse(res)
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleUpdate = async () => {

    const payload = {
      // branch: selectedCheckboxes
    };
    if (isOpen === true) {

      Object.keys(formOrderValue).map((item, index) => {
        payload["branches"] = selectedCheckboxes;
        payload["user_type"] = 4;

        if (formOrderValue[item] !== initialformOrderValue[item]) {
          payload[item] = formOrderValue[item];
          payload["branches"] = selectedCheckboxes;
          payload["user_type"] = 4;
        }
      });
    }

    if (isOpenPassword === true) {
      Object.keys(formOrderValue).map((item, index) => {

        if (formOrderValue[item] !== initialformOrderValue[item]) {
          payload[item] = formOrderValue[item];
        }
      })
    }

    const data = {
      formdata: payload,
      id: updateId,
    };

    updateMutation.mutate(data);
  };

  const addMutation = useMutation(addUser, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const updateMutation = useMutation(updateUser, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteUser, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllUser");
    setIsOpen(false);

    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleModalActive = (data) => {
    setActiveModalTab(data?.value);
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;
      case 2:
        return Requirement;
      default:
        return Requirement;
    }
  };

  useEffect(() => { }, [fromList]);

  const updateExpoDetails = (index, data) => {
    const { key, value } = data;

    let newExpenseList = [...expenseList];
    newExpenseList[index][key] = value;
    setExpenseList(newExpenseList);
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Branch":
        setFormOrderValue({
          ...formOrderValue,
          addBranch: inputvalue,
          openName: "branch",
        });
        setOpenModal(true);

        break;

      case "Select Currency":
        setFormOrderValue({
          ...formOrderValue,
          addCurrency: inputvalue,
          openName: "currency",
        });
        setOpenModal(true);
        break;

      case "Select Country":
        setFormOrderValue({
          ...formOrderValue,
          addCountry: inputvalue,
          openName: "country",
        });
        setOpenModal(true);
        break;

      case "Select Customer Name":
        setOpenCustomer(true);
        break;

      default:
        break;
    }
  };

  const addNewSubmit = async (value) => {
    const validationResult = await checkFormError(
      {
        name:
          value === "branch"
            ? formOrderValue.addBranch
            : value === "currency"
              ? formOrderValue?.addCurrency
              : formOrderValue.addCountry,
      },
      modalInputSchema
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAddNew(value);
      }
    } else {
      setFormError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleAddNew = (value) => {
    const payload = {
      name: formOrderValue?.addCurrency,
    };
    addNewCurrencyMutation.mutate(payload);
  };

  const addNewCurrencyMutation = useMutation(addCurrencyApi, {
    onSuccess: (res) => {
      if (res?.success) {
        let expe = [...expenseList];
        expe.map((item, index) => {
          updateExpoDetails(index, {
            key: "currency",
            value: res?.results?.id,
          });
        });
        setExpenseList(expe);
        getCurrencyList();
        setOpenModal(false);
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const routeCallBack = (data) => {
    if (data?.email) {
      getCustomerList();
      setFormOrderValue({ ...formOrderValue, customer: data?.id });
      setFormOrderName({
        ...formOrderName,
        customer: { value: data?.id, label: data?.name },
      });
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "selectAll") {
      setSelectAll(checked);
      if (checked) {
        const allOptionIds = options.map((option) => option.id);
        setSelectedCheckboxes(allOptionIds);
      } else {
        setSelectedCheckboxes([]);
      }
    } else {
      // const id = parseInt(name);
      let updatedSelectedCheckboxes = [...selectedCheckboxes];
      if (checked) {
        updatedSelectedCheckboxes = [...updatedSelectedCheckboxes, name];
      } else {
        updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
          (checkboxId) => checkboxId !== name
        );
      }
      setSelectedCheckboxes(updatedSelectedCheckboxes);
      setSelectAll(updatedSelectedCheckboxes.length === options.length);
    }
  };

  const showHandle = () => {
    setShowPassword(!showPassword);
  };
  const showHandleConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={userTabList}
          handleActive={handleActive}
          activeTab={+filter?.status}
        />
        <div>
          <button className="anime-btn addPlan" onClick={() => setIsOpen(true)}>
            Add User
          </button>
        </div>
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>

          <div className="inr_flex">
            <InputField placeholder="Search User" className="anvSearch_input" label="Search User"
              value={filter?.name}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  name: e.target.value,
                }))
              }
            />

          </div>
          <div className="inr_flex">
            <InputField placeholder="Email" className="anvSearch_input" label="Email"
              value={filter?.email}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  email: e.target.value,
                }))
              }
            />

          </div>
          <div className="inr_flex">
            <InputField placeholder="Mobile Number" className="anvSearch_input" label="Mobile Number"
              value={filter?.mobile_number}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  mobile_number: e.target.value,
                }))
              }
            />

          </div>
          {(filter?.email || filter?.name || filter?.mobile_number) && <div className="inr_flex_mini">
            <button className="anime-btn reset_button" onClick={() => {
              setFilter({
                ...filter,
                email: "",
                name: "",
                mobile_number: "",


              })
              setFormOrderName({ ...formOrderName, branchFilter: '' })
            }
            } >Reset</button>
          </div>}

        </div>
      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"

              selectedOption={formOrderName?.count ? formOrderName?.count : { label: "20", value: 20 }}

              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))

                setFormOrderName({ ...formOrderName, count: data });
              }}


            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setPreview("");

            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) =>
            +activeModalTab === 2 ? onOrderSubmit(e) : validationHnalde()
          }
          title={isEdit ? "Edit User" : "Add User"}
          submitLabel={+activeModalTab === 2 ? "Save" : "Continue"}
          inputProps={
            <>
              {isOpen && (
                <div div className="TabHeadofmodal">
                  {" "}
                  <Tabs
                    TabList={addUserTabList}
                    handleActive={handleModalActive}
                    activeTab={+activeModalTab}
                  />
                </div>
              )}
              {+activeModalTab === 1 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input orderModal_Input"
                        label={isEdit ? "Employee Name" : "Employee Name"}
                        labelClass="FormLableKaran"
                        placeholder="Employee Name"
                        name="name"
                        type="text"
                        focus={!!formError?.name}
                        error={formError.name}
                        value={formOrderValue?.name}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={
                          isEdit
                            ? "Email ID (It will be used for Login)"
                            : "Email ID (It will be used for Login)"
                        }
                        labelClass="FormLableKaran"
                        placeholder="Email ID"
                        name="email"
                        type="email"
                        focus={!!formError?.email}
                        error={formError.email}
                        value={formOrderValue?.email}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>

                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Country Code" : "Country Code"}
                        labelClass="FormLableKaran"
                        placeholder="Add Country Code"
                        name="alternative_mobile_number"
                        type="text"
                        maxLength={3}
                        onKeyDown={handleKeyDown}
                        focus={!!formError?.alternative_mobile_number}
                        error={formError.alternative_mobile_number}
                        value={formOrderValue?.alternative_mobile_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Contact Number" : "Contact Number"}
                        labelClass="FormLableKaran"
                        placeholder="Add Contact Number"
                        name="mobile_number"
                        type="text"
                        maxLength={12}
                        onKeyDown={handleKeyDown}
                        focus={!!formError?.mobile_number}
                        error={formError.mobile_number}
                        value={formOrderValue?.mobile_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>

                    <div className="anvOt_outerFull">
                      <div className="anvOt_inputField checkboxtext">
                        <InputField
                          className="modal_input "
                          labelClass="FormLableKaran"
                          placeholder="Company Registration Number"
                          name="is_web_access"
                          type="checkbox"
                          focus={!!formError?.is_web_access}
                          error={formError.is_web_access}
                          value={formOrderValue?.is_web_access}
                          checked={formOrderValue.is_web_access === true}
                          onChange={({ target: { name, checked } }) => {
                            handleOrderChange(name, checked);
                          }}
                        />
                        <sapn className="checkboxTextbox">Web App Access</sapn>
                      </div>
                    </div>
                    {isEdit === false && formOrderValue?.is_web_access && (
                      <div className="anvOt_inputField ">
                        <div className="dropdaonAndselect">
                          <div className="anvSpecificDiv">
                            <InputField
                              className="modal_input orderModal_Input"
                              label={isEdit ? "Set Password" : "Set Password"}
                              labelClass="FormLableKaran"
                              placeholder="Enter Password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              data="password"
                              showPassword={showPassword}
                              showHandle={showHandle}
                              focus={!!formError?.password}
                              error={formError.password}
                              value={
                                formOrderValue?.password === "none"
                                  ? ""
                                  : formOrderValue?.password
                              }
                              onChange={({ target: { name, value } }) =>
                                handleOrderChange(name, value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {isEdit === false && formOrderValue?.is_web_access && (
                      <div className="anvOt_inputField ">
                        <div className="dropdaonAndselect">
                          <div className="anvSpecificDiv">
                            <InputField
                              className="modal_input orderModal_Input"
                              label={
                                isEdit ? "Repeat Password" : "Repeat Password"
                              }
                              labelClass="FormLableKaran"
                              placeholder="Repeat Password"
                              name="confirm_password"
                              type={showConfirmPassword ? "text" : "password"}
                              data="password"
                              showPassword={showConfirmPassword}
                              showHandle={showHandleConfirm}
                              focus={!!formError?.confirm_password}
                              error={formError.confirm_password}
                              value={
                                formOrderValue?.confirm_password === "none"
                                  ? ""
                                  : formOrderValue?.confirm_password
                              }
                              onChange={({ target: { name, value } }) =>
                                handleOrderChange(name, value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="anvOt_inputField ">
                      {/* <span>
                        <Label className="FormLableKaran">
                          Select User Role
                        </Label>
                      </span> */}
                      <div>
                        <Dropdown
                          className="dropdownoforder"
                          placeholder="Select Role"
                          label="Select Role"
                          classNamePrefix="_anvFloatSelect"
                          boxClass="_anvFloatedSelect"
                          name="user_type"
                          options={roleList ? roleList : []}
                          valueText="uuid"
                          labelText="name"
                          components={{ Menu: CustomControl }}
                          focus={!!formError?.role}
                          error={formError?.reole}
                          selectedOption={formOrderName?.role}
                          // onMenuScrollToBottom={loadMoreOptions}
                          setSelectedOption={(data) => {
                            setFormOrderName({ ...formOrderName, role: data });
                            handleOrderChange("role", data?.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {+activeModalTab === 2 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_outerFull">
                      <div className="anvOt_inputField checkboxtext ">
                        <InputField
                          className="modal_input "
                          labelClass="FormLableKaran"
                          placeholder="Company Registration Number"
                          name="selectAll"
                          checked={selectAll}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          focus={!!formError?.branch}
                        // error={formError.branch}
                        />
                        <sapn className="checkboxTextbox">All Branch</sapn>
                      </div>
                    </div>
                    {options?.map((branch, index) => (
                      <div className=" checkboxtext userRoleInput">
                        <InputField
                          className="modal_input "
                          labelClass="FormLableKaran"
                          type="checkbox"
                          id={branch?.id}
                          checked={selectedCheckboxes.includes(branch.id)}
                          onChange={handleCheckboxChange}
                          focus={!!formError?.branch}
                          name={branch.id.toString()}
                        // error={formError.branch}
                        />
                        <sapn className="checkboxTextbox">{branch?.name}</sapn>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          }
        />
      )}

      <Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />

      {openModal && (
        <InputModal
          isOpen={openModal}
          headerName={
            isEdit
              ? "Edit Branch"
              : formOrderValue.openName === "branch"
                ? "Add Branch"
                : formOrderValue.openName === "currency"
                  ? "Add Currency"
                  : "Add Country"
          }
          toggle={() => setOpenModal(!openModal)}
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={
                isEdit
                  ? "Edit Branch"
                  : formOrderValue.openName === "branch"
                    ? "Branch Name"
                    : formOrderValue.openName === "currency"
                      ? "Currency Name"
                      : "Country Name"
              }
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder={
                formOrderValue.openName === "branch"
                  ? "Branch Name"
                  : formOrderValue.openName === "currency"
                    ? "Currency Name"
                    : "Country Name"
              }
              name={
                formOrderValue.openName === "branch"
                  ? "addBranch"
                  : formOrderValue.openName === "currency"
                    ? "addCurrency"
                    : "addCountry"
              }
              focus={
                formOrderValue.openName === "branch"
                  ? !!formError.addBranch
                  : formOrderValue.openName === "currency"
                    ? !!formError.addCurrency
                    : !!formError.addCountry
              }
              error={
                formOrderValue.openName === "branch"
                  ? formError.addBranch
                  : formOrderValue.openName === "currency"
                    ? formError.addCurrency
                    : formError.addCountry
              }
              value={
                formOrderValue.openName === "branch"
                  ? formOrderValue.addBranch
                  : formOrderValue.openName === "currency"
                    ? formOrderValue.addCurrency
                    : formOrderValue.addCountry
              }
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}
      {openCustomer && (
        <CommonCustomer
          isOpen={openCustomer}
          setIsOpen={setOpenCustomer}
          routeCallBack={routeCallBack}
        />
      )}
      <InputModal
        isOpen={isOpenPassword}
        headerName={isEdit ? "Edit Password" : "Add Branch"}
        toggle={() => {
          setIsOpenPassword(false);
          setIsEdit(false);
          clearAll();

        }}
        submitLabel={isEdit ? "Update Password" : "Continue"}
        onSubmit={(e) => handleUpdate(e)}
        // disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoadding}
        inputProps={
          <>
            <div className="dropdaonAndselect">
              <div className="anvSpecificDiv">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Set Password" : "Set Password"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  data="password"
                  showPassword={showPassword}
                  showHandle={showHandle}
                  focus={!!formError?.password}
                  error={formError.password}
                  value={formOrderValue?.password}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
            <div className="dropdaonAndselect">
              <div className="anvSpecificDiv">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Repeat Password" : "Repeat Password"}
                  labelClass="FormLableKaran"
                  placeholder="Repeat Password"
                  name="confirm_password"
                  type={showConfirmPassword ? "text" : "password"}
                  data="password"
                  showPassword={showConfirmPassword}
                  showHandle={showHandleConfirm}
                  focus={!!formError?.confirm_password}
                  error={formError.confirm_password}
                  value={formOrderValue?.confirm_password}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
