import React from "react";
import { Loader } from "./Loader";

const TableForModal = (props) => {
  const { data, column, boxClass, tableLoading, total } = props;
  return (
    <div className={boxClass}>
      <table className="table">
        <thead>
          <tr>
            {column?.map((item, i) => (
              <>
                <TableHeadItems key={i} item={item} />
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableLoading || data?.length <= 0 ? (
            <tr>
              <td colSpan={10} rowSpan={1}>
                {tableLoading ? (
                  <span className="d-flex justify-content-center">
                    Loadding....
                  </span>
                ) : (
                  <span className="d-flex justify-content-center">
                    No data!
                  </span>
                )}
              </td>
            </tr>
          ) : (
            data?.map((item, i) => (
              <>
                <TableRow
                  key={i}
                  item={item}
                  column={column}
                  i={i}
                  total={total}
                />
              </>
            ))
          )}
          {data?.length > 0  && (
            <tr className="total_value">
            <td><span>Total</span></td> 

            <td><span>{total}</span></td> 
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableHeadItems = ({ item }) => <th>{item.heading}</th>;

const TableRow = ({ item, column, i, total }) => (
  <>
    <tr>
      {column?.map((colItem, i) => {
        return (
          <>
            <td className="modal_td" key={i}>
              {item[`${colItem.value}`]}
            </td>
          </>
        );
      })}
    </tr>
  </>
);

export default TableForModal;
