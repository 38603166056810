import React, { useState } from "react";
import Select from "react-select";
import { FaEye } from "react-icons/fa6";

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;

  const handleViewClick = (e) => {
    e.stopPropagation();
    props.selectProps.onViewOption(data); 
  };

  return (
    <div ref={innerRef} {...innerProps} className="custom-option active">
      <span>{data.label}</span>
      {props.selectProps.isViewVisible && (
        <div
          color="link"
          className="llt_dropdown_hover_btn"
          onClick={handleViewClick}
        >
          <FaEye />
        </div>
      )}
    </div>
  );
};

const DropdownWithButton = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!props.selectedOption) {
      setIsFocused(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      position: "relative",
      borderColor: isFocused ? "#666" : "#ddd",
      "&:hover": { borderColor: "#666" },
    }),
    placeholder: () => ({
      display: "none",
    }),
  };

  const {
    placeholder,
    options,
    selectedOption,
    setSelectedOption,
    valueText,
    labelText,
    label,
    error,
    focus,
    boxClass,
    className,
    classNamePrefix,
    isMulti = false,
    style,
    disabled,
    components: customComponents,
    onMenuScrollToBottom,
    isViewVisible,
    onViewOption,
  } = props;

  const invalid = !!(focus && error);

  return (
    <div className={boxClass}>
      {label && (
        <div
          className={`floating-placeholder ${
            selectedOption || isFocused ? "float" : ""
          }`}
        >
          <span className="_likInpt">{label}</span>
        </div>
      )}

      <Select
        isMulti={isMulti}
        className={className}
        styles={customStyles}
        value={selectedOption}
        classNamePrefix={classNamePrefix}
        onChange={setSelectedOption}
        onFocus={handleFocus}
        onBlur={handleBlur}
        options={
          valueText === "value" && labelText === "label"
            ? options
            : options?.map((item) => ({
                value: item[valueText],
                label: item[labelText],
                data: item,
              }))
        }
        menuPlacement="auto"
        maxMenuHeight={190}
        placeholder={placeholder}
        isDisabled={disabled}
        components={{
          ...customComponents,
          Option: CustomOption,
        }}
        isViewVisible={isViewVisible}
        onViewOption={onViewOption} 
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      {invalid && <div className="error-message">{error}</div>}
    </div>
  );
};

export default DropdownWithButton;