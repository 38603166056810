
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';

const CommonSignature = (props) => {
    const { open, setOpen, signatureCallBack } = props;

    const signatureRef = useRef();

    const clearSignature = () => {
        signatureRef.current.clear();
    };

    const save = () => {


        const base64Data =signatureRef.current.getTrimmedCanvas().toDataURL(''); // Get base64 string
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
    
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
    
        const file = new File([ab], 'signature.png', { type: mimeString });
        // setSignatureImage(file);
      

        signatureCallBack(signatureRef.current.getTrimmedCanvas().toDataURL(''));


        setOpen(!open)
    }
    const toggle = () => setOpen(!open);
    return (
        <Modal isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}> Draw Signature</ModalHeader>
            <div>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{ className: "signature_pad_style" }}
                />

            </div>
            <ModalFooter className='footerOfModal'>
                <button className='anime-btn ques_row_btn btn btn-secondary' onClick={save}>Save</button>
                <button className='cancelbtn_mdllcls btn btn-' onClick={clearSignature}>Clear Signature</button>

            </ModalFooter>
        </Modal>
    );
};

export default CommonSignature;