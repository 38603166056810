import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label, Modal, } from "reactstrap";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { addFuelStationValidation, addLocationValidation, addResearchSchema } from "../../helper/errorSchema";
import { checkFormError, formatDate, fuelStationTabList, handleKeyDown, locationTabList, pageCount, } from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import { getAllCustomer, singleNewDetails, updateActiveResearch, } from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { getCountry } from "../../services/countryService";
import { getFleetType, updateFleet } from "../../services/fleetTypeService";
import mytruck from "../../../image/tanker.png"

import { addLocation, deleteLocation, getAllLocation, updateActiveLocation, updateLocation } from "../../services/locationService";
import { addFuelStation, deleteFuelStation, getAllFuelStation, getAllFuelStationApi, singleFuelStationDetails, updateActiveFuelStation, updateFuelStation } from "../../services/fuelStationService";
import { getStorage } from "../../helper/storage";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

export const FuelStation = () => {
    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id"
    };

    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true

    };

    const customerObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const formObj = {
        number: "",
        date: formatDate(new Date()),
    };

    const orderObj = {

        station_name: '',
        contact_number: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        code: "",
        is_active: true,
        count: ""
    }
    const orderObjName = {

        station_name: '',
        contact_number: "",
        address_one: '',
        address_two: '',
        country: "",
        state: "",
        city: "",
        pincode: '',
        code: "",
        is_active: true,
        count: "",
    }
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [status, setStatus] = useState(1)
    const [filter, setFilter] = useState({
        limit: 20,
        status: status,
        ...obj,

    });
    const userLogin = getStorage("logged-in");
    const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Fuel Station"))[0]?.
        permissions : []
    const debouncedFilter = useDebounce(filter, 500);
    const [disableInp, setDisableInp] = useState({
        name: "",
    });
    const [formValue, setFormValue] = useState(formObj);
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formError, setFormError] = useState("");
    const [updateId, setUpdateId] = useState("")
    const [preview, setPreview] = useState(null)
    const [showCropper, setShowCropper] = useState(false);
    const [isLoadding, setIsLoadding] = useState(false)
    const [customerList, setCustomerList] = useState()
    const [countryList, setCountryList] = useState()
    const [fuelApiList, setFuelApiList] = useState()

    const [downloadcsv, setDownload] = useState('')
    const [loaderForList, setLoaderForList] = useState(false)
    const Requirement = [
        { heading: "S.No", className: "sorting_asc", value: "sno", sortName: "" },

        { heading: "Fuel Station Name", className: "sorting", value: "station_name", sortName: "" },
        { heading: "Branch", className: "sorting_asc", value: "route", sortName: "" },
        { heading: "City", className: "sorting_asc", value: "city", sortName: "" },

        { heading: "Action", className: "sorting_asc", value: "action", sortName: "" },

    ];


    const rowDataList = () => {
        const listArray = dataList?.results?.map((item, i) => ({
            ...item,

            locationType: item.location_type === 1 ? "Pickup" : "Drop",
            status: (
                <div className="statusofOrder">
                    {item?.status === 1 ? <span>Requirement</span> : item?.status === 2 ? <span>Pending</span> : item?.status === 3 ? <span>Active Orders</span> : item?.status === 4 ? <span>Near to Expair</span> : item?.status === 5 ? <span>Cancelled</span> : item?.status === 6 ? <span>Completed</span> : ""}
                </div>
            ),

            route: (
                <div>
                    {countryList?.find((cu) => cu.id === item?.country).name}
                </div>
            ),

            product: (
                <div>
                    {item?.product_detail?.name}
                </div>
            ),

            date: (

                <div className="dateOfOredr">{formatDate(item?.added_on)}</div>
            ),
            qty: `${item?.qty} Ltr`,

            action: (
                <>
                    <div className="actionBtn">

                        <div className="customerStatus"><SwitchField onSwitchChange={() => updateTagStatus(item)} value={item?.is_active} />{item?.is_active ? <span>Disable</span> : <span>Enable</span>}</div>

                        {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span> : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
                        {userLogin?.user_type === 1 ? <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span> : permissions[4] && <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>}

                        {/* {userLogin?.user_type === 1 ? <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} /> : permissions[3] && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
                        {userLogin?.user_type === 1 ? <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> : permissions[4] && <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />} */}
                    </div>
                </>
            ),
            sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
            icon: (
                <>
                    <img src={item?.icon} alt={item?.name} />
                </>
            ),
        }));
        return listArray;
    };



    const { data: dataList, isSuccess } = useQuery(
        ["getAllFuelStation", debouncedFilter],
        () => getAllFuelStation(debouncedFilter, filter),
        {
            enabled: Boolean(debouncedFilter),
            refetchOnWindowFocus: false

        }
    );

    useEffect(() => {
        if (dataList) {
            setLoaderForList(false)
        } else {
            setLoaderForList(true)
        }
    }, [dataList])

    useEffect(() => {
        getCountryList()
        getCustomerList()
        getFuelApiList()

    }, [])

    const getCountryList = async () => {
        const res = await getCountry(countryObj)
        setCountryList(res?.results)

    }

    const getFuelApiList = async () => {
        const res = await getAllFuelStationApi(countryObj)
        setFuelApiList(res?.results)

    }

    const getCustomerList = async () => {
        const res = await getAllCustomer(customerObj)
        const list = res.results?.map((item, i) => {
            return { name: item.name, id: item?.id }
        })

        setCustomerList(list)

    }


    const clearAll = () => {
        setFormOrderValue({
            station_name: '',
            contact_number: "",
            address_one: '',
            address_two: '',
            country: "",
            state: "",
            city: "",
            pincode: '',
            api: ""

        })
        setFormOrderName({
            station_name: '',
            contact_number: "",
            address_one: '',
            address_two: '',
            country: "",
            state: "",
            city: "",
            pincode: '',
            api: ""

        })
        setPreview('')
        setIsEdit(false);
        setFormValue("");
        setFormError("");
    }



    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };

    const updateTagStatus = (item) => {

        const payload = {
            id: item.id,
            is_active: !item?.is_active
        }
        updateActiveMutation.mutate(payload);
    }
    const updateActiveMutation = useMutation(updateActiveFuelStation, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });


    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addLocationValidation
            );
            setValidationError(error);
        }
    };


    const onOrderSubmit = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue },
            addFuelStationValidation
        );

        const payload = {

            station_name: formOrderValue?.station_name,
            contact_number: formOrderValue?.contact_number,
            address_one: formOrderValue?.address_one,
            address_two: formOrderValue?.address_two,
            country: formOrderValue?.country,
            state: formOrderValue?.state,
            city: formOrderValue?.city,
            pincode: formOrderValue?.pincode,
            is_active: formOrderValue?.is_active,
            code: formOrderValue?.code,
            api: formOrderValue?.api

        }

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                handleUpdate(payload);
            } else {
                handleAdd(payload);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };



    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };

    const handleDelete = () => {
        deleteMutation.mutate(deleteId);
    };


    const handleEditAction = (item) => {
        getFuelStationDetails.mutate(item?.id)

    };

    const getFuelStationDetails = useMutation(singleFuelStationDetails, {
        onSuccess: (res) => {

            if (res?.success) {

                setFormOrderValue({
                    station_name: res?.results?.station_name,
                    contact_number: res?.results?.contact_number,
                    address_one: res?.results?.address_one,
                    address_two: res?.results?.address_two,
                    country: res?.results?.country,
                    state: res?.results?.state,
                    city: res?.results?.city,
                    pincode: res?.results?.pincode,
                    code: res?.results?.code,
                    is_active: res?.results?.is_active,
                    api: res?.results?.api
                })

                const common = (name, id) => {
                    if (name === "country") {
                        let details = countryList?.find((i) => i.id === id)
                        return { data: details, label: details?.name, value: details?.id }
                    }
                    if (name === "api") {
                        let details = fuelApiList?.find((i) => i.id === id)
                        return { data: details, label: details?.title, value: details?.id }
                    }
                }
                setFormOrderName({
                    ...formOrderName,
                    country: common("country", res?.results?.country),
                    api: common("api", res?.results?.api),

                })

                setPreview(res?.results?.image)
                setIsEdit(true)
                setIsOpen(true)
                setFormError("");
                setUpdateId(res?.results?.id)

            } else {

            }
        }
    })

    const handleUpdate = async (data) => {

        const payload = {
            formdata: data,
            id: updateId
        }

        updateMutation.mutate(payload);
    };

    const addMutation = useMutation(addFuelStation, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);

            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    const updateMutation = useMutation(updateFuelStation, {
        onSuccess: (res) => {
            if (res?.success) {
                setIsEdit(false);
                onSuccessResponse(res?.message);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const deleteMutation = useMutation(deleteFuelStation, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                setOpenDel(false);
            } else {
                onErrorResponse(res?.error);
            }
        },
    });

    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success", { position: "bottom-left" });
        queryClient.refetchQueries("getAllFuelStation");
        setIsOpen(false);
        setIsLoadding(false)
        clearAll()
    };

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
        setIsLoadding(false)
    };

    const handlePageClick = async (data) => {
        const currentPage = data.selected;
        setFilter((s) => ({
            ...s,
            offset: currentPage * filter.limit,
            currentPage: currentPage,
        }));
    };

    const hendleDeleteTag = (data, flag) => {
        setOpenDel(flag);
        setDeleteId(data)
    }
    useEffect(() => {
        if (formValue?.image) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setPreview(reader.result);
            };

            reader.readAsDataURL(formValue?.image);
        } else {
            if (preview) {
                setPreview(preview);
            } else {
                setPreview(null);
            }
        }
    }, [formValue?.image]);

    const handleActive = (data) => {
        setLoaderForList(true)
        setFilter({
            ...filter,
            status: data.value
        })
    }

    const getTableList = () => {
        switch (+filter.status) {
            case 1:
                return Requirement

            default:
                return Requirement

        }
    }



    return (
        <>
            <div className='tabMain_head'> <Tabs TabList={fuelStationTabList} handleActive={handleActive} activeTab={filter.status} />
                <div>
                    {userLogin?.user_type === 1 ? <button className='addPlan' onClick={() => setIsOpen(true)}>Add Fuel Station</button> : permissions[2] && <button className='addPlan' onClick={() => setIsOpen(true)}>Add Fuel Station</button>}


                </div>

            </div>
            <div className="anvTask_prog">
                <div className="anvInputs&fillters">

                    <div className="inr_flex_filter" >
                        <button className="filterBox">Filter</button>
                    </div>

                    <div className="inr_flex_mini">
                        <InputField placeholder="Search" className="anvSearch_input" value={filter?.search}
                            label="Search"
                            onChange={(e) =>
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    search: e.target.value,
                                }))
                            } />
                    </div>
                </div>
            </div>

            <div>
                <div className="table-responsive">
                    <CustomTable
                        column={getTableList()}
                        data={rowDataList()}
                        tableLoading={loaderForList}
                    />
                </div>
            </div>
            {dataList?.results?.length === 0 ? (
                ""
            ) : (
                <div className="pagination_count">
                    <div className="">
                        <Dropdown
                            placeholder="Select Count"
                            className="dropdownoforder orderModal_Input"
                            name="driver"
                            options={pageCount ? pageCount : []}

                            valueText="value"
                            labelText="label"
                            selectedOption={formOrderName?.count ? formOrderName?.count : { label: "20", value: 20 }}
                            setSelectedOption={(data) => {
                                setFilter((s) => ({
                                    ...s,
                                    ...obj,
                                    limit: data.value,
                                }))

                                setFormOrderName({ ...formOrderName, count: data });
                            }}
                        />
                    </div>

                    <CustomPagination
                        onPageChange={handlePageClick}
                        currentPage={filter?.currentPage}
                        pageCount={Math.ceil(dataList?.count / filter?.limit)}
                    />
                </div>
            )}



            {isOpen && <CustomOffcanvas
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(!isOpen)
                    clearAll()
                    setPreview('')
                    setIsEdit(false);
                }
                }
                isLoadding={isLoadding}
                onSubmit={(e) => onOrderSubmit(e)}
                title={isEdit ? "Edit Fuel Station" : "Add Fuel Station"}
                submitLabel={isEdit ? "Update Fuel Station" : "Add Fuel Station"}
                inputProps={
                    <div className="anvAdd_drive">


                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Fuel Station" : "Fuel Station"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Fuel Station Name"
                                    name="station_name"
                                    type="text"
                                    focus={!!formError?.station_name}
                                    error={formError.station_name}
                                    value={formOrderValue?.station_name}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Contact Number" : "Contact Number"}
                                    labelClass="FormLableKaran"
                                    placeholder="Add Contact Number"
                                    name="contact_number"
                                    type="text"
                                    maxLength={12}
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.contact_number}
                                    error={formError.contact_number}
                                    value={formOrderValue?.contact_number}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Fuel Station Code (Third Party)" : "Fuel Station Code (Third Party)"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Code"
                                    name="code"
                                    type="text"
                                    maxLength={7}
                                    focus={!!formError?.code}
                                    error={formError.code}
                                    value={formOrderValue?.code}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                {/* <span>
                                    <Label className="FormLableKaran">Fuel Station Api</Label>
                                </span> */}
                                <Dropdown
                                    className='dropdownoforder'
                                    placeholder="Select Fuel Api"
                                    label="Select Fuel Api"
                                    classNamePrefix="_anvFloatSelect"
                                    boxClass="_anvFloatedSelect"
                                    name="api"
                                    options={fuelApiList ? fuelApiList : []}
                                    valueText="id"
                                    labelText="title"
                                    focus={!!formError?.api}
                                    error={formError?.api}
                                    selectedOption={formOrderName?.api}
                                    setSelectedOption={(data) => {
                                        setFormOrderName({ ...formOrderName, api: data })
                                        handleOrderChange("api", data?.value);

                                    }}
                                />
                            </div>
                        </div>
                        <div className="anvOt_outerFull">
                            <span></span>
                        </div>
                        <div className="anvOt_outerFull partMarg">
                            <span>Fuel Station Address Details</span>
                        </div>
                        <div className="anvOt_gridBx ">
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Address Line 1" : "Address Line 1"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="address_one"
                                    type="text"
                                    focus={!!formError?.address_one}
                                    error={formError.address_one}
                                    value={formOrderValue?.address_one}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Address Line 2" : "Address Line 2"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="address_two"
                                    type="text"
                                    focus={!!formError?.address_two}
                                    error={formError.address_two}
                                    value={formOrderValue?.address_two}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                {/* <span>
                                    <Label className="FormLableKaran">Country</Label>
                                </span> */}
                                <div>
                                    <Dropdown
                                        className='dropdownoforder'
                                        placeholder="Select Country"
                                        label="Select Country"
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect"
                                        name="fleet_type"
                                        options={countryList ? countryList : []}
                                        valueText="id"
                                        labelText="name"
                                        focus={!!formError?.country}
                                        error={formError?.country}
                                        selectedOption={formOrderName?.country}
                                        setSelectedOption={(data) => {

                                            setFormOrderName({ ...formOrderName, country: data })
                                            handleOrderChange("country", data?.value);

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "State" : "State"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="state"
                                    type="text"
                                    focus={!!formError?.state}
                                    error={formError.state}
                                    value={formOrderValue?.state}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "City" : "City"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="city"
                                    type="text"
                                    focus={!!formError?.city}
                                    error={formError.city}
                                    value={formOrderValue?.city}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                            <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Pin Code" : "Pin Code"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="pincode"
                                    type="text"
                                    maxLength={7}
                                    focus={!!formError?.pincode}
                                    error={formError.pincode}
                                    value={formOrderValue?.pincode}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div>
                        </div>
                        {/* <div className="anvOt_inputField ">
                                <InputField
                                    className="modal_input orderModal_Input"
                                    label={isEdit ? "Truck number" : "Country"}
                                    labelClass="FormLableKaran"
                                    placeholder=""
                                    name="country"
                                    type="text"
                                    focus={!!formError?.country}
                                    error={formError.country}
                                    value={formOrderValue?.country}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            </div> */}


                    </div>

                }
            />}
            < Modal isOpen={showCropper} onClose={() => setShowCropper(true)} className="imageCrooperModal" >
                {
                    typeof showCropper !== "boolean" && (
                        <ImageCropper
                            setFormValue={setFormValue}
                            setShowCropper={setShowCropper}
                            image={showCropper}
                            cropShape="rect"
                            aspect={6 / 3}
                            formValue={formValue}
                        />
                    )
                }

            </ Modal>
            <ConfirmModal
                isOpen={openDel}
                toggle={() => setOpenDel(!openDel)}
                onSubmit={() => handleDelete()}
            />


        </>
    );
};
