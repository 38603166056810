import React, { useState } from "react";
import { Button, Navbar } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { icon } from "../Icon";
import { clearStorage, getStorage } from "../../helper/storage";
import { AiOutlineMenu } from "react-icons/ai";
import ConfirmModal from "../Common/ConstomModal/ConfirmModal";
import toggleIcon from "../../../image/hamIcon.svg"

const Header = ({ toggle }) => {

  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const userProfile = getStorage("logged-in")
  const handleLogout = () => {
    const callBack = () => {
      // navigate("/login");
    };
    clearStorage(callBack);
  };
  return (
    <>
      <Navbar className="navbar shadow-sm p-2 " expand="md">
        <div className="head_outDiv">
          <div className="">
            <Button className="anv_togleBtn" onClick={() => toggle()}>
              <img src={toggleIcon} />
              {/* <AiOutlineMenu /> */}
            </Button>
          </div>
          <div className="userName_div">
            <div>
{`${userProfile?.name} ${userProfile?.user_type==1? "(Admin)":"(User)"}`}
            </div>
          <div className="btn" onClick={() => setOpen(true)}>
            {icon.LogoutIcon} Log out
          </div>
          </div>
        </div>
      </Navbar>

      <ConfirmModal
        isOpen={open}
        toggle={() => setOpen(!open)}
        onSubmit={() => handleLogout()}
        title="Logout"
        message="Are you sure you want to log out?"
      />
    </>
  );
};



export default Header;
