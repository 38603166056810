import React, { useCallback, useEffect, useState } from "react";
import {
  Circle,
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
  useLoadScript,
} from "@react-google-maps/api";
import { FaDrawPolygon } from "react-icons/fa"; 

const containerStyle = {
  width: "100%",
  height: "100%",
  border: "1px solid #ddd",
  borderRadius: "0px",
};  

const libraries = ["drawing"];

function NewGoogleMapComp({
  zoom,
  lat,
  lng,
  locations,
  onPolygonChange,
  onLocationChange,
  onNewPolygonChange,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
    libraries,
  });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: Number(lat), lng: Number(lng) });
  const [polygonPath, setPolygonPath] = useState([]);
  const [tempPolygonPath, setTempPolygonPath] = useState([]);
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(zoom);

  const loadHandler = (mapInstance) => {
    setMap(mapInstance);
    mapInstance.addListener("zoom_changed", () => {
      setZoomLevel(mapInstance.getZoom());
    });
  };

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    setCenter({ lat: Number(lat), lng: Number(lng) });
  }, [lat, lng]);


  if (!isLoaded) return <div>Loading...</div>;
  if (loadError) return <div>Error loading map</div>;

  const togglePolygonDrawing = () => {
    if (!isDrawingPolygon) {
      setTempPolygonPath([]);
    }
    setIsDrawingPolygon((prev) => !prev);
  };

  const onMapClick = (event) => {
    if (!isDrawingPolygon) return;

    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    const newPoint = { lat: newLat, lng: newLng };

    const isFirstPoint =
      tempPolygonPath.length > 0 &&
      isCloseToOrigin(newPoint, tempPolygonPath[0]);

    if (isFirstPoint) {
      const finalPolygonPath = [...tempPolygonPath, tempPolygonPath[0]];
      const formattedCoordinates = finalPolygonPath.map((point) => [
        point.lng,
        point.lat,
      ]);

      const result = {
        coordinates: [formattedCoordinates],
      };

      setPolygonPath(finalPolygonPath);
      setTempPolygonPath([]);
      setIsDrawingPolygon(false);

      if (onPolygonChange) {
        onPolygonChange(result);
      }
    } else {
      const updatedPath = [...tempPolygonPath, newPoint];
      setTempPolygonPath(updatedPath);
      setPolygonPath([]);

      if (onPolygonChange) {
        onPolygonChange(updatedPath);
      }
    }
  };

  const isCloseToOrigin = (newPoint, originPoint) => {
    const threshold = 0.0001;
    const latDiff = Math.abs(newPoint.lat - originPoint.lat);
    const lngDiff = Math.abs(newPoint.lng - originPoint.lng);

    return latDiff < threshold && lngDiff < threshold;
  };

  const calculatePolygonCenter = (path) => {
    let latSum = 0;
    let lngSum = 0;
    const len = path.length;

    path.forEach((point) => {
      latSum += point.lat;
      lngSum += point.lng;
    });

    return {
      lat: latSum / len,
      lng: lngSum / len,
    };
  };

  const handleCircleClick = (coordinate) => {
    const finalPolygonPath = [...tempPolygonPath, tempPolygonPath[0]];

    const formattedCoordinates = finalPolygonPath.map((point) => [
      point.lng,
      point.lat,
    ]);

    const result = {
      coordinates: [formattedCoordinates],
    };

    const polygonCenter = calculatePolygonCenter(finalPolygonPath);
    setCenter(polygonCenter);

    setPolygonPath(finalPolygonPath);
    setTempPolygonPath([]);
    setIsDrawingPolygon(false);

    if (onPolygonChange) {
      onPolygonChange(result);
    }
  };

  const getCircleRadius = () => {
    return Math.pow(2, 20 - zoomLevel) * 0.8;
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <button
        onClick={togglePolygonDrawing}
        style={{
          position: "absolute",
          top: "60px",
          right: "10px",
          zIndex: 5,
          backgroundColor: isDrawingPolygon ? "#FF0000" : "#FFFFFF",
          padding: "10px",
          borderRadius: "50%",
          border: "none",
          cursor: "pointer",
        }}
        title={
          isDrawingPolygon ? "Stop Drawing Polygon" : "Start Drawing Polygon"
        }
      >
        <FaDrawPolygon size={24} color={isDrawingPolygon ? "#FFF" : "#000"} />
      </button>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoomLevel}
        onLoad={loadHandler}
        onUnmount={onUnmount}
        onClick={onMapClick}
        mapTypeId="hybrid"
      >
        <Marker position={center} />
        {locations &&
          locations.map((location, index) => (
            <Marker
              key={index}
              position={{
                lat: Number(location.latitude),
                lng: Number(location.longitude),
              }}
              title={
                location.clientName ? location.clientName : location.siteName
              }
            />
          ))}

        {tempPolygonPath.length > 0 && (
          <>
            <Polygon
              paths={tempPolygonPath}
              options={{
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                editable: false,
              }}
            />

            <Circle
              center={tempPolygonPath[0]}
              radius={getCircleRadius()}
              options={{
                fillColor: "#FF0000",
                strokeColor: "#FF0000",
                strokeWeight: 1,
                fillOpacity: 1,
              }}
              onClick={() => handleCircleClick(tempPolygonPath[0])}
            />

            <Circle
              center={tempPolygonPath[tempPolygonPath.length - 1]}
              radius={getCircleRadius()}
              options={{
                fillColor: "#FF0000",
                strokeColor: "#FF0000",
                strokeWeight: 1,
                fillOpacity: 1,
              }}
              onClick={() =>
                handleCircleClick(tempPolygonPath[tempPolygonPath.length - 1])
              }
            />
          </>
        )}

        {polygonPath.length > 0 && (
          <>
            <Polygon
              paths={polygonPath}
              options={{
                fillColor: "#0000FF",
                fillOpacity: 0.35,
                strokeColor: "#0000FF",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                editable: false,
              }}
            />

            <Circle
              center={polygonPath[0]}
              radius={getCircleRadius()}
              options={{
                fillColor: "#0000FF",
                strokeColor: "#0000FF",
                strokeWeight: 1,
                fillOpacity: 1,
              }}
              onClick={() => handleCircleClick(polygonPath[0])}
            />

            <Circle
              center={polygonPath[polygonPath.length - 1]}
              radius={getCircleRadius()}
              options={{
                fillColor: "#0000FF",
                strokeColor: "#0000FF",
                strokeWeight: 1,
                fillOpacity: 1,
              }}
              onClick={() =>
                handleCircleClick(polygonPath[polygonPath.length - 1])
              }
            />
          </>
        )}
      </GoogleMap>
    </div>
  );
}

export default React.memo(NewGoogleMapComp);
