import React, { useEffect, useState } from "react";
import { Tabs } from "../../Component/Common/Tabs";
import { getAllTrips, getTripExcel } from "../../services/tripService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CustomPagination from "../../Component/Common/CustomPagination";
import { checkFormError, formatDate, formatDateList, pageCount, tripsTabList } from "../../helper";
import { getStorage } from "../../helper/storage";
import useDebounce from "../../helper/customhook";
import CustomTable from "../../Component/Common/CustomTable";
import { getAllRoute } from "../../services/routeService";
import { Label } from "reactstrap";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import { filterTripDate } from "../../helper/errorSchema";
import Dropdown from "../../Component/Common/Dropdown";

export const TripReport = () => {
  const Requirement = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },
    {
      heading: "Trip/ Nomination",
      className: "sorting",
      value: "nomination",
      sortName: "",
    },
    {
      heading: "Order No.",
      className: "sorting_asc",
      value: "orderNo",
      sortName: "",
    },
    { heading: "Date", className: "sorting_asc", value: "date", sortName: "" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "customerName",
      sortName: "",
    },
    { heading: "Route", className: "sorting", value: "route", sortName: "" },
    {
      heading: "Fleet Number",
      className: "sorting_asc",
      value: "fleet_number",
      sortName: "",
    },
    {
      heading: "Driver",
      className: "sorting_asc",
      value: "driverName",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "status",
      sortName: "",
    },
    // { heading: "Action", className: "sorting_asc", value: "action", sortName: "" },
  ];

  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    customer_name: "",
  };

  const routeObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const dateObject = {
    from_date: '',
    to_date:""
  }

  const dateObjectName = {
    from_date:"" ,
    to_date:""
  }

  const queryClient = useQueryClient();
  const userLogin = getStorage("logged-in");
  const [routeList, setRouteList] = useState();
  const [status, setStatus] = useState(1);
  const [loaderForList, setLoaderForList] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [formOrderValue, setFormOrderValue] = useState(dateObject)
  const [formOrderName, setFormOrderName] = useState(dateObjectName)
  const [formError, setFormError] = useState("");

  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    customer_id: userLogin?.user_type === 2 ? userLogin?.id : "",
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);

  const rowDataList = () => {
    const listArray = dataList?.results?.map((item, i) => ({
      ...item,
      orderNo: item?.order_detail?.order_number,
      customerName: item?.order_detail?.customer,
      driverName: item?.order_detail?.driver,
      nomination: (
        <div className="driverName">
          {item?.order_detail?.nomination_number}
        </div>
      ),
      serial: i + 1,

      status: (
        <div className="statusofOrder">
          {item?.status === 1 ? (
            <span>Nomination</span>
          ) : item?.status === 2 ? (
            <span>Doc. Ready</span>
          ) : item?.status === 3 ? (
            <span>At Port Entry</span>
          ) : item?.status === 4 ? (
            <span>At Loading</span>
          ) : item?.status === 6 ? (
            <span>In Transit</span>
          ) : item?.status === 5 ? (
            <span>Fund Allocation</span>
          ) : item?.status === 7 ? (
            <span>At Destination</span>
          ) : item?.status === 8 ? (
            <span>Delivered</span>
          ) : item?.status === 10 ? (
            <span>Completed</span>
          ) : item?.status === 11 ? (
            <span>Cancel</span>
          ) : (
            ""
          )}
        </div>
      ),

      route: routeList?.find((i) => i.id === item?.route)?.route_name,
      product: item?.product_detail?.name,
      date: formatDateList(item?.added_on),
      qty: `${item?.qty} ${
        item?.product_detail?.product_type === 1 ? "Ltr" : "Ton"
      }`,

      sno: i + 1 + filter.limit * filter.currentPage,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllTrips", debouncedFilter],
    () => getAllTrips(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
      setIsLoadding(false);
    } else {
      setLoaderForList(true);
    }
    getRouteList();
  }, [dataList]);

  const getRouteList = async () => {
    const res = await getAllRoute(routeObj);
    setRouteList(res?.results);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handleActive = (data) => {
    if (filter.status !== data.value) {
      setIsLoadding(true);
    }
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return Requirement;

      default:
        return Requirement;
    }
  };

  const setValidationError = (error) => {
    setFormError({
        ...error,
    });
};

  const handleOrderChange = async (name, value) => {

    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
        const error = await checkFormError(stateObj, filterTripDate
        );
        setValidationError(error);
    }
};

const handleExportAction = () => {
    const payload ={
        from_date:formOrderName?.from_date,
        to_date:formOrderName?.to_date
    }
    downloadcsvExcle.mutate(payload);
};

const downloadcsvExcle = useMutation(getTripExcel, {
    onSuccess: (res) => {

        if (res) {
            const url = window.URL.createObjectURL(
                new Blob([res], { type: "application/octet-stream" })
            );
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Trip Report.xlsx`);
            link.click();
            // link.download = 'filename.txt';
           
        }
    },
});


  return (
    <div>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={tripsTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div  className="trip_display">
          {/* <Label className="FormLableKaran">From</Label> */}
            <div className="inr_flex_mini1 ">
              <div>
                <CustomDatePicker
                  type="date"
                  label="From"
                  value={formOrderName?.from_date}
                  max={formatDate(new Date())}
                  focus={!!formError?.from_date}
                  error={formError.from_date}
                  name="from_date"
                  onSelect={({ target: { name, value } }) => {
                      handleOrderChange(name, value)
                      setFormOrderName({ ...formOrderName, from_date: value })
                  }
                  }

                  className="date-input"
                />
              </div>
            </div>
            {/* <Label className="FormLableKaran">To</Label> */}
            <div className="inr_flex_mini1 ">
              <div>
                <CustomDatePicker
                label="To"
                  type="date"
                  value={formOrderName?.to_date}
                  max={formatDate(new Date())}
                  focus={!!formError?.to_date}
                  error={formError.to_date}
                  name="to_date"
                  onSelect={({ target: { name, value } }) => {
                      handleOrderChange(name, value)
                      setFormOrderName({ ...formOrderName, to_date: value })
                  }
                  }

                  className="date-input"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn_trip_donload">
                    <button className='addPlan' onClick={handleExportAction}>Download</button>
                   
                </div>
      </div>

      <div>
        <div className="table-responsive">
          <CustomTable
            column={getTableList()}
            data={rowDataList()}
            tableLoading={loaderForList}
          />
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
        <div className="">
        <Dropdown
         placeholder="Select Count"
         className="dropdownoforder orderModal_Input"
         name="driver"
         options={pageCount ? pageCount : []}
         valueText="value"
           labelText="label"
          
           selectedOption={formOrderName?.count?formOrderName?.count:{label:"20",value:20}}

           setSelectedOption={(data) => {
            setFilter((s) => ({
              ...s,
              ...obj,
              limit:data.value,
            }))
            
             setFormOrderName({ ...formOrderName, count: data });
           }}
  
       
        />
        </div>
        
<CustomPagination
        onPageChange={handlePageClick}
        currentPage={filter?.currentPage}
        pageCount={Math.ceil(dataList?.count / filter?.limit)}
      />
      </div>
      )}
    </div>
  );
};
