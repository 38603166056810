import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { Label } from 'reactstrap'
import { addRouteTabList, checkFormError, formatDate, getResposeError, handleKeyDown } from '../../helper'
import { addExpenseValidation, addFuelValidation, addRouteDetails, modalInputSchema } from '../../helper/errorSchema'
import { getCountry } from '../../services/countryService'
import { addCurrencyApi, getCurrency } from '../../services/currencyService'
import { getExpenseCategory } from '../../services/expenseCategory'
import { getAllFuelStation } from '../../services/fuelStationService'
import { getAllDrop, getAllPickup } from '../../services/locationService'
import { getAllCustomer } from '../../services/researchService'
import { addRoute } from '../../services/routeService'
import InputModal from './ConstomModal/InputModal'
import Dropdown from './Dropdown'
import { InputField } from './InputField'
import { Tabs } from './Tabs'
import { components } from "react-select";
import { getExitBorder } from '../../services/exitBorderService'
import { singleRouteDetails } from '../../services/routeService'


const CommonRoute = (props) => {
    const { isOpen, setIsOpen, routeCallBack, customerValue, isDisableOpen } = props
   

    const obj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id"
    };


    const fuelstationObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const customerObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const expenseObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };
    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true

    };
    const currencyObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all"

    };


    const formObj = {
        number: "",
        date: formatDate(new Date()),
    };

    const orderObj = {
        customer: customerValue?.value,
        from_location: [],
        to_location: [],
        route_name: '',
        kilometer: '',
        remark: "",
        expenses: [],
        fuel_expenses: [],
        is_active: true,
        addCurrency: "",
    }
    const orderObjName = {
        customer: customerValue,
        from_location: [],
        to_location: [],
        route_name: '',
        kilometer: '',
        remark: "",
        expenses: [],
        fuel_expenses: [],
        is_active: true,
        addCurrency: "",
    }

    let defultExpense = {
        category: "",
        amount: "",
        currency: "",
        description: "",
    };

    let defultFuel = {
        fuel_station: "",
        ltr: "",
        description: "",
    };

    const [formValue, setFormValue] = useState(formObj);
    const [formOrderValue, setFormOrderValue] = useState(orderObj)
    const [formOrderName, setFormOrderName] = useState(orderObjName)
    const [formError, setFormError] = useState("");
    const [activeModalTab, setActiveModalTab] = useState(1)
    const [customerList, setCustomerList] = useState([])
    const [fuelStationList, setFuelStationList] = useState()
    const [expeCateList, setExpeCateList] = useState()
    const [countryList, setCountryList] = useState()
    const [pickupList, setPickupList] = useState([])
    const [dropList, setDropList] = useState([])
    const [borderList,setBorderList]= useState([])
    const [currencyList, setCurrencyList] = useState()
    const [fromList, setFromList] = useState([{ label: "", value: "" }])
    const [toList, setToList] = useState([{ label: "", value: "" }])
    const [borderCheckList, setBorderCheckList] = useState([{ label: "", value: "" }])
    const [isLoadding, setIsLoadding] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [expenseList, setExpenseList] = useState([
        defultExpense
    ])
    const [fuelList, setFuelList] = useState([
        defultFuel
    ])
    const [isDisableOption,setDisableOption]= useState(false)

    useEffect(() => {
        getExpeCategoryList()
        getFuelStationList()
        getCountryList()
        getCurrencyList()
        getCustomerList()
        getToLocatonList()
        getFromLocatonList()
        getExitBorderList()


    }, [])


    useEffect(()=>{
        if(isDisableOpen === true && customerList.length > 0){
            getRouteDetails(isOpen)
        }
    },[isDisableOpen,customerList])

    useEffect(()=>{
        if(isOpen?.data && isDisableOpen === false) {
            setFormOrderValue({
                customer: isOpen?.data?.customer_detail?.id,
                route_name: isOpen?.data?.route_name,
                kilometer: isOpen?.data?.kilometer,
                remark: isOpen?.data?.remark,
                is_active: isOpen?.data?.is_active,
            });

            const common = (name, id) => {
                // if (name === "customer") {
                //     let details = customerList?.find((i) => i.id === id);
                //     return { data: details, label: details?.name, value: details?.id };
                // }
                if (name === "currency") {
                    let details = currencyList?.find((i) => i.id === id);
                    return { data: details, label: details?.name, value: details?.id };
                }
                if (name === "country") {
                    let details = countryList?.find((i) => i.id === id);
                    return { data: details, label: details?.name, value: details?.id };
                }
            };

            setFormOrderName({
                ...formOrderName,
                // customer: common("customer", isOpen?.data?.customer_detail?.id),
                currency: common("currency", isOpen?.data?.currency),
                country: common("country", isOpen?.data?.country),
            });

            const newFromList = isOpen?.data?.pickup_location_detail?.map((i) => ({
                label: i?.name, value: i?.id
            }));
            const newToList = isOpen?.data?.drop_location_detail?.map((i) => ({
                label: i?.name, value: i?.id
            }));
            const newBorderCheckList = isOpen?.data?.exit_border_detail?.map((i) => ({
                label: i?.name, value: i?.id
            }));

            setFromList(newFromList);
            setToList(newToList);
            setBorderCheckList(newBorderCheckList);

            // generateRouteName(newFromList, newBorderCheckList, newToList);

            const expoNew = isOpen?.data?.expense_detail?.map((data) => ({
                category: data?.category,
                amount: data?.amount,
                currency: data?.currency,
                description: data?.description,
            }));
            setExpenseList(expoNew);

            const fuelNew = isOpen?.data?.fuel_expense_detail?.map((data) => ({
                fuel_station: data?.fuel_station,
                description: data?.description,
                ltr: data?.ltr,
            }));
            setFuelList(fuelNew);
        }
    },[isOpen])


    const getRouteDetails = async (payloadId) => {
        const res = await singleRouteDetails(payloadId);
        if(res?.status === 200 && res?.success === true){
            setFormOrderValue({
                customer: res?.results?.customer_detail?.id,
                route_name: res?.results?.route_name,
                kilometer: res?.results?.kilometer,
                remark: res?.results?.remark,
                is_active: res?.results?.is_active,
            });
    
            const common = (name, id) => {
                if (name === "customer") {
                    let details = customerList?.find((i) => i.id === id);
                    return { data: details, label: details?.name, value: details?.id };
                }
                if (name === "currency") {
                    let details = currencyList?.find((i) => i.id === id);
                    return { data: details, label: details?.name, value: details?.id };
                }
                if (name === "country") {
                    let details = countryList?.find((i) => i.id === id);
                    return { data: details, label: details?.name, value: details?.id };
                }
            };
    
            setFormOrderName({
                ...formOrderName,
                customer: common("customer", res?.results?.customer),
                currency: common("currency", res?.results?.currency),
                country: common("country", res?.results?.country),
            });
    
            const newFromList = res?.results?.pickup_location_detail?.map((i) => ({
                label: i?.name, value: i?.id
            }));
            const newToList = res?.results?.drop_location_detail?.map((i) => ({
                label: i?.name, value: i?.id
            }));
            const newBorderCheckList = res?.results?.exit_border_detail?.map((i) => ({
                label: i?.name, value: i?.id
            }));
    
            setFromList(newFromList);
            setToList(newToList);
            setBorderCheckList(newBorderCheckList);
    
            // generateRouteName(newFromList, newBorderCheckList, newToList);
    
            const expoNew = res.results?.expense_detail?.map((data) => ({
                category: data?.category,
                amount: data?.amount,
                currency: data?.currency,
                description: data?.description,
            }));
            setExpenseList(expoNew);
    
            const fuelNew = res.results?.fuel_expense_detail?.map((data) => ({
                fuel_station: data?.fuel_station,
                description: data?.description,
                ltr: data?.ltr,
            }));
            setFuelList(fuelNew);
            setDisableOption(true)
        }
      };

    const getExpeCategoryList = async () => {
        const res = await getExpenseCategory(expenseObj)
        setExpeCateList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getFuelStationList = async () => {
        const res = await getAllFuelStation(fuelstationObj)
        setFuelStationList(res?.results?.map((item) => {
            return { ...item, label: item?.station_name, value: item?.id }
        }))

    }

    const getCountryList = async () => {
        const res = await getCountry(countryObj)
        setCountryList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getCurrencyList = async () => {
        const res = await getCurrency(currencyObj)
        setCurrencyList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))
        // setCurrencyList(res?.results)

    }

    const getCustomerList = async () => {
        const res = await getAllCustomer(customerObj)
        const list = res.results?.map((item, i) => {
            return { name: item.name, id: item?.id }
        })

        setCustomerList(list)

    }

    const getFromLocatonList = async () => {
        const payload = {
            status: {
                limit: 20,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
            type: 1
        }
        const res = await getAllPickup(payload)
        setPickupList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getToLocatonList = async () => {
        const payload = {
            status: {
                limit: 20,
                status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
            type: 2
        }
        const res = await getAllDrop(payload)
        setDropList(res?.results?.map((item) => {

            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const getExitBorderList = async () => {
        const payload = {
            status: {
                limit: 20,
                // status: 1,
                offset: 0,
                currentPage: 0,
                search: '',
                ordering: "-id"
            },
        }
        const res = await getExitBorder(payload)
        setBorderList(res?.results?.map((item) => {
            return { ...item, label: item?.name, value: item?.id }
        }))

    }

    const handleModalActive = (data) => {
        setActiveModalTab(data?.value)
    }

    const setValidationError = (error) => {
        setFormError({
            ...error,
        });
    };
    const validationHnalde = async () => {

        const validationResult = await checkFormError(
            { ...formOrderValue, from_location: fromList, to_location: toList },
            addRouteDetails
        );

        setValidationError(validationResult)
        if (validationResult === true) {
            setActiveModalTab(2)
        }
    }

    const validationHnalde2 = async () => {
        const validationResult = await checkFormError(
            { expense: expenseList },
            addExpenseValidation
        );


        setValidationError(validationResult)
        if (validationResult === true) {
            setActiveModalTab(3)
        }
    }

    const handleOrderChange = async (name, value) => {

        const stateObj = { ...formOrderValue, [name]: value };

        setFormOrderValue(stateObj);

        if (!!formError) {
            const error = await checkFormError(stateObj, addRouteDetails);
            setValidationError(error);
        }
    };

    const onOrderSubmit = async () => {
        const validationResult = await checkFormError(
            { ...formOrderValue, from_location: fromList, to_location: toList, expense: expenseList, fuel_expenses: fuelList },
            isEdit ? addFuelValidation : addFuelValidation
        );

        const payload = {
            customer: formOrderValue.customer,
            from_location: fromList?.map((i) => i?.id ? i?.id : i?.value),
            to_location: toList?.map((i) => i?.id ? i?.id : i?.value),
            exit_border: borderCheckList?.map((i) => i?.id ? i?.id : i?.value),
            route_name: formOrderValue.route_name,
            kilometer: formOrderValue.kilometer,
            remark: formOrderValue.remark,
            expenses: expenseList,
            fuel_expenses: fuelList,
            is_active: formOrderValue.is_active
        }

        if (validationResult === true) {

            setIsLoadding(true)
            if (isEdit === true) {

            } else {
                handleAdd(payload);
            }
        } else {
            setIsLoadding(false)
            setFormError(validationResult);
        }
    };

    const handleAdd = (formData) => {

        addMutation.mutate(formData);
    };

    const addMutation = useMutation(addRoute, {
        onSuccess: (res) => {
            if (res?.success) {
                onSuccessResponse(res?.message);
                routeCallBack(res?.results)
            } else {
                setIsLoadding(false)
                onErrorResponse(res?.error);
            }
        },
    });

    const onErrorResponse = (error) => {
        toast.error(getResposeError(error ? error : "server error"));
    };
    const onSuccessResponse = (message) => {
        toast.success(message ? message : "success");

        // queryClient.refetchQueries("getAllRoute");
        setIsOpen(false);
        setIsLoadding(false)
        clearAll()

    };

    const clearAll = () => {
        setFormOrderValue({
            customer: "",
            from_location: [],
            to_location: [],
            route_name: '',
            kilometer: '',
            remark: "",
            expenses: [],
            fuel_expenses: [],
            is_active: true

        })
        setFormOrderName({
            customer: "",
            from_location: [],
            to_location: [],
            route_name: '',
            kilometer: '',
            remark: "",
            expenses: [],
            fuel_expenses: [],
            is_active: true

        })

        setIsEdit(false);
        setFuelList([{
            fuel_station: "",
            ltr: "",
            description: "",
        }])
        setExpenseList([{
            category: "",
            amount: "",
            currency: "",
            description: "",
        }])
        setFromList([{ label: "", value: "" }])
        setToList([{ label: "", value: "" }])
        setFormError('')
        setActiveModalTab(1)
    }

    const addNewExpo = (e) => {
        e.preventDefault();
        setExpenseList((preState) => {
            return [...preState, defultExpense]
        });
    }


    const addNewFuel = (e) => {
        e.preventDefault();
        setFuelList((preState) => {
            return [...preState, defultFuel]
        });
    }

    const addPickLoction = (e) => {
        e.preventDefault();
        setFromList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const addDropLoction = (e) => {
        e.preventDefault();
        setToList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const addBorderLoction = (e) => {
        e.preventDefault();
        setBorderCheckList((preState) => {
            return [...preState, { name: "", id: "" }]
        });
    }

    const deletePickLocation = (e) => {
        e.preventDefault();
        setFromList((prevState) => prevState.slice(0, -1));
      };

      const deleteDropLocation = (e) => {
        e.preventDefault();
        setToList((prevState) => prevState.slice(0, -1));
      };

      const deleteBorderLocation = (e) => {
        e.preventDefault();
        setBorderCheckList((prevState) => prevState.slice(0, -1));
      };
    const updateExpoDetails = (index, data) => {
        const { key, value } = data;

        let newExpenseList = [...expenseList];
        newExpenseList[index][key] = value;
        setExpenseList(newExpenseList);

    }

    const updateFuelDetails = (index, data) => {
        const { key, value } = data;

        let newFuelList = [...fuelList];
        newFuelList[index][key] = value;
        setFuelList(newFuelList);

    }

    const CustomControl = ({ children, ...props }) => (
        <components.Menu {...props}>
            <button className="addMoreBtn" onClick={() => addNewEntryHandle(props?.selectProps?.placeholder, props?.selectProps?.inputValue)}>+ Add New {props?.selectProps?.inputValue ? ` -> "${props?.selectProps?.inputValue}"` : ""}</button>
            {children}

        </components.Menu>
    );

    const addNewEntryHandle = (PlaceName, inputvalue) => {

        switch (PlaceName) {
            case "Select Branch":
                setFormOrderValue({ ...formOrderValue, addBranch: inputvalue, openName: "branch" })
                setOpenModal(true)

                break;

            case "Select Currency":
                setFormOrderValue({ ...formOrderValue, addCurrency: inputvalue, openName: "currency" })
                setOpenModal(true)
                break;

            case "Select Country":
                setFormOrderValue({ ...formOrderValue, addCountry: inputvalue, openName: "country" })
                setOpenModal(true)
                break;

            default:
                break;
        }
    }

    const addNewSubmit = async (value) => {


        const validationResult = await checkFormError(
            { name: value === "branch" ? formOrderValue.addBranch : value === "currency" ? formOrderValue?.addCurrency : formOrderValue.addCountry },
            modalInputSchema
        )

        if (validationResult === true) {
            setIsLoadding(true)
            if (isEdit === true) {
                // handleUpdate();
            } else {
                handleAddNew(value);
            }
        } else {
            setFormError(validationResult);
            setIsLoadding(false)
        }

    };

    const handleAddNew = (value) => {

        const payload = {
            name: formOrderValue?.addCurrency
        }

        addNewCurrencyMutation.mutate(payload)

    }

    const addNewCurrencyMutation = useMutation(addCurrencyApi, {
        onSuccess: (res) => {
            if (res?.success) {
                let expe = [...expenseList];
                expe.map((item, index) => {
                    updateExpoDetails(index, { key: "currency", value: res?.results?.id })

                })
                setExpenseList(expe)
                getCurrencyList()

                setOpenModal(false)

                setIsLoadding(false)
            } else {
                onErrorResponse(res?.error);
                setIsLoadding(false)
            }
        },
    });

    return (
        <div>
            <InputModal
                isOpen={isOpen}
                headerName={isEdit ? "Edit Route" : "Create Route"}
                toggle={() => {
                    // clearAll()
                    setIsOpen(!isOpen)
                    setDisableOption(false)
                }}
                submitLabel={+activeModalTab === 3 ? "Save" : "Continue"}
                onSubmit={(e) => +activeModalTab === 3 ? onOrderSubmit(e) : +activeModalTab === 1 ? validationHnalde() : validationHnalde2()}
                disabled={isDisableOption}
                isLoadding={isLoadding}
                customeClassName="ModalRouteFlex"
                className="routemodalNew"
                inputProps={

                    <>
                        {isOpen && <div div className='TabHeadofmodal routemargin'> <Tabs TabList={addRouteTabList} handleActive={handleModalActive} activeTab={+activeModalTab} customClassName="route" /></div>}
                        {/* {+activeModalTab === 1 && <div className="anvMaintab_container_route">
                            <div className="dropdownMargin">
                                <span>
                                    <Label className="FormLableKaran">Customer Name</Label>
                                </span>
                                <div>
                                    <Dropdown
                                        className='dropdownoforder'
                                        classNamePrefix="mySelect"
                                        placeholder="Select Customer Name"
                                        name="customer"
                                        options={customerList ? customerList : []}
                                        valueText="id"
                                        labelText="name"
                                        focus={!!formError?.customer}
                                        error={formError?.customer}
                                        // component={{ Menu }}
                                        disabled="true"
                                        selectedOption={customerValue}
                                        setSelectedOption={(data) => {

                                            setFormOrderName({ ...formOrderName, customer: data })
                                            handleOrderChange("customer", data?.value);

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="dropdownMargin">
                                {fromList?.map((onFrom, index) => <>
                                    <span>
                                        <Label className="FormLableKaran">Pickup Location</Label>
                                    </span>

                                    <div>
                                        <Dropdown
                                            className='dropdownoforder '
                                            classNamePrefix="mySelect"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={pickupList ? pickupList : []}
                                            valueText="value"
                                            labelText="label"
                                            focus={`${formError[`from_location[${index}].label`]}` !== "undefined" ? !!`${formError[`from_location[${index}].label`]}` : ""}
                                            error={`${formError[`from_location[${index}].label`]}` !== "undefined" ? `${formError[`from_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, branch: data })
                                                const newFromList = [...fromList];
                                                newFromList[index] = data;
                                                setFromList(newFromList)

                                            }}
                                        />
                                    </div>
                                </>)}
                                <div className="addLoctionBtn">
                                    <span onClick={addPickLoction}>+ Add Pickup  Location</span>
                                </div>
                            </div>
                            <div className="dropdownMargin">
                                {toList?.map((onFrom, index) => <><span>
                                    <Label className="FormLableKaran">Drop Location</Label>
                                </span>
                                    <div>
                                        <Dropdown
                                            className='dropdownoforder'
                                            classNamePrefix="mySelect"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={dropList ? dropList : []}
                                            valueText="value"
                                            labelText="label"
                                            focus={`${formError[`to_location[${index}].label`]}` !== "undefined" ? !!`${formError[`to_location[${index}].label`]}` : ""}
                                            error={`${formError[`to_location[${index}].label`]}` !== "undefined" ? `${formError[`to_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {

                                                setFormOrderName({ ...formOrderName, branch: data })
                                                handleOrderChange("branch", data?.value);
                                                const newToList = [...toList];
                                                newToList[index] = data;
                                                setToList(newToList)
                                            }}
                                        />
                                    </div>
                                </>)}
                                <div className="addLoctionBtn">
                                    <span onClick={addDropLoction}>+ Add Drop Location</span>
                                </div>
                            </div>
                            <div className="inputMargin">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "Route Name" : "Route Name"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Route Name"
                                    name="route_name"
                                    type="text"
                                    focus={!!formError?.route_name}
                                    error={formError?.route_name}
                                    value={formOrderValue?.route_name}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            <div className="inputMargin">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "KMS" : "KMS"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter KMs"
                                    name="kilometer"
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.kilometer}
                                    error={formError?.kilometer}
                                    value={formOrderValue?.kilometer}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                            <div className="inputMargin" >

                                <InputField
                                    className="modal_input routeModal_Input "
                                    label={isEdit ? "Remarks" : "Remarks"}
                                    labelClass="FormLableKaran"
                                    placeholder="Remarks"
                                    name="remarks"
                                    type="textarea"
                                    focus={!!formError?.remarks}
                                    error={formError?.remarks}
                                    value={formOrderValue?.remarks}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />

                            </div>
                        </div>} */}
                                                {+activeModalTab === 1 && <div className="anvMaintab_container_route">
                            <div className="dropdownMargin">
                                    <Dropdown
                                        className='dropdownoforder'
                                        classNamePrefix="_anvFloatSelect"
                                        boxClass="_anvFloatedSelect"
                                        label="Customer Name"
                                        placeholder="Select Customer Name"
                                        name="customer"
                                        options={customerList ? customerList : []}
                                        valueText="id"
                                        labelText="name"
                                        disabled={true}
                                        focus={!!formError?.customer}
                                        error={formError?.customer}
                                        components={{ Menu: CustomControl }}
                                        selectedOption={formOrderName?.customer}
                                        setSelectedOption={(data) => {

                                            setFormOrderName({ ...formOrderName, customer: data })
                                            handleOrderChange("customer", data?.value);

                                        }}
                                    />
                            </div>
                            <div className="dropdownMargin mt-2">
                                {fromList?.map((onFrom, index) => <>
                                        <Dropdown
                                            className='dropdownoforder mb-1'
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect lltMarginBtm"
                                            label="Loading Point"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={pickupList ? pickupList : []}
                                            valueText="value"
                                            labelText="label"
                                            focus={`${formError[`from_location[${index}].label`]}` !== "undefined" ? !!`${formError[`from_location[${index}].label`]}` : ""}
                                            error={`${formError[`from_location[${index}].label`]}` !== "undefined" ? `${formError[`from_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, branch: data })
                                                const newFromList = [...fromList];
                                                newFromList[index] = data;
                                                setFromList(newFromList)

                                            }}
                                            disabled={isDisableOption}
                                        />
                                </>)}
                                {isDisableOpen === true ? <></>:
                                <div className="d-flex gap-2">
                                <div className="addLoctionBtn ">
                                    <span onClick={addPickLoction}>+ Add Loading Point</span>
                                </div>
                                {fromList && fromList?.length >1 && 
                                <div className="addLoctionBtn delColorLocation">
                                <span onClick={deletePickLocation}>+ Delete Loading Point</span>
                                </div>
                                }
                                </div>
                                }
                            </div>
                            
                            <div className="dropdownMargin mt-3">
                                {borderCheckList?.map((onFrom, index) => <>
                                        <Dropdown
                                            className='dropdownoforder mb-1'
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect lltMarginBtm"
                                            label="Border"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={borderList ? borderList : []}
                                            valueText="value"
                                            labelText="label"
                                            // focus={`${formError[`from_location[${index}].label`]}` !== "undefined" ? !!`${formError[`from_location[${index}].label`]}` : ""}
                                            // error={`${formError[`from_location[${index}].label`]}` !== "undefined" ? `${formError[`from_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {
                                                setFormOrderName({ ...formOrderName, branch: data })
                                                const newFromList = [...borderCheckList];
                                                newFromList[index] = data;
                                                setBorderCheckList(newFromList)
                                            }}
                                            disabled={isDisableOption}
                                        />
                                </>)}
                                {
                                    isDisableOpen=== true ?<></>:

                                <div className="d-flex gap-2">
                                <div className="addLoctionBtn">
                                    <span onClick={addBorderLoction}>+ Add Border Location</span>
                                </div>
                                {borderCheckList && borderCheckList?.length >1 && 
                                <div className="addLoctionBtn delColorLocation">
                                <span onClick={deleteBorderLocation}>+ Delete Border Location</span>
                                </div>
                                }
                                </div>
                                }
                            </div>

                            <div className="dropdownMargin mt-3">
                                {toList?.map((onFrom, index) => <>
                                        <Dropdown
                                            className='dropdownoforder mb-1'
                                            classNamePrefix="_anvFloatSelect"
                                            boxClass="_anvFloatedSelect lltMarginBtm"
                                            label="Offloading Point"
                                            placeholder="Select Location"
                                            name="branch"
                                            options={dropList ? dropList : []}
                                            valueText="value"
                                            labelText="label"
                                            focus={`${formError[`to_location[${index}].label`]}` !== "undefined" ? !!`${formError[`to_location[${index}].label`]}` : ""}
                                            error={`${formError[`to_location[${index}].label`]}` !== "undefined" ? `${formError[`to_location[${index}].label`]}` : ""}
                                            selectedOption={onFrom}
                                            setSelectedOption={(data) => {

                                                setFormOrderName({ ...formOrderName, branch: data })
                                                handleOrderChange("branch", data?.value);
                                                const newToList = [...toList];
                                                newToList[index] = data;
                                                setToList(newToList)
                                            }}
                                            disabled={isDisableOption}
                                        />
                                </>)}
                                {
                                    isDisableOpen === true ? <></>:
                                <div className="d-flex gap-2">               
                                <div className="addLoctionBtn">
                                    <span onClick={addDropLoction}>+ Add Offloading Point</span>
                                </div>
                                {toList && toList?.length >1 && 
                                <div className="addLoctionBtn delColorLocation">
                                <span onClick={deleteDropLocation}>+ Delete Offloading Point</span>
                                </div>
                                }
                                </div>
                                }
                            </div>
                            <div className="inputMargin" style={{marginTop: "12px"}}>
                            <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "Route Name" : "Route Name"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter Route Name"
                                    name="route_name"
                                    type="text"
                                    focus={!!formError?.route_name}
                                    error={formError?.route_name}
                                    value={formOrderValue?.route_name}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                    disabled={isDisableOption}
                                />


                            </div>
                            <div className="inputMargin">

                                <InputField
                                    className="modal_input routeModal_Input"
                                    label={isEdit ? "KMS" : "KMS"}
                                    labelClass="FormLableKaran"
                                    placeholder="Enter KMs"
                                    name="kilometer"
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    focus={!!formError?.kilometer}
                                    error={formError.kilometer}
                                    value={formOrderValue?.kilometer}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                    disabled={isDisableOption}
                                />

                            </div>
                            <div className="inputMargin" >

                                <InputField
                                    className="modal_input routeModal_Input "
                                    label={isEdit ? "Remarks" : "Remarks"}
                                    labelClass="FormLableKaran"
                                    placeholder="Remarks"
                                    name="remarks"
                                    type="textarea"
                                    focus={!!formError?.remarks}
                                    error={formError.remarks}
                                    value={formOrderValue?.remarks}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                    disabled={isDisableOption}
                                />

                            </div>
                        </div>}
                        {+activeModalTab === 2 &&
                            <div className="anvMaintab_container_route">
                                {expenseList?.map((onExpo, index) => <div className="marginExpoBottom">
                                <div className="dropdownMargin">
                                            <Dropdown
                                                className='dropdownoforder'
                                                classNamePrefix="_anvFloatSelect"
                                                boxClass="_anvFloatedSelect"
                                                label="Currency"
                                                placeholder="Select Currency"
                                                name="currency"
                                                options={currencyList ? currencyList : []}
                                                components={{ Menu: CustomControl }}

                                                valueText="id"
                                                labelText="name"
                                                focus={`${formError[`expense[${index}].currency`]}` !== "undefined" ? !!`${formError[`expense[${index}].currency`]}` : ""}
                                                error={`${formError[`expense[${index}].currency`]}` !== "undefined" ? `${formError[`expense[${index}].currency`]}` : ""}
                                                // selectedOption={currencyList.find((item) => item?.id === onExpo.currency)}
                                                selectedOption={onExpo.currency ? currencyList.find((item) => item?.id === onExpo.currency) : currencyList[0]}
                                                setSelectedOption={(data) => {
                                                    setFormOrderName({ ...formOrderName, currency: data })
                                                    handleOrderChange("currency", data?.value);
                                                    updateExpoDetails(index, { key: "currency", value: data?.value })

                                                }}
                                                disabled={isDisableOption}
                                            />
                                    </div>
                                    <div className="expenseTabdiv">
                                        
                                        <div className="dropdownMargin">
                                                <Dropdown
                                                    className='dropdownoforder'
                                                    classNamePrefix="_anvFloatSelect"
                                                    boxClass="_anvFloatedSelect"
                                                    label="Expense Category"
                                                    placeholder="Parking Fees"
                                                    name="branch"
                                                    options={expeCateList ? expeCateList : []}
                                                    valueText="id"
                                                    labelText="name"
                                                    focus={`${formError[`expense[${index}].category`]}` !== "undefined" ? !!`${formError[`expense[${index}].category`]}` : ""}
                                                    error={`${formError[`expense[${index}].category`]}` !== "undefined" ? `${formError[`expense[${index}].category`]}` : ""}
                                                    selectedOption={expeCateList.find((item) => item?.id === onExpo.category)}
                                                    setSelectedOption={(data) => {

                                                        setFormOrderName({ ...formOrderName, customer: data })
                                                        handleOrderChange("category", data?.value);
                                                        updateExpoDetails(index, { key: "category", value: data?.value })

                                                    }}
                                                    disabled={isDisableOption}
                                                />
                                        </div>
                                        <div className="inputMargin">

                                            <InputField
                                                className="modal_input routeModal_Input"
                                                label={isEdit ? "Amount" : "Amount"}
                                                labelClass="FormLableKaran"
                                                placeholder="Amount"
                                                name="amount"
                                                type="text"
                                                onKeyDown={handleKeyDown}
                                                focus={`${formError[`expense[${index}].amount`]}` !== "undefined" ? !!`${formError[`expense[${index}].amount`]}` : ""}
                                                error={`${formError[`expense[${index}].amount`]}` !== "undefined" ? `${formError[`expense[${index}].amount`]}` : ""}
                                                value={onExpo?.amount}
                                                onChange={({ target: { name, value } }) =>
                                                    updateExpoDetails(index, { key: "amount", value: value })
                                                }
                                                disabled={isDisableOption}
                                            />

                                        </div>
                                    </div>

                                    <div className="inputMargin">

                                        <InputField
                                            className="modal_input routeModal_Input"
                                            label={isEdit ? "Description" : "Description"}
                                            labelClass="FormLableKaran"
                                            placeholder="Description"
                                            name="description"
                                            type="text"
                                            focus={`${formError[`expense[${index}].description`]}` !== "undefined" ? !!`${formError[`expense[${index}].description`]}` : ""}
                                            error={`${formError[`expense[${index}].description`]}` !== "undefined" ? `${formError[`expense[${index}].description`]}` : ""}
                                            value={onExpo?.description}
                                            onChange={({ target: { name, value } }) =>
                                                updateExpoDetails(index, { key: "description", value: value })
                                            }
                                            disabled={isDisableOption}
                                        />

                                    </div>
                                </div>)}
                                {
                                isDisableOpen === true ? <></>:
                                <div className="addLoctionBtn">
                                    <span onClick={addNewExpo}>+ Add Expenses</span>
                                </div>
                                }
                            </div>}
                        {+activeModalTab === 3 &&
                            <div className="anvMaintab_container_route" >
                                {fuelList?.map((onfuel, index) => <div className="marginExpoBottom">
                                    <div className="expenseTabdiv">
                                        <div className="dropdownMargin">
                                                <Dropdown
                                                    className='dropdownoforder'
                                                    classNamePrefix="_anvFloatSelect"
                                                    boxClass="_anvFloatedSelect"
                                                    label="Fuel Station"
                                                    placeholder="Parking Fees"
                                                    name="fuel_station"
                                                    options={fuelStationList ? fuelStationList : []}
                                                    valueText="id"
                                                    labelText="station_name"
                                                    focus={`${formError[`fuel_expenses[${index}].fuel_station`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].fuel_station`]}` : ""}
                                                    error={`${formError[`fuel_expenses[${index}].fuel_station`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].fuel_station`]}` : ""}
                                                    selectedOption={fuelStationList.find((item) => item?.id === onfuel.fuel_station)}
                                                    setSelectedOption={(data) => {

                                                        setFormOrderName({ ...formOrderName, fuel_station: data })
                                                        updateFuelDetails(index, { key: "fuel_station", value: data?.value })


                                                    }}
                                                    disabled={isDisableOption}
                                                />
                                        </div>
                                        <div className="inputMargin">

                                            <InputField
                                                className="modal_input routeModal_Input"
                                                label={isEdit ? "Ltr" : "Ltr"}
                                                labelClass="FormLableKaran"
                                                placeholder="Qty Ltr"
                                                name="ltr"
                                                type="text"
                                                onKeyDown={handleKeyDown}
                                                focus={`${formError[`fuel_expenses[${index}].ltr`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].ltr`]}` : ""}
                                                error={`${formError[`fuel_expenses[${index}].ltr`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].ltr`]}` : ""}
                                                value={onfuel?.ltr}
                                                onChange={({ target: { name, value } }) =>
                                                    updateFuelDetails(index, { key: "ltr", value: value })
                                                }
                                                disabled={isDisableOption}
                                            />

                                        </div>
                                    </div>
                                    <div className="inputMargin">

                                        <InputField
                                            className="modal_input routeModal_Input"
                                            label={isEdit ? "Description" : "Description"}
                                            labelClass="FormLableKaran"
                                            placeholder="Description"
                                            name="description"
                                            type="text"
                                            focus={`${formError[`fuel_expenses[${index}].description`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].description`]}` : ""}
                                            error={`${formError[`fuel_expenses[${index}].description`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].description`]}` : ""}
                                            value={onfuel?.description}
                                            onChange={({ target: { name, value } }) =>
                                                updateFuelDetails(index, { key: "description", value: value })
                                            }
                                            disabled={isDisableOption}
                                        />

                                    </div>
                                </div>)}
                            {
                                isDisableOpen === true ? <></>:
                                <div className="addLoctionBtn">
                                    <span onClick={addNewFuel} >+ Add Fuel Expenses</span>
                                </div>
                            }

                            </div>}
                        {openModal && < InputModal
                            isOpen={openModal}
                            headerName={isEdit ? "Edit Branch" : formOrderValue.openName === "branch" ? "Add Branch" : formOrderValue.openName === "currency" ? "Add Currency" : "Add Country"}
                            toggle={() => setOpenModal(!openModal)}
                            submitLabel={isEdit ? "Update Branch" : "Continue"}
                            onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
                            // disabled={isEdit ? inputValue.name === disableInp.name : ""}
                            isLoadding={isLoadding}
                            inputProps={

                                <InputField
                                    className="modal_input"
                                    label={isEdit ? "Edit Branch" : formOrderValue.openName === "branch" ? "Branch Name" : formOrderValue.openName === "currency" ? "Currency Name" : "Country Name"}
                                    labelClass="modal_label"
                                    labelSpan="*"
                                    labelSpanClass="style_for_star"
                                    placeholder={formOrderValue.openName === "branch" ? "Branch Name" : formOrderValue.openName === "currency" ? "Currency Name" : "Country Name"}
                                    name={formOrderValue.openName === "branch" ? "addBranch" : formOrderValue.openName === "currency" ? "addCurrency" : "addCountry"}
                                    focus={formOrderValue.openName === "branch" ? !!formError.addBranch : formOrderValue.openName === "currency" ? !!formError.addCurrency : !!formError.addCountry}
                                    error={formOrderValue.openName === "branch" ? formError.addBranch : formOrderValue.openName === "currency" ? formError.addCurrency : formError.addCountry}
                                    value={formOrderValue.openName === "branch" ? formOrderValue.addBranch : formOrderValue.openName === "currency" ? formOrderValue.addCurrency : formOrderValue.addCountry}
                                    onChange={({ target: { name, value } }) =>
                                        handleOrderChange(name, value)
                                    }
                                />
                            }
                        />
                        }
                    </>
                }
            />

        </div>
    )
}

export default CommonRoute
