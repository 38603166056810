import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ConfirmModal(props) {
  const {
    className,
    isOpen,
    toggle,
    onSubmit,
    title = "Confirm Delete",
    message = "Are you sure you want to delete ?"
  } = props;

  return (
    <div>
      <Modal
        size="md"
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        backdrop
        keyboard
        centered={true}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody> {message} </ModalBody>
        <ModalFooter className="footerOfModal">
          
          <Button color="secondary" className="anime-btn ques_row_btn" onClick={onSubmit}>
            Submit
          </Button>

          <Button color="" className="cancelbtn_mdllcls btn btn-" onClick={toggle}>
            Cancel
          </Button>

        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
