import { apiEndPoints, methodType, performRequest } from "./_apiConfig";




export const addCrossForFleet = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.crossBorder, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addCrossForTrailer = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.crossBorder, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


