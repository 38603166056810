import React, { useEffect, useState } from "react";
import { Input, Label, Modal, Table } from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch } from "../../services";
import { toast } from "react-toastify";
import {
  addFleetValidation,
  addFleetValidationFirst,
  addResearchSchema,
  crossBorderValidtion,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  addfleetsTabList,
  approveList,
  checkFormError,
  fleetsTabList,
  formatDate,
  fuelStationTabList,
  fuelTranstionList,
  pageCount,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  singleNewDetails,
  updateActiveResearch,
  updateResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import { getCountry } from "../../services/countryService";
import {
  addFleetApi,
  deleteFleetApi,
  getAllFleet,
  getFleetType,
  singleFleetDetails,
  updateActiveFleet,
  updateFleet,
} from "../../services/fleetTypeService";
import TableForModal from "../../Component/Common/TableForModal";
import { getDocumentType } from "../../services/documentTypeService";
import {
  getAllTrailer,
  getAllTrailerWithType,
  singleTrailerDetails,
} from "../../services/trailerService";
import { addCrossForFleet } from "../../services/crossBorderService";
import mytruck from "../../../image/tanker.png";
import { getAllDriver, updateDriverPaid } from "../../services/driverService";
import { components } from "react-select";
import { CommonDriver } from "../../Component/Common/CommonDriver";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";
import { getAllApprove } from "../../services/approveService";

export const Approval = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };
  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const fleetObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const driverObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  const documentObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    truck_number: "",
    fleet_type: "",
    branch: "",
    tare_weight: "",
    vin_number: "",
    trailers: [],
    livret: "",
    verbete_expire_date: formatDate(new Date(), "-"),
    insurance_expire_date: formatDate(new Date(), "-"),
    cross_border_payment_expire_date: formatDate(new Date(), "-"),
    inspection_expire_date: formatDate(new Date(), "-"),
    caderneta_expire_date: formatDate(new Date(), "-"),
    manifesto_expire_date: formatDate(new Date(), "-"),
    taxa_de_radio_expire_date: formatDate(new Date(), "-"),
    fire_extinguisher_expiry_date: formatDate(new Date(), "-"),
    registration_status: "",
    import_document_number: "",
    country: "",
    document_type: "",
    cross_border_permit_date: formatDate(new Date(), "-"),
    trailer_number: "",
    driver: "",
    addBranch: "",
  };
  const orderObjName = {
    truck_number: "",
    fleet_type: "",
    branch: "",
    tare_weight: "",
    vin_number: "",
    trailers: [],
    livret: "",
    verbete_expire_date: formatDate(new Date(), "-"),
    insurance_expire_date: formatDate(new Date(), "-"),
    cross_border_payment_expire_date: formatDate(new Date(), "-"),
    inspection_expire_date: formatDate(new Date(), "-"),
    caderneta_expire_date: formatDate(new Date(), "-"),
    manifesto_expire_date: formatDate(new Date(), "-"),
    taxa_de_radio_expire_date: formatDate(new Date(), "-"),
    fire_extinguisher_expiry_date: formatDate(new Date(), "-"),
    registration_status: "",
    import_document_number: "",
    country: "",
    document_type: "",
    cross_border_permit_date: formatDate(new Date(), "-"),
    trailer_number: "",
    driver: "",
    addBranch: "",
  };

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState(1);
  const [activeModalTab, setActiveModalTab] = useState(1);
  const [tabName, setTabName] = useState("");
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    approval_status: 1,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const [disableInp, setDisableInp] = useState({
    name: "",
  });
  const [isLoadding, setIsLoadding] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
  });

  const [walletId, setWalletId] = useState();
  const [openDel, setOpenDel] = useState(false);
  const [loaderForList, setLoaderForList] = useState(false);
  const [branchList, setBranchList] = useState();

  const { data: dataList, isSuccess } = useQuery(
    ["getAllApprove", debouncedFilter],
    () => getAllApprove(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {

      setLoaderForList(false);
    } else {

      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getBranchList();
  }, []);

  const getBranchList = async () => {
    const res = await getBranch(branchObj);
    setBranchList(res?.results);
  };
  const column = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Trn Number",
      accessor: "tipno",
    },
    {
      Header: "Request by",
      accessor: "requestedBy",
    },
    {
      Header: "Category",
      accessor: "category_name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Amount",
      accessor: "ammount",
    },
    {
      Header: "Trip Relation",
      accessor: "",
    },
    {
      Header: "Fleet Relation",
      accessor: "",
    },

    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const approve = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Trn Number",
      accessor: "tipno",
    },
    {
      Header: "Request by",
      accessor: "requestedBy",
    },
    {
      Header: "Category",
      accessor: "category_name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Amount",
      accessor: "ammount",
    },
    {
      Header: "Trip Relation",
      accessor: "",
    },
    {
      Header: "Fleet Relation",
      accessor: "",
    },

    {
      Header: "Approved By",
      accessor: "approveBY",
    },
    {
      Header: "Approved Time",
      accessor: "approved_time",
    },
  ];
  const cancel = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Trn Number",
      accessor: "tipno",
    },
    {
      Header: "Request by",
      accessor: "requestedBy",
    },
    {
      Header: "Category",
      accessor: "category_name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Amount",
      accessor: "ammount",
    },
    {
      Header: "Trip Relation",
      accessor: "",
    },
    {
      Header: "Fleet Relation",
      accessor: "",
    },

    {
      Header: "Rejected by",
      accessor: "rejectedBy",
    },
    {
      Header: "Rejected Time",
      accessor: "rejected_time",
    },
  ];

  const aprroveDriverStatus = (data) => {
    handleUpdate1(data);
  };

  const handleUpdate1 = async (data) => {
    const payload = {
      formdata: {
        approval_status: 2,
      },
      id: data,
    };

    updateMutation.mutate(payload);
  };

  const handleUpdate2 = async (data) => {
    const payload = {
      formdata: {
        approval_status: 3,
        cancel_reason: inputValue?.name,
      },
      id: data,
    };

    updateMutation.mutate(payload);
  };

  const updateMutation = useMutation(updateDriverPaid, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        setIsLoadding(false);
        setOpenDel(!openDel);
        if (tabName === "approve") {
          setFilter({ ...filter, status: 2, approval_status: 2 });
        }
        if (tabName === "Reject") {
          setFilter({ ...filter, status: 3, approval_status: 3 });
          setInputValue({ ...inputValue, name: "" });
        }
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      serial: i + 1,
      status: (
        <div className="statusofOrder">
          <span>Pending</span>
        </div>
      ),
      tipno: item?.receipt_number ? item?.receipt_number : item?.payment_number,
      requestedBy: item?.names?.requested_by_name,
      approveBY: item?.names?.approved_by_name,
      rejectedBy: item?.names?.rejected_by_name,

      action: (
        <>
          {filter?.status === 1 && (
            <div className="actionBtn">
              <button
                className="anime-btn accept_odr"
                onClick={() => {
                  setOpenDel(true);
                  setWalletId(item?.id);
                  setTabName("approve");
                }}
              >
                Approve
              </button>
              <button
                className="cancel_ord"
                onClick={() => {
                  setTabName("Reject");
                  setIsOpen(true);
                  setWalletId(item?.id);
                }}
              >
                Reject
              </button>
            </div>
          )}
        </>
      ),
      ammount: item?.receipt_number
        ? `+ ${item?.amount} ${item?.currency_name}`
        : `- ${item?.amount} ${item?.currency_name}`,
      action1: (
        <>
          <div className="actionBtn"></div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllApprove");
    setIsOpen(false);
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };
  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
      approval_status: data.value,
    });
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { name: inputValue.name },
      modalInputSchema
    );
    if (validationResult === true) {
      setIsLoadding(true);
      handleUpdate2(walletId);
    } else {
      setInputError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return column;
      case 2:
        return approve;
      case 3:
        return cancel;
      default:
        return column;
    }
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={approveList}
          handleActive={handleActive}
          activeTab={+filter?.status}
        />
      </div>
      <div className="anvTask_prog ">
        <div className="anvInputs&fillters">
          <div className="inr_flex">
            <div className="inr_flex_mini ">
              <Dropdown
                className="dropdownoforder"
                placeholder="Select category"
                label="Select category"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
                name="Branch"
                options={branchList ? branchList : []}
                valueText="id"
                labelText="name"
                // focus={!!formError?.country}
                // error={formError?.country}
                // selectedOption={formOrderName?.country}
                // components={{ Menu: CustomControl }}
                setSelectedOption={(data) => {
                  // setFormOrderName({ ...formOrderName, country: data })
                  // handleOrderChange("country", data?.value);
                }}
              />
            </div>
          </div>
          {/* <div className="inr_flex">
          <div className="inr_flex_mini ">
            <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Branch"
                    name="Branch"
                    options={branchList ? branchList : []}
                    valueText="id"
                    labelText="name"
                    // focus={!!formError?.country}
                    // error={formError?.country}
                    // selectedOption={formOrderName?.country}
                    // components={{ Menu: CustomControl }}
                    setSelectedOption={(data) => {
                      // setFormOrderName({ ...formOrderName, country: data })
                      // handleOrderChange("country", data?.value);
                    }}
                  />
            </div>
          </div> */}

          <div className="inr_flex">
            {/* <Input placeholder="Search" className="anvSearch_input"
              value={filter?.search}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  search: e.target.value,
                }))
              }
            /> */}
            <InputField
              placeholder="Search Order"
              className="anvSearch_input"
              label="Search Order"
              //   value={filter?.order_number}
              //   onChange={(e) =>
              //     setFilter((s) => ({
              //       ...s,
              //       order_number: e.target.value,
              //     }))
              //   }

              value={filter?.search}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  search: e.target.value,
                }))
              }
            />
          
          </div>

        </div>
      </div>

      <div>
        <div className="table-responsive">
          <div className="tabFix">
            <CustomReactTable
              columns={getTableList()}
              data={rowDataList(
                dataList?.results?.length > 0 ? dataList?.results : []
              )}
              tableLoading={loaderForList}
            />
          </div>
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))
                setInputValue({ ...inputValue, count: data });
              }}

            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(dataList?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen}
        headerName={isEdit ? "Edit Branch" : "Cancel Transaction"}
        toggle={() => {
          setInputValue({ ...inputValue, name: "" });
          setIsOpen(!isOpen);
        }}
        submitLabel={isEdit ? "Update Reject" : "Reject"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={isEdit ? "Edit Reject" : "Cancellation Reason"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Enter Reason for cancellation"
            name="name"
            focus={!!inputError.name}
            error={inputError.name}
            value={inputValue.name}
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
          />
        }
      />
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => aprroveDriverStatus(walletId)}
        title="Approve Confirm"
        message="Are you sure you want to Approve ?"
      />
    </>
  );
};
