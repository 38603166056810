import React from "react";
import { Spinner } from "reactstrap";

export const Loader = (props) => {
  const { boxClass ,loaderClass} = props;
  return (
    <div className={boxClass}>
      <Spinner color="secondary" size="" className={loaderClass}>
        Loading...
      </Spinner>
    </div>
  );
};
