import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";

import { modalInputSchema, productModalSchema } from "../../helper/errorSchema";
import { breadcrumbsMenu, checkFormError, pageCount } from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/editIcon.svg";
import DeleteIcon from "../../../image/deleteIcon.svg";
import CheckboxField from "../../Component/Common/CheckboxField";
import { addProductApi, deleteProductApi, getProduct, updateActiveProduct, updateProductApi } from "../../services/productService";
import mytruck from "../../../image/tanker.png"
import { getUnit } from "../../services/unitService";
import { getStorage } from "../../helper/storage";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

const dummyDrop = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
  { label: "three", value: 3 },
];

const productList = [
  { label: "Liquid", value: 1 },
  { label: "Dry", value: 2 },
]

export const Product = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id"

  };

  const userLogin = getStorage("logged-in")
  const permissions = Object.values(userLogin?.user_permissions)?.length ? Object.values(userLogin?.user_permissions)?.filter((i) => i?.module_name?.includes("Product"))[0]?.
    permissions : []
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("")
  const [productType, setProductType] = useState(null)
  const [unitType, setUnitType] = useState(null)
  const [loaderForList, setLoaderForList] = useState(false)
  const [unitList, setUnitList] = useState([])
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    limit: 20,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);

  const { data, isSuccess } = useQuery(
    ["getProduct", debouncedFilter],
    () => getProduct(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  useEffect(() => {
    if (data) {
      setLoaderForList(false)
    } else {
      setLoaderForList(true)
    }
  }, [data])

  const [inputValue, setInputValue] = useState({
    name: "",
    product_type: "",
    unit: "",
    count: ""

  });
  const [inputError, setInputError] = useState({
    name: "",
    product_type: "",
    unit: ""
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    product_type: "",
    unit: ""
  });

  useEffect(() => {
    getUnitList()
  }, [])

  const getUnitList = async () => {
    const res = await getUnit()

    setUnitList(res?.results)

  }

  const closeModal = () => {
    setIsOpen(true);
    setIsEdit(false);
    setInputValue("");
    setInputError("");
    setProductType(null)
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { name: inputValue.name, product_type: inputValue?.product_type, unit: inputValue?.unit },
      productModalSchema
    );
    if (validationResult === true) {
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd();
      }
    } else {
      setInputError(validationResult);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value }
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, productModalSchema
      );
      setInputError(error);
    }
  };

  const handleAdd = () => {
    const payload = {
      name: inputValue.name,
      product_type: inputValue?.product_type,
      unit: inputValue.unit
    };
    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };
  const handleEditAction = (item) => {

    setDisableInp({ id: item.id, name: item.name, product_type: item?.product_type, unit: item?.unit });
    setInputValue({ id: item.id, name: item.name, product_type: item?.product_type, unit: item?.unit });
    setProductType({
      label: item.product_type === 1 ? "Liquid" : "Dry",
      value: item?.product_type,
    })
    setUnitType({
      label: item.unit_details?.name,
      value: item?.unit_details?.id,
    })
    setIsOpen(true);
    setIsEdit(true);
    setInputError("");
  };

  const handleUpdate = () => {
    const payload = {
      id: inputValue.id,
      name: inputValue.name,
      product_type: inputValue?.product_type,
      unit: inputValue?.unit
    };
    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addProductApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const updateMutation = useMutation(updateProductApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteProductApi, {
    onSuccess: (res) => {

      if (res?.success) {
        onSuccessResponse(res);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data)
  }

  const onSuccessResponse = (message) => {
    toast.success(message?.message ? message?.message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getProduct");
    setIsOpen(false);
  };

  const onErrorResponse = (error) => {
    toast.error(getResposeError(error ? error : "server error", { position: "bottom-left" }));
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const updateTagStatus = (item) => {

    const payload = {
      id: item.id,
      is_active: !item?.is_active
    }
    updateActiveMutation.mutate(payload);
  }
  const updateActiveMutation = useMutation(updateActiveProduct, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });



  const column = [
    { heading: "S.No", className: "sorting", value: "sno", sortName: "" },

    { heading: "Product Name", className: "sorting_asc", value: "name", sortName: "" },
    { heading: "Product Type", className: "sorting_asc", value: "product_type", sortName: "" },
    { heading: "Action", className: "sorting", value: "action", sortName: "" },
  ];

  const rowDataList = () => {


    const listArray = data?.results?.map((item, i) => ({
      ...item,
      check: (
        <>
          <CheckboxField type="checkbox" name={item?.title} />
        </>
      ),
      product_type: (<div>
        {item?.product_type === 1 ? "Liquid" : "Dry"}
      </div>),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
      action: (
        <>
          <div className="actionBtn">
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)}
              value={item.is_active} />
              {userLogin?.user_type === 1 ? <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span> : permissions[3] && <span className="_faEdit" onClick={() => handleEditAction(item)}><FiEdit /></span>}
              {userLogin?.user_type === 1 ? <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span> : permissions[4] && <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>}
            {/* <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
            <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> */}
            {/* {userLogin?.user_type === 1 ? <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} /> : permissions[3] && <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />}
            {userLogin?.user_type === 1 ? <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} /> : permissions[4] && <img src={DeleteIcon} alt="" onClick={() => hendleDeleteTag(item, true)} />} */}
          </div>
        </>
      ),
    }));
    return listArray;
  };



  return (
    <>
      <div className="ques_container">
        <Breadcrumbs
          active="Product"
          action={
            userLogin?.user_type === 1 ? <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Product
            </Button> : permissions[2] && <Button
              type="button"
              className="btn anime-btn ques_row_btn"
              onClick={() => closeModal()}
            >
              Add Product
            </Button>
          }
        />
        <div className="anvTask_prog">
          <div className="anvInputs&fillters">


            <div className="inr_flex ">
              <Dropdown
                placeholder="Enable"
                option={dummyDrop}
                valueText="value"
                labelText="label"
                label="Enable"
                classNamePrefix="_anvFloatSelect"
                boxClass="_anvFloatedSelect"
              />
            </div>

            <div className="inr_flex">
              <InputField placeholder="Search" label="Search"
                className="anvSearch_input"
                value={filter?.name}
                onChange={(e) =>
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    name: e.target.value,
                  }))
                }
              />
            </div>
            {filter?.name && <div className="inr_flex_mini">
              <button className="anime-btn reset_button" onClick={() => {
                setFilter({
                  ...filter,

                  name: "",



                })

              }
              } >Reset</button>
            </div>}
          </div>
        </div>
        <div>
          <div className="table-responsive">
            <CustomTable
              column={column}
              data={rowDataList()}
              tableLoading={loaderForList}
            />
          </div>
        </div>
      </div>

      {data?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
          <div className="">
            <Dropdown
              placeholder="Select Count"
              className="dropdownoforder orderModal_Input"
              name="driver"
              options={pageCount ? pageCount : []}
              valueText="value"
              labelText="label"
              selectedOption={inputValue?.count ? inputValue?.count : { label: "20", value: 20 }}
              setSelectedOption={(data) => {
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  limit: data.value,
                }))
                setInputValue({ ...inputValue, count: data });
              }}

            />
          </div>

          <CustomPagination
            onPageChange={handlePageClick}
            currentPage={filter?.currentPage}
            pageCount={Math.ceil(data?.count / filter?.limit)}
          />
        </div>
      )}

      <InputModal
        isOpen={isOpen}
        headerName={isEdit ? "Edit Product" : "Add Product"}
        toggle={() => setIsOpen(!isOpen)}
        submitLabel={isEdit ? "Update Country" : "Continue"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? (JSON.stringify(inputValue) === JSON.stringify(disableInp)) : ""}
        inputProps={
          <>
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Product" : "Product Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Product Name"
              name="name"
              focus={!!inputError.name}
              error={inputError.name}
              value={inputValue.name}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <div className="" >
              {/* <span>
                <Label>
                  Product Type<span className="style_for_star">*</span>
                </Label>
              </span> */}
                <Dropdown
                  placeholder="Liquid/Dry"
                  label="Liquid/Dry"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  name="product_type"
                  options={productList}
                  focus={!!inputError.product_type}
                  error={inputError.product_type}
                  selectedOption={productType}
                  setSelectedOption={(data) => {
                    handleOnChange("product_type", data?.value);
                    setProductType(data);
                  }}
                  valueText="value"
                  labelText="label"
                />
            </div>
            <div>
              {/* <span>
                <Label>
                  Unit Type<span className="style_for_star">*</span>
                </Label>
              </span> */}
              
                <Dropdown
                  className='dropdownoforder'
                  placeholder="LTR/KG"
                  label="LTR/KG"
                  classNamePrefix="_anvFloatSelect"
                  boxClass="_anvFloatedSelect"
                  name="Unit"
                  options={unitList ? unitList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!inputError.unit}
                  error={inputError.unit}
                  selectedOption={unitType}
                  setSelectedOption={(data) => {
                    handleOnChange("unit", data?.value);
                    setUnitType(data);
                  }}
                />

            </div>

          </>
        }
      />
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
    </>
  );
};
