import {
  Login,
  Dashboard,
  Orders,
  Branch,
  DocumentType,
  Country,
  FleetType,
  Product,
  Customer,
  Fleets,
  Trailer,
  Location,
  Driver,
  Nomination,
  Currency,
  Unit,
  Trips,
  FuelStation,
  ExpenseCategory,
  Route,
  DriverDetails,
  ExitBorder,
  TripDetails,
  User,
  Role,
  TripReport,
  FleetGroup,
  FuelTransaction,
  Approval,
  FuelStationApi,
  Company,
  FinancialApproval,
  TripControl,
  BranchDetails


} from "../Pages";
import { ReportDownload } from "../Pages/Trips/ReportDownload";

export const routerList = [
  {
    path: "/login",
    element: Login,
    private: 0,
  },

  {
    path: "/dashboard",
    element: Dashboard,
    key: "dashboard",
    private: 1,
  },
  {
    path: "/Orders",
    element: Orders,
    key: "Orders",
    private: 1,
  },
  {
    path: "/branch",
    element: Branch,
    key: "branch",
    private: 1,
  },
  {
    path: "/company",
    element: Company,
    key: "company",
    private: 1,
  },
  {

    path: "/document-type",
    element: DocumentType,
    key: "documentType",
    private: 1,
  },
  {
    path: "/country",
    element: Country,
    key: "country",
    private: 1,
  },
  {
    path: "/product",
    element: Product,
    key: "product",
    private: 1,
  },
  {
    path: "/fleettype",
    element: FleetType,
    key: "fleettype",
    private: 1,
  },
  {
    path: "/customer",
    element: Customer,
    key: "customer",
    private: 1,
  },
  {
    path: "/fleets",
    element: Fleets,
    key: "fleets",
    private: 1,
  },
  {
    path: "/trailer",
    element: Trailer,
    key: "trailer",
    private: 1,
  },
  {
    path: "/location",
    element: Location,
    key: "location",
    private: 1,
  },
  {
    path: "/drivers",
    element: Driver,
    key: "drivers",
    private: 1,
  },
  {
    path: "/drivers-details",
    element: DriverDetails,
    key: "driversDetails",
    private: 1,
  },
  {
    path: "/trip-details",
    element: TripDetails,
    key: "tripDetails",
    private: 1,
  },
  {
    path: "/nomination",
    element: Nomination,
    key: "nomination",
    private: 1,
  },
  {
    path: "/currency",
    element: Currency,
    key: "currency",
    private: 1,
  },
  {
    path: "/unit",
    element: Unit,
    key: "unit",
    private: 1,
  },
  {
    path: "/exitBorder",
    element: ExitBorder,
    key: "exitBorder",
    private: 1,
  },
  {
    path: "/trips?/:statusTag",
    element: Trips,
    key: "trips",
    private: 1,
  },
  {
    path: "/fuelStation",
    element: FuelStation,
    key: "fuelStation",
    private: 1,
  },
  {
    path: "/expense-category",
    element: ExpenseCategory,
    key: "expense-category",
    private: 1,
  },

  {
    path: "/route",
    element: Route,
    key: "route",
    private: 1,
  },
  {
    path: "/user",
    element: User,
    key: "user",
    private: 1,
  },
  {
    path: "/role",
    element: Role,
    key: "role",
    private: 1,
  },
  {
    path: "/trip_report",
    element: TripReport,
    key: "TripReport",
    private: 1,
  },
  {
    path: "/report_download",
    element: ReportDownload,
    key: "ReportDownload",
    private: 1
  },
  {
    path: "/fleet_group",
    element: FleetGroup,
    key: "Fleet Group",
    private: 1,
  },
  {
    path: "/fuel_transaction",
    element: FuelTransaction,
    key: "fuel_transaction",
    private: 1,
  },{
    path: "/approval",
    element: Approval,
    key: "approval",
    private: 1,
  },
  {
    path: "/fuelStationApi",
    element: FuelStationApi,
    key: "fuelStationApi",
    private: 1,
  },
  {
    path: "/financial-approval",
    element: FinancialApproval,
    key: "financialApproval",
    private: 1,
  },
  {
    path: "/trips-control",
    element: TripControl,
    key: "trip-control",
    private: 1,
  },
  {
    path: "/branch-details",
    element: BranchDetails,
    key: "branch-details",
    private: 1,
  },
];

export { ProtectRoutes } from "./ProtectRoutes";
export { PublicRoute } from "./PublicRoute";
