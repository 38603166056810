import React from 'react'
import { Button, Dropdown, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import { InputField } from './InputField'
const personaList = [
    {
        value: "developer",
        label: "developer",
    },
    {
        value: "designer",
        label: "designer",
    },
    {
        value: "management",
        label: "management",
    },
];
const CustomOffcanvas = (props) => {

    const { isOpen, toggle, onSubmit, inputProps, title, submitLabel, isLoadding ,customeClass} = props
    return (
        <div>

            <Offcanvas direction="end" isOpen={isOpen} className={`customOffCanvs ${customeClass}`}>
                <OffcanvasHeader toggle={toggle}>
                    {title}
                </OffcanvasHeader>
                <OffcanvasBody className={title === "Add Customer" || "Add Fleet" ? "" : 'BodyOfCnves'}>
                    {inputProps}
                </OffcanvasBody>
                <div className="offcanvesFooter">
                    {isLoadding === true ? <Button
                        className="anime-btn anvOt_btn"
                        type="button"

                        disabled='true'
                    >
                        processing
                    </Button> :
                        <Button
                            className="anime-btn anvOt_btn"
                            type="button"
                            onClick={onSubmit}
                        >
                            {submitLabel}
                        </Button>}
                    <Button className="anime-btn cancelbtn_mdllcls" onClick={toggle}>Cancel</Button>
                </div>
            </Offcanvas>
        </div>

    )
}

export default CustomOffcanvas