import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getBranch = (data = {}) => {
  return performRequest(methodType.GET, apiEndPoints.branch, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDocumentTypeRelatedToBranch = (data = {}, id) => {
  return performRequest(
    methodType.GET,
    apiEndPoints.documentTypeRelatedToBranch + `${id}`,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addBranchApi = (data = {}) => {
  return performRequest(methodType.POST, apiEndPoints.branch, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateBranchApi = (data = {}) => {
  return performRequest(
    methodType.PATCH,
    apiEndPoints.branch + `/${data?.id}`,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteBranchApi = (data = {}) => {
  return performRequest(methodType.DELETE, apiEndPoints.branch + `/${data?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateActiveBranch = (data = {}) => {
  const payload = {
    is_active: data?.is_active,
  };
  return performRequest(
    methodType.PATCH,
    apiEndPoints.branch + `/${data?.id}`,
    payload,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getHierarchyDetailsList = (data = {}) => {
  return performRequest(
    methodType.GET,
    apiEndPoints.branchAlertGroup,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addHierarchyDetails = (data = {}) => {
  return performRequest(methodType.POST, apiEndPoints.branchAlertGroup, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateHierarchyDetails = (data = {}) => {
  return performRequest(
    methodType.PUT,
    apiEndPoints.branchAlertGroup + `/${data?.id}`,
    data
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteHierarchyLevel = (data = {}) => {
    return performRequest(methodType.DELETE, apiEndPoints.branchAlertLevel + `/${data?.uuid}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
