import React, { useEffect, useState } from 'react'
import Dropdown from '../../Component/Common/Dropdown';
import { InputField } from '../../Component/Common/InputField';
import { getAllFuelStation } from '../../services/fuelStationService';
import DeleteIcon from "../../../image/deleteIcon1.png";
import { handleKeyDown } from '../../helper';
import { MdOutlineDelete } from 'react-icons/md';

export const MoreFuel = ({ updateFuelDetails, setFormOrderName, formOrderName, index, item, formError, handleOrderChange, handleDeleteFuel, fuelList }) => {
    const countryObj = {
        offset: 0,
        currentPage: 0,
        search: "",
        ordering: "-id",
        limit: "all",
        is_active: true
    };
    const [fuelStationList, setFuelStationList] = useState([])
    useEffect(() => {

        getFuelList()
    }, [])


    const getFuelList = async () => {
        const res = await getAllFuelStation(countryObj)
        setFuelStationList(res?.results)

    }


    return (
        <tr>

            <td className="fundinput">
                <Dropdown
                    className='dropdownoforder'
                    placeholder="Fuel Station 1"
                    classNamePrefix="_anvFloatSelect"
                    boxClass="_anvFloatedSelect"
                    name="fuel_station"
                    options={fuelStationList ? fuelStationList : []}
                    valueText="id"
                    labelText="station_name"
                    focus={`${formError[`fuel_expenses[${index}].fuel_station`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].fuel_station`]}` : ""}
                    error={`${formError[`fuel_expenses[${index}].fuel_station`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].fuel_station`]}` : ""}
                    selectedOption={{ label: fuelStationList.find((ex) => ex?.id === item?.fuel_station)?.station_name, value: fuelStationList.find((ex) => ex?.id === item?.fuel_station)?.id }}
                    setSelectedOption={(data) => {
                        setFormOrderName({ ...formOrderName, fuel_station: data })
                        updateFuelDetails(index, { key: "fuel_station", value: data?.value })
                    }}
                />
            </td>

            <td>
                <div>
                    <InputField
                        labelClass="modal_label"
                        labelSpanClass="style_for_star"
                        placeholder="Description"
                        name="description"
                        type="text"
                        focus={`${formError[`fuel_expenses[${index}].description`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].description`]}` : ""}
                        error={`${formError[`fuel_expenses[${index}].description`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].description`]}` : ""}
                        value={item?.description}
                        onChange={({ target: { name, value } }) =>
                            updateFuelDetails(index, { key: "description", value: value })
                        }
                    />
                </div>
            </td>

            <td className="fundinput2">
                <div>
                    <InputField
                        labelClass="modal_label"
                        labelSpanClass="style_for_star"
                        placeholder="Qty (Ltr)"
                        name="qty"
                        type="text"
                        onKeyDown={handleKeyDown}
                        focus={`${formError[`fuel_expenses[${index}].qty`]}` !== "undefined" ? !!`${formError[`fuel_expenses[${index}].qty`]}` : ""}
                        error={`${formError[`fuel_expenses[${index}].qty`]}` !== "undefined" ? `${formError[`fuel_expenses[${index}].qty`]}` : ""}
                        value={item?.qty ? `${item?.qty}` : ""}
                        onChange={({ target: { name, value } }) =>
                            updateFuelDetails(index, { key: "qty", value: value })
                        }
                    />
                </div>
            </td>

            <td className='deleteIconeWidth'>
                {fuelList.length !== 1 && (
                    <div><span className="_faDelete" onClick={(e) => handleDeleteFuel(index)} ><MdOutlineDelete /></span></div>)}
                    {/* // <img src={DeleteIcon} alt="" onClick={(e) => handleDeleteFuel(index)} />)} */}
            </td>
        </tr>
    )
}
