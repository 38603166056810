import React, { useEffect, useState } from "react";
import { Label, Modal, Table } from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch } from "../../services";
import { toast } from "react-toastify";
import {
  addFleetValidation,
  addFleetValidationFirst,
  addResearchSchema,
  crossBorderValidtion,
  modalInputSchema,
} from "../../helper/errorSchema";
import {
  addfleetsTabList,
  checkFormError,
  fleetsTabList,
  formatDate,
  fuelStationTabList,
  fuelTranstionList,
  pageCount,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  singleNewDetails,
  updateActiveResearch,
  updateResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import { getCountry } from "../../services/countryService";
import {
  addFleetApi,
  deleteFleetApi,
  getAllFleet,
  getFleetType,
  singleFleetDetails,
  updateActiveFleet,
  updateFleet,
} from "../../services/fleetTypeService";
import TableForModal from "../../Component/Common/TableForModal";
import { getDocumentType } from "../../services/documentTypeService";
import {
  getAllTrailer,
  getAllTrailerWithType,
  singleTrailerDetails,
} from "../../services/trailerService";
import { addCrossForFleet } from "../../services/crossBorderService";
import mytruck from "../../../image/tanker.png";
import {
  approvefuelExpense,
  getAllDriver,
  getAllFuelExpense,
  getKranExpense,
  updatefuelTripExpense,
} from "../../services/driverService";
import { components } from "react-select";
import { CommonDriver } from "../../Component/Common/CommonDriver";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";
import { MdOutlineDelete } from "react-icons/md";

export const FuelTransaction = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };
  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const fleetObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const driverObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const countryObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
    is_active: true,
  };

  const documentObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    truck_number: "",
    fleet_type: "",
    branch: "",
    tare_weight: "",
    vin_number: "",
    trailers: [],
    livret: "",
    verbete_expire_date: formatDate(new Date(), "-"),
    insurance_expire_date: formatDate(new Date(), "-"),
    cross_border_payment_expire_date: formatDate(new Date(), "-"),
    inspection_expire_date: formatDate(new Date(), "-"),
    caderneta_expire_date: formatDate(new Date(), "-"),
    manifesto_expire_date: formatDate(new Date(), "-"),
    taxa_de_radio_expire_date: formatDate(new Date(), "-"),
    fire_extinguisher_expiry_date: formatDate(new Date(), "-"),
    registration_status: "",
    import_document_number: "",
    country: "",
    document_type: "",
    cross_border_permit_date: formatDate(new Date(), "-"),
    trailer_number: "",
    driver: "",
    addBranch: "",
  };
  const orderObjName = {
    truck_number: "",
    fleet_type: "",
    branch: "",
    tare_weight: "",
    vin_number: "",
    trailers: [],
    livret: "",
    verbete_expire_date: formatDate(new Date(), "-"),
    insurance_expire_date: formatDate(new Date(), "-"),
    cross_border_payment_expire_date: formatDate(new Date(), "-"),
    inspection_expire_date: formatDate(new Date(), "-"),
    caderneta_expire_date: formatDate(new Date(), "-"),
    manifesto_expire_date: formatDate(new Date(), "-"),
    taxa_de_radio_expire_date: formatDate(new Date(), "-"),
    fire_extinguisher_expiry_date: formatDate(new Date(), "-"),
    registration_status: "",
    import_document_number: "",
    country: "",
    document_type: "",
    cross_border_permit_date: formatDate(new Date(), "-"),
    trailer_number: "",
    driver: "",
    addBranch: "",
  };

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [imageType, setimageType] = useState("");
  const [status, setStatus] = useState(1);
  const [activeModalTab, setActiveModalTab] = useState(1);

  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    fuel_order_status: 1,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const [disableInp, setDisableInp] = useState({
    name: "",
  });
  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [branchList, setBranchList] = useState();
  const [fleetList, setFleetList] = useState();
  const [countryList, setCountryList] = useState();
  const [documentList, setDocumentList] = useState();
  const [driverList, setDriverList] = useState();
  const [truckNoList, setTruckNoList] = useState();
  const [trailerList, setTrailerList] = useState([]);
  const [fleetId, setFleetId] = useState("");
  const [crossBorderList, setCrossBorderList] = useState([]);
  const [crossBordrCountry, setCrossBordrCountry] = useState([]);
  const [inputValue, setInputValue] = useState({
    name: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
  });
  const [loaderForList, setLoaderForList] = useState(false);
  const [disbleButton, setDisbleButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [responseKaran, setResponseKaran] = useState();
  const [labelStatus, setLabel] = useState("");

  useEffect(() => {
    if (formOrderName?.fleet_type) {
      if (
        formOrderName.fleet_type?.data?.no_of_trailer === trailerList?.length
      ) {
      }
    }
  }, [formOrderName?.fleet_type]);

  useEffect(() => {
    getBranchList();
    getFleetsList();
    getCountryList();
    getDocumentList();
    getDriverList();
  }, []);

  useEffect(() => {
    if (formOrderValue.fleet_type) {
      getTruckNoList();
    }
  }, [formOrderValue.fleet_type]);

  useEffect(() => {
    if (labelStatus === "approve") {
      if (responseKaran?.status === 400) {
        const payload = { fuel_order_status: 2, id: updateId };
        handleUpdateFuel(payload);
      } else {
        const payload = {
          fuel_order_status: 3,
          order_otp: responseKaran?.results?.otp,
          order_number: responseKaran?.results?.order_no,
          id: updateId,
        };
        handleUpdateFuel(payload);
      }
    }
    if (labelStatus === "resend") {
      if (responseKaran?.status === 200) {
        const payload = {
          fuel_order_status: 3,
          order_otp: responseKaran?.results?.otp,
          order_number: responseKaran?.results?.order_no,
          id: updateId,
        };
        handleUpdateFuel(payload);
      } else {
        setUpdateId("");
      }
    }
    if (labelStatus === "check") {
      if (responseKaran?.message === "Data Available") {
        const payload = { fuel_order_status: 4, id: updateId };
        handleUpdateFuel(payload);
      } else {
        setUpdateId("");
      }
    }
  }, [responseKaran]);

  const column = [
    {
      Header: "Date",
      accessor: "order_date",
    },
    // {
    //   Header: "Order Number",
    //   accessor: "order_number",
    // },
    {
      Header: "Trip Number",
      accessor: "nomination_number",
    },
    {
      Header: "Fuel Station",
      accessor: "fuel_station_name",
    },
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "LTR",
      accessor: "qty",
    },
    {
      Header: "Given",
      accessor: "actual",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Amount",
      accessor: "total_amount",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const AllFleet = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },

    { heading: "Branch", className: "sorting", value: "branch", sortName: "" },
    {
      heading: "Truck Type",
      className: "sorting_asc",
      value: "truckType",
      sortName: "",
    },
    {
      heading: "Truck Number",
      className: "sorting_asc",
      value: "truck_number",
      sortName: "",
    },
    {
      heading: "Driver",
      className: "sorting_asc",
      value: "driver_name",
      sortName: "",
    },
    {
      heading: "No. of Trailer",
      className: "sorting_asc",
      value: "no_of_trailer",
      sortName: "",
    },
    // { heading: "Status", className: "sorting_asc", value: "last_login" },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const addFleet = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },

    {
      heading: "Trailer Number",
      className: "sorting",
      value: "trailerNumber",
      sortName: "",
    },
    {
      heading: "Capacity",
      className: "sorting_asc",
      value: "weight",
      sortName: "",
    },
    { heading: "", className: "sorting_asc", value: "action1" },
  ];

  const Pending = [
    { heading: "S.No", className: "sorting", value: "serial", sortName: "" },

    { heading: "Date", className: "sorting", value: "Date", sortName: "" },
    {
      heading: "Customer Name",
      className: "sorting_asc",
      value: "Customer_Name",
      sortName: "",
    },
    {
      heading: "Route",
      className: "sorting_asc",
      value: "Route",
      sortName: "",
    },
    {
      heading: "Status",
      className: "sorting_asc",
      value: "status",
      sortName: "",
    },
    {
      heading: "Product",
      className: "sorting_asc",
      value: "Product",
      sortName: "",
    },
    {
      heading: "Requirement",
      className: "sorting_asc",
      value: "Requirement",
      sortName: "",
    },
    {
      heading: "Placed",
      className: "sorting_asc",
      value: "Placed",
      sortName: "",
    },
    {
      heading: "Balance",
      className: "sorting_asc",
      value: "Balance",
      sortName: "",
    },
    {
      heading: "Action",
      className: "sorting_asc",
      value: "action",
      sortName: "",
    },
  ];

  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      serial: i + 1,
      status: (
        <div className="">
          {item.fuel_order_status === 1 && <span>Requested</span>}
          {item.fuel_order_status === 2 && <span>Low Balance</span>}
          {item.fuel_order_status === 3 && (
            <div className="otp_div">
              <span>Success</span>
              <small>
                {item?.order_number ? `O.N.-${item?.order_number}` : ""}
              </small>
              <small>{item?.order_otp ? `OTP-${item?.order_otp}` : ""}</small>
            </div>
          )}
          {item.fuel_order_status === 4 && <span>Completed</span>}
        </div>
      ),
      branch: (
        <>
          <span>
            {
              branchList?.filter((filter) => filter?.id === item?.branch)[0]
                ?.name
            }
          </span>
        </>
      ),
      truckType: (
        <>
          <span>
            {
              fleetList?.filter((filter) => filter?.id === item?.fleet_type)[0]
                ?.name
            }
          </span>
        </>
      ),
      fleet: (
        <>
          <span>{item?.fleet_type_details?.no_of_trailer}</span>
        </>
      ),
      qty: item?.qty ? `${item?.qty} Ltr` : "",
      actual: item?.actual_qty_given ? `${item?.actual_qty_given} Ltr` : "",

      action: (
        <>
          <div className="button_div"  disabled={item?.id === updateId}>
            {item.fuel_order_status === 1 && (
              <button
                className="anime-btn accept_odr"
                onClick={() => {
                  setDisbleButton(true);
                  approveStatus(item, "approve");
                  setLabel("approve");
                  setUpdateId(item?.id);

                }}
                disabled={item?.id === updateId}
              >
                Accept
              </button>
            )}
            {item.fuel_order_status === 2 && (
              <button
                className="llt_apprOrdBtn"
                onClick={() => {
                  setDisbleButton(true);
                  approveStatus(item, "resend");
                  setLabel("resend");
                  setUpdateId(item?.id);
                }}
                disabled={item?.id === updateId}
              >
                Resend
              </button>
            )}
            {item.fuel_order_status === 3 && (
              <button
                className="llt_apprOrdBtn"
                onClick={() => {
                  setDisbleButton(true);
                  approveStatus(item, "check");
                  setLabel("check");
                  setUpdateId(item?.id);
                }}
                disabled={item?.id === updateId}
              >
                Check
              </button>
            )}
          </div>
        </>
      ),
      action1: (
        <>
          <div className="actionBtn">
          <span className="_faDelete" onClick={() => hendleDeleteTag(item, true)}><MdOutlineDelete /></span>
            {/* <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            /> */}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const rowDataList2 = () => {
    const listArray = trailerList?.map((item, i) => ({
      ...item,

      // tare_weight: item?.weight,
      action1: (
        <>
          <div className="actionBtn">
          <span className="_faDelete" onClick={() => deleteTrailerList(i, true)}><MdOutlineDelete /></span>
            {/* <img
              src={DeleteIcon}
              alt=""
              onClick={() => deleteTrailerList(i, true)}
            /> */}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getAllFuelExpen", debouncedFilter],
    () => getAllFuelExpense(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  const getBranchList = async () => {
    const res = await getBranch(branchObj);

    setBranchList(res?.results);
  };

  const getFleetsList = async () => {
    const res = await getFleetType(fleetObj);
    setFleetList(res?.results);
  };

  const getCountryList = async () => {
    const res = await getCountry(countryObj);
    setCountryList(res?.results);
  };

  const getDocumentList = async () => {
    const res = await getDocumentType(documentObj);
    setDocumentList(res?.results);
  };

  const getDriverList = async () => {
    const res = await getAllDriver(driverObj);
    setDriverList(res?.results);
  };
  const getTruckNoList = async () => {
    const payload = {
      data: {},
      type: formOrderName?.fleet_type?.data?.product_type,
    };
    const res = await getAllTrailerWithType(payload);

    setTruckNoList(res?.results?.length > 0 ? res?.results : []);
  };

  const getTarailerDeatailsFun = async (data) => {
    const trailer = [...trailerList];
    const res = await singleTrailerDetails(data);
    const list = {
      trailerNumber: res?.results?.trailer_number,
      weight: res?.results?.tare_weight,
      id: res?.results?.id,
    };
    trailer?.push(list);
    setTrailerList(trailer);
    const tuck = [...truckNoList];
    let tarilerRemove = tuck.filter(
      (item) => item.id !== formOrderName.trailers.value
    );

    setTruckNoList(tarilerRemove);
    setFormOrderName({ ...formOrderName, trailer: [] });
    setFormOrderValue({ ...formOrderValue, trailer: [] });
  };

  const deleteTrailerList = (i) => {
    const trailer = [...trailerList];
    const trailer1 = [...trailerList];

    let newTrailer = trailer1.find((item, index) => index === i);
    trailer.splice(i, 1);
    setTrailerList(trailer);
    trailer1.push({
      id: newTrailer?.id,
      tare_weight: newTrailer?.weight,
      trailer_number: newTrailer?.trailerNumber,
    });
    setTruckNoList(trailer1);

    // handleOrderChange("trailers", trailer);
  };

  const clearAll = () => {
    setFormOrderValue({
      truck_number: "",
      fleet_type: "",
      branch: "",
      tare_weight: "",
      vin_number: "",
      trailers: [],
      livret: "",
      verbete_expire_date: formatDate(new Date(), "-"),
      insurance_expire_date: formatDate(new Date(), "-"),
      cross_border_payment_expire_date: formatDate(new Date(), "-"),
      inspection_expire_date: formatDate(new Date(), "-"),
      caderneta_expire_date: formatDate(new Date(), "-"),
      manifesto_expire_date: formatDate(new Date(), "-"),
      taxa_de_radio_expire_date: formatDate(new Date(), "-"),
      fire_extinguisher_expiry_date: formatDate(new Date(), "-"),
      registration_status: "",
      import_document_number: "",
    });
    setFormOrderName({
      truck_number: "",
      fleet_type: "",
      branch: "",
      tare_weight: "",
      vin_number: "",
      trailers: [],
      livret: "",
      verbete_expire_date: formatDate(new Date(), "-"),
      insurance_expire_date: formatDate(new Date(), "-"),
      cross_border_payment_expire_date: formatDate(new Date(), "-"),
      inspection_expire_date: formatDate(new Date(), "-"),
      caderneta_expire_date: formatDate(new Date(), "-"),
      manifesto_expire_date: formatDate(new Date(), "-"),
      taxa_de_radio_expire_date: formatDate(new Date(), "-"),
      fire_extinguisher_expiry_date: formatDate(new Date(), "-"),
      registration_status: "",
      import_document_number: "",
    });
    setPreview("");
    setIsEdit(false);
    setFormValue("");
    setFormError("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_active: !item?.is_active,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addFleetValidation);
      setValidationError(error);
    }
  };

  const validationHnalde = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      addFleetValidationFirst
    );

    setValidationError(validationResult);

    if (validationResult === true) {
      setActiveModalTab(2);
    }
  };

  const validationHnaldeDate = async () => {
    setIsOpen(false);
    clearAll();
    setActiveModalTab(1);
    setCrossBorderList([]);
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      addFleetValidation
    );

    const payload = {
      truck_number: formOrderValue?.truck_number,
      fleet_type: formOrderValue?.fleet_type,
      branch: formOrderValue?.branch,
      tare_weight: formOrderValue?.tare_weight,
      vin_number: formOrderValue?.vin_number,
      trailers: trailerList?.map((item) => item?.id),
      verbete_expire_date: formOrderValue?.verbete_expire_date,
      insurance_expire_date: formOrderValue?.insurance_expire_date,
      cross_border_payment_expire_date:
        formOrderValue?.cross_border_payment_expire_date,
      inspection_expire_date: formOrderValue?.inspection_expire_date,
      caderneta_expire_date: formOrderValue?.caderneta_expire_date,
      manifesto_expire_date: formOrderValue?.manifesto_expire_date,
      taxa_de_radio_expire_date: formOrderValue?.taxa_de_radio_expire_date,
      fire_extinguisher_expiry_date:
        formOrderValue?.fire_extinguisher_expiry_date,
      registration_status: formOrderValue?.registration_status,
      import_document_number: formOrderValue?.import_document_number,
      driver: formOrderValue?.driver,
      livret: formOrderValue?.livret,
    };

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(payload);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const addCrossBorderDocument = async () => {
    const payload = {
      country: formOrderValue?.country,
      document_type: formOrderValue?.document_type,
      cross_border_permit_date: formOrderValue.cross_border_permit_date,
      fleet: fleetId,
    };

    const validationResult = await checkFormError(
      {
        ...payload,
      },
      crossBorderValidtion
    );
    if (validationResult === true) {
      if (isEdit === true) {
        // handleUpdate();
      } else {
        const res = await addCrossForFleet(payload);

        if (res.status === 201) {
          toast.success(res?.message);
          setIsOpenModal2(false);
          let list = [...crossBorderList];
          let cuntyList = [...crossBordrCountry];
          list.push(res?.results);
          cuntyList.push(res?.results.country_name);
          setCrossBorderList(list);
          setCrossBordrCountry(cuntyList);
          setFormOrderValue({
            ...formOrderValue,
            country: "",
            cross_border_permit_date: formatDate(new Date()),
            document_type: "",
          });
        }
      }
    } else {
      setInputError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveFleet, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getFleetDetails.mutate(item?.id);
  };

  const getFleetDetails = useMutation(singleFleetDetails, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          truck_number: res?.results?.truck_number,
          fleet_type: res?.results?.fleet_type,
          branch: res?.results?.branch,
          tare_weight: res?.results?.tare_weight,
          vin_number: res?.results?.vin_number,
          trailers: res?.results?.trailers,
          livret: res?.results?.livret,
          verbete_expire_date: formatDate(
            new Date(res?.results?.verbete_expire_date),
            "-"
          ),
          insurance_expire_date: res?.results?.insurance_expire_date,
          cross_border_payment_expire_date:
            res?.results?.cross_border_payment_expire_date,
          inspection_expire_date: res?.results?.inspection_expire_date,
          caderneta_expire_date: res?.results?.caderneta_expire_date,
          manifesto_expire_date: res?.results?.manifesto_expire_date,
          taxa_de_radio_expire_date: res?.results?.taxa_de_radio_expire_date,
          fire_extinguisher_expiry_date:
            res?.results?.fire_extinguisher_expiry_date,
          registration_status: res?.results?.registration_status,
          import_document_number: res?.results?.import_document_number,
          driver: res?.results?.driver,
        });

        const common = (name, id) => {
          if (name === "fleet") {
            let details = fleetList?.find((i) => i.id === id);

            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "branch") {
            let details = branchList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }
          if (name === "driver") {
            let details = driverList?.find((i) => i.id === id);
            return { data: details, label: details?.name, value: details?.id };
          }

          if ((name = "trailer")) {
            id.map((item) => {
              return {
                id: item?.id,
                trailerNumber: item?.trailer_number,
                weight: item?.tare_weight,
              };
            });
          }
        };

        setTrailerList(
          res?.results?.trailer_details.map((item) => {
            return {
              trailerNumber: item?.trailer_number,
              weight: item?.tare_weight,
              id: item?.id,
            };
          })
        );

        setFormOrderName({
          fleet_type: common("fleet", res?.results?.fleet_type),
          branch: common("branch", res?.results?.branch),
          driver: common("driver", res?.results?.driver),
          verbete_expire_date: formatDate(
            new Date(res?.results?.verbete_expire_date),
            "-"
          ),
          insurance_expire_date: res?.results?.insurance_expire_date,
          cross_border_payment_expire_date:
            res?.results?.cross_border_payment_expire_date,
          inspection_expire_date: res?.results?.inspection_expire_date,
          caderneta_expire_date: res?.results?.caderneta_expire_date,
          manifesto_expire_date: res?.results?.manifesto_expire_date,
          taxa_de_radio_expire_date: res?.results?.taxa_de_radio_expire_date,
          fire_extinguisher_expiry_date:
            res?.results?.fire_extinguisher_expiry_date,
        });

        setPreview(res?.results?.image);
        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.id);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );
    const payload = {
      formdata: {
        truck_number: formOrderValue?.truck_number,
        fleet_type: formOrderValue?.fleet_type,
        branch: formOrderValue?.branch,
        tare_weight: formOrderValue?.tare_weight,
        vin_number: formOrderValue?.vin_number,
        trailers: trailerList?.map((item) => item?.id),
        verbete_expire_date: formOrderValue?.verbete_expire_date,
        insurance_expire_date: formOrderValue?.insurance_expire_date,
        cross_border_payment_expire_date:
          formOrderValue?.cross_border_payment_expire_date,
        inspection_expire_date: formOrderValue?.inspection_expire_date,
        caderneta_expire_date: formOrderValue?.caderneta_expire_date,
        manifesto_expire_date: formOrderValue?.manifesto_expire_date,
        taxa_de_radio_expire_date: formOrderValue?.taxa_de_radio_expire_date,
        fire_extinguisher_expiry_date:
          formOrderValue?.fire_extinguisher_expiry_date,
        registration_status: formOrderValue?.registration_status,
        import_document_number: formOrderValue?.import_document_number,
        driver: formOrderValue?.driver,
        livret: formOrderValue?.livret,
      },
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addFleetApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setFleetId(res?.results?.id);
        setIsOpen(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateFleet, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteFleetApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getAllFuelExpen");
    setIsOpen(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };
  useEffect(() => {
    if (formValue?.image) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(formValue?.image);
    } else {
      if (preview) {
        setPreview(preview);
      } else {
        setPreview(null);
      }
    }
  }, [formValue?.image]);

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
      fuel_order_status: data.value,
    });
  };

  const handleModalActive = (data) => {
    setActiveModalTab(data?.value);
  };

  const getTableList = () => {
    switch (+filter.status) {
      case 1:
        return AllFleet;
      case 2:
        return AllFleet;
      case 3:
        return AllFleet;
      default:
        return AllFleet;
    }
  };

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return element.id === el.id;
      });
    });
    return res;
  };

  /// ----------- add Branch ---------

  const CustomControl = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button
        className="addMoreBtn"
        onClick={() =>
          addNewEntryHandle(
            props?.selectProps?.placeholder,
            props?.selectProps?.inputValue
          )
        }
      >
        + Add New{" "}
        {props?.selectProps?.inputValue
          ? ` -> "${props?.selectProps?.inputValue}"`
          : ""}
      </button>
      {children}
    </components.Menu>
  );

  const addNewEntryHandle = (PlaceName, inputvalue) => {
    switch (PlaceName) {
      case "Select Fleet Branch":
        setFormOrderValue({
          ...formOrderValue,
          addCountry: inputvalue,
          openName: "branch",
        });
        setOpenModal(true);

        break;
      case "Select Driver":
        setOpenDriver(true);
        break;
      default:
        break;
    }
  };

  const addNewSubmit = async (value) => {
    const validationResult = await checkFormError(
      { name: formOrderValue.addBranch },
      modalInputSchema
    );

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAddNew(value);
      }
    } else {
      setFormError(validationResult);
      setIsLoadding(false);
    }
  };

  const handleAddNew = (value) => {
    const payload = {
      name: formOrderValue?.addBranch,
    };

    addNewBranchMutation.mutate(payload);
  };

  const addNewBranchMutation = useMutation(addBranchApi, {
    onSuccess: (res) => {
      if (res?.success) {
        // onSuccessResponse(res?.message);

        setFormOrderName({
          ...formOrderName,
          branch: {
            data: res?.results,
            label: res?.results?.name,
            value: res?.results?.id,
          },
          addBranch: "",
        });
        setFormOrderValue({
          ...formOrderValue,
          branch: res?.results?.id,
          addbranch: "",
        });

        setOpenModal(false);
        getBranchList();
        setIsLoadding(false);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const routeCallBack = (data) => {
    getDriverList();
    setFormOrderValue({ ...formOrderValue, driver: data?.id });
    setFormOrderName({
      ...formOrderName,
      driver: { value: data?.id, label: data?.name },
    });
  };

  const approveStatus = async (item, label) => {
    const payload = {
      fuel_expense: item?.id
    };

    if (label === "approve") {
      approveFuelMutation.mutate(payload);
    }

    if (label === "resend") {
      approveFuelMutation.mutate(payload);
    }

    if (label === "check") {
      const getorder = { order_date: item?.order_date };
      getFuelExpenseKaran.mutate(getorder);
    }

    // approveFuelMutation.mutate(payload)
  };
  const getFuelExpenseKaran = useMutation(getKranExpense, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);

        setResponseKaran(res);

        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const approveFuelMutation = useMutation(approvefuelExpense, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);

        setResponseKaran(res);

        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleUpdateFuel = async (data) => {
    const otp = {
      fuel_order_status: data?.fuel_order_status,
      order_number: data?.order_number,
      order_otp: data?.order_otp,
    };

    const payload = {
      formdata: { fuel_order_status: data?.fuel_order_status },
      id: data?.id,
    };

    if(data?.order_number) payload["formdata"] = otp;

    updateFuelMutation.mutate(payload);
  };

  const updateFuelMutation = useMutation(updatefuelTripExpense, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        setLabel("")
        setResponseKaran('')
        setUpdateId("")
        queryClient.refetchQueries("getAllFuelExpen");
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
        setLabel("")
        setUpdateId("")
      }
    },
  });

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={fuelTranstionList}
          handleActive={handleActive}
          activeTab={+filter?.status}
        />
      </div>
      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex">
            <InputField
              placeholder="Search"
              className="anvSearch_input"
              label="Search"
              value={filter?.search}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  search: e.target.value,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <div className="tabFix">
            <CustomReactTable
              columns={column}
              data={rowDataList(
                dataList?.results?.length > 0 ? dataList?.results : []
              )}
              // tableLoading={loaderForList}
            />
          </div>
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <div className="pagination_count">
        <div className="">
        <Dropdown
         placeholder="Select Count"
         className="dropdownoforder orderModal_Input"
         name="driver"
         options={pageCount ? pageCount : []}
         valueText="value"
           labelText="label"
          
           selectedOption={formOrderName?.count?formOrderName?.count:{label:"20",value:20}}

           setSelectedOption={(data) => {
            setFilter((s) => ({
              ...s,
              ...obj,
              limit:data.value,
            }))
            
             setFormOrderName({ ...formOrderName, count: data });
           }}
  
        />
        </div>
        
<CustomPagination
        onPageChange={handlePageClick}
        currentPage={filter?.currentPage}
        pageCount={Math.ceil(dataList?.count / filter?.limit)}
      />
      </div>
      )}

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            validationHnaldeDate();
            setFormOrderValue({
              truck_number: "",
              fleet_type: "",
              branch: "",
              tare_weight: "",
              vin_number: "",
              trailers: [],
              livret: "",
              verbete_expire_date: formatDate(new Date()),
              insurance_expire_date: formatDate(new Date()),
              cross_border_payment_expire_date: formatDate(new Date()),
              inspection_expire_date: formatDate(new Date()),
              caderneta_expire_date: formatDate(new Date()),
              manifesto_expire_date: formatDate(new Date()),
              taxa_de_radio_expire_date: formatDate(new Date()),
              fire_extinguisher_expiry_date: formatDate(new Date()),
              registration_status: "",
              import_document_number: "",
            });
            setPreview("");
            setIsEdit(false);
            setDisbleButton(false);
            setTrailerList([]);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) =>
            +activeModalTab === 1
              ? validationHnalde()
              : +activeModalTab === 2
              ? onOrderSubmit()
              : validationHnaldeDate(e)
          }
          title={isEdit ? "Edit Fleet" : "Add Fleet"}
          submitLabel={+activeModalTab === 3 ? "Save" : "Continue"}
          inputProps={
            <>
              {isOpen && (
                <div className="TabHeadofmodal">
                  {" "}
                  <Tabs
                    TabList={addfleetsTabList}
                    handleActive={handleModalActive}
                    activeTab={+activeModalTab}
                  />
                </div>
              )}
              {+activeModalTab === 1 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_inputField">
                      <InputField
                        className="modal_input orderModal_Input orderModal_Input"
                        label={isEdit ? "Truck number" : "Truck number"}
                        labelClass="FormLableKaran"
                        placeholder="Fleet Number"
                        name="truck_number"
                        type="text"
                        focus={!!formError?.truck_number}
                        error={formError.truck_number}
                        value={formOrderValue?.truck_number}
                        onChange={({ target: { name, value } }) => {
                          handleOrderChange(name, value.toUpperCase());
                        }}
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <span>
                        <Label className="FormLableKaran">Fleet Type</Label>
                      </span>
                      <div>
                        <Dropdown
                          className="dropdownoforder"
                          placeholder="Fleet Type"
                          name="fleet_type"
                          options={fleetList ? fleetList : []}
                          valueText="id"
                          labelText="name"
                          focus={!!formError?.fleet_type}
                          error={formError?.fleet_type}
                          selectedOption={formOrderName?.fleet_type}
                          setSelectedOption={(data) => {
                            setFormOrderName({
                              ...formOrderName,
                              fleet_type: data,
                            });
                            handleOrderChange("fleet_type", data?.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="anvOt_inputField ">
                      <span>
                        <Label className="FormLableKaran">Fleet Branch</Label>
                      </span>
                      <div>
                        <Dropdown
                          className="dropdownoforder"
                          placeholder="Select Fleet Branch"
                          name="branch"
                          options={branchList ? branchList : []}
                          valueText="id"
                          labelText="name"
                          focus={!!formError?.branch}
                          error={formError?.branch}
                          selectedOption={formOrderName?.branch}
                          components={{ Menu: CustomControl }}
                          setSelectedOption={(data) => {
                            setFormOrderName({
                              ...formOrderName,
                              branch: data,
                            });
                            handleOrderChange("branch", data?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <span>
                        <Label className="FormLableKaran">Fleet Driver</Label>
                      </span>
                      <div>
                        <Dropdown
                          className="dropdownoforder"
                          placeholder="Select Driver"
                          name="driver"
                          options={driverList ? driverList : []}
                          valueText="id"
                          labelText="name"
                          components={{ Menu: CustomControl }}
                          focus={!!formError?.driver}
                          error={formError?.driver}
                          selectedOption={formOrderName?.driver}
                          setSelectedOption={(data) => {
                            setFormOrderName({
                              ...formOrderName,
                              driver: data,
                            });
                            handleOrderChange("driver", data?.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Chassis No" : "Chassis No"}
                        labelClass="FormLableKaran"
                        placeholder="Enter Chassis No"
                        name="vin_number"
                        type="text"
                        focus={!!formError?.vin_number}
                        error={formError.vin_number}
                        value={formOrderValue?.vin_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "TARE Weight" : "TARE Weight"}
                        labelClass="FormLableKaran"
                        placeholder="Ton"
                        name="tare_weight"
                        type="text"
                        maxLength={10}
                        focus={!!formError?.tare_weight}
                        error={formError.tare_weight}
                        value={formOrderValue?.tare_weight}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_outerFull">
                      <button
                        className="anime-btn addPlan"
                        disabled={
                          formOrderName?.fleet_type?.data?.no_of_trailer ===
                            trailerList?.length || !formOrderName?.fleet_type
                        }
                        onClick={() => {
                          setIsOpenModal(true);
                          getTruckNoList();
                        }}
                      >
                        Add Trailer
                      </button>
                    </div>
                    <div className="anvOt_outerFull">
                      <TableForModal column={addFleet} data={rowDataList2()} />
                    </div>
                  </div>
                </div>
              )}
              {+activeModalTab === 2 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal">
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input1"
                        label={isEdit ? "RC" : "RC"}
                        labelClass="FormLableKaran"
                        placeholder="Enter RC"
                        name="livret"
                        type="text"
                        focus={!!formError?.livret}
                        error={formError.livret}
                        value={formOrderValue?.livret}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    {/* <div className="anvOt_inputField ">
                                    <div >
                                        <span>
                                            <Label className="FormLableKaran">Verbete (Expire date)</Label>
                                        </span>
                                        <div>
                                            <CustomDatePicker
                                                value={formOrderName?.verbete_expire_date}
                                                max={formatDate(new Date())}
                                                focus={!!formError?.verbete_expire_date}
                                                error={formError?.verbete_expire_date}
                                                name="verbete_expire_date"
                                                onSelect={({ target: { name, value } }) => {
                                                    handleOrderChange(name, value)
                                                    setFormOrderName({ ...formOrderName, verbete_expire_date: value })
                                                }
                                                }
                                                className="date-input"
                                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                                            // className="date-input"
                                            />

                                        </div>
                                    </div>
                                </div> */}
                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Insurance (Expire date)
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            value={formOrderName?.insurance_expire_date}
                            max={formatDate(new Date())}
                            type="date"
                            focus={!!formError?.insurance_expire_date}
                            error={formError.insurance_expire_date}
                            name="insurance_expire_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                insurance_expire_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Cross Border Payment (Expire date)
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            value={
                              formOrderName?.cross_border_payment_expire_date
                            }
                            max={formatDate(new Date())}
                            focus={
                              !!formError?.cross_border_payment_expire_date
                            }
                            error={formError.cross_border_payment_expire_date}
                            name="cross_border_payment_expire_date"
                            type="date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                cross_border_payment_expire_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Inspection (Expire date)
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            value={formOrderName?.inspection_expire_date}
                            max={formatDate(new Date())}
                            focus={!!formError?.inspection_expire_date}
                            error={formError.inspection_expire_date}
                            name="inspection_expire_date"
                            type="date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                inspection_expire_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Caderneta (Expire date)
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            value={formOrderName?.caderneta_expire_date}
                            max={formatDate(new Date())}
                            focus={!!formError?.caderneta_expire_date}
                            error={formError.caderneta_expire_date}
                            name="caderneta_expire_date"
                            type="date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                caderneta_expire_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Manifesto (Expire date)
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            type="date"
                            value={formOrderName?.manifesto_expire_date}
                            max={formatDate(new Date())}
                            focus={!!formError?.manifesto_expire_date}
                            error={formError.manifesto_expire_date}
                            name="manifesto_expire_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                manifesto_expire_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Taxa de Radio (Expire date)
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            type="date"
                            value={formOrderName?.taxa_de_radio_expire_date}
                            max={formatDate(new Date())}
                            focus={!!formError?.taxa_de_radio_expire_date}
                            error={formError.taxa_de_radio_expire_date}
                            name="taxa_de_radio_expire_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                taxa_de_radio_expire_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <div>
                        <span>
                          <Label className="FormLableKaran">
                            Fire Extinguisher Expiry Date
                          </Label>
                        </span>
                        <div>
                          <CustomDatePicker
                            type="date"
                            value={formOrderName?.fire_extinguisher_expiry_date}
                            max={formatDate(new Date())}
                            focus={!!formError?.fire_extinguisher_expiry_date}
                            error={formError.fire_extinguisher_expiry_date}
                            name="fire_extinguisher_expiry_date"
                            onSelect={({ target: { name, value } }) => {
                              handleOrderChange(name, value);
                              setFormOrderName({
                                ...formOrderName,
                                fire_extinguisher_expiry_date: value,
                              });
                            }}
                            className="date-input"
                            // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                            // className="date-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={isEdit ? "Registration No" : "Registration No"}
                        labelClass="FormLableKaran"
                        placeholder="Registration No"
                        name="registration_status"
                        type="text"
                        maxLength={10}
                        focus={!!formError?.registration_status}
                        error={formError.registration_status}
                        value={formOrderValue?.registration_status}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                    <div className="anvOt_inputField ">
                      <InputField
                        className="modal_input orderModal_Input"
                        label={
                          isEdit
                            ? "Import Documents (DU number)"
                            : "Import Documents (DU number)"
                        }
                        labelClass="FormLableKaran"
                        placeholder="Import Documents"
                        name="import_document_number"
                        type="text"
                        maxLength={10}
                        focus={!!formError?.import_document_number}
                        error={formError.import_document_number}
                        value={formOrderValue?.import_document_number}
                        onChange={({ target: { name, value } }) =>
                          handleOrderChange(name, value)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {+activeModalTab === 3 && (
                <div className="anvMaintab_container">
                  <div className="anvOt_gridBx_modal crossBorderTrailer">
                    <div className="addpermitDetails">
                      <span>Add Permit Details</span>
                      <button
                        className="anime-btn addPlan"
                        disabled={!disbleButton}
                        onClick={() => setIsOpenModal2(true)}
                      >
                        Add Details
                      </button>
                    </div>

                    {[...new Set(crossBordrCountry)]?.map((cty) => {
                      return (
                        <>
                          <div className="crosstrailerButtonBox">
                            <span>{cty}</span>
                            <button className="addCrossAction">Edit</button>
                            <button className="addCrossAction">Delete</button>
                          </div>

                          <Table bordered hover responsive>
                            <thead>
                              <tr>
                                <th>Document</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            {crossBorderList
                              ?.filter((filtr) => filtr.country_name === cty)
                              ?.map((item, i) => (
                                <tbody>
                                  <tr
                                    className={
                                      i % 2 === 0
                                        ? "table-primary"
                                        : "table-success"
                                    }
                                  >
                                    <td scope="row">
                                      {
                                        documentList?.filter(
                                          (doc) =>
                                            doc?.id === item?.document_type
                                        )[0]?.name
                                      }
                                    </td>
                                    <td>{item?.cross_border_permit_date}</td>
                                    <td>Active</td>
                                  </tr>
                                </tbody>
                              ))}
                          </Table>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          }
        />
      )}
      <Modal
        Modal
        isOpen={showCropper}
        onClose={() => setShowCropper(true)}
        className="imageCrooperModal"
      >
        {typeof showCropper !== "boolean" && (
          <ImageCropper
            setFormValue={setFormValue}
            setShowCropper={setShowCropper}
            image={showCropper}
            cropShape="rect"
            aspect={6 / 3}
            formValue={formValue}
          />
        )}
      </Modal>
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />

      <InputModal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(!isOpenModal)}
        headerName={isEdit ? "Edit Category" : "Add Trailer"}
        submitLabel={isEdit ? "Update Category" : "Continue"}
        onSubmit={(e) => {
          getTarailerDeatailsFun(formOrderName.trailers.value);
          setIsOpenModal(false);
        }}
        disabled={!formOrderValue?.trailers}
        inputProps={
          <>
            <span>
              <Label className="FormLableKaran">Trailer Number</Label>
            </span>
            <div>
              {/* <Dropdown
                                className='dropdownoforder'
                                placeholder="Select Trailers"
                                isMulti={true}
                                name="truck_number"
                                options={truckNoList ? truckNoList : []}
                                valueText="id"
                                labelText="trailer_number"
                                focus={!!formError?.trailers}
                                error={formError?.trailers}
                                selectedOption={formOrderValue?.trailers}
                                setSelectedOption={(data) => {

                                    setFormOrderName({ ...formOrderName, trailers: data })
                                    handleOrderChange("trailers", data);

                                }}
                            /> */}
              {truckNoList?.length > 0 ? (
                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Trailers"
                  // isMulti={true}
                  name="truck_number"
                  options={
                    truckNoList
                      ? trailerList?.length
                        ? filterByReference(truckNoList, trailerList)
                        : truckNoList
                      : []
                  }
                  valueText="id"
                  labelText="trailer_number"
                  focus={!!formError?.trailers}
                  error={formError?.trailers}
                  selectedOption={formOrderValue?.trailers}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, trailers: data });
                    handleOrderChange("trailers", data);
                    // getTarailerDeatailsFun(data.value)
                  }}
                />
              ) : (
                "No Trailer Added"
              )}
            </div>
          </>
        }
      />

      <InputModal
        isOpen={isOpenModal2}
        toggle={() => setIsOpenModal2(!isOpenModal2)}
        headerName={isEdit ? "Edit Category" : "Add Cross Border Permit"}
        submitLabel={isEdit ? "Update Category" : "Continue"}
        onSubmit={(e) => addCrossBorderDocument(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        inputProps={
          <>
            <div>
              <span>
                <Label className="FormLableKaran">Country</Label>
              </span>
              <div>
                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Country"
                  name="fleet_type"
                  options={countryList ? countryList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!formError?.country}
                  error={formError?.country}
                  selectedOption={formOrderName?.country}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, country: data });
                    handleOrderChange("country", data?.value);
                  }}
                />
              </div>
            </div>
            <div>
              <span>
                <Label className="FormLableKaran">Document Name</Label>
              </span>
              <div>
                <Dropdown
                  className="dropdownoforder"
                  placeholder="Select Document"
                  name="fleet_type"
                  options={documentList ? documentList : []}
                  valueText="id"
                  labelText="name"
                  focus={!!formError?.document_type}
                  error={formError?.document_type}
                  selectedOption={formOrderName?.document_type}
                  setSelectedOption={(data) => {
                    setFormOrderName({ ...formOrderName, document_type: data });
                    handleOrderChange("document_type", data?.value);
                  }}
                />
              </div>
            </div>

            <div>
              <span>
                <Label className="FormLableKaran">Expire date</Label>
              </span>
              <div>
                <CustomDatePicker
                  type="date"
                  value={formOrderName?.cross_border_permit_date}
                  max={formatDate(new Date())}
                  focus={!!formError?.cross_border_permit_date}
                  error={formError.cross_border_permit_date}
                  name="cross_border_permit_date"
                  onSelect={({ target: { name, value } }) => {
                    handleOrderChange(name, value);
                    setFormOrderName({
                      ...formOrderName,
                      cross_border_permit_date: value,
                    });
                  }}
                  className="date-input"
                  // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                  // className="date-input"
                />
              </div>
            </div>
          </>
        }
      />

      {openModal && (
        <InputModal
          isOpen={openModal}
          headerName={isEdit ? "Edit Branch" : "Add Branch"}
          toggle={() => setOpenModal(!openModal)}
          submitLabel={isEdit ? "Update Branch" : "Continue"}
          onSubmit={(e) => addNewSubmit(formOrderValue.openName)}
          // disabled={isEdit ? inputValue.name === disableInp.name : ""}
          isLoadding={isLoadding}
          inputProps={
            <InputField
              className="modal_input"
              label={isEdit ? "Edit Branch" : "Branch Name"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Branch Name"
              name="addBranch"
              focus={!!formError.addBranch}
              error={formError.addBranch}
              value={formOrderValue.addBranch}
              onChange={({ target: { name, value } }) =>
                handleOrderChange(name, value)
              }
            />
          }
        />
      )}
      {openDriver && (
        <CommonDriver
          isOpen={openDriver}
          setIsOpen={setOpenDriver}
          routeCallBack={routeCallBack}
        />
      )}
    </>
  );
};
